<script setup></script>

<template>
  <crud-details-page
    api="battle/talent-level"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- Level -->
        <crud-field-number
          api-field-name="Level"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- RequiredTalentsNumber -->
        <crud-field-number api-field-name="RequiredTalentsNumber" />
        <!-- RequiredGold -->
        <crud-field-number api-field-name="RequiredGold" />
        <!-- RequiredEternium -->
        <crud-field-number api-field-name="RequiredEternium" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- IncrementBonusValue -->
        <crud-field-number
          api-field-name="IncrementBonusValue"
          :precision="2"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
