<script>
import CrudFieldSelectorOffers from '@/components/forms/crud-fields/CrudFieldSelectorOffers.vue'

export default {
  components: { CrudFieldSelectorOffers }
}
</script>

<template>
  <crud-details-page
    api="quests/milestone"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- GamePass -->
        <CrudFieldSelectorOffers api-field-name="GamePass" />
      </fields-col>
      <fields-col :sm="6">
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="6">
        <!-- IsMilestonePerQuest -->
        <crud-field-switcher api-field-name="IsMilestonePerQuest" />
      </fields-col>
      <template
        v-for="i in 60"
        :key="i"
      >
        <fields-col>
          <section-divider>Milestone #{{ i }}</section-divider>
        </fields-col>
        <fields-col :sm="6">
          <!-- ReqPoints -->
          <crud-field-number
            :api-field-name="`Milestone${i}.ReqPoints`"
            label="Required points"
          />
          <!-- ReqLevel -->
          <crud-field-number
            :api-field-name="`Milestone${i}.ReqLevel`"
            label="Required level"
          />
          <!-- ShowDefaultIcon -->
          <crud-field-switcher
            :api-field-name="`Milestone${i}.ShowDefaultIcon`"
            label="Show default icon"
          />
        </fields-col>
        <fields-col :sm="12">
          <template
            v-for="k in 4"
            :key="k"
          >
            <!-- ItemSlot -->
            <crud-field-selector-items
              :sm="16"
              :api-field-name="`Milestone${i}.ItemSlot${k}`"
              :label="`Item ${k}`"
            />
            <!-- ItemSlot -->
            <crud-field-number
              :sm="8"
              :api-field-name="`Milestone${i}.ItemSlot${k}Amount`"
              :label="`Item ${k} amount`"
            />
          </template>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
