<script>
import { inject } from 'vue'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'
import { filterCascader } from '!/composition/filterCascader'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'TopSelector',
  components: { FieldsLabel },
  setup() {
    const searchFields = inject('searchFields')
    const langName = (codeLang) => {
      return `${codeLang.toUpperCase()} - ${app.$utils.languageByCode(codeLang).name}  ${app.$utils.languageByCode(codeLang).emoji} `
    }
    const options = [
      {
        value: 'langs',
        label: 'missing specific translation',
        children: ['en', 'fr', 'de', 'it', 'es', 'pt', 'ru', 'zh', 'cm', 'pl'].map((code) => {
          return {
            value: code,
            label: langName(code)
          }
        })
      },
      {
        value: 'any',
        label: 'missing translation'
      },
      {
        value: 'new',
        label: 'new keys'
      },
      {
        value: 'remarks',
        label: 'with remarks'
      }
    ]
    const { settings, model, toggle } = filterCascader(options, searchFields)

    return {
      options,
      settings,
      model,
      searchFields,
      toggle
    }
  }
}
</script>

<template>
  <FieldsLabel
    class="ml-1.5"
    label="Filters"
  >
    <el-cascader
      v-model="model"
      v-bind="settings"
      :size="$store.getters['auth/userScaledSize']"
      :options="options"
      popper-class="gs-font-scaled"
    >
      <template #default="{ node, data }">
        <span
          class="inline-block w-full hover:text-blue-400"
          @click="toggle(node)"
        >
          {{ data.label }}
        </span>
      </template>
    </el-cascader>
  </FieldsLabel>
</template>

<style scoped></style>
