<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectHitFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions, booleanOptions, srcOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      booleanOptions,
      srcOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'hit',
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'times': 1,
      'chance': 100,
      'dlvl-chance': 0,
      'source-change': 0,
      'src': 'attack',
      'is-percent': 1,
      'skip-defence-min': 0,
      'skip-defence-max': 0,
      'min-value': 0,
      'max_value': 0,
      'dlvl-value': 0,
      'add-defence-value': 0,
      'add-defence-dlvl-value': 0,
      'dlvl-skip-defence': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="hit_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- times -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.times`"
          label="Times"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="hit_times"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.chance`"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-chance`"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="hit_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- src -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.src`"
          label="Value source"
          slim
          :clearable="false"
          :options="srcOptions"
          doc-field-name="hit_Value_source"
          disable-doc-settings
        />
      </div>
      <!-- source-change -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.source-change`"
          label="Source Change %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Source_Change_%"
          disable-doc-settings
        />
      </div>
      <!-- is-percent -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.is-percent`"
          label="Use % of value"
          slim
          :clearable="false"
          :options="booleanOptions"
          doc-field-name="hit_Use%_of_value"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="hit_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="hit_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="hit_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- skip-defence-min -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.skip-defence-min`"
          label="Skip def. min %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Skip_def._min%"
          disable-doc-settings
        />
      </div>
      <!-- skip-defence-max -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.skip-defence-max`"
          label="Skip def. max %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Skip_def._max%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-skip-defence -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-skip-defence`"
          label="Increment per level"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Skip_Defence_Increment_per_level"
          disable-doc-settings
        />
      </div>
      <!-- min-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.min-value`"
          label="Value min"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="hit_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- max_value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.max_value`"
          label="Value max"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="hit_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-value`"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Dlvl_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- add-defence-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.add-defence-value`"
          label="Defence hit %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Defence_hit_%"
          disable-doc-settings
        />
      </div>
      <!-- add-defence-dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.add-defence-dlvl-value`"
          label="Increment per level"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="hit_Defence_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
