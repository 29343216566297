<script setup></script>

<template>
  <crud-details-page
    api="guild/academy-bonus"
  >
    <template #form>
      <fields-col :sm="6">
        <!-- BonusLevel -->
        <crud-field-number
          api-field-name="BonusLevel"
          required
        />
        <!-- BonusAttribute -->
        <crud-field-select
          api-field-name="BonusAttribute"
          options-enum="enums.BaseAttributeGuildAcademy"
          required
        />
        <!-- BonusValue -->
        <crud-field-number
          api-field-name="BonusValue"
          :precision="2"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- ReqGuildLevel -->
        <crud-field-number
          api-field-name="ReqGuildLevel"
          required
        />
        <!-- ReqAcademyAttribute -->
        <crud-field-select
          api-field-name="ReqAcademyAttribute"
          options-enum="enums.BaseAttributeGuildAcademy"
        />
        <!-- ReqAcademyAttributeLevel -->
        <crud-field-number api-field-name="ReqAcademyAttributeLevel" />
      </fields-col>
      <fields-col :sm="12">
        <!-- TributeGold -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeGold"
        />
        <!-- AdvanceReqGold -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqGold"
        />
        <!-- TributeItem1Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeItem1Amount"
          label="Amount of #1061 Eternium"
        />
        <!-- AdvanceReqItem1Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqItem1Amount"
          label="Amount max"
        />
        <!-- TributeItem2Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeItem2Amount"
          label="Amount of #10098 Blue Writ"
        />
        <!-- AdvanceReqItem2Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqItem2Amount"
          label="Amount max"
        />
        <!-- TributeItem3Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeItem3Amount"
          label="Amount of #10096 Red Writ"
        />
        <!-- AdvanceReqItem3Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqItem3Amount"
          label="Amount max"
        />
        <!-- TributeItem4Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeItem4Amount"
          label="Amount of #3923 Caravan material 2"
        />
        <!-- AdvanceReqItem4Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqItem4Amount"
          label="Amount max"
        />
        <!-- TributeItem5Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="TributeItem5Amount"
          label="Amount of #3921 Caravan material 1"
        />
        <!-- AdvanceReqItem5Amount -->
        <crud-field-number
          :sm="12"
          api-field-name="AdvanceReqItem5Amount"
          label="Amount max"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
