<script>
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'

export default {
  components: { CrudFieldFile },
  methods: {
    copyMaxId(url) {
      this.$utils
        .copyToClipboard(url)
        .then(() => {
          this.$message({
            message: `Url copied to clipboard`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.$message({
            message: 'copy to clipboard failed',
            type: 'error',
            offset: 40
          })
        })
    }
  }
}
</script>

<template>
  <crud-details-page
    api="ai/image"
    :versioned="false"
  >
    <template #form="{ form }">
      <!-- Url -->
      <CrudFieldFile
        :sm="8"
        api-field-name="Url"
        label="Image"
        upload-title="Upload picture"
        required
        doc-icon-class="-top-2 left-20"
      />
      <crud-field-slot
        v-if="form.Url"
        :sm="16"
        api-field-name="Url"
        label="Url"
      >
        <template #default>
          <table>
            <tr>
              <td>{{ form.Url }}</td>
              <td>
                <el-button
                  class="gs-btn-outlined-warning-light gs-font-scaled relative mx-1 px-1"
                  @click="copyMaxId(form.Url)"
                >
                  <icon-ify
                    icon="tabler:click"
                    class="gs-scaled-icon-xs mr-0.5"
                  />
                </el-button>
              </td>
            </tr>
          </table>
        </template>
        <template #fieldAppend />
      </crud-field-slot>
    </template>
  </crud-details-page>
</template>
