<script>
export default {
  name: 'CellContent',
  props: {
    colData: {
      type: Object,
      default: () => ({})
    },
    scopeCol: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<template>
  <div
    v-if="colData?.htmlValue"
    v-html="scopeCol.column.formatter(scopeCol.row, scopeCol.column.property, scopeCol.row[scopeCol.column.property], scopeCol.$index)"
  />
  <template v-else-if="scopeCol.column?.formatter">
    {{ scopeCol.column.formatter(scopeCol.row, scopeCol.column.property, scopeCol.row[scopeCol.column.property], scopeCol.$index) }}
  </template>
  <template v-else-if="['entity', 'clientstring'].includes(colData?.typeValueField) && scopeCol.row[scopeCol.column.property]?.ID !== undefined">
    <template v-if="!scopeCol.row[scopeCol.column.property]?.AdminLabel && !scopeCol.row[scopeCol.column.property]?.AdminIcon">
      {{ scopeCol.row[scopeCol.column.property].ID }}
    </template>
    <div
      v-else
      class="flex items-center leading-none"
    >
      <div
        v-if="scopeCol.row[scopeCol.column.property]?.AdminIcon"
        class="mr-1"
      >
        <el-tooltip
          v-if="scopeCol.row[scopeCol.column.property].AdminIcon"
          raw-content
          :show-after="600"
          effect="light"
          placement="right-start"
        >
          <template #default>
            <el-image
              :src="scopeCol.row[scopeCol.column.property].AdminIcon"
              fit="contain"
              :style="{
                width: `${
                  35 * $store.getters['auth/userScaledRatioWidth'] + (35 * $store.getters['auth/userScaledRatioWidth'] * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100
                }px`,
                height: `${
                  35 * $store.getters['auth/userScaledRatioWidth'] + (35 * $store.getters['auth/userScaledRatioWidth'] * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100
                }px`
              }"
              lazy
            />
          </template>
          <template #content>
            <el-image
              :src="scopeCol.row[scopeCol.column.property].AdminIcon"
              fit="contain"
              style="width: 200px; height: 200px"
            />
          </template>
        </el-tooltip>
      </div>
      <div v-if="scopeCol.row[scopeCol.column.property]?.AdminLabel">
        {{ scopeCol.row[scopeCol.column.property].AdminLabel }}
      </div>
    </div>
  </template>
  <template v-else-if="colData?.typeValueField === 'datetime'">
    {{
      `${$utils.formatUTCDateTime(scopeCol.row[scopeCol.column.property], 'YYYY-MM-DD HH:mm').replace(' 00:00', '')}${$utils
        .formatUTCDateTime(scopeCol.row[scopeCol.column.property], ':ss')
        .replace(':00', '')}`
    }}
  </template>
  <template v-else-if="colData?.typeValueField === 'date'">
    {{ $utils.formatUTCDateTime(scopeCol.row[scopeCol.column.property], 'YYYY-MM-DD') }}
  </template>
  <template v-else-if="colData?.typeValueField === 'bool'">
    <span class="">{{ scopeCol.row[scopeCol.column.property] ? '&#9989;' : '' }}</span>
  </template>
  <template v-else>
    {{ scopeCol.row[scopeCol.column.property] }}
  </template>
</template>
