import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import VuePapaParse from 'vue-papa-parse'
import * as am5 from '@amcharts/amcharts5'
import App from './App.vue'
import store from './store'
import fontAwsome from './font-awsome'
import { initCommonAdminRoutes, initRouter, setVue } from './router/index'
import elementPlus from './plugins/element-plus'
import windowSize from './plugins/window-size'
import axios from './plugins/axios'
import utilities from './plugins/utilities'
import globalComponents from './plugins/globalComponents'
import mdijs from './plugins/mdi'
import validators from './plugins/validators'
import webSocket from './plugins/webSocket'
import 'element-plus/dist/index.css'
import './css/index.css'
import routesGame from './router/routes'

export default (configure) => {
  am5.addLicense('AM5C-3633-1458-4152-6876')
  const Vue = createApp(App)
  setVue(Vue)
  const head = createHead()
  store(Vue)
  fontAwsome(Vue)
  Vue.use(elementPlus)
  Vue.use(windowSize)
  Vue.use(axios)
  Vue.use(utilities)
  Vue.use(globalComponents)
  Vue.use(mdijs)
  Vue.use(validators)
  Vue.use(webSocket)
  Vue.use(VuePapaParse)
  Vue.use(head)
  const routes = initCommonAdminRoutes()
  let routesChildren = routes[2].children
  let settings = {}
  if (configure) {
    settings = {
      routes: routes[2].children,
      gameFeatures: true,
      addRoutes(routes) {
        this.routes = [...this.routes, ...routes]
      },
      hideGameFeatures() {
        this.gameFeatures = false
      }
    }
    configure(Vue, settings)
    routesChildren = settings.routes
  }
  routes[2].children = routesChildren

  initRouter(Vue, routes, { commonMenuItems: settings.gameFeatures ? routesGame() : [] })
  Vue.mount('#app')
}
