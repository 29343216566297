<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'
import { AddNewByGlobalSearchProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'ClientStringSelector',
  components: {
    DynamicSelector,
    ClientStringsDetailsPage: defineAsyncComponent(() => import('!/pages/translations/client-strings/DetailsPage.vue'))
  },
  mixins: [AddNewByGlobalSearchProps],
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change']
}
</script>

<template>
  <DynamicSelector
    :id="id"
    :label="filterMode ? '' : 'Strings'"
    :columns-settings="{
      StringId: { width: 280, header: 'StringId (key)' },
      Contents: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="settings/client-strings"
    :edit-model-route-name="filterMode ? '' : 'client-strings-details'"
    add-new-by-global-search
    :field="field"
    @change="$emit('change', $event)"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <ClientStringsDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
