<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldText',
  mixins: [BasicProps],
  props: {
    /**
     * if > 0 prop component is set as el-input with attr type="textarea"
     */
    textareaRows: {
      type: Number,
      default: 5
    },
    type: {
      type: String,
      default: 'textarea'
    }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
