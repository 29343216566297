import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser as fasUser } from '@fortawesome/free-solid-svg-icons'
import { faUser as farUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fasUser, farUser)

export default (app) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon)
}
