<script>
export default {
  name: 'ItemMenu',
  data() {
    return {
      loading: {}
    }
  },
  methods: {
    clearCache(endPoint, name) {
      this.loading[name] = true
      this.$axios
        .get(endPoint, {})
        .then(() => {
          this.loading[name] = false
          this.$message({
            message: `${name} cache cleared`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.logError = true
          this.loading[name] = false
          this.$message({
            message: `${name} cache failed`,
            type: 'warning',
            offset: 40
          })
        })
    }
  }
}
</script>

<template>
  <li class="cursor-text text-center">
    <el-button
      type="warning"
      :loading="loading.DEV"
      class="gs-size-scaled"
      size="small"
      @click="clearCache('/admin/api/clearcache/', 'DEV')"
    >
      <span v-if="$store.getters['main/pageMenuCollapse']">CCD</span>
      <span v-else>CC DEV</span>
    </el-button>
  </li>
</template>

<style scoped>

</style>
