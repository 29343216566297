<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_2/tip_2_1.png'

export default {
  data() {
    return {
      srcList: [
        TipImg1
      ]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      Global user settings are available in the user box (after clicking/hover the avatar in the upper right corner of the admin panel) <br>
      <b>1 - changing the font size</b><br>
      <b>2 - action column position</b> - changes the position of the action column on pages listing entities<br>
      <b>3 - preview image resize</b> - allows  to resize preview images<br>
      <b>4 - striped list tables</b> - allows to enable/disable color highlighting of rows on pages listing entities<br>
      <b>5 - menu CCD</b> - allows to show/hide clear cache dev button in menu<br>
      <b>6 - zero values</b> - hide/show zero values in the form fields of type number (does not apply to nullables)<br>
    </p>
  </div>
</template>

<style scoped></style>
