<script></script>

<template>
  <crud-details-page
    :back-routes="['dashboard']"
    api="settings/battle-setting"
    :versioned="false"
    disable-top-margin
    :actions="{ crudDuplicate: false, crudDelete: false }"
  >
    <template #form>
      <fields-col>
        <section-divider>Intensity values</section-divider>
      </fields-col>
      <crud-field-number
        v-for="n in 3"
        :key="n"
        :sm="6"
        :api-field-name="`IntensityValue${n}`"
        :label="`Intensity ${n} value %`"
        :precision="2"
      />
      <fields-col>
        <section-divider>Mob stats multipliers (campaign & battle event mobs only)</section-divider>
      </fields-col>
      <crud-field-number
        :sm="6"
        api-field-name="MultiplyMobDamage"
        label="Damage"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiplyMobDefense"
        label="Defense"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiplyMobHealth"
        label="Health"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiplyMobMagic"
        label="Magic"
        :precision="2"
      />
      <fields-col class="mt-8">
        <section-divider>Tutorial Settings</section-divider>
      </fields-col>
      <crud-field-number
        :sm="6"
        api-field-name="TutorialHeroHp"
        label="Tutorial hero HP"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="TutorialHeroAtk"
        label="Tutorial hero ATK"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="TutorialHeroDef"
        label="Tutorial hero DEF"
        :precision="2"
      />
      <crud-field-number
        :sm="6"
        api-field-name="TutorialHeroMag"
        label="Tutorial hero MAG"
        :precision="2"
      />
      <fields-col
        :sm="11"
      >
        <section-divider>Tutorial 1</section-divider>
        <crud-field-selector-items
          v-for="n in 3"
          :key="n"
          :api-field-name="`Tutorial1Talent${n}`"
          :label="`Talent${n}`"
        />
        <crud-field-textarea
          v-for="n in 12"
          :key="n"
          :sm="12"
          :textarea-rows="2"
          :api-field-name="`Tutorial1SpiritsSet${n}`"
          :label="`Spirits set ${n}`"
        />
        <el-col class="gs-font-scaled font-related-xs text-neutral-500">
          * Use 1=blue, 2=white, 3=red, 2 rows, 7 characters each
        </el-col>
      </fields-col>
      <el-col
        :sm="2"
        class="hidden sm:flex"
      />
      <fields-col
        :sm="11"
      >
        <section-divider>Tutorial 2</section-divider>
        <crud-field-selector-items
          v-for="n in 3"
          :key="n"
          :api-field-name="`Tutorial2Talent${n}`"
          :label="`Talent${n}`"
        />
        <crud-field-textarea
          v-for="n in 12"
          :key="n"
          :sm="12"
          :textarea-rows="2"
          :api-field-name="`Tutorial2SpiritsSet${n}`"
          :label="`Spirits set ${n}`"
        />
        <el-col class="gs-font-scaled font-related-xs text-neutral-500">
          * Use 1=blue, 2=white, 3=red, 2 rows, 7 characters each
        </el-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
