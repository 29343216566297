<script setup lang="ts">
</script>

<template>
  <crud-details-page
    api="journeys/name"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-selector-client-strings api-field-name="Name" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
