<script>
import { Edit } from '@element-plus/icons-vue'
import { inject } from 'vue'
import EnvTags from '!/components/crud/sub/EnvTags.vue'
import AbTag from '!/components/crud/sub/AbTag.vue'
import SyncTags from '!/components/crud/sub/SyncTags.vue'

export default {
  name: 'CrudVersionsTable',
  components: { EnvTags, AbTag, SyncTags },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    dev2ProdSync: {
      type: Object,
      default: () => ({})
    },
    editRoute: {
      type: [String, Function],
      default: undefined
    },
    isMultipleEntity: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:dev2-prod-sync'],
  setup() {
    const crudTable = inject('crudTable')
    return {
      crudTable,
      icons: { Edit }
    }
  },
  methods: {
    crudPreviewAction(row = {}) {
      try {
        let routeObj = ''
        if (typeof this.editRoute === 'function') {
          routeObj = this.editRoute(row)
        } else if (this.editRoute) {
          routeObj = { name: this.editRoute, params: { id: row?.ID || 0 } }
        } else {
          routeObj = { name: `${this.$route.name}-details`, params: { id: row?.ID || 0 } }
        }
        const detailRoute = this.$utils.getFullRouteData(routeObj, true)
        if (detailRoute) {
          const query = {}
          if (this.editRoute) {
            query.back = this.$route.name
          }
          return this.$utils.bindStaticParams({ name: detailRoute.name, params: { id: row?.ID || 0 }, query })
        }
      } catch (_e) {
        return ''
      }
      return ''
    },
    onDblClick(row) {
      const routeData = this.crudPreviewAction(row)
      if (routeData) {
        this.$router.push(routeData)
      }
    }
  }
}
</script>

<template>
  <div class="bg-neutral-50 px-4 py-2 shadow-inner">
    <el-table
      :data="rows"
      style="min-width: 98%"
      :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
      class="font-related-xss mx-auto bg-neutral-50"
      @row-dblclick="onDblClick"
    >
      <!-- multi select rows -->
      <el-table-column
        v-if="!crudTable.selectMode && crudTable.crudSettings.crudSelectIdsMode"
        width="50"
        class-name="bg-neutral-50"
      >
        <template #default="{ row }">
          <div
            class="flex items-center justify-center"
            @click="crudTable.selectIdFromRow(row)"
          >
            <el-icon
              v-if="crudTable.crudSelectIds?.[row?.ID]"
              class="gs-scaled-icon-xs scale-95 cursor-pointer text-sky-600 hover:scale-100"
            >
              <icon-ify
                icon="mdi:checkbox-marked-outline"
                class="h-full w-full"
              />
            </el-icon>
            <el-icon
              v-else
              class="gs-scaled-icon-xs scale-95 cursor-pointer text-neutral-400 hover:scale-100"
            >
              <icon-ify
                icon="mdi:checkbox-blank-outline"
                class="h-full w-full"
              />
            </el-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ID"
        label="ID"
        class-name="bg-neutral-50"
        width="125px"
      >
        <template #default="{ row }">
          <el-tooltip
            content="edit <div class='font-related-xss text-neutral-400'>(also on dblclick row)</div>"
            effect="light"
            placement="right"
            :show-after="600"
            raw-content
          >
            <router-link :to="crudPreviewAction(row)">
              <el-button
                class="gs-btn-outlined-primary-light gs-height-related-lg gs-font-scaled px-1"
                :icon="icons.Edit"
              >
                {{ row.ID }}
              </el-button>
            </router-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="sync"
        label="Sync"
        class-name="bg-neutral-50"
        :width="160"
      >
        <template #default="{ row }">
          <SyncTags
            :versioned-data="row.Versioned"
            :children-envs="row.childrenEnvs"
            :dev2prod="dev2ProdSync[$utils.uniqRowIndex(row.ID, row?.Entity, isMultipleEntity)]"
            @update:dev2prod="
              $emit('update:dev2-prod-sync', {
                ...dev2ProdSync,
                [$utils.uniqRowIndex(row.ID, row?.Entity, isMultipleEntity)]: $event
              })
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="environment"
        label="Environment"
        class-name="bg-neutral-50"
      >
        <template #default="{ row }">
          <EnvTags
            :environments="{
              dev: row.Versioned.IsDev,
              prod: row.Versioned.IsProd
            }"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="ab"
        label="AB"
        class-name="bg-neutral-50"
      >
        <template #default="{ row }">
          <AbTag
            ab-data="HipoAB"
            ab-group="HipoSegmentNo"
            :rights="row.RightAbTest"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="added"
        label="Added by"
        class-name="bg-neutral-50"
        :min-width="150"
      >
        <template #default="{ row }">
          <span class="font-related-sm">{{ row.Versioned.AddedBy }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="diffs"
        label="Diffs"
        class-name="bg-neutral-50"
        min-width="350px"
      >
        <template #default="{ row }">
          <div class="my-1 flex flex-wrap gap-y-1.5">
            <template
              v-for="(value, field) in row?.Versioned?.Diffs || {}"
              :key="field"
            >
              <el-tag
                v-if="field !== 'Modified'"
                class="gs-font-scaled gs-height-related-sm mx-0.5 px-1 leading-4 transition hover:scale-110"
                type="info"
                size="small"
              >
                <span class="font-related-sm">
                  <b>{{ field }}:</b>
                  {{ value }}
                </span>
              </el-tag>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped></style>
