class Socket {
  url = ''
  app = null
  websocket = null
  isOpen = false
  onFailedCallBack = null
  onOpenCallBack = null
  onResponseCallBack = null
  responseQueue = []
  lastResponse = null
  responseData = []
  destroySocket = false
  autoReconnectCounter = 0
  requestId = 1

  constructor(url = null, app = null) {
    let urlTmp = import.meta.env.VITE_API_URL
    if (url) {
      urlTmp = url
    }

    const apiUrlHost = urlTmp.split('://')
    this.url = `${(apiUrlHost[0] === 'http' ? 'ws://' : 'wss://') + apiUrlHost[1]}/ws`

    this.app = app
  }

  openSocket(autoReconnect = false) {
    this.destroySocket = false
    this.websocket = new WebSocket(this.url)
    this.websocket.onerror = (ev) => {
      this.app?.$notify &&
      this.app.$notify({
        title: 'WebSocket connection error',
        type: 'warning',
        customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
        message: `Could not connect to ${this.url}`,
        duration: 9000
      })
      this.onFailedCallBack && this.onFailedCallBack(ev)
    }
    this.websocket.onopen = (ev) => {
      this.isOpen = true
      this.onOpenCallBack && this.onOpenCallBack(ev)
    }
    this.websocket.onclose = (ev) => {
      this.isOpen = false
      if (this.destroySocket || !autoReconnect || this.autoReconnectCounter > 5) {
        this.onFailedCallBack && this.onFailedCallBack(ev)
        this.responseQueue = []
        this.lastResponse = ''
        this.responseData = []
      } else if (autoReconnect) {
        ++this.autoReconnectCounter
        this.openSocket(true)
        setTimeout(() => {
          this.autoReconnectCounter = 0
        }, 2000)
      }
    }
    this.websocket.onmessage = (ev) => {
      if (this.isOpen) {
        if (ev?.data?.length) {
          this.responseData.push(JSON.parse(ev.data))
        }
        this.responseQueue.push(ev)
        this.onResponseCallBack && this.onResponseCallBack(ev)
        this.lastResponse = ev || ''
      }
    }
    return this
  }

  onFailed(failedCallBack) {
    this.onFailedCallBack = failedCallBack || null
    return this
  }

  onOpen(openCallBack) {
    this.onOpenCallBack = openCallBack || null
    return this
  }

  onResponse(responseCallBack) {
    this.onResponseCallBack = responseCallBack || null
    return this
  }

  destroy() {
    this.destroySocket = true
    this.isOpen = false
    return this.websocket?.close?.()
  }

  send(data) {
    if (this.isOpen) {
      data.req_id = ++this.requestId
      this.websocket.send(JSON.stringify(data))

      return this.requestId
    }

    return 0
  }
}

export default {
  install: (Vue) => {
    const globalProperties = Vue.config.globalProperties
    globalProperties.$socket = (url) => {
      return new Socket(url, globalProperties)
    }
  }
}
