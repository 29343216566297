<script>
import CrudFieldSelectorOffers from '@/components/forms/crud-fields/CrudFieldSelectorOffers.vue'
import CrudFieldSelectorBattleEvents from '@/components/forms/crud-fields/CrudFieldSelectorBattleEvents.vue'
import CrudFieldSelectorQuestGroups from '@/components/forms/crud-fields/CrudFieldSelectorQuestGroups.vue'

export default {
  components: { CrudFieldSelectorQuestGroups, CrudFieldSelectorBattleEvents, CrudFieldSelectorOffers }
}
</script>

<template>
  <crud-details-page
    api="payments/offers-list"
    disable-top-margin
    enable-relations
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- HeaderGraphic -->
        <crud-field-selector-files
          api-field-name="HeaderGraphic"
          required
        />
        <!-- AvailableFrom -->
        <crud-field-date-time
          api-field-name="AvailableFrom"
          required
        />
        <!-- AvailableTo -->
        <crud-field-date-time
          api-field-name="AvailableTo"
          required
        />
        <!-- RibbonText -->
        <crud-field-text api-field-name="RibbonText" />
        <!-- OfferIconGraphic -->
        <crud-field-selector-files
          api-field-name="OfferIconGraphic"
          required
        />
        <!-- IconOnTop -->
        <crud-field-switcher api-field-name="IconOnTop" />
        <!-- SplitVipTopup -->
        <crud-field-switcher api-field-name="SplitVipTopup" />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="8">
        <template
          v-for="i in 15"
          :key="i"
        >
          <fields-col :sm="16">
            <CrudFieldSelectorOffers
              :api-field-name="`Offer${i}`"
              :label="`Offer ${i}`"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Offer${i}Priority`"
              label="Sort priority"
            />
          </fields-col>
        </template>
      </fields-col>
      <fields-col :sm="8">
        <!-- ShowHome -->
        <crud-field-switcher
          api-field-name="ShowHome"
          label="Show on home"
        />
        <!-- ShowBattleevent -->
        <crud-field-switcher
          api-field-name="ShowBattleevent"
          label="Show in Battle event"
        />
        <!-- BattleEvent -->
        <CrudFieldSelectorBattleEvents
          v-show="form.ShowBattleevent"
          api-field-name="BattleEvent"
        />
        <!-- ShowQuestevent -->
        <crud-field-switcher
          api-field-name="ShowQuestevent"
          label="Show in Quest event"
        />
        <!-- QuestGroup -->
        <CrudFieldSelectorQuestGroups
          v-show="form.ShowQuestevent"
          api-field-name="QuestGroup"
        />
        <!-- ShowForge -->
        <crud-field-switcher
          api-field-name="ShowForge"
          label="Show in forge"
        />
        <!-- ShowCity -->
        <crud-field-switcher
          api-field-name="ShowCity"
          label="Show in city"
        />
        <!-- ShowMarketplace -->
        <crud-field-switcher
          api-field-name="ShowMarketplace"
          label="Show on marketplace"
        />
        <!-- ShowBattlecampaign -->
        <crud-field-switcher
          api-field-name="ShowBattlecampaign"
          label="Show in battlecampaign"
        />
        <!-- ShowTavern -->
        <crud-field-switcher
          api-field-name="ShowTavern"
          label="Show in tavern"
        />
        <!-- ShowGuildhome -->
        <crud-field-switcher
          api-field-name="ShowGuildhome"
          label="Show on guildhome"
        />
        <!-- ShowBarberShop -->
        <crud-field-switcher
          api-field-name="ShowBarberShop"
          label="Show in barber shop"
        />
        <!-- ShowBoutique -->
        <crud-field-switcher
          api-field-name="ShowBoutique"
          label="Show in boutique"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
