<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { Delete, Download, Picture as PictureError, Plus, ZoomIn } from '@element-plus/icons-vue'

export default {
  name: 'PreviewFileInput',
  components: {
    Plus,
    PictureError,
    Download,
    Delete,
    ZoomIn,
    FilesUploaderDrawer: defineAsyncComponent(() => import('!/components/drawers/FilesUploaderDrawer.vue'))
  },
  props: {
    uploadTitle: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    downloadFileName: {
      type: String,
      default: ''
    },
    api: {
      type: String,
      default: '/admin/api/binary/upload/simple/'
    },
    apiData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:model-value'],
  setup(props, context) {
    const isDevEnv = import.meta.env.DEV
    const image = ref(null)
    const uploadDrawer = ref(false)
    const fieldModel = computed({
      get() {
        return props.modelValue || ''
      },
      set(val) {
        context.emit('update:model-value', val || '')
      }
    })
    return {
      isDevEnv,
      image,
      uploadDrawer,
      fieldModel,
      onClick: () => {
        if (!props.disabled) {
          uploadDrawer.value = true
        }
      },
      preview: () => {
        image.value.$el.getElementsByClassName('el-image__inner')[0].click()
      },
      onUpload: (data) => {
        if (data?.url) {
          fieldModel.value = data.url
        }
      }
    }
  },
  methods: {
    download() {
      this.$utils.saveUrlToFile(this.fieldModel, this.downloadFileName)
    }
  }
}
</script>

<template>
  <div
    class="gs-preview-file-input"
    :class="{ empty: !fieldModel }"
  >
    <div
      class="el-input gs-input-border w-auto"
      :class="{
        'is-disabled': disabled
      }"
    >
      <el-image
        ref="image"
        :src="fieldModel"
        class=""
        :preview-src-list="[fieldModel]"
        hide-on-click-modal
        fit="cover"
      >
        <template #error>
          <div
            v-if="fieldModel"
            class="gs-user-font absolute inset-px grid content-center justify-center bg-red-100"
          >
            <div class="font-related-x3em">
              <el-icon class="text-red-500">
                <PictureError />
              </el-icon>
            </div>
          </div>
          <div
            v-else
            class="gs-user-font absolute inset-px grid content-center justify-center active:bg-neutral-100"
            :class="{ 'cursor-pointer bg-neutral-50': !disabled }"
            @click="onClick"
          >
            <div class="font-related-x2em">
              <el-icon
                class="transition-transform"
                :class="{ 'hover:rotate-180': !disabled }"
              >
                <Plus />
              </el-icon>
            </div>
          </div>
        </template>
      </el-image>
      <div
        v-if="modelValue"
        class="actions absolute left-0 right-0 bottom-0 grid grid-cols-3 content-center justify-items-center overflow-hidden bg-black/60 px-2.5 text-center text-center text-white transition-all"
      >
        <el-icon
          class="font-related-xxl mr-2 cursor-pointer leading-none active:text-cyan-200"
          @click="preview"
        >
          <ZoomIn />
        </el-icon>
        <el-icon
          v-if="!disabled"
          class="font-related-xxl mr-2 cursor-pointer leading-none active:text-cyan-200"
          @click="download"
        >
          <Download />
        </el-icon>
        <el-icon
          v-if="!disabled"
          class="font-related-xxl mr-2 cursor-pointer leading-none active:text-cyan-200"
          @click="fieldModel = ''"
        >
          <Delete />
        </el-icon>
      </div>
    </div>
    <FilesUploaderDrawer
      v-if="uploadDrawer"
      v-model:show="uploadDrawer"
      simple
      accept=".jpg,.png"
      :api="api"
      :api-data="apiData"
      :title="uploadTitle"
      @change="onUpload"
    />
  </div>
</template>

<style scoped>
.gs-preview-file-input {
  .el-image {
    width: calc(var(--gs-size-font) * 9);
    height: calc(var(--gs-size-font) * 9);
  }
  .actions {
    height: 0;
  }
  &:not(.empty) {
    &:hover {
      .actions {
        height: 2.5em;
      }
    }
  }
  ,
  .el-input {
    &.is-disabled {
      background-color: var(--el-disabled-bg-color);
      border-color: var(--el-disabled-border-color);
      color: var(--el-disabled-text-color);
      cursor: not-allowed;
    }
  }
}
</style>
