<script>
export default {
  name: 'FieldsLabel',
  props: {
    label: {
      type: [String, Boolean],
      default: ''
    },
    classLabel: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: true
    },
    medium: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <div
    class="gs-font-scaled inline-block"
    :class="{ 'w-full': fullWidth }"
  >
    <div
      v-if="label"
      class="font-related-xss whitespace-nowrap"
      :class="[
        {
          'pl-2 leading-tight text-neutral-400': small && !(medium || large),
          'pb-1 pl-2 font-medium text-neutral-500 text-[0.8em]': medium && !large,
          'py-1.5 pl-2 font-medium text-neutral-500 text-[0.9em]': large
        },
        classLabel
      ]"
    >
      {{ label }}
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
