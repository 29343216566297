<script setup></script>

<template>
  <crud-details-page
    api="events/boss-setting"
    disable-top-margin
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- BossKind -->
        <crud-field-select
          api-field-name="BossKind"
          options-enum="enums.BattleEventBossKindGuild"
          required
        />
        <!-- Level -->
        <crud-field-number
          api-field-name="Level"
          required
        />
        <!-- RenewTimeMinutes -->
        <crud-field-number
          api-field-name="RenewTimeMinutes"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Damage -->
        <crud-field-number api-field-name="Damage" />
        <!-- Defence -->
        <crud-field-number api-field-name="Defence" />
        <!-- Hp -->
        <crud-field-number api-field-name="Hp" />
        <!-- Magic -->
        <crud-field-number api-field-name="Magic" />
        <!-- CritChance -->
        <crud-field-number api-field-name="CritChance" />
        <!-- CritValue -->
        <crud-field-number api-field-name="CritValue" />
        <!-- Dodge -->
        <crud-field-number api-field-name="Dodge" />
        <!-- Wait -->
        <crud-field-number api-field-name="Wait" />
      </fields-col>
      <fields-col :sm="8">
        <!-- RewardExp -->
        <crud-field-number api-field-name="RewardExp" />
        <!-- RewardGold -->
        <crud-field-number api-field-name="RewardGold" />
        <!-- RewardItemexp -->
        <crud-field-number
          api-field-name="RewardItemexp"
          label="Reward Itemexp (Eternium)"
        />
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-items
            :sm="16"
            :api-field-name="`RewardItem${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`RewardItem${i}Amount`"
            label="Amount"
          />
        </template>
        <!-- GuildBossKillExtraScore -->
        <crud-field-number api-field-name="GuildBossKillExtraScore" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
