<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_4/tip_4_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_4/tip_4_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_4/tip_4_3.png'
import TipImg4 from '!/pages/tools/ap-tips/tips/tip_4/tip_4_4.png'
import TipImg5 from '!/pages/tools/ap-tips/tips/tip_4/tip_4_5.png'

export default {
  data() {
    return {
      srcList: [TipImg1, TipImg2, TipImg3, TipImg4, TipImg5]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      By default, number fields cannot be empty (they are not nullable). The default value is zero.<br>
      Nullable number fields are marked with an eraser icon in label.<br>
      It is possible not to display zero values. This is set in the global user settings (does not apply to nullable fields)<br>
    </p>
  </div>
</template>

<style scoped></style>
