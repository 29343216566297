<script setup></script>

<template>
  <crud-details-page
    api="bosses/reward"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <!-- Name -->
        <crud-field-text
          :sm="8"
          api-field-name="Name"
          required
        />
        <!-- Labels -->
        <crud-field-text
          :sm="8"
          api-field-name="Labels"
          required
        />
      </fields-col>
      <fields-col
        v-for="i in 6"
        :key="i"
      >
        <fields-col>
          <section-divider>Reward #{{ i }}</section-divider>
        </fields-col>
        <fields-col :sm="3">
          <!-- PlaceMin -->
          <crud-field-number
            :api-field-name="`Place${i}Min`"
            label="Place min"
          />
          <!-- PlaceMax -->
          <crud-field-number
            :api-field-name="`Place${i}Max`"
            label="Place max"
          />
          <!-- Gold -->
          <crud-field-number
            :api-field-name="`Gold${i}`"
            label="Gold"
          />
          <!-- Premium -->
          <crud-field-number
            :api-field-name="`Premium${i}`"
            label="Premium"
          />
        </fields-col>
        <fields-col :sm="6">
          <!-- Name -->
          <crud-field-text
            :api-field-name="`Tier${i}Name`"
            label="Name"
          />
          <!-- TierAsset -->
          <crud-field-selector-files
            :api-field-name="`TierAsset${i}`"
            label="Tier asset"
          />
          <!-- ChestAsset -->
          <crud-field-selector-files
            :api-field-name="`ChestAsset${i}`"
            label="Chest asset"
          />
        </fields-col>
        <fields-col :sm="15">
          <template
            v-for="j in 3"
            :key="j"
          >
            <!-- RewardItem -->
            <crud-field-selector-items
              :sm="12"
              :api-field-name="`Reward${i}Item${j}`"
              :label="`Item #${j}`"
            />
            <!-- RewardItemAmount -->
            <crud-field-number
              :sm="6"
              :api-field-name="`Reward${i}Item${j}Amount`"
              :label="`Item #${j} amount`"
            />
            <!-- ItemPinned -->
            <crud-field-switcher
              :sm="6"
              :api-field-name="`Item${i}Pinned`"
              :label="`Item #${j} pinned`"
            />
          </template>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
