<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectActiveBoostFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions, buffAssetsOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      buffAssetsOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'activeboost',
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'buff-asset': 'no_icon',
      'duration': 0,
      'value-min': 0,
      'value-max': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Activeboost_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- buff-asset -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.buff-asset`"
          label="Buff asset"
          slim
          :clearable="false"
          :options="buffAssetsOptions"
          doc-field-name="Activeboost_Buff_asset"
          disable-doc-settings
        />
      </div>
      <!-- duration -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.duration`"
          label="Duration"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Activeboost_Duration"
          disable-doc-settings
        />
      </div>
      <!-- empty -->
      <div class="col-w-1" />
      <!-- ch_balls -->
      <div class="w-64 p-3">
        <crud-field-slot
          :api-field-name="`effectRows.${rowIndex}.data.ch_balls`"
          label="Balls"
          doc-field-name="Activeboost_Balls"
          disable-doc-settings
        >
          <template #default>
            <el-checkbox-group v-model="form.effectRows[rowIndex].data.ch_balls">
              <table class="w-full">
                <tr>
                  <td>
                    <el-checkbox :label="1">
                      1-ball
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="2">
                      2-ball
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="4">
                      4-ball
                    </el-checkbox>
                  </td>
                </tr>
              </table>
            </el-checkbox-group>
          </template>
        </crud-field-slot>
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Activeboost_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-range -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="Activeboost_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="Activeboost_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- value-min -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.value-min`"
          label="Value min"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Activeboost_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- value-max -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.value-max`"
          label="Value max"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Activeboost_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-value`"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Activeboost_Increment_per_level"
          disable-doc-settings
        />
      </div>
      <!-- ch_colors -->
      <div class="w-64 p-3">
        <crud-field-slot
          :api-field-name="`effectRows.${rowIndex}.data.ch_colors`"
          label="Colors"
          doc-field-name="Activeboost_Colors"
          disable-doc-settings
        >
          <template #default>
            <el-checkbox-group v-model="form.effectRows[rowIndex].data.ch_colors">
              <table class="w-full">
                <tr>
                  <td>
                    <el-checkbox :label="1">
                      blue
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="2">
                      white
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="4">
                      red
                    </el-checkbox>
                  </td>
                </tr>
              </table>
            </el-checkbox-group>
          </template>
        </crud-field-slot>
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
