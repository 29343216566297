<script>
import ApTip1 from '!/pages/tools/ap-tips/tips/tip_1/Tip_1.vue'
import ApTip2 from '!/pages/tools/ap-tips/tips/tip_2/Tip_2.vue'
import ApTip3 from '!/pages/tools/ap-tips/tips/tip_3/Tip_3.vue'
import ApTip4 from '!/pages/tools/ap-tips/tips/tip_4/Tip_4.vue'
import ApTip5 from '!/pages/tools/ap-tips/tips/tip_5/Tip_5.vue'
import ApTip6 from '!/pages/tools/ap-tips/tips/tip_6/Tip_6.vue'
import ApTip7 from '!/pages/tools/ap-tips/tips/tip_7/Tip_7.vue'
import ApTip8 from '!/pages/tools/ap-tips/tips/tip_8/Tip_8.vue'

export default {
  components: {
    ApTip1,
    ApTip2,
    ApTip3,
    ApTip4,
    ApTip5,
    ApTip6,
    ApTip7,
    ApTip8
  },
  data() {
    return {
      tips: [
        { ID: 1, tags: 'forms, fields, date picker', description: 'additional date picker functionalities', date: '2023-02-13' },
        { ID: 2, tags: 'settings, entities, table', description: 'possible global user settings', date: '2023-02-13' },
        { ID: 3, tags: 'menu, favorite pages', description: 'add pages to favorites', date: '2023-02-13' },
        { ID: 4, tags: 'forms, fields, numbers', description: 'fields numbers values', date: '2023-02-13' },
        {
          ID: 5,
          tags: 'forms, fields, documentation, notes',
          description: 'creating documentation of forms and notes',
          date: '2023-05-04'
        },
        {
          ID: 6,
          tags: 'forms, fields, documentation, validation, numbers, settings, duplicate',
          description: 'form field documentation and settings;',
          date: '2023-06-21'
        },
        {
          ID: 7,
          tags: 'multi, select, rows, export',
          description: 'multi select rows; actions on selected rows ',
          date: '2023-06-21'
        },
        {
          ID: 8,
          tags: 'multi, duplicate',
          description: 'multi duplication rows;',
          date: '2023-08-28'
        }
      ],
      pagination: {
        currentPage: 1,
        onPage: 25
      },
      filters: {},
      total: 0,
      loading: false
    }
  },
  computed: {
    rowsPage() {
      const filterParams = Object.entries(this.filters)
      let rows = []
      if (filterParams.length) {
        rows = this.tips.filter((tip) => {
          return !filterParams.some(([fieldProp, filterValue]) => {
            if (['description', 'date', 'tags'].includes(fieldProp)) {
              if (!String(tip[fieldProp]).includes(filterValue)) {
                return true
              }
            } else if (['ID'].includes(fieldProp)) {
              if (filterValue * 1 !== tip[fieldProp] * 1) {
                return true
              }
            } else if (filterValue !== tip[fieldProp]) {
              return true
            }
            return false
          })
        })
      } else {
        rows = this.tips
      }
      const total = rows.length
      return {
        rows: rows.slice(
          this.pagination.currentPage * this.pagination.onPage - this.pagination.onPage,
          this.pagination.currentPage * this.pagination.onPage
        ),
        total
      }
    }
  },
  methods: {
    onFetchData(ev) {
      this.loading = true
      this.pagination = { ...ev.pagination }
      this.filters = { ...ev.params }
      this.$utils.nextLoopEvent().then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<template>
  <crud-table
    :versioned="false"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    :action-col-settings="false"
    :columns="{ description: 'string', date: 'string', tags: 'string' }"
    :columns-settings="{
      ID: {
        elAttr: {
          sortable: false
        }
      },
      description: { width: 300 },
      date: { width: 100, header: 'Added' },
      tags: { width: 200 }
    }"
    :rows-on-page="25"
    :rows-on-page-list="[25, 50]"
    :enable-cell-edit-dialogs="false"
    :rows="rowsPage.rows"
    :rows-total="rowsPage.total"
    hide-columns-selector
    :loading="loading"
    @fetch-data="onFetchData"
  >
    <template #expand_row="{ row }">
      <component :is="`ApTip${row.ID}`" />
    </template>
  </crud-table>
</template>
