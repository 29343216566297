<script>
export default {
  name: 'CrudFieldSlot',
  props: {
    /**
     * props used by el-col component (like span, offset)
     * https://element-plus.org/en-US/component/layout.html
     */
    span: {
      type: Number,
      default: undefined
    },
    xs: {
      type: [Number, Boolean],
      default: undefined
    },
    sm: {
      type: [Number, Boolean],
      default: undefined
    },
    offset: {
      type: Number,
      default: undefined
    },
    push: {
      type: Number,
      default: undefined
    },
    pull: {
      type: Number,
      default: undefined
    },
    minHeight: {
      type: [Number, Boolean],
      default: 48
    },
    colClass: {
      type: [String, Object],
      default: undefined
    },
    /** Config props */
    label: {
      type: [String, Boolean],
      default: undefined
    },
    /** !Not defined props ($attr) are used as component field props */
    classComponent: {
      type: [String, Object],
      default: undefined
    },
    /** removes the bottom margin */
    slim: {
      type: Boolean,
      default: false
    },
    emptyLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    component="empty-slot"
    :external-errors="{}"
    :form="{}"
    api-field-name=""
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
