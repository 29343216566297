<script setup>
import { inject } from 'vue'
import { DocumentCopy, Download } from '@element-plus/icons-vue'

const crudTable = inject('crudTable')
</script>

<template>
  <el-tooltip
    v-if="crudTable.usedTopActions?.excelExport && !crudTable.selectMode"
    effect="light"
    placement="top"
    content="export"
    :show-after="600"
  >
    <el-dropdown v-if="crudTable.versioned || (crudTable.crudSettings.crudSelectIdsMode && crudTable.crudSelectIdsLength)">
      <el-button
        :size="$store.getters['auth/userScaledMediumSize']"
        class="gs-btn-outlined-primary-light gs-loading relative mx-1 pl-1 pr-5"
        :loading="crudTable.loadingActions.excelExport"
        :disabled="!crudTable.total || crudTable.total > crudTable.excelExportSettings.maxRows"
      >
        <icon-ify
          icon="file-icons:microsoft-excel"
          class="gs-scaled-icon-xs"
        />
        <icon-ify
          icon="akar-icons:arrow-forward-thick"
          class="absolute right-0.5 top-1 h-3 w-3"
        />
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <template v-if="crudTable.versioned">
            <el-dropdown-item
              v-for="item in crudTable.excelExportItems"
              :key="item"
              :divided="item === 'selected'"
            >
              <div class="gs-font-scaled w-16">
                {{ item }}
              </div>
              <div class="mr-1">
                <el-button
                  plain
                  type="primary"
                  :size="$store.getters['auth/userScaledMediumSize']"
                  class="px-1"
                  @click="crudTable.excelExportVersioned(item)"
                >
                  <el-icon class="gs-scaled-icon-xss m-0">
                    <DocumentCopy />
                  </el-icon>
                </el-button>
              </div>
              <div class="ml-2">
                <el-button
                  plain
                  type="primary"
                  :size="$store.getters['auth/userScaledMediumSize']"
                  class="px-1"
                  @click="crudTable.excelExportVersionedToFile(item)"
                >
                  <el-icon class="gs-scaled-icon-xss m-0">
                    <Download />
                  </el-icon>
                </el-button>
              </div>
            </el-dropdown-item>
          </template>
          <template v-else>
            <el-dropdown-item>
              <div class="gs-font-scaled w-16">
                filtered
              </div>
              <div class="mr-1">
                <el-button
                  plain
                  type="primary"
                  :size="$store.getters['auth/userScaledMediumSize']"
                  class="px-1"
                  @click="crudTable.excelExport(false)"
                >
                  <el-icon class="gs-scaled-icon-xss m-0">
                    <DocumentCopy />
                  </el-icon>
                </el-button>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="gs-font-scaled w-16">
                selected
              </div>
              <div class="mr-1">
                <el-button
                  plain
                  type="primary"
                  :size="$store.getters['auth/userScaledMediumSize']"
                  class="px-1"
                  @click="crudTable.excelExport(true)"
                >
                  <el-icon class="gs-scaled-icon-xss m-0">
                    <DocumentCopy />
                  </el-icon>
                </el-button>
              </div>
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button
      v-else
      size="small"
      class="gs-btn-outlined-primary-light gs-loading relative mx-1 pl-1 pr-5"
      :loading="crudTable.loadingActions.excelExport"
      :disabled="!crudTable.total || crudTable.total > crudTable.excelExportSettings.maxRows"
      @click="crudTable.excelExport(false)"
    >
      <icon-ify
        icon="file-icons:microsoft-excel"
        class="h-5 w-5"
      />
      <icon-ify
        icon="akar-icons:arrow-forward-thick"
        class="absolute right-0.5 top-1 h-3 w-3"
      />
    </el-button>
  </el-tooltip>
</template>

<style scoped></style>
