<script>
import { ref } from 'vue'
import dayjs from 'dayjs'
import { userRightRoles } from '!/composition/utilities'

export default {
  props: {
    menuItem: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      userRightRoles
    }
  },
  data() {
    return {
      form: {},
      loading: {},
      log: '',
      isHtmlLog: false,
      logError: false,
      copyLog: '',
      isHtmlCopyLog: false,
      copyLogError: false,
      isDev: import.meta.env.PROD === true && import.meta.env.VITE_TIMESHIFT !== 'on',
      isTimeshift: import.meta.env.VITE_TIMESHIFT === 'on',
      timeshiftAccess: false,
      dateInput: ref(''),
      env: '',
      envApi: '',
      ccBlockade: null
    }
  },
  created() {
    this.timeshiftAccess = this.$utils.checkRights(userRightRoles.timeshift)
    if (this.isDev) {
      this.env = 'dev'
    } else if (this.isTimeshift) {
      this.env = 'timeshift'
    }
    if (this.timeshiftAccess && this.env !== '') {
      const envApi = this.$store.getters['auth/envs']?.[this.env]
      if (typeof envApi !== 'undefined') {
        this.envApi = envApi
      }
    }
    this.getClearCacheBlockade()
  },
  methods: {
    clearCache(endPoint, name) {
      this.loading[name] = true
      this.log = ''
      this.logError = false
      this.$axios
        .get(endPoint, {})
        .then(({ data }) => {
          try {
            if (data?.logs) {
              this.log = data.logs
              this.isHtmlLog = true
            } else {
              this.log = JSON.stringify(data, null, '\t')
            }
            this.logError = data?.status !== 'ok'
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.loading[name] = false
          this.$message({
            message: `${name} cache cleared`,
            type: 'success',
            offset: 40
          })
        })
        .catch((data) => {
          try {
            if (data?.response?.data?.errors?.[0]) {
              this.log = data.response.data.errors[0]
              this.isHtmlLog = true
            } else {
              this.log = JSON.stringify(data?.response || data, null, '\t')
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.logError = true
          this.loading[name] = false
          this.$message({
            message: `${name} cache failed`,
            type: 'warning',
            offset: 40
          })
        })
    },
    changeTime() {
      if (!this.envApi) {
        return false
      }

      this.$refs?.form
        ?.validate?.()
        .then(() => {
          this.$axios
            .get(`${this.envApi}/dev/timeshift/`, {
              params: {
                date: dayjs(this.dateInput).format('YYYY-MM-DD_HH:mm:ss')
              }
            })
            .then(({ data }) => {
              this.notify(data)
            })
            .catch((err) => {
              this.$utils.catchError(err)
            })
        })
        .catch(() => {})
    },
    copyDbToTs() {
      if (!this.envApi) {
        return false
      }

      this.loading.copyDb = true
      this.copyLog = ''
      this.copyLogError = false

      this.$axios
        .get(`${this.envApi}/dev/duptimeshiftdb/`, {})
        .then(({ data }) => {
          try {
            if (data?.logs) {
              this.copyLog = data.logs
              this.isHtmlCopyLog = true
            } else {
              this.copyLog = JSON.stringify(data, null, '\t')
            }
            this.copyLogError = data?.status !== 'ok'
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.loading.copyDb = false
          this.$message({
            message: 'Database duplicated',
            type: 'success',
            offset: 40
          })
        })
        .catch((data) => {
          try {
            if (data?.response?.data?.errors?.[0]) {
              this.copyLog = data.response.data.errors[0]
              this.isHtmlCopyLog = true
            } else {
              this.copyLog = JSON.stringify(data?.response || data, null, '\t')
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.copyLogError = true
          this.loading.copyDb = false
          this.$message({
            message: 'Database copy failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    getClearCacheBlockade() {
      this.$axios
        .get('/admin/api/getclearcacheblockade/', {})
        .then(({ data }) => {
          try {
            if (data?.blockade) {
              this.ccBlockade = data.blockade
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        })
        .catch(() => {})
    },
    blockClearCache() {
      this.loading.blockClearCache = true

      this.$axios
        .get('/admin/api/blockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.getClearCacheBlockade()
          this.$message({
            message: 'Clear cache blocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while setting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    unblockClearCache() {
      this.loading.blockClearCache = true

      this.$axios
        .get('/admin/api/unblockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.ccBlockade = null
          this.$message({
            message: 'Clear cache unblocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while unsetting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    notify(message = 'success') {
      this.$message({
        message,
        type: 'success',
        offset: 40
      })
    }
  }
}
</script>

<template>
  <template v-if="timeshiftAccess && (isDev || isTimeshift)">
    <fields-col>
      <el-divider>Timeshift actions</el-divider>
    </fields-col>
    <template v-if="isTimeshift">
      <el-row>
        <el-form
          ref="form"
          :model="$data"
          label-position="top"
          style="min-width: 1000px"
        >
          <fields-col :span="8">
            <el-col>
              <crud-field-date-time
                api-field-name="dateInput"
                required
                :form="$data"
                :external-errors="{}"
              />
              <el-popconfirm
                title="Are you sure?"
                :teleported="false"
                @confirm="changeTime"
              >
                <template #reference>
                  <el-button
                    type="primary"
                    class="m-5"
                  >
                    Change date and time
                  </el-button>
                </template>
              </el-popconfirm>
            </el-col>
          </fields-col>
        </el-form>
      </el-row>
    </template>
    <template v-if="isDev">
      <el-row>
        <el-col>
          <el-popconfirm
            title="Are you sure?"
            :teleported="false"
            @confirm="copyDbToTs"
          >
            <template #reference>
              <el-button
                type="primary"
                class="m-5"
                :loading="loading.copyDb"
              >
                Copy DB from DEV to Timeshift
              </el-button>
            </template>
          </el-popconfirm>
        </el-col>
        <transition name="el-fade-in-linear">
          <div
            v-if="copyLog.length"
            class="gs-font-scaled px-5"
          >
            <el-divider content-position="left">
              DB copy log
            </el-divider>
            <div
              v-if="isHtmlCopyLog"
              class="mx-5 p-5"
              :class="[copyLogError ? 'bg-red-100' : 'bg-green-100']"
              v-html="copyLog"
            />
            <pre
              v-else
              class="mx-5 p-5"
              :class="[copyLogError ? 'bg-red-100' : 'bg-green-100']"
            >{{ copyLog }}</pre>
          </div>
        </transition>
      </el-row>
    </template>
  </template>
  <template v-if="ccBlockade === null">
    <el-popconfirm
      title="Are you sure?"
      :teleported="false"
      @confirm="blockClearCache"
    >
      <template #reference>
        <el-button
          type="primary"
          class="m-5"
          :loading="loading.blockClearCache"
        >
          BLOCK CC
        </el-button>
      </template>
    </el-popconfirm>
  </template>
  <template v-if="ccBlockade !== null">
    <el-tooltip
      class="box-item"
      placement="top"
      :content="`Clear cache blocked by: ${ccBlockade.AdminUser}`"
    >
      <span>
        <el-popconfirm
          title="Are you sure?"
          :teleported="false"
          @confirm="unblockClearCache"
        >
          <template #reference>
            <el-button
              type="primary"
              class="m-5"
              :loading="loading.blockClearCache"
            >
              UNBLOCK CC
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </el-tooltip>
  </template>
  <el-button
    type="warning"
    :loading="loading.DEV"
    class="m-5"
    :disabled="ccBlockade !== null"
    @click="clearCache('/admin/api/clearcache/', 'DEV')"
  >
    CLEAR DEV
  </el-button>
  <el-button
    type="danger"
    :loading="loading.PROD"
    class="m-5"
    :disabled="ccBlockade !== null"
    @click="clearCache('/admin/api/clearcacheprod/', 'PROD')"
  >
    CLEAR PROD
  </el-button>
  <transition name="el-fade-in-linear">
    <div
      v-if="log.length"
      class="gs-font-scaled px-5"
    >
      <el-divider content-position="left">
        Log
      </el-divider>
      <div
        v-if="isHtmlLog"
        class="mx-5 p-5"
        :class="[logError ? 'bg-red-100' : 'bg-green-100']"
        v-html="log"
      />
      <pre
        v-else
        class="mx-5 p-5"
        :class="[logError ? 'bg-red-100' : 'bg-green-100']"
      >{{ log }}</pre>
    </div>
  </transition>
</template>
