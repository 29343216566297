<script>
import { shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import PageManu from '!/components/page-layout/menu/PageMenu.vue'
import LabelMenu from '!/components/page-layout/menu/PageMenuLabel.vue'
import ItemMenu from '!/components/page-layout/menu/PageMenuItem.vue'
import SubMenu from '!/components/page-layout/menu/PageMenuSubmenu.vue'
import PageMenuFavorite from '!/components/page-layout/menu/PageMenuFavorite.vue'
import { globalProperties } from '!/plugins/utilities'

const isSubmenuVisible = function (menuItem) {
  for (const route of menuItem.routes) {
    const accessRole = route?.meta?.accessRole
    if (!accessRole || globalProperties.$utils.checkRights(accessRole)) {
      return true
    }
  }
  return false
}

export default {
  name: 'AdminPanelMenu',
  components: { PageManu, LabelMenu, ItemMenu, SubMenu, PageMenuFavorite },
  setup() {
    const router = useRouter()
    const orderByPriority = (a, b) => {
      if (a.meta.priority === b.meta.priority) {
        return 0
      }
      return a.meta.priority > b.meta.priority ? -1 : 1
    }
    const menuItems = shallowRef(
      router.options.menuItems
        .map((item) => {
          item.routes = item.routes.sort(orderByPriority)
          return item
        })
        .sort(orderByPriority)
    )
    return {
      menuItems,
      isSubmenuVisible
    }
  },
  computed: {}
}
</script>

<template>
  <PageManu allow-collapsed>
    <template
      v-for="menuItem in menuItems"
      :key="menuItem.idMenuItem"
    >
      <template v-if="menuItem?.component">
        <component
          :is="menuItem.component"
          v-if="!!$store.getters['auth/userLocalSettings']?.ccd"
          menu-item
        />
      </template>
      <template v-else>
        <SubMenu
          v-if="menuItem.isSubmenu && isSubmenuVisible(menuItem)"
          :id="menuItem.idMenuItem"
          :title="menuItem.label"
          :icon="menuItem.meta.icon"
          :class-icon="menuItem.meta.cssClass"
        >
          <ItemMenu
            v-for="route in menuItem.routes"
            :key="route.name"
            :router="route.name"
            :title="route.meta?.menuLabel"
            :icon-right="route.meta.icon ?? ''"
            :class-icon="route.meta.cssClass"
            :forced-id="route.meta?.forcedId"
          />
        </SubMenu>
        <template v-if="!menuItem.isSubmenu">
          <ItemMenu
            v-for="route in menuItem.routes"
            :key="route.name"
            :router="route.name"
            :icon="menuItem?.meta?.icon || route.meta.icon"
            :title="menuItem?.label || route.meta?.menuLabel"
            :class-icon="route?.meta?.cssClass || route.meta.cssClass"
            :forced-id="route.meta?.forcedId"
          />
        </template>

        <PageMenuFavorite v-if="menuItem.idMenuItem === 'dashboard'" />
      </template>
    </template>
    <!-- or remove not used label-menu and import :) -->
    <LabelMenu v-if="false">
      test label
    </LabelMenu>
  </PageManu>
</template>

<style lang="postcss">
.gs-light-menu-icon {
  g {
    stroke-width: 2;
    stroke: currentColor !important;
  }
  &-x2 {
    g {
      stroke-width: 2;
      stroke: currentColor !important;
    }
  }
}
</style>
