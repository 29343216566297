<template>
  <crud-details-page
    api="settings/vip-status"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Code -->
        <crud-field-number
          api-field-name="VipLevel"
          required
        />
        <!-- ReqPremium -->
        <crud-field-number
          api-field-name="ReqPremium"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- MoreLevelsSuperUpgrade -->
        <crud-field-number api-field-name="MoreLevelsSuperUpgrade" />
        <!-- PurchasePremiumPer -->
        <crud-field-number
          api-field-name="PurchasePremiumPer"
          label="Purchase premium normal gem packs [per]"
        />
        <!-- BeSkipBosses -->
        <crud-field-switcher
          api-field-name="BeSkipBosses"
          label="Battle Event - can use skip bosses"
        />
        <!-- BeExtraTicketsCap -->
        <crud-field-number
          api-field-name="BeExtraTicketsCap"
          label="Battle Event - extra tickets cap"
        />
        <!-- BankGoldGetAndCapPer -->
        <crud-field-number
          api-field-name="BankGoldGetAndCapPer"
          label="Bank gold and capacity [per]"
        />
        <!-- SmeltEterniumPer -->
        <crud-field-number
          api-field-name="SmeltEterniumPer"
          label="Smelt Eternium [per]"
        />
        <!-- SmeltEternium2Per -->
        <crud-field-number
          api-field-name="SmeltEternium2Per"
          label="Smelt Eternium2 [per]"
        />
        <!-- SmeltExtraPer -->
        <crud-field-number
          api-field-name="SmeltExtraPer"
          label="Smelt Extra [per]"
        />
        <!-- IdleExpPer -->
        <crud-field-number
          api-field-name="IdleExpPer"
          label="Idle exp [per]"
        />
        <!-- IdleGoldPer -->
        <crud-field-number
          api-field-name="IdleGoldPer"
          label="Idle gold [per]"
        />
        <!-- IdleEterniumPer -->
        <crud-field-number
          api-field-name="IdleEterniumPer"
          label="Idle eternium [per]"
        />
        <!-- IdleEternium2Per -->
        <crud-field-number
          api-field-name="IdleEternium2Per"
          label="Idle eternium2 [per]"
        />
        <!-- PvpFreeFights -->
        <crud-field-number
          api-field-name="PvpFreeFights"
          label="Pvp free fights"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- RewardGold -->
        <crud-field-number api-field-name="RewardGold" />
        <!-- RewardPremium -->
        <crud-field-number api-field-name="RewardPremium" />
        <!-- Rewards -->
        <template
          v-for="i in 3"
          :key="i"
        >
          <crud-field-selector-items
            :api-field-name="`RewardItem${i}`"
            :label="`Reward Item${i}`"
          />
          <crud-field-number :api-field-name="`RewardItem${i}Amount`" />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
