<script>
import { autoFillGraphic } from '@/composition/autoFillGraphic'

export default {
  name: 'GiftboxDetailsPage',
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'giftbox'
        itemData.ApIsSearchable = true
        itemData.PriceGold = 0
        itemData.PricePremium = 0
        itemData.GiftboxLevel = 0
        itemData.SortingOrder = 0
        itemData.ReqLevel = 0
        itemData.GiftGold = 0
        itemData.GiftPremium = 0
        itemData.IsSendable = 0
      }
      if (itemData.GiftItem6 == null) {
        itemData.GiftAmount6 = 0
      }
      return itemData
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    form-class="mt-0"
    enable-relations
    doc-key="items-gift-box"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Admin type -->
        <crud-field-select
          api-field-name="ApType"
          options-enum="enums.AdminPanelTypeItems"
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          @change="autoFillGraphic($event, form, { sd: 'IconSd', hd: 'IconHd', prev: 'Preview' })"
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
        <!-- Chest roll graphic -->
        <crud-field-selector-files
          api-field-name="UchestRollAsset"
          label="Chest roll graphic"
        />
        <!-- Is gear shard -->
        <crud-details-field
          api-field-name="IsGearShard"
          :label="false"
        >
          <template #default>
            <div>
              <el-checkbox
                v-model="form.IsGearShard"
                label="Is gear shard"
              />
            </div>
            <div class="flex items-center">
              <div class="el-form-item__label leading-none">
                Amount:
              </div>
              <el-input-number
                v-model="form.ReqGearShardAmount"
                controls-position="right"
                :disabled="!form.IsGearShard"
                class=""
              />
            </div>
          </template>
        </crud-details-field>
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="6">
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
        <!-- Required level (to show on items list) -->
        <crud-field-number
          label="Required level (to show on items list)"
          api-field-name="ReqLevel"
        />
        <!-- Sorting order -->
        <crud-field-number
          api-field-name="SortingOrder"
          :max="1000000"
        />
        <!-- Is sendable -->
        <crud-field-switcher api-field-name="IsSendable" />
        <!-- Auto open (not working after battle) -->
        <crud-field-switcher
          api-field-name="GiftIsAutoopen"
          label="Auto open (not working after battle)"
        />
        <!-- Show loot chances -->
        <crud-field-switcher api-field-name="LootShowChances" />
        <!-- Is hidden -->
        <crud-field-switcher api-field-name="IsHidden" />
      </fields-col>
      <fields-col :sm="12">
        <!-- Labels -->
        <crud-field-textarea
          sm
          mobile-break
          api-field-name="Labels"
          required
        />
        <!-- Gifts fields cols -->
        <fields-col
          :sm="12"
          mobile-break
        >
          <!-- Gift gold -->
          <crud-field-number api-field-name="GiftGold" />
          <!-- Gift premium -->
          <crud-field-number api-field-name="GiftPremium" />
        </fields-col>
        <fields-col :sm="12">
          <!-- Giftbox level -->
          <crud-field-number api-field-name="GiftboxLevel" />
          <!-- Is uchest -->
          <crud-field-switcher
            api-field-name="IsGiftboxUchest"
            label="Is uchest"
          />
        </fields-col>
        <!-- Items fields -->
        <fields-col>
          <template
            v-for="n in 6"
            :key="n"
          >
            <!-- Item 1-6 -->
            <crud-field-selector-items
              :sm="9"
              :api-field-name="`GiftItem${n}`"
              :label="`Item ${n}`"
              :disabled="form.IsSendable && n > 5"
            />
            <!-- Amount 1-6 -->
            <crud-field-number
              :sm="6"
              :label="`amount ${n}`"
              :api-field-name="`GiftAmount${n}`"
              :disabled="form.IsSendable && n > 5"
            />
            <!-- Loot 1-6 -->
            <crud-field-selector-loots
              :sm="9"
              :api-field-name="`GiftLoot${n}`"
              :label="`Or loot ${n}`"
              :disabled="form.IsSendable"
            />
          </template>
          <!-- IsResetAtBattleEventStart -->
          <crud-field-switcher api-field-name="IsResetAtBattleEventStart" />
        </fields-col>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
