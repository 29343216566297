const CellEdit = {
  data() {
    return {
      cellsEditFormShow: {},
      cellsEditFormFields: {},
      cellsEditErrors: {},
      crudScrollRefer: null,
      editAllColumn: false,
      apiValidationWarningsDetails: {
        warnings: [],
        skip: false
      },
      apiValidationWarningsDialog: false
    }
  },
  mounted() {
    this.crudScrollRefer = this.$refs.crudTable?.$el?.querySelector?.('.el-scrollbar__wrap')
  },
  methods: {
    showCellEditDialog(row, column) {
      if (this.selectMode) {
        return false
      }
      this.clearCells()
      if (this.tableColumns?.[column.property]?._cellEdit) {
        this.cellsEditFormFields[`${row[this.indexColumn]}_${column.property}`] = row?.[column.property]
        this.cellsEditFormShow[`${row[this.indexColumn]}_${column.property}`] = true
      }
    },
    cancelCellEditDialog() {
      this.clearCells()
    },
    saveCellEditDialog(row, columnApiName, index = null) {
      const keyValue = `${row[this.indexColumn]}_${columnApiName}`
      const value = this.cellsEditFormFields[keyValue]
      const prevValue = row[columnApiName]
      this.loadingActions[keyValue] = true
      if ((value?.ID ? value.ID : value) === (prevValue?.ID ? prevValue.ID : prevValue)) {
        this.finishEditCell(keyValue, index, columnApiName)
      } else {
        const apiParams = {
          entity: this.crudEntity,
          field: columnApiName,
          id: row[this.indexColumn],
          value: value?.ID ? value.ID : value
        }
        if (this.apiValidationWarningsDetails.skip) {
          apiParams.skip_warnings = true
        }

        this.$axios
          .post(`${this.apiPrefix}entity/edit/`, apiParams)
          .then(() => {
            row[columnApiName] = value
            this.fetchData(() => {
              this.finishEditCell(keyValue, index, columnApiName)
            })
            if (index === null) {
              this.$message({
                message: 'saved',
                type: 'success',
                offset: 40
              })
            }
          })
          .catch((error) => {
            const errMsg = error?.response?.data?.errors || ''
            if (error?.response?.data?.status === 'warning' && error?.response?.data?.warnings?.length) {
              this.apiValidationWarningsDetails = { warnings: error.response.data.warnings }
              this.apiValidationWarningsDialog = true
              this.apiValidationWarningsDetails.close = { method: this.finishEditCell, params: [keyValue, index, columnApiName] }
              this.apiValidationWarningsDetails.save = { method: this.saveCellEditDialog, params: [row, columnApiName, index] }
            } else if (errMsg) {
              this.cellsEditErrors[keyValue] = Object.values(errMsg).shift()
            } else {
              this.$utils.catchError(error)
            }
            delete this.loadingActions[keyValue]
          })
          .then(() => {
            if (this.apiValidationWarningsDetails.skip) {
              this.apiValidationWarningsDetails = {
                warnings: [],
                skip: false
              }
              this.apiValidationWarningsDialog = false
            }
          })
      }
    },
    finishEditCell(keyValue, index, columnApiName) {
      this.cancelCellEditDialog()
      delete this.loadingActions[keyValue]
      if (index !== null && this.tableRows?.[index + 1]?.[this.indexColumn]) {
        this.showCellEditDialog(this.tableRows[index + 1], { property: columnApiName })
        if (index > 3) {
          this.$refs.crudTable.scrollTo({ top: this.crudScrollRefer.scrollTop + 50 })
        }
      }
    },
    clearCells() {
      this.cellsEditFormShow = {}
      this.cellsEditFormFields = {}
      this.cellsEditErrors = {}
      this.editAllColumn = null
    },
    editCellsInColumn(colApiName) {
      this.clearCells()
      if (this.tableColumns?.[colApiName]?._cellEdit) {
        this.editAllColumn = colApiName
        this.tableRows.forEach((row) => {
          this.cellsEditFormFields[`${row[this.indexColumn]}_${colApiName}`] = row?.[colApiName]
          this.cellsEditFormShow[`${row[this.indexColumn]}_${colApiName}`] = true
        })
        this.$utils.nextLoopEvent(100).then(() => {
          this.$refs.crudTable.scrollTo({ top: 0 })
        })
      }
    },
    saveCellsInColumn(colApiName) {
      const savedData = []
      this.tableRows.forEach((row, indexRow) => {
        this.loadingActions.editAllColumn = true
        const keyValue = `${row[this.indexColumn]}_${colApiName}`
        const value = this.cellsEditFormFields[keyValue]
        const prevValue = row[colApiName]
        if ((value?.ID ? value.ID : value) !== (prevValue?.ID ? prevValue.ID : prevValue)) {
          savedData.push({ value, indexRow, keyValue, id: row[this.indexColumn] })
        }
      })
      if (!savedData.length) {
        this.loadingActions.editAllColumn = false
        this.$message({
          message: 'no change data detected',
          type: 'success',
          offset: 40
        })
        this.cancelCellEditDialog()
        return
      }
      const requests = []
      savedData.forEach((savedRow) => {
        const apiParams = {
          entity: this.crudEntity,
          field: colApiName,
          id: savedRow.id,
          value: savedRow.value?.ID ? savedRow.value.ID : savedRow.value
        }
        if (this.apiValidationWarningsDetails.skip) {
          apiParams.skip_warnings = true
        }
        requests.push(this.$axios.post(`${this.apiPrefix}entity/edit/`, apiParams))
      })
      Promise.allSettled(requests).then((results) => {
        const errorRows = {}
        results.forEach((result, index) => {
          if (result.status === 'rejected') {
            errorRows[savedData[index].keyValue] = result
            let errMsg = result?.reason?.response?.data?.errors || ''
            if (result?.reason?.response?.data?.status === 'warning' && result?.reason?.response?.data?.warnings?.length) {
              this.apiValidationWarningsDetails.warnings.push(...result.reason.response.data.warnings)
              this.apiValidationWarningsDialog = true
              this.apiValidationWarningsDetails.close = { method: this.clearCells, params: [] }
              this.apiValidationWarningsDetails.save = { method: this.saveCellsInColumn, params: [colApiName] }
            } else if (errMsg) {
              errMsg = Object.values(errMsg).shift()
            }
            this.cellsEditErrors[savedData[index].keyValue] = errMsg
          } else {
            this.tableRows[savedData[index].indexRow][colApiName] = savedData[index].value
            this.cellsEditFormShow[savedData[index].keyValue] = false
            delete this.cellsEditErrors[savedData[index].keyValue]
          }
        })
        const isError = !!Object.keys(errorRows).length
        this.tableRows.forEach((row) => {
          const keyValue = `${row[this.indexColumn]}_${colApiName}`
          if (!errorRows?.[keyValue]) {
            this.cellsEditFormShow[keyValue] = false
            delete this.cellsEditErrors[keyValue]
          }
        })
        if (isError) {
          this.$message({
            message: 'not everything was saved',
            type: 'warning',
            offset: 40
          })
        } else {
          this.$message({
            message: 'saved',
            type: 'success',
            offset: 40
          })
          if (this.apiValidationWarningsDetails.skip) {
            this.apiValidationWarningsDetails = {
              warnings: [],
              skip: false
            }
            this.apiValidationWarningsDialog = false
          }
          this.clearCells()
          this.fetchData()
        }
        this.loadingActions.editAllColumn = false
      })
    },
    clearError(key) {
      delete this.cellsEditErrors[key]
    }
  }
}

export { CellEdit }
