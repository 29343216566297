import { ElMessage, ElMessageBox, ElNotification, dayjs } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/notification/style/css'

export default {
  install: (Vue) => {
    const globalProperties = Vue.config.globalProperties
    globalProperties.$message = ElMessage
    globalProperties.$messageBox = ElMessageBox
    globalProperties.$notify = ElNotification
    dayjs.Ls.en.weekStart = 1
  }
}
