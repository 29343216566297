<template>
  <crud-details-page
    api="level-rewards/level-reward"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Level -->
        <crud-field-number
          api-field-name="Level"
          required
        />
        <!-- ExpItemsAmount -->
        <crud-field-number
          api-field-name="ExpItemsAmount"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Gold -->
        <crud-field-number api-field-name="Gold" />
        <!-- Premium -->
        <crud-field-number api-field-name="Premium" />
      </fields-col>
      <fields-col :sm="8">
        <!-- Item -->
        <crud-field-selector-items api-field-name="Item" />
        <!-- Amount -->
        <crud-field-number api-field-name="Amount" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
