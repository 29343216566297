<template>
  <crud-details-page
    api="settings/secret-code-reward-by-me"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- ReqScore -->
        <crud-field-text
          api-field-name="ReqScore"
          label="Required score"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Gold -->
        <crud-field-number api-field-name="Gold" />
        <!-- Premium -->
        <crud-field-number api-field-name="Premium" />
      </fields-col>
      <fields-col :sm="8">
        <!-- Item -->
        <crud-field-selector-items api-field-name="Item" />
        <!-- Amount -->
        <crud-field-number api-field-name="Amount" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
