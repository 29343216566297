<script>
import CrudFieldSelectorQuestGroups from '@/components/forms/crud-fields/CrudFieldSelectorQuestGroups.vue'
import CrudFieldSelectorCraftTabs from '@/components/forms/crud-fields/CrudFieldSelectorCraftTabs.vue'
import CrudFieldSelectorCrafts from '@/components/forms/crud-fields/CrudFieldSelectorCrafts.vue'

export default {
  components: { CrudFieldSelectorCrafts, CrudFieldSelectorCraftTabs, CrudFieldSelectorQuestGroups }
}
</script>

<template>
  <crud-details-page
    api="news/top"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Start -->
        <crud-field-date-time
          api-field-name="Start"
          required
        />
        <!-- End -->
        <crud-field-date-time
          api-field-name="End"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
        />
        <!-- Order -->
        <crud-field-number
          api-field-name="Order"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- TargetPlace -->
        <crud-field-select
          api-field-name="TargetPlace"
          options-enum="enums.QuestPlace"
          required
        />
        <!-- TargetCraft -->
        <CrudFieldSelectorCrafts api-field-name="TargetCraft" />
        <!-- TargetCraftTab -->
        <CrudFieldSelectorCraftTabs api-field-name="TargetCraftTab" />
        <!-- TargetSubevent -->
        <crud-field-select
          api-field-name="TargetSubevent"
          options-enum="enums.EventKind"
        />
        <!-- TargetQuestGroup -->
        <CrudFieldSelectorQuestGroups api-field-name="TargetQuestGroup" />
      </fields-col>
      <fields-col :sm="8">
        <!-- ItemsPreviewCsv -->
        <crud-field-text api-field-name="ItemsPreviewCsv" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
