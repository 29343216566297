<template>
  <crud-details-page
    api="payments/ars-product"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- OfferId -->
        <crud-field-text
          api-field-name="OfferId"
          required
        />
        <!-- FirstBuyPremium -->
        <crud-field-number api-field-name="FirstBuyPremium" />
        <!-- DailyPremium -->
        <crud-field-number api-field-name="DailyPremium" />
        <template
          v-for="i in 3"
          :key="i"
        >
          <fields-col :sm="16">
            <crud-field-selector-items
              :api-field-name="`DailyItem${i}`"
              :label="`Daily tem ${i}`"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`DailyItem${i}Amount`"
              :label="`Amount ${i}`"
            />
          </fields-col>
        </template>
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-client-strings
          v-for="i in 2"
          :key="i"
          :api-field-name="`TopTxt${i}`"
          :label="`Top text ${i}`"
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-client-strings
          v-for="i in 10"
          :key="i"
          :api-field-name="`PerkTxt${i}`"
          :label="`Perk text ${i}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
