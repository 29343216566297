<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_5/tip_5_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_5/tip_5_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_5/tip_5_3.png'

export default {
  data() {
    return {
      srcList: [TipImg1, TipImg2, TipImg3]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      <span>
        # Form documentation and notes can be added on forms. This documentation is common (visible) for each instance of the
        form (for different ID's).
      </span>
      <br>
      <span># Notes are visible only for a specific instance of the form (for a specific ID).</span>
      <br>
      <br>
      click on the screenshots to find out more
    </p>
  </div>
</template>

<style scoped></style>
