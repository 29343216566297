<script>
export default {
  props: {
    games: {
      type: Array,
      default: undefined
    }
  },
  emits: ['change'],
  data() {
    return {
      value: undefined,
      options: []
    }
  },
  created() {
    const options = this.games || []
    options.forEach((option) => {
      if (import.meta.env[option.env_variable]) {
        option.api = import.meta.env[option.env_variable]
      }
      if (
        option.api &&
        option.api === this.$store.getters['main/gameApiSettings']?.api &&
        (!this.$route?.query?.__game || option.id === this.$route?.query?.__game)
      ) {
        this.value = option
      }
    })
    this.options = options
    if (!this.value) {
      this.value = this.options.find((option) => {
        return option.api === import.meta.env.VITE_API_URL
      })
    }
  },
  mounted() {},
  methods: {
    onChange(val) {
      this.$store.commit('main/setGameApiSettings', val)
      this.$emit('change', val)
      this.$store.dispatch('auth/reLoadUser', val.api).then(() => {
        const currentRoute = this.$utils.bindStaticParams(this.$router.currentRoute.value)
        this.$router.push({ name: currentRoute.name, params: currentRoute.params, query: currentRoute.query }).then(() => {
          this.$router.go(0)
        })
      })
    }
  }
}
</script>

<template>
  <el-select
    v-if="options.length"
    v-model="value"
    :teleported="false"
    placement="bottom"
    value-key="env_variable"
    @change="onChange"
  >
    <el-option
      v-for="game in options"
      :key="game.env_variable"
      :label="game.name"
      :value="game"
      :disabled="!game.api"
    />
  </el-select>
</template>

<style scoped></style>
