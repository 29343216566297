<script>
import { Delete } from '@element-plus/icons-vue'
import { inject } from 'vue'

export default {
  name: 'EventItemsComponent',
  components: { Delete },
  props: {
    form: {
      type: Object,
      required: true
    },
    externalErrors: {
      type: Object,
      required: true
    },
    noob: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const crudRelations = inject('crudRelations', null)
    return {
      kinds: [
        'noob_week',
        'spend_premium',
        'topup_premium',
        'spend_premium_daily',
        'topup_premium_daily',
        'spin_buy1',
        'spin_buy2',
        'spin_buy3',
        'spin_buy4',
        'spin_buy5',
        'chest_buy1',
        'chest_buy2',
        'chest_buy3',
        'chest_buy4',
        'chest_buy5',
        'chest_buy6',
        'chest_buy7',
        'chest_buy8',
        'talents_loot',
        'Info1',
        'Info2',
        'Info3',
        'Info4',
        'Info5'
      ],
      crudRelations
    }
  },
  computed: {
    items() {
      const keys = {}
      for (const key in this.form.rows) {
        const row = this.form.rows[key]
        if ((this.noob && row.IsNoobItem) || (!this.noob && !row.IsNoobItem)) {
          const kind = row.Kind
          if (!keys[kind]) {
            keys[kind] = []
          }
          keys[kind].push(Number.parseInt(key))
        }
      }
      return keys
    }
  },
  methods: {
    deleteItem(key) {
      const rowID = this.$props.form?.rows?.[key]?.ID
      if (rowID) {
        Object.keys(this.crudRelations).forEach((relationKey) => {
          if (relationKey.includes(`rows.${rowID}.`)) {
            delete this.crudRelations[relationKey]
          }
        })
      }
      this.$props.form.rows.splice(key, 1)
    },
    addItem(kind) {
      if (!this.$props.form.rows) {
        this.$props.form.rows = []
      }
      this.$props.form.rows.push({
        ID: 0,
        Flow: '',
        IsNoobItem: this.noob ? 1 : 0,
        Kind: kind,
        Position: 1,
        ReqValue: 0,
        RewardGold: 0,
        RewardItem1Id: null,
        RewardItem1Amount: 0,
        RewardItem2Id: null,
        RewardItem2Amount: 0,
        RewardItem3Id: null,
        RewardItem3Amount: 0,
        RewardItem4Id: null,
        RewardItem4Amount: 0,
        RewardPremium: 0
      })
    }
  }
}
</script>

<template>
  <fields-col>
    <section-divider>Gameplay modifiers</section-divider>
  </fields-col>
  <crud-field-number
    :sm="4"
    api-field-name="Spend.Group"
    label="Spend premium group"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-number
    :sm="5"
    api-field-name="Spend.Prio"
    label="Spend premium prio"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="Spend.Asset"
    label="Asset spend premium"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="Spend.TabIcon"
    label="Tab icon spend premium"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-client-strings
    :sm="5"
    api-field-name="Spend.TabName"
    label="Tab name spend premium "
    :form="form"
    :external-errors="externalErrors"
  />

  <crud-field-number
    :sm="4"
    api-field-name="Topup.Group"
    label="Topup premium group"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-number
    :sm="5"
    api-field-name="Topup.Prio"
    label="Topup premium prio"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="Topup.Asset"
    label="Asset topup premium"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="Topup.TabIcon"
    label="Tab icon topup premium"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-client-strings
    :sm="5"
    api-field-name="Topup.TabName"
    label="Tab name topup premium"
    :form="form"
    :external-errors="externalErrors"
  />

  <crud-field-number
    :sm="4"
    api-field-name="SpendDaily.Group"
    label="Spend premium daily group"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-number
    :sm="5"
    api-field-name="SpendDaily.Prio"
    label="Spend premium daily prio"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="SpendDaily.Asset"
    label="Asset spend premium daily"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="SpendDaily.TabIcon"
    label="Tab icon spend premium daily"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-client-strings
    :sm="5"
    api-field-name="SpendDaily.TabName"
    label="Tab name spend premium daily"
    :form="form"
    :external-errors="externalErrors"
  />

  <crud-field-number
    :sm="4"
    api-field-name="TopupDaily.Group"
    label="Topup premium daily group"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-number
    :sm="5"
    api-field-name="TopupDaily.Prio"
    label="Topup premium daily prio"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="TopupDaily.Asset"
    label="Asset topup premium daily"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-files
    :sm="5"
    api-field-name="TopupDaily.TabIcon"
    label="Tab icon topup premium daily"
    :form="form"
    :external-errors="externalErrors"
  />
  <crud-field-selector-client-strings
    :sm="5"
    api-field-name="TopupDaily.TabName"
    label="Tab name topup premium daily"
    :form="form"
    :external-errors="externalErrors"
  />

  <fields-col>
    <el-tabs
      class="w-full"
      model-value="spend_premium"
    >
      <el-tab-pane
        v-for="kind in kinds"
        :key="kind"
        lazy
        :label="kind.replaceAll('_', ' ')"
        :name="kind"
      >
        <fields-col>
          <fields-table>
            <template #tHeader>
              <tr>
                <th>Flow</th>
                <th>Pos</th>
                <th>Req val</th>
                <th>Gold</th>
                <th>Premium</th>
                <th
                  v-for="i in 4"
                  :key="i"
                >
                  Reward {{ i }}
                </th>
                <th />
              </tr>
            </template>
            <template #tBody>
              <tr
                v-for="(row, index) in items[kind]"
                :key="row"
              >
                <fields-table-td :width="115">
                  <crud-field-select
                    api-field-name="Flow"
                    options-enum="enums.EventFlowKind"
                    :form="form.rows[row]"
                    :external-errors="externalErrors"
                    slim
                    :label="false"
                    :disable-doc="!!index"
                    doc-icon-class="-top-10 right-0"
                    disable-doc-settings
                    :doc-field-name="`${kind}_Flow`"
                  />
                </fields-table-td>
                <fields-table-td :width="70">
                  <crud-field-number
                    api-field-name="Position"
                    :form="form.rows[row]"
                    :external-errors="externalErrors"
                    slim
                    :label="false"
                    :disable-doc="!!index"
                    doc-icon-class="-top-10 right-0"
                    disable-doc-settings
                    :doc-field-name="`${kind}_Position`"
                  />
                </fields-table-td>
                <fields-table-td :width="100">
                  <crud-field-number
                    api-field-name="ReqValue"
                    :form="form.rows[row]"
                    :external-errors="externalErrors"
                    slim
                    :label="false"
                    :disable-doc="!!index"
                    doc-icon-class="-top-10 right-0"
                    disable-doc-settings
                    :doc-field-name="`${kind}_ReqValue`"
                  />
                </fields-table-td>
                <fields-table-td :width="90">
                  <crud-field-number
                    api-field-name="RewardGold"
                    :form="form.rows[row]"
                    :external-errors="externalErrors"
                    slim
                    :label="false"
                    :disable-doc="!!index"
                    doc-icon-class="-top-10 right-0"
                    disable-doc-settings
                    :doc-field-name="`${kind}_RewardGold`"
                  />
                </fields-table-td>
                <fields-table-td :width="90">
                  <crud-field-number
                    api-field-name="RewardPremium"
                    :form="form.rows[row]"
                    :external-errors="externalErrors"
                    slim
                    :label="false"
                    :disable-doc="!!index"
                    doc-icon-class="-top-10 -right-3"
                    disable-doc-settings
                    :doc-field-name="`${kind}_RewardPremium`"
                  />
                </fields-table-td>
                <template
                  v-for="i in 4"
                  :key="i"
                >
                  <fields-table-td :width="400">
                    <fields-col>
                      <fields-col :sm="18">
                        <crud-field-selector-items
                          :api-field-name="`rows.${row}.RewardItem${i}`"
                          :form="form"
                          :external-errors="externalErrors"
                          slim
                          :label="false"
                          :disable-doc="!!index"
                          doc-icon-class="-top-10 right-0"
                          disable-doc-settings
                          :doc-field-name="`${kind}_RewardItem${i}`"
                        />
                      </fields-col>
                      <fields-col :sm="6">
                        <crud-field-number
                          :api-field-name="`RewardItem${i}Amount`"
                          :form="form.rows[row]"
                          :external-errors="externalErrors"
                          slim
                          :label="false"
                          :disable-doc="!!index"
                          doc-icon-class="-top-10 right-0"
                          disable-doc-settings
                          :doc-field-name="`${kind}_RewardItem${i}Amount`"
                        />
                      </fields-col>
                    </fields-col>
                  </fields-table-td>
                </template>
                <fields-table-td :width="90">
                  <el-icon class="mr-1 cursor-pointer">
                    <Delete @click="deleteItem(row)" />
                  </el-icon>
                </fields-table-td>
              </tr>
            </template>
          </fields-table>
        </fields-col>
        <fields-col>
          <el-button
            class="gs-btn-outlined-primary-light m-3"
            @click="addItem(kind)"
          >
            add new item
          </el-button>
        </fields-col>
      </el-tab-pane>
    </el-tabs>
  </fields-col>
</template>
