<script setup>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

const dialogSimulate = ref(false)
const loadingSimulate = ref('')
const simulateData = ref({
  row: null,
  response: null
})

function simulate(row) {
  loadingSimulate.value = `simulate_${row?.ID}`
  simulateData.value.row = row

  const params = {
    loot_id: row?.ID
  }
  app.$axios
    .get('/jwt/creator/simloot/', { params })
    .then(({ data }) => {
      simulateData.value.response = data || 'no data'
      simulateData.value.response = simulateData.value.response.replace('<pre>', '').replace('</pre>', '')
      dialogSimulate.value = true
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingSimulate.value = ''
    })
}

function clearSimulateData() {
  simulateData.value.row = null
  simulateData.value.response = null
}
</script>

<template>
  <crud-table
    :default-visible-columns="['Name']"
    api="loots/loots"
    api-item="loots/loot"
    :action-col-settings="{ elAttr: { width: 80 } }"
  >
    <template #cell_rowAction="{ row }">
      <el-tooltip
        content="simulate"
        effect="light"
        placement="top"
        :show-after="600"
      >
        <el-button
          class="gs-loading gs-no-icon gs-height-related-sm font-related-xl px-1 gs-btn-outlined-primary-light ml-0.5 max-w-6"
          tabindex="-1"
          :loading="loadingSimulate === `simulate_${row.ID}`"
          @click="simulate(row)"
        >
          <icon-ify
            icon="fad:random-1dice"
            class="gs-scaled-icon-md text-neutral-400"
          />
        </el-button>
      </el-tooltip>
    </template>
    <template #drawers>
      <el-dialog
        v-model="dialogSimulate"
        width="90%"
        :title="`Simulate: ${simulateData?.row?.AdminLabel || simulateData?.row?.ID}`"
        @close="clearSimulateData"
      >
        <div class="my-2 py-10 border-t-2 border-t-neutral-300 ">
          <el-scrollbar max-height="65vh">
            <pre>{{ simulateData.response }}</pre>
          </el-scrollbar>
        </div>
      </el-dialog>
    </template>
  </crud-table>
</template>
