<script>
import { inject } from 'vue'
// TODO check why gs-checkbox @click triggrs dbl;
export default {
  name: 'ApplyItemTagsDialog',
  components: {},
  setup() {
    const crudDetails = inject('crudDetails')
    return {
      crudDetails
    }
  },
  data() {
    return {
      groups: {},
      loading: {},
      groupAccordion: '',
      relationsTags: {},
      showNotRelated: false,
      mapItemTagsWithFields: []
    }
  },
  computed: {
    groupsArr() {
      const groupsArr = Object.values(this.groups).sort((a, b) => a.groupNo - b.groupNo)
      if (this.showNotRelated) {
        return groupsArr
      }
      return groupsArr.filter(group => !!group.relationsCounter)
    },
    isLocal() {
      return window?.location?.host && window?.location?.host?.indexOf?.('localhost') >= 0
    }
  },
  methods: {
    async loadData() {
      this.loading = { dialog: true }
      this.groupAccordion = ''
      this.mapItemTagsWithFields = []
      await this.$axios
        .get(`/admin/api/item-tag-group/children/`, {
          params: {
            id: this.crudDetails?.id,
            entity: this.crudDetails?.crudEntity
          }
        })
        .then(({ data }) => {
          this.mapItemTagsWithFields = []
          const rows = data?.rows || []
          const assigned = {}
          for (const row of rows) {
            if (row.tag) {
              if (!this.mapItemTagsWithFields[row.tag]) {
                this.mapItemTagsWithFields[row.tag] = []
              }
              if (!assigned[`${row.tag}_${row?.field}`]) {
                this.mapItemTagsWithFields[row.tag].push(row)
              }
              assigned[`${row.tag}_${row?.field}`] = true
            }
          }
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.dialog = false
        })
      await this.$axios
        .get(`/admin/api/items/item-tags/`, { params: { sort: 'GroupNo', sort_desc: true, on_page: 10000 } })
        .then(({ data }) => {
          this.renderApiData(data)
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.loadData = false
        })
    },
    buildUsedData(data) {
      return { value: false, data }
    },
    renderApiData(data) {
      const items = data?.items || []
      const groups = {}
      this.relationsTags = {}
      Object.values(this.crudDetails.form?._Relations || {}).forEach((relationTag) => {
        this.relationsTags[relationTag] = true
      })

      items.forEach((itemTag) => {
        if (!groups[itemTag.GroupNo.ID]) {
          groups[itemTag.GroupNo.ID] = {
            groupNo: itemTag.GroupNo.ID,
            itemTags: [],
            tableData: [],
            initTable: false,
            relationsCounter: 0,
            groupData: itemTag.GroupNo
          }
        }

        itemTag.Used = []
        if (this.mapItemTagsWithFields?.[itemTag.Name]?.length) {
          for (const mappedField of this.mapItemTagsWithFields[itemTag.Name]) {
            itemTag.Used.push(this.buildUsedData(mappedField))
          }
        }

        itemTag.isRelation = !!itemTag.Used.length
        if (itemTag.isRelation) {
          ++groups[itemTag.GroupNo.ID].relationsCounter
          groups[itemTag.GroupNo.ID].itemTags.push(itemTag)
        } else if (this.showNotRelated) {
          groups[itemTag.GroupNo.ID].itemTags.push(itemTag)
        }
      })
      this.groups = groups
    },
    onChangeAccordion(groupNo) {
      if (groupNo) {
        const tableData = [...this.groups[groupNo].itemTags].sort((a, b) => {
          if (a.isRelation && b.isRelation) {
            return b?.Name - a?.Name
          }
          if (a.isRelation) {
            return -1
          }
          return b?.Name - a?.Name
        })
        this.groups[groupNo].tableData = tableData
        this.groups[groupNo].initTable = true
      }
    },
    renderRowClassName({ row }) {
      if (row?.isRelation && this.showNotRelated) {
        return 'text-orange-500'
      }
    },
    applyGroup(group) {
      this.loading[`applyItemsTagsGroup_${group.groupNo}`] = true
      const relationsTags = {}
      group.itemTags.forEach((itemTag) => {
        if (itemTag.isRelation) {
          relationsTags[itemTag.Name] = itemTag.Item || null
        }
      })
      Object.entries(this.crudDetails.form._Relations).forEach(([fieldName, itemTagName]) => {
        if (relationsTags[itemTagName] !== undefined) {
          this.$utils.setByPath(this.crudDetails.form, fieldName.split('.'), relationsTags[itemTagName])
        }
      })
      this.loading[`applyItemsTagsGroup_${group.groupNo}`] = false
      this.crudDetails.applyItemsTagsDialog = false
    },
    applyGroupNew(group) {
      const used = {}
      if (group.itemTags.length) {
        for (const itemTag of group.itemTags) {
          if (itemTag?.Used?.length) {
            used[itemTag.Name] = [
              ...itemTag.Used.map((item) => {
                return { value: item.value, field: item.data.field }
              })
            ]
          }
        }
      }

      this.loading[`applyItemsTagsGroup_new_${group.groupNo}`] = true
      this.$axios
        .post(`/admin/api/apply-group-new/`, {
          id: this.crudDetails?.id,
          entity: this.crudDetails?.crudEntity,
          group: group.groupNo,
          used
        })
        .then(() => {
          this.crudDetails?.refreshData?.()
          this.crudDetails.applyItemsTagsDialog = false
          this.$notify({
            type: 'info',
            message: `Item tag group assigned.`,
            position: 'top-right',
            customClass: 'bg-amber-50 text-amber-600 child-inherit-colors',
            offset: 30,
            duration: 9000,
            showClose: true
          })
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading[`applyItemsTagsGroup_new_${group.groupNo}`] = false
        })
    },
    toggleUsedRow(toggleValue, row) {
      for (const usedRecord of row.Used) {
        usedRecord.value = toggleValue
      }
    },
    toggleUsedRowDiff(toggleValue, row) {
      for (const usedRecord of row.Used) {
        if (!!usedRecord.data.item && usedRecord.data.item === row?.Item?.ID) {
          usedRecord.value = false
        } else {
          usedRecord.value = toggleValue
        }
      }
    },
    toggleAllGroup(toggleValue, group) {
      if (group?.tableData?.length) {
        for (const groupRows of group.tableData) {
          if (groupRows?.Used?.length) {
            this.toggleUsedRow(toggleValue, groupRows)
          }
        }
      }
    },
    toggleAllGroupDiff(toggleValue, group) {
      if (group?.tableData?.length) {
        for (const groupRows of group.tableData) {
          if (groupRows?.Used?.length) {
            for (const usedRecord of groupRows.Used) {
              if (!!usedRecord.data.item && usedRecord.data.item === groupRows?.Item?.ID) {
                usedRecord.value = false
              } else {
                usedRecord.value = toggleValue
              }
            }
          }
        }
      }
    }
  }
}
</script>

<template>
  <el-dialog
    v-if="crudDetails.enableRelations"
    v-model="crudDetails.applyItemsTagsDialog"
    class="small"
    width="80%"
    top="10vh"
    @open="loadData"
  >
    <template #header>
      Apply items tags by group
      <gs-checkbox
        v-model="showNotRelated"
        label="show not related"
        class="ml-10"
        @update:model-value="loadData"
      />
    </template>
    <el-scrollbar
      v-loading="!!loading?.dialog"
      height="75vh"
      class="relative"
    >
      <el-collapse
        v-model="groupAccordion"
        accordion
        class="gs-inverted mx-10 mt-5"
        @change="onChangeAccordion"
      >
        <el-collapse-item
          v-for="(group, index) in groupsArr"
          :key="index"
          :name="group.groupNo"
        >
          <template #title>
            <el-button
              class="gs-loading gs-font-scaled gs-height-related-lg gs-btn-outlined-primary mr-2 px-3"
              :loading="loading[`applyItemsTagsGroup_${group.groupNo}`]"
              :disabled="!group.relationsCounter"
              @click.stop="applyGroup(group)"
            >
              apply group
            </el-button>
            <el-badge
              :hidden="!group.relationsCounter"
              :value="group.relationsCounter"
              class="inline-block pr-5"
              type="warning"
              style="line-height: 1.3em"
            >
              {{ `Group ${group?.groupData.AdminLabel}` }}
              <icon-ify
                v-if="crudDetails?.form?.ItemTagGroup?.ID && crudDetails?.form?.ItemTagGroup?.ID === group?.groupData?.ID"
                icon="uil:link"
                class="gs-scaled-icon-xs inline-block"
              />
            </el-badge>
            <el-popconfirm
              title="Make sure your edit form data has been saved. Unsaved data will be lost after performing this action."
              :width="300"
              confirm-button-text="continue"
              cancel-button-text="cancel"
              class="text-base"
              @click.stop
              @confirm.stop="applyGroupNew(group)"
            >
              <template #reference>
                <el-button
                  class="ml-10 gs-loading gs-font-scaled gs-height-related-lg gs-btn-outlined-primary mr-2 px-3"
                  :loading="loading[`applyItemsTagsGroup_new_${group.groupNo}`]"
                  :disabled="!group.relationsCounter"
                  @click.stop
                >
                  apply group new !!!
                </el-button>
              </template>
            </el-popconfirm>
          </template>
          <template #default>
            <div
              v-if="group.initTable"
              class="relative"
            >
              <el-table
                v-loading="loading.loadData"
                :data="group.tableData"
                :max-height="$windowHeight * 0.5"
                class="w-full"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                :row-class-name="renderRowClassName"
              >
                <el-table-column type="index" />
                <el-table-column
                  prop="ID"
                  label="ID"
                  :width="60"
                  class-name="gs-cell-padding-mini"
                />
                <el-table-column
                  prop="Preview"
                  label="Preview"
                  class-name="gs-leading-0 gs-cell-padding-mini"
                  :width="80"
                >
                  <template #default="{ row }">
                    <el-tooltip
                      v-if="row.AdminIcon"
                      raw-content
                      :show-after="600"
                      effect="light"
                      placement="right-start"
                    >
                      <template #default>
                        <el-image
                          :src="row.AdminIcon"
                          fit="contain"
                          style="width: 35px; height: 35px"
                          :preview-src-list="[row.AdminIcon]"
                          preview-teleported
                          hide-on-click-modal
                        />
                      </template>
                      <template #content>
                        <el-image
                          :src="row.AdminIcon"
                          fit="contain"
                          style="width: 200px; height: 200px"
                        />
                      </template>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Name"
                  label="Name"
                  :min-width="150"
                />
                <el-table-column
                  prop="Label"
                  label="Label"
                  :width="200"
                />
                <el-table-column
                  prop="Item"
                  label="Item"
                  :width="250"
                >
                  <template #default="{ row }">
                    {{ row?.Item?.AdminLabel }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Used"
                  :min-width="300"
                >
                  <template #header>
                    Used by
                    <div>
                      <el-checkbox
                        label="- Toggle all group -"
                        size="small"
                        class="toggle"
                        @update:model-value="toggleAllGroup($event, group)"
                      />
                    </div>
                    <div>
                      <el-checkbox
                        label="- Toggle only diffs of all group -"
                        size="small"
                        class="text-red-500 toggle"
                        @update:model-value="toggleAllGroupDiff($event, group)"
                      />
                    </div>
                  </template>
                  <template #default="{ row }">
                    <div
                      v-if="row?.Used?.length"
                      class="flex flex-col gap-y-1"
                    >
                      <div>
                        <el-checkbox
                          label="- Toggle all -"
                          size="small"
                          class="toggle mr-2"
                          @update:model-value="toggleUsedRow($event, row)"
                        />
                        <el-checkbox
                          label="- Toggle only diffs -"
                          size="small"
                          class="toggle text-red-500"
                          @update:model-value="toggleUsedRowDiff($event, row)"
                        />
                      </div>
                      <el-divider class="my-0" />
                      <div
                        v-for="(used, usedIndex) in row.Used"
                        :key="usedIndex"
                      >
                        <el-checkbox
                          v-model="used.value"
                          :label="`${used.data.field}`"
                          size="small"
                          class="whitespace-break-spaces"
                          :class="[!!used.data.item && used.data.item === row?.Item?.ID ? 'text-green-600' : 'text-red-500']"
                        />
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Start"
                  label="Start"
                  :width="180"
                >
                  <template #default="{ row }">
                    {{ $utils.formatUTCDateTime(row.Start) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="End"
                  label="End"
                  :width="180"
                >
                  <template #default="{ row }">
                    {{ $utils.formatUTCDateTime(row.End) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
      <div
        v-if="!groupsArr.length"
        class="absolute top-28 w-full text-center"
      >
        no data
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<style scoped>
.toggle:deep(.el-checkbox__input) {
  display: none !important;
  &.is-checked + .el-checkbox__label {
    color: inherit !important;
  }
}
</style>
