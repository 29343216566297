import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

dayjs.extend(utc)

const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  ' ',
  'Shift',
  'Backspace',
  'Delete',
  'Enter',
  undefined
]

function initDateTimeMask(pickerRefer, crudFormRefer, apiFieldName) {
  let input = null
  const mask = '20??-??-?? ??:??:00'
  const maskLength = mask.length
  const inputDateTimeFocused = ref(false)

  return {
    initMaskDateTime: (ev) => {
      app.$utils.nextLoopEvent(50).then(() => {
        if (input && !input.value?.length) {
          input.value = mask
          input?.select?.()
        }
      })
      if (input !== null) {
        return false
      }
      inputDateTimeFocused.value = true
      input = ev?.target || pickerRefer?.$el?.parentElement?.querySelector?.('input')
      app.$utils.nextLoopEvent(50).then(() => {
        input?.select?.()
      })
      input?.addEventListener?.('focus', () => {
        inputDateTimeFocused.value = true
        app.$utils.nextLoopEvent(50).then(() => {
          if (!input.value?.length) {
            input.value = mask
          }
          input?.select?.()
        })
      })
      input?.addEventListener?.('focusout', () => {
        inputDateTimeFocused.value = false
        if (input.value.includes('?')) {
          input.value = ''
        }
      })
      input?.addEventListener?.('paste', (ev) => {
        let pasteText = (ev.clipboardData || window.clipboardData).getData('text').trim()
        app.$utils.nextLoopEvent(50).then(() => {
          if (pasteText.length < 19) {
            pasteText = pasteText + mask.substring(pasteText.length)
            input.value = pasteText
            input.dispatchEvent(
              new Event('keydown', {
                bubbles: true
              })
            )
          }
        })
      })
      input?.addEventListener?.('keydown', (ev) => {
        if (!allowedKeys.includes(ev.key) && !ev.metaKey && !ev.ctrlKey) {
          ev.preventDefault()
          return false
        }
        let indexOfMask = input.value.indexOf('?')
        if (ev.key === 'Shift') {
          app.$utils.nextLoopEvent(50).then(() => {
            input?.select?.()
          })
        } else if (['Backspace', 'Delete'].includes(ev.key) && indexOfMask > -1) {
          ev.preventDefault()
          input.setRangeText(
            mask.substring(ev.target.selectionStart - 1, ev.target.selectionEnd),
            ev.target.selectionStart - 1,
            ev.target.selectionEnd
          )
        } else if (((indexOfMask > -1 && /^\d$/.test(ev.key)) || ev.key === undefined) && input.value.length === maskLength) {
          ev.preventDefault()
          let isCorrect = true
          if (ev.key !== undefined) {
            if (indexOfMask === 5) {
              isCorrect = ev.key * 1 <= 1
            } else if (indexOfMask === 6) {
              isCorrect = (input.value.substring(indexOfMask - 1, indexOfMask) + ev.key) * 1 <= 12
            } else if (indexOfMask === 8) {
              isCorrect = ev.key * 1 <= 3
            } else if (indexOfMask === 9) {
              isCorrect = (input.value.substring(indexOfMask - 1, indexOfMask) + ev.key) * 1 <= 31
              if (isCorrect) {
                isCorrect = dayjs(input.value.substring(0, indexOfMask) + ev.key, 'YYYY-MM-DD', true).isValid()
              }
            } else if (indexOfMask === 11) {
              isCorrect = ev.key * 1 <= 2
            } else if (indexOfMask === 12) {
              isCorrect = input.value.substring(indexOfMask - 1, indexOfMask) + ev.key <= 23
            } else if (indexOfMask === 14) {
              isCorrect = ev.key * 1 <= 5
            } else if (indexOfMask === 15) {
              isCorrect = input.value.substring(indexOfMask - 1, indexOfMask) + ev.key <= 59
            }
          } else {
            isCorrect = dayjs(input.value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
          }
          if (isCorrect) {
            if (ev.key !== undefined) {
              input.setRangeText(ev.key, indexOfMask, indexOfMask + 1)
              indexOfMask = input.value.indexOf('?')
              input.setSelectionRange(indexOfMask, indexOfMask + 1)
            }

            if (!input.value.includes('?')) {
              input.dispatchEvent(
                new Event('input', {
                  bubbles: true
                })
              )
              input.dispatchEvent(
                new Event('change', {
                  bubbles: true
                })
              )
              crudFormRefer?.validateField?.(apiFieldName)
              app.$utils.nextLoopEvent().then(() => {
                if (ev.key !== undefined) {
                  input.setSelectionRange(17, maskLength)
                } else {
                  input.setSelectionRange(0, maskLength)
                }
              })
            } else {
              if (indexOfMask / maskLength > 0.45) {
                input.scrollLeft = Math.ceil((indexOfMask / maskLength) * input.scrollWidth)
              }
            }
          } else {
            if (indexOfMask > -1) {
              input.scrollLeft = Math.ceil((indexOfMask / maskLength) * input.scrollWidth)
              input.setSelectionRange(indexOfMask, maskLength)
            } else {
              app.$message({
                message: 'Not correct value',
                type: 'warning',
                offset: app.$windowHeight * 0.25,
                grouping: true
              })
            }
          }
        } else if (ev.key === 'Enter' && indexOfMask > -1) {
          ev.preventDefault()
        } else if (ev.key === 'Enter' && input.value.length === maskLength) {
          ev.preventDefault()
          if (
            ev.target.selectionStart >= 17 ||
            ev.target.selectionEnd < 4 ||
            (ev.target.selectionStart === 0 && ev.target.selectionEnd === 10)
          ) {
            input.setSelectionRange(0, 4)
          } else if (ev.target.selectionEnd < 7) {
            input.setSelectionRange(5, 7)
          } else if (ev.target.selectionEnd < 10) {
            input.setSelectionRange(8, 10)
          } else if (ev.target.selectionEnd < 13) {
            input.setSelectionRange(11, 13)
          } else if (ev.target.selectionEnd < 16) {
            input.setSelectionRange(14, 16)
          } else if (ev.target.selectionEnd < maskLength) {
            input.setSelectionRange(17, maskLength)
          }
        } else if (ev.key === 'Enter' && !input.value.length) {
          ev.preventDefault()
          input.value = mask
          app.$utils.nextLoopEvent(50).then(() => {
            input?.select?.()
          })
        }
      })
    },
    inputDateTimeFocused
  }
}

function initDateMask(pickerRefer, crudFormRefer, apiFieldName) {
  let input = null
  const mask = '20??-??-??'
  const maskLength = mask.length
  const inputDateFocused = ref(false)

  return {
    initMaskDate(ev) {
      app.$utils.nextLoopEvent().then(() => {
        if (input && !input.value?.length) {
          input.value = '20??-??-??'
          input?.select?.()
        }
      })
      if (input !== null) {
        return false
      }
      inputDateFocused.value = true
      input = ev?.target || pickerRefer?.$el?.parentElement?.querySelector?.('input')
      input?.select?.()
      input?.addEventListener?.('focus', () => {
        inputDateFocused.value = true
        app.$utils.nextLoopEvent().then(() => {
          if (!input.value?.length) {
            input.value = mask
          }
          input?.select?.()
        })
      })
      input?.addEventListener?.('focusout', () => {
        inputDateFocused.value = false
        if (input.value.includes('?')) {
          input.value = ''
        }
      })
      input?.addEventListener?.('paste', (ev) => {
        let pasteText = (ev.clipboardData || window.clipboardData).getData('text').trim()
        app.$utils.nextLoopEvent(50).then(() => {
          if (pasteText.length < 10) {
            pasteText = pasteText + mask.substring(pasteText.length)
            input.value = pasteText
            input.dispatchEvent(
              new Event('keydown', {
                bubbles: true
              })
            )
          }
        })
      })
      input?.addEventListener?.('keydown', (ev) => {
        if (!allowedKeys.includes(ev.key) && !ev.metaKey && !ev.ctrlKey) {
          ev.preventDefault()
          return false
        }
        let indexOfMask = input.value.indexOf('?')
        if (ev.key === 'Shift') {
          app.$utils.nextLoopEvent(50).then(() => {
            input?.select?.()
          })
        } else if (['Backspace', 'Delete'].includes(ev.key) && indexOfMask > -1) {
          ev.preventDefault()
          input.setRangeText(
            mask.substring(ev.target.selectionStart - 1, ev.target.selectionEnd),
            ev.target.selectionStart - 1,
            ev.target.selectionEnd
          )
        } else if (((indexOfMask > -1 && /^\d$/.test(ev.key)) || ev.key === undefined) && input.value.length === maskLength) {
          ev.preventDefault()
          let isCorrect = true
          if (ev.key !== undefined) {
            if (indexOfMask === 5) {
              isCorrect = ev.key * 1 <= 1
            } else if (indexOfMask === 6) {
              isCorrect = (input.value.substring(indexOfMask - 1, indexOfMask) + ev.key) * 1 <= 12
            } else if (indexOfMask === 8) {
              isCorrect = ev.key * 1 <= 3
            } else if (indexOfMask === 9) {
              isCorrect = (input.value.substring(indexOfMask - 1, indexOfMask) + ev.key) * 1 <= 31
              if (isCorrect) {
                isCorrect = dayjs(input.value.substring(0, indexOfMask) + ev.key, 'YYYY-MM-DD', true).isValid()
              }
            }
          } else {
            isCorrect = dayjs(input.value, 'YYYY-MM-DD', true).isValid()
          }
          if (isCorrect) {
            if (ev.key !== undefined) {
              input.setRangeText(ev.key, indexOfMask, indexOfMask + 1)
              indexOfMask = input.value.indexOf('?')
              input.setSelectionRange(indexOfMask, indexOfMask + 1)
            }

            if (!input.value.includes('?')) {
              input.dispatchEvent(
                new Event('input', {
                  bubbles: true
                })
              )
              input.dispatchEvent(
                new Event('change', {
                  bubbles: true
                })
              )
              crudFormRefer?.validateField?.(apiFieldName)
              app.$utils.nextLoopEvent().then(() => {
                if (ev.key !== undefined) {
                  input.setSelectionRange(8, maskLength)
                } else {
                  input.setSelectionRange(0, maskLength)
                }
              })
            } else {
              if (indexOfMask / maskLength > 0.45) {
                input.scrollLeft = Math.ceil((indexOfMask / maskLength) * input.scrollWidth)
              }
            }
          } else {
            if (indexOfMask > -1) {
              input.scrollLeft = Math.ceil((indexOfMask / maskLength) * input.scrollWidth)
              input.setSelectionRange(indexOfMask, maskLength)
            } else {
              app.$message({
                message: 'Not correct value',
                type: 'warning',
                offset: app.$windowHeight * 0.25,
                grouping: true
              })
            }
          }
        } else if (ev.key === 'Enter' && indexOfMask > -1) {
          ev.preventDefault()
        } else if (ev.key === 'Enter' && input.value.length === 10) {
          ev.preventDefault()
          if (ev.target.selectionStart >= 8 || ev.target.selectionEnd < 4) {
            input.setSelectionRange(0, 4)
          } else if (ev.target.selectionEnd < 7) {
            input.setSelectionRange(5, 7)
          } else if (ev.target.selectionEnd < 9) {
            input.setSelectionRange(8, 10)
          }
        } else if (ev.key === 'Enter' && !input.value.length) {
          ev.preventDefault()
          input.value = mask
          input?.select?.()
        }
      })
    },
    inputDateFocused
  }
}

function initShortcutsDateTime(refer, nameModel, format = 'YYYY-MM-DD HH:mm:ss') {
  return [
    {
      text: 'today',
      value: () => {
        return dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(format)
      }
    },
    {
      text: '+7 days',
      value: () => {
        return dayjs
          .utc(refer[nameModel] || dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(format))
          .add(7, 'day')
          .format(format)
      }
    },
    {
      text: '+14 days',
      value: () => {
        return dayjs
          .utc(refer[nameModel] || dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(format))
          .add(14, 'day')
          .format(format)
      }
    },
    {
      text: '+21 days',
      value: () => {
        return dayjs
          .utc(refer[nameModel] || dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(format))
          .add(21, 'day')
          .format(format)
      }
    },
    {
      text: '+1 month',
      value: () => {
        return dayjs
          .utc(refer[nameModel] || dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(format))
          .add(1, 'month')
          .format(format)
      }
    }
  ]
}
function initShortcutsDate(refer, nameModel) {
  return [
    {
      text: 'today',
      value: () => {
        return dayjs().format('YYYY-MM-DD')
      }
    },
    {
      text: '+7 days',
      value: () => {
        return dayjs(refer[nameModel] || dayjs().format('YYYY-MM-DD'))
          .add(7, 'day')
          .format('YYYY-MM-DD')
      }
    },
    {
      text: '+14 days',
      value: () => {
        return dayjs(refer[nameModel] || dayjs().format('YYYY-MM-DD'))
          .add(14, 'day')
          .format('YYYY-MM-DD')
      }
    },
    {
      text: '+21 days',
      value: () => {
        return dayjs(refer[nameModel] || dayjs().format('YYYY-MM-DD'))
          .add(21, 'day')
          .format('YYYY-MM-DD')
      }
    },
    {
      text: '+1 month',
      value: () => {
        return dayjs(refer[nameModel] || dayjs().format('YYYY-MM-DD'))
          .add(1, 'month')
          .format('YYYY-MM-DD')
      }
    }
  ]
}

export { initDateTimeMask, initDateMask, initShortcutsDateTime, initShortcutsDate }
