<script>
import CrudFieldSelectorBattleEventBossMobs from '@/components/forms/crud-fields/CrudFieldSelectorBattleEventBossMobs.vue'

export default {
  components: { CrudFieldSelectorBattleEventBossMobs }
}
</script>

<template>
  <crud-details-page
    api="events/boss-group"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- BossNormal -->
        <CrudFieldSelectorBattleEventBossMobs
          api-field-name="BossNormal"
          label="Boss normal"
          required
        />
        <!-- BossHard -->
        <CrudFieldSelectorBattleEventBossMobs
          api-field-name="BossHard"
          label="Boss hard"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- BossGuild -->
        <CrudFieldSelectorBattleEventBossMobs
          api-field-name="BossGuild"
          required
        />
        <!-- BossMulti -->
        <CrudFieldSelectorBattleEventBossMobs
          api-field-name="BossMulti"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
