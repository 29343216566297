<template>
  <crud-details-page
    api="guild/fame-perk"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- FameLevel -->
        <crud-field-number
          api-field-name="FameLevel"
          required
        />
        <!-- FameLevel -->
        <crud-field-number
          api-field-name="ReqFamePoints"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
