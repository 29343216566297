<script>
import { ref, watch } from 'vue'
import UserSettingsBox from '!/components/page-layout/header/UserSettingsBox.vue'
import { LayoutSettings } from '@/settings/defaultMeta.js'
import { globalProperties as app, globalProperties } from '!/plugins/utilities'

export default {
  name: 'PageHeader',
  components: { UserSettingsBox },
  setup() {
    const isTimeShift = import.meta.env.VITE_TIMESHIFT === 'on'
    const isDevEnv = import.meta.env.VITE_TEST_ENV === 'on'
    const isBetaVersion = import.meta.env.VITE_BETA === 'on'
    const timeShift = ref('')

    watch(
      () => app.$route,
      () => {
        if (LayoutSettings?.gameSelectorSettings?.length) {
          app.$store.commit('enums/clearAll')
          if (!app?.$route?.query?.__game) {
            app.$router.replace(app.$utils.bindStaticParams(app?.$route))
          }
          const currentGameId = globalProperties.$store.getters['main/gameApiSettings']?.id
          if (app?.$route?.query?.__game !== currentGameId) {
            const forcedGame = LayoutSettings.gameSelectorSettings.find((option) => {
              return option.id === app?.$route?.query?.__game
            })
            if (!forcedGame) {
              // eslint-disable-next-line no-console
              console.log(`Game with id "${app?.$route?.query?.__game}" not found !`)
              return false
            }
            if (import.meta.env[forcedGame.env_variable]) {
              forcedGame.api = import.meta.env[forcedGame.env_variable]
            } else {
              // eslint-disable-next-line no-console
              console.log(`Game env_variable "${forcedGame.env_variable}" not found !`)
              return false
            }
            app.$store.commit('main/setGameApiSettings', forcedGame)
            app.$store.dispatch('auth/reLoadUser', forcedGame.api).then(() => {
              const currentRoute = app.$utils.bindStaticParams(app.$router.currentRoute.value)
              app.$router.push({ name: currentRoute.name, params: currentRoute.params, query: currentRoute.query }).then(() => {
                app.$router.go(0)
              })
            })
          }
        }
      },
      { immediate: true }
    )

    if (isTimeShift) {
      app.$axios
        .get('/ping/')
        .then(({ data }) => {
          timeShift.value = data?.time || ''
        })
        .catch(app.$utils.catchError)
    }
    return {
      LayoutSettings,
      isTimeShift,
      timeShift,
      isDevEnv,
      isBetaVersion
    }
  },
  computed: {},
  mounted() {
    this.$root.$el.style.setProperty('--gs-page-top-padding', 'calc(var(--gs-bar-height) + var(--gs-actions-bar-height))')
  }
}
</script>

<template>
  <el-header
    class="gs-page-header gs-font-scaled fixed right-0 top-0 flex items-center justify-between"
    :class="LayoutSettings.pageHeader.bgClass"
    :style="{ left: $store.getters['main/pageMenuWidth'] }"
  >
    <div class="flex-none">
      <slot name="left">
        <el-button
          class="gs-button-page-header p-0"
          :class="LayoutSettings.pageHeader.btnClass"
          size="small"
        >
          <transition>
            <icon-ify
              v-if="$store.getters['main/pageMenu']"
              icon="ic:baseline-menu-open"
              class="gs-scaled-icon-xl"
              @click="$store.commit('main/togglePageMenu')"
            />
            <icon-ify
              v-else
              icon="ic:baseline-menu"
              class="gs-scaled-icon-xl"
              @click="$store.commit('main/togglePageMenu')"
            />
          </transition>
        </el-button>
      </slot>
    </div>
    <div
      class="font-related-xxl ml-0 mr-auto flex items-center px-5"
      style="max-width: 80%"
    >
      <div class="whitespace-nowrap">
        <slot name="default">
          {{ $route?.meta?.PageHeaderTitle || $route?.meta?.Title }}
        </slot>
      </div>
      <div class="truncate">
        {{ $store.getters['main/pageHeaderSubtitle'] }}
      </div>
      <el-tooltip
        v-if="$store.getters['main/pageHeaderImgUrl'] && !$store.getters['main/pageHeaderHideImg']"
        raw-content
        :show-after="600"
        effect="light"
        placement="right-end"
      >
        <template #default>
          <el-image
            :src="`https://csndmapzfa.cloudimg.io/${$store.getters['main/pageHeaderImgUrl'].replace('https://', '')}?w=50`"
            fit="fill"
            class="ml-4 cursor-pointer rounded-lg"
            lazy
            :preview-src-list="[$store.getters['main/pageHeaderImgUrl']]"
            preview-teleported
            hide-on-click-modal
            style="max-width: 35px; max-height: 35px"
          />
        </template>
        <template #content>
          <el-image
            :src="`https://csndmapzfa.cloudimg.io/${$store.getters['main/pageHeaderImgUrl'].replace('https://', '')}?w=200`"
            fit="contain"
            style="width: 200px; height: 200px"
          />
        </template>
      </el-tooltip>
    </div>
    <template v-if="isTimeShift">
      <div class="absolute right-16 top-0 font-bold">
        {{ timeShift }}
      </div>
      <div class="absolute left-40 right-64 top-0 overflow-hidden font-bold">
        <div class="moving-text whitespace-nowrap">
          <span
            v-for="n in 10"
            :key="n"
            class="mr-24"
          >!!! TimeShift !!!!</span>
        </div>
      </div>
    </template>
    <div
      v-else-if="isDevEnv || isBetaVersion"
      class="absolute left-40 right-32 top-0 overflow-hidden font-bold"
    >
      <div class="moving-text whitespace-nowrap">
        <span
          v-for="n in 5"
          :key="n"
          class="mr-16"
        >{{ isBetaVersion ? '!!! BETA VERSION !!!!' : '!!! TEST ENVIRONMENT !!!!' }}</span>
      </div>
    </div>
    <div class="flex-none">
      <slot name="right">
        <UserSettingsBox />
      </slot>
    </div>
  </el-header>
</template>

<style lang="postcss">
.gs-page-header {
  z-index: 2000;
  --el-header-height: var(--gs-bar-height);
}

/* The animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.moving-text {
  animation: marquee 15s linear infinite;
}
</style>
