<template>
  <crud-details-page
    api="items/wearable-set"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
        />
        <!-- IconSd -->
        <crud-field-selector-files api-field-name="IconSd" />
        <!-- IconHd -->
        <crud-field-selector-files api-field-name="IconHd" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
