<script setup>
</script>

<template>
  <crud-details-page
    api="spins/spin"
    form-class="mt-0"
    enable-relations
  >
    <template #form>
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col
                :sm="8"
              >
                <!-- Comment -->
                <crud-field-text
                  api-field-name="Comment"
                  required
                />
                <!-- PricePremiumX10 -->
                <crud-field-number api-field-name="PricePremiumX10" />
                <el-col
                  :span="24"
                  class="bg-amber-100"
                >
                  <el-row>
                    <crud-field-item-tags api-field-name="ItemTags" />
                  </el-row>
                  <el-row>
                    <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
                  </el-row>
                </el-col>
              </fields-col>
              <fields-col
                :sm="16"
              >
                <template
                  v-for="i in 10"
                  :key="i"
                >
                  <crud-field-number
                    :sm="12"
                    :api-field-name="`PriceGold${i}`"
                  />
                  <crud-field-number
                    :sm="12"
                    :api-field-name="`PricePremium${i}`"
                  />
                </template>
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Wheel slots"
            name="wheel_slots"
          >
            <fields-col>
              <template
                v-for="i in 8"
                :key="i"
              >
                <fields-col>
                  <section-divider>Slot #{{ i }}</section-divider>
                </fields-col>
                <fields-col
                  :sm="24"
                >
                  <!-- SlotChance -->
                  <crud-field-number
                    :sm="4"
                    :api-field-name="`Slot${i}.Chance`"
                    label="Chance"
                    :precision="2"
                  />
                  <!-- SlotMultiplier -->
                  <crud-field-number
                    :sm="4"
                    :api-field-name="`Slot${i}.Multiplier`"
                    label="or multiplier (Events only)"
                    :precision="2"
                  />
                  <!-- SlotItem -->
                  <crud-field-selector-items
                    :sm="6"
                    :api-field-name="`Slot${i}.Item`"
                    label="or item"
                    :precision="2"
                  />
                  <crud-field-number
                    :sm="4"
                    :api-field-name="`Slot${i}.ItemAmount`"
                    label="amount"
                    :precision="2"
                  />
                  <!-- SlotItemIcon -->
                  <crud-field-selector-items
                    :sm="6"
                    :api-field-name="`Slot${i}.ItemIcon`"
                    label="Item on wheel"
                  />
                </fields-col>
                <fields-col
                  :sm="24"
                >
                  <!-- SlotGold -->
                  <crud-field-number
                    :sm="4"
                    :api-field-name="`Slot${i}.Gold`"
                    label="Gold"
                    :precision="2"
                  />
                  <!-- SlotPremium -->
                  <crud-field-number
                    :sm="4"
                    :api-field-name="`Slot${i}.Premium`"
                    label="or premium"
                    :precision="2"
                  />
                  <crud-field-selector-loots
                    :sm="6"
                    :api-field-name="`Slot${i}.Loot`"
                    label="or loot"
                  />
                </fields-col>
              </template>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
