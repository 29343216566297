<template>
  <crud-details-page
    api="settings/initial-inventory"
  >
    <template #form>
      <fields-col
        v-for="code in [
          'MainHand',
          'OffHand',
          'Chest',
          'Head',
          'Feet',
          'Gloves',
          'Ring',
          'Amulet',
          'Talisman',
          'Talent1',
          'Talent2',
          'Talent3'
        ]"
        :key="code"
        :sm="8"
      >
        <crud-field-selector-items :api-field-name="code" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
