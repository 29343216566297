<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectHealFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcHealOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcHealOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'heal',
      'target-team': 'enemies',
      'times': 1,
      'srcHeal': 'magic',
      'is-percent': 1,
      'min-value': 0,
      'max_value': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Heal_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Heal_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- times -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.times"
          label="Times"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Heal_Times"
          disable-doc-settings
        />
      </div>
      <!-- srcHeal -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.srcHeal"
          label="Value source"
          slim
          :clearable="false"
          :options="srcHealOptions"
          doc-field-name="Heal_Value_source"
          disable-doc-settings
        />
      </div>
      <!-- is-percent -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.is-percent"
          label="Use % of value"
          slim
          :clearable="false"
          :options="booleanOptions"
          doc-field-name="Heal_Use%_of_value"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <div class="col-w-3" />
      <div class="col-w-1" />
      <!-- min-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.min-value"
          label="Value min"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="Heal_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- max_value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.max_value"
          label="Value max"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="Heal_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-value"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Heal_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
