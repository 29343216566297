<script>
import { computed, onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'
import { Bottom, CircleClose, DCaret, Finished, Search, Top } from '@element-plus/icons-vue'
import { globalProperties as app } from '!/plugins/utilities'
import CellContent from '!/components/crud/sub/CellContent.vue'

export default {
  name: 'SimpleTable',
  components: { CellContent, CircleClose },
  props: {
    // used when not used api prop (external data)
    rows: {
      type: Array,
      default: undefined
    },
    columns: {
      type: Object,
      default: () => ({
        // 'colNameApi': {
        //    elAttr: {attr for el-table-column (https://element-plus.org/en-US/component/table.html#table-column-attributes)}, (optional)
        //    header: 'txt header', (optional - default colApiName, short for elAttr.label)
        //    width: shortcut for elAttr.width settings (optional)
        //    sortable: shortcut for elAttr.sortable settings (optional, default false)
        //    filterField: [false | true (name === colNameApi) | name_field (look at more info below)], (optional - default: false)
        //    type: type column (any, string, int, uint, float, bool, date, datetime), (optional - default: any); this determines the type of filter field
        // }
      })
    },
    pageSizes: {
      type: Array,
      default: () => [15, 30, 50, 100]
    },
    indexRowsColumn: {
      type: Boolean,
      default: false
    },
    externalTotal: {
      type: Number,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    api: {
      type: String,
      default: ''
    },
    apiParams: {
      type: Object,
      default: undefined
    },
    apiPrefix: {
      type: String,
      default: '/admin/api/'
    },
    /**
     * enable use envs api host
     * Important: env is required in the apiParams;
     */
    proxy: {
      type: Boolean,
      default: () => false
    },
    apiPagination: {
      type: Boolean,
      default: false
    },
    apiSorting: {
      type: Boolean,
      default: false
    },
    apiFiltering: {
      type: Boolean,
      default: false
    },
    renderApiRows: {
      type: Function,
      default(response) {
        return response?.data?.items || []
      }
    },
    renderApiTotal: {
      type: Function,
      default(response) {
        return response?.data?.items?.length || 0
      }
    },
    hideRefreshBtn: {
      type: Boolean,
      default: false
    },
    showClearBtn: {
      type: Boolean,
      default: true
    }
  },
  emits: ['fetch-data'],
  setup(props, context) {
    const loading = ref(false)
    const pagination = ref({
      currentPage: 1,
      onPage: props.pageSizes?.[0] || 15,
      total: 0
    })
    const sorting = ref({
      order: '',
      prop: ''
    })
    const filteredTotal = ref(undefined)
    const updateTableRowsTotal = (total) => {
      filteredTotal.value = total
    }
    const apiRows = ref([])
    const tableCols = computed(() => {
      return Object.fromEntries(
        Object.entries(props.columns).map(([colApiName, colSettings]) => {
          if (!colSettings.elAttr) {
            colSettings.elAttr = {}
          }
          colSettings.elAttr.width = colSettings?.width || colSettings.elAttr?.width || undefined
          colSettings.elAttr.label =
            colSettings?.header || colSettings.elAttr.label || app.$utils.convertApiNamesToHuman(colApiName)
          colSettings.elAttr.sortable = false
          colSettings.elAttr.prop = colSettings?.elAttr?.prop || colApiName
          colSettings.filterFIeld = colSettings?.filterFIeld || false
          colSettings.type = colSettings?.type || 'any'
          colSettings.typeValueField = colSettings?.type || 'any'
          return [colApiName, colSettings]
        })
      )
    })
    const tableFilters = ref({})
    const isNotEmptySearchValue = (typeField, value) => {
      switch (typeField) {
        case 'int':
        case 'uint':
          return !!value || value === 0
        case 'bool':
          return value || value === false
        default:
          return !!value || value === false || value === 0
      }
    }
    const tableFiltersNotEmptyArr = computed(() => {
      return Object.entries(tableFilters.value).filter(([nameField, filterValue]) => {
        return isNotEmptySearchValue(tableCols.value?.[nameField]?.type || 'any', filterValue)
      })
    })
    const fetchData = debounce(() => {
      if (props.api) {
        const params = props.apiParams || {}
        if (props.apiPagination) {
          params.page = pagination.value.currentPage
          params.on_page = pagination.value.onPage
        }
        if (props.apiSorting) {
          params.sort = sorting.value.prop ? sorting.value.prop : undefined
          params.sort_desc = sorting.value.prop ? sorting.value.order === 'descending' : undefined
        }
        if (props.apiFiltering) {
          params.push(...Object.fromEntries(tableFiltersNotEmptyArr.value))
        }
        loading.value = true
        const proxyApHost = props.proxy ? app.$store.getters['auth/envs'][props?.apiParams?.env] : ''
        app.$axios
          .get(`${(proxyApHost || '') + props.apiPrefix + props.api}/`, { params })
          .then((response) => {
            apiRows.value = props.renderApiRows(response) || []
            pagination.value.total = props.renderApiTotal(response) || 0
          })
          .catch(app.$utils.catchError)
          .then(() => {
            loading.value = false
          })
      } else {
        context.emit('fetch-data', { pagination: pagination.value, sorting: sorting.value })
      }
    }, 400)
    onMounted(() => {
      fetchData()
    })
    if (props.apiPagination) {
      watch(
        () => pagination.value.onPage,
        () => {
          fetchData()
        },
        { immediate: true }
      )
      watch(
        () => pagination.value.currentPage,
        () => {
          fetchData()
        }
      )
    }

    return {
      pagination,
      tableRows: computed(() => {
        let rows = [...(props.api ? apiRows.value : props.rows || [])]

        if (!props.apiFiltering) {
          rows = rows.filter((row) => {
            return !tableFiltersNotEmptyArr.value.some(([nameField, filterValue]) => {
              const fieldVal = tableCols.value?.[nameField]?.elAttr?.formatter
                ? tableCols.value[nameField].elAttr.formatter(row)
                : row[nameField]
              const typeField = tableCols.value?.[nameField]?.type || 'any'
              if (['any', 'string'].includes(typeField)) {
                if (String(fieldVal).toLowerCase().includes(String(filterValue).toLowerCase())) {
                  return false
                }
              } else if (['number', 'int', 'uint', 'float'].includes(typeField)) {
                if (fieldVal * 1 === filterValue * 1) {
                  return false
                }
              } else if (fieldVal === filterValue) {
                return false
              }
              return true
            })
          })
          updateTableRowsTotal(rows.length)
        }

        if (!props.apiSorting) {
          if (sorting.value.prop) {
            const sortTypeVal = sorting.value.order === 'ascending' ? 1 : -1
            const isNumber = ['int', 'uint', 'float', 'number'].includes(tableCols.value?.[sorting.value.prop]?.type)
            rows = rows.sort((a, b) => {
              const aVal = tableCols.value?.[sorting.value.prop]?.elAttr.formatter
                ? tableCols.value?.[sorting.value.prop]?.elAttr.formatter(a)
                : a?.[sorting.value.prop]
              const bVal = tableCols.value?.[sorting.value.prop]?.elAttr.formatter
                ? tableCols.value?.[sorting.value.prop]?.elAttr.formatter(b)
                : b?.[sorting.value.prop]
              if (isNumber) {
                if (aVal < bVal)
                  return -1 * sortTypeVal
                if (aVal > bVal)
                  return 1 * sortTypeVal
              } else {
                if (String(aVal).toUpperCase().trim() < String(bVal).toUpperCase().trim())
                  return -1 * sortTypeVal
                if (String(aVal).toUpperCase().trim() > String(bVal).toUpperCase().trim())
                  return 1 * sortTypeVal
              }
              return 0
            })
          }
        }

        if (props.apiPagination) {
          return rows
        } else {
          const firstIndex = (pagination.value.currentPage - 1) * pagination.value.onPage
          return rows.slice(firstIndex, firstIndex + pagination.value.onPage)
        }
      }),
      tableCols,
      tableTotal: computed(() => {
        if (props.externalTotal !== undefined) {
          return props.externalTotal
        }
        if (filteredTotal.value !== undefined) {
          return filteredTotal.value
        }
        if (props.apiPagination) {
          return pagination.value.total
        }
        return props.rows?.length || 0
      }),
      tableLoading: computed(() => {
        return props.loading || loading.value
      }),
      calcIndexPage: (index) => {
        return index + 1 + (pagination.value.currentPage - 1) * pagination.value.onPage
      },
      onChangeSort: (colApiName) => {
        const disableCurrentSorting =
          sorting.value.prop && sorting.value.prop === colApiName && sorting.value.order === 'descending'
        sorting.value = {
          prop: disableCurrentSorting ? '' : colApiName,
          order: disableCurrentSorting || sorting.value.prop !== colApiName ? 'ascending' : 'descending'
        }
        if (props.apiSorting) {
          fetchData()
        }
      },
      fetchData,
      sorting,
      icons: {
        Top,
        Bottom,
        DCaret,
        Finished,
        Search
      },
      tableFilters,
      getFilterFieldName(colApiName) {
        return tableCols.value?.[colApiName]?.filterField === true
          ? colApiName
          : tableCols.value?.[colApiName]?.filterField || colApiName
      },
      clearFilters: () => {
        tableFilters.value = {}
        sorting.value.prop = ''
        sorting.value.order = ''
      },
      visibleResetBtn: computed(() => {
        return tableFiltersNotEmptyArr.value.length || sorting.value.prop.length
      })
    }
  }
}
</script>

<template>
  <el-col class="px-0">
    <div>
      <el-table
        :data="tableRows"
        class="gs-scaled mx-auto"
        :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
        v-bind="$attrs"
        :empty-text="tableLoading ? 'loading data' : 'no data'"
      >
        <!-- number column -->
        <el-table-column
          v-if="indexRowsColumn"
          type="index"
          label="#"
        >
          <template #default="{ $index }">
            <div class="whitespace-nowrap break-normal">
              {{ calcIndexPage($index) }}
            </div>
          </template>
        </el-table-column>
        <template
          v-for="(colAttr, colProp, index) in tableCols"
          :key="index"
        >
          <el-table-column v-bind="colAttr.elAttr">
            <!-- headers slots -->
            <template #header="scope">
              <div class="flex whitespace-nowrap">
                <!-- slot header_default -->
                <div
                  class="inline-block overflow-hidden text-ellipsis"
                  style="max-width: calc(100% - 10px)"
                >
                  <slot
                    v-if="$slots?.header_default && !$slots?.[`header_${scope.column.property}`]"
                    name="header_default"
                    :settings="colAttr"
                    :col-name="colProp"
                  >
                    <el-tooltip
                      v-if="colAttr.elAttr.width && scope.column.label.length > colAttr.elAttr.width / 13"
                      :content="scope.column.label"
                      effect="light"
                      placement="top"
                    >
                      {{ scope.column.label }}
                    </el-tooltip>
                    <template v-else>
                      {{ scope.column.label }}
                    </template>
                  </slot>
                  <!-- slot header_[colApiName] -->
                  <slot
                    v-else
                    :name="`header_${scope.column.property}`"
                    :settings="colAttr"
                    :col-name="colProp"
                  >
                    <el-tooltip
                      v-if="colAttr.elAttr.width && scope.column.label.length > colAttr.elAttr.width / 13"
                      :content="scope.column.label"
                      effect="light"
                      placement="top"
                    >
                      {{ scope.column.label }}
                    </el-tooltip>
                    <template v-else>
                      {{ scope.column.label }}
                    </template>
                  </slot>
                </div>
                <!-- slot if sortable -->
                <template v-if="colAttr?.sortable">
                  <el-button
                    class="gs-btn-text-neutral-light gs-sort gs-font-scaled mr-1 px-0 hover:visible"
                    :class="{
                      visible: sorting.prop === scope.column.property,
                      invisible: sorting.prop !== scope.column.property
                    }"
                    :icon="
                      sorting.prop === scope.column.property
                        ? sorting.order === 'ascending'
                          ? icons.Top
                          : icons.Bottom
                        : icons.DCaret
                    "
                    size="small"
                    @click.stop="onChangeSort(scope.column.property)"
                  />
                </template>
                <el-button
                  v-else
                  size="small"
                  class="invisible"
                />
              </div>
              <!-- header_search_default -->
              <slot
                v-if="!$slots?.[`header_search_${scope.column.property}`]"
                name="header_search_default"
                :settings="colAttr"
              >
                <div
                  v-if="colAttr?.filterField"
                  class="leading-none"
                >
                  <crud-type-fields
                    v-model:value-field="tableFilters[getFilterFieldName(scope.column.property)]"
                    :type-value-field="colAttr.type"
                    :prefix-icon="icons.Search"
                    :col-api-name="scope.column.property"
                  />
                </div>
              </slot>
              <!-- header_search_[colApiName] -->
              <slot
                v-else
                :name="`header_search_${scope.column.property}`"
                :settings="colAttr"
              />
              <!-- header_search_[colApiName]_append -->
              <slot
                :name="`header_${scope.column.property}_append`"
                :settings="colAttr"
                :col-name="colProp"
              />
            </template>
            <template #default="scope">
              <div
                class="relative flex items-center"
                :class="[colAttr?.elAttr?.['class-name']]"
              >
                <div
                  class="relative inline-block"
                  style="max-width: 100%"
                >
                  <!-- slot cell_default -->
                  <slot
                    v-if="!$slots?.[`cell_${scope.column.property}`]"
                    name="cell_default"
                    :index="scope.$index"
                    :col-api-name="scope.column.property"
                    :value="scope.row[scope.column.property]"
                    :column-settings="colAttr"
                    :row="scope.row"
                  >
                    <CellContent
                      :col-data="colAttr"
                      :scope-col="scope"
                    />
                  </slot>
                  <!-- slot cell_[colApiName] -->
                  <slot
                    v-if="$slots?.[`cell_${scope.column.property}`]"
                    :name="`cell_${scope.column.property}`"
                    :index="scope.$index"
                    :col-api-name="scope.column.property"
                    :value="scope.row[scope.column.property]"
                    :column-settings="colAttr"
                    :row="scope.row"
                    :search-filters="tableFilters"
                  />
                </div>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="relative flex justify-end px-2 py-2">
      <el-progress
        v-show="tableLoading"
        class="absolute -top-1 left-0 right-0"
        :percentage="100"
        color="#0e7490"
        :stroke-width="2"
        :show-text="false"
        :indeterminate="tableLoading"
        :duration="1"
      />
      <!-- clear all filters -->
      <el-button
        v-show="showClearBtn && visibleResetBtn"
        class="gs-btn-text-neutral-light mr-2 h-auto px-0 py-0"
        @click="clearFilters"
      >
        <el-tooltip
          content="Reset all"
          effect="light"
          placement="top"
          :show-after="600"
        >
          <div>
            <el-icon class="gs-scaled-icon-xs">
              <CircleClose />
            </el-icon>
          </div>
        </el-tooltip>
      </el-button>
      <!-- refresh data -->
      <el-button
        v-if="!hideRefreshBtn"
        class="gs-btn-text-neutral-light ml-2 h-auto p-0"
        :loading="tableLoading"
        @click="fetchData"
      >
        <el-tooltip
          content="Refresh data"
          effect="light"
          placement="top"
          :show-after="600"
        >
          <div>
            <icon-ify
              v-show="!tableLoading"
              icon="ic:baseline-refresh"
              class="h-5 w-5"
            />
          </div>
        </el-tooltip>
      </el-button>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        v-model:page-size="pagination.onPage"
        :page-sizes="pageSizes"
        :size="$store.getters['auth/userScaledRatio'] < 1.1 ? 'small' : 'default'"
        :layout="`prev ${$windowWidth >= 640 ? ', pager' : ''}, next, total, sizes`"
        :total="tableTotal"
      />
    </div>
  </el-col>
</template>

<style scoped>
.el-table {
  th:hover {
    .gs-sort {
      visibility: visible !important;
    }
  }
}
</style>
