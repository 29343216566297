<template>
  <crud-details-page
    api="city/bank-income"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- HeroLevel -->
        <crud-field-number
          api-field-name="HeroLevel"
          number
        />
        <!-- GoldIncome -->
        <crud-field-number
          api-field-name="GoldIncome"
          required
        />
        <!-- GoldCapacity -->
        <crud-field-number
          api-field-name="GoldCapacity"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
