<script>
import { ArrowRightBold, DArrowLeft, Delete, DocumentCopy, Loading, Memo, SwitchButton, Tickets } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import GsTable from '!/components/shared/GsTable.vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  components: { Loading, GsTable, ArrowRightBold, DArrowLeft },
  setup() {
    const dialogSimulate = ref(false)
    const loadingSimulate = ref(false)
    const simulateData = ref({
      row: null,
      response: null
    })
    const route = useRoute()

    const simulate = (row) => {
      loadingSimulate.value = true
      simulateData.value.row = row

      const params = {
        loot_id: route.params?.id
      }
      app.$axios
        .get('/jwt/creator/simloot/', { params })
        .then(({ data }) => {
          simulateData.value.response = data || 'no data'
          simulateData.value.response = simulateData.value.response.replace('<pre>', '').replace('</pre>', '')
          dialogSimulate.value = true
        })
        .catch(app.$utils.catchError)
        .then(() => {
          loadingSimulate.value = false
        })
    }

    const clearSimulateData = () => {
      simulateData.value.row = null
      simulateData.value.response = null
    }

    return {
      icons: {
        Delete,
        Tickets,
        SwitchButton,
        Memo,
        DocumentCopy,
        DArrowLeft
      },
      groupsSettings: {
        0: { bg: 'bg-green-100' },
        1: { bg: 'bg-lime-100' },
        2: { bg: 'bg-emerald-200' },
        3: { bg: 'bg-teal-200' },
        4: { bg: 'bg-cyan-100' },
        5: { bg: 'bg-sky-100' },
        6: { bg: 'bg-indigo-100' },
        7: { bg: 'bg-purple-100' },
        8: { bg: 'bg-fuchsia-200' },
        9: { bg: 'bg-yellow-100' }
      },
      dialogSimulate,
      loadingSimulate,
      simulateData,
      simulate,
      clearSimulateData

    }
  },
  data() {
    return {
      groupsItems: [],
      previewToggle: false,
      forms: {
        groupsPercent: {
          form: { group: Array.from({ length: 10 }).fill(0) },
          errors: {},
          applied: Array.from({ length: 10 }).fill(0)
        },
        itemTemplate: {
          form: {
            group: 0,
            moveDown: 1,
            newItemTemplate: null,
            visibleMoveDown: false
          },
          errors: {}
        }
      },
      loading: {},
      lazyOffset: 1000,
      initRowsLength: 0,
      emptyTableText: 'loading loots',
      columns: [
        { title: ' ', prop: 'expand', width: 20 },
        { title: '#', prop: 'index', width: 50 },
        { title: 'Percent', prop: '_rowPercent', width: 80 },
        { title: 'Group\n%', prop: '_groupPercent', width: 80, headerAlignClass: 'whitespace-pre text-center align-top' },
        { prop: 'WeightGroup', width: 90 },
        { title: 'Weight preview', prop: 'Weight', width: 100 },
        { prop: 'Amount', width: 90 },
        { prop: 'GroupId', width: 90 },
        { prop: 'ItemTemplate', width: 420, cellAlignClass: 'text-left' },
        { prop: 'UseAsThumbnail', width: 90 },
        { title: 'In preview', prop: 'UseAsPreview', width: 110 },
        { prop: 'PreviewOrder', width: 90 },
        { prop: 'Action', width: 90 }
      ],
      referToFormRows: [],
      visibleGroups: {},
      formRefer: null,
      visibleRows: []
    }
  },
  computed: {
    thLabelMinHeight() {
      return `${35 * this.$store.getters['auth/userScaledRatioWidth']}px`
    }
  },
  watch: {
    visibleGroups: {
      handler() {
        this.updateVisibleRows()
      },
      deep: true
    }
  },
  methods: {
    updateVisibleRows() {
      const isAddedExpandRow = []
      const rows = []
      let groupIndex = 0
      this.referToFormRows.forEach((row, index) => {
        if (index && row.GroupId !== this.referToFormRows[index - 1].GroupId) {
          ++groupIndex
        }
        if (!isAddedExpandRow?.[groupIndex]) {
          isAddedExpandRow[groupIndex] = { counterRows: 0 }
          const expandRow = {
            _expandRow: true,
            _colspan: {
              expand: 2,
              _rowPercent: 11
            },
            GroupId: row.GroupId,
            _counterGroupedRows: isAddedExpandRow[groupIndex],
            _groupRowsIndex: groupIndex,
            cellClass: 'p-0 border-slate-400'
          }
          rows.push(expandRow)
        }
        row._prevSoredData = {
          rowGroupIndex: {
            prev: this.referToFormRows?.[index - 1]?._groupRowsIndex,
            current: row._groupRowsIndex,
            next: this.referToFormRows?.[index + 1]?._groupRowsIndex
          },
          lootsGroup: {
            prev: this.referToFormRows?.[index - 1]?.GroupId,
            current: row.GroupId,
            next: this.referToFormRows?.[index + 1]?.GroupId
          },
          visibleGroups: { ...this.visibleGroups }
        }
        row._groupRowsIndex = groupIndex
        if (this.visibleGroups?.[groupIndex]) {
          row._expandRow = false
          row._index = index
          rows.push({ ...row })
        }
        ++isAddedExpandRow[groupIndex].counterRows
      })
      Object.keys(this.visibleGroups).forEach((grIndex) => {
        if (grIndex * 1 > groupIndex) {
          delete this.visibleGroups[grIndex]
        }
      })
      this.visibleRows = rows
    },
    rowClassGs(row) {
      return [this.groupsSettings?.[(row?.GroupId || 0) - 1]?.bg] || undefined
    },
    dynamicRowClass({ rowData }) {
      return this.groupsSettings?.[(rowData?.GroupId || 0) - 1]?.bg || undefined
    },
    rowClass({ row }) {
      return this.groupsSettings?.[(row?.GroupId || 0) - 1]?.bg || undefined
    },
    onChangeThumbnail(val, rowIndex) {
      if (val) {
        this.referToFormRows.forEach((row, index) => {
          if (index !== rowIndex && row.UseAsThumbnail) {
            row.UseAsThumbnail = false
          }
        })
      }
    },
    onChangeAddItemTemplate(itemTemplateEntity) {
      if (!itemTemplateEntity) {
        return false
      }
      const newRow = {
        Amount: 1,
        GroupId: this.forms.itemTemplate.form.group + 1,
        ItemTemplate: itemTemplateEntity,
        PreviewOrder: 0,
        UseAsPreview: false,
        UseAsThumbnail: false,
        Weight: 1,
        WeightGroup: 1,
        _groupPercent: 0,
        _rowPercent: 0
      }
      this.calculateDataFromRows(true, newRow)
      this.$nextTick(() => {
        this.forms.itemTemplate.form.newItemTemplate = null
        this.visibleGroups[this.visibleRows[this.visibleRows.length - 1]._groupRowsIndex] = true
      })
    },
    calculateDataFromRows(calculateNewWeights = false, newRow = null, updateGroupsPercents = false) {
      this.loading.rows = true
      if (!this.referToFormRows) {
        this.referToFormRows = []
      }
      const calculated = {
        weightsPerGroup: Array.from({ length: 10 }).fill(0),
        weightsTotal: 0,
        weightsInGroup: Array.from({ length: 10 }).fill(0),
        weightsInGroupTotal: 0,
        existGroup: Array.from({ length: 10 }).fill(false),
        usedGroupsCounter: 0,
        groupPercent: Array.from({ length: 10 }).fill(0),
        groupRowsCounter: Array.from({ length: 10 }).fill(0),
        lastGroupWithMinGroupPercent: null
      }
      this.referToFormRows.forEach((row) => {
        const rowWeight = (row.Weight || 0) * 1
        const rowWeightInGroup = (row.WeightGroup || 0) * 1
        const index = row.GroupId - 1
        calculated.weightsTotal += rowWeight
        calculated.weightsPerGroup[index] += rowWeight
        calculated.weightsInGroup[index] += rowWeightInGroup
        calculated.weightsInGroupTotal += rowWeightInGroup
        ++calculated.groupRowsCounter[index]
        if (!calculated.existGroup[index]) {
          ++calculated.usedGroupsCounter
        }
        calculated.existGroup[index] = true
      })

      if (updateGroupsPercents) {
        calculated.groupPercent = [...this.forms.groupsPercent.form.group]
        calculated.groupPercent.some((val, index) => {
          if (val <= 0 && calculated.existGroup[index]) {
            this.averagePercentageGroups(calculated)
            return true
          }
          return false
        })
      } else {
        calculated.groupPercent.forEach((_, index) => {
          calculated.groupPercent[index] =
            ((calculated.weightsPerGroup[index] / (calculated.weightsTotal || 1)) * 100).toFixed(1) * 1
        })
      }

      if (newRow !== null) {
        const newRowGroupIndex = newRow.GroupId - 1
        calculated.weightsPerGroup[newRowGroupIndex] += 1
        calculated.weightsTotal += 1
        calculated.weightsInGroup[newRowGroupIndex] += 1
        calculated.weightsInGroupTotal += 1
        ++calculated.groupRowsCounter[newRowGroupIndex]
        if (!calculated.existGroup[newRowGroupIndex]) {
          ++calculated.usedGroupsCounter
          calculated.existGroup[newRowGroupIndex] = true
          this.averagePercentageGroups(calculated)
        }
        this.referToFormRows.push(newRow)
      }
      if (newRow || calculateNewWeights) {
        this.calculateNewWeights(calculated)
      }

      if (updateGroupsPercents) {
        calculated.groupPercent.forEach((_, index) => {
          this.forms.groupsPercent.form.group[index] =
            ((calculated.weightsPerGroup[index] / (calculated.weightsTotal || 1)) * 100).toFixed(1) * 1
        })
      }
      this.loading.rows = false
      this.updateVisibleRows()
      return calculated
    },
    initFormData(form) {
      this.referToFormRows =
        form.rows.sort((a, b) => {
          return b.PreviewOrder - a.PreviewOrder
        }) || []
      this.$watch(
        () => this.referToFormRows,
        () => {
          this.updateVisibleRows()
        }
      )
      const calculated = this.calculateDataFromRows()
      this.setRowsCalculatedColumns(form.rows, calculated)
      this.forms.groupsPercent.form.group = [...calculated.groupPercent]
      if (!this.referToFormRows.length) {
        this.emptyTableText = 'no loots'
      } else {
        this.emptyTableText = ''
      }
      this.initRowsLength = this.referToFormRows.length
      this.lazyShowFieldsRows(this.initRowsLength || 0)
      this.formRefer = form
      return form
    },
    lazyShowFieldsRows(rowsLength) {
      this.lazyOffset += 10
      if (this.lazyOffset <= rowsLength) {
        this.$utils.nextLoopEvent(100).then(() => {
          this.lazyShowFieldsRows(rowsLength)
        })
      }
    },
    setRowsCalculatedColumns(rows, calculated, newWeights = null) {
      if (newWeights) {
        calculated.weightsPerGroup = Array.from({ length: 10 }).fill(0)
        calculated.weightsTotal = 0
        rows.forEach((row, indexRow) => {
          if (newWeights && newWeights[indexRow] !== undefined) {
            row.Weight = newWeights[indexRow]
            calculated.weightsTotal += row.Weight
            calculated.weightsPerGroup[row.GroupId - 1] += row.Weight
          }
        })
      }
      rows.forEach((row) => {
        row._groupPercent = calculated.groupPercent[row.GroupId - 1]
        row._rowPercent = ((row.Weight / calculated.weightsTotal) * 100).toFixed(2) * 1
      })
    },
    averagePercentageGroups(calculated) {
      const newGroupsPercent = (100 / calculated.usedGroupsCounter).toFixed(2) * 1
      let rest = (100 - newGroupsPercent * calculated.usedGroupsCounter).toFixed(2) * 1
      calculated.existGroup.forEach((exist, index) => {
        if (exist) {
          calculated.groupPercent[index] = (newGroupsPercent + rest).toFixed(2) * 1
          this.forms.groupsPercent.form.group[index] = calculated.groupPercent[index]
          rest = 0
        }
      })
    },
    calculateNewWeights(calculated) {
      let minGroupPercent = null
      this.referToFormRows.forEach((row) => {
        const currentGroupIndex = row.GroupId - 1
        if (
          calculated.existGroup[currentGroupIndex] &&
          calculated.groupPercent[currentGroupIndex] &&
          (calculated.groupPercent[currentGroupIndex] <= minGroupPercent || minGroupPercent === null)
        ) {
          minGroupPercent = calculated.groupPercent[currentGroupIndex]
          calculated.lastGroupWithMinGroupPercent = currentGroupIndex
        }
      })
      const onePercentOfTotalWeights =
        calculated.weightsInGroup[calculated.lastGroupWithMinGroupPercent] /
        calculated.groupPercent[calculated.lastGroupWithMinGroupPercent]
      const maxPointsInGroup = Array.from({ length: 10 }).fill(0)
      maxPointsInGroup.forEach((_, index) => {
        maxPointsInGroup[index] = calculated.groupPercent[index] * onePercentOfTotalWeights
      })
      let newWeights = []
      let isCorrect = false
      let multiplier = 10
      while (!isCorrect) {
        isCorrect = true
        this.referToFormRows.some((row, indexRow) => {
          const currentGroupIndex = row.GroupId - 1
          const currentWeightInGroup = (row.WeightGroup || 0) * 1
          newWeights[indexRow] = 0
          if (calculated.weightsInGroup[currentGroupIndex] && currentWeightInGroup && maxPointsInGroup[currentGroupIndex]) {
            newWeights[indexRow] =
              maxPointsInGroup[currentGroupIndex] *
              ((currentWeightInGroup / (calculated.weightsInGroup[currentGroupIndex] || 1)) * multiplier)
            if (newWeights[indexRow] < 1) {
              multiplier *= 10
              isCorrect = false
              return !isCorrect
            }
          }
          newWeights[indexRow] = newWeights[indexRow].toFixed(1) * 10
          return !isCorrect
        })
      }
      newWeights = this.euclidOnArray(newWeights)
      this.setRowsCalculatedColumns(this.referToFormRows, calculated, newWeights)
    },
    euclidOnArray(weightsArr) {
      let validArray = false
      for (let i = 0; i < weightsArr.length; i++) {
        if (weightsArr[i] > 0) {
          validArray = true
          break
        }
      }
      if (!weightsArr.length || !validArray) {
        return weightsArr
      }
      if (weightsArr.length === 1) {
        return [1]
      }
      function gcd(a, b) {
        return b ? gcd(b, a % b) : a
      }
      let gdc = 0
      for (let i = 0; i < weightsArr.length; i++) {
        if (!gdc) {
          gdc = gcd(weightsArr[i], weightsArr[i + 1])
          i++
        } else {
          if (gdc === 1) {
            return weightsArr
          }
          gdc = gcd(weightsArr[i], gdc)
        }
      }
      for (let i = 0; i < weightsArr.length; i++) {
        weightsArr[i] = weightsArr[i] / gdc
      }
      return weightsArr
    },
    deleteRow(rowIndex) {
      this.loading.rows = true
      this.loading[`delete${rowIndex}`] = true
      const rowID = this.referToFormRows?.[rowIndex]?.ID
      if (rowID && this.formRefer?._Relations) {
        Object.keys(this.formRefer?._Relations).forEach((relationKey) => {
          if (relationKey.includes(`rows.${rowID}.`)) {
            delete this.formRefer._Relations[relationKey]
          }
        })
      }
      this.referToFormRows.splice(rowIndex, 1)

      const calculated = this.calculateDataFromRows(true)
      this.forms.groupsPercent.form.group = [...calculated.groupPercent]
      this.loading[`delete${rowIndex}`] = false
    },
    deleteGroup(groupIndex) {
      this.loading.rows = true
      this.loading[`deleteGroup${groupIndex}`] = true

      this.referToFormRows.forEach((row) => {
        if ((row.GroupId - 1) * 1 === groupIndex) {
          const rowID = row?.ID
          if (rowID && this.formRefer?._Relations) {
            Object.keys(this.formRefer._Relations).forEach((relationKey) => {
              if (relationKey.includes(`rows.${rowID}.`)) {
                delete this.formRefer._Relations[relationKey]
              }
            })
          }
        }
      })

      this.referToFormRows = this.referToFormRows.filter((row) => {
        return (row.GroupId - 1) * 1 !== groupIndex
      })
      const calculated = this.calculateDataFromRows(true)
      this.forms.groupsPercent.form.group = [...calculated.groupPercent]
      this.loading[`deleteGroup${groupIndex}`] = false
      delete this.visibleGroups[groupIndex]
    },
    deleteAllRows(form) {
      this.loading.rows = true
      this.loading.deleteAllRows = true

      if (this.formRefer?._Relations) {
        this.formRefer._Relations = {}
      }

      form.rows = []
      this.referToFormRows = form.rows
      this.visibleGroups = {}
      this.forms.groupsPercent.form.group = Array.from({ length: 10 }).fill(0)
      this.loading.rows = false
      this.loading.deleteAllRows = false
    },
    sortRowsByGroups() {
      this.loading.rows = true
      this.$utils.nextLoopEvent(50).then(() => {
        this.visibleGroups = {}
        this.referToFormRows
          .sort((a, b) => {
            return a.ID - b.ID
          })
          .sort((a, b) => {
            return a.GroupId - b.GroupId
          })
        this.loading.rows = false
      })
    },
    resetRowsWeight() {
      this.loading.rows = true
      this.loading.resetRowsWeight = true
      this.referToFormRows.forEach((row) => {
        row.WeightGroup = 1
      })
      this.calculateDataFromRows(true)
      this.loading.resetRowsWeight = false
    },
    resetRowsPreviewOrder() {
      this.loading.rows = true
      this.loading.resetRowsPreviewOrder = true
      this.referToFormRows.forEach((row) => {
        row.PreviewOrder = 0
      })
      this.loading.rows = false
      this.loading.resetRowsPreviewOrder = false
    },
    sortRowsPreviewColumn() {
      this.loading.rows = true
      this.loading.sortRowsPreviewColumn = true
      this.referToFormRows = this.referToFormRows
        .sort((a, b) => {
          return a.ID - b.ID
        })
        .sort((a, b) => {
          return b.PreviewOrder - a.PreviewOrder
        })
      this.loading.rows = false
      this.loading.sortRowsPreviewColumn = false
    },
    recalculateRowsOrder() {
      this.loading.rows = true
      this.loading.recalculateRowsOrder = true
      let order = this.referToFormRows.length || 0
      this.referToFormRows.forEach((row) => {
        row.PreviewOrder = order--
      })
      this.loading.rows = false
      this.loading.recalculateRowsOrder = false
    },
    duplicateRow(rowIndex) {
      this.loading.rows = true
      this.loading[`duplicate_${rowIndex}`] = true
      const duplicatedRow = this.$utils.cloneDeep(this.referToFormRows[rowIndex])
      delete duplicatedRow.ID
      duplicatedRow.UseAsThumbnail = false
      this.referToFormRows.splice(rowIndex + 1, 0, duplicatedRow)
      this.calculateDataFromRows(true)
      this.loading[`duplicate_${rowIndex}`] = false
    },
    groupsApply() {
      this.loading.rows = true
      this.loading.groupsApply = true
      this.calculateDataFromRows(true, null, true)
      this.loading.groupsApply = false
    },
    changeRowGroup(row) {
      if (
        row?._prevSoredData?.lootsGroup?.current === row?._prevSoredData?.lootsGroup?.prev ||
        row?._prevSoredData?.lootsGroup?.current === row?._prevSoredData?.lootsGroup?.next ||
        this.referToFormRows[row._index].GroupId === row?._prevSoredData?.lootsGroup?.prev ||
        this.referToFormRows[row._index].GroupId === row?._prevSoredData?.lootsGroup?.next
      ) {
        let shiftingGroups = 0
        if (row?._prevSoredData?.lootsGroup?.current === row?._prevSoredData?.lootsGroup?.prev) {
          shiftingGroups += 1
        }
        if (row?._prevSoredData?.lootsGroup?.current === row?._prevSoredData?.lootsGroup?.next) {
          shiftingGroups += 1
        }
        if (this.referToFormRows[row._index].GroupId === row?._prevSoredData?.lootsGroup?.prev) {
          shiftingGroups += -1
        }
        if (this.referToFormRows[row._index].GroupId === row?._prevSoredData?.lootsGroup?.next) {
          shiftingGroups += -1
        }
        const oldVisibleGroups = row?._prevSoredData?.visibleGroups || {}
        const newVisibleGroups = {}
        Object.entries(oldVisibleGroups).forEach(([gr, visibility]) => {
          gr *= 1
          if (shiftingGroups >= 0) {
            newVisibleGroups[gr + (gr > row?._prevSoredData?.rowGroupIndex?.current ? shiftingGroups : 0)] = visibility
          } else if (gr < row?._prevSoredData?.rowGroupIndex?.current) {
            newVisibleGroups[gr] = visibility
          } else if (oldVisibleGroups?.[gr - shiftingGroups] === true) {
            newVisibleGroups[gr] = oldVisibleGroups?.[gr - shiftingGroups]
          }
        })
        if (shiftingGroups > 0 && row?._prevSoredData?.lootsGroup?.current === row?._prevSoredData?.lootsGroup?.next) {
          newVisibleGroups[this.referToFormRows[row._index]._groupRowsIndex + 1] = true
        }
        newVisibleGroups[this.referToFormRows[row._index]._groupRowsIndex] = true
        this.visibleGroups = newVisibleGroups
      }

      this.loading.rows = true
      const calculated = this.calculateDataFromRows(true)
      this.forms.groupsPercent.form.group = [...calculated.groupPercent]
    },
    rowsPreviewToggle(toggleVal) {
      this.loading.rows = true
      this.referToFormRows.forEach((row) => {
        row.UseAsPreview = toggleVal
      })
      this.loading.rows = false
    },
    moveDownItemsTemplate() {
      if (!this.$refs?.moveDownForm) {
        return false
      }
      this.loading.rows = true
      this.loading.moveDownItemsTemplate = true
      this.$refs.moveDownForm.validate((isValid) => {
        if (isValid) {
          this.visibleGroups = {}
          this.$utils.nextLoopEvent(50).then(() => {
            const offset = this.forms.itemTemplate.form.moveDown
            let rowsItemsTemplate = this.referToFormRows.map((row) => {
              return row.ItemTemplate
            })
            rowsItemsTemplate = [...Array.from({ length: offset || 0 }).fill(null), ...rowsItemsTemplate.slice(0, offset * -1)]
            rowsItemsTemplate.forEach((newRowItemTemplate, rowIndex) => {
              if (this.referToFormRows?.[rowIndex]) {
                this.referToFormRows[rowIndex].ItemTemplate = newRowItemTemplate
              }
            })
            this.forms.itemTemplate.form.moveDown = 1
            this.forms.itemTemplate.form.visibleMoveDown = false
            this.visibleGroups = { 0: true }
            this.loading.rows = false
            this.loading.moveDownItemsTemplate = false
          })
        } else {
          this.loading.rows = false
          this.loading.moveDownItemsTemplate = false
        }
      })
    },
    renderSaveData(form) {
      form.rows = this.referToFormRows
      if (form?.rows?.length) {
        form.rows.some((row) => {
          if (row.UseAsThumbnail) {
            form.PreviewItem = row.ItemTemplate
            return true
          }
          return false
        })
      } else {
        form.PreviewItem = null
      }
      return form
    },
    expandRow(row) {
      this.visibleGroups[row._groupRowsIndex] = !this.visibleGroups[row._groupRowsIndex]
      // this.referToFormRows = [...this.referToFormRows]
    },
    move(index, up = true) {
      if (!this.referToFormRows[index].ItemTemplate) {
        return false
      }
      const replacedIndex = up ? index - 1 : index + 1
      if (replacedIndex >= 0 && replacedIndex < this.referToFormRows.length) {
        this.referToFormRows[replacedIndex].ItemTemplate = this.referToFormRows[index].ItemTemplate
          ? { ...this.referToFormRows[index].ItemTemplate }
          : null
      }
      this.referToFormRows[index].ItemTemplate = null
      this.updateVisibleRows()
    }
  }
}
</script>

<template>
  <crud-details-page
    api="loots/loot"
    disable-top-margin
    :render-init-item="initFormData"
    :render-saved-item="renderSaveData"
    re-load-data-after-save
    enable-relations
    :actions="{
      crudNewVersion: false
    }"
  >
    <template #form="{ form }">
      <fields-col :sm="18">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
      </fields-col>
      <fields-col :sm="24">
        <GsTable
          :columns="columns"
          :rows="visibleRows || []"
          header-align-class="text-center align-top"
          cell-align-class="text-center justify-center items-center"
          :row-class-render="rowClassGs"
          :loading-string="emptyTableText"
          empty-string="no loots"
          force-all
        >
          <template #topHead="{ headerClass }">
            <tr :class="[headerClass]">
              <td
                colspan="13"
                class="px-2 py-4 text-center relative"
              >
                <el-button
                  class="gs-loading gs-height-related-lg bg-transparent gs-btn-outlined-primary px-4 tracking-wide absolute left-2 top-2"
                  tabindex="-1"
                  :loading="loadingSimulate"
                  @click="simulate(row)"
                >
                  <icon-ify
                    icon="fad:random-1dice"
                    class="gs-scaled-icon-md text-cyan-600"
                  /> Simulate
                </el-button>
                <div><span class="gs-related-py-sm">Groups:</span></div>
                <el-form
                  :model="forms.groupsPercent.form"
                  class="gs-font-scaled mt-1"
                >
                  <div class="inline-flex items-center">
                    <div class="pr-4">
                      <el-button
                        :loading="loading.groupsApply"
                        type="primary"
                        plain
                        class="gs-loading gs-height-related-lg ml-4 mt-1 px-4 tracking-wide"
                        :disabled="loading.rows"
                        @click="groupsApply"
                      >
                        apply
                      </el-button>
                    </div>
                    <div
                      v-for="(v, i) in forms.groupsPercent.form.group"
                      :key="i"
                      class="inline-flex pl-1.5"
                    >
                      <div class="rounded">
                        <div
                          class="gs-related-px-sm flex h-full scale-90 items-center justify-center rounded"
                          :class="groupsSettings[i].bg"
                        >
                          {{ i + 1 }}:
                        </div>
                      </div>
                      <div class="w-20 py-0.5">
                        <crud-field-number
                          :key="i"
                          :external-errors="forms.groupsPercent.errors"
                          :form="forms.groupsPercent.form"
                          :api-field-name="`group.${i}`"
                          :label="false"
                          :min="0"
                          :max="100"
                          :precision="2"
                          slim
                          doc-icon-class="-top-3 -right-3"
                          :doc-field-name="`group.${i + 1}`"
                          disable-doc-settings
                        />
                      </div>
                      <div
                        v-if="forms.groupsPercent.form?.group?.[i]"
                        class="flex h-full items-center pt-1"
                      >
                        <el-popconfirm
                          title="Are you sure to delete this?"
                          :width="240"
                          @confirm="deleteGroup(i)"
                        >
                          <template #reference>
                            <el-button
                              :loading="loading[`deletingGroup${i}`]"
                              :icon="icons.Delete"
                              class="gs-btn-outlined-danger-light gs-height-related-sm font-related-xl px-1 leading-none"
                            />
                          </template>
                        </el-popconfirm>
                      </div>
                    </div>
                  </div>
                </el-form>
              </td>
            </tr>
          </template>
          <template #header_WeightGroup>
            <div
              class="pb-1.5"
              :style="{ minHeight: thLabelMinHeight }"
            >
              Group weight
            </div>
            <div v-if="referToFormRows?.length">
              <el-tooltip
                content="Set all groups weight to 1"
                effect="light"
                placement="top"
                :show-after="600"
              >
                <el-button
                  :loading="loading.resetRowsWeight"
                  :icon="icons.SwitchButton"
                  class="gs-btn-warning-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                  :disabled="loading.rows"
                  @click="resetRowsWeight"
                />
              </el-tooltip>
            </div>
          </template>
          <template #header_GroupId>
            <div :style="{ minHeight: thLabelMinHeight }">
              Group
            </div>
            <div
              v-if="referToFormRows?.length"
              class="flex justify-center"
            >
              <div>
                <el-tooltip
                  content="Sort groups ascending"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <el-button
                    :icon="icons.Tickets"
                    class="gs-btn-primary-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                    :disabled="loading.rows"
                    @click="sortRowsByGroups"
                  />
                </el-tooltip>
              </div>
              <div>
                <el-popconfirm
                  title="Are you sure to delete this?"
                  :width="240"
                  @confirm="deleteAllRows(form)"
                >
                  <template #reference>
                    <span>
                      <el-tooltip
                        content="Delete all groups"
                        effect="light"
                        placement="top"
                        :show-after="600"
                      >
                        <el-button
                          :loading="loading.deleteAllRows"
                          :icon="icons.Delete"
                          class="gs-btn-danger-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                          :disabled="loading.rows"
                        />
                      </el-tooltip>
                    </span>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </template>
          <template #header_ItemTemplate>
            <div
              class="pb-1.5"
              :style="{ minHeight: thLabelMinHeight }"
            >
              Item template
            </div>
            <div class="flex justify-between">
              <div class="flex text-left">
                <div class="pr-1.5 leading-7">
                  Add:
                </div>
                <div class="w-64">
                  <crud-field-selector-items
                    :external-errors="forms.itemTemplate.errors"
                    :form="forms.itemTemplate.form"
                    api-field-name="newItemTemplate"
                    slim
                    disable-edit-model
                    :label="false"
                    :disabled="loading.rows"
                    disable-relations
                    doc-icon-class="-top-6 right-0"
                    @change="onChangeAddItemTemplate($event)"
                  />
                </div>
                <div
                  class="pl-0.5"
                  :style="{ width: `${80 * $store.getters['auth/userScaledRatioWidth']}px` }"
                >
                  <el-select
                    v-model="forms.itemTemplate.form.group"
                    :size="$store.getters['auth/userScaledSize']"
                    class="gs-field"
                    :disabled="loading.rows"
                  >
                    <el-option
                      v-for="i in 10"
                      :key="i"
                      :label="`grp ${i}`"
                      :value="i - 1"
                    />
                  </el-select>
                </div>
              </div>
              <div v-if="referToFormRows?.length">
                <el-popover
                  v-model:visible="forms.itemTemplate.form.visibleMoveDown"
                  placement="bottom"
                  :width="110 * $store.getters['auth/userScaledRatioWidth']"
                  trigger="click"
                >
                  <template #reference>
                    <div>
                      <el-button
                        plain
                        type="primary"
                        class="gs-height-related-sm font-related-lg ml-1.5 px-1 leading-none"
                        :disabled="loading.rows"
                      >
                        Move down
                        <el-icon
                          :size="14"
                          class="ml-1 -rotate-90"
                        >
                          <DArrowLeft />
                        </el-icon>
                      </el-button>
                    </div>
                  </template>
                  <div class="gs-font-scaled">
                    <div class="mb-1.5">
                      Move down items:
                    </div>
                    <el-form
                      ref="moveDownForm"
                      :model="forms.itemTemplate.form"
                    >
                      <div class="w-full">
                        <crud-field-number
                          :external-errors="forms.itemTemplate.errors"
                          :form="forms.itemTemplate.form"
                          api-field-name="moveDown"
                          :min="1"
                          slim
                          :label="false"
                          doc-icon-class="-top-7 right-0"
                        />
                      </div>
                    </el-form>
                    <div>
                      <el-button
                        :loading="loading.moveDownItemsTemplate"
                        type="primary"
                        class="gs-loading gs-height-related-sm font-related-lg mt-2 w-full px-4 tracking-wide"
                        :disabled="loading.rows || !!forms.itemTemplate.errors.moveDown"
                        @click="moveDownItemsTemplate"
                      >
                        apply
                      </el-button>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
          </template>
          <template #header_UseAsPreview>
            <div :style="{ minHeight: thLabelMinHeight }">
              In preview
            </div>
            <div v-if="referToFormRows?.length">
              <el-checkbox
                v-model="previewToggle"
                class="gs-font-scaled"
                :disabled="loading.rows"
                @change="rowsPreviewToggle"
              >
                <span class="text-blue-400">{{ previewToggle ? 'Uncheck all' : 'Check all' }}</span>
              </el-checkbox>
            </div>
          </template>
          <template #header_PreviewOrder>
            <div
              class="pb-1.5"
              :style="{ minHeight: thLabelMinHeight }"
            >
              Preview order
            </div>
            <div
              v-if="referToFormRows?.length"
              class="flex justify-between"
            >
              <div>
                <el-tooltip
                  content="Reset all preview order to 0"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <el-button
                    :loading="loading.resetRowsPreviewOrder"
                    :icon="icons.SwitchButton"
                    class="gs-btn-warning-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                    :disabled="loading.rows"
                    @click="resetRowsPreviewOrder"
                  />
                </el-tooltip>
              </div>
              <div>
                <el-tooltip
                  content="Sort by preview order descending"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <el-button
                    :loading="loading.sortRowsPreviewColumn"
                    :icon="icons.Tickets"
                    class="gs-btn-primary-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                    :disabled="loading.rows"
                    @click="sortRowsPreviewColumn"
                  />
                </el-tooltip>
              </div>
              <div>
                <el-tooltip
                  content="Recalculate the order"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <el-button
                    :loading="loading.recalculateRowsOrder"
                    :icon="icons.Memo"
                    class="gs-btn-success-hover-outlined gs-height-related-sm font-related-lg px-1 leading-none"
                    :disabled="loading.rows"
                    @click="recalculateRowsOrder"
                  />
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #cell_expand="{ row }">
            <el-button
              v-if="row._expandRow"
              size="small"
              link
              @click="expandRow(row)"
            >
              <el-icon
                :size="14"
                :class="{ 'rotate-90': !!visibleGroups[row._groupRowsIndex] }"
              >
                <ArrowRightBold />
              </el-icon>
            </el-button>
          </template>
          <template #cell_index="{ row }">
            <template v-if="!row._expandRow">
              {{ row._index + 1 }}
            </template>
          </template>
          <template #cell__rowPercent="{ row }">
            <template v-if="!row._expandRow">
              <template v-if="loading.rows">
                <el-icon
                  class="is-loading"
                  :size="15"
                >
                  <Loading />
                </el-icon>
              </template>
              <template v-else>
                {{ row._rowPercent?.toFixed(2) }}%
              </template>
            </template>
            <template v-else>
              <div
                class="ml-0 mr-auto flex h-[40px] w-full cursor-pointer items-center justify-start"
                @click="expandRow(row)"
              >
                <span class="font-black">Group: {{ row.GroupId }}</span>
                <span class="font-related-xs pl-4"> (rows: {{ row._counterGroupedRows.counterRows }})</span>
              </div>
            </template>
          </template>
          <template #cell__groupPercent="{ row }">
            <template v-if="!row._expandRow">
              <template v-if="loading.rows">
                <el-icon
                  class="is-loading"
                  :size="15"
                >
                  <Loading />
                </el-icon>
              </template>
              <template v-else>
                {{ row._groupPercent?.toFixed(2) }}%
              </template>
            </template>
          </template>
          <template #cell_WeightGroup="{ row }">
            <crud-field-number
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.WeightGroup`"
              slim
              :label="false"
              :min="0"
              :disabled="loading.rows"
              disable-doc
              @change="calculateDataFromRows(true)"
            />
          </template>
          <template #cell_Weight="{ row }">
            <template v-if="!row._expandRow">
              <template v-if="loading.rows">
                <el-icon
                  class="is-loading"
                  :size="15"
                >
                  <Loading />
                </el-icon>
              </template>
              <template v-else>
                {{ row.Weight }}
              </template>
            </template>
          </template>
          <template #cell_Amount="{ row }">
            <crud-field-number
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.Amount`"
              slim
              :label="false"
              :min="0"
              :disabled="loading.rows"
              disable-doc
            />
          </template>
          <template #cell_GroupId="{ row }">
            <crud-field-number
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.GroupId`"
              slim
              :label="false"
              :min="1"
              :max="10"
              :disabled="loading.rows"
              disable-doc
              @change="changeRowGroup(row)"
            />
            <span v-else />
          </template>
          <template #cell_ItemTemplate="{ row }">
            <div class="flex w-full flex-row flex-nowrap items-center">
              <div class="w-96">
                <crud-field-selector-items
                  v-if="!row._expandRow"
                  :api-field-name="`rows.${row._index}.ItemTemplate`"
                  slim
                  :label="false"
                  :disabled="loading.rows"
                  disable-doc
                  @change="updateVisibleRows"
                />
              </div>
              <div class="flex flex-col items-baseline">
                <el-button
                  :icon="icons.DArrowLeft"
                  plain
                  type="primary"
                  class="gs-btn-outlined-primary-light gs-height-related-xs rotate-90 p-0 leading-none"
                  :disabled="!row.ItemTemplate"
                  @click="move(row._index, true)"
                />
                <el-button
                  :icon="icons.DArrowLeft"
                  plain
                  type="primary"
                  class="gs-btn-outlined-primary-light gs-height-related-xs -rotate-90 p-0 leading-none"
                  :disabled="!row.ItemTemplate"
                  @click="move(row._index, false)"
                />
              </div>
            </div>
          </template>
          <template #cell_UseAsThumbnail="{ row }">
            <crud-field-switcher
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.UseAsThumbnail`"
              slim
              :label="false"
              :disabled="loading.rows"
              disable-doc
              @change="onChangeThumbnail($event, row._index)"
            />
          </template>
          <template #cell_UseAsPreview="{ row }">
            <crud-field-switcher
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.UseAsPreview`"
              slim
              :label="false"
              :disabled="loading.rows"
              disable-doc
            />
            {{}}
          </template>
          <template #cell_PreviewOrder="{ row }">
            <crud-field-number
              v-if="!row._expandRow"
              :api-field-name="`rows.${row._index}.PreviewOrder`"
              slim
              :label="false"
              :min="0"
              :disabled="loading.rows"
              disable-doc
            />
          </template>
          <template #cell_Action="{ row }">
            <template v-if="!row._expandRow">
              <el-tooltip
                content="Duplicate"
                effect="light"
                placement="top"
                :show-after="600"
              >
                <el-button
                  :loading="loading[`duplicate_${row._index}`]"
                  :icon="icons.DocumentCopy"
                  class="gs-btn-outlined-primary-light gs-height-related-xl font-related-xl px-1 leading-none"
                  :disabled="loading.rows"
                  @click="duplicateRow(row._index)"
                />
              </el-tooltip>
              <el-popconfirm
                title="Are you sure to delete this?"
                :width="240"
                @confirm="deleteRow(row._index)"
              >
                <template #reference>
                  <span>
                    <el-tooltip
                      content="Delete"
                      effect="light"
                      placement="top"
                      :show-after="600"
                    >
                      <el-button
                        :loading="loading[`delete${row._index}`]"
                        :icon="icons.Delete"
                        class="gs-btn-outlined-danger-light gs-height-related-xl font-related-xl px-1 leading-none"
                        :disabled="loading.rows"
                      />
                    </el-tooltip>
                  </span>
                </template>
              </el-popconfirm>
            </template>
          </template>
        </GsTable>
      </fields-col>
      <fields-col class="mt-10">
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
    </template>
    <template #drawers>
      <el-dialog
        v-model="dialogSimulate"
        width="90%"
        title="Simulate"
        @close="clearSimulateData"
      >
        <div class="my-2 py-10 border-t-2 border-t-neutral-300 ">
          <el-scrollbar max-height="65vh">
            <pre>{{ simulateData.response }}</pre>
          </el-scrollbar>
        </div>
      </el-dialog>
    </template>
  </crud-details-page>
</template>

<style lang="postcss" scoped>
:deep(.el-table) {
  th {
    height: 100%;
    .cell {
      word-break: normal;
      overflow-wrap: normal;
      line-height: 1.3em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
