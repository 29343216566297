<script>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'EnvSelector',
  props: {
    typeValueField: {
      type: undefined,
      default: undefined
    },
    label: {
      type: String,
      default: 'Environment'
    }
  },
  setup() {
    const options = ref([])
    const order = {
      local: 1,
      dev: 2,
      prod: 3
    }
    let defaultEnv = null
    Object.entries(app.$store.getters['auth/envs']).forEach(([env, url]) => {
      if (url) {
        if (env === 'local') {
          if (app.$utils.isDevMode()) {
            defaultEnv = 'local'
            options.value.push({ value: env, label: env })
          }
        } else {
          options.value.push({ value: env, label: env })
          if (!defaultEnv && env === 'prod') {
            defaultEnv = 'prod'
          }
          if (env === 'timeshift' && import.meta.env.VITE_TIMESHIFT === 'on') {
            defaultEnv = 'timeshift'
          }
        }
      }
    })
    options.value.sort((a, b) => (order?.[a.value] || 99) - (order?.[b.value] || 99))
    if (!defaultEnv) {
      defaultEnv = options.value.slice(-1)[0]?.value
    }
    return {
      options,
      defaultEnv
    }
  }
}
</script>

<template>
  <crud-type-fields
    v-bind="$props"
    type-value-field="select"
    full-width
    :options="options"
    :init-value="defaultEnv"
    :label="label"
    :clearable="false"
  />
</template>

<style scoped></style>
