<script>
import { Delete, Refresh } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'VersionsInfoTags',
  components: { Refresh, Delete },
  props: {
    quantity: {
      type: Object,
      default: () => ({
        dev2prod: 0,
        versioned: 0,
        ab: 0
      })
    },
    childrenEnvs: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    return {
      tagsSettings: CrudTagsSettings(...Object.keys(props.quantity)),
      visibleTags: computed(() => {
        return Object.fromEntries(
          Object.entries(props.quantity).filter(([, quantity]) => {
            return quantity > 0
          })
        )
      })
    }
  }
}
</script>

<template>
  <div class="my-1 flex flex-wrap gap-y-1.5">
    <el-tag
      v-for="(value, tagName, index) in visibleTags"
      :key="index"
      class="gs-font-scaled gs-height-related-sm mx-0.5 h-4 border-transparent px-1 transition hover:scale-110"
      :class="[tagsSettings[tagName].styleClasses]"
      size="small"
      effect="dark"
    >
      <span class="inline-flex items-center">
        <span class="font-related-xs">{{ tagName }}</span>
        <template v-if="tagsSettings[tagName].syncIcon">
          <el-icon
            v-if="tagName === 'dev2prod' && childrenEnvs.childrenIsProd"
            class="h-max pl-0.5"
          >
            <Refresh />
          </el-icon>
          <el-icon
            v-else
            class="ml-1 h-max"
          >
            <Delete />
          </el-icon>
        </template>
        <span v-else>: {{ value }}</span>
      </span>
    </el-tag>
  </div>
</template>
