<script>
import { Moon, Sunny, SwitchButton } from '@element-plus/icons-vue'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { debounce } from 'lodash'
import { saveLoginRedirect } from '!/composition/utilities'

export default {
  name: 'UserSettingsBox',
  components: { SwitchButton },
  setup() {
    const store = useStore()
    const storedSettings = { ...store.getters['auth/userLocalSettings'] }
    const settings = ref({
      darkMode: storedSettings?.darkMode || false,
      fontSize: storedSettings?.fontSize || 12,
      actionColum: storedSettings?.actionColum || 'right',
      imgPreviewScale: storedSettings?.imgPreviewScale || 0,
      stripedTables: storedSettings?.stripedTables || false,
      ccd: storedSettings?.ccd || false,
      showZeroInNumbers: storedSettings?.showZeroInNumbers !== false,
      showZeroAmountsWarnings: storedSettings?.showZeroAmountsWarnings || false
    })

    watch(
      settings,
      debounce(() => {
        store.commit('auth/userLocalSettings', { ...store.getters['auth/userLocalSettings'], ...settings.value })
      }, 500),
      { deep: true }
    )

    return {
      settings,
      icons: {
        Sunny,
        Moon
      }
    }
  },
  methods: {
    logout() {
      saveLoginRedirect(this.$route)
      // important because we want force first 'open-form/close/' api first (in beforeUnmount() CrudDetailsPage)
      this.$router.push({ name: 'login' }).then(async () => {
        await this.$axios.get('/admin/api/logged/logout/')
        this.$store.dispatch('auth/logout')
      })
    }
  }
}
</script>

<template>
  <el-dropdown>
    <el-button
      class="gs-button-page-header gs-font-scaled border-0 p-0"
      size="small"
      round
    >
      <el-avatar
        v-if="$store.getters['auth/userData']?.Avatar"
        :src="$store.getters['auth/userData'].Avatar"
        class="gs-scaled-icon-xl"
      />
      <icon-ify
        v-else
        icon="bxs:user-circle"
        class="gs-scaled-icon-xl"
      />
    </el-button>
    <template #dropdown>
      <div class="gs-font-scaled w-96 px-1 py-3">
        <div class="flex flex-row items-center justify-between px-4">
          <div>
            <div class="font-related-lg">
              {{ $store.getters['auth/userData']?.Name }}
            </div>
            <div class="font-related text-gray-400">
              {{ $store.getters['auth/userData']?.Email }}
            </div>
          </div>
          <div>
            <div class="gs-font-scaled pl-5">
              <el-button
                type=""
                class="gs-button font-related-lg py-0 text-gs-bg hover:text-cyan-500 active:text-cyan-300"
                @click="logout"
              >
                logout <el-icon class="gs-scaled-icon-xl pl-2">
                  <SwitchButton />
                </el-icon>
              </el-button>
            </div>
          </div>
        </div>
        <el-collapse class="gs-inverted gs-scaled mt-1 px-2">
          <el-collapse-item name="global_config">
            <template #title>
              <span class="text-gray-500">Global configuration</span>
            </template>
            <div class="gs-font-scaled flex flex-row items-center justify-between px-4">
              <div>
                <icon-ify
                  icon="fluent:text-font-size-24-regular"
                  class="gs-scaled-icon-lg inline-block text-neutral-400"
                />
                <el-input-number
                  v-model="settings.fontSize"
                  class="scale-90"
                  :style="{ width: `${90 * $store.getters['auth/userScaledRatio']}px` }"
                  :size="$store.getters['auth/userScaledMediumSize']"
                  :min="12"
                  :max="20"
                />
              </div>
              <template v-if="$utils.isDevMode()">
                <div class="leading-none">
                  <el-switch
                    v-model="settings.darkMode"
                    :size="$store.getters['auth/userScaledLargeSize']"
                    class="theme-switcher mr-4 scale-110"
                    inline-prompt
                    :active-icon="icons.Moon"
                    :inactive-icon="icons.Sunny"
                    style="--el-switch-on-color: #faf8f8; --el-switch-off-color: #faf8f8; --el-switch-border-color: #e5dddd"
                  />
                </div>
              </template>
            </div>
            <div class="gs-font-scaled mt-2.5 flex w-full flex-row items-center justify-start px-4">
              <div class="font-related-lg leading-relaxed text-neutral-500">
                Action column on
                <el-switch
                  v-model="settings.actionColum"
                  inline-prompt
                  active-value="right"
                  inactive-value="left"
                  active-text="Right&nbsp;"
                  inactive-text="&nbsp;Left"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #13ce66"
                />
                side
              </div>
            </div>
            <div class="gs-font-scaled mt-2.5 flex w-full flex-row items-center justify-start px-4">
              <div class="font-related-lg whitespace-nowrap pr-4 leading-relaxed text-neutral-500">
                Preview image resize:
              </div>
              <el-slider
                v-model="settings.imgPreviewScale"
                :step="1"
              />
            </div>
            <div class="gs-font-scaled mt-2.5 flex w-full flex-row items-center justify-between px-4">
              <div class="flex">
                <div class="font-related-lg whitespace-nowrap pr-1 leading-relaxed text-neutral-500">
                  Striped list tables:
                </div>
                <el-switch
                  v-model="settings.stripedTables"
                  inline-prompt
                  active-text="On&nbsp;"
                  inactive-text="Off"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #0478f6"
                  size="small"
                />
              </div>
              <div class="flex justify-end">
                <div class="font-related-lg whitespace-nowrap pr-1 leading-relaxed text-neutral-500">
                  Menu CCD:
                </div>
                <el-switch
                  v-model="settings.ccd"
                  inline-prompt
                  active-text="Y"
                  inactive-text="N"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #0478f6"
                  size="small"
                />
              </div>
            </div>
            <div class="gs-font-scaled mt-2.5 flex w-full flex-row items-center justify-start px-4">
              <div class="font-related-lg leading-relaxed text-neutral-500">
                <el-switch
                  v-model="settings.showZeroInNumbers"
                  inline-prompt
                  active-text="Show&nbsp;"
                  inactive-text="&nbsp;Hide"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #0478f6"
                />
                zero value for numbers fields
              </div>
            </div>
            <div class="gs-font-scaled mt-2.5 flex w-full flex-row items-center justify-start px-4">
              <div class="font-related-lg leading-relaxed text-neutral-500">
                <el-switch
                  v-model="settings.showZeroAmountsWarnings"
                  inline-prompt
                  active-text="Show&nbsp;"
                  inactive-text="&nbsp;Hide"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #0478f6"
                />
                zero amounts warnings (before save)
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </template>
  </el-dropdown>
</template>

<style lang="postcss">
.theme-switcher {
  .el-icon {
    color: black !important;
  }
}
</style>
