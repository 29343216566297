<script>
import EventItemsComponent from './components/EventItemsComponent.vue'
import CrudFieldSelectorSpins from '@/components/forms/crud-fields/CrudFieldSelectorSpins.vue'
import CrudFieldSelectorChests from '@/components/forms/crud-fields/CrudFieldSelectorChests.vue'
import CrudFieldSelectorTalentLoots from '@/components/forms/crud-fields/CrudFieldSelectorTalentLoots.vue'

export default {
  components: {
    CrudFieldSelectorTalentLoots,
    CrudFieldSelectorChests,
    CrudFieldSelectorSpins,
    EventItemsComponent
  }
}
</script>

<template>
  <crud-details-page
    api="events/event"
    disable-top-margin
    enable-relations
    re-load-data-after-save
    :actions="{
      crudNewVersion: false
    }"
  >
    <template #form="{ form, externalErrors }">
      <fields-col
        :sm="24"
      >
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col
                :sm="8"
              >
                <!-- Name -->
                <crud-field-text
                  api-field-name="Name"
                  required
                />
                <!-- Labels -->
                <crud-field-text
                  api-field-name="Labels"
                  required
                />
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- Start -->
                <crud-field-date-time
                  api-field-name="Start"
                  required
                />
                <!-- End -->
                <crud-field-date-time
                  api-field-name="End"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- AssetPopup -->
                <crud-field-selector-files api-field-name="AssetPopup" />
                <el-col
                  :span="24"
                  class="bg-amber-100"
                >
                  <el-row>
                    <crud-field-item-tags api-field-name="ItemTags" />
                  </el-row>
                  <el-row>
                    <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
                  </el-row>
                </el-col>
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Tab groups"
            name="tab_groups"
          >
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>Group</th>
                    <th>Top asset</th>
                    <th>Top asset text</th>
                    <th>Prio</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Notes</th>
                  </tr>
                </template>
                <template #tBody>
                  <tr
                    v-for="row in 15"
                    :key="row"
                  >
                    <fields-table-td
                      :width="70"
                      class="text-center"
                    >
                      {{ row }}
                    </fields-table-td>
                    <fields-table-td :width="300">
                      <crud-field-selector-files
                        :api-field-name="`Group${row}.TopAsset`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <fields-table-td :width="300">
                      <crud-field-text
                        :api-field-name="`Group${row}.TextsCsv`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <fields-table-td :width="70">
                      <crud-field-number
                        :api-field-name="`Group${row}.Prio`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <fields-table-td :width="180">
                      <crud-field-date-time
                        :api-field-name="`Group${row}.Start`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <fields-table-td :width="180">
                      <crud-field-date-time
                        :api-field-name="`Group${row}.End`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <fields-table-td :width="300">
                      <crud-field-text
                        :api-field-name="`Group${row}.Notes`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                  </tr>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Spins"
            name="spins"
          >
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>No</th>
                    <th>Group</th>
                    <th>Prio</th>
                    <th>Asset</th>
                    <th>Tab icon</th>
                    <th>Tab name</th>
                    <th>Spin</th>
                    <th>Limit</th>
                    <th>Daily Limit</th>
                    <th>Free</th>
                  </tr>
                </template>
                <template #tBody>
                  <tr
                    v-for="row in 5"
                    :key="row"
                  >
                    <!-- No -->
                    <fields-table-td
                      :width="70"
                      class="text-center"
                    >
                      {{ row }}
                    </fields-table-td>
                    <!-- Group -->
                    <fields-table-td :width="130">
                      <crud-field-number
                        :api-field-name="`Spin${row}.Group`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Prio -->
                    <fields-table-td :width="130">
                      <crud-field-number
                        :api-field-name="`Spin${row}.Prio`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Asset -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Spin${row}.Asset`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabIcon -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Spin${row}.TabIcon`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabName -->
                    <fields-table-td :width="270">
                      <crud-field-selector-client-strings
                        :api-field-name="`Spin${row}.TabName`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Spin -->
                    <fields-table-td :width="270">
                      <CrudFieldSelectorSpins
                        :api-field-name="`Spin${row}.Spin`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Limit -->
                    <fields-table-td :width="100">
                      <crud-field-number
                        :api-field-name="`Spin${row}.Limit`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- DailyLimit -->
                    <fields-table-td :width="100">
                      <crud-field-number
                        :api-field-name="`Spin${row}.DailyLimit`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Free -->
                    <fields-table-td :width="100">
                      <crud-field-number
                        :api-field-name="`Spin${row}.Free`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                  </tr>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Chests"
            name="chests"
          >
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>
                      Buy <br>
                      group
                    </th>
                    <th>
                      Buy <br>
                      prio
                    </th>
                    <th>Asset chest buy</th>
                    <th>Tab icon</th>
                    <th>Tab name</th>
                    <th>Items preview CSV</th>
                    <th>Texts preview CSV</th>
                    <th>Chest</th>
                    <th>Chest free hours</th>
                  </tr>
                </template>
                <template #tBody>
                  <tr
                    v-for="row in 8"
                    :key="row"
                  >
                    <!-- Buy group -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        :api-field-name="`Chest${row}.Group`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Buy prio -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        :api-field-name="`Chest${row}.Prio`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Asset chest buy -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Chest${row}.Asset`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabIcon -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Chest${row}.TabIcon`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabName -->
                    <fields-table-td :width="270">
                      <crud-field-selector-client-strings
                        :api-field-name="`Chest${row}.TabName`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Items preview CSV -->
                    <fields-table-td :width="240">
                      <crud-field-text
                        :api-field-name="`Chest${row}.ItemsCsv`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Texts preview CSV -->
                    <fields-table-td :width="240">
                      <crud-field-text
                        :api-field-name="`Chest${row}.TextsCsv`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Chest -->
                    <fields-table-td :width="270">
                      <CrudFieldSelectorChests
                        :api-field-name="`Chest${row}.Chest`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Chest free hours -->
                    <fields-table-td :width="100">
                      <crud-field-number
                        :api-field-name="`Chest${row}.FreeHours`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                  </tr>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Event items"
            name="event_items"
          >
            <el-scrollbar>
              <fields-col class="min-w-[1400px]">
                <EventItemsComponent
                  :form="form"
                  :external-errors="externalErrors"
                />
              </fields-col>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Talent"
            name="talent"
          >
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>
                      Buy <br>
                      group
                    </th>
                    <th>
                      Buy <br>
                      prio
                    </th>
                    <th>Asset</th>
                    <th>Tab icon</th>
                    <th>Tab name</th>
                    <th>Texts preview CSV</th>
                    <th>Loot</th>
                  </tr>
                </template>
                <template #tBody>
                  <tr>
                    <!-- Buy group -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        api-field-name="Talent.Group"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Buy prio -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        api-field-name="Talent.Prio"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Asset -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        api-field-name="Talent.Asset"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabIcon -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        api-field-name="Talent.TabIcon"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabName -->
                    <fields-table-td :width="270">
                      <crud-field-selector-client-strings
                        api-field-name="Talent.TabName"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Texts preview CSV -->
                    <fields-table-td :width="270">
                      <crud-field-text
                        api-field-name="'Talent.TextsCsv"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Loot -->
                    <fields-table-td :width="270">
                      <CrudFieldSelectorTalentLoots
                        api-field-name="Talent.Loot"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                  </tr>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            lazy
            label="Info"
            name="info"
          >
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>
                      Buy <br>
                      group
                    </th>
                    <th>
                      Buy <br>
                      prio
                    </th>
                    <th>Items preview CSV</th>
                    <th>Texts preview CSV</th>
                    <th>Asset</th>
                    <th>Tab icon</th>
                    <th>Tab name</th>
                  </tr>
                </template>
                <template #tBody>
                  <tr
                    v-for="row in 5"
                    :key="row"
                  >
                    <!-- Buy group -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        :api-field-name="`Info${row}.Group`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Buy prio -->
                    <fields-table-td :width="70">
                      <crud-field-number
                        :api-field-name="`Info${row}.Prio`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Items preview CSV -->
                    <fields-table-td :width="270">
                      <crud-field-text
                        :api-field-name="`Info${row}.ItemsCsv`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Texts preview CSV -->
                    <fields-table-td :width="270">
                      <crud-field-text
                        :api-field-name="`Info${row}.TextsCsv`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- Asset -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Info${row}.Asset`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabIcon -->
                    <fields-table-td :width="270">
                      <crud-field-selector-files
                        :api-field-name="`Info${row}.TabIcon`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                    <!-- TabName -->
                    <fields-table-td :width="270">
                      <crud-field-selector-client-strings
                        :api-field-name="`Info${row}.TabName`"
                        slim
                        :label="false"
                      />
                    </fields-table-td>
                  </tr>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
