<script setup></script>

<template>
  <crud-details-page
    api="quests/event-ranking-reward"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col>
        <fields-col :sm="8">
          <!-- Name -->
          <crud-field-text
            api-field-name="Name"
            required
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Labels -->
          <crud-field-text
            api-field-name="Labels"
            required
          />
        </fields-col>
      </fields-col>
      <template
        v-for="i in 10"
        :key="i"
      >
        <fields-col>
          <section-divider>Reward #{{ i }}</section-divider>
        </fields-col>
        <fields-col :sm="4">
          <!-- PlaceMin -->
          <crud-field-number
            :api-field-name="`Place${i}Min`"
            label="Place min"
          />
          <!-- PlaceMax -->
          <crud-field-number
            :api-field-name="`Place${i}Max`"
            label="Place max"
          />
          <!-- Gold -->
          <crud-field-number
            :api-field-name="`Gold${i}`"
            label="Gold"
          />
          <!-- Premium -->
          <crud-field-number
            :api-field-name="`Premium${i}`"
            label="Premium"
          />
        </fields-col>
        <fields-col :sm="6">
          <!-- Name -->
          <crud-field-text
            :api-field-name="`Tier${i}Name`"
            label="Name"
          />
          <!-- TierAsset -->
          <crud-field-selector-files
            :api-field-name="`TierAsset${i}`"
            label="Tier asset"
          />
          <!-- ChestAsset -->
          <crud-field-selector-files
            :api-field-name="`ChestAsset${i}`"
            label="Chest asset"
          />
        </fields-col>
        <fields-col :sm="14">
          <template
            v-for="j in 3"
            :key="j"
          >
            <!-- RewardItem -->
            <crud-field-selector-items
              :sm="14"
              :api-field-name="`Reward${i}Item${j}`"
              :label="`Item #${j}`"
            />
            <!-- RewardItemAmount -->
            <crud-field-number
              :sm="5"
              :api-field-name="`Reward${i}Item${j}Amount`"
              :label="`Item #${j} amount`"
            />
            <!-- ItemPinned -->
            <crud-field-switcher
              :sm="5"
              :api-field-name="`Item${i}Pinned`"
              :label="`Item #${j} pinned`"
            />
          </template>
        </fields-col>
      </template>
      <el-col
        :span="24"
        class="bg-amber-100"
      >
        <el-row>
          <crud-field-item-tags api-field-name="ItemTags" />
        </el-row>
        <el-row>
          <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
        </el-row>
      </el-col>
    </template>
  </crud-details-page>
</template>
