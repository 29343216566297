<script setup>
import { ref } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'

const tab = ref('main')
const formRefer = ref({})
const undoVal = ref('')
const textareaCursorPosition = ref({ start: 0, end: 0, target: null })
const insideTextPreview = ref('')
const contentActionsBtn = [
  { txt: '<img0>' },
  { txt: '<img0 link>' },
  { txt: '<b></b>', start: '<b>', end: '</b>' },
  { txt: '<u></u>', start: '<u>', end: '</u>' },
  { txt: '<i></i>', start: '<i>', end: '</i>' },
  { txt: '<indent=5%>' },
  { txt: '<align=center>' },
  { txt: '<size=100%></size>', start: '<size=100%>', end: '</size>' },
  { txt: '<color=#000000></color>', start: '<color=#000000>', end: '</color>' }
]
function initItem(form) {
  formRefer.value = form
  return form
}
function onLoadedData(data) {
  changeTextarea(data.InsideText)
}
function blurTextarea(ev) {
  textareaCursorPosition.value.target = ev?.target || null
  textareaCursorPosition.value.start = ev?.target?.selectionStart || 0
  textareaCursorPosition.value.end = ev?.target?.selectionEnd || 0
}

function insertFormatString(formatStringSettings) {
  undoVal.value = formRefer.value.InsideText
  if (textareaCursorPosition.value?.target?.setRangeText) {
    if (formatStringSettings.start) {
      textareaCursorPosition.value.target.setRangeText(
        formatStringSettings.start,
        textareaCursorPosition.value.start,
        textareaCursorPosition.value.start
      )
      textareaCursorPosition.value.target.setRangeText(
        formatStringSettings.end,
        textareaCursorPosition.value.end + formatStringSettings.start.length,
        textareaCursorPosition.value.end + formatStringSettings.start.length
      )
    } else {
      textareaCursorPosition.value.target.setRangeText(
        formatStringSettings.txt,
        textareaCursorPosition.value.start,
        textareaCursorPosition.value.end
      )
    }
    formRefer.value.InsideText = textareaCursorPosition.value.target.value
  } else {
    const value = formRefer.value?.InsideText?.length ? formRefer.value.InsideText : ''
    formRefer.value.InsideText = value + formatStringSettings.txt
  }
  changeTextarea(formRefer.value.InsideText)
}

function undo() {
  formRefer.value.InsideText = undoVal.value
  undoVal.value = ''
}

function changeTextarea(msg) {
  const re1 = /(<align=(left|center|right|justified)>)(.+)<\/align>/gi
  msg = msg.replaceAll(re1, '<div class="$2">$3\n</div>')
  const re2 = /(<align=(left|center|right|justified)>)(.+)\n/gi
  msg = msg.replaceAll(re2, '<div class="$2">$3\n</div>')
  const re3 = /<color=#([a-z0-9]{3,6})>(.+)<\/color>/gi
  msg = msg.replaceAll(re3, '<div style="color: #$1">$2</div>')
  const re4 = /<color=#([a-z0-9]{3,6})>(.+)\n/gi
  msg = msg.replaceAll(re4, '<div style="color: #$1">$2</div>')
  const re5 = /<size=(\d+%)>(.+)<\/size>/gi
  msg = msg.replaceAll(re5, '<div style="font-size: $1;">$2\n</div>')
  const re6 = /<size=(\d+%)>(.+)\n/gi
  msg = msg.replaceAll(re6, '<div style="font-size: $1;">$2</div>')
  // images
  for (let i = 0; i <= 14; i++) {
    if (formRefer.value[`InsideGraphic${i + 1}`] != null) {
      const re = new RegExp(`<img${i} (.*)>`, 'gi')
      msg = msg.replaceAll(
        re,
        `<div class="center"><a href="$1" target="_blank"><img src="${
          formRefer.value[`InsideGraphic${i + 1}`].AdminIcon
        }" /></a></div>`
      )
      msg = msg.replaceAll(
        `<img${i}>`,
        `<div class="center"><img src="${formRefer.value[`InsideGraphic${i + 1}`].AdminIcon}" /></div>`
      )
    }
  }
  msg = msg.replace(/\n/g, '<br />')

  insideTextPreview.value = msg
}
</script>

<template>
  <crud-details-page
    api="news/feed"
    disable-top-margin
    :render-init-item="initItem"
    @loaded-data="onLoadedData"
  >
    <template #form>
      <el-tabs
        v-model="tab"
        class="w-full"
      >
        <el-tab-pane
          label="Main data"
          name="main"
        >
          <fields-col class="mt-3">
            <fields-col
              :sm="8"
            >
              <!-- Start -->
              <crud-field-date-time
                api-field-name="Start"
                required
              />
              <!-- Tag -->
              <crud-field-text
                api-field-name="Tag"
                required
              />
              <!-- Header -->
              <crud-field-text
                api-field-name="Header"
                required
              />
              <!-- OutsideUrl -->
              <crud-field-text api-field-name="OutsideUrl" />
            </fields-col>
            <fields-col :sm="8">
              <!-- BigGraphic -->
              <crud-field-selector-files
                api-field-name="BigGraphic"
                required
              />
              <!-- AvatarGraphic -->
              <crud-field-selector-files api-field-name="AvatarGraphic" />
            </fields-col>
          </fields-col>
        </el-tab-pane>
        <el-tab-pane
          class="relative"
          label="Content"
          name="content"
        >
          <fields-col class="mt-6">
            <fields-col
              :sm="8"
            >
              <crud-field-textarea
                api-field-name="InsideText"
                :textarea-rows="57"
                @blur="blurTextarea"
                @change="changeTextarea"
              />
            </fields-col>
            <fields-col
              :sm="8"
            >
              <crud-field-selector-files
                v-for="n in 15"
                :key="n"
                :api-field-name="`InsideGraphic${n}`"
                :label="`Inside graphic #${n - 1}`"
              />
            </fields-col>
            <fields-col :sm="8">
              <label
                for="div-inside-text-preview"
                class="el-form-item__label"
              >In-game inside text preview:</label>
              <div
                id="div-inside-text-preview"
                v-html="insideTextPreview"
              />
            </fields-col>
          </fields-col>
        </el-tab-pane>
      </el-tabs>
    </template>
    <template #actionsFixedLeft>
      <transition name="el-zoom-in-center">
        <div
          v-if="tab === 'content'"
          class="flex shrink-0 mr-5"
        >
          <el-dropdown>
            <el-button
              type="primary"
              class="gs-height-related-lg ml-1 sm:ml-10"
            >
              Insert tag <el-icon class="el-icon--right">
                <ArrowDown />
              </el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(btn, index) in contentActionsBtn"
                  :key="index"
                  @click="insertFormatString(btn)"
                >
                  <span v-text="btn.txt" />
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="danger"
            class="gs-height-related-lg ml-1.5"
            :disabled="!undoVal.length"
            @click="undo"
          >
            undo
          </el-button>
        </div>
      </transition>
    </template>
  </crud-details-page>
</template>

<style>
@font-face {
  font-family: 'Brothers OT';
  src:
    local('Brothers OT'),
    url('/brothers_ot.otf') format('opentype');
}

#div-inside-text-preview {
  font-family: 'Brothers OT';
  font-weight: bold;
  color: #433a2f;
  background: #f6eada;
  min-height: 140px;
  width: 100%;
  border-radius: 5px;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 10px 20px;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.justified {
  text-align: justify;
}
.center img {
  margin: auto;
}
</style>
