<script>
import { computed, inject, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Close, WarnTriangleFilled } from '@element-plus/icons-vue'

export default {
  name: 'CrudSettingsBox',
  components: { Close, WarnTriangleFilled },
  setup() {
    const crudTable = inject('crudTable')
    const store = useStore()
    const route = useRoute()
    const favoritePage = ref(false)
    const deleteFavorite = ref(false) // to prevent change content popover content before hide;
    const favoriteUserName = ref(route.meta?.Title || route.name)
    const visiblePopoverCrudSettings = ref(false)
    const visiblePopoverFavorite = ref(false)

    const storedFavoritePageValue = computed(() => {
      return store.getters['main/crudStorage']?.favoritePages?.[crudTable.crudHash]
    })

    watch(
      () => crudTable.crudSettings,
      () => {
        if (!crudTable.crudSettings.crudSelectIdsMode) {
          crudTable.crudSelectIds = {}
        }
        crudTable.updateCrudStorage('crudSettings', {
          fit: crudTable.crudSettings.fit,
          fixed: crudTable.crudSettings.fixed,
          crudSelectIdsMode: crudTable.crudSettings.crudSelectIdsMode
        })
        crudTable.updateSessionCrudStorage('crudSettings', { visibleCellEdit: crudTable.crudSettings.visibleCellEdit })
      },
      { deep: true }
    )

    watch(
      () => storedFavoritePageValue.value,
      (val) => {
        favoritePage.value = !!val
      },
      { immediate: true }
    )

    return {
      crudTable,
      favoritePage,
      favoriteUserName,
      visiblePopoverCrudSettings,
      visiblePopoverFavorite,
      deleteFavorite,
      enabledCrudSettings: computed(() => {
        return Object.entries(crudTable.crudSettings).some(([, settingsValue]) => {
          return settingsValue === true
        })
      }),
      storedFavoritePageValue,
      setFavoritePage: (toggle = false) => {
        visiblePopoverFavorite.value = false
        if (toggle) {
          favoritePage.value = !favoritePage.value
        }
        const storage = { ...toRaw(store.getters['main/crudStorage']?.favoritePages || {}) }
        if (favoritePage.value) {
          const index = Object.keys(storage).length
          storage[crudTable.crudHash] = {
            routeName: route.name,
            userName: favoriteUserName.value || route.meta?.Title || route.name,
            index
          }
        } else {
          delete storage[crudTable.crudHash]
        }
        store.commit('main/setCrudStorage', ['favoritePages', storage])
        store.dispatch('main/saveCrudStorage', store.getters['main/crudStorage'])
        favoriteUserName.value = route.meta?.Title || route.name
      }
    }
  }
}
</script>

<template>
  <el-popover
    v-model:visible="visiblePopoverCrudSettings"
    :show-arrow="false"
    popper-class="p-0"
    trigger="click"
    :width="210 * $store.getters['auth/userScaledRatioWidth']"
    placement="top"
    :offset="-25"
  >
    <!-- button of crud settings -->
    <template #reference>
      <el-button
        class="ml-0 h-auto p-0"
        :class="{
          'gs-btn-text-neutral-light': !enabledCrudSettings,
          'gs-btn-text-primary': enabledCrudSettings
        }"
      >
        <el-tooltip
          content="Settings"
          effect="light"
          placement="top"
          :show-after="600"
        >
          <div>
            <icon-ify
              icon="heroicons-outline:dots-vertical"
              class="gs-scaled-icon-xs"
            />
          </div>
        </el-tooltip>
      </el-button>
    </template>
    <!-- content menu of crud settings -->
    <el-scrollbar
      class="pt-2"
      :max-height="Math.min($windowHeight - 100, 350)"
    >
      <div class="flex items-center justify-start pl-2 pr-4 text-xs">
        <!-- close menu btn -->
        <el-button
          type=""
          class="gs-btn-text-neutral-light absolute right-4 top-0 bg-transparent p-0"
          @click="visiblePopoverCrudSettings = false"
        >
          <el-icon class="gs-scaled-icon-xss">
            <Close />
          </el-icon>
        </el-button>
      </div>
      <!-- list settings -->
      <div class="gs-font-scaled mb-1 mt-4">
        <div
          class="pl-2 pr-3 hover:bg-neutral-100"
          style="max-width: 250px"
        >
          <el-checkbox
            v-model="crudTable.crudSettings.fixed"
            style="position: initial"
            class="hyphenate h-auto w-full py-2 font-normal"
            @change="crudTable.forceRefreshPage(250)"
          >
            Fixed height
          </el-checkbox>
        </div>
        <div
          class="pl-2 pr-3 hover:bg-neutral-100"
          style="max-width: 250px"
        >
          <el-checkbox
            v-model="crudTable.crudSettings.fit"
            style="position: initial"
            class="hyphenate h-auto w-full py-2 font-normal"
            @change="crudTable.forceRefreshPage(250)"
          >
            Fit width
          </el-checkbox>
        </div>
        <div
          v-if="crudTable.areCellsToEdit"
          class="pl-2 pr-3 hover:bg-neutral-100"
          style="max-width: 250px"
        >
          <el-checkbox
            v-model="crudTable.crudSettings.visibleCellEdit"
            style="position: initial"
            class="hyphenate h-auto w-full py-2 font-normal"
            @change="crudTable.forceRefreshPage(250)"
          >
            Enable edit cells
          </el-checkbox>
        </div>
        <div
          v-if="!crudTable.disableMultiSelectRows"
          class="pl-2 pr-3 hover:bg-neutral-100"
          style="max-width: 250px"
        >
          <el-checkbox
            v-model="crudTable.crudSettings.crudSelectIdsMode"
            style="position: initial"
            class="hyphenate h-auto w-full py-2 font-normal"
          >
            Enable multi select rows
          </el-checkbox>
        </div>
        <el-divider class="my-2" />
        <div class="relative pl-2 pr-3">
          <el-switch
            v-model="favoritePage"
            :size="$store.getters['auth/userScaledMediumSize']"
            style="--el-switch-on-color: #13ce66"
            inline-prompt
            active-text="Y"
            inactive-text="N"
          />
          <span
            class="gs-font-scaled cursor-pointer pl-1.5"
            :class="{ 'text-green-600': favoritePage }"
          >
            Favorite page
          </span>
          <el-popover
            v-model:visible="visiblePopoverFavorite"
            placement="top"
            :width="200 * $store.getters['auth/userScaledRatioWidth']"
            trigger="click"
            :teleported="false"
            @show="favoriteUserName = $route.meta?.Title || $route.name"
            @before-enter="deleteFavorite = favoritePage"
            @after-leave="deleteFavorite = favoritePage"
          >
            <template #reference>
              <div class="absolute inset-0 cursor-pointer" />
            </template>
            <div class="gs-font-scaled">
              <template v-if="deleteFavorite">
                <div>
                  <div class="flex items-center">
                    <div class="leading-none">
                      <el-icon class="gs-scaled-icon-md text-orange-400">
                        <WarnTriangleFilled />
                      </el-icon>
                    </div>
                    <div class="font-related-lg gs-related-px-md break-normal text-left">
                      Remove this page from favorites?
                    </div>
                  </div>
                  <el-divider class="gs-related-my-xs" />
                  <div class="flex justify-between">
                    <el-button
                      class="gs-height-related-sm"
                      plain
                      @click="visiblePopoverFavorite = false"
                    >
                      cancel
                    </el-button>
                    <el-button
                      class="gs-height-related-sm"
                      type="primary"
                      @click="setFavoritePage(true)"
                    >
                      confirm
                    </el-button>
                  </div>
                </div>
              </template>
              <template v-else>
                <el-input
                  v-model="favoriteUserName"
                  class="gs-height-related-sm"
                  clearable
                />
                <el-divider class="gs-related-my-xs" />
                <div class="flex justify-between">
                  <el-button
                    class="gs-height-related-sm font-related-sm"
                    plain
                    @click="visiblePopoverFavorite = false"
                  >
                    cancel
                  </el-button>
                  <el-button
                    class="gs-height-related-sm"
                    type="primary"
                    @click="setFavoritePage(true)"
                  >
                    add
                  </el-button>
                </div>
              </template>
            </div>
          </el-popover>
        </div>
      </div>
    </el-scrollbar>
  </el-popover>
</template>

<style scoped></style>
