<template>
  <crud-details-page
    api="extras/frame"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files api-field-name="Graphic" />
        <!-- Order -->
        <crud-field-number api-field-name="Order" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
