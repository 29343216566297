<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Delete, Download, Upload } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import { globalProperties as app } from '!/plugins/utilities'
import { userRightRoles } from '!/composition/utilities'
import FilesUploaderDrawer from '!/components/drawers/FilesUploaderDrawer.vue'

export default {
  components: { FilesUploaderDrawer },
  setup() {
    const store = useStore()
    const route = useRoute()
    const exporting = ref(false)
    const id = ref(route.params.id)
    const drawer = ref(false)
    const languages = store.getters['auth/userLanguages'].map((lang) => {
      return lang.toUpperCase()
    })
    const apiFormKeys = ['Keys', 'EN', 'Remarks', ...languages]

    return {
      id,
      drawer,
      exporting,
      Upload,
      Download,
      Delete,
      apiFormKeys,
      languages,
      userRightRoles,
      exportExcel: () => {
        exporting.value = true
        app.$axios
          .get('/admin/api/settings/translation-pack-export/', {
            params: {
              id: id.value
            },
            responseType: 'blob'
          })
          .then((response) => {
            app.$utils.saveBlobToFile(
              response.data,
              `export_translation_pack_${id.value}_${dayjs().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`
            )
          })
          .catch(app.$utils.catchError)
          .then(() => {
            exporting.value = false
          })
      }
    }
  }
}
</script>

<template>
  <crud-details-page
    api="settings/translation-pack"
    :versioned="false"
    :actions="{
      crudSave: { right: userRightRoles.forbidden },
      crudDuplicate: { right: userRightRoles.forbidden },
      crudNewVersion: { right: userRightRoles.forbidden },
      crudUsage: { right: userRightRoles.forbidden },
      crudDelete: { right: userRightRoles.translator }
    }"
    :api-form-keys="apiFormKeys"
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <crud-field-slot
          :sm="6"
          label="Keys"
        >
          <el-link
            class="gs-related-mx-md"
            type="primary"
            :href="`/translations/client-strings?c_fil%5BTranslation%5D=${id}`"
            target="_blank"
          >
            {{ form.Keys ? form.Keys.toLocaleString() : '-' }}
          </el-link>
        </crud-field-slot>
        <!-- CreatedAt -->
        <crud-field-date-time
          :sm="18"
          api-field-name="CreatedAt"
          disabled
          required
        />
        <!-- Contents -->
        <crud-field-text
          :textarea-rows="1"
          api-field-name="CreatedBy"
          disabled
          required
        />
      </fields-col>
      <fields-col
        xs
        :sm="4"
      >
        <crud-field-slot :xs="12">
          <template #label>
            Missing EN <span class="font-related-xl">{{ $utils.languageByCode('en')?.emoji }}</span>
          </template>
          <el-link
            class="gs-related-mx-md"
            type="primary"
            :href="`/translations/client-strings?c_fil%5BTranslation%5D=${id}&c_sea%5Ben%5D=on`"
            target="_blank"
          >
            {{ form.EN ? form.EN.toLocaleString() : '-' }}
          </el-link>
        </crud-field-slot>
        <crud-field-slot
          :xs="12"
          label="Remarks"
        >
          <el-link
            type="primary"
            :href="`/translations/client-strings?c_fil%5BTranslation%5D=${id}${id}&c_sea%remarks%5D=on`"
            target="_blank"
          >
            {{ form.Remarks ? form.Remarks.toLocaleString() : '-' }}
          </el-link>
        </crud-field-slot>
      </fields-col>
      <fields-col :sm="12">
        <crud-field-slot
          v-for="lang in languages"
          :key="lang"
          :xs="12"
          :sm="8"
        >
          <template #label>
            Missing {{ lang }} <span class="font-related-xl">{{ $utils.languageByCode(lang)?.emoji }}</span>
          </template>
          <el-link
            class="gs-related-mx-md"
            type="primary"
            :href="`/translations/client-strings?c_fil%5BTranslation%5D=${id}&c_sea%5B${lang.toLowerCase()}%5D=on`"
            target="_blank"
          >
            {{ form[lang] ? form[lang].toLocaleString() : '-' }}
          </el-link>
        </crud-field-slot>
      </fields-col>
    </template>
    <template #actionsFixed>
      <el-button
        v-if="$utils.checkRights(userRightRoles.editor)"
        class="gs-loading gs-btn-outlined-primary-neutral gs-font-scaled gs-height-related-xl ml-2 px-3"
        :icon="Upload"
        :loading="exporting"
        size="small"
        @click="exportExcel()"
      >
        Export
      </el-button>
      <el-button
        v-if="$utils.checkRights(userRightRoles.translator)"
        class="gs-loading gs-btn-outlined-primary-neutral gs-font-scaled gs-height-related-xl ml-2 px-3"
        :icon="Download"
        size="small"
        @click="drawer = true"
      >
        Import
      </el-button>
    </template>
    <template #drawers="{ refreshData }">
      <FilesUploaderDrawer
        v-model:show="drawer"
        title="Import translation file"
        simple
        accept=".xlsx"
        api="/settings/translation-pack/upload/"
        :api-data="{ id }"
        @refresh="refreshData()"
      />
    </template>
  </crud-details-page>
</template>
