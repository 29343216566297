<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectBallFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcOptions, colorOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcOptions,
      colorOptions,
      initDefaultValues,
      effectKindOptions: [
        { value: 'replace', label: 'replace' },
        { value: 'delete', label: 'delete' }
      ]
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'ball',
      'target-team': 'enemies',
      'effect': 'replace',
      'color': 'white',
      'min': 0,
      'max': 0,
      'chance': 100,
      'dlvl-chance': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Ball_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Ball_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- Kind -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.effect"
          label="Kind"
          slim
          :clearable="false"
          :options="effectKindOptions"
          doc-field-name="Ball_Kind"
          disable-doc-settings
        />
      </div>
      <!-- color -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.color"
          label="Color"
          slim
          :clearable="false"
          :options="colorOptions"
          doc-field-name="Ball_Color"
          disable-doc-settings
        />
      </div>
      <!-- min -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.min"
          label="Ball No. min"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Ball_No._min"
          disable-doc-settings
        />
      </div>
      <!-- max -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.max"
          label="Ball No. max"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Ball_No._max"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <div class="col-w-3" />
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.chance"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Ball_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-chance"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Ball_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
