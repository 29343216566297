<template>
  <crud-details-page
    api="quests/criteria-setting"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- CritCriterion -->
        <crud-field-text
          api-field-name="CritCriterion"
          required
        />
        <!-- Asset -->
        <crud-field-selector-files
          api-field-name="CriterionBlueIcon"
          label="Asset Blue Icon"
          required
        />
        <crud-field-selector-files
          api-field-name="CriterionWhiteIcon"
          label="Asset White Icon"
          required
        />
        <crud-field-selector-files
          api-field-name="CriterionRedIcon"
          label="Asset Red Icon"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
