<script>
export default {
  data() {
    return {
      allRegionsSelected: false
    }
  },
  methods: {
    initItem(form) {
      if (!form?.ID) {
        if (typeof form.TargetLevel === 'undefined') {
          form.TargetLevel = 1
        }
      } else if (form?.ID && !form.TargetLevel) {
        form.TargetLevel = 1
      }
      if (form.IsDev && form.IsStaging && form.IsProd) {
        this.allRegionsSelected = true
      }
      return form
    },
    toggleRegions(val, form) {
      if (val) {
        form.IsDev = true
        form.IsStaging = true
        form.IsProd = true
      } else {
        form.IsDev = false
        form.IsStaging = false
        form.IsProd = false
      }
    },
    toggleRegions2(val, form) {
      if (!val) {
        this.allRegionsSelected = false
      }
      if (form.IsDev && form.IsStaging && form.IsProd) {
        this.allRegionsSelected = true
      }
    }
  }
}
</script>

<template>
  <crud-details-page
    api="tools/global-message"
    disable-top-margin
    :versioned="false"
    :render-init-item="initItem"
    re-load-data-after-save
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Sender -->
        <crud-field-text
          api-field-name="Sender"
          required
        />
        <!-- Title -->
        <crud-field-text
          api-field-name="Title"
          required
        />
        <!-- ScheduledSend -->
        <crud-field-date-time
          api-field-name="ScheduledSend"
          label="Date to send"
        />
        <!-- TargetLevel -->
        <crud-field-number
          api-field-name="TargetLevel"
          label="From Hero level"
          :min="1"
          :max="100"
          :clearable="false"
        />
      </fields-col>
      <fields-col :sm="8">
        <section-divider>Region</section-divider>
        <el-switch
          v-model="allRegionsSelected"
          style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          active-text="All selected"
          inactive-text="None/some selected"
          @change="toggleRegions($event, form)"
        />
        <section-divider />
        <crud-field-switcher
          api-field-name="IsDev"
          label="Dev"
          class="flex-none"
          slim
          doc-icon-class="-right-5"
          @change="toggleRegions2($event, form)"
        />
        <crud-field-switcher
          api-field-name="IsStaging"
          label="Staging"
          class="flex-none"
          slim
          doc-icon-class="-right-5"
          @change="toggleRegions2($event, form)"
        />
        <crud-field-switcher
          api-field-name="IsProd"
          label="Prod"
          class="flex-none"
          slim
          doc-icon-class="-right-5"
          @change="toggleRegions2($event, form)"
        />
        <!-- Contents -->
        <crud-field-textarea
          api-field-name="Contents"
          :textarea-rows="20"
          required
          class="mt-3"
        />
      </fields-col>
      <fields-col :sm="10">
        <!-- Gold -->
        <crud-field-number api-field-name="Gold" />
        <!-- Premium -->
        <crud-field-number api-field-name="Premium" />
        <template
          v-for="i in 5"
          :key="i"
        >
          <crud-field-selector-items
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Amount${i}`"
            label="Amount"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
