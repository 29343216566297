<script setup></script>

<template>
  <crud-details-page
    api="guild/level-perk"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- GuildLevel -->
        <crud-field-text
          api-field-name="GuildLevel"
          required
        />
        <!-- GuildExp -->
        <crud-field-number
          api-field-name="GuildExp"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- MaxMembers -->
        <crud-field-number api-field-name="MaxMembers" />
        <!-- MaxAcademyBonusLevel -->
        <crud-field-number api-field-name="MaxAcademyBonusLevel" />
        <!-- InviteLimit -->
        <crud-field-number api-field-name="InviteLimit" />
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Items 1-3 -->
        <fields-table class="w-11/12">
          <template #tHeader>
            <tr>
              <th>Nr</th>
              <th>Item</th>
              <th>Amount</th>
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="row in 3"
              :key="row"
            >
              <fields-table-td
                :width="50"
                class="text-center"
              >
                {{ row }}
              </fields-table-td>
              <fields-table-td :min-width="300">
                <crud-field-selector-items
                  :api-field-name="`RewardItem${row}`"
                  :label="false"
                  :min-height="false"
                  slim
                  doc-icon-class="-top-3 -right-5"
                />
              </fields-table-td>
              <fields-table-td :width="150">
                <crud-field-number
                  :api-field-name="`RewardItem${row}Amount`"
                  :label="false"
                  :min-height="false"
                  slim
                  doc-icon-class="-top-3 -right-5"
                />
              </fields-table-td>
            </tr>
          </template>
        </fields-table>
      </fields-col>
    </template>
  </crud-details-page>
</template>
