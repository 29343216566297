<script>
import { Refresh } from '@element-plus/icons-vue'
import { computed, ref, watch } from 'vue'
import { mapGetters } from 'vuex'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'ToggleAllSyncTags',
  components: { Refresh },
  props: {
    dev2ProdSum: {
      type: Number,
      default: 0
    },
    dev2ProdSync: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:dev2-prod-sync'],
  setup(props, context) {
    const model = ref({ dev2prod: false, dev2prodIntermediate: false })
    const selectedDev2prodLength = computed(() => {
      return Object.values(props.dev2ProdSync).filter(val => val).length
    })
    watch(selectedDev2prodLength, () => {
      if (selectedDev2prodLength.value && selectedDev2prodLength.value === props.dev2ProdSum) {
        model.value.dev2prod = true
        model.value.dev2prodIntermediate = false
      } else if (selectedDev2prodLength.value === 0) {
        model.value.dev2prod = false
        model.value.dev2prodIntermediate = false
      } else {
        model.value.dev2prodIntermediate = true
      }
    })
    watch(
      () => model.value.dev2prod,
      () => {
        if (model.value.dev2prod && selectedDev2prodLength.value !== props.dev2ProdSum) {
          model.value.dev2prodIntermediate = false
          context.emit(
            'update:dev2-prod-sync',
            Object.fromEntries(
              Object.entries(props.dev2ProdSync).map(([syncItem]) => {
                return [syncItem, true]
              })
            )
          )
        } else if (!model.value.dev2prod && selectedDev2prodLength.value) {
          model.value.dev2prodIntermediate = false
          context.emit(
            'update:dev2-prod-sync',
            Object.fromEntries(
              Object.entries(props.dev2ProdSync).map(([syncItem]) => {
                return [syncItem, false]
              })
            )
          )
        }
      },
      { deep: true }
    )

    return {
      model,
      tagsSettings: CrudTagsSettings('dev2prod'),
      visibleTags: computed(() => {
        const tags = []
        if (props.dev2ProdSum) {
          tags.push('dev2prod')
        }
        return tags
      })
    }
  },
  computed: {
    ...mapGetters({
      userScaledSize: 'auth/userScaledSize'
    })
  }
}
</script>

<template>
  <div
    v-if="$store.getters['auth/userRights']?.editor"
    class="-mt-1 flex flex-wrap items-center"
    style="min-height: 2.5em"
  >
    <template
      v-for="(tagName, index) in visibleTags"
      :key="index"
    >
      <el-checkbox
        v-if="model[tagName]"
        v-model="model[tagName]"
        :indeterminate="model[`${tagName}Intermediate`]"
        class="gs-checkbox-mini gs-font-scaled gs-height-related-sm relative mr-1.5 mt-1 pr-1"
        :style="`min-width: ${107 * $store.getters['auth/userScaledRatio']}px;`"
        :class="[tagsSettings[tagName].outlinedClasses]"
        border
      >
        <div class="leading-1 gs-font-scaled flex items-center">
          <span class="font-related-xs mr-1.5">{{ tagName }}</span>
          <el-icon class="gs-scaled-icon-xss absolute right-1 scale-90">
            <Refresh />
          </el-icon>
        </div>
      </el-checkbox>
      <el-popconfirm
        v-else
        :width="300"
        confirm-button-text="continue"
        cancel-button-text="cancel"
        class="text-base"
        title="You're about to check all records to synchronize?"
        @confirm.stop="model[tagName] = true"
      >
        <template #reference>
          <el-checkbox
            v-model="model[tagName]"
            :indeterminate="model[`${tagName}Intermediate`]"
            class="gs-checkbox-mini gs-font-scaled gs-height-related-sm relative mr-1.5 mt-1 pr-1"
            :style="`min-width: ${107 * $store.getters['auth/userScaledRatio']}px;`"
            :class="[tagsSettings[tagName].outlinedClasses]"
            border
            @click.prevent
          >
            <div class="leading-1 gs-font-scaled flex items-center">
              <span class="font-related-xs mr-1.5">{{ tagName }}</span>
              <el-icon class="gs-scaled-icon-xss absolute right-1 scale-90">
                <Refresh />
              </el-icon>
            </div>
          </el-checkbox>
        </template>
      </el-popconfirm>
    </template>
  </div>
</template>
