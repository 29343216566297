<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_3.png'
import TipImg4 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_4.png'
import TipImg5 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_5.png'
import TipImg6 from '!/pages/tools/ap-tips/tips/tip_7/tip_7_6.png'

export default {
  data() {
    return {
      srcList: [
        TipImg1,
        TipImg2,
        TipImg3,
        TipImg4,
        TipImg5,
        TipImg6
      ]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      <span># Multiple row selection</span>
      <br>
      <span># Enables:</span>
      <br> - filtering selected rows;
      <br> - export selected rows;
      <br> - delete selected rows;
      <br> <br>
      <span>You can use different search criteria to select consecutive rows (filters, page). It is also possible to paste a list of ids from excel (clipboard; this option does not overwrite the already existing list).</span>
      <br> <br>
      click on the screenshots to find out more
    </p>
  </div>
</template>

<style scoped></style>
