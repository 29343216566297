<script>
import { Settings } from '@/pages/items/items/compositions/TypeLegendSettings'

export default {
  name: 'TypeLegend',
  props: {
    settingsKey: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    return {
      legend: Settings?.[props.settingsKey] || {}
    }
  }
}
</script>

<template>
  <div class="gs-font-scaled -mt-1 leading-none text-slate-400">
    <span
      v-for="(settings, itemName) in legend"
      :key="itemName"
      style="padding-right: 10px"
      class="mt-1 whitespace-nowrap"
    >
      <div
        class="border border-gray-300"
        style="position: relative; top: 2px; display: inline-block; width: 12px; height: 12px"
        :style="{ backgroundColor: settings.color }"
      />
      &nbsp;-&nbsp;{{ settings.label }}
    </span>
  </div>
</template>

<style scoped></style>
