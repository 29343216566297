<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectResurrectionFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions, booleanOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      booleanOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'resurrection',
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'chance': 100,
      'dlvl-chance': 0,
      'is-percent': 1,
      'hp-min': 0,
      'hp-max': 0,
      'dlvl-hp': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Resurrection_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Resurrection_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-range -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="Resurrection_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="Resurrection_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.chance`"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Resurrection_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-chance`"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Resurrection_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- is-percent -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.is-percent`"
          label="Use % of HP"
          slim
          :clearable="false"
          :options="booleanOptions"
          doc-field-name="Resurrection_Use%_of_HP"
          disable-doc-settings
        />
      </div>
      <!-- hp-min -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.hp-min`"
          label="HP value min"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Resurrection_HP_value_min"
          disable-doc-settings
        />
      </div>
      <!-- hp-max -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.hp-max`"
          label="HP value max"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Resurrection_HP_value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-hp -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-hp`"
          label="Increment per level"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Resurrection_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
