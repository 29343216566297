<script>
import { Check } from '@element-plus/icons-vue'
import CrudFieldSelectorCraftTabs from '@/components/forms/crud-fields/CrudFieldSelectorCraftTabs.vue'
import CrudFieldSelectorWearableSets from '@/components/forms/crud-fields/CrudFieldSelectorWearableSets.vue'

export default {
  components: { CrudFieldSelectorCraftTabs, CrudFieldSelectorWearableSets, Check },
  methods: {
    changeAutonaming(val, form) {
      if (val) {
        if (!form.Sub1.Active) {
          this.notify('Sub-challenge 1 is not active')
          return
        }
        if (form.Sub1.RewardItem1 === null) {
          this.notify('Please first set Reward item 1')
          return
        }

        this.$axios
          .get(`/admin/api/items/item/${form.Sub1.RewardItem1.ID}/`)
          .then(({ data }) => {
            if (data.item.Name !== undefined) {
              form.Name = data.item.Name
              form.Sub1.Name = data.item.Name.AdminLabel.replace(/^\d+:\s/, '')
            }
          })
          .catch(this.$utils.catchError)
      }
    },
    notify(message) {
      this.$message({
        message,
        type: 'warning',
        offset: 40
      })
    }
  }
}
</script>

<template>
  <crud-details-page
    api="challenge-crafts/challenge-craft"
    disable-top-margin
    enable-relations
  >
    <template #form="{ form }">
      <fields-col sm>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- Name -->
                <crud-field-selector-client-strings
                  api-field-name="Name"
                  required
                  :sm="16"
                />
                <crud-field-checkbox
                  class="flex-none"
                  api-field-name="autonaming"
                  label="Autonaming"
                  @change="changeAutonaming($event, form)"
                />
                <!-- Label -->
                <crud-field-text api-field-name="Label" />
                <!-- Description -->
                <crud-field-text
                  api-field-name="Description"
                  label="Description"
                />
                <!-- Tab -->
                <CrudFieldSelectorCraftTabs api-field-name="Tab" />
                <!-- LevelMin -->
                <crud-field-number api-field-name="LevelMin" />
                <!-- LevelMax -->
                <crud-field-number api-field-name="LevelMax" />
                <el-col
                  :span="24"
                  class="bg-amber-100"
                >
                  <el-row>
                    <crud-field-item-tags api-field-name="ItemTags" />
                  </el-row>
                  <el-row>
                    <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
                  </el-row>
                </el-col>
              </fields-col>
              <fields-col :sm="8">
                <!-- Start -->
                <crud-field-date-time api-field-name="Start" />
                <!-- End -->
                <crud-field-date-time api-field-name="End" />
                <!-- BackgroundImage -->
                <crud-field-selector-files api-field-name="BackgroundImage" />
                <!-- SortPrio -->
                <crud-field-number
                  api-field-name="SortPrio"
                  label="Sort prioritet"
                />
                <!-- MaxUsesLimit -->
                <crud-field-number api-field-name="MaxUsesLimit" />
                <!-- ApType -->
                <crud-field-select
                  api-field-name="ApType"
                  label="Admin type"
                  options-enum="enums.AdminPanelTypeCrafts"
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- IsFeatured -->
                <crud-field-switcher api-field-name="IsFeatured" />
                <!-- DontColorBackground -->
                <crud-field-switcher api-field-name="DontColorBackground" />
                <!-- IsRewardVisible -->
                <crud-field-switcher api-field-name="IsRewardVisible" />
                <!-- IsShowingExchangeArrow -->
                <crud-field-switcher api-field-name="IsShowingExchangeArrow" />
                <!-- IsAutoClear -->
                <crud-field-switcher
                  api-field-name="IsAutoClear"
                  label="Is auto clear (at event change)"
                />
                <!-- IsLimitDailyReset -->
                <crud-field-switcher api-field-name="IsLimitDailyReset" />
                <!-- IsNoProgress -->
                <crud-field-switcher api-field-name="IsNoProgress" />
              </fields-col>
              <fields-col>
                <section-divider>Main rewards</section-divider>
              </fields-col>
              <fields-col :sm="8">
                <!-- RewardGold -->
                <crud-field-number api-field-name="RewardGold" />
                <!-- RewardPremium -->
                <crud-field-number api-field-name="RewardPremium" />
              </fields-col>
              <fields-col
                v-for="i in 2"
                :key="i"
                :sm="8"
              >
                <!-- RewardItemId -->
                <crud-field-selector-items
                  :api-field-name="`RewardItem${i}`"
                  :label="`Reward item ${i}`"
                />
                <!-- RewardItem1Amount -->
                <crud-field-number
                  :api-field-name="`RewardItem${i}Amount`"
                  :label="`Reward item amount ${i}`"
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            v-for="i in 4"
            :key="i"
            :label="`Sub-challenge ${i}`"
            :name="`sub${i}`"
            lazy
          >
            <template #label>
              <span class="custom-tabs-label">
                <el-icon
                  v-show="form[`Sub${i}`].Active"
                  class="mr-1"
                ><Check /></el-icon>
                <span>Sub-challenge {{ i }}</span>
              </span>
            </template>
            <fields-col>
              <fields-col :sm="8">
                <!-- SubActive -->
                <crud-field-switcher
                  :api-field-name="`Sub${i}.Active`"
                  label="Active"
                />
              </fields-col>
              <fields-col v-show="form[`Sub${i}`].Active">
                <fields-col>
                  <section-divider>Basic info</section-divider>
                </fields-col>
                <crud-field-text
                  :sm="6"
                  :api-field-name="`Sub${i}.Name`"
                  label="Name"
                />
                <crud-field-text
                  :sm="6"
                  :api-field-name="`Sub${i}.Description`"
                  label="Description"
                />
                <crud-field-selector-files
                  :sm="6"
                  :api-field-name="`Sub${i}.BackgroundImage`"
                  label="Background image"
                />
                <crud-field-switcher
                  class="flex-none"
                  :api-field-name="`Sub${i}.AllowMultiselect`"
                  label="Allow multiselect"
                />
                <crud-field-switcher
                  class="flex-none"
                  :api-field-name="`Sub${i}.AllowAutofill`"
                  label="Allow autofill"
                />
                <fields-col>
                  <section-divider>Rewards</section-divider>
                </fields-col>
                <fields-col :sm="6">
                  <!-- SubRewardGold -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.RewardGold`"
                    label="Reward gold"
                  />
                  <!-- SubRewardPremium -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.RewardPremium`"
                    label="Reward premium"
                  />
                </fields-col>
                <fields-col
                  v-for="k in 4"
                  :key="k"
                  :sm="6"
                >
                  <!-- SubRewardItem -->
                  <crud-field-selector-items
                    :api-field-name="`Sub${i}.RewardItem${k}`"
                    :label="`Reward item ${k}`"
                  />
                  <!-- SubRewardItemAmount -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.RewardItem${k}Amount`"
                    :label="`Reward item amount ${k}`"
                  />
                </fields-col>
                <fields-col>
                  <section-divider>Wearable sets requirements</section-divider>
                </fields-col>
                <fields-col
                  v-for="k in 3"
                  :key="k"
                  :sm="6"
                >
                  <!-- SubReqSet -->
                  <CrudFieldSelectorWearableSets
                    :api-field-name="`Sub${i}.ReqSet${k}`"
                    :label="`Set ${k}`"
                  />
                  <!-- SubReqSetValueMin -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.ReqSet${k}ValueMin`"
                    :label="`Set ${k} min`"
                  />
                  <!-- SubReqSetValueMax -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.ReqSet${k}ValueMax`"
                    :label="`Set ${k} max`"
                  />
                </fields-col>
                <fields-col :sm="6">
                  <!-- SubReqSumDifferentSetsValueMin -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.ReqSumDifferentSetsValueMin`"
                    label="SUM different sets min"
                  />
                  <!-- SubReqSumDifferentSetsValueMax -->
                  <crud-field-number
                    :api-field-name="`Sub${i}.ReqSumDifferentSetsValueMax`"
                    label="SUM different sets max"
                  />
                </fields-col>
                <fields-col>
                  <section-divider>Items requirements</section-divider>
                </fields-col>
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqAvgPotentialValueMin`"
                  label="AVG gear/orb potential min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqAvgPotentialValueMax`"
                  label="AVG gear/orb potential max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqPotentialValueMin`"
                  label="MIN/MAX gear/orb potential min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqPotentialValueMax`"
                  label="MIN/MAX gear/orb potential max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqAvgUpgradeLevelValueMin`"
                  label="AVG gear/orb upgrade level min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqAvgUpgradeLevelValueMax`"
                  label="AVG gear/orb upgrade level max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqUpgradeLevelValueMin`"
                  label="MIN/MAX gear/orb upgrade level min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqUpgradeLevelValueMax`"
                  label="MIN/MAX gear/orb upgrade level max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityCommonValueMin`"
                  label="SUM common quality min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityCommonValueMax`"
                  label="SUM common quality max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityEpicValueMin`"
                  label="SUM epic quality min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityEpicValueMax`"
                  label="SUM epic quality max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityUncommonValueMin`"
                  label="SUM uncommon quality min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityUncommonValueMax`"
                  label="SUM uncommon quality max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityLegendaryValueMin`"
                  label="SUM legendary quality min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityLegendaryValueMax`"
                  label="SUM legendary quality max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityRareValueMin`"
                  label="SUM rare quality min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumQualityRareValueMax`"
                  label="SUM rare quality max"
                />
                <fields-col>
                  <section-divider>Slots requirements</section-divider>
                </fields-col>
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotMainHandValueMin`"
                  label="SUM main_hand slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotMainHandValueMax`"
                  label="SUM main_hand slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotGlovesValueMin`"
                  label="SUM gloves slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotGlovesValueMax`"
                  label="SUM gloves slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotOffHandValueMin`"
                  label="SUM off_hand slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotOffHandValueMax`"
                  label="SUM off_hand slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotRingValueMin`"
                  label="SUM ring slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotRingValueMax`"
                  label="SUM ring slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotHeadValueMin`"
                  label="SUM head slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotHeadValueMax`"
                  label="SUM head slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotAmuletValueMin`"
                  label="SUM amulet slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotAmuletValueMax`"
                  label="SUM amulet slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotChestValueMin`"
                  label="SUM chest slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotChestValueMax`"
                  label="SUM chest slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotTalismanValueMin`"
                  label="SUM talisman slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotTalismanValueMax`"
                  label="SUM talisman slot max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqReqSumSlotFeetValueMin`"
                  label="SUM feet slot min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumSlotFeetValueMax`"
                  label="SUM feet slot max"
                />

                <fields-col>
                  <section-divider>Orbs requirements</section-divider>
                </fields-col>
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbDamageValueMin`"
                  label="SUM damage orbs min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbDamageValueMax`"
                  label="SUM damage orbs max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbDefenceValueMin`"
                  label="SUM defence orbs min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbDefenceValueMax`"
                  label="SUM defence orbs max"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbHpValueMin`"
                  label="SUM hp orbs min"
                />
                <crud-field-number
                  :sm="6"
                  :api-field-name="`Sub${i}.ReqSumOrbHpValueMax`"
                  label="SUM hp orbs max"
                />
                <fields-col>
                  <section-divider>Recipes</section-divider>
                </fields-col>
                <fields-col>
                  <fields-table>
                    <template #tHeader>
                      <tr>
                        <th>Amount</th>
                        <th>Item</th>
                        <th>Quality</th>
                        <th>Upgrade lvl</th>
                        <th>Boost lvl</th>
                        <th>Enhance lvl</th>
                        <th>Slot</th>
                        <th>Orb attr</th>
                        <th>Gear only</th>
                        <th>Orb only</th>
                        <th>Part only</th>
                        <th>Emblem</th>
                      </tr>
                    </template>
                    <template #tBody>
                      <tr
                        v-for="row in 10"
                        :key="row"
                      >
                        <fields-table-td :width="100">
                          <crud-field-number
                            :api-field-name="`Sub${i}.Comp${row}.Amount`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="350">
                          <crud-field-selector-items
                            :api-field-name="`Sub${i}.Comp${row}.Item`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="100">
                          <crud-field-select
                            :api-field-name="`Sub${i}.Comp${row}.Quality`"
                            options-enum="enums.ItemQuality"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="110">
                          <crud-field-number
                            :api-field-name="`Sub${i}.Comp${row}.UpgradeLevel`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="110">
                          <crud-field-number
                            :api-field-name="`Sub${i}.Comp${row}.BoostLevel`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="110">
                          <crud-field-number
                            :api-field-name="`Sub${i}.Comp${row}.EnhanceLevel`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="80">
                          <crud-field-select
                            :api-field-name="`Sub${i}.Comp${row}.Slot`"
                            options-enum="enums.WearableSlot"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="90">
                          <crud-field-select
                            :api-field-name="`Sub${i}.Comp${row}.OrbAttr`"
                            options-enum="enums.WearableSlot"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="90">
                          <crud-field-checkbox
                            :api-field-name="`Sub${i}.Comp${row}.OnlyGear`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="88">
                          <crud-field-checkbox
                            :api-field-name="`Sub${i}.Comp${row}.OnlyOrb`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="88">
                          <crud-field-checkbox
                            :api-field-name="`Sub${i}.Comp${row}.OnlyPart`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                        <fields-table-td :width="350">
                          <CrudFieldSelectorWearableSets
                            :api-field-name="`Sub${i}.Comp${row}.Emblem`"
                            :label="false"
                            slim
                          />
                        </fields-table-td>
                      </tr>
                    </template>
                  </fields-table>
                </fields-col>
              </fields-col>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
