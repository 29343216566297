<template>
  <crud-table
    :default-visible-columns="['Label']"
    api="items/item-tag-groups"
    api-item="items/item-tag-group"
    :versioned="false"
    show-img-preview-column
  >
    <!-- this code should be added when component is used also in select mode (allow pass slots) -->
    <template
      v-for="(_, name) in $slots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>
    <!-- don't remove above code -->
  </crud-table>
</template>
