<script>
import { useCssVar } from '@vueuse/core'
import { defineAsyncComponent } from 'vue'
import { LayoutSettings } from '@/settings/defaultMeta.js'

export default {
  name: 'PageMenu',
  components: {
    GameSelector: defineAsyncComponent(() => import('!/components/page-layout/header/GameSelector.vue'))
  },
  props: {
    allowCollapsed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    if (LayoutSettings.menu.textColor) {
      useCssVar('--gs-menu-text-color', document.documentElement).value = LayoutSettings.menu.textColor
    }

    if (LayoutSettings.menu.textActiveColor) {
      useCssVar('--gs-menu-active-color', document.documentElement).value = LayoutSettings.menu.textActiveColor
    }

    if (LayoutSettings.menu.hoverBgColor) {
      useCssVar('--gs-menu-hover-bg-color', document.documentElement).value = LayoutSettings.menu.hoverBgColor
    }

    if (LayoutSettings.menu.submenuBgColor) {
      useCssVar('--gs-menu-bg-color', document.documentElement).value = LayoutSettings.menu.submenuBgColor
    }

    return {
      LayoutSettings
    }
  },
  data() {
    return {
      isCollapse: false,
      showGameSelector: false
    }
  },
  watch: {
    allowCollapsed: {
      handler() {
        if (this.allowCollapsed === false) {
          this.$store.state.main.pageMenuCollapse = false
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    storeOpenedSubmenus(idMenu, open) {
      if (idMenu.slice(0, 8) === 'submenu-') {
        this.$store.commit('main/updatePageMenuSubmenus', [idMenu, open])
      }
    }
  }
}
</script>

<template>
  <el-scrollbar
    class="gs-menu-wrapper gs-font-scaled fixed min-h-full"
    :class="[
      { 'collapsed': $store.getters['main/pageMenuCollapse'], 'pr-0.5': $store.getters['main/pageMenuWidth'] },
      LayoutSettings.menu.bgClass
    ]"
    :style="{ width: $store.getters['main/pageMenuWidth'] }"
    style="transform: translateX(0px)"
  >
    <slot name="header">
      <div
        class="gs-menu-header relative ml-2 mt-2 flex items-center"
        :class="{
          'justify-start': !$store.getters['main/pageMenuCollapse'],
          'justify-center': $store.getters['main/pageMenuCollapse']
        }"
      >
        <div>
          <div class="menu-logo-wrapper max-w-full">
            <el-image
              v-if="typeof LayoutSettings.menu.Logo === 'string'"
              :src="LayoutSettings.menu.Logo"
              fit="contain"
            />
            <component
              :is="LayoutSettings.menu.Logo"
              v-else
            />
          </div>
        </div>
        <div>
          <span
            v-if="!$store.getters['main/pageMenuCollapse']"
            class="font-related-xxl bg-clip-text pl-5 tracking-widest subpixel-antialiased"
            :class="LayoutSettings.menu.titleClass"
          >
            <template v-if="LayoutSettings?.gameSelectorSettings?.length && $store.getters['main/gameApiSettings']?.name">
              {{ $store.getters['main/gameApiSettings'].name }}
            </template>
            <template v-else>
              {{ LayoutSettings.menu.title }}
            </template>
          </span>
        </div>
        <div
          v-if="LayoutSettings?.gameSelectorSettings?.length && !$store.getters['main/pageMenuCollapse']"
          class="font-related-xss absolute bottom-1 right-1 cursor-pointer opacity-90 hover:opacity-100 focus:outline-none"
        >
          <el-tooltip
            effect="light"
            content="Switch game"
            placement="right"
            :show-after="600"
          >
            <icon-ify
              icon="mdi:cog-outline"
              class="gs-scaled-icon-xs transition hover:rotate-180 focus:outline-none"
              @click="showGameSelector = !showGameSelector"
            />
          </el-tooltip>
        </div>
      </div>
    </slot>
    <transition name="el-fade-in-linear">
      <GameSelector
        v-if="LayoutSettings?.gameSelectorSettings?.length && !$store.getters['main/pageMenuCollapse'] && showGameSelector"
        :games="LayoutSettings.gameSelectorSettings"
        class="w-full px-2"
        @change="showGameSelector = !showGameSelector"
      />
    </transition>
    <el-menu
      class="w-full border-0 bg-transparent pb-5 text-gs-menu-txt"
      router
      :default-active="$route.path"
      :collapse="$store.getters['main/pageMenuCollapse']"
      :collapse-transition="false"
    >
      <slot name="default" />
    </el-menu>
    <div
      v-if="allowCollapsed"
      class="not-mobile z-10 pr-1"
    >
      <slot name="collapsed_btn">
        <el-button
          class="gs-button fixed right-0.5 top-0 p-0"
          :class="LayoutSettings.menu.collapsedBtnClass"
          size="small"
        >
          <transition>
            <icon-ify
              v-if="!$store.getters['main/pageMenuCollapse']"
              icon="dashicons:arrow-left"
              class="gs-scaled-icon-lg"
              @click="$store.commit('main/togglePageManuCollapse')"
            />
            <icon-ify
              v-else
              icon="dashicons:arrow-right"
              class="gs-scaled-icon-lg"
              @click="$store.commit('main/togglePageManuCollapse')"
            />
          </transition>
        </el-button>
      </slot>
    </div>
  </el-scrollbar>
</template>

<style lang="postcss">
.el-aside {
  .el-menu {
    --el-menu-bg-color: var(--gs-menu-bg-color);
    &:not(.el-menu--collapse) {
      margin-left: -8px;
      .gs-menu-no-icon {
        display: none;
      }
    }
  }
  .gs-menu {
    &-header {
      height: var(--gs-bar-height);
    }
  }

  .el-menu-item.is-active {
    color: var(--el-menu-active-color) !important;
  }
}
.gs-menu-wrapper,
.gs-menu-sub {
  .el-menu-item,
  .el-sub-menu__title {
    --el-menu-text-color: var(--gs-menu-text-color);
    --el-menu-active-color: var(--gs-menu-active-color);
    --el-menu-hover-bg-color: var(--gs-menu-hover-bg-color);
    --el-menu-item-height: 44px;
    height: 44px;
    &.is-active {
      font-weight: bolder;
    }
    &.el-tooltip__trigger {
      padding: 0;
    }
  }

  .no-title {
    > .el-menu-item-group__title {
      display: none !important;
    }
  }
}

.gs-menu-wrapper {
  .el-menu {
    &.el-menu--collapse {
      .el-menu-item-group.label .el-menu-item-group__title {
        display: none !important;
      }
    }
  }

  .group-items {
    .el-menu-item-group__title {
      display: none;
    }
  }

  .el-sub-menu.active {
    .el-sub-menu__title {
      color: var(--el-menu-active-color) !important;
    }
  }
}

.gs-menu-sub {
  .el-divider.build-in {
    border-color: inherit;
  }

  .el-menu-item.is-active {
    color: var(--el-menu-active-color) !important;
  }
}
.el-menu .el-tooltip__trigger {
  justify-content: center;
}
body {
  &[gs-size] {
    .menu-logo-wrapper {
      width: 35px;
      aspect-ratio: 1;
    }
    .collapsed {
      .menu-logo-wrapper {
        transform: translate(-2px, 5px);
      }
    }
  }
  &[gs-size='default'] {
    .menu-logo-wrapper {
      width: 38px;
    }
    .collapsed {
      .menu-logo-wrapper {
        transform: translate(-4px, 5px);
      }
    }
  }
  &[gs-size='large'] {
    .menu-logo-wrapper {
      width: 40px;
    }
    .collapsed {
      .menu-logo-wrapper {
        transform: translate(-6px, 5px);
      }
    }
  }
}
.el-popper {
  &.gs-menu-sub {
    --el-menu-bg-color: var(--gs-menu-bg-color);
  }
}
</style>
