<template>
  <crud-details-page
    api="battle/buff-asset"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.BuffAssets"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Icon -->
        <crud-field-selector-files
          api-field-name="Icon"
          required
        />
        <!-- Animation -->
        <crud-field-selector-files
          api-field-name="Animation"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
