<script>
import CrudFieldSelectorBattleActiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattleActiveSkills.vue'
import CrudFieldSelectorBattlePassiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattlePassiveSkills.vue'

export default {
  components: { CrudFieldSelectorBattleActiveSkills, CrudFieldSelectorBattlePassiveSkills }
}
</script>

<template>
  <crud-details-page
    api="battle/mob"
    disable-top-margin
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Labels -->
        <crud-field-text
          api-field-name="Labels"
          required
        />
        <!-- IsBoss -->
        <crud-field-switcher api-field-name="IsBoss" />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
        />
        <!-- AvatarGraphic -->
        <crud-field-selector-files
          api-field-name="AvatarGraphic"
          required
        />
        <!-- PreviewGraphic -->
        <crud-field-selector-files api-field-name="PreviewGraphic" />
        <!-- GraphicSkin -->
        <crud-field-text api-field-name="GraphicSkin" />
        <!-- AiAttackOrder -->
        <crud-field-number api-field-name="AiAttackOrder" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- HpBase -->
        <crud-field-number api-field-name="HpBase" />
        <!-- AttackBase -->
        <crud-field-number api-field-name="AttackBase" />
        <!-- DefenceBase -->
        <crud-field-number api-field-name="DefenceBase" />
        <!-- MagicBase -->
        <crud-field-number api-field-name="MagicBase" />
        <!-- MeleeResistance -->
        <crud-field-number api-field-name="MeleeResistance" />
        <!-- Dodge -->
        <crud-field-number api-field-name="Dodge" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- HpIncrement -->
        <crud-field-number api-field-name="HpIncrement" />
        <!-- AttackIncrement -->
        <crud-field-number api-field-name="AttackIncrement" />
        <!-- DefenceIncrement -->
        <crud-field-number api-field-name="DefenceIncrement" />
        <!-- MagicIncrement -->
        <crud-field-number api-field-name="MagicIncrement" />
        <!-- MagicResistance -->
        <crud-field-number api-field-name="MagicResistance" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Wait1 -->
        <crud-field-number api-field-name="Wait1" />
        <!-- Wait2 -->
        <crud-field-number api-field-name="Wait2" />
        <!-- ActiveSkill1 -->
        <CrudFieldSelectorBattleActiveSkills api-field-name="ActiveSkill1" />
        <!-- ActiveSkill2 -->
        <CrudFieldSelectorBattleActiveSkills api-field-name="ActiveSkill2" />
        <!-- PassiveSkill1 -->
        <CrudFieldSelectorBattlePassiveSkills api-field-name="PassiveSkill1" />
        <!-- PassiveSkill2 -->
        <CrudFieldSelectorBattlePassiveSkills api-field-name="PassiveSkill2" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
