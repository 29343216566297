<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectShieldReviveFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'shield_revive',
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'chance': 0,
      'dlvl-chance': 0,
      'value': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="ShieldRevive_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="ShieldRevive_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-range -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="ShieldRevive_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="ShieldRevive_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.chance`"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-chance`"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.value`"
          label="Shield val after revive %"
          :min="0"
          :max="100"
          :precision="2"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Val_after_revive%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-value`"
          label="Value inc per level"
          :precision="2"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Value_inc_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
