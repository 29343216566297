<script>
import CrudFieldSelectorBattleActiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattleActiveSkills.vue'

export default {
  name: 'TalentDetailsPage',
  components: { CrudFieldSelectorBattleActiveSkills },
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'talent'
        itemData.ApIsSearchable = true
        itemData.PriceGold = 0
        itemData.PricePremium = 0
        itemData.TalentReqCampaignStars = 0
        itemData.TalentExtractCurrencyAmount = 0
        itemData.TalentColor = 'blue'
      }
      return itemData
    }
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    enable-relations
    doc-key="items-talent"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
        <!-- Preview asset -->
        <crud-field-selector-files
          api-field-name="TalentPreviewAsset"
          label="Preview asset"
        />
        <!-- Background asset -->
        <crud-field-selector-files
          api-field-name="TalentBackAsset"
          label="Background asset"
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
        <!-- Spirit color -->
        <crud-field-select
          api-field-name="TalentColor"
          label="Spirit color"
          options-enum="enums.SpiritColor"
        />
        <!-- Required campaign stars -->
        <crud-field-number
          api-field-name="TalentReqCampaignStars"
          label="Required campaign stars"
        />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          required
        />
        <fields-col :sm="12">
          <!-- Skill 1 ball -->
          <CrudFieldSelectorBattleActiveSkills
            api-field-name="ActiveSkill1"
            label="Skill 1 ball"
          />
          <!-- Skill 2 balls -->
          <CrudFieldSelectorBattleActiveSkills
            api-field-name="ActiveSkill2"
            label="Skill 2 balls"
          />
          <!-- Skill 4 balls -->
          <CrudFieldSelectorBattleActiveSkills
            api-field-name="ActiveSkill3"
            label="Skill 4 balls"
          />
          <!-- Talent Extract Currency -->
          <crud-field-selector-items
            api-field-name="TalentExtractCurrency"
            label="Talent Extract Currency"
          />
          <!-- Talent Extract Currency Amount -->
          <crud-field-number
            api-field-name="TalentExtractCurrencyAmount"
            label="Talent Extract Currency Amount"
          />
        </fields-col>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
