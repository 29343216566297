<script>
import CrudFieldSelectorBattleLocations from '@/components/forms/crud-fields/CrudFieldSelectorBattleLocations.vue'
import CrudFieldSelectorBattleMobs from '@/components/forms/crud-fields/CrudFieldSelectorBattleMobs.vue'

export default {
  components: { CrudFieldSelectorBattleLocations, CrudFieldSelectorBattleMobs }
}
</script>

<template>
  <crud-details-page
    api="battle/stage"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col
                :sm="6"
              >
                <!-- Name -->
                <crud-field-selector-client-strings
                  api-field-name="Name"
                  required
                />
                <!-- Location -->
                <CrudFieldSelectorBattleLocations
                  api-field-name="Location"
                  required
                />
                <!-- Graphic -->
                <crud-field-selector-files
                  api-field-name="Graphic"
                  required
                />
                <!-- StageNo -->
                <crud-field-number
                  api-field-name="StageNo"
                  required
                />
                <!-- BattlesDailyLimit -->
                <crud-field-number
                  api-field-name="BattlesDailyLimit"
                  label="Battle stages limit (no. of fights per reset)"
                  required
                />
                <!-- BattlesDailyLimitResetTimes -->
                <crud-field-number
                  api-field-name="BattlesDailyLimitResetTimes"
                  label="Battle stages limit reset times"
                  required
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- EnergyCostNormal -->
                <crud-field-number
                  api-field-name="EnergyCostNormal"
                  required
                />
                <!-- EnergyCostHard -->
                <crud-field-number
                  api-field-name="EnergyCostHard"
                  required
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- TurnsFor2starNormal -->
                <crud-field-number
                  api-field-name="TurnsFor2starNormal"
                  required
                />
                <!-- TurnsFor2starHard -->
                <crud-field-number
                  api-field-name="TurnsFor2starHard"
                  required
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- TurnsFor3starNormal -->
                <crud-field-number
                  api-field-name="TurnsFor3starNormal"
                  required
                />
                <!-- TurnsFor3starHard -->
                <crud-field-number
                  api-field-name="TurnsFor3starHard"
                  required
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Mobs definitions"
            name="mobs"
          >
            <fields-col>
              <fields-col
                v-for="i in 3"
                :key="i"
              >
                <fields-col>
                  <section-divider class="mx-5">
                    Wave {{ i }}
                  </section-divider>
                </fields-col>
                <fields-col
                  v-for="j in 3"
                  :key="j"
                  :sm="8"
                >
                  <section-divider class="mx-4">
                    {{ j === 1 ? 'Left' : j === 2 ? 'Center' : 'Right' }} position
                  </section-divider>
                  <!-- Mob -->
                  <CrudFieldSelectorBattleMobs
                    :api-field-name="`Mob${i}${j}`"
                    label="Mob"
                  />
                  <!-- MobLevelNormal -->
                  <crud-field-number
                    :api-field-name="`Mob${i}${j}LevelNormal`"
                    label="Level normal"
                  />
                  <!-- MobLevelHard -->
                  <crud-field-number
                    :api-field-name="`Mob${i}${j}LevelHard`"
                    label="Level hard"
                  />
                  <!-- Mob11Isback -->
                  <crud-field-switcher
                    :api-field-name="`Mob${i}${j}Isback`"
                    label="Back row"
                  />
                </fields-col>
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Rewards"
            name="rewards"
          >
            <fields-col>
              <fields-col>
                <section-divider>Rewards</section-divider>
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- RewardItemexpNormal -->
                <crud-field-number
                  api-field-name="RewardItemexpNormal"
                  label="Reward itemexp normal"
                />
                <!-- RewardItemexpHard -->
                <crud-field-number
                  api-field-name="RewardItemexpHard"
                  label="Reward itemexp hard"
                />
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- RewardHeroexpNormal -->
                <crud-field-number
                  api-field-name="RewardHeroexpNormal"
                  label="Reward heroexp normal"
                />
                <!-- RewardHeroexpHard -->
                <crud-field-number
                  api-field-name="RewardHeroexpHard"
                  label="Reward heroexp hard"
                />
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- RewardGoldNormal -->
                <crud-field-number
                  api-field-name="RewardGoldNormal"
                  label="Reward gold normal"
                />
                <!-- RewardGoldHard -->
                <crud-field-number
                  api-field-name="RewardGoldHard"
                  label="Reward gold hard"
                />
              </fields-col>
              <fields-col>
                <section-divider>Extra Rewards</section-divider>
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- ExtraRewardChanceNormal -->
                <crud-field-number
                  api-field-name="ExtraRewardChanceNormal"
                  label="Chance normal"
                />
                <!-- ExtraRewardChanceHard -->
                <crud-field-number
                  api-field-name="ExtraRewardChanceHard"
                  label="Chance hard"
                />
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- ExtraRewardItemNormal -->
                <crud-field-selector-items
                  api-field-name="ExtraRewardItemNormal"
                  label="Item normal"
                />
                <!-- ExtraRewardItemHard -->
                <crud-field-selector-items
                  api-field-name="ExtraRewardItemHard"
                  label="Item hard"
                />
              </fields-col>
              <fields-col
                :sm="8"
              >
                <!-- ExtraRewardChanceNormal -->
                <crud-field-number
                  api-field-name="ExtraRewardItemNormalAmount"
                  label="Item amount"
                />
                <!-- ExtraRewardChanceHard -->
                <crud-field-number
                  api-field-name="ExtraRewardItemHardAmount"
                  label="Item amount"
                />
              </fields-col>
              <fields-col>
                <section-divider>Event Rewards</section-divider>
              </fields-col>
              <template
                v-for="code in ['Normal', 'Hard']"
                :key="code"
              >
                <fields-col
                  :sm="12"
                >
                  <template
                    v-for="i in 6"
                    :key="i"
                  >
                    <crud-field-selector-items
                      :sm="16"
                      :api-field-name="`EventRewardItem${code}${i}`"
                      :label="`Item ${code} ${i}`"
                    />
                    <crud-field-number
                      :sm="8"
                      :api-field-name="`EventRewardItem${code}${i}Amount`"
                      :label="`Amount ${i}`"
                    />
                  </template>
                  <crud-field-selector-loots
                    :sm="16"
                    :api-field-name="`EventRewardItem${code}`"
                    :label="`Loot ${code}`"
                  />
                  <crud-field-number
                    :sm="8"
                    :api-field-name="`EventRewardLoot${code}Chance`"
                    label="Chance"
                  />
                  <crud-field-selector-loots
                    :sm="16"
                    :api-field-name="`EventRewardLoot2${code}`"
                    :label="`Loot ${code} 2`"
                  />
                  <crud-field-number
                    :sm="8"
                    :api-field-name="`EventRewardLoot2${code}Chance`"
                    label="Chance"
                  />
                </fields-col>
              </template>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Idle"
            name="idle"
          >
            <fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleGoldGainNormal -->
                <crud-field-number
                  api-field-name="IdleGoldGainNormal"
                  label="Idle gold gain normal [1 min]"
                />
                <!-- IdleGoldGainHard -->
                <crud-field-number
                  api-field-name="IdleGoldGainHard"
                  label="Idle gold gain hard [1 min]"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleEterniumGainNormal -->
                <crud-field-number
                  api-field-name="IdleEterniumGainNormal"
                  label="Idle eternium gain normal [1 min]"
                />
                <!-- IdleEterniumGainHard -->
                <crud-field-number
                  api-field-name="IdleEterniumGainHard"
                  label="Idle eternium gain hard [1 min]"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleEternium2GainNormal -->
                <crud-field-number
                  api-field-name="IdleEternium2GainNormal"
                  label="Idle eternium2 gain normal [1 min]"
                  :precision="3"
                />
                <!-- IdleEternium2GainHard -->
                <crud-field-number
                  api-field-name="IdleEternium2GainHard"
                  label="Idle eternium2 gain hard [1 min]"
                  :precision="3"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleExpGainNormal -->
                <crud-field-number
                  api-field-name="IdleExpGainNormal"
                  label="Idle exp gain normal [1 min]"
                  :precision="3"
                />
                <!-- IdleExpGainHard -->
                <crud-field-number
                  api-field-name="IdleExpGainHard"
                  label="Idle exp gain hard [1 min]"
                  :precision="3"
                />
              </fields-col>
              <fields-col>
                <section-divider>Feature unlock</section-divider>
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleExtractGainNormal -->
                <crud-field-number
                  api-field-name="IdleExtractGainNormal"
                  label="Idle extract gain normal [1 min]"
                  :precision="3"
                />
                <!-- IdleExtractGainHard -->
                <crud-field-number
                  api-field-name="IdleExtractGainHard"
                  label="Idle extract gain hard [1 min]"
                  :precision="3"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleGlobalLootChanceNormal -->
                <crud-field-number
                  api-field-name="IdleGlobalLootChanceNormal"
                  label="Idle global loot chance normal"
                  :precision="3"
                />
                <!-- IdleGlobalLootChanceHard -->
                <crud-field-number
                  api-field-name="IdleGlobalLootChanceHard"
                  label="Idle global loot chance hard"
                  :precision="3"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleReforgeGainNormal -->
                <crud-field-number
                  api-field-name="IdleReforgeGainNormal"
                  label="Idle reforge gain normal [1 min]"
                  :precision="3"
                />
                <!-- IdleReforgeGainHard -->
                <crud-field-number
                  api-field-name="IdleReforgeGainHard"
                  label="Idle reforge gain hard [1 min]"
                  :precision="3"
                />
              </fields-col>
              <fields-col>
                <section-divider>Idle rewards</section-divider>
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleRewardChanceNormal -->
                <crud-field-number
                  api-field-name="IdleRewardChanceNormal"
                  label="Idle reward chance normal"
                  :precision="3"
                />
                <!-- IdleRewardChanceHard -->
                <crud-field-number
                  api-field-name="IdleRewardChanceHard"
                  label="Idle reward chance hard"
                  :precision="3"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleRewardWeightNormal -->
                <crud-field-number
                  api-field-name="IdleRewardWeightNormal"
                  label="Idle reward weight normal"
                  :precision="3"
                />
                <!-- IdleRewardWeightHard -->
                <crud-field-number
                  api-field-name="IdleRewardWeightHard"
                  label="Idle reward weight hard"
                  :precision="3"
                />
              </fields-col>
              <fields-col
                :sm="6"
              >
                <!-- IdleRewardItemNormal -->
                <crud-field-selector-items
                  api-field-name="IdleRewardItemNormal"
                  label="Idle reward item normal"
                  :precision="3"
                />
                <!-- IdleRewardItemHard -->
                <crud-field-selector-items
                  api-field-name="IdleRewardItemHard"
                  label="Idle reward item hard"
                  :precision="3"
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
