<script>
import { inject } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldLinkageGraph',
  mixins: [BasicProps],
  props: {
    initFilters: {
      type: Object,
      default: () => ({
        // 'colNameApi': initValue
      })
    }
  },
  setup(props) {
    const initForm = props.form ? {} : inject('initForm')
    return {
      initForm
    }
  },
  computed: {
    linkageList() {
      if (Array.isArray(this.fieldModel)) {
        return Object.fromEntries(this.fieldModel.map((coordinates, index) => [`${coordinates[0]}_${coordinates[1]}`, index]))
      }
      return {}
    },
    linkageNodes() {
      const nodest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      if (Array.isArray(this.fieldModel)) {
        this.fieldModel.forEach((linkage) => {
          nodest[linkage[0] - 1]++
          nodest[linkage[1] - 1]++
        })
      }
      return nodest
    }
  },
  methods: {
    toggleLinkage(coordinates) {
      if (!Array.isArray(this.fieldModel)) {
        this.fieldModel = [coordinates]
      } else {
        const index = this.linkageList?.[`${coordinates[0]}_${coordinates[1]}`]
        if (index !== undefined) {
          this.fieldModel.splice(index, 1)
        } else {
          this.fieldModel.push(coordinates)
        }
      }
      if (JSON.stringify(this.fieldModel) === JSON.stringify(this.$utils.getByPath(this.initForm.form, this.apiFieldName) || [])) {
        delete this.initForm.diffs[this.apiFieldName]
      } else {
        this.initForm.diffs[this.apiFieldName] = true
      }
    }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <div class="flex justify-center mt-2 bg-black relative pb-5">
          <div class="p-12 inline-block">
            <div class="flex justify-center w-[20em] flex-wrap">
              <template
                v-for="row in 4"
                :key="row"
              >
                <div class="w-[10em] h-[12em] relative">
                  <div
                    class="absolute w-2 h-full left-0 cursor-pointer -translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 1}_${(row - 1) * 3 + 4}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 1, (row - 1) * 3 + 4])"
                  />
                  <div
                    class="absolute w-2 h-full right-0 cursor-pointer translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 2}_${(row - 1) * 3 + 5}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 2, (row - 1) * 3 + 5])"
                  />
                  <div
                    class="absolute w-full h-2 bottom-0 cursor-pointer translate-y-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 4}_${(row - 1) * 3 + 5}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 4, (row - 1) * 3 + 5])"
                  />
                  <div
                    v-if="row !== 3"
                    class="absolute h-[15.6em] w-2 left-0 top-0 cursor-pointer -rotate-[39.8deg] origin-top-left -translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 1}_${(row - 1) * 3 + 5}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 1, (row - 1) * 3 + 5])"
                  />
                  <div
                    v-if="row !== 2"
                    class="absolute h-[15.6em] w-2 right-0 top-0 cursor-pointer rotate-[39.8deg] origin-top-left translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 2}_${(row - 1) * 3 + 4}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 2, (row - 1) * 3 + 4])"
                  />
                  <template
                    v-if="row === 1"
                  >
                    <div
                      class="absolute w-full h-2 top-0 cursor-pointer -translate-y-1 z-[1]"
                      :class="[linkageList?.['1_2'] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                      @click="toggleLinkage([1, 2])"
                    />
                    <div
                      class="absolute left-0 top-0 rounded-full size-[3em] -translate-x-1/2 -translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[0] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      1
                    </div>
                    <div
                      class="absolute right-0 top-0 rounded-full size-[5em] translate-x-1/2 -translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[1] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      2
                    </div>
                    <div
                      class="absolute right-0 bottom-0 rounded-full size-[2.8em] translate-x-1/2 translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[4] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      5
                    </div>
                  </template>
                  <template v-else-if="row === 2">
                    <div
                      class="absolute right-0 bottom-0 rounded-full size-[12em] translate-x-1/2 translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[7] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      8
                    </div>
                  </template>
                  <template v-else-if="row === 4">
                    <div
                      class="absolute right-0 bottom-0 rounded-full size-[5em] translate-x-1/2 translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[13] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      14
                    </div>
                    <div
                      class="absolute right-0 top-0 rounded-full size-[2.8em] translate-x-1/2 -translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[10] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      11
                    </div>
                  </template>
                  <div
                    class="absolute left-0 bottom-0 rounded-full size-[3em] -translate-x-1/2 translate-y-1/2 z-[2] flex justify-center items-center"
                    :class="[linkageNodes[(row - 1) * 3 + 3] ? 'bg-white' : 'bg-neutral-500']"
                  >
                    {{ (row - 1) * 3 + 4 }}
                  </div>
                </div>
                <div class="w-[10em] h-[12em] relative">
                  <div
                    class="absolute w-2 h-full right-0 cursor-pointer translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 3}_${(row - 1) * 3 + 6}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 3, (row - 1) * 3 + 6])"
                  />
                  <div
                    class="absolute w-full h-2 bottom-0 cursor-pointer translate-y-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 5}_${(row - 1) * 3 + 6}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 5, (row - 1) * 3 + 6])"
                  />
                  <div
                    v-if="row !== 2"
                    class="absolute h-[15.6em] w-2 left-0 top-0 cursor-pointer -rotate-[39.8deg] origin-top-left -translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 2}_${(row - 1) * 3 + 6}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 2, (row - 1) * 3 + 6])"
                  />
                  <div
                    v-if="row !== 3"
                    class="absolute h-[15.6em] w-2 right-0 top-0 cursor-pointer rotate-[39.8deg] origin-top-left translate-x-1 z-[1]"
                    :class="[linkageList?.[`${(row - 1) * 3 + 3}_${(row - 1) * 3 + 5}`] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                    @click="toggleLinkage([(row - 1) * 3 + 3, (row - 1) * 3 + 5])"
                  />
                  <template
                    v-if="row === 1"
                  >
                    <div
                      class="absolute w-full h-2 top-0 cursor-pointer -translate-y-1 z-[1]"
                      :class="[linkageList?.['2_3'] !== undefined ? 'bg-white' : 'bg-neutral-500']"
                      @click="toggleLinkage([2, 3])"
                    />
                    <div
                      class="absolute right-0 top-0 rounded-full size-[3em] translate-x-1/2 -translate-y-1/2 z-[2] flex justify-center items-center"
                      :class="[linkageNodes[2] ? 'bg-white' : 'bg-neutral-500']"
                    >
                      3
                    </div>
                  </template>
                  <div
                    class="absolute right-0 bottom-0 rounded-full size-[3em] translate-x-1/2 translate-y-1/2 z-[2] flex justify-center items-center"
                    :class="[linkageNodes[(row - 1) * 3 + 5] ? 'bg-white' : 'bg-neutral-500']"
                  >
                    {{ (row - 1) * 3 + 6 }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <el-button
            class="absolute bottom-2 left-1"
            link
            @click="fieldModel = []"
          >
            -clear all-
          </el-button>
        </div>
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
