<template>
  <crud-details-page
    api="settings/build-version"
    :versioned="false"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Version -->
        <crud-field-text
          api-field-name="Version"
          required
        />
        <!-- Platform -->
        <crud-field-select
          api-field-name="Platform"
          options-enum="enums.Platform"
          required
        />
      </fields-col>
      <fields-col :sm="4">
        <!-- Forced -->
        <crud-field-switcher api-field-name="Forced" />
        <!-- Optional -->
        <crud-field-switcher api-field-name="Optional" />
        <!-- LatestProdVersion -->
        <crud-field-switcher api-field-name="LatestProdVersion" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
