<template>
  <crud-details-page
    api="battle/skill-description-tag"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Tag -->
        <crud-field-text
          api-field-name="Tag"
          required
        />
        <!-- Replacement -->
        <crud-field-text
          api-field-name="Replacement"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
