const validators = {
  maxLength: (len, msg = '') => {
    return { type: 'string', max: len, trigger: 'change', message: msg || `max length ${len} chars` }
  },
  gsVersion: (msg = '') => {
    return {
      type: 'string',
      validator: (rule, value) => {
        if (value?.length) {
          // eslint-disable-next-line regexp/no-super-linear-backtracking
          return /^\d(?:\d?[^\d\n\r\u2028\u2029]\d+|\d{2,}(?:[^\d\n\r\u2028\u2029]\d+)?).\d(?:\d?[^\d\n\r\u2028\u2029]\d+|\d{2,}(?:[^\d\n\r\u2028\u2029]\d+)?)$/.test(value)
        }
        return true
      },
      trigger: 'change',
      message: msg || `invalid version number`
    }
  },
  integer: (msg = '') => {
    return {
      type: 'string',
      validator: (rule, value) => {
        if (value?.length) {
          return /^\d+$/.test(value)
        }
        return true
      },
      trigger: 'change',
      message: msg || `invalid integer`
    }
  }
}

export default {
  install: (Vue) => {
    Vue.config.globalProperties.$v = validators
  }
}
