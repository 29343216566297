<template>
  <crud-table
    :default-visible-columns="['Comment', 'MinUserLevel', 'MaxUserLevel']"
    api="city/shop-sets"
    api-item="city/shop-set"
  >
    <template #header_search_Type>
      <crud-type-fields
        type-value-field="select"
        options-enum="enums.ShopType"
        multiple
        collapse-tags
      />
    </template>
  </crud-table>
</template>
