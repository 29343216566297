<script setup></script>

<template>
  <crud-details-page
    api="quests/earn-gem"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Description -->
        <crud-field-text
          api-field-name="Description"
          required
        />
        <!-- QuestPlace -->
        <crud-field-select
          api-field-name="QuestPlace"
          options-enum="enums.QuestPlace"
          required
        />
        <!-- Criterion -->
        <crud-field-select
          api-field-name="Criterion"
          options-enum="enums.QuestCriterionEarnGems"
          required
        />
      </fields-col>
      <fields-col :sm="16">
        <template
          v-for="i in 20"
          :key="i"
        >
          <!-- Limit -->
          <crud-field-number
            :sm="12"
            :api-field-name="`Limit${i}`"
          />
          <!-- RewardPremium -->
          <crud-field-number
            :sm="12"
            :api-field-name="`RewardPremium${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
