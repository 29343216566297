<template>
  <crud-details-page
    api="challenge-crafts-tabs/challenge-crafts-tab"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-selector-client-strings api-field-name="Name" />
        <!-- OrderBy -->
        <crud-field-number api-field-name="OrderBy" />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
        />
        <!-- ReqLevel -->
        <crud-field-number
          api-field-name="ReqLevel"
          label="Required level"
        />
        <!-- IsActive -->
        <crud-field-switcher api-field-name="IsActive" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
