<script>
import CrudFieldSelectorSpins from '@/components/forms/crud-fields/CrudFieldSelectorSpins.vue'
import CrudFieldSelectorNewsTops from '@/components/forms/crud-fields/CrudFieldSelectorNewsTops.vue'
import CrudFieldSelectorQuestGroups from '@/components/forms/crud-fields/CrudFieldSelectorQuestGroups.vue'

export default {
  components: { CrudFieldSelectorSpins, CrudFieldSelectorNewsTops, CrudFieldSelectorQuestGroups }
}
</script>

<template>
  <crud-details-page
    :back-routes="['dashboard']"
    api="settings/game-setting"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- ActiveSpin -->
                <CrudFieldSelectorSpins
                  api-field-name="ActiveSpin"
                  required
                />
                <!-- ActiveWatchadsSpin -->
                <CrudFieldSelectorSpins
                  api-field-name="ActiveWatchadsSpin"
                  required
                />
                <!-- ChestPurchase10Price -->
                <crud-field-number
                  api-field-name="ChestPurchase10Price"
                  required
                />
                <!-- GuildMinScoreMembers -->
                <crud-field-number
                  api-field-name="GuildMinScoreMembers"
                  label="Min quild members for score (BE guild ranking v2)"
                  required
                />
                <!-- DivideAwakenCoeff -->
                <crud-field-number
                  api-field-name="DivideAwakenCoeff"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- TutorialItemboost -->
                <crud-field-selector-items
                  api-field-name="TutorialItemboost"
                  required
                />
                <!-- CampaginGlobalLoot -->
                <crud-field-selector-loots
                  api-field-name="CampaginGlobalLoot"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- DefaultNewstop -->
                <CrudFieldSelectorNewsTops api-field-name="DefaultNewstop" />
                <!-- ItemAutobattleX10 -->
                <crud-field-selector-items
                  api-field-name="ItemAutobattleX10"
                  required
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Items"
            name="items_for_rewards"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- ItemSpinToken -->
                <crud-field-selector-items
                  api-field-name="ItemSpinToken"
                  required
                />
                <!-- ItemCraftingExtractCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemCraftingExtractCurrency"
                  required
                />
                <!-- ItemRenewTokenLesser -->
                <crud-field-selector-items
                  api-field-name="ItemRenewTokenLesser"
                  required
                />
                <!-- ItemRenewTokenSuperior -->
                <crud-field-selector-items
                  api-field-name="ItemRenewTokenSuperior"
                  required
                />
                <!-- ItemDailytaskCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemDailytaskCurrency"
                  required
                />
                <fields-col>
                  <fields-col :sm="16">
                    <!-- ItemJourneyCompleteRewardItem1 -->
                    <crud-field-selector-items
                      api-field-name="ItemJourneyCompleteRewardItem1"
                      required
                    />
                  </fields-col>
                  <fields-col :sm="8">
                    <!-- ItemJourneyCompleteRewardItem1Amount -->
                    <crud-field-number
                      api-field-name="ItemJourneyCompleteRewardItem1Amount"
                      label="Amount"
                      required
                    />
                  </fields-col>
                  <fields-col :sm="16">
                    <!-- ItemJourneyCompleteRewardItem2 -->
                    <crud-field-selector-items
                      api-field-name="ItemJourneyCompleteRewardItem2"
                      required
                    />
                  </fields-col>
                  <fields-col :sm="8">
                    <!-- ItemJourneyCompleteRewardItem2Amount -->
                    <crud-field-number
                      api-field-name="ItemJourneyCompleteRewardItem2Amount"
                      label="Amount"
                      required
                    />
                  </fields-col>
                  <fields-col :sm="16">
                    <!-- ItemJourneyCompleteRewardItem3 -->
                    <crud-field-selector-items
                      api-field-name="ItemJourneyCompleteRewardItem3"
                      required
                    />
                  </fields-col>
                  <fields-col :sm="8">
                    <!-- ItemJourneyCompleteRewardItem3Amount -->
                    <crud-field-number
                      api-field-name="ItemJourneyCompleteRewardItem3Amount"
                      label="Amount"
                      required
                    />
                  </fields-col>
                </fields-col>

                <crud-field-selector-items
                  api-field-name="ItemPetNodeUnlock"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- ItemExpCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemExpCurrency"
                  required
                />
                <!-- ItemExp2Currency -->
                <crud-field-selector-items
                  api-field-name="ItemExp2Currency"
                  required
                />
                <!-- ItemReforgeCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemReforgeCurrency"
                  required
                />
                <!-- ItemCollection1UnlockCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemCollection1UnlockCurrency"
                  required
                />
                <!-- ItemCollection2UnlockCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemCollection2UnlockCurrency"
                  required
                />
                <!-- ItemJourneyCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemJourneyCurrency"
                  required
                />
                <!-- ItemPvpCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemPvpCurrency"
                  required
                />
                <!-- ItemPvpTicket -->
                <crud-field-selector-items
                  api-field-name="ItemPvpTicket"
                  required
                />
                <!-- ItemStatueShopCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemStatueShopCurrency"
                  required
                />
                <!-- IconCraftTokenLegendary -->
                <crud-field-selector-items
                  api-field-name="IconCraftTokenLegendary"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- ItemGuildCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemGuildCurrency"
                  required
                />
                <!-- ItemEventTrophy -->
                <crud-field-selector-items
                  api-field-name="ItemEventTrophy"
                  required
                />
                <!-- ItemEventBossNormalTicket -->
                <crud-field-selector-items
                  api-field-name="ItemEventBossNormalTicket"
                  required
                />
                <!-- ItemEventBossHardTicket -->
                <crud-field-selector-items
                  api-field-name="ItemEventBossHardTicket"
                  required
                />
                <!-- ItemEventBossMultiTicket -->
                <crud-field-selector-items
                  api-field-name="ItemEventBossMultiTicket"
                  required
                />
                <!-- ItemEventBossGuildTicket -->
                <crud-field-selector-items
                  api-field-name="ItemEventBossGuildTicket"
                  required
                />
                <!-- ItemEventBartershopCurrency -->
                <crud-field-selector-items
                  api-field-name="ItemEventBartershopCurrency"
                  required
                />
                <!-- ItemEventGuildTrophy -->
                <crud-field-selector-items
                  api-field-name="ItemEventGuildTrophy"
                  required
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Items/icons"
            name="items_providing_icons"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- IconItemGold -->
                <crud-field-selector-items
                  api-field-name="IconItemGold"
                  required
                />
                <!-- IconItemPremium -->
                <crud-field-selector-items
                  api-field-name="IconItemPremium"
                  required
                />
                <!-- IconItemVipPoints -->
                <crud-field-selector-items
                  api-field-name="IconItemVipPoints"
                  required
                />
                <!-- IconItemTopupPoints -->
                <crud-field-selector-items
                  api-field-name="IconItemTopupPoints"
                  required
                />
                <!-- IconGuildExp -->
                <crud-field-selector-items
                  api-field-name="IconGuildExp"
                  required
                />
                <!-- IconGuildFamepoints -->
                <crud-field-selector-items
                  api-field-name="IconGuildFamepoints"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- IconCraftTokenUncommon -->
                <crud-field-selector-items
                  api-field-name="IconCraftTokenUncommon"
                  required
                />
                <!-- IconCraftTokenRare -->
                <crud-field-selector-items
                  api-field-name="IconCraftTokenRare"
                  required
                />
                <!-- IconCraftTokenEpic -->
                <crud-field-selector-items
                  api-field-name="IconCraftTokenEpic"
                  required
                />
                <!-- IconCraftTokenLegendary -->
                <crud-field-selector-items
                  api-field-name="IconCraftTokenLegendary"
                  required
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- IconItemEnergy -->
                <crud-field-selector-items
                  api-field-name="IconItemEnergy"
                  required
                />
                <!-- ItemEnergyStew -->
                <crud-field-selector-items
                  api-field-name="ItemEnergyStew"
                  required
                />
                <!-- IconHeroExp -->
                <crud-field-selector-items
                  api-field-name="IconHeroExp"
                  required
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Assets"
            name="custom_assets"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- AssetArenaLocation -->
                <crud-field-selector-files
                  api-field-name="AssetArenaLocation"
                  required
                />
                <!-- AssetBossChallengeLocation -->
                <crud-field-selector-files
                  api-field-name="AssetBossChallengeLocation"
                  required
                />
                <!-- AssetTowerLocation -->
                <crud-field-selector-files
                  api-field-name="AssetTowerLocation"
                  required
                />
                <!-- AssetUniversalChest -->
                <crud-field-selector-files
                  api-field-name="AssetUniversalChest"
                  required
                />
                <!-- AssetArenaLocation -->
                <crud-field-selector-files api-field-name="AssetArenaLocation" />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Monthly card"
            name="monthly_card"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- ItemMonthlyCard1 -->
                <crud-field-selector-items api-field-name="ItemMonthlyCard1" />
                <!-- ItemMonthlyCard1Amount -->
                <crud-field-number api-field-name="ItemMonthlyCard1Amount" />
              </fields-col>
              <fields-col :sm="8">
                <!-- ItemMonthlyCard2 -->
                <crud-field-selector-items api-field-name="ItemMonthlyCard2" />
                <!-- ItemMonthlyCard2Amount -->
                <crud-field-number api-field-name="ItemMonthlyCard2Amount" />
              </fields-col>
              <fields-col :sm="8">
                <!-- PremiumMonthlyCard -->
                <crud-field-number api-field-name="PremiumMonthlyCard" />
                <!-- GoldMonthlyCard -->
                <crud-field-number api-field-name="GoldMonthlyCard" />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Smelt"
            name="smelt"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- SmeltAmountCommon -->
                <crud-field-number api-field-name="SmeltAmountCommon" />
                <!-- SmeltAmountUncommon -->
                <crud-field-number api-field-name="SmeltAmountUncommon" />
                <!-- SmeltAmountRare -->
                <crud-field-number api-field-name="SmeltAmountRare" />
                <!-- SmeltAmountEpic -->
                <crud-field-number api-field-name="SmeltAmountEpic" />
                <!-- SmeltAmountLegendary -->
                <crud-field-number api-field-name="SmeltAmountLegendary" />
              </fields-col>
              <fields-col :sm="8">
                <!-- SmeltEt2AmountCommon -->
                <crud-field-number api-field-name="SmeltEt2AmountCommon" />
                <!-- SmeltEt2AmountUncommon -->
                <crud-field-number api-field-name="SmeltEt2AmountUncommon" />
                <!-- SmeltEt2AmountRare -->
                <crud-field-number api-field-name="SmeltEt2AmountRare" />
                <!-- SmeltEt2AmountEpic -->
                <crud-field-number api-field-name="SmeltEt2AmountEpic" />
                <!-- SmeltEt2AmountLegendary -->
                <crud-field-number api-field-name="SmeltEt2AmountLegendary" />
              </fields-col>
              <fields-col :sm="8">
                <!-- SmeltExtraAmountCommon -->
                <crud-field-number api-field-name="SmeltExtraAmountCommon" />
                <!-- SmeltExtraAmountUncommon -->
                <crud-field-number api-field-name="SmeltExtraAmountUncommon" />
                <!-- SmeltExtraAmountRare -->
                <crud-field-number api-field-name="SmeltExtraAmountRare" />
                <!-- SmeltExtraAmountEpic -->
                <crud-field-number api-field-name="SmeltExtraAmountEpic" />
                <!-- SmeltExtraAmountLegendary -->
                <crud-field-number api-field-name="SmeltExtraAmountLegendary" />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Vip perks"
            name="vip_perks"
          >
            <fields-col :sm="8">
              <!-- EnableAutocalcSkipLevel -->
              <crud-field-number api-field-name="EnableAutocalcSkipLevel" />
              <!-- EnableBattleX10Level -->
              <crud-field-number api-field-name="EnableBattleX10Level" />
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Newcomers event"
            name="newcomers_event"
          >
            <fields-col>
              <!-- NoobWeekDisabled -->
              <crud-field-switcher
                api-field-name="NoobWeekDisabled"
                :sm="6"
              />
              <!-- AssetNoobWeek -->
              <crud-field-selector-files
                api-field-name="AssetNoobWeek"
                :sm="6"
              />
              <!-- AssetNoobWeekNew -->
              <crud-field-selector-files
                api-field-name="AssetNoobWeekNew"
                :sm="6"
              />
              <!-- AssetNoobWeekTopbar -->
              <crud-field-selector-files
                api-field-name="AssetNoobWeekTopbar"
                :sm="6"
              />
              <template
                v-for="i in 7"
                :key="i"
              >
                <fields-col>
                  <el-divider>DAY #{{ i }}</el-divider>
                </fields-col>
                <fields-col :sm="6">
                  <!-- NewcomersEventItemId -->
                  <crud-field-selector-items
                    :api-field-name="`NewcomersEvent${i}.Item1`"
                    label="Item 1"
                  />
                  <!-- NewcomersEventItemAmount -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Amount1`"
                    label="Item amount 1"
                  />
                </fields-col>
                <fields-col :sm="6">
                  <!-- NewcomersEventItemId -->
                  <crud-field-selector-items
                    :api-field-name="`NewcomersEvent${i}.Item2`"
                    label="Item 2"
                  />
                  <!-- NewcomersEventItemAmount -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Amount2`"
                    label="Item amount 2"
                  />
                </fields-col>
                <fields-col :sm="6">
                  <!-- NewcomersEventGold -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Gold`"
                    label="Gold"
                  />
                  <!-- NewcomersEventPremium -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Premium`"
                    label="Premium"
                  />
                </fields-col>
                <fields-col :sm="6">
                  <!-- NewcomersEventEnergy -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Energy`"
                    label="Energy"
                  />
                  <!-- NewcomersEventEternium -->
                  <crud-field-number
                    :api-field-name="`NewcomersEvent${i}.Eternium`"
                    label="Eternium"
                  />
                </fields-col>
              </template>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Custom quest groups"
            name="custom_quest_groups"
          >
            <fields-col :sm="8">
              <!-- CustomGroupGemPass -->
              <CrudFieldSelectorQuestGroups api-field-name="CustomGroupGemPass" />
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Custom battle event"
            name="custom_battle_event"
          >
            <fields-col>
              <crud-field-selector-client-strings
                v-for="n in 15"
                :key="n"
                :sm="8"
                :api-field-name="`BeFeaturedItemDesc${n}`"
                :label="`BeFeaturedItemDesc${n}`"
              />
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
