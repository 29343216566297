<script>
import { MessageBox } from '@element-plus/icons-vue'
import TopSelectorClientStrings from '!/components/pages/settings/client-strings/TopSelector.vue'
import { userRightRoles } from '!/composition/utilities'

export default {
  components: { MessageBox, TopSelectorClientStrings },
  setup() {
    return {
      userRightRoles
    }
  },
  data() {
    return {
      translationPackLoading: false
    }
  },
  methods: {
    createTranslationPack() {
      this.$messageBox
        .confirm('Do you really want to create new translation pack?', undefined, {
          confirmButtonText: 'Yes, create',
          type: 'warning'
        })
        .then(() => {
          this.translationPackLoading = true
          this.$axios
            .post(`/admin/api/client-strings/new-translation-pack/`, {})
            .then((data) => {
              if (!data.data.keys) {
                this.$message({
                  message: 'No new keys were found',
                  type: 'warning',
                  offset: 40
                })
                return
              }
              this.$refs.table.fetchData()
              this.$message({
                message: `translation pack created with ${data.data.keys} keys`,
                type: 'success',
                offset: 40
              })
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.translationPackLoading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<template>
  <crud-table
    ref="table"
    :columns-settings="{
      StringId: { cellEdit: false, header: 'Key', width: 500 },
      Contents: { header: 'EN', width: 500 },
      ContentsTranslated: { header: 'EN verified' },
      Translation: { header: 'Translation pack' },
      Remarks: { cellEdit: true }
    }"
    :default-columns-settings="{
      cellEdit: $utils.checkRights(userRightRoles.translator)
    }"
    :actions-on-list="{
      crudDelete: false
    }"
    :default-visible-columns="['StringId', 'Contents', 'ContentsTranslated', 'Translation']"
    api="settings/client-strings"
    api-item="settings/client-string"
    :versioned="false"
    :top-actions="{
      excelExport: true,
      importExcel: $utils.checkRights(userRightRoles.translator),
      addNew: true
    }"
  >
    <template #topLeft>
      <TopSelectorClientStrings />
    </template>
    <template #topRight>
      <el-tooltip
        v-if="$utils.checkRights(userRightRoles.translator)"
        effect="light"
        placement="top"
        content="create new translation pack"
        :show-after="600"
      >
        <el-button
          :size="$store.getters['auth/userScaledMediumSize']"
          class="gs-btn-outlined-danger-light gs-loading gs-font-scaled mx-1 pl-1 pr-2"
          :loading="translationPackLoading"
          @click="createTranslationPack"
        >
          <el-icon class="gs-scaled-icon-xs mr-0.5">
            <MessageBox />
          </el-icon> translate
        </el-button>
      </el-tooltip>
    </template>
  </crud-table>
</template>
