<script>
import { defineAsyncComponent, ref, watch } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'
import { buildEffectString, renderEffectString } from '@/components/pages/battle/passive-skills/compositions/EffectConfig'

export default {
  components: {
    // eslint-disable-next-line vue/component-options-name-casing
    hit_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectHitFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    heal_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectHealFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    attribute_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectAttributeFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    resurrection_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectResurrectionFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    stun_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectStunFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    ball_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectBallFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    activeboost_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectActiveBoostFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    chain_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectChainFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    remove_intensity_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectRemoveIntensityFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    shield_revive_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectShieldReviveFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    shield_alter_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectShieldAlterFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    pet_skill_level_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectPetSkillLevelFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    pet_skill_cooldown_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/passive-skills/PassiveSkillsEffectPetSkillCooldownFormFields.vue')
    )
  },
  setup() {
    const addEffectRow = (form, type = 'hit', initData = null) => {
      form.effectRow = { type, component: `${type}_component`, data: { ...initData } }
    }

    const generateDescription = async (form, lang) => {
      app.$axios
        .post('/admin/api/passive-skills/generate-description/', {
          desc: form.Description,
          effects: form.Effect,
          trigger_param: form.TriggerParam,
          lang
        })
        .then(({ data }) => {
          const element = document.getElementById('div-description-preview')
          if (element) {
            element.innerHTML = data.desc
          }
        })
        .catch(app.$utils.catchFormErrors(app.externalErrors))
    }

    const renderInitItem = (form) => {
      form.effectRow = ref({ type: 'hit', component: 'hit_component', data: {} })
      form.TriggerUseBalls = []
      form.IntensityEqualParamsColors = []
      form.IntensityEqualParamsPower = 1
      if (form.Trigger === 'use_balls') {
        for (let bit = 0; bit <= 5; bit++) {
          if (((1 << bit) & form.TriggerParam) > 0) {
            form.TriggerUseBalls.push(bit)
          }
        }
      } else if (form.Trigger === 'intensity_equal') {
        for (let bit = 0; bit <= 2; bit++) {
          if (((1 << bit) & form.TriggerParam) > 0) {
            form.IntensityEqualParamsColors.push(bit)
          }
          form.IntensityEqualParamsPower = form.TriggerParam >> 4
        }
      }
      if (form.Effect) {
        renderEffectString(form.Effect, (effectsRowsData) => {
          addEffectRow(form, effectsRowsData.type, effectsRowsData.initData)
        })
      }
      watch(
        () => form.effectRow,
        () => {
          buildEffectString(form.effectRow, (newEffectString) => {
            form.Effect = newEffectString
          })
          generateDescription(form, 'en')
        },
        { deep: true }
      )

      return form
    }

    const onChangeEffect = (form, effectType) => {
      if (form.effectRow.type === effectType) {
        return false
      }
      addEffectRow(form, effectType, null)
    }

    const onChangeTriggerUseBalls = (form, val, triggerMarkAsChanged) => {
      let triggerParamValue = 0
      val.forEach((bit) => {
        triggerParamValue += 1 << bit
      })
      form.TriggerParam = triggerParamValue
      triggerMarkAsChanged('TriggerParam')
    }

    const onChangeIntensityEqual = (form, triggerMarkAsChanged) => {
      let triggerParamValue = 0
      form.IntensityEqualParamsColors.forEach((bit) => {
        triggerParamValue += 1 << bit
      })
      triggerParamValue += form.IntensityEqualParamsPower << 4
      form.TriggerParam = triggerParamValue
      triggerMarkAsChanged('TriggerParam')
    }

    const onChangeTrigger = (form, val, triggerMarkAsChanged) => {
      if (val === 'use_balls') {
        onChangeTriggerUseBalls(form, form.TriggerUseBalls, triggerMarkAsChanged)
      } else if (val === 'intensity_equal') {
        onChangeIntensityEqual(form, triggerMarkAsChanged)
      }
    }

    return {
      renderInitItem,
      onChangeEffect,
      onChangeTriggerUseBalls,
      onChangeTrigger,
      onChangeIntensityEqual,
      generateDescription
    }
  }
}
</script>

<template>
  <crud-details-page
    api="battle/passive-skill"
    disable-top-margin
    :render-init-item="renderInitItem"
  >
    <template #form="{ form, triggerMarkAsChanged }">
      <!-- Section 1 -->
      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Description -->
        <crud-field-textarea
          api-field-name="Description"
          required
        />
        <!-- BattleEventValueDesc -->
        <crud-field-text
          api-field-name="BattleEventValueDesc"
          label="BE value description"
        />
        <!-- BattleEventAttributeDesc -->
        <crud-field-text
          api-field-name="BattleEventAttributeDesc"
          label="BE attribute description"
        />
        <!-- Filter -->
        <crud-field-select
          api-field-name="Filter"
          label="Used by filter"
          options-enum="enums.ASkillUsedBy"
        />
        <!-- AssetEffect -->
        <crud-field-selector-files
          api-field-name="AssetEffect"
          label="Effect asset"
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Trigger -->
        <crud-field-select
          api-field-name="Trigger"
          options-enum="enums.PassiveSkillTriggerType"
          required
          @change="onChangeTrigger(form, $event, triggerMarkAsChanged)"
        />
        <!-- TriggerParam -->
        <crud-field-number
          v-if="!['hit', 'hit_melee', 'hit_magic', 'heal', 'killed', 'on_battle_begin'].includes(form.Trigger)"
          api-field-name="TriggerParam"
          :readonly="['use_balls', 'intensity_equal'].includes(form.Trigger)"
        />
        <fields-col v-if="form.Trigger === 'use_balls'">
          <el-checkbox-group
            v-model="form.TriggerUseBalls"
            class="w-full px-4"
            @change="onChangeTriggerUseBalls(form, $event, triggerMarkAsChanged)"
          >
            <table class="w-full">
              <tr>
                <td>
                  <el-checkbox :value="0">
                    blue
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="1">
                    white
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="2">
                    red
                  </el-checkbox>
                </td>
              </tr>
              <tr>
                <td>
                  <el-checkbox :value="3">
                    1-ball
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="4">
                    2-ball
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="5">
                    3-ball
                  </el-checkbox>
                </td>
              </tr>
            </table>
          </el-checkbox-group>
        </fields-col>
        <fields-col v-if="form.Trigger === 'intensity_equal'">
          <el-checkbox-group
            v-model="form.IntensityEqualParamsColors"
            class="w-full px-4"
            @change="onChangeIntensityEqual(form, triggerMarkAsChanged)"
          >
            <table class="w-full">
              <tr>
                <td>
                  <el-checkbox :value="0">
                    blue
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="1">
                    white
                  </el-checkbox>
                </td>
                <td>
                  <el-checkbox :value="2">
                    red
                  </el-checkbox>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <crud-field-select
                    api-field-name="IntensityEqualParamsPower"
                    label="Power"
                    :clearable="false"
                    :options="[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 }
                    ]"
                    class-component="w-20"
                    slim
                    @change="onChangeIntensityEqual(form, triggerMarkAsChanged)"
                  />
                </td>
              </tr>
            </table>
          </el-checkbox-group>
        </fields-col>
        <!-- Chance -->
        <crud-field-number api-field-name="Chance" />
        <!-- ExecutedBy -->
        <crud-field-select
          api-field-name="ExecutedBy"
          options-enum="enums.PassiveSkillSubjectType"
          required
        />
        <crud-field-switcher
          v-if="['hit', 'hit_melee', 'hit_magic'].includes(form.Trigger)"
          api-field-name="IsExecutedOnOwner"
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <label
          for="div-description-preview"
          class="el-form-item__label"
        >In-game description preview:</label>
        <div id="div-description-preview" />
        <fields-col justify="start">
          <el-button-group>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'en')"
            >
              Generate
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'fr')"
            >
              FR
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'de')"
            >
              DE
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'it')"
            >
              IT
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'es')"
            >
              ES
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'pt')"
            >
              PT
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'ru')"
            >
              RU
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'zh')"
            >
              ZH
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'cm')"
            >
              CM
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'pl')"
            >
              PL
            </el-button>
          </el-button-group>
        </fields-col>
      </fields-col>

      <!-- Section 2 (Effects) -->

      <fields-col>
        <section-divider>Effects</section-divider>
      </fields-col>
      <!-- Effect form -->
      <fields-col class="effect-rows">
        <!-- Effects string -->
        <crud-field-text
          api-field-name="Effect"
          label="Effects string"
        />
        <transition
          name="el-zoom-in-center"
          leave-to-class="hidden"
        >
          <component
            :is="form.effectRow.component"
            class="transition-box"
            :form="form"
            @change-effect="onChangeEffect(form, $event)"
          />
        </transition>
      </fields-col>
    </template>
  </crud-details-page>
</template>

<style lang="postcss">
.gs-effect-field {
  .el-input__wrapper {
    background-color: rgba(217, 249, 157, 0.38);
  }
}
.effect-rows {
  .col-w-1 {
    padding: 12px;
    width: 180px;
    min-width: 180px;
  }
  .col-w-3 {
    padding: 12px;
    width: calc(180px * 3);
    min-width: calc(180px * 3);
  }
}
#div-description-preview {
  color: #fcf7e2;
  background: #35312b;
  height: 140px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 10px;
}
#div-description-preview img {
  display: inline;
}
</style>
