const Sorting = {
  data() {
    return {
      sorting: {
        order: '',
        prop: ''
      }
    }
  },
  methods: {
    onSort(colApiName) {
      const disableCurrentSorting = this.sorting.prop && this.sorting.prop === colApiName && this.sorting.order === 'descending'
      this.sorting = {
        prop: disableCurrentSorting ? '' : colApiName,
        order: disableCurrentSorting || this.sorting.prop !== colApiName ? 'anscending' : 'descending'
      }
      if (this.apiSorting) {
        this.fetchData()
      } else {
        this.crudUpdateQueryParams()
      }
    }
  }
}

export { Sorting }
