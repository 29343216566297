<script>
import { useStore } from 'vuex'
import { setMeta } from '!/composition/meta'
import { initActions } from '!/composition/layoutActions'
import PageHeader from '!/components/page-layout/header/PageHeader.vue'
import AdminPanelMenu from '!/router/AdminPanelMenu.vue'
import VersionControl from '!/components/page-layout/tools/VersionControl.vue'

export default {
  components: { AdminPanelMenu, PageHeader, VersionControl },
  setup() {
    const store = useStore()
    store.commit('main/pageHeaderSubtitle', '')
    store.commit('main/pageHeaderImgUrl', '')
    setMeta()
    initActions()
  },
  data() {
    return {
      route: '',
      clicks: 0,
      mouseMoves: 0,
      start: Math.floor(Date.now() / 1000),
      intervalRefer: null
    }
  },
  watch: {
    $route(to, from) {
      this.sendUsageStats(from.name, Math.floor(Date.now() / 1000) - this.start)
    }
  },
  mounted() {
    this.start = Math.floor(Date.now() / 1000)
    this.intervalRefer = setInterval(() => {
      this.sendUsageStats(this.$route.name, 10)
    }, 10000)
    document.addEventListener('mouseup', this.onClick)
    document.addEventListener('keydown', this.onClick)
    document.addEventListener('mousemove', (e) => {
      this.onMove(e)
    })
    if (this.$windowWidth < 640) {
      this.$store.state.main.pageMenuCollapse = false
    }
  },
  unmounted() {
    if (this.intervalRefer) {
      clearInterval(this.intervalRefer)
      this.intervalRefer = null
    }
    document.removeEventListener('mouseup', this.onClick)
    document.removeEventListener('keydown', this.onClick)
  },
  methods: {
    sendUsageStats(name, seconds) {
      if (this.mouseMoves === 0 && this.clicks === 0) {
        return
      }
      const post = { route: name, clicks: this.clicks, mouse_moves: this.mouseMoves, seconds }
      this.$axios
        .post('/admin/api/usage/save/', post)
      this.mouseMoves = 0
      this.clicks = 0
      this.start = Math.floor(Date.now() / 1000)
    },
    onClick() {
      this.clicks++
    },
    onMove(e) {
      this.mouseMoves += Math.abs(e.movementX) + Math.abs(e.movementY)
    }
  }
}
</script>

<template>
  <div class="common-layout">
    <el-container>
      <el-aside
        class="min-h-screen border-neutral-300 bg-gradient-to-bl from-neutral-200 to-neutral-100 text-white transition-all"
        :style="{ width: $store.getters['main/pageMenuWidth'] }"
        :class="{ 'border-r': $store.getters['main/pageMenu'] }"
      >
        <AdminPanelMenu />
      </el-aside>
      <el-container
        class="is-vertical"
        style="padding-top: var(--gs-page-top-padding)"
      >
        <PageHeader v-if="$route?.meta?.DisableLayoutPageHeader !== true" />
        <el-main class="p-0">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-progress
      v-show="$store.state.main.pageLoaderLinear"
      class="gs-page-loader-linear fixed top-0 left-0 right-0"
      :percentage="100"
      indeterminate
      :show-text="false"
      :duration="2.5"
      :stroke-width="3"
      color="#67e8f9"
    />
    <VersionControl />
  </div>
</template>

<style lang="postcss">
.el-aside {
  box-shadow: 4px 0 5px -5px #ebebeb;
  min-height: 600px !important;
}
.gs-page-loader-linear {
  .el-progress-bar__outer {
    background-color: transparent;
  }
}
.common-layout {
  > .el-container {
    min-height: 100vh;
  }
}
</style>
