<script setup>
import { ref } from 'vue'
import draggable from 'vuedraggable'
import CrudFieldSelectorOffers from '@/components/forms/crud-fields/CrudFieldSelectorOffers.vue'

const tabsSettings = [
  { label: 'Monday', id: 1 },
  { label: 'Tuesday', id: 2 },
  { label: 'Wednesday', id: 3 },
  { label: 'Thursday', id: 4 },
  { label: 'Friday', id: 5 },
  { label: 'Saturday', id: 6 },
  { label: 'Sunday', id: 7 }
]

const weekdayOrder = ref(Array.from({ length: 7 }).fill(null).map(() => []))

function onLoadedData() {
  // weekdayOrder is initiated after bind loaded data to form
  weekdayOrder.value = Array.from({ length: 7 }).fill(null).map(() => [])
  for (let day = 1; day <= 7; day++) {
    for (let index = 1; index <= 20; index++) {
      weekdayOrder.value[day - 1].push({
        day,
        index
      })
    }
  }
}
function saveItem(form) {
  // save original values
  for (let day = 1; day <= 7; day++) {
    for (let index = 1; index <= 20; index++) {
      const item = weekdayOrder.value[day - 1][index - 1]
      weekdayOrder.value[day - 1][index - 1].value = form[[`Weekday${item.day}${item.index}`]]
    }
  }
  // reorder fields
  for (let day = 1; day <= 7; day++) {
    for (let index = 1; index <= 20; index++) {
      const item = weekdayOrder.value[day - 1][index - 1]
      form[[`Weekday${day}${index}`]] = item.value
    }
  }
  return form
}

function onChangeOrder(day, markAsChanged) {
  markAsChanged(
    'weekdayOrder',
    weekdayOrder.value[day].some((element, index) => {
      return element.index !== index + 1
    })
  )
}
</script>

<template>
  <crud-details-page
    :back-routes="['dashboard']"
    api="payments/weekly-flash"
    :versioned="false"
    disable-top-margin
    :actions="{ crudDuplicate: false, crudDelete: false }"
    :render-saved-item="saveItem"
    re-load-data-after-save
    @loaded-data="onLoadedData"
  >
    <template #form="{ form, markAsChanged }">
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="Monday"
        >
          <el-tab-pane
            v-for="tab in tabsSettings"
            :key="tab.label"
            :label="tab.label"
            :name="tab.label"
            class="sm:pl-10"
          >
            <fields-col :sm="8">
              <draggable
                v-model="weekdayOrder[tab.id - 1]"
                item-key="id"
                ghost-class="gs-ghost"
                class="w-full"
                @change="onChangeOrder(tab.id - 1, markAsChanged)"
              >
                <template #item="{ element }">
                  <el-col>
                    <CrudFieldSelectorOffers
                      :label="false"
                      :api-field-name="`Weekday${element.day}${element.index}`"
                      slim
                      class="my-2 cursor-move py-2"
                      :class="[form?.[`Weekday${element.day}${element.index}`] ? 'bg-yellow-200' : 'bg-neutral-100']"
                      style="padding-left: 8px !important"
                    >
                      <template #fieldAppend>
                        <div class="w-10 text-center">
                          [{{ element.index }}]
                        </div>
                      </template>
                    </CrudFieldSelectorOffers>
                  </el-col>
                </template>
              </draggable>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
      <fields-col />
    </template>
  </crud-details-page>
</template>
