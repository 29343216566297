<script>
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { setMeta } from '!/composition/meta'
import { LoginPageSettings } from '@/settings/defaultMeta.js'
import { globalProperties } from '!/plugins/utilities.js'

export default {
  beforeRouteEnter(to) {
    if (to?.query?.status === 'success' && to?.query?.token) {
      globalProperties.$store.dispatch('auth/login', to.query).then(() => {
        let redirectRoute
        try {
          redirectRoute = JSON.parse(localStorage.getItem('login-redirect'))
        } catch (_e) {
          redirectRoute = null
        }
        localStorage.removeItem('login-redirect')
        if (redirectRoute) {
          globalProperties.$router.push(redirectRoute)
        } else {
          globalProperties.$router.push({ name: 'dashboard' })
        }
      })
    } else {
      return true
    }
  },
  setup() {
    const isGoogleResponseLogged = ref(false)
    const route = useRoute()
    if (route?.query?.status === 'success' && route?.query?.token) {
      isGoogleResponseLogged.value = true
    }
    setMeta()
    return {
      LoginPageSettings,
      isGoogleResponseLogged,
      loading: ref(false)
    }
  },
  computed: {
    loginGoogleUrl() {
      return `${import.meta.env.VITE_API_URL}/oauth/gmail/login/?redirect=${encodeURIComponent(`${window.location.origin}/login/`)}`
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (!(this.$route?.query?.status === 'success' && this.$route?.query?.token)) {
          this.isGoogleResponseLogged = false
        }
        if (val?.status === 'error') {
          this.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors bg-teal-50 text-red-600 right-1/4 top-10',
            duration: 0,
            message: 'Invalid credentials'
          })
        } else if (val?.error === 'access denied') {
          this.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors bg-teal-50 text-red-600 right-1/4 top-10',
            duration: 10000,
            message: 'You are not registered.'
          })
          this.$router.push('/login')
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {}
}
</script>

<template>
  <div
    v-if="!isGoogleResponseLogged"
    class="flex min-h-screen flex-col items-center justify-center"
    :class="LoginPageSettings.bgClass"
  >
    <div class="">
      <el-image
        v-if="typeof LoginPageSettings.Logo === 'string'"
        :src="LoginPageSettings.Logo"
        class="absolute"
        style="width: 130px; height: 130px; transform: translate(25%, -140%)"
        fit="contain"
      />
      <component
        :is="LoginPageSettings.Logo"
        v-else
        style="width: 130px; height: 130px; transform: translate(25%, -25%)"
      />
      <a :href="loginGoogleUrl">
        <el-button
          class="bg-transparent text-xl tracking-widest shadow-lg"
          :class="LoginPageSettings.btnClass"
          style="min-height: 73px; min-width: 192px"
          :loading="loading"
          @click="loading = true"
        >
          <icon-ify
            v-if="!loading"
            :icon="LoginPageSettings.btnIcon ? LoginPageSettings.btnIcon : 'flat-color-icons:google'"
            class="mr-3 h-6 w-6"
          />
          Login
        </el-button>
      </a>
    </div>
    <div class="absolute bottom-3 right-5 text-xs text-white/75">
      © Gamesture 2023. All Rights Reserved.
    </div>
  </div>
</template>

<style></style>
