<script>
export default {}
</script>

<template>
  <crud-table
    :columns-settings="{
      Title: {},
      Start: { header: 'Start UTC' },
      End: { header: 'End UTC' }
    }"
    :default-visible-columns="['Title', 'Start', 'End']"
    api="settings/hipos"
    api-item="settings/hipo"
    :versioned="false"
    disable-multi-duplicates
    disable-multi-select-rows
    :enable-cell-edit-dialogs="false"
    :actions-on-list="{ crudDelete: false }"
    :top-actions="{
      excelExport: true,
      importExcel: true,
      addNew: false
    }"
  />
</template>
