<script>
import CrudFieldSelectorBossRewards from '@/components/forms/crud-fields/CrudFieldSelectorBossRewards.vue'
import CrudFieldSelectorBattleActiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattleActiveSkills.vue'
import CrudFieldSelectorBattlePassiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattlePassiveSkills.vue'

export default {
  components: { CrudFieldSelectorBossRewards, CrudFieldSelectorBattleActiveSkills, CrudFieldSelectorBattlePassiveSkills }
}
</script>

<template>
  <crud-details-page
    api="bosses/boss"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <fields-col :sm="8">
          <!-- Name -->
          <crud-field-text
            api-field-name="Name"
            required
          />
          <!-- Order -->
          <crud-field-number api-field-name="Order" />
          <!-- Scale -->
          <crud-field-number
            api-field-name="Scale"
            :precision="2"
            required
          />
          <!-- ScaleInfoscreen -->
          <crud-field-number
            api-field-name="ScaleInfoscreen"
            :precision="2"
            required
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Graphic -->
          <crud-field-selector-files api-field-name="Graphic" />
          <!-- HomeGraphic -->
          <crud-field-selector-files api-field-name="HomeGraphic" />
          <!-- AvatarGraphic -->
          <crud-field-selector-files api-field-name="AvatarGraphic" />
          <!-- BossTypeGraphic -->
          <crud-field-selector-files api-field-name="BossTypeGraphic" />
          <!-- RankingRewards -->
          <CrudFieldSelectorBossRewards api-field-name="RankingRewards" />
        </fields-col>
        <fields-col :sm="8">
          <!-- Wait -->
          <crud-field-number api-field-name="Wait" />
          <!-- ActiveSkill1 -->
          <CrudFieldSelectorBattleActiveSkills api-field-name="ActiveSkill1" />
          <!-- PassiveSkill1 -->
          <CrudFieldSelectorBattlePassiveSkills api-field-name="PassiveSkill1" />
          <!-- PassiveSkill2 -->
          <CrudFieldSelectorBattlePassiveSkills api-field-name="PassiveSkill2" />
        </fields-col>
      </fields-col>
      <fields-col>
        <fields-col :sm="5">
          <!-- BossLevelIncreaseInterval -->
          <crud-field-number api-field-name="BossLevelIncreaseInterval" />
          <!-- DefenceBase -->
          <crud-field-number api-field-name="DefenceBase" />
          <!-- AttackBase -->
          <crud-field-number api-field-name="AttackBase" />
          <!-- MagicBase -->
          <crud-field-number api-field-name="MagicBase" />
          <!-- AttackResistance -->
          <crud-field-number api-field-name="AttackResistance" />
          <!-- MagicResistance -->
          <crud-field-number api-field-name="MagicResistance" />
        </fields-col>
        <fields-col :sm="16">
          <template
            v-for="i in 8"
            :key="i"
          >
            <!-- TurnsFrom -->
            <crud-field-number
              :sm="6"
              :api-field-name="`TurnsFrom${i}`"
              :label="`TurnsFrom ${i}`"
            />
            <!-- DefenceDelta -->
            <crud-field-number
              :sm="6"
              :api-field-name="`DefenceDelta${i}`"
              :label="`DefenceDelta ${i}`"
            />
            <!-- AttackDelta -->
            <crud-field-number
              :sm="6"
              :api-field-name="`AttackDelta${i}`"
              :label="`AttackDelta ${i}`"
            />
            <!-- MagicDelta -->
            <crud-field-number
              :sm="6"
              :api-field-name="`MagicDelta${i}`"
              :label="`MagicDelta ${i}`"
            />
          </template>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
