<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectShieldReviveFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'shield_revive',
      'target-team': 'enemies',
      'chance': 0,
      'dlvl-chance': 0,
      'value': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="ShieldRevive_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="ShieldRevive_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.chance"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-chance"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.value"
          label="Shield val after revive %"
          :min="0"
          :max="100"
          :precision="2"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_val_after_revive%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-value"
          label="Value inc per level"
          :precision="2"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="ShieldRevive_Value_inc_per_level"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full" />
  </el-scrollbar>
</template>

<style scoped></style>
