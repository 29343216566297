<script>
import { HomeFilled } from '@element-plus/icons-vue'

export default {
  name: 'BreadcrumbComponent',
  components: { HomeFilled },
  props: {
    dashboard: {
      type: Boolean,
      default: true
    },
    backRoutes: {
      type: Array,
      default: () => []
    },
    activeTitle: {
      type: String,
      default: undefined
    },
    toRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listRoutes() {
      return this.backRoutes.map((item) => {
        return this.$utils.getFullRouteData(item)
      })
    },
    isForcedId() {
      return !!this.$route?.meta?.forcedId
    }
  },
  mounted() {}
}
</script>

<template>
  <el-breadcrumb
    v-if="backRoutes.length || isForcedId"
    class="gs-font-scaled mx-4 mt-0.5 flex items-center font-light lowercase antialiased"
    :class="{
      'justify-end': toRight
    }"
  >
    <el-breadcrumb-item
      v-if="dashboard"
      style="line-height: 0"
    >
      <router-link :to="$utils.bindStaticParams({ path: '/' })">
        <span class="inline-block hover:text-cyan-600"><el-icon class="gs-height-related"><HomeFilled /></el-icon></span>
      </router-link>
    </el-breadcrumb-item>
    <el-breadcrumb-item
      v-for="(route, index) in listRoutes"
      :key="index"
    >
      <router-link :to="$utils.bindStaticParams(route)">
        <span class="font-related-xs hover:text-cyan-600">{{ route?.meta?.Title || route?.name }}</span>
      </router-link>
    </el-breadcrumb-item>
    <el-breadcrumb-item>
      <span class="font-related-xs text-green-700">{{
        activeTitle || $route?.meta?.Title || $route?.name
      }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<style scoped></style>
