<template>
  <crud-details-page
    api="quests/npc"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Kind -->
        <crud-field-select
          api-field-name="Kind"
          options-enum="enums.NpcKind"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
