<script>
import { inject, ref } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'CrudFieldVersions',
  mixins: [BasicProps],
  setup(props) {
    const options = ref([])
    const params = {
      columns: 'Version,Forced',
      on_page: 300,
      page: 1,
      sort: 'ID',
      sort_desc: true
    }
    app.$axios
      .get('admin/api/settings/build-versions/', { params })
      .then(({ data }) => {
        if (data?.items?.length) {
          const versions = {}
          const highlighted = {
            forced: {},
            newest: {}
          }
          data.items.forEach((item, index) => {
            if (index === 0) {
              highlighted.newest[item.Version] = { label: `Newest (${item.Version})`, value: item.Version, order: index }
            }
            if (item.Forced) {
              highlighted.forced[item.Version] = { label: `Forced (${item.Version})`, value: item.Version, order: index }
            } else if (highlighted.forced?.[item.Version] === undefined && highlighted.newest?.[item.Version] === undefined) {
              versions[item.Version] = { label: item.Version, value: item.Version, order: index }
            }
          })
          const usedValues = {}
          options.value = [
            ...Object.values(highlighted.newest),
            ...Object.values(highlighted.forced),
            ...Object.values(versions).sort((a, b) => a.order - b.order)
          ].filter((val) => {
            if (usedValues[val.value]) {
              return false
            }
            usedValues[val.value] = true
            return true
          })
        }
      })
      .catch(app.$utils.catchError)

    const initForm = props.form ? {} : inject('initForm', {})
    const fieldNamePath = props.apiFieldName.split('.')
    const handleChange = (val) => {
      if (initForm?.value?.diffs) {
        if ((app.$utils.getByPath(initForm.value.form, fieldNamePath) || null) !== (val || null)) {
          initForm.value.diffs[props.apiFieldName] = true
        } else {
          delete initForm.value.diffs[props.apiFieldName]
        }
      }
    }

    return { options, handleChange }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default="{ size }">
      <slot>
        <el-select-v2
          v-model="fieldModel"
          :options="options"
          :size="size"
          placeholder=" "
          class="gs-field w-full"
          allow-create
          filterable
          clearable
          :reserve-keyword="false"
          @change="handleChange"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
