<script>
import draggable from 'vuedraggable'
import { ref } from 'vue'

export default {
  components: { Draggable: draggable },
  setup() {
    const rewardsItemsOrder = ref([])

    const initItem = (form) => {
      rewardsItemsOrder.value = Array.from({ length: 20 }).fill(null).map((_, row) => {
        return { row: row + 1 }
      })
      return form
    }

    const saveItem = (form) => {
      const orderedData = {}
      rewardsItemsOrder.value.forEach((orderData, order) => {
        orderedData[`Item${order + 1}Item`] = form[`Item${orderData.row}Item`]
        orderedData[`Item${order + 1}Show`] = form[`Item${orderData.row}Show`]
        orderedData[`Item${order + 1}Amount`] = form[`Item${orderData.row}Amount`]
        orderedData[`Item${order + 1}Importance`] = form[`Item${orderData.row}Importance`]
      })
      return { ...form, ...orderedData }
    }

    const onChangeOrder = (markAsChanged) => {
      markAsChanged(
        'rewardsItemsOrder',
        rewardsItemsOrder.value.some((element, index) => {
          return element.row !== index + 1
        })
      )
    }

    const offerIdChange = (val, form) => {
      const basePrices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 50, 60, 70, 100, 150, 175, 200]
      for (let i = 0; i <= basePrices.length; i++) {
        const regex = new RegExp(`(.*)s${basePrices[i]}$`)
        if (val.match(regex)) {
          form.Price = basePrices[i] - 1 + 0.99
          break
        }
      }
    }

    const offerTypeChange = (val, form) => {
      if (val === 'free_offer') {
        form.Price = 0
      }
    }

    return {
      rewardsItemsOrder,
      initItem,
      saveItem,
      onChangeOrder,
      offerIdChange,
      offerTypeChange
    }
  }
}
</script>

<template>
  <crud-details-page
    api="payments/offer"
    disable-top-margin
    enable-relations
    :render-init-item="initItem"
    :render-saved-item="saveItem"
    re-load-data-after-save
  >
    <template #form="{ form, markAsChanged }">
      <fields-col :sm="24">
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="6">
                <!-- OfferId -->
                <crud-field-text
                  api-field-name="OfferId"
                  label="Offer ID"
                  required
                  @change="offerIdChange($event, form)"
                />
                <!-- OfferName -->
                <crud-field-selector-client-strings
                  api-field-name="OfferName"
                  required
                />
                <!-- Label -->
                <crud-field-text api-field-name="Label" />
                <!-- ApType -->
                <crud-field-select
                  api-field-name="ApType"
                  options-enum="enums.AdminPanelTypePayments"
                  label="Admin type"
                />
                <!-- FlashGroup -->
                <crud-field-number
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="FlashGroup"
                />
                <fields-col :sm="12">
                  <!-- IsGamePass -->
                  <crud-field-switcher
                    v-show="form.SaleType === 'flash_sale'"
                    api-field-name="IsGamePass"
                  />
                </fields-col>
                <fields-col :sm="12">
                  <!-- Trash -->
                  <crud-field-switcher api-field-name="Trash" />
                  <!-- Deactivate -->
                  <crud-field-switcher api-field-name="Deactivate" />
                </fields-col>
              </fields-col>
              <fields-col :sm="6">
                <!-- AvailableFrom -->
                <crud-field-date-time
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="AvailableFrom"
                />
                <!-- AvailableTo -->
                <crud-field-date-time
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="AvailableTo"
                />
                <!-- ResetAfterEnd -->
                <crud-field-switcher
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="ResetAfterEnd"
                />
                <!-- DurationHours -->
                <crud-field-number
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="DurationHours"
                  :precision="2"
                />
                <!-- ConvertAvailabletoToDuration -->
                <crud-field-switcher
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="ConvertAvailabletoToDuration"
                />
                <!-- WeeklyFlashHour -->
                <crud-field-number
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="WeeklyFlashHour"
                />
                <!-- IsWeeklyFlash -->
                <crud-field-switcher
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="IsWeeklyFlash"
                />
              </fields-col>
              <fields-col :sm="6">
                <!-- SaleType -->
                <crud-field-select
                  api-field-name="SaleType"
                  options-enum="enums.SaleType"
                  required
                />
                <!-- OfferType -->
                <crud-field-select
                  api-field-name="OfferType"
                  options-enum="enums.OfferType"
                  required
                  @change="offerTypeChange($event, form)"
                />
                <!-- BuyLimit -->
                <crud-field-number api-field-name="BuyLimit" />
                <!-- BuyLimitDailyReset -->
                <crud-field-switcher api-field-name="BuyLimitDailyReset" />
                <!-- ChainNo -->
                <crud-field-number
                  api-field-name="ChainNo"
                  label="Chain no (null if not used)"
                />
                <!-- ChainOrder -->
                <crud-field-number
                  api-field-name="ChainOrder"
                  label="Chain order (null if not used)"
                />
                <!-- ResourceItem -->
                <crud-field-selector-items
                  v-show="form.ProductType === 'free_offer'"
                  api-field-name="ResourceItem"
                />
                <!-- ResourceCost -->
                <crud-field-number
                  v-show="form.ProductType === 'free_offer'"
                  api-field-name="ResourceCost"
                  :precision="2"
                />
              </fields-col>
              <fields-col :sm="6">
                <!-- ContextualOfferType -->
                <crud-field-select
                  api-field-name="ContextualOfferType"
                  options-enum="enums.ContextualOfferType"
                />
                <el-col
                  :span="24"
                  class="bg-amber-100"
                >
                  <el-row>
                    <crud-field-item-tags api-field-name="ItemTags" />
                  </el-row>
                  <el-row>
                    <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
                  </el-row>
                </el-col>
              </fields-col>
            </fields-col>
          </el-tab-pane>

          <el-tab-pane
            label="Gamesture Store"
            name="gamesture-store"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- StoreIsAvailable -->
                <crud-field-switcher api-field-name="StoreIsAvailable" />
                <!-- StoreName -->
                <crud-field-textarea
                  api-field-name="StoreName"
                  :textarea-rows="2"
                />
                <!-- StoreDescription -->
                <crud-field-textarea
                  api-field-name="StoreDescription"
                  :textarea-rows="4"
                />
                <!-- StoreAssetBackground -->
                <crud-field-selector-files
                  api-field-name="StoreAssetBackground"
                  label="Store asset"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      ratio 1 / 1.6 (min size: 500px x 310px)
                    </div>
                  </template>
                </crud-field-selector-files>
                <crud-field-number
                  api-field-name="StorePriceInGCoins"
                  label="Price In GCoins"
                />
                <!-- StoreAllowQuantityPurchase -->
                <crud-field-switcher api-field-name="StoreAllowQuantityPurchase" />
              </fields-col>
              <fields-col :sm="8">
                <!-- StoreIsBestDeal -->
                <crud-field-switcher
                  api-field-name="StoreIsBestDeal"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      !!! Must be selected in 2 offers !!!
                    </div>
                  </template>
                </crud-field-switcher>
                <!-- StoreIsTopOffer -->
                <crud-field-switcher
                  api-field-name="StoreIsTopOffer"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      !!! Must be selected in 4 offers !!!
                    </div>
                  </template>
                </crud-field-switcher>
                <!-- StoreBonusGems -->
                <crud-field-number api-field-name="StoreBonusGems" />
                <!-- StoreBonusEnergy -->
                <crud-field-number api-field-name="StoreBonusEnergy" />
                <!-- StoreDiscountPercent -->
                <crud-field-number api-field-name="StoreDiscountPercent" />
                <!-- StoreAssetDiscount -->
                <crud-field-selector-files
                  api-field-name="StoreAssetDiscount"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      ratio 47 / 6 (min size: 470 x 60px)
                    </div>
                  </template>
                </crud-field-selector-files>
              </fields-col>
              <fields-col :sm="8">
                <crud-field-switcher api-field-name="StoreIsOnlyForStore" />
                <!-- StoreIsFeatureBanner -->
                <crud-field-switcher api-field-name="StoreIsFeatureBanner" />
                <!-- StoreFeatureBannerAsset -->
                <crud-field-selector-files
                  api-field-name="StoreFeatureBannerAsset"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      ratio 175 / 67  (min size: 1400px x 536px)
                    </div>
                  </template>
                </crud-field-selector-files>
                <!-- StoreFeatureMobileBannerAsset -->
                <crud-field-selector-files
                  api-field-name="StoreFeatureMobileBannerAsset"
                  vertical
                >
                  <template #fieldAppend>
                    <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
                      ratio 250 / 281 (min size: 500px x 562px)
                    </div>
                  </template>
                </crud-field-selector-files>
                <!-- StoreFeatureBannerDescription -->
                <crud-field-textarea
                  api-field-name="StoreFeatureBannerDescription"
                  :textarea-rows="2"
                />
                <crud-field-number api-field-name="StoreDisplayPriority" />
              </fields-col>
              <!-- End of Gamesture storeapi -->
            </fields-col>
          </el-tab-pane>

          <el-tab-pane
            label="Rewards"
            name="rewards"
            lazy
          >
            <fields-col>
              <section-divider>Special settings</section-divider>
            </fields-col>
            <fields-col>
              <fields-col :sm="10">
                <crud-field-number
                  :sm="12"
                  api-field-name="Premium"
                  :min="0"
                />
                <crud-field-select
                  :sm="12"
                  api-field-name="PremiumImportance"
                  options-enum="enums.RewardImportance"
                />
                <crud-field-number
                  :sm="12"
                  api-field-name="Gold"
                  :min="0"
                />
                <crud-field-select
                  :sm="12"
                  api-field-name="GoldImportance"
                  options-enum="enums.RewardImportance"
                />
                <crud-field-number
                  :sm="12"
                  api-field-name="Energy"
                  :min="0"
                />
                <crud-field-select
                  :sm="12"
                  api-field-name="EnergyImportance"
                  options-enum="enums.RewardImportance"
                />
              </fields-col>
              <fields-col :sm="5">
                <!-- AffectsVipByPremium -->
                <crud-field-number
                  api-field-name="AffectsVipByPremium"
                  label="Affects Vip Points by"
                  :min="0"
                />
                <!-- AffectsTopupByPremium -->
                <crud-field-number
                  api-field-name="AffectsTopupByPremium"
                  label="Affects Topup Points by"
                  :min="0"
                />
                <!-- AffectsGuildExpBy -->
                <crud-field-number
                  api-field-name="AffectsGuildExpBy"
                  label="Affects Guild Exp by"
                  :min="0"
                />
                <!-- AffectsGuildFameBy -->
                <crud-field-number
                  api-field-name="AffectsGuildFameBy"
                  label="Affects Guild Fame by"
                  :min="0"
                />
              </fields-col>
            </fields-col>
            <fields-col>
              <section-divider />
            </fields-col>
            <fields-col>
              <fields-table>
                <template #tHeader>
                  <tr>
                    <th>Nr</th>
                    <th>Item</th>
                    <th>Show</th>
                    <th>Amount</th>
                    <th>Importance</th>
                  </tr>
                </template>
                <template #tBody>
                  <Draggable
                    v-model="rewardsItemsOrder"
                    item-key="row"
                    ghost-class="gs-ghost-tr"
                    tag="tbody"
                    handle=".handle"
                    @change="onChangeOrder(markAsChanged)"
                  >
                    <template #item="props">
                      <tr class="bg-neutral-50">
                        <fields-table-td
                          :width="55"
                          class="relative text-center align-middle"
                          cell-class=""
                        >
                          <div class="absolute -left-1 top-0 bottom-0 flex items-center">
                            <icon-ify
                              icon="clarity:drag-handle-line"
                              class="gs-scaled-icon-xl handle cursor-move"
                            />
                          </div>
                          <div>{{ props.index + 1 }}</div>
                        </fields-table-td>
                        <fields-table-td :width="450">
                          <crud-field-selector-items
                            :api-field-name="`Item${props.element.row}Item`"
                            slim
                            :label="false"
                            :min-height="false"
                            disable-doc
                          />
                        </fields-table-td>
                        <fields-table-td :width="70">
                          <crud-field-switcher
                            v-if="
                              [1837, 1835, 1987, 1839, 1841, 3919, 3917, 1061].includes(
                                form?.[`Item${props.element.row}Item`]?.ID
                              )
                            "
                            :api-field-name="`Item${props.element.row}Show`"
                            slim
                            :label="false"
                            :min-height="false"
                            class-component="ml-0"
                            disable-doc
                          />
                        </fields-table-td>
                        <fields-table-td :width="180">
                          <crud-field-number
                            :api-field-name="`Item${props.element.row}Amount`"
                            slim
                            :label="false"
                            :min-height="false"
                            disable-doc
                          />
                        </fields-table-td>
                        <fields-table-td :width="180">
                          <crud-field-select
                            :api-field-name="`Item${props.element.row}Importance`"
                            options-enum="enums.RewardImportance"
                            slim
                            :label="false"
                            :min-height="false"
                            disable-doc
                          />
                        </fields-table-td>
                      </tr>
                    </template>
                  </Draggable>
                </template>
              </fields-table>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Display"
            name="display"
            lazy
          >
            <fields-col>
              <fields-col :sm="6">
                <!-- Name1 -->
                <crud-field-textarea
                  api-field-name="Name1"
                  :textarea-rows="5"
                />
                <!-- Name2 -->
                <crud-field-textarea
                  api-field-name="Name2"
                  :textarea-rows="5"
                />
                <fields-col :sm="12">
                  <!-- OnePerTime -->
                  <crud-field-switcher api-field-name="OnePerTime" />
                </fields-col>
              </fields-col>
              <fields-col :sm="6">
                <!-- FlashIcon -->
                <crud-field-selector-files api-field-name="FlashIcon" />
                <!-- Image1 -->
                <crud-field-selector-files
                  api-field-name="Image1"
                  label="Image"
                />
                <!-- RibbonGraphic -->
                <crud-field-selector-files
                  api-field-name="RibbonGraphic"
                  label="New image"
                />
                <!-- PercentDiscount -->
                <crud-field-number api-field-name="PercentDiscount" />
              </fields-col>
              <fields-col
                :sm="6"
                class="pl-10"
              >
                <!-- ForcedPopup -->
                <crud-field-checkbox
                  api-field-name="ForcedPopup"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- ForcedPopup -->
                <crud-field-checkbox
                  api-field-name="SoIsPriceAboveButton"
                  label="Price above button"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- RedBadge -->
                <crud-field-checkbox
                  api-field-name="RedBadge"
                  label="Is red badge"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- IsVisualOffer -->
                <crud-field-checkbox
                  api-field-name="IsVisualOffer"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- IsRotator -->
                <crud-field-checkbox
                  api-field-name="IsRotator"
                  label="Is flash rotator"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- IsMoveToOsList -->
                <crud-field-checkbox
                  api-field-name="IsMoveToOsList"
                  label="Move to OS list"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- IsPremiumShopItem -->
                <crud-field-checkbox
                  api-field-name="IsPremiumShopItem"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- CalendarOffer -->
                <crud-field-checkbox
                  api-field-name="CalendarOffer"
                  label="Event calendar"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowOnList -->
                <crud-field-checkbox
                  v-show="form.SaleType === 'flash_sale'"
                  api-field-name="FlashShowOnList"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
              </fields-col>
              <fields-col
                :sm="6"
                class="pl-10"
              >
                <!-- FlashShowHome -->
                <crud-field-checkbox
                  api-field-name="FlashShowHome"
                  label="Show on home"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowForge -->
                <crud-field-checkbox
                  api-field-name="FlashShowForge"
                  label="Show on forge"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowCity -->
                <crud-field-checkbox
                  api-field-name="FlashShowCity"
                  label="Show on city"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowMarketplace -->
                <crud-field-checkbox
                  api-field-name="FlashShowMarketplace"
                  label="Show on marketplace"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowBattlecampaign -->
                <crud-field-checkbox
                  api-field-name="FlashShowBattlecampaign"
                  label="Show on battlecampaign"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowTavern -->
                <crud-field-checkbox
                  api-field-name="FlashShowTavern"
                  label="Show on tavern"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashShowGuildhome -->
                <crud-field-checkbox
                  api-field-name="FlashShowGuildhome"
                  label="Show on guildhome"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- ShowQuestEvent -->
                <crud-field-checkbox
                  api-field-name="ShowQuestEvent"
                  label="Show on Quest Event"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- ShowBattleEvent -->
                <crud-field-checkbox
                  api-field-name="ShowBattleEvent"
                  label="Show on Battle Event"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
              </fields-col>
              <fields-col>
                <section-divider>Display prio</section-divider>
              </fields-col>
              <fields-col :sm="12">
                <!-- PriorityBelow2 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow2"
                  label="Priority Below Base 2"
                />
                <!-- PriorityAbove2 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove2"
                  label="Priority Above Base 2"
                />
                <!-- PriorityBelow5 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow5"
                  label="Priority Below Base 5"
                />
                <!-- PriorityAbove5 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove5"
                  label="Priority Above Base 5"
                />
                <!-- PriorityBelow10 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow10"
                  label="Priority Below Base 10"
                />
                <!-- PriorityAbove10 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove10"
                  label="Priority Above Base 10"
                />
                <!-- PriorityBelow25 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow25"
                  label="Priority Below Base 25"
                />
                <!-- PriorityAbove25 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove25"
                  label="Priority Above Base 25"
                />
                <!-- PriorityBelow50 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow50"
                  label="Priority Below Base 50"
                />
                <!-- PriorityAbove50 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove50"
                  label="Priority Above Base 50"
                />
                <!-- PriorityBelow100 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow100"
                  label="Priority Below Base 100"
                />
                <!-- PriorityAbove100 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove100"
                  label="Priority Above Base 100"
                />
                <!-- PriorityBelow101 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityBelow101"
                  label="Priority Below Base 101"
                />
                <!-- PriorityAbove101 -->
                <crud-field-number
                  :sm="12"
                  api-field-name="PriorityAbove101"
                  label="Priority Above Base 101"
                />
              </fields-col>
              <!-- PriorityVipLevel -->
              <crud-field-number
                :sm="6"
                api-field-name="PriorityVipLevel"
                label="Priority VIP Level"
              />
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Requirements"
            name="requirements"
            lazy
          >
            <fields-col>
              <fields-col :sm="12">
                <!-- ReqLevelMin -->
                <crud-field-number
                  :sm="12"
                  api-field-name="ReqLevelMin"
                />
                <!-- ReqLevelMax -->
                <crud-field-number
                  :sm="12"
                  api-field-name="ReqLevelMax"
                />
                <!-- ReqDayofplayMin -->
                <crud-field-number
                  :sm="12"
                  api-field-name="ReqDayofplayMin"
                />
                <!-- ReqDayofplayMax -->
                <crud-field-number
                  :sm="12"
                  api-field-name="ReqDayofplayMax"
                />
                <!-- FlashHeroCreatedBefore -->
                <crud-field-date-time
                  :sm="12"
                  api-field-name="FlashHeroCreatedBefore"
                />
                <!-- FlashHeroCreatedAfter -->
                <crud-field-date-time
                  :sm="12"
                  api-field-name="FlashHeroCreatedAfter"
                />
              </fields-col>
              <fields-col
                :sm="6"
                class="pl-10"
              >
                <!-- FlashCheckNonPayers -->
                <crud-field-checkbox
                  api-field-name="FlashCheckNonPayers"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <!-- FlashCheckPayers -->
                <crud-field-checkbox
                  api-field-name="FlashCheckPayers"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <crud-field-checkbox
                  v-for="i in [0, 2, 5, 10, 25, 50, 100]"
                  :key="i"
                  :api-field-name="`FlashCheck${i}`"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
              </fields-col>
              <fields-col
                :sm="6"
                class="pl-10"
              >
                <!-- FlashSoRenewCycleTimeAfterBuy -->
                <crud-field-checkbox
                  api-field-name="FlashSoRenewCycleTimeAfterBuy"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
                <crud-field-checkbox
                  v-for="i in [0, 2, 5, 10, 25, 50, 100]"
                  :key="i"
                  :api-field-name="`FlashSoIncBaseTo${i}AfterBuy`"
                  :label="`Flash so inc base to ${i} after buy`"
                  slim
                  doc-icon-class="-top-0 right-20"
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Version 2.0"
            name="ver_2"
            lazy
          >
            <fields-col>
              <!-- Name3 -->
              <crud-field-textarea
                :sm="8"
                api-field-name="Name3"
                label="Name"
                :textarea-rows="5"
              />
              <fields-col :sm="8">
                <!-- IsPriceAboveButton -->
                <crud-field-switcher api-field-name="IsPriceAboveButton" />
                <!-- SoOverButtonValue -->
                <crud-field-text
                  api-field-name="SoOverButtonValue"
                  label="Over button text"
                />
                <!-- SoCustomButtonText -->
                <crud-field-text
                  api-field-name="SoCustomButtonText"
                  label="Custom button text"
                />
                <!-- SoBackgroundColor -->
                <crud-field-text
                  api-field-name="SoBackgroundColor"
                  label="Background color"
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- SoPromoBarColor -->
                <crud-field-text
                  api-field-name="SoPromoBarColor"
                  label="Promo bar color"
                />
                <!-- SoIconsBundleGraphic -->
                <crud-field-selector-files
                  api-field-name="SoIconsBundleGraphic"
                  label="Icons layout bundle"
                />
                <!-- SoRibbonGraphic -->
                <crud-field-selector-files
                  api-field-name="SoRibbonGraphic"
                  label="Ribbon graphic"
                />
                <!-- SoRibbonText -->
                <crud-field-text
                  api-field-name="SoRibbonText"
                  label="Ribbon text"
                />
                <!-- SoCustomButtonGraphic -->
                <crud-field-selector-files
                  api-field-name="SoCustomButtonGraphic"
                  label="Custom button graphic"
                />
              </fields-col>
              <fields-col :sm="16">
                <crud-field-slot
                  :sm="12"
                  label="Premium"
                >
                  <el-input
                    model-value="0"
                    disabled
                  />
                </crud-field-slot>
                <!-- SoPremiumImportance -->
                <crud-field-select
                  :sm="12"
                  api-field-name="SoPremiumImportance"
                  label="Premium importance"
                  options-enum="enums.RewardImportance"
                />
                <crud-field-slot
                  :sm="12"
                  label="Gold"
                >
                  <el-input
                    model-value="0"
                    disabled
                  />
                </crud-field-slot>
                <!-- SoGoldImportance -->
                <crud-field-select
                  :sm="12"
                  api-field-name="SoGoldImportance"
                  label="Gold importance"
                  options-enum="enums.RewardImportance"
                />
                <crud-field-slot
                  :sm="12"
                  label="Energy"
                >
                  <el-input
                    model-value="0"
                    disabled
                  />
                </crud-field-slot>
                <!-- SoEnergyImportance -->
                <crud-field-select
                  :sm="12"
                  api-field-name="SoEnergyImportance"
                  label="Energy importance"
                  options-enum="enums.RewardImportance"
                />
              </fields-col>
              <fields-col>
                <fields-table>
                  <template #tHeader>
                    <tr>
                      <th>Nr</th>
                      <th>Item</th>
                      <th>Amount</th>
                      <th>Importance</th>
                    </tr>
                  </template>
                  <template #tBody>
                    <tr
                      v-for="row in 20"
                      :key="row"
                    >
                      <fields-table-td
                        :width="55"
                        class="text-center"
                      >
                        {{ row }}
                      </fields-table-td>
                      <fields-table-td :width="250">
                        {{ form[`Item${row}Item`]?.AdminLabel }}
                      </fields-table-td>
                      <fields-table-td :width="200">
                        {{ form[`Item${row}Amount`] ? form[`Item${row}Amount`] : '' }}
                      </fields-table-td>
                      <fields-table-td :width="200">
                        <crud-field-select
                          :api-field-name="`SoItem${row}Importance`"
                          options-enum="enums.RewardImportance"
                          slim
                          :label="false"
                          :min-height="false"
                        />
                      </fields-table-td>
                    </tr>
                  </template>
                </fields-table>
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Hidden"
            name="hidden"
            lazy
          >
            <fields-col>
              <fields-col :sm="6">
                <!-- ProductSubid -->
                <crud-field-number api-field-name="ProductSubid" />
                <!-- Price -->
                <crud-field-number
                  api-field-name="Price"
                  :precision="2"
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
