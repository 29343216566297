<template>
  <crud-details-page
    api="events/runic-event"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-selector-client-strings api-field-name="Name" />
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Start -->
        <crud-field-date-time
          api-field-name="Start"
          required
        />
        <!-- End -->
        <crud-field-date-time
          api-field-name="End"
          required
        />
        <!-- BoostTimeStart -->
        <crud-field-date-time
          api-field-name="BoostTimeStart"
          required
        />
        <!-- BattleGroupStart -->
        <crud-field-date-time
          v-for="i in 5"
          :key="i"
          :api-field-name="`BattleGroup${i}Start`"
          required
        />
        <!-- SeasonNo -->
        <crud-field-number
          api-field-name="SeasonNo"
          required
        />
        <!-- BackgroundAssetGraphic -->
        <crud-field-selector-files api-field-name="BackgroundAssetGraphic" />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- TurnTimeLimit -->
        <crud-field-number
          api-field-name="TurnTimeLimit"
          label="TurnTimeLimit [s]"
        />
        <!-- BattleTurnLimit -->
        <crud-field-number api-field-name="BattleTurnLimit" />
        <!-- FightsCount -->
        <crud-field-number api-field-name="FightsCount" />
        <!-- FightsInterval -->
        <crud-field-number
          api-field-name="FightsInterval"
          label="FightsInterval [s]"
        />
        <!-- HealthMultiplier -->
        <crud-field-number api-field-name="HealthMultiplier" />
      </fields-col>
      <fields-col :sm="8">
        <template
          v-for="i in 25"
          :key="i"
        >
          <crud-field-number
            :api-field-name="`Fight${i}Points`"
            :label="`Fight ${i} points`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
