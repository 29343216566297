<template>
  <crud-details-page
    api="tools/annotation"
    disable-top-margin
    :versioned="false"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Date -->
        <crud-field-date
          api-field-name="Date"
          required
        />
        <!-- Group -->
        <crud-field-select
          api-field-name="Group"
          options-enum="enums.AnnotationGroup"
          required
        />
      </fields-col>
      <fields-col
        :sm="16"
      >
        <!-- Change -->
        <crud-field-textarea
          api-field-name="Change"
          :textarea-rows="10"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
