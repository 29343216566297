<template>
  <crud-details-page
    api="settings/promo-code"
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Code -->
        <crud-field-text
          api-field-name="Code"
          required
        />
        <!-- MaxNumberOfUse -->
        <crud-field-number
          api-field-name="MaxNumberOfUse"
          label="MaxNumberOfUse - nie działa, bez limitu będzie, wpisz 0"
        />
        <!-- ExpireDate -->
        <crud-field-date-time
          api-field-name="ExpireDate"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- RewardGold -->
        <crud-field-number
          api-field-name="RewardGold"
          label="Gold"
        />
        <!-- RewardPremium -->
        <crud-field-number
          api-field-name="RewardPremium"
          label="Gems"
        />
      </fields-col>
      <fields-col :sm="12">
        <!-- items table -->
        <fields-table class="w-full">
          <template #tHeader>
            <tr>
              <th>Nr</th>
              <th>Item</th>
              <th>Amount</th>
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="row in 4"
              :key="row"
            >
              <fields-table-td
                :width="50"
                class="text-center"
              >
                {{ row }}
              </fields-table-td>
              <fields-table-td :min-width="300">
                <crud-field-selector-items
                  :api-field-name="`RewardItem${row}`"
                  :label="false"
                  slim
                />
              </fields-table-td>
              <fields-table-td :width="120">
                <crud-field-number
                  :api-field-name="`RewardItem${row}Amount`"
                  class="mr-3"
                  :label="false"
                  slim
                />
              </fields-table-td>
            </tr>
          </template>
        </fields-table>
      </fields-col>
    </template>
  </crud-details-page>
</template>
