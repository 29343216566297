<script>
import { defineAsyncComponent } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import {
  buildEffectString,
  methods,
  renderEffectString
} from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  components: {
    // eslint-disable-next-line vue/component-options-name-casing
    hit_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectHitFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    heal_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectHealFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    attribute_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectAttributeFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    resurrection_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectResurrectionFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    stun_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectStunFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    ball_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectBallFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    activeboost_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectActiveBoostFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    chain_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectChainFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    remove_intensity_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectRemoveIntensityFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    shield_revive_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectShieldReviveFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    shield_alter_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/ActiveSkillsEffectShieldAlterFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    conditionshield_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/EffectConditionShieldFormFields.vue')
    ),
    // eslint-disable-next-line vue/component-options-name-casing
    conditionintensity_component: defineAsyncComponent(() =>
      import('@/components/pages/battle/active-skills/EffectConditionIntensityFormFields.vue')
    )
  },
  // eslint-enable
  setup() {
    const { isConditionType, getAssetsRows } = methods
    return {
      icons: {
        Delete
      },
      buildEffectString,
      renderEffectString,
      isConditionType,
      getAssetsRows
    }
  },
  data() {
    return {
      isCondition: false
    }
  },
  methods: {
    renderInitItem(form) {
      form.effectRows = []
      form.assetsRows = []
      if (!form?.Effects) {
        if (form?.ID) {
          this.addEffectRows(form)
        }
      } else {
        this.renderEffectString(form.Effects, (effectsRowsData) => {
          effectsRowsData.forEach((rowData) => {
            this.addEffectRows(form, rowData.type, rowData.initData)
          })
          this.generateDescription(form, 'en')
        })
      }
      form.assetsRows = this.getAssetsRows(form.effectRows)
      this.$watch(
        () => form.effectRows,
        () => {
          this.buildEffectString(form.effectRows, (newEffectString) => {
            form.Effects = newEffectString
          })
          form.assetsRows = this.getAssetsRows(form.effectRows)
        },
        { deep: true }
      )
      return form
    },
    addEffectRows(form, type = 'hit', initData = null, rowIndex = null) {
      const row = {
        type,
        component: `${type}_component`,
        data: { ...initData },
        isConditionType: this.isConditionType(type)
      }
      if (row.isConditionType) {
        this.isCondition = true
      }
      if (rowIndex === null) {
        form.effectRows.push(row)
      } else {
        form.effectRows.splice(rowIndex, 1, row)
      }
    },
    deleteRowEffect(form, rowIndex) {
      if (form.effectRows[rowIndex].isConditionType) {
        this.isCondition = false
      }
      form.effectRows.splice(rowIndex, 1)
    },
    onChangeEffect(form, rowIndex, effectType) {
      if (form.effectRows[rowIndex].type === effectType) {
        return false
      }
      this.addEffectRows(form, effectType, null, rowIndex)
    },
    async generateDescription(form, lang) {
      this.$axios
        .post('/admin/api/active-skills/generate-description/', {
          desc: form.Description,
          desc2: form.Description2,
          effects: form.Effects,
          lang
        })
        .then(({ data }) => {
          const element = document.getElementById('div-description-preview')
          if (element) {
            element.innerHTML = data.desc
          }
        })
        .catch(this.$utils.catchFormErrors(this.externalErrors))
    }
  }
}
</script>

<template>
  <crud-details-page
    api="battle/active-skill"
    disable-top-margin
    :render-init-item="renderInitItem"
  >
    <template #form="{ form }">
      <!-- Section 1 -->

      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-selector-client-strings api-field-name="Name" />
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          :rules="[$v.maxLength(255)]"
        />
        <!-- EnhanceColor -->
        <crud-field-select
          :sm="16"
          api-field-name="EnhanceColor"
          label="In-battle enhance color"
          options-enum="enums.SpiritColor"
        />
        <!-- EnhanceValue -->
        <crud-field-number
          :sm="8"
          api-field-name="EnhanceValue"
          label="In-battle enhance value"
        />
        <!-- UsedBy -->
        <crud-field-select
          :sm="16"
          api-field-name="UsedBy"
          label="Used by filter"
          options-enum="enums.ASkillUsedBy"
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Description -->
        <crud-field-textarea api-field-name="Description" />
        <!-- Description2 -->
        <crud-field-textarea
          api-field-name="Description2"
          label="Description for conditional effect version"
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <label
          for="div-description-preview"
          class="el-form-item__label"
        >In-game description preview:</label>
        <div id="div-description-preview" />
        <fields-col justify="start">
          <el-button-group>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'en')"
            >
              Generate
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'fr')"
            >
              FR
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'de')"
            >
              DE
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'it')"
            >
              IT
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'es')"
            >
              ES
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'pt')"
            >
              PT
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'zh')"
            >
              ZH
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'ru')"
            >
              RU
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'cm')"
            >
              CM
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateDescription(form, 'pl')"
            >
              PL
            </el-button>
          </el-button-group>
        </fields-col>
      </fields-col>

      <!-- Section 2 (Assets) -->

      <fields-col
        :sm="8"
        class="sm:mt-8"
      >
        <!-- AssetEffectIcon's -->
        <crud-field-selector-files
          v-for="i in 3"
          :key="i"
          :api-field-name="`AssetEffectIcon${i}`"
        />
      </fields-col>
      <fields-col
        :sm="16"
        class="sm:mt-8"
      >
        <template
          v-for="(assetsRow, index) in form.assetsRows"
          :key="index"
        >
          <crud-field-selector-files
            :sm="12"
            :api-field-name="`AssetEffect${assetsRow.effectIndex + 1}`"
            :label="`Asset for ${assetsRow.type}`"
          />
          <crud-field-selector-files
            v-if="assetsRow.type === 'hit'"
            :sm="12"
            :api-field-name="`AssetEffect${assetsRow.effectIndex + 1}V2`"
            :label="`Asset for ${assetsRow.type}`"
          />
          <el-col
            v-else
            :sm="8"
          />
        </template>
      </fields-col>

      <!-- Section 3 (Effects) -->
      <fields-col>
        <section-divider>Effects</section-divider>
      </fields-col>

      <fields-col>
        <!-- Effects string -->
        <crud-field-text
          api-field-name="Effects"
          label="Effects string"
        />
      </fields-col>
      <!-- Effects rows (forms) -->
      <fields-col
        v-for="(effectRow, rowIndex) in form.effectRows"
        :key="rowIndex"
        class="effect-rows mb-3 pb-1.5 pt-6 shadow"
        :class="{
          'hover:bg-neutral-50': !effectRow.isConditionType,
          'bg-blue-200/50 hover:bg-blue-200/75': effectRow.isConditionType
        }"
      >
        <transition
          name="el-zoom-in-center"
          leave-to-class="hidden"
        >
          <component
            :is="effectRow.component"
            class="transition-box"
            :row-index="rowIndex"
            :form="form"
            @change-effect="onChangeEffect(form, rowIndex, $event)"
          />
        </transition>
        <el-col
          class="gs-font-scaled text-right"
        >
          <el-popconfirm
            title="Are you sure to delete this?"
            :width="240"
            @confirm="deleteRowEffect(form, rowIndex)"
          >
            <template #reference>
              <span>
                <el-tooltip
                  content="Delete effect"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <el-button
                    :icon="icons.Delete"
                    class="gs-btn-danger-hover-outlined gs-height-related-xl leading-none"
                  />
                </el-tooltip>
              </span>
            </template>
          </el-popconfirm>
        </el-col>
      </fields-col>
      <fields-col class="gs-font-scaled mb-10 mt-3.5">
        <el-col>
          <el-button
            type="primary"
            plain
            class="gs-height-related-sm"
            @click="addEffectRows(form)"
          >
            Add new effect
          </el-button>
          <el-button
            v-if="!!form.effectRows.length && !isCondition"
            type="primary"
            class="gs-height-related-sm"
            plain
            color="#626aef"
            @click="addEffectRows(form, 'conditionshield')"
          >
            Add condition
          </el-button>
        </el-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>

<style lang="postcss">
.gs-effect-field {
  .el-input__wrapper {
    background-color: rgba(217, 249, 157, 0.38);
  }
}
.effect-rows {
  .col-w-1 {
    padding: 12px;
    width: 180px;
    min-width: 180px;
  }
  .col-w-3 {
    padding: 12px;
    width: calc(180px * 3);
    min-width: calc(180px * 3);
  }
}
#div-description-preview {
  color: #fcf7e2;
  background: #35312b;
  height: 140px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 10px;
}
#div-description-preview img {
  display: inline;
}
</style>
