export default () => {
  return {
    namespaced: true,
    state() {
      return {
        crudStorage: {}
      }
    },
    mutations: {
      updateCrudStorage(state, [crudHash, value]) {
        // $store.commit('session/updateCrudStorage', [crudHash, value])
        if (!state.crudStorage[crudHash]) {
          state.crudStorage[crudHash] = {}
        }
        state.crudStorage[crudHash] = { ...state.crudStorage[crudHash], ...value }
      },
      removeCrudStorage(state, crudHash) {
        // $store.commit('session/removeCrudStorage', crudHash)
        delete state.crudStorage[crudHash]
      }
    },
    getters: {
      crudStorage: (state) => {
        // $store.getters['session/crudStorage'];
        return state.crudStorage
      }
    }
  }
}
