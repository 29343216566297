<script>
import { computed, ref, watch } from 'vue'
import { ArrowDown, Bottom, Check, Top } from '@element-plus/icons-vue'
import { mapGetters } from 'vuex'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'EnvFiltersTags',
  components: { ArrowDown, FieldsLabel, Check },
  props: {
    searchFields: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: undefined,
      validator: (propValue) => {
        return ['', 'small', 'large'].includes(propValue)
      }
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['update:search-fields'],
  setup(props, context) {
    const open = ref(false)
    const tags = ref({})

    const envs = ['dev2prod', 'dev', 'prod']
    const listItems = CrudTagsSettings(...envs)

    watch(
      () => props.searchFields,
      () => {
        envs.forEach((env) => {
          if (!props.searchFields?.[env]) {
            if (tags.value[env]) {
              delete tags.value[env]
            }
          } else if (!tags.value?.[env]) {
            tags.value[env] = listItems[env]
          }
        })
      },
      { immediate: true }
    )

    return {
      open,
      tags,
      icons: {
        Bottom,
        Top
      },
      listItems,
      tagsLength: computed(() => {
        return Object.keys(tags.value).length
      }),
      onHidePopover: () => {
        open.value = false
      },
      toggleTag: (tagKey) => {
        const searchParams = { ...props.searchFields }
        if (tags.value[tagKey]) {
          delete tags.value[tagKey]
          delete searchParams[tagKey]
        } else {
          tags.value[tagKey] = listItems[tagKey]
          searchParams[tagKey] = true
        }
        context.emit('update:search-fields', searchParams)
      }
    }
  },
  computed: {
    ...mapGetters({
      userScaledSize: 'auth/userScaledSize'
    }),
    sizeClass() {
      return this.size ? `el-input--${this.size}` : `el-input--${this.userScaledSize}`
    }
  }
}
</script>

<template>
  <el-popover
    placement="bottom"
    trigger="click"
    popper-class="px-0"
    :width="80"
    @show="open = true"
    @before-leave="onHidePopover"
  >
    <template #reference>
      <FieldsLabel :label="label">
        <div
          class="el-input pl-1"
          :class="[sizeClass]"
        >
          <div
            class="el-input__wrapper cursor-pointer pl-0"
            tabindex="0"
            style="min-width: 100px"
          >
            <div class="el-input__inner">
              <div class="flex h-full items-center">
                <div
                  v-if="!tags.length && placeholder"
                  class="w-24 truncate pl-2 text-neutral-300"
                >
                  {{ placeholder }}
                </div>
                <div class="flex flex-1 flex-nowrap">
                  <el-tag
                    v-for="(tagOptions, tagKey, index) in tags"
                    :key="index"
                    class="scale-90 pl-0.5"
                    :size="size ? size : userScaledSize"
                    :type="tagOptions.typeBtn"
                    effect="light"
                    closable
                    @close="toggleTag(tagKey)"
                  >
                    <span class="inline-block">{{ tagKey }}</span>
                  </el-tag>
                  <el-tag
                    v-if="!tagsLength"
                    class="invisible scale-90 px-0"
                  >
&nbsp;
                  </el-tag>
                </div>
                <div class="h-fit pl-2 leading-none">
                  <el-icon
                    :size="13"
                    class="transition-transform ease-in"
                    :class="{
                      '-rotate-180': open
                    }"
                  >
                    <ArrowDown />
                  </el-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FieldsLabel>
    </template>
    <div>
      <div class="pt-1">
        <div
          v-for="(options, name, index) in listItems"
          :key="index"
          class="gs-font-scaled cursor-pointer font-light"
          :class="[options.textColor, options.bgHover]"
        >
          <div
            class="flex items-center justify-between px-2 py-0.5"
            @click="toggleTag(name)"
          >
            <div>{{ name }}</div>
            <div class="leading-none">
              <el-icon v-if="tags[name]">
                <Check />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<style lang="postcss"></style>
