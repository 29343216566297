<script>
import CrudFieldSelectorBattleLocations from '@/components/forms/crud-fields/CrudFieldSelectorBattleLocations.vue'
import CrudFieldSelectorBattleMobs from '@/components/forms/crud-fields/CrudFieldSelectorBattleMobs.vue'
import CrudFieldSelectorWearableSets from '@/components/forms/crud-fields/CrudFieldSelectorWearableSets.vue'

export default {
  components: { CrudFieldSelectorWearableSets, CrudFieldSelectorBattleMobs, CrudFieldSelectorBattleLocations },
  setup() {
    return {
      progressLabels: ['increments by one', 'absolute', 'increments by many'],
      criteria: {
        arena_battle: [0],
        arena_win: [0],
        arena_score: [1],
        attach_finished_quest: [0],
        attach_started_quest: [0],
        awaken_item: [0, ['CritItem', 'CritWearableslot', 'CritItemquality']],
        battle_event_multiplier: [1],
        buy_any_ship: [0],
        campaign_dont_use_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        campaign_dont_use_2_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        campaign_dont_use_4_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        challenge_boss_battle: [0],
        collect_n_bonuses_onbody: [1, ['CritWearableslot']],
        combine_item_from_parts: [0, ['CritItem', 'CritWearableslot', 'CritItemquality']],
        complete_all_journeys: [0],
        complete_battle: [0, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty']],
        complete_challenge_craft: [0],
        complete_journey: [0],
        complete_tower_stage: [1],
        defeat_enemy: [0, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritEnemy', 'CritBattleDifficulty']],
        defeat_mob_and_win: [2, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritEnemy', 'CritBattleDifficulty']],
        divide_item: [0, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        enhance_orb_by_level: [2, ['CritItem', 'CritItemquality']],
        get_item: [0, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        get_talent: [2, ['CritItem']],
        guild_donate: [0],
        guild_join: [0],
        guild_send_gift: [2, ['CritItem']],
        guild_tribute: [0],
        idle_claim: [0],
        level_up: [0],
        login: [0],
        loot_item: [
          0,
          [
            'CritLocation',
            'CritStageNo',
            'CritStageNoInformation',
            'CritBattleDifficulty',
            'CritItem',
            'CritWearableslot',
            'CritItemquality',
            'CritEmblem',
            'CritItemgroup'
          ]
        ],
        loot_item_amount: [
          2,
          ['CritBattleDifficulty', 'CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']
        ],
        open_bronze_chest: [0],
        open_chest: [0],
        open_event_chest: [0],
        open_giftbox: [0, 'CritItem', 'CritItemquality'],
        open_gold_chest: [0],
        open_lockbox: [0, ['CritItem']],
        open_silver_chest: [0],
        purchase_in_barrel_shop: [0, 'CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup'],
        purchase_in_stamp_shop: [0, 'CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup'],
        purchase_item: [0, 'CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup', 'CritShoptype'],
        put_extract_powder: [2],
        puton_n_epic_or_better_gears: [1, [], 'is calculated'],
        puton_n_epic_or_better_orbs: [1, [], 'is calculated'],
        puton_n_orbs: [1, [], 'is calculated'],
        puton_n_legendary_or_better_orbs: [1, [], 'is calculated'],
        puton_n_rare_or_better_gears: [1, [], 'is calculated'],
        puton_n_legendary_or_better_gears: [1, [], 'is calculated'],
        reach_gear_upgrade_level: [1, ['CritItem', 'CritItemquality']],
        reach_heropower: [1, [], 'is calculated'],
        reach_level: [1, [], 'is calculated'],
        reach_n_stars: [1, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty']],
        reach_orb_enhance_level: [1, ['CritItem', 'CritItemquality']],
        reach_orb_upgrade_level: [1, ['CritItem', 'CritItemquality']],
        reach_talent_upgrade_level: [1, ['CritItem']],
        reforge_item: [1, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        reload_shop: [0],
        smelt_items: [2, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        spend_premium: [2],
        spend_time_on_journey: [2],
        start_journey: [0],
        take_gold_from_bank: [0],
        unlock_location: [0, ['CritItem', 'CritBattleDifficulty']],
        upgrade_gear_by_level: [2, ['CritItem', 'CritItemquality']],
        upgrade_orb_by_level: [2, ['CritItem', 'CritItemquality']],
        upgrade_talent_level: [2, ['CritItem']],
        use_campaign_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        use_campaign_2_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        use_campaign_4_spirit_color: [
          2,
          ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty', 'CritSpiritColor']
        ],
        use_campaign_spirit: [2, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty']],
        use_extract_recipe: [2, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        use_item: [0, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        use_merge_recipe: [0, ['CritItem', 'CritWearableslot', 'CritItemquality', 'CritEmblem', 'CritItemgroup']],
        use_spin: [0],
        use_up_energy: [2, ['CritLocation', 'CritStageNo', 'CritStageNoInformation', 'CritBattleDifficulty']],
        use_up_energy_stew: [0]
      }
    }
  }
}
</script>

<template>
  <crud-details-page
    api="quests/quest"
    enable-relations
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Labels -->
        <crud-field-text
          api-field-name="Labels"
          required
        />
        <!-- ApType -->
        <crud-field-select
          api-field-name="ApType"
          label="Admin type"
          options-enum="enums.AdminPanelTypeQuests"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- CritPlace -->
        <crud-field-select
          api-field-name="CritPlace"
          label="Criterion place"
          options-enum="enums.QuestPlace"
          required
        />
        <!-- CritPlacePng -->
        <crud-field-selector-files
          api-field-name="CritPlacePng"
          label="Place Png Icon (overrides icon from above crit. place)"
        />
      </fields-col>
      <el-col
        :span="24"
        class="bg-amber-100"
      >
        <el-row>
          <crud-field-item-tags api-field-name="ItemTags" />
        </el-row>
        <el-row>
          <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
        </el-row>
      </el-col>
      <fields-col>
        <section-divider>Criteria</section-divider>
      </fields-col>
      <!-- CritCriterion -->
      <crud-field-select
        :sm="6"
        api-field-name="CritCriterion"
        label="Criterion"
        options-enum="enums.QuestCriterion"
        required
      />
      <crud-field-slot
        :sm="6"
        label="Progress calculation"
      >
        <el-input
          :model-value="progressLabels[criteria[form.CritCriterion]?.[0]]"
          disabled
        />
      </crud-field-slot>
      <crud-field-slot
        :sm="6"
        label="Backward criteria info"
      >
        <el-input
          :model-value="criteria[form.CritCriterion]?.[2]"
          disabled
        />
      </crud-field-slot>
      <!-- CritLocation -->
      <CrudFieldSelectorBattleLocations
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritLocation')"
        :sm="6"
        api-field-name="CritLocation"
        label="Criterion location"
        required
      />
      <!-- CritStageNo -->
      <crud-field-number
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritStageNo')"
        :sm="6"
        api-field-name="CritStageNo"
        label="Criterion stage NO"
        required
      />
      <!-- CritStageNoInformation -->
      <crud-field-number
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritStageNoInformation')"
        :sm="6"
        api-field-name="CritStageNoInformation"
        label="Criterion stage NO info"
        required
      />
      <!-- CritEnemy -->
      <CrudFieldSelectorBattleMobs
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritEnemy')"
        :sm="6"
        api-field-name="CritEnemy"
        label="Criterion mob"
        required
      />
      <!-- CritBattleDifficulty -->
      <crud-field-select
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritBattleDifficulty')"
        :sm="6"
        api-field-name="CritBattleDifficulty"
        label="Criterion difficulty"
        options-enum="enums.BattleDifficulty"
        required
      />
      <!-- CritItem -->
      <crud-field-selector-items
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritItem')"
        :sm="6"
        api-field-name="CritItem"
        label="Criterion item"
      />
      <!-- CritWearableslot -->
      <crud-field-select
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritWearableslot')"
        :sm="6"
        api-field-name="CritWearableslot"
        label="Criterion slot"
        options-enum="enums.WearableSlot"
      />
      <!-- CritItemquality -->
      <crud-field-select
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritItemquality')"
        :sm="6"
        api-field-name="CritItemquality"
        label="Criterion item quality"
        options-enum="enums.ItemQuality"
      />
      <!-- CritShoptype -->
      <crud-field-select
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritShoptype')"
        :sm="6"
        api-field-name="CritShoptype"
        label="Criterion shop type"
        options-enum="enums.ShopType"
        required
      />
      <!-- CritEmblem -->
      <CrudFieldSelectorWearableSets
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritEmblem')"
        :sm="6"
        api-field-name="CritEmblem"
        label="Criterion emblem"
      />
      <!-- CritItemgroup -->
      <crud-field-select
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritItemgroup')"
        :sm="6"
        api-field-name="CritItemgroup"
        label="Criterion item group"
        options-enum="enums.QuestCriterionItemGroup"
      />
      <!-- CritSpiritColor -->
      <crud-field-number
        v-if="criteria[form.CritCriterion]?.[1]?.includes('CritSpiritColor')"
        :sm="6"
        api-field-name="CritSpiritColor"
        label="Criterion spirit color"
        required
      />
    </template>
  </crud-details-page>
</template>
