<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import chroma from 'chroma-js'
import { computed, onMounted, ref, watch } from 'vue'

export default {
  name: 'AmChartWorldMap',
  props: {
    id: {
      type: String,
      default: 'chartmap'
    },
    lowColor: {
      type: String,
      default: '#c5e8e5'
    },
    highColor: {
      type: String,
      default: '#044a44'
    },
    mapData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    const amChart = ref({})
    let root = null
    let map = null
    let polygonSeries = null
    const chromaScaleColor = chroma.scale([props.lowColor, props.highColor])
    const mapDataRendered = computed(() => {
      const data = { ...props.mapData }
      delete data.maxVal
      return Object.entries(data).map(([lang, val]) => {
        return {
          id: lang,
          value: val,
          polygonSettings: {
            fill: chromaScaleColor(val / props.mapData.maxVal).hex(),
            tooltipText: '[bold]{name}[/]: {value}'
          }
        }
      })
    })
    const buildMapData = (root) => {
      polygonSeries = am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ']
      })

      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        getLabelFillFromSprite: false
      })

      tooltip.get('background').setAll({
        fill: am5.color('#282727'),
        fillOpacity: 0.5
      })

      polygonSeries.mapPolygons.template.setAll({
        fill: am5.color('#efefef'),
        stroke: am5.color('#000000'),
        tooltipText: '[bold]{name}[/]: 0',
        tooltip,
        interactive: true,
        templateField: 'polygonSettings'
      })

      polygonSeries.mapPolygons.template.states.create('hover', {
        strokeWidth: 1,
        opacity: 0.8,
        stroke: am5.color('#777474'),
        stateAnimationDuration: 0,
        fill: am5.color('#f6c43e')
      })
      polygonSeries.mapPolygons.template.states.create('default', {
        opacity: 1,
        stateAnimationDuration: 0
      })
      polygonSeries.data.setAll(mapDataRendered.value)
      return polygonSeries
    }
    onMounted(() => {
      root = am5.Root.new(props.id)
      root.numberFormatter.set('numberFormat', '#')
      // Set themes
      root.setThemes([am5themes_Animated.new(root)])
      map = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'none',
          panY: 'none',
          wheelX: 'none',
          wheelY: 'none',
          projection: am5map.geoMercator()
        })
      )
      polygonSeries = buildMapData(root)
      map.series.push(polygonSeries)
      watch(mapDataRendered, () => {
        polygonSeries = buildMapData(root)
        map.series.push(polygonSeries)
      })

      // Make stuff animate on load
      map.appear(1000, 100)
      amChart.value.root = root
      amChart.value.map = map
      amChart.value.polygonSeries = polygonSeries
    })
    return {
      amChart,
      mapDataRendered
    }
  }
}
</script>

<template>
  <div :id="id" />
</template>
