<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Close, Delete, UploadFilled } from '@element-plus/icons-vue'
import { filesize } from 'filesize'
import DocumentationComponent from './DocumentationComponent.vue'
import FilesUploaderDrawer from '!/components/drawers/FilesUploaderDrawer.vue'
import { globalProperties as app } from '!/plugins/utilities'
import { userRightRoles } from '!/composition/utilities'

export default {
  name: 'DetailsPage',
  components: { FilesUploaderDrawer, DocumentationComponent, Close },
  setup() {
    const store = useStore()
    const loading = ref({})
    const renderInitItem = (loadedData) => {
      if (loadedData?.versions?.length) {
        loadedData.versions.sort((a, b) => {
          const aVersion = a.Version.split('.')
          const bVersion = b.Version.split('.')
          const len = Math.min(aVersion.length, bVersion.length)
          for (let i = 0; i < len; i++) {
            const aVersionComp = +aVersion[i] || 0
            const bVersionComp = +bVersion[i] || 0

            if (aVersionComp !== bVersionComp) {
              return aVersionComp > bVersionComp ? -1 : 1
            }
          }
          return bVersion.length - aVersion.length
        })
      }
      return loadedData
    }

    return {
      filesize,
      icons: {
        UploadFilled,
        Delete
      },
      userRightRoles,
      drawer: ref(false),
      targetColWidth: computed(() => {
        return 80 + store.getters['auth/userScaledWidth']
      }),
      loading,
      deleteVersionRow: (versionsRow, refreshData) => {
        loading.value[`deleteVersionRow_${versionsRow.ID}`] = true
        app.$axios
          .delete('/admin/api/binary/file/', {
            params: {
              id: versionsRow.ID
            }
          })
          .then(() => {
            app.$message({
              message: 'deleted',
              type: 'success',
              offset: 40
            })
            refreshData()
          })
          .catch(app.$utils.catchError)
          .then(() => {
            loading.value[`deleteVersionRow_${versionsRow.ID}`] = false
          })
      },
      renderInitItem
    }
  },
  data() {
    return {
      drawerDocumentation: false
    }
  },
  methods: {
    download(url) {
      const subs = url.split('/')
      this.$utils.saveUrlToFile(url, subs[subs.length - 1])
    }
  }
}
</script>

<template>
  <crud-details-page
    api="binary/file"
    :versioned="false"
    disable-top-margin
    :api-form-keys="['versions', 'links', 'statistics']"
    disable-doc
    :actions="{ crudLogs: false }"
    :render-init-item="renderInitItem"
  >
    <template #form="{ form, metaData, refreshData }">
      <fields-col
        :sm="14"
        mobile-break
      >
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          :disabled="!!form.ID"
          required
        />
        <!-- Group -->
        <crud-field-select
          :sm="12"
          api-field-name="Group"
          options-enum="enums.FileGroup"
          required
        />
        <!-- Importance -->
        <crud-field-select
          :sm="12"
          api-field-name="Importance"
          options-enum="enums.FileImportance"
          required
        />
      </fields-col>
      <fields-col
        :sm="10"
        mobile-break
        justify="center"
      >
        <template v-if="metaData?.imgUrl">
          <el-image
            :src="metaData.imgUrl"
            fit="contain"
            class="sm:ml-8 sm:mt-8 max-w-sm cursor-pointer"
            lazy
            :preview-src-list="[metaData?.imgUrl]"
            preview-teleported
            hide-on-click-modal
          />
        </template>
      </fields-col>
      <section-divider>Versions</section-divider>
      <fields-col class="mt-6">
        <fields-table class="gs-font-scaled w-full">
          <template #tHeader>
            <tr>
              <th style="width: 150px">
                Version
              </th>
              <th
                class="px-1 text-center"
                :style="`width: ${targetColWidth}px;`"
              >
                Android
              </th>
              <th
                class="px-1 text-center"
                :style="`width: ${targetColWidth}px;`"
              >
                Android SD
              </th>
              <th
                class="px-1 text-center"
                :style="`width: ${targetColWidth}px;`"
              >
                Ios
              </th>
              <th
                class="px-1 text-center"
                :style="`width: ${targetColWidth}px;`"
              >
                Ios SD
              </th>
              <th
                class="px-1 text-center"
                :style="`width: ${targetColWidth}px;`"
              >
                Png
              </th>
              <th style="minwidth: 200px">
                Added
              </th>
              <th style="width: 80px">
                Action
              </th>
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="versionsRow in form.versions"
              :key="versionsRow.ID"
            >
              <fields-table-td
                class="align-middle"
                :width="150"
              >
                {{ versionsRow.Version || (versionsRow.Dev ? 'DEV' : '-') }}
              </fields-table-td>
              <fields-table-td
                v-for="field in ['SizeAndroid', 'SizeAndroidSd', 'SizeIos', 'SizeIosSd', 'SizePng', 'SizeSoundBank']"
                :key="field"
                :width="targetColWidth"
                class="px-1 align-middle"
                cell-class="text-center whitespace-nowrap"
              >
                <el-link
                  v-if="form.links[versionsRow.ID] && form.links[versionsRow.ID][field]"
                  type="primary"
                  :href="form.links[versionsRow.ID][field]"
                  @click.prevent="download(form.links[versionsRow.ID][field])"
                >
                  {{ filesize(versionsRow[field], { round: 2 }) }}
                </el-link>
                <span v-else>-</span>
              </fields-table-td>
              <fields-table-td class="align-middle">
                {{ versionsRow.AddedBy }}
                <br>
                {{ $utils.formatUTCDateTime(versionsRow.AddedAt) }}
              </fields-table-td>
              <fields-table-td>
                <el-popconfirm
                  v-if="$utils.checkRights(userRightRoles.editor)"
                  title="Are you sure to delete this?"
                  :width="240"
                  @confirm="deleteVersionRow(versionsRow, refreshData)"
                >
                  <template #reference>
                    <span>
                      <el-tooltip
                        content="Delete versions"
                        effect="light"
                        placement="top"
                        :show-after="600"
                      >
                        <el-button
                          :icon="icons.Delete"
                          class="gs-btn-danger-hover-outlined gs-height-related-xl leading-none"
                          :loading="loading[`deleteVersionRow_${versionsRow.ID}`]"
                        />
                      </el-tooltip>
                    </span>
                  </template>
                </el-popconfirm>
              </fields-table-td>
            </tr>
          </template>
        </fields-table>
      </fields-col>
      <section-divider
        v-if="form.statistics"
        class="mt-10"
      >
        Download statistics
      </section-divider>
      <fields-col
        v-if="form.statistics"
        class="mt-6"
      >
        <fields-table class="gs-font-scaled w-full">
          <template #tHeader>
            <tr>
              <th>Date</th>
              <th>First downloads</th>
              <th>Later downloads</th>
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="statRow in form.statistics"
              :key="statRow.ID"
            >
              <fields-table-td :min-width="150">
                {{ statRow.Date.substring(0, 10) }}
              </fields-table-td>
              <fields-table-td :min-width="150">
                {{ statRow.First ? statRow.First : '' }}
              </fields-table-td>
              <fields-table-td :min-width="150">
                {{ statRow.Other ? statRow.Other : '' }}
              </fields-table-td>
            </tr>
          </template>
        </fields-table>
      </fields-col>
    </template>
    <template #actionsFixedLeft>
      <el-tooltip
        effect="light"
        placement="top"
        content="files documentation"
        :show-after="600"
      >
        <el-button
          size="small"
          class="gs-btn-outlined-primary-light relative mx-1 px-1"
          @click="drawerDocumentation = true"
        >
          <icon-ify
            icon="bxs:help-circle"
            class="h-6 w-6"
          />
        </el-button>
      </el-tooltip>
    </template>
    <template #actionsFixed="{ form }">
      <div v-if="$utils.checkRights(userRightRoles.editor)">
        <el-tooltip
          effect="light"
          placement="top"
          content="save the form first"
          :disabled="!!form.ID"
        >
          <div>
            <el-button
              class="gs-loading gs-btn-outlined-primary-neutral gs-font-scaled gs-height-related-xl ml-2 px-3"
              :icon="icons.UploadFilled"
              :disabled="!form.ID"
              @click="drawer = true"
            >
              Upload new file
            </el-button>
          </div>
        </el-tooltip>
      </div>
    </template>
    <template #drawers="{ id, refreshData }">
      <FilesUploaderDrawer
        :id="parseInt(id) || 0"
        v-model:show="drawer"
        title="Upload new file"
        @refresh="refreshData()"
      />
      <el-drawer
        v-model="drawerDocumentation"
        :with-header="false"
        :size="$windowWidth < 640 ? '95%' : '60%'"
      >
        <el-button
          class="gs-btn-text-neutral-light absolute right-4 top-0 bg-transparent p-0"
          @click="drawerDocumentation = false"
        >
          <el-icon class="gs-scaled-icon-xss">
            <Close />
          </el-icon>
        </el-button>
        <DocumentationComponent />
      </el-drawer>
    </template>
  </crud-details-page>
</template>
