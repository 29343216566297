<script>
import { mapGetters } from 'vuex'
import { userRightRoles } from '!/composition/utilities'

export default {
  setup() {
    return {
      accessRight: userRightRoles.playerViewer
    }
  },
  data() {
    return {
      userRightRoles,
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {}
    }
  },
  computed: {
    ...mapGetters({
      userScaledRatioWidth: 'auth/userScaledRatioWidth'
    })
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/jwt/gameplay/guild/${this.$route.params?.id}/`
      this.$axios
        .get(envApi + this.endPoint)
        .then(({ data }) => {
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${data?.item?.Name} (ID:${data?.item?.ID}) on ${data?.env}`
    },
    setNewGuildName(formRefer) {
      if (!this.envApi)
        return false
      const params = {
        newGuildName: this.externalData.item.Name,
        id: this.externalData.item.ID
      }
      formRefer
        .validateField('item.Name')
        .then(() => {
          this.saveData(params, 'setNewGuildName')
        })
        .catch(() => {
          this.notifyNotValid()
        })
    },
    setNewGuildDescription(formRefer) {
      if (!this.envApi)
        return false
      const params = {
        newGuildDescription: this.externalData.item.Description,
        id: this.externalData.item.ID
      }
      formRefer
        .validateField('item.Description')
        .then(() => {
          this.saveData(params, 'setNewGuildDescription')
        })
        .catch(() => {
          this.notifyNotValid()
        })
    },
    notifySaved() {
      this.$message({
        message: 'saved',
        type: 'success',
        offset: 40
      })
    },
    notifyNotValid() {
      this.$notify({
        type: 'warning',
        customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
        message: 'Not valid fields',
        position: 'top-right',
        offset: 80,
        duration: 10000
      })
    },
    saveData(params, loadingKey = '') {
      this.loading[loadingKey] = true
      this.$axios
        .get(`${this.envApi}/jwt/gameplay/saveguild/${this.externalData.item.ID}/`, { params })
        .then(({ data }) => {
          this.notifySaved()
          this.externalData = data
        })
        .catch(this.$utils.catchFormErrors(this.externalErrors))
        .then(() => {
          this.loading[loadingKey] = false
        })
    }
  }
}
</script>

<template>
  <crud-details-page
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    disable-top-margin
    :external-data="externalData"
    :render-title="setTitle"
    :access-right="accessRight"
    proxy
    @is-access="loadData"
  >
    <template #form="{ form, formRefer }">
      <el-tabs
        class="w-full gs-font-scaled sm:px-4"
        model-value="main"
      >
        <el-tab-pane
          label="Main"
          name="main"
          lazy
        >
          <fields-col :sm="4">
            <table class="gs-basic-table w-full">
              <tr>
                <td>
                  <strong>Guild ID</strong><br>
                  {{ form.item.ID }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Region</strong><br>
                  {{ form?.env }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Name</strong><br>
                  {{ form.item.Name.toUpperCase() }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Power</strong><br>
                  {{ form.item.Power }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Created at</strong><br>
                  {{ $utils.formatUTCDateTime(form.item.CreatedAt) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Level</strong><br>
                  {{ form.item.Level }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Members</strong><br>
                  {{ form.item.MembersCount }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Description</strong><br>
                  {{ form.item?.Description }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Coins</strong><br>
                  {{ form.coins }}
                </td>
              </tr>
            </table>
          </fields-col>
          <fields-col>
            <filtering-table
              v-if="!!form.academy_stats.length"
              :rows="form.academy_stats"
              :max-height="`${Math.max(500, $windowHeight * 0.7)}px`"
              class="mt-5"
              header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
              cell-class-name="gs-col-padding-mini"
              fit
              stripe
              :columns="{
                attribute: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 120 } },
                lvl: { header: 'Level', sortable: true, filterField: true, type: 'int', elAttr: { width: 80 } },
                tribute1: { header: 'Tribute', sortable: true, filterField: true, type: 'int', elAttr: { width: 120 } },
                tribute1_percent: {
                  header: 'Tribute %',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { width: 100, formatter: (row) => `${row.tribute1_percent}${row.tribute1_percent !== '-' ? '%' : ''}` }
                },
                tribute2: { header: 'Tribute', sortable: true, filterField: true, type: 'int', elAttr: { width: 120 } },
                tribute2_percent: {
                  header: 'Tribute %',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { width: 100, formatter: (row) => `${row.tribute2_percent}${row.tribute2_percent !== '-' ? '%' : ''}` }
                },
                tribute3: { header: 'Tribute', sortable: true, filterField: true, type: 'int', elAttr: { width: 120 } },
                tribute3_percent: {
                  header: 'Tribute %',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { width: 100, formatter: (row) => `${row.tribute3_percent}${row.tribute3_percent !== '-' ? '%' : ''}` }
                },
                tribute4: { header: 'Tribute', sortable: true, filterField: true, type: 'int', elAttr: { width: 120 } },
                tribute4_percent: {
                  header: 'Tribute %',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { width: 100, formatter: (row) => `${row.tribute4_percent}${row.tribute4_percent !== '-' ? '%' : ''}` }
                }
              }"
              :page-sizes="[50, 100, 200]"
              hide-refresh-btn
            >
              <template #header_tribute1_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.gold"
                    :src="form.icons.gold"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute1_percent_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.gold"
                    :src="form.icons.gold"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute2_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.eternium"
                    :src="form.icons.eternium"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute2_percent_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.eternium"
                    :src="form.icons.eternium"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute3_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.blue_writ"
                    :src="form.icons.blue_writ"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute3_percent_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.blue_writ"
                    :src="form.icons.blue_writ"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute4_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.red_writ"
                    :src="form.icons.red_writ"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
              <template #header_tribute4_percent_append>
                <div class="pt-2">
                  <el-image
                    v-if="form.icons.red_writ"
                    :src="form.icons.red_writ"
                    style="width: 40px; height: 40px"
                  />
                </div>
              </template>
            </filtering-table>
          </fields-col>
        </el-tab-pane>
        <el-tab-pane
          label="Players"
          name="players"
          lazy
        >
          <fields-col>
            <filtering-table
              v-if="!!form.players.length"
              :rows="form.players"
              :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.7 : 0.6))}px`"
              header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
              cell-class-name="gs-col-padding-mini"
              fit
              :columns="{
                name: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 160 } },
                guild_rank: {
                  header: 'Guild rank',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { minWidth: 120 }
                },
                level: { sortable: true, filterField: true, type: 'int', elAttr: { minWidth: 120 } },
                heropower: { sortable: true, filterField: true, type: 'int', elAttr: { minWidth: 120 } },
                coins: { sortable: true, filterField: true, type: 'int', elAttr: { minWidth: 120 } }
              }"
              :page-sizes="[50, 100, 200]"
              hide-refresh-btn
            />
          </fields-col>
        </el-tab-pane>
        <el-tab-pane
          label="Edit"
          name="edit"
          lazy
        >
          <fields-col>
            <el-card
              shadow="never"
              class="mt-6 w-full"
            >
              <fields-col>
                <crud-field-text
                  :sm="6"
                  api-field-name="item.Name"
                  label="Guild name"
                  :rules="[$v.maxLength(255)]"
                  required
                />
                <crud-field-slot
                  :sm="4"
                  empty-label
                  label="Change guild name"
                >
                  <el-popconfirm
                    title="Are you sure?"
                    @confirm="setNewGuildName(formRefer)"
                  >
                    <template #reference>
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :disabled="!envApi"
                        :loading="loading.setNewGuildName"
                      >
                        Change guild name
                      </el-button>
                    </template>
                  </el-popconfirm>
                </crud-field-slot>
              </fields-col>
            </el-card>
            <el-card
              shadow="never"
              class="relative mt-6 w-full"
            >
              <fields-col>
                <crud-field-text
                  :sm="6"
                  api-field-name="item.Description"
                  label="Guild description"
                  :rules="[$v.maxLength(70)]"
                />
                <crud-field-slot
                  :sm="4"
                  empty-label
                  label="Change guild description"
                >
                  <el-popconfirm
                    title="Are you sure?"
                    @confirm="setNewGuildDescription(formRefer)"
                  >
                    <template #reference>
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :disabled="!envApi"
                        :loading="loading.setNewGuildDescription"
                      >
                        Change guild description
                      </el-button>
                    </template>
                  </el-popconfirm>
                </crud-field-slot>
              </fields-col>
            </el-card>
          </fields-col>
        </el-tab-pane>
      </el-tabs>
    </template>
  </crud-details-page>
</template>
