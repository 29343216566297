<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectStunFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions, buffAssetsOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      buffAssetsOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'stun',
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'chance': 100,
      'dlvl-chance': 0,
      'value': 0,
      'prevent-value': 0,
      'buff-asset': 'no_icon'
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Stun_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Stun_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-range -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="Stun_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="Stun_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.chance`"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Stun_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-chance`"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Stun_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.value`"
          label="Stun turns"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Stun_turns"
          disable-doc-settings
        />
      </div>
      <!-- prevent-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.prevent-value`"
          label="Prevent turns"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Stun_Prevent_turns"
          disable-doc-settings
        />
      </div>
      <!-- buff-asset -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.buff-asset`"
          label="Buff asset"
          slim
          :clearable="false"
          :options="buffAssetsOptions"
          doc-field-name="Stun_Buff_asset"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
