<script setup></script>

<template>
  <crud-details-page
    api="pvp/setting"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="5">
        <!-- PvpSeasonDuration -->
        <crud-field-number
          api-field-name="PvpSeasonDuration"
          label="Pvp season duration [d]"
          required
        />
      </fields-col>
      <template
        v-for="i in 3"
        :key="i"
      >
        <fields-col>
          <section-divider>Season {{ i }}</section-divider>
        </fields-col>
        <crud-field-text
          :sm="6"
          :api-field-name="`PvpSeasonName${i}`"
          label="Name"
          required
        />
        <crud-field-selector-files
          :sm="6"
          :api-field-name="`PvpSeasonGraphic${i}`"
          label="Pvp graphic"
          required
        />
        <crud-field-text
          v-for="k in 3"
          :key="k"
          :sm="4"
          :api-field-name="`PvpSeasonHead${i}Color${k}`"
          :label="`Color ${k}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
