import { defineAsyncComponent } from 'vue'
import { CrudRoute, MenuItem } from './index'
import { userRightRoles } from '!/composition/utilities'

/**
 * components used by routes
 */
export default () => {
  const dashRoutes = [
    CrudRoute.buildRoute('dashboard', () => import('!/pages/index/IndexPage.vue'), 'Dashboard', 'Dash', 'ep:data-board')
      .setCssClass('scale-90')
      .getData()
  ]
  const filesRoutes = [
    ...CrudRoute.buildRoutes(
      'files',
      () => import('!/pages/files/IndexPage.vue'),
      () => import('!/pages/files/DetailsPage.vue'),
      'Files',
      'File',
      'bx:cloud-upload',
      'game.FileEntity',
      defineAsyncComponent(() => import('!/components/selectors/FilesSelector.vue'))
    )
      .setCssClass('scale-105 opacity-80')
      .getData()
  ]
  const globalSyncRoutes = [
    CrudRoute.buildRoute('global-sync', () => import('!/pages/globalSync/IndexPage.vue'), 'Global Sync', 'Global Sync', 'mdi:cog-sync-outline')
      .setCssClass('scale-90')
      .getData()
  ]
  const translationsRoutes = [
    ...CrudRoute.buildRoutes(
      'client-strings',
      () => import('!/pages/translations/client-strings/IndexPage.vue'),
      () => import('!/pages/translations/client-strings/DetailsPage.vue'),
      'Client strings',
      'Client string',
      'fluent:scan-text-20-regular',
      'game.ClientStringEntity',
      defineAsyncComponent(() => import('!/components/selectors/ClientStringSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'translation-packs',
      () => import('!/pages/translations/translation-packs/IndexPage.vue'),
      () => import('!/pages/translations/translation-packs/DetailsPage.vue'),
      'Translation packs',
      'Translation pack',
      'icons8:translation',
      'game.TranslationPackEntity',
      defineAsyncComponent(() => import('!/components/selectors/TranslatePacksSelector.vue'))
    ).getData()
  ]
  const settingsRoutes = [
    ...CrudRoute.buildRoutes(
      'client-flags',
      () => import('!/pages/settings/client-flags/IndexPage.vue'),
      () => import('!/pages/settings/client-flags/DetailsPage.vue'),
      'Client flags',
      'Client flag',
      'charm:flag',
      'game.ClientFlagEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'build-versions',
      () => import('!/pages/settings/build-versions/IndexPage.vue'),
      () => import('!/pages/settings/build-versions/DetailsPage.vue'),
      'Build versions',
      'Build version',
      'system-uicons:versions',
      'game.VersionEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'admin-users',
      () => import('!/pages/settings/admin-users/IndexPage.vue'),
      () => import('!/pages/settings/admin-users/DetailsPage.vue'),
      'Admin users',
      'Admin user',
      'clarity:administrator-line',
      'adminCore.AccessRightEntity'
    )
      .setAccessRole(userRightRoles.admin)
      .getData()
  ]
  settingsRoutes.push(...CrudRoute.buildRoutes(
    'ab-tests',
    () => import('!/pages/settings/abtests/IndexHipoAbPage.vue'),
    () => import('!/pages/settings/abtests/DetailsHipoPage.vue'),
    'AB tests',
    'AB test',
    'teenyicons:ab-testing-outline',
    'game.HiPoEntity',
    defineAsyncComponent(() => import('!/components/selectors/AbSelector.vue'))
  ).getData())
  const toolsRoutes = [
    ...CrudRoute.buildRoutes(
      'ai-images',
      () => import('!/pages/tools/ai-images/IndexPage.vue'),
      () => import('!/pages/tools/ai-images/DetailsPage.vue'),
      'Images',
      'Image',
      'material-symbols:image-search',
      'game.AiImageEntity'
    ).getData(),
    CrudRoute.buildRoute(
      'tools-ap-tips',
      () => import('!/pages/tools/ap-tips/IndexPage.vue'),
      'Admin panel tips',
      'AP tips',
      'ic:outline-tips-and-updates'
    ).getData(),
    CrudRoute.buildRoute(
      'tools-client-console',
      () => import('!/pages/tools/client-console/IndexPage.vue'),
      'Client console',
      'Client console',
      'mdi:console'
    )
      .setAccessRole(userRightRoles.clientConsoleProd)
      .getData(),
    CrudRoute.buildRoute(
      'admin-usage',
      () => import('!/pages/tools/admin-stats/IndexPage.vue'),
      'Admin usage statistics',
      'Admin usage',
      'wpf:statistics'
    ).getData()
  ]

  return [
    MenuItem.build('dashboard', dashRoutes).setIsSubMenu(false).setPriority(10).getData(),
    MenuItem.build('global-sync', globalSyncRoutes).setIsSubMenu(false).setPriority(10).getData(),
    MenuItem.build('files', filesRoutes).setIsSubMenu(false).setPriority(10).getData(),
    MenuItem.build('translations', translationsRoutes, 'icon-park-outline:translation', 'Translations')
      .setCssClass('gs-light-menu-icon')
      .setPriority(-10)
      .getData(),
    MenuItem.build('tools', toolsRoutes, 'ant-design:tool-outlined', 'Tools')
      .setCssClass('opacity-80')
      .setPriority(-20)
      .getData(),
    MenuItem.build('settings', settingsRoutes, 'arcticons:settings', 'Settings')
      .setCssClass('stroke-2')
      .setPriority(-30)
      .getData()
  ]
}
