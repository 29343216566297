import Logo from '../../assets/logo_ql.webp'

const DefaultMeta = {
  Title: '', // if empty string ('') will be overwritten by $route.meta.Title or $route.name
  TitlePrefix: '',
  TitleSuffix: ' : Questland : Admin Panel',
  FaviconFile: '' // name file in public folder,
}

const RouterMeta = {
  ...DefaultMeta,
  PageHeaderTitle: '', // if empty string ('') will be overwritten by DefaultMeta.Title
  DisableLayoutPageHeader: false, // if $route.meta.DisableLayoutPageHeader - disable only that current rote;
  IdParentSubmenu: [], // array unique ids of parent submenus,
  Entity: null
}

const LoginPageSettings = {
  Logo,
  bgClass: 'bg-gradient-to-b from-black/90 via-black to-black/90',
  btnClass: 'text-white hover:bg-stone-900 border-slate-100 hover:border-slate-200 shadow-slate-500/50',
  btnIcon: 'akar-icons:google-fill',
  loadEnvs: true
}

const LayoutSettings = {
  pageHeader: {
    bgClass: 'bg-gradient-to-r from-black to-black/70 text-white font-normal',
    btnClass: 'text-white hover:bg-stone-800 active:bg-stone-500'
  },
  menu: {
    bgClass: 'bg-gradient-to-b from-black to-black/70 text-white',
    Logo,
    title: 'Questland',
    titleClass: 'text-transparent bg-gradient-to-r from-yellow-300 to-yellow-200 font-black ',
    collapsedBtnClass: 'text-yellow-200 hover:text-yellow-300 active:bg-stone-800',
    textColor: '#ffffff',
    textActiveColor: 'rgb(253 224 71)',
    hoverBgColor: 'rgb(64 64 64)',
    submenuBgColor: 'rgb(82 82 82)'
  }
}

if (import.meta.env.VITE_TEST_ENV === 'on') {
  LayoutSettings.pageHeader.bgClass = 'bg-gradient-to-r from-red-800 to-red-800/90 text-white font-normal'
  LayoutSettings.menu.bgClass = 'bg-gradient-to-b from-red-800 to-red-800/70 text-white'
} else if (import.meta.env.VITE_TIMESHIFT === 'on') {
  LayoutSettings.pageHeader.bgClass = 'bg-gradient-to-r from-cyan-800 to-cyan-800/90 text-white font-normal'
  LayoutSettings.menu.bgClass = 'bg-gradient-to-b from-cyan-800 to-cyan-800/70 text-white'
}

export { DefaultMeta, RouterMeta, LoginPageSettings, LayoutSettings }
