import { debounce } from 'lodash'

const options = {
  effectOptions: [
    { value: 'hit', label: 'hit' },
    { value: 'heal', label: 'heal' },
    { value: 'attribute', label: 'attribute' },
    { value: 'resurrection', label: 'resurrection' },
    { value: 'stun', label: 'stun' },
    { value: 'ball', label: 'ball' },
    { value: 'activeboost', label: 'activeboost' },
    { value: 'chain', label: 'chain' },
    { value: 'remove_intensity', label: 'remove intensity' },
    { value: 'shield_revive', label: 'shield revive' },
    { value: 'shield_alter', label: 'shield alter' },
    { value: 'pet_skill_level', label: 'pet skill level' },
    { value: 'pet_skill_cooldown', label: 'pet skill cooldown' }
  ],
  targetTeamOptions: [
    { value: 'self', label: 'self' },
    { value: 'friends', label: 'friends' },
    { value: 'enemies', label: 'enemies' },
    { value: 'interaction_enemy', label: 'interaction enemy' },
    { value: 'triggering_fighter', label: 'triggering fighter' }
  ],
  srcOptions: [
    { value: 'attack', label: 'attack' },
    { value: 'magic', label: 'magic' }
  ],
  srcHealOptions: [
    { value: 'magic', label: 'magic' },
    { value: 'maxhp', label: 'maxhp' }
  ],
  booleanOptions: [
    { value: 1, label: 'yes' },
    { value: 0, label: 'no' }
  ],
  buffAssetsOptions: [
    { value: 'no_icon', label: 'no icon' },
    { value: 'active_boost', label: 'active boost' },
    { value: 'active_boost_blue', label: 'active boost blue' },
    { value: 'active_boost_red', label: 'active boost red' },
    { value: 'active_boost_white', label: 'active boost white' },
    { value: 'bleeding', label: 'bleeding' },
    { value: 'burning', label: 'burning' },
    { value: 'crit_up', label: 'crit up' },
    { value: 'crit_down', label: 'crit down' },
    { value: 'dodge_up', label: 'dodge up' },
    { value: 'dodge_down', label: 'dodge down' },
    { value: 'freeze', label: 'freeze' },
    { value: 'heal', label: 'heal' },
    { value: 'magic_up', label: 'magic up' },
    { value: 'magic_down', label: 'magic down' },
    { value: 'magic_resistance', label: 'magic resistance' },
    { value: 'magic_resistance_down', label: 'magic resistance down' },
    { value: 'melee_up', label: 'melee up' },
    { value: 'melee_down', label: 'melee down' },
    { value: 'melee_resistance', label: 'melee resistance' },
    { value: 'melee_resistance_down', label: 'melee resistance down' },
    { value: 'poison', label: 'poison' },
    { value: 'prevent_stun', label: 'prevent stun' },
    { value: 'shield_up', label: 'shield up' },
    { value: 'shield_down', label: 'shield down' },
    { value: 'stun', label: 'stun' }
  ],
  colorOptions: [
    { value: 'white', label: 'white' },
    { value: 'red', label: 'red' },
    { value: 'blue', label: 'blue' },
    { value: 'random', label: 'random' }
  ]
}

const methods = {
  initDefaultValues(values = {}) {
    const path = `effectRow.data`
    this.$utils.setByPath(this.form, path, { ...values, ...this.$utils.getByPath(this.form, path, {}) })
  },
  getAssetsRows(effectsRows) {
    return effectsRows
  }
}

const stringMap = {
  hit: [
    'target-team', // Target range
    'times', // Times
    'src', // Value source
    'is-percent', // Use % of value  yes: 1, no: 0
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value', // Increment per level
    'skip-defence-min', // Skip def. min %
    'skip-defence-max', // Skip def. max %
    'dlvl-skip-defence', // Increment per level
    'static-value', // not visible in form
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'add-defence-value', // Defence hit %
    'add-defence-dlvl-value' // Increment per level
  ],
  heal: [
    'target-team', // Target team
    'times', // Times
    'srcHeal', // Value source
    'is-percent', // Use % of value  yes: 1, no: 0
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value' // Increment per level
  ],
  attribute: [
    'target-team', // Target team
    'kind', // Kind,
    'static-value', // not visible in form
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value', // Increment per level
    'duration', // Duration
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'buff-asset' // Buff asset
  ],
  resurrection: [
    'target-team', // Target team
    'is-percent', // Use % of HP,
    'hp-min', // HP value min
    'hp-max', // HP value max
    'dlvl-hp', // Increment per level
    'chance', // Chance %
    'dlvl-chance' // Chance inc per lvl
  ],
  stun: [
    'target-team', // Target team
    'value', // Stun turns
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'buff-asset', // Buff asset
    'prevent-value' // Prevent turns
  ],
  ball: [
    'target-team', // Target team
    'effect', // Kind
    'color', // Color
    'min', // Ball No. min
    'max', // Ball No. max
    'chance', // Chance %
    'dlvl-chance' // Chance increment per level
  ],
  activeboost: [
    'target-team', // Target team
    'buff-asset', // Buff asset
    'ch_colors', // Colors
    'ch_balls', // Balls
    'value-min', // Value min
    'value-max', // Value max
    'dlvl-value', // Increment per level
    'duration' // Duration
  ],
  chain: [
    'target-team', // Target team
    'chance', // Chance %,
    'dlvl-chance', // Chance increment per level
    'color', // Triggered color
    'balls', // Triggered balls
    'if_color', // If color
    'if_balls' // If balls
  ],
  remove_intensity: [
    'target-team', // Target team
    'chance', // Chance %,
    'dlvl-chance' // Chance increment per level
  ],
  shield_revive: [
    'target-team', // Target team
    'chance', // Chance %,
    'dlvl-chance', // Chance inc per level
    'value', // Shield value after revive %
    'dlvl-value' // Value inc per level
  ],
  shield_alter: [
    'target-team', // Target team
    'times', // Times
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value' // Increment per level
  ],
  pet_skill_level: [
    'target-team',
    'value'
  ],
  pet_skill_cooldown: [
    'target-team',
    'value'
  ]
}
const buildEffectString = debounce((effectsRow, assignCallBack) => {
  const newRowStringData = []
  if (stringMap?.[effectsRow.type]) {
    stringMap?.[effectsRow.type].forEach((field) => {
      let val
      switch (field) {
        case 'dlvl-value':
        case 'dlvl-skip-defence':
        case 'dlvl-chance':
        case 'add-defence-dlvl-value':
        case 'dlvl-hp':
          val = String(effectsRow?.data?.[field])
          if (!val.includes('.')) {
            val += '.0'
          }
          break
        case 'ch_balls':
        case 'ch_colors':
          val = 0
          if (effectsRow?.data?.[field]?.length) {
            effectsRow.data[field].forEach((sumVale) => {
              val += sumVale
            })
          }

          break
        default:
          val = effectsRow?.data?.[field]
      }

      // console.log(field, effectsRow?.data?.[field], effectsRow?.data?.[field] === undefined ? '???!!!!!!!!' : '???')
      newRowStringData.push(val)
    })
  }
  assignCallBack(`${effectsRow.type}=${newRowStringData.join(',')}`)
}, 500)

function renderEffectString(effectString, assignCallBack) {
  const effectsRowData = effectString.split(',')
  if (effectsRowData?.[0]) {
    effectsRowData[0] = effectsRowData[0].split('=')
  }
  const formRow = {
    type: effectsRowData[0][0],
    initData: {
      effectType: effectsRowData[0][0]
    },
    stringData: effectsRowData
  }
  effectsRowData[0] = effectsRowData[0][1]
  effectsRowData.forEach((val, index) => {
    const isNumber = /^-?\d*(?:[.,]\d*)?$/.test(val)
    const renderedVal = isNumber ? val * 1 : val
    formRow.initData[stringMap[formRow.type][index]] = renderedVal
    if (['ch_balls', 'ch_colors'].includes(stringMap[formRow.type][index])) {
      const val = []
      const checkValues = [1, 2, 4]
      checkValues.forEach((checkVal) => {
        if (checkVal & renderedVal) {
          val.push(checkVal)
        }
      })
      formRow.initData[stringMap[formRow.type][index]] = val
    }
  })
  assignCallBack(formRow)
}

const props = {
  props: {
    form: {
      type: Object,
      default: undefined
    },
    externalErrors: {
      type: Object,
      default: undefined
    }
  }
}

export { options, methods, props, stringMap, buildEffectString, renderEffectString }
