<script>
import { autoFillGraphic } from '@/composition/autoFillGraphic'

export default {
  name: 'OrbDetailsPage',
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'orb'
        itemData.ShowInIndex = true
        itemData.ApIsSearchable = true
        itemData.PriceGold = 0
        itemData.PricePremium = 0
        itemData.IndexPoints = 0
        itemData.OrbValueBase = 0
        itemData.OrbValueIncrement = 0
      }
      return itemData
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    enable-relations
    doc-key="items-orb"
  >
    <template #form="{ form }">
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          :init-filters="{ Group: 'item' }"
          @change="autoFillGraphic($event, form, { sd: 'IconSd', hd: 'IconHd', prev: 'Preview' })"
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
        <!-- Index points -->
        <crud-field-number
          :span="12"
          api-field-name="IndexPoints"
        />
        <!-- Show in index -->
        <crud-field-switcher
          :span="11"
          :offset="1"
          api-field-name="ShowInIndex"
        />
        <!-- Related Artefact1 -->
        <crud-field-selector-items
          api-field-name="AwakenItem"
          label="Related Artefact1"
          :disabled="form.Quality !== 'legendary'"
        />
        <!-- Related Mythical -->
        <crud-field-selector-items
          api-field-name="RelatedMythical"
          label="Related Mythical"
          :disabled="form.Quality !== 'legendary'"
        />
        <el-col class="bg-amber-100">
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          required
        />
        <fields-col :sm="11">
          <!-- Attribute -->
          <crud-field-select
            api-field-name="OrbAttribute"
            options-enum="enums.BaseAttributeOrb"
          />
          <!-- Attribute value -->
          <crud-field-number
            label="Attribute value"
            api-field-name="OrbValueBase"
          />
          <!-- Attribute increment -->
          <crud-field-number
            label="Attribute increment"
            api-field-name="OrbValueIncrement"
          />

          <el-col>
            <section-divider>Mythical Orbs Only</section-divider>
          </el-col>
          <!-- OrbMythicalSettings -->
          <crud-field-selector-myth-orbs-settings-selector
            label="Mythical Orb Settings"
            api-field-name="OrbMythicalSettings"
          />
          <!-- OrbMythicalExtraAttribute -->
          <crud-field-select
            api-field-name="OrbMythicalExtraAttribute"
            label="Mythical Orb Extra Attribute"
            options-enum="enums.BaseAttributeOrb"
          />
        </fields-col>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
