<script>
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'AbTags',
  props: {
    abData: {
      type: Object,
      default: () => ({})
    },
    abGroup: {
      type: undefined,
      default: undefined
    },
    rights: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      tagsSettings: CrudTagsSettings('ab')
    }
  }
}
</script>

<template>
  <div
    v-if="abData?.ID"
    class="my-1 flex flex-wrap gap-y-1.5"
  >
    <el-tag
      class="gs-font-scaled gs-height-related-sm mx-0.5 border-transparent px-1 transition hover:scale-110"
      :class="[tagsSettings.ab.styleClasses]"
      size="small"
      effect="dark"
    >
      <router-link
        v-if="rights"
        :to="$utils.bindStaticParams({ name: 'ab-tests-details', params: { id: abData.ID } })"
      >
        <span class="font-related-xs">[{{ abData.ID }}] {{ abData.Name }} &lt;gr:{{ abGroup }}&gt;</span>
      </router-link>
      <span
        v-else
        class="font-related-xs"
      >[{{ abData.ID }}] {{ abData.Name }} &lt;gr:{{ abGroup }}&gt;</span>
    </el-tag>
  </div>
</template>
