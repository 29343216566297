<script>
import { ref } from 'vue'
import CrudFieldLangSelect from '!/components/forms/crud-fields/CrudFieldLangSelect.vue'
import CrudFieldCountriesSelect from '!/components/forms/crud-fields/CrudFieldCountriesSelect.vue'
import EnvSelector from '!/components/proxy/EnvSelector.vue'
import { userRightRoles } from '!/composition/utilities'

export default {
  components: { EnvSelector, CrudFieldLangSelect, CrudFieldCountriesSelect },
  setup() {
    return {
      userRightRoles
    }
  },
  data() {
    return {
      form: {},
      externalData: {},
      envApi: '',
      env: 'local',
      playersNumInput: ref(1),
      commandsInput: ref(''),
      skipTutorialInput: ref(false),
      countryCodeInput: ref(''),
      langInput: ref('EN'),
      loading: {},
      respPlayersIds: '',
      isAccess: undefined
    }
  },
  mounted() {
    if (!this.isAccess) {
      this.$notify({
        title: 'No permission',
        type: 'warning',
        customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
        message: 'access denied'
      })
    }
  },
  created() {
    this.isAccess = this.$utils.checkRights(userRightRoles.clientConsoleProd)
    if (this.isAccess) {
      const envApi = this.$store.getters['auth/envs']?.[this.env]
      this.envApi = envApi
    }
  },
  methods: {
    changeEnv() {
      if (this.isAccess) {
        const envApi = this.$store.getters['auth/envs']?.[this.env]
        this.envApi = envApi
      }
    },
    async createPlayers() {
      this.clearLog()
      this.$refs?.form
        ?.validate?.()
        .then(() => {
          this.loading.loadingCreatePlayers = true

          this.$axios
            .post(`${this.envApi}/jwt/tools/createplayers/`, {
              lang: this.langInput.toLowerCase(),
              country_code: this.countryCodeInput,
              players_num: Number.parseInt(this.playersNumInput, 10),
              commands: this.commandsInput,
              skip_tutorial: this.skipTutorialInput
            })
            .then(({ data }) => {
              this.loading.loadingCreatePlayers = false
              this.externalData = data
              data.players.forEach((value) => {
                document.getElementById('log').innerHTML += `${value}\n`
              })
              this.notify('Players created successfully')
            })
            .catch(this.$utils.catchError)
        })
        .catch((err) => {
          this.loading.loadingCreatePlayers = false
          this.notifyNotAllValid(err)
        })
    },
    notifyNotAllValid(err) {
      const isErrorInstance = err instanceof Error
      let msg = 'Oops, something went wrong '
      if (!isErrorInstance) {
        msg = '<b>Not all fields are valid</b> <ul class="italic" style="font-size:10px; line-height: 1.3em;">'
        Object.entries(err).forEach(([apiFieldName]) => {
          msg += `<li >${this.$utils.convertApiNamesToHuman(apiFieldName)}</li>`
        })
        msg += '</ul>'
      }
      this.$notify({
        type: 'warning',
        customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
        dangerouslyUseHTMLString: true,
        message: msg,
        position: 'bottom-right',
        offset: 80,
        duration: 10000
      })
      if (isErrorInstance) {
        throw err
      }
    },
    notify(message = 'saved') {
      this.$message({
        message,
        type: 'success',
        offset: 40
      })
    },
    clearLog() {
      document.getElementById('log').innerHTML = ''
    }
  }
}
</script>

<template>
  <crud-table
    :top-actions="false"
    :versioned="false"
    hide-columns-selector
    hide-refresh-btn
    disable-pagination
    disable-multi-select-rows
  >
    <template #table>
      <div class="relative mb-16 mt-4 sm:px-4">
        <el-form
          ref="form"
          :model="$data"
          label-position="top"
          class="px-5 gs-font-scaled"
          style="min-width: 1000px"
        >
          <el-row>
            <fields-col
              :xs="8"
              :sm="24"
              mobile-break
            >
              <crud-field-slot
                :sm="3"
                label="Env"
              >
                <EnvSelector
                  v-model:value-field="env"
                  :disabled="!envApi"
                  label=" "
                  @change="changeEnv"
                />
              </crud-field-slot>

              <CrudFieldLangSelect
                :sm="4"
                api-field-name="langInput"
                :form="$data"
                :external-errors="{}"
                :visible-options="['EN', 'FR', 'DE', 'IT', 'ES', 'PT', 'RU', 'ZH', 'CM', 'PL']"
                label="Lang"
                :disabled="!envApi"
              />
              <CrudFieldCountriesSelect
                :sm="4"
                api-field-name="countryCodeInput"
                :form="$data"
                :external-errors="{}"
                label="Country code"
                :disabled="!envApi"
              />
              <crud-field-number
                id="param-playersnum"
                :sm="4"
                api-field-name="playersNumInput"
                name="param-playersnum"
                placeholder="Players number"
                :min="1"
                :form="$data"
                :external-errors="{}"
                label="Players number"
                required
                :disabled="!envApi"
              />
              <crud-field-switcher
                :sm="4"
                api-field-name="skipTutorialInput"
                :form="$data"
                :external-errors="{}"
                label="Tutorial skip"
                :disabled="!envApi"
              />
            </fields-col>
            <crud-field-textarea
              id="commands"
              :xs="8"
              :sm="16"
              mobile-break
              api-field-name="commandsInput"
              :textarea-rows="7"
              :form="$data"
              :external-errors="{}"
              label="Commands"
              slim
              :disabled="!envApi"
            />
            <fields-col
              :sm="10"
              class="mt-4 pl-4"
            >
              <el-button
                type="primary"
                :disabled="!envApi"
                :loading="loading.loadingCreatePlayers"
                @click="createPlayers()"
              >
                Create players
              </el-button>
              <el-button
                :disabled="!envApi"
                @click="clearLog()"
              >
                Clear log
              </el-button>
            </fields-col>
            <fields-col
              :xs="8"
              :sm="16"
              class="pt-2 px-4"
            >
              <label
                for="log"
                class="el-form-item__label"
              >Results (players IDs):</label>
              <div
                id="log"
                class="main w-full"
              />
            </fields-col>
          </el-row>
        </el-form>
      </div>
    </template>
  </crud-table>
</template>

<style>
#commands {
  min-height: 100px;
}
.main {
  margin-top: 10px;
  font-size: 12px;
  padding: 0 10px;
  background: #fff;
  overflow: auto;
  white-space: pre-wrap;
  min-height: 500px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
</style>
