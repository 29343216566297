<template>
  <crud-table
    :default-visible-columns="[
      'VipLevel',
      'ReqPremium',
      'PurchasePremiumPer',
      'BankGoldGetAndCapPer',
      'SmeltEterniumPer',
      'BeExtraTicketsCap',
      'IdleExpPer',
      'IdleGoldPer',
      'IdleEterniumPer'
    ]"
    api="settings/vip-statuses"
    api-item="settings/vip-status"
  />
</template>
