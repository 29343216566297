<script>
import CrudFieldSelectorNpcs from '@/components/forms/crud-fields/CrudFieldSelectorNpcs.vue'

export default {
  components: { CrudFieldSelectorNpcs }
}
</script>

<template>
  <crud-details-page
    api="quests/dialog"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Place -->
        <crud-field-select
          api-field-name="Place"
          options-enum="enums.DialogPlace"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- NpcA -->
        <CrudFieldSelectorNpcs
          api-field-name="NpcA"
          label="NPC A"
        />
        <!-- NpcB -->
        <CrudFieldSelectorNpcs
          api-field-name="NpcB"
          label="NPC B"
        />
        <!-- NpcC -->
        <CrudFieldSelectorNpcs
          api-field-name="NpcC"
          label="NPC C"
        />
        <!-- NpcD -->
        <CrudFieldSelectorNpcs
          api-field-name="NpcD"
          label="NPC D"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Dialog -->
        <crud-field-selector-client-strings
          api-field-name="Dialog"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
