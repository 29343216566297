<script>
import { inject, ref } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'
import { initDateTimeMask, initShortcutsDateTime } from '!/composition/dateTimePickerExtensions'

export default {
  name: 'CrudFieldDateTime',
  mixins: [BasicProps],
  props: {
    placeholder: {
      type: String,
      default: 'Select date and time'
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    valueFormat: {
      type: String,
      default: 'YYYY-MM-DDTHH:mm:ss[Z]'
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const crudFormRefer = inject('crudFormRefer', {})
    const initForm = props.form ? {} : inject('initForm', {})
    const fieldNamePath = props.apiFieldName.split('.')
    const handleChange = (val) => {
      if (initForm?.value?.diffs) {
        if ((app.$utils.getByPath(initForm.value.form, fieldNamePath) || null) !== (val || null)) {
          initForm.value.diffs[props.apiFieldName] = true
        } else {
          delete initForm.value.diffs[props.apiFieldName]
        }
      }
    }
    const pickerDateTime = ref(null)
    const { initMaskDateTime } = initDateTimeMask(pickerDateTime, crudFormRefer, props.apiFieldName)

    return {
      crudFormRefer,
      handleChange,
      initMaskDateTime
    }
  },
  data() {
    return {
      input: null
    }
  },
  computed: {
    scaleClass() {
      const classesMap = {
        small: undefined,
        default: 'scale-110',
        large: 'scale-125'
      }
      return classesMap?.[this.$store.getters['auth/userScaledSize']]
    }
  },
  created() {
    this.shortcuts = initShortcutsDateTime(this, 'fieldModel', this.format)
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    type="datetime"
    component="el-date-picker"
  >
    <template #default>
      <slot>
        <!-- eslint-disable vue/no-unused-refs -->
        <el-date-picker
          ref="pickerDateTime"
          v-model="fieldModel"
          v-bind="$attrs"
          :disabled="disabled"
          class="gs-field"
          :class="[classComponent, { 'w-full': fullWidth }]"
          type="datetime"
          :placeholder="placeholder"
          :format="format"
          :value-format="valueFormat"
          :popper-class="`${scaleClass || ''} ${$attrs?.['popper-class'] || ''}` "
          :shortcuts="shortcuts"
          @focus.once="initMaskDateTime"
          @change.once="initMaskDateTime"
          @change="handleChange"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
