import copy from 'clipboard-copy'
import { cloneDeep, get, isEqual, set } from 'lodash'
import { countries, languages } from 'countries-list'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { userRightRoles } from '!/composition/utilities'
import { LayoutSettings } from '@/settings/defaultMeta.js'

dayjs.extend(utc)

languages.zh.name = 'Chinese (simp.)'
languages.cm = {
  name: 'Chinese (trad.)',
  native: ''
}
languages.jp = languages.ja

// eslint-disable-next-line import/no-mutable-exports
let globalProperties = null
function convertApiNamesToHuman(str) {
  if (str.length > 2) {
    let newString = str
      .slice(1)
      .replace(/[A-Z]/g, letter => ` ${letter}`)
      .toLowerCase()
    newString = str.charAt(0).toUpperCase() + newString
    if (newString.slice(-2).toLowerCase() === 'id') {
      newString = newString.slice(0, -2)
    }
    return newString
  }
  return str
}
const utils = {
  env: import.meta.env,
  catchError(error) {
    if (error !== false && (error?.response?.status < 401 || !error?.response?.status)) {
      let errorMsg = error
      if (error.response?.data?.status === 'error') {
        if (error.response?.data?.errors) {
          errorMsg = Object.entries(error.response.data.errors)
            .map(([, val]) => {
              return val
            })
            .join(', ')
        } else if (error.response?.data?.logs) {
          errorMsg = error.response.data.logs
        }
      }
      globalProperties.$notify({
        title: 'Error',
        type: 'warning',
        customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
        duration: 9000,
        message: `${errorMsg}` || 'Oops something went wrong'
      })
      if (!error?.response?.status) {
        console.error(error)
      }
    }
  },
  catchFormErrors(errorFormObjectRefer) {
    return (error) => {
      if (error !== false && (error?.response?.status < 401 || !error?.response?.status)) {
        let errorPopupMsg = error
        let isMatchedFieldError = false
        if (error.response?.data?.status === 'error') {
          if (error.response?.data?.errors) {
            const fieldErrors = error.response.data.errors
            Object.entries(fieldErrors).forEach(([fieldName, errorValue]) => {
              if (errorFormObjectRefer?.[fieldName] !== undefined) {
                isMatchedFieldError = true
                errorFormObjectRefer[fieldName] = errorValue + (errorFormObjectRefer[fieldName] === errorValue ? ' ' : '') // don't remove; needed because the same error is not displayed after validation
                delete fieldErrors[fieldName]
              }
            })
            errorPopupMsg = Object.entries(fieldErrors)
              .map(([, val]) => {
                return val
              })
              .join(', ')
          }
        }
        if (isMatchedFieldError) {
          errorPopupMsg = `Not all fields are valid! <br/>${errorPopupMsg}`
        }
        if (errorPopupMsg) {
          globalProperties.$notify({
            title: 'Error',
            type: 'warning',
            dangerouslyUseHTMLString: true,
            customClass: 'child-inherit-colors bg-orange-50 text-red-600 z-[999999]',
            duration: 9000,
            message:
              `${isMatchedFieldError ? errorPopupMsg : errorPopupMsg?.response?.data?.error || errorPopupMsg}` ||
              'Oops something went wrong'
          })
        }
        if (!error?.response?.status) {
          console.error(error)
        }
      }
    }
  },
  cloneDeep,
  isEqual,
  setByPath: set,
  getByPath: get,
  copyToClipboard: copy,
  checkRights(role) {
    return role === userRightRoles.user || globalProperties.$store.getters['auth/userRights']?.[role] === true
  },
  getFullRouteData(nameStringOrObjRoute, silent = false) {
    // obj: {name: 'nameRoute'} | {path: 'pathRoute'}
    try {
      return typeof nameStringOrObjRoute === 'string'
        ? globalProperties.$router.resolve({ name: nameStringOrObjRoute })
        : globalProperties.$router.resolve(nameStringOrObjRoute)
    } catch (e) {
      if (!silent) {
        console.error(e, nameStringOrObjRoute)
      }
    }
  },
  convertApiNamesToHuman,
  convertPascalToKebabCase(str) {
    return str.replace(/[A-Z]/g, letter => `-${letter}`).toLowerCase()
  },
  countryDataByCode(code) {
    return countries?.[code.toUpperCase()] || { name: code, emoji: null }
  },
  countries,
  languages,
  languageByCode(code) {
    return languages?.[code.toLowerCase()] || { name: code, native: null }
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  getRouteByEntity(entityName, detailsRoute = false) {
    return globalProperties.$router.options.getRouteByEntity(entityName, detailsRoute)
  },
  formatUTCDateTime(dateTimeUTC, format = 'YYYY-MM-DD HH:mm:ss') {
    return dateTimeUTC ? dayjs.utc(dateTimeUTC).format(format) : ''
  },
  nextLoopEvent(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, delay)
    })
  },
  saveBlobToFile(blob, fileName) {
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  },
  saveUrlToFile(url, filename) {
    fetch(url)
      .then(response => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename || url.split('/').pop()
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  },
  isDevMode() {
    return import.meta.env.MODE === 'development'
  },
  isLocalHost() {
    return window?.location?.hostname?.substr(0, 9) === 'localhost'
  },
  getOptionsFromArray(arr) {
    return arr.map((item) => {
      return { value: item, label: item.replaceAll('_', ' ') }
    })
  },
  uniqRowIndex(id, entity, multiEntity) {
    return multiEntity ? `${id}__${entity}` : id
  },
  isFilledField(val, emptyValues = [null]) {
    return ![undefined, ...emptyValues].includes(val)
  },
  bindStaticParams(routeObj) {
    if (LayoutSettings?.gameSelectorSettings?.length && typeof routeObj === 'object') {
      return {
        ...routeObj,
        query: {
          ...routeObj.query,
          __game: globalProperties.$store.getters['main/gameApiSettings']?.id || LayoutSettings?.gameSelectorSettings?.[0]?.id
        }
      }
    }
    return routeObj
  },
  currencySymbolFormat(value, opt = {}) {
    const options = {
      symbol: '$',
      prepend: true,
      locale: undefined,
      precision: 2,
      fixed: false,
      ...opt
    }
    return `${options.prepend ? options.symbol : ''}${Intl.NumberFormat(options.locale, { minimumFractionDigits: options.fixed ? options.precision : 0, maximumFractionDigits: options.precision }).format(value ?? 0)}${options.prepend ? '' : options.symbol}`
  },
  numberFormat(value, opt = {}) {
    const options = {
      locale: 'pl-PL',
      precision: 2,
      fixed: false,
      shortcuts: false,
      ...opt
    }
    let numberValue = value
    let shortCut = ''
    if (options.shortcuts) {
      if (numberValue >= 1000) {
        if (numberValue >= 1000000000) {
          numberValue = Math.round((numberValue / 1000000000) * 100) / 100
          shortCut = 'G'
        } else if (numberValue >= 1000000) {
          numberValue = Math.round((numberValue / 1000000) * 100) / 100
          shortCut = 'M'
        } else {
          numberValue = Math.round((numberValue / 1000) * 100) / 100
          shortCut = 'k'
        }
      }
    }
    return (
      Intl.NumberFormat(options.locale, {
        minimumFractionDigits: options.fixed ? options.precision : 0,
        maximumFractionDigits: options.precision
      }).format(numberValue ?? 0) + shortCut
    )
  },
  notifyNotAllValid(err) {
    const isErrorInstance = err instanceof Error
    let msg = 'Oops, something went wrong '
    if (!isErrorInstance) {
      msg = '<b>Not all fields are valid</b> <ul class="italic" style="font-size:10px; line-height: 1.3em;">'
      Object.entries(err).forEach(([apiFieldName]) => {
        msg += `<li >${convertApiNamesToHuman(apiFieldName)}</li>`
      })
      msg += '</ul>'
    }
    globalProperties.$notify({
      type: 'warning',
      customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
      dangerouslyUseHTMLString: true,
      message: msg,
      position: 'bottom-right',
      offset: 80,
      duration: 10000
    })
    if (isErrorInstance) {
      throw err
    }
  }
}

export default {
  install: (Vue) => {
    Vue.config.globalProperties.$utils = utils
    globalProperties = Vue.config.globalProperties
  }
}

export { globalProperties, utils }
