<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldCheckbox',
  mixins: [BasicProps],
  props: {
    emptyLabel: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    component="el-checkbox"
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
