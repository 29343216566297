<script>
import { autoFillGraphic } from '@/composition/autoFillGraphic'

export default {
  name: 'GuildBannerDetailsPage',
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'guildbanner'
        itemData.ApIsSearchable = true
        itemData.PriceGold = 0
        itemData.PricePremium = 0
        itemData.BannerOrder = 0
      }
      return itemData
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    doc-key="items-guild-banner"
  >
    <template #form="{ form }">
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          @change="
            autoFillGraphic(
              $event,
              form,
              {
                sd: 'IconSd',
                hd: 'IconHd',
                prev: 'Preview',
                avatar1: 'BannerAvatar1',
                avatar2: 'BannerAvatar2',
                avatar3: 'BannerAvatar3'
              },
              { auto_avatars: true }
            )
          "
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files api-field-name="Preview" />
        <!-- Avatar graphic 1-3 -->
        <crud-field-selector-files
          v-for="i in 3"
          :key="i"
          :api-field-name="`BannerAvatar${i}`"
          :label="`Avatar graphic ${i}`"
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          required
        />
        <fields-col
          :sm="12"
        >
          <!-- Banner part -->
          <crud-field-select
            api-field-name="BannerPart"
            options-enum="enums.GuildBanner"
          />
          <!-- Banner order -->
          <crud-field-number
            api-field-name="BannerOrder"
            number
          />
          <!-- Banner is starting -->
          <crud-field-switcher api-field-name="BannerIsStarting" />
        </fields-col>
        <fields-col :sm="12">
          <!-- Banner color (ie: #f1234f) -->
          <crud-field-color
            api-field-name="BannerColor"
            label="Banner color (ie: #f1234f)"
          />
        </fields-col>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
