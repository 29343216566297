<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import ItemMenu from '!/components/page-layout/menu/PageMenuItem.vue'
import SubMenu from '!/components/page-layout/menu/PageMenuSubmenu.vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'PageMenuFavorite',
  components: { SubMenu, ItemMenu },
  setup() {
    const store = useStore()
    const isDragging = ref(false)
    const dragOptions = {
      dropItem: undefined,
      dropItemIndex: undefined
    }
    const favoriteList = ref([])
    const crudHashMap = {}

    watch(
      () => store.getters['main/crudStorage']?.favoritePages,
      () => {
        const listArray = []
        Object.entries({ ...(store.getters['main/crudStorage']?.favoritePages || {}) }).forEach(([crudHash, favoriteItem]) => {
          const routerData = app.$utils.getFullRouteData(favoriteItem.routeName, true)
          if (routerData?.name) {
            crudHashMap[favoriteItem.routeName] = crudHash
            listArray.push({ ...favoriteItem })
          }
        })
        listArray.sort((a, b) => {
          return a.index - b.index
        })
        favoriteList.value = listArray.map((el, index) => {
          delete el.offset
          el.index = index + 1
          return el
        })
      },
      { immediate: true, deep: true }
    )

    // @dragover.prevent is important;

    let ghostEle

    const onDragStart = (ev, item, dropIndex) => {
      ev.dataTransfer.dropEffect = 'move'
      ev.dataTransfer.effectAllowed = 'move'
      dragOptions.dropItem = { ...item }
      dragOptions.dropItemIndex = dropIndex
      dragOptions.dropItemOrder = dropIndex
      isDragging.value = true

      ev.target.parentElement.classList.add('gs-dragging')

      ghostEle = document.createElement('div')
      ghostEle.innerHTML = ev.target.innerHTML
      ghostEle.classList.add('flex', 'text-white', 'bg-black', 'w-48', 'justify-around', 'items-center', 'p-1.5', 'font-related-xss')
      document.body.appendChild(ghostEle)
      ev.dataTransfer.setDragImage(ghostEle, 0, 0)
    }

    const onDragEnter = (index) => {
      if (isDragging.value && index !== dragOptions.dropItemIndex) {
        const indexOrder = index * 2 + 1
        const newOrder = indexOrder + 1 * (indexOrder > dragOptions.dropItemOrder ? 1 : -1)
        dragOptions.dropItemOrder = newOrder
        favoriteList.value[dragOptions.dropItemIndex].offset = newOrder - (dragOptions.dropItemIndex * 2 + 1)
      }
    }

    const onDrop = (ev) => {
      if (isDragging.value) {
        isDragging.value = false
        ev.target.parentElement.classList.remove('gs-dragging')
        const list = [...favoriteList.value]
        list.sort((a, b) => {
          return a.index * 2 + 1 + (a?.offset || 0) - (b.index * 2 + 1 + (b?.offset || 0))
        })
        favoriteList.value = list.map((el, index) => {
          delete el.offset
          el.index = index + 1
          return el
        })
        const storage = Object.fromEntries(
          favoriteList.value.map((item, index) => {
            return [crudHashMap[item.routeName], { ...item, index }]
          })
        )
        store.commit('main/setCrudStorage', ['favoritePages', storage])
        store.dispatch('main/saveCrudStorage', store.getters['main/crudStorage'])
      }
    }

    const onDragEnd = (ev) => {
      if (isDragging.value) {
        ev.target.parentElement.classList.remove('gs-dragging')
        favoriteList.value[dragOptions.dropItemIndex].offset = 0
        dragOptions.dropItem = undefined
        dragOptions.dropItemIndex = undefined
        document.body.removeChild(ghostEle)
      }
    }

    return {
      favoriteList,
      onDragStart,
      onDragEnd,
      onDrop,
      onDragEnter
    }
  }
}
</script>

<template>
  <SubMenu
    v-if="favoriteList.length"
    id="favorite"
    title="Favorite"
    icon="uit:favorite"
    class-icon=""
  >
    <ul class="flex flex-col">
      <ItemMenu
        v-for="(item, index) in favoriteList"
        :key="`${item.routeName}_${index}}`"
        :title="item.userName"
        :router="item.routeName"
        icon-right="healthicons:star-large-outline"
        class-icon=""
        :style="{ order: index * 2 + 1 + (item.offset || 0) }"
        :draggable="favoriteList?.length > 1"
        @dragstart="onDragStart($event, item, index)"
        @dragend="onDragEnd"
        @dragover.prevent
        @drop="onDrop"
        @dragenter="onDragEnter(index)"
      />
    </ul>
  </SubMenu>
</template>

<style lang="postcss">
.gs-dragging {
  background-color: var(--gs-menu-active-color) !important;
  a > * {
    display: none !important;
  }
}
</style>
