<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectChainFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcOptions,
      ifChainColorOptions: [
        { value: 0, label: 'any' },
        { value: 1, label: 'blue' },
        { value: 2, label: 'white' },
        { value: 3, label: 'red' }
      ],
      ifBallsOptions: [
        { value: 0, label: 'any' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 4, label: '4' }
      ],
      chainColorOptions: [
        { value: 1, label: 'blue' },
        { value: 2, label: 'white' },
        { value: 3, label: 'red' }
      ],
      ballsOptions: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 4, label: '4' }
      ],
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'chain',
      'target-team': 'enemies',
      'chance': 0,
      'dlvl-chance': 0,
      'if_color': 0,
      'if_balls': 0,
      'color': 1,
      'balls': 1
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Chain_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Chain_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.chance"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Chain_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-chance"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Chain_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- if_color -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.if_color"
          label="If Color"
          slim
          :clearable="false"
          :options="ifChainColorOptions"
          doc-field-name="Chain_If_Color"
          disable-doc-settings
        />
      </div>
      <!-- if_balls -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.if_balls"
          label="If Balls"
          slim
          :clearable="false"
          :options="ifBallsOptions"
          doc-field-name="Chain_If_Balls"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <div class="col-w-3" />
      <div class="col-w-3" />
      <!-- color -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.color"
          label="Triggered Color"
          slim
          :clearable="false"
          :options="chainColorOptions"
          doc-field-name="Chain_Triggered_Color"
          disable-doc-settings
        />
      </div>
      <!-- balls -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.balls"
          label="Triggered Balls"
          slim
          :clearable="false"
          :options="ballsOptions"
          doc-field-name="Chain_Triggered_Balls"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
