<script setup>
</script>

<template>
  <crud-details-page
    api="guild/reward"
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- LeagueNo -->
        <crud-field-number
          api-field-name="LeagueNo"
          label="League number"
          required
        />
        <!-- PlaceMin -->
        <crud-field-number
          api-field-name="PlaceMin"
          required
        />
        <!-- PlaceMax -->
        <crud-field-number
          api-field-name="PlaceMax"
          required
        />
      </fields-col>
      <fields-col
        :sm="16"
      >
        <!-- LeaderRewardTpl -->
        <crud-field-selector-items
          :sm="12"
          api-field-name="LeaderRewardTpl"
          label="Leader reward"
        />
        <!-- LeadRewardAmount -->
        <crud-field-number
          :sm="12"
          api-field-name="LeadRewardAmount"
        />
        <!-- MembersRewardTpl -->
        <crud-field-selector-items
          :sm="12"
          api-field-name="MembersRewardTpl"
          label="Members reward"
        />
        <!-- MembersRewardAmount -->
        <crud-field-number
          :sm="12"
          api-field-name="MembersRewardAmount"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
