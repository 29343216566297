<script setup>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

const dialogDispatch = ref(false)
const loading = ref('')
const dispatchData = ref({
  mode: null,
  response: null
})

function dispatch(mode, id) {
  loading.value = mode
  dispatchData.value.mode = mode
  let action = null
  const params = { content_id: id, mode }

  if (mode === 'preview') {
    action = '/jwt/creator/dispatch/'
  } else if (mode === 'create') {
    action = '/jwt/creator/dispatch/'
  }

  app.$axios
    .get(action, { params })
    .then(({ data }) => {
      dispatchData.value.response = data || 'no data'
      dispatchData.value.response = dispatchData.value.response.replace('<pre>', '').replace('</pre>', '')
      dialogDispatch.value = true
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value = ''
    })
}

function clearDispatchData() {
  dispatchData.value.mode = null
  dispatchData.value.response = null
}
</script>

<template>
  <crud-details-page
    api="creator/creator"
    disable-top-margin
    enable-relations
    :versioned="false"
  >
    <template #actionsFixedRight="{ form }">
      <el-button
        :size="$store.getters['auth/userScaledMediumSize']"
        class="gs-loading gs-font-scaled gs-height-related-xl relative px-3 gs-btn-outlined-warning-neutral ml-2"
        :loading="loading === 'preview'"
        @click="dispatch('preview', form?.ID)"
      >
        <icon-ify
          icon="icon-park-outline:preview-open"
          class="gs-scaled-icon-xs mr-1"
        />
        Preview
      </el-button>
      <el-popconfirm
        title="Are you sure?"
        @confirm="dispatch('create', form?.ID)"
      >
        <template #reference>
          <el-button
            :size="$store.getters['auth/userScaledMediumSize']"
            class="gs-loading gs-font-scaled gs-height-related-xl relative px-3 gs-btn-outlined-primary-neutral ml-2"
            :loading="loading === 'create'"
          >
            <icon-ify
              icon="system-uicons:mail-new"
              class="gs-scaled-icon-xs mr-1"
            />
            Create
          </el-button>
        </template>
      </el-popconfirm>
    </template>
    <template #form>
      <fields-col :sm="4">
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.CreatorType"
          required
        />
        <crud-field-text api-field-name="Label" />
        <crud-field-text api-field-name="TargetName" />
        <crud-field-number
          api-field-name="TargetId"
          label="TargetID"
        />
      </fields-col>

      <fields-col :sm="20">
        <template
          v-for="i in 5"
          :key="i"
        >
          <crud-field-text
            :sm="6"
            :api-field-name="`Param${i}.Label`"
            :label="`Param${i}.Label`"
          />
          <crud-field-selector-items
            :sm="8"
            :api-field-name="`Param${i}.Item`"
            :label="`Param${i}.Item`"
          />
          <crud-field-number
            :sm="5"
            :api-field-name="`Param${i}.Number`"
            :label="`Param${i}.Number`"
          />
          <crud-field-text
            :sm="5"
            :api-field-name="`Param${i}.String`"
            :label="`Param${i}.String`"
          />
        </template>
      </fields-col>
      <fields-col :sm="9">
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
    </template>
    <template #drawers>
      <el-dialog
        v-model="dialogDispatch"
        width="90%"
        @close="clearDispatchData"
      >
        <template #header>
          <span class="capitalize"> {{ dispatchData.mode }}</span>
        </template>
        <div class="my-2 py-10 border-t-2 border-t-neutral-300">
          <el-scrollbar max-height="65vh">
            <pre>{{ dispatchData.response }}</pre>
          </el-scrollbar>
        </div>
      </el-dialog>
    </template>
  </crud-details-page>
</template>
