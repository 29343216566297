import { ref, watch } from 'vue'

const model = ref([])
const optionsMap = {}
let optionsMapArr = []
const crudSearchFields = ref({})

function initOptionsMap(opt, parent) {
  opt.forEach((item) => {
    item._parent = item._parent || {}
    if (item?.children?.length) {
      item._parent[item.value] = item.value
      initOptionsMap(item.children, item)
    } else {
      optionsMap[item.value] = {
        item,
        option: parent?._parent ? [...Object.keys(parent._parent), item.value] : [item.value]
      }
    }
  })
}

function updateListFromCrudSearchFields() {
  const list = []
  optionsMapArr.forEach((opt) => {
    if (crudSearchFields.value[opt.item.value]) {
      list.push([...opt.option])
    }
  })
  model.value = list
}

watch(
  model,
  () => {
    const checkedValues = {}
    model.value.forEach((item) => {
      checkedValues[[...item].pop()] = true
    })
    optionsMapArr.forEach(({ item }) => {
      crudSearchFields.value[item.value] = checkedValues[item.value] ? 'on' : undefined
    })
  },
  { deep: true }
)

function toggle(node) {
  if (!node.isLeaf)
    return false
  const list = [...model.value]
  if (node.checked) {
    model.value.some((item, index) => {
      if ([...item].pop() === node.value) {
        list.splice(index, 1)
        return true
      }
      return false
    })
    node.checked = false
    model.value = list
  } else {
    node.checked = true
    list.push(node.valueByOption)
    model.value = list
  }
}

export function filterCascader(options, searchFields) {
  initOptionsMap(options)
  optionsMapArr = Object.entries(optionsMap).map(([, val]) => {
    return val
  })
  watch(
    searchFields,
    () => {
      crudSearchFields.value = searchFields.value
      updateListFromCrudSearchFields()
    },
    { deep: true, immediate: true }
  )

  return {
    settings: {
      clearable: true,
      collapseTags: true,
      showAllLevels: false,
      placeholder: ' ',
      props: {
        multiple: true,
        expandTrigger: 'hover'
      }
    },
    model,
    toggle
  }
}
