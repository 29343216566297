<template>
  <crud-details-page
    api="faq/tab"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-selector-client-strings api-field-name="Name" />
        <!-- Order -->
        <crud-field-number
          api-field-name="Order"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- IconFromTargetPlace -->
        <crud-field-select
          api-field-name="IconFromTargetPlace"
          label="Take icon from target place (preferred way)"
          options-enum="enums.QuestPlace"
        />
        <!-- IconGraphic -->
        <crud-field-selector-files
          api-field-name="IconGraphic"
          label="Or icon graphic"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
