<template>
  <crud-details-page
    api="items/myth-orbs-setting"
  >
    <template #form>
      <fields-col>
        <crud-field-text
          api-field-name="Label"
        />
      </fields-col>
      <fields-col>
        <fields-col>
          <el-divider>Potential</el-divider>
        </fields-col>
        <fields-col :sm="8">
          <crud-field-number
            v-for="i in 5"
            :key="i"
            :api-field-name="`Potential${i}.Percent`"
            :label="`Potential Chance [%] ${i}`"
            required
          />
        </fields-col>
        <fields-col :sm="8">
          <crud-field-number
            v-for="i in 5"
            :key="i"
            :api-field-name="`Potential${i}.Min`"
            :label="`Potential Min ${i}`"
            required
          />
        </fields-col>
        <fields-col :sm="8">
          <crud-field-number
            v-for="i in 5"
            :key="i"
            :api-field-name="`Potential${i}.Max`"
            :label="`Potential Max ${i}`"
            required
          />
        </fields-col>
        <fields-col>
          <el-divider>Attribute bonus</el-divider>
        </fields-col>
        <fields-col :sm="8">
          <crud-field-number
            v-for="i in 10"
            :key="i"
            :api-field-name="`AttrBonus${i}.Percent`"
            :label="`Bonus Chance [%] ${i}`"
            required
          />
        </fields-col>
        <fields-col :sm="8">
          <crud-field-number
            v-for="i in 10"
            :key="i"
            :api-field-name="`AttrBonus${i}.Value`"
            :label="`Value [%] ${i}`"
            :precision="2"
            required
          />
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
