<script>
import { computed, ref, toRefs } from 'vue'
import {
  DeleteFilled,
  Close as DeleteIcon,
  Document as DocumentIcon,
  Lightning as ErrorIcon,
  UploadFilled
} from '@element-plus/icons-vue'
import CrudDetailsField from '!/components/crud/sub/CrudDetailsField.vue'
import { globalProperties as app } from '!/plugins/utilities'
import CrudFieldVersions from '!/components/forms/crud-fields/CrudFieldVersions.vue'

export default {
  name: 'FilesUploaderDrawer',
  components: { CrudDetailsField, UploadFilled, ErrorIcon, DocumentIcon, DeleteIcon, CrudFieldVersions },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Object,
      default: () => ({ bytesBinary: 8388608, human: '8MB' })
    },
    title: {
      type: String,
      default: 'Uploader files'
    },
    id: {
      type: Number,
      default: 0
    },
    multi: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: undefined
    },
    staticGroup: {
      type: String,
      default: undefined
    },
    simple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: undefined
    },
    api: {
      type: String,
      default: undefined
    },
    apiData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:show', 'refresh', 'change'],
  setup(props, context) {
    const form = ref({ files: [] })
    const externalErrors = ref({ files: [] })
    const loading = ref(false)
    const progress = ref(0)
    const uploadError = ref(false)
    const formRef = ref(null)
    const showDrawer = computed({
      get() {
        return props.show
      },
      set(val) {
        context.emit('update:show', val)
      }
    })
    if (props.staticGroup) {
      const { staticGroup } = toRefs(props)
      form.value.group = staticGroup.value
    }
    const isValidNameFile = (file) => {
      const checkedName = file.name.split('.').reverse()
      if (checkedName.length === 2 && checkedName[0].toLowerCase() === 'png') {
        return true
      }
      if (checkedName.length === 2 && checkedName[0].toLowerCase() === 'bank') {
        return true
      }
      return (
        checkedName.length >= 3 &&
        checkedName[0] &&
        ['android', 'androidsd', 'ios', 'iossd', 'png'].includes(checkedName[1].toLowerCase()) &&
        checkedName[2]
      )
    }
    const submitTarget = () => {
      uploadError.value = false
      progress.value = 10
      const progressAnimation = setInterval(() => {
        if (progress.value < 70) {
          progress.value += 10
        } else {
          clearInterval(progressAnimation)
        }
      }, 500)
      loading.value = true
      const formData = {
        file: form.value.files[0].raw,
        id: props.id,
        target: form.value.target,
        version: form.value.version,
        importance: form.value.importance,
        dev: form.value.dev,
        skip_compression: form.value.skip_compression || false
      }
      app.$axios
        .post('/admin/api/binary/upload-one/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          context.emit('refresh')
          progress.value = 100
          setTimeout(() => {
            showDrawer.value = false
            app.$message({
              message: 'file uploaded',
              type: 'success',
              offset: 40
            })
            loading.value = false
          }, 600)
        })
        .catch((error) => {
          app.$utils.catchError(error)
          progress.value = 70
          loading.value = false
          uploadError.value = true
        })
        .then(() => {
          clearInterval(progressAnimation)
        })
    }
    const submitMulti = () => {
      let isError = false
      uploadError.value = false
      const fileStepProgressValue = ((1 / form.value.files.length) * 100).toFixed() * 1
      progress.value = fileStepProgressValue / 2
      loading.value = true
      const formData = {
        group: form.value.group,
        version: form.value.version,
        importance: form.value.importance,
        dev: form.value.dev,
        skip_compression: form.value.skip_compression || false
      }
      const requests = []
      form.value.files.forEach((file) => {
        formData.file_0 = file.raw
        requests.push(
          app.$axios
            .post(
              '/admin/api/binary/upload-many/',
              { ...formData },
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            )
            .then(() => {
              file.status = 'success'
            })
            .catch((error) => {
              app.$utils.catchError(error)
              file.status = 'fail'
              isError = true
            })
            .then(() => {
              progress.value = Math.min(progress.value + fileStepProgressValue, 100)
            })
        )
      })
      Promise.allSettled(requests).then(() => {
        if (isError) {
          loading.value = false
          uploadError.value = true
          form.value.files = form.value.files.filter((file) => {
            return file.status !== 'fail'
          })
        } else {
          context.emit('refresh')
          progress.value = 100
          setTimeout(() => {
            showDrawer.value = false
            app.$message({
              message: 'files uploaded',
              type: 'success',
              offset: 40
            })
            loading.value = false
          }, 600)
        }
      })
    }
    const submitStaticGroup = () => {}
    const submitSimple = () => {
      uploadError.value = false
      progress.value = 10
      const progressAnimation = setInterval(() => {
        if (progress.value < 70) {
          progress.value += 10
        } else {
          clearInterval(progressAnimation)
        }
      }, 500)
      loading.value = true
      const formData = {
        file: form.value.files[0].raw,
        ...props.apiData,
        skip_compression: form.value.skip_compression || false
      }
      app.$axios
        .post(props.api, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          context.emit('refresh')
          context.emit('change', data)
          progress.value = 100
          setTimeout(() => {
            showDrawer.value = false
            app.$message({
              message: 'file uploaded',
              type: 'success',
              offset: 40
            })
            loading.value = false
          }, 600)
        })
        .catch((error) => {
          app.$utils.catchError(error)
          progress.value = 70
          loading.value = false
          uploadError.value = true
        })
        .then(() => {
          clearInterval(progressAnimation)
        })
    }
    return {
      showDrawer,
      form,
      formRef,
      loading,
      externalErrors,
      progress,
      uploadError,
      onSelectFile: (file) => {
        if (file.size > props.maxSize.bytesBinary) {
          file.status = 'fail'
        }
        form.value.files = [file]
        formRef.value.validate().catch(() => {})
      },
      onMultiSelectFile: (file) => {
        if (file.size > props.maxSize.bytesBinary) {
          file.status = 'fail'
          file.error_msg = 'size'
        } else if (!isValidNameFile(file)) {
          file.status = 'fail'
          file.error_msg = 'name'
        } else if (props.limit && form.value.files.length > props.limit) {
          file.status = 'fail'
          file.error_msg = 'limit'
        }
        formRef.value.validate().catch(() => {})
      },
      isSomeFail: computed(() => {
        return form.value.files.some((file) => {
          return file?.status === 'fail'
        })
      }),
      submitMulti,
      submitStaticGroup,
      submitTarget,
      submitSimple,
      close: () => {
        form.value = { files: [] }
        externalErrors.value = {}
        loading.value = false
        progress.value = 0
        uploadError.value = false
      },
      icons: {
        DeleteFilled
      },
      cancelFile: (file) => {
        let index = 0
        form.value.files.some((checkFile) => {
          if (checkFile.uid === file.uid) {
            form.value.files.splice(index, 1)
          }
          ++index
          return false
        })
        if (props.multi && props.limit && form.value.files.length > props.limit - 1) {
          for (let i = props.limit - 1; i <= form.value.files.length; i++) {
            if (form.value.files?.[i]?.error_msg === 'limit') {
              form.value.files[i].error_msg = ''
              form.value.files[i].status = 'ready'
            }
          }
        }
      },
      statusProgress: computed(() => {
        if (progress.value < 100) {
          return undefined
        }
        if (uploadError.value) {
          return 'warning'
        }
        return 'success'
      }),
      handleError: (keysArr, isValid, message) => {
        externalErrors.value[keysArr[0]] = message
      }
    }
  }
}
</script>

<template>
  <el-drawer
    v-model="showDrawer"
    :title="title"
    direction="rtl"
    :size="$windowWidth < 640 ? '95%' : '60%'"
    append-to-body
    destroy-on-close
    @closed="close"
  >
    <el-form
      ref="formRef"
      :model="form"
      label-position="top"
      @validate="handleError"
    >
      <fields-col v-if="multi">
        <CrudDetailsField
          :span="12"
          col-break
          api-field-name="group"
          label="Group"
          :form="form"
          :external-errors="externalErrors"
          component="el-select"
          options-enum="enums.FileGroup"
          :disabled="loading"
          required
        />
        <crud-field-select
          :span="12"
          col-break
          options-enum="enums.FileImportance"
          api-field-name="importance"
          label="Importance"
          :form="form"
          :external-errors="externalErrors"
          :disabled="loading"
        />
        <CrudFieldVersions
          api-field-name="version"
          :span="12"
          label="Version"
          :form="form"
          :external-errors="externalErrors"
          :disabled="loading"
          :rules="[$v.gsVersion()]"
        />
        <crud-field-slot
          api-field-name="none"
          empty-label
          :span="12"
        >
          <el-row>
            <crud-field-checkbox
              :form="form"
              class="flex-none"
              slim
              :external-errors="externalErrors"
              :disabled="loading"
              api-field-name="dev"
            />
            <crud-field-checkbox
              :form="form"
              slim
              class="flex-none ml-2"
              :external-errors="externalErrors"
              :disabled="loading"
              label="Skip compression"
              api-field-name="skip_compression"
            />
          </el-row>
        </crud-field-slot>
        <el-col
          :span="24"
          class="mt-5"
        >
          <el-upload
            v-model:file-list="form.files"
            class="gs-uploader-default"
            drag
            multiple
            :accept="accept"
            :auto-upload="false"
            :on-change="onMultiSelectFile"
            :disabled="loading"
          >
            <el-icon class="el-icon--upload">
              <UploadFilled />
            </el-icon>
            <div class="el-upload__text">
              Drop file here or <em>click to select</em>
            </div>
            <template #tip>
              <div
                class="el-upload__tip"
                :class="{
                  'text-rose-500': isSomeFail
                }"
              >
                files with a size less than {{ maxSize.human }}; <br>
                up to {{ limit }} files; <br>
                specific file name structure:
                <span class="font-related-xs text-neutral-400">[any text].[android|androidsd|ios|iossd|png].[ext] or [txt-no-dots].png or [txt-no-dots].bank;</span>
              </div>
              <el-button
                :class="{ invisible: !form?.files?.length }"
                class="gs-btn-text-danger float-right mr-7 mt-3"
                size="small"
                :icon="icons.DeleteFilled"
                @click="form.files = []"
              >
                clear file list
              </el-button>
            </template>
            <template #file="{ file }">
              <div
                class="break-all pr-5"
                :class="{
                  'text-rose-500': file.status === 'fail'
                }"
              >
                <template v-if="file.status === 'fail'">
                  <el-icon
                    :size="16"
                    class="mr-1 translate-y-1"
                  >
                    <ErrorIcon />
                  </el-icon>
                  <sub
                    v-if="file.error_msg"
                    class="mr-1 text-[8px]"
                  >{{ file.error_msg }}</sub>
                </template>
                <el-icon
                  v-else
                  class=""
                  :size="14"
                >
                  <DocumentIcon />
                </el-icon>
                {{ file.name }}
                <el-icon
                  class="absolute right-1 top-1 cursor-pointer"
                  :size="10"
                  @click="cancelFile(file)"
                >
                  <DeleteIcon />
                </el-icon>
              </div>
            </template>
          </el-upload>
        </el-col>
        <el-col :span="12">
          <el-button
            class="gs-loading mt-7 px-3"
            type="success"
            :loading="loading"
            :disabled="
              !form.files.length
                || isSomeFail
                || !form.group?.length
                || !!externalErrors?.version?.length
                || !!externalErrors?.importance?.length
            "
            @click="submitMulti"
          >
            upload new files
          </el-button>
        </el-col>
        <el-col :span="12">
          <transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
          >
            <div
              v-if="loading"
              class="gs-uploader-progress transition ease-in"
            >
              <el-progress
                type="circle"
                :percentage="progress"
                :status="statusProgress"
                class="h-20 w-20"
              />
            </div>
          </transition>
        </el-col>
      </fields-col>
      <fields-col v-else-if="simple">
        <el-col :span="24">
          <el-upload
            v-model:file-list="form.files"
            class="gs-uploader-default"
            drag
            :accept="accept"
            :auto-upload="false"
            :on-change="onSelectFile"
            :disabled="loading"
          >
            <el-icon class="el-icon--upload">
              <UploadFilled />
            </el-icon>
            <div class="el-upload__text">
              Drop file here or <em>click to select</em>
            </div>
            <template #tip>
              <div
                class="el-upload__tip"
                :class="{
                  'text-rose-500': isSomeFail
                }"
              >
                file with a size less than {{ maxSize.human }};
                <span v-if="accept"> accept: {{ accept }}</span>
              </div>
            </template>
          </el-upload>
        </el-col>
        <crud-field-checkbox
          :form="form"
          slim
          col-break
          class="flex-none ml-2"
          :external-errors="externalErrors"
          :disabled="loading"
          label="Skip compression"
          api-field-name="skip_compression"
        />
        <el-col :span="12">
          <el-button
            class="gs-loading mt-7 px-3"
            type="success"
            :loading="loading"
            :disabled="!form.files.length || isSomeFail"
            @click="submitSimple"
          >
            upload file
          </el-button>
        </el-col>
        <el-col :span="12">
          <transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
          >
            <div
              v-if="loading"
              class="gs-uploader-progress transition ease-in"
            >
              <el-progress
                type="circle"
                :percentage="progress"
                :status="statusProgress"
                class="h-20 w-20"
              />
            </div>
          </transition>
        </el-col>
      </fields-col>
      <fields-col
        v-else
        :gutter="40"
      >
        <CrudDetailsField
          v-if="!staticGroup"
          :span="12"
          col-break
          api-field-name="target"
          label="Target"
          :form="form"
          :external-errors="externalErrors"
          component="el-select"
          :options="[
            { value: 'android', label: 'Android' },
            { value: 'androidsd', label: 'Android SD' },
            { value: 'ios', label: 'Ios' },
            { value: 'iossd', label: 'Ios SD' },
            { value: 'png', label: 'Png' },
            { value: 'soundbank', label: 'SoundBank' }
          ]"
          :disabled="loading"
          required
        />
        <CrudFieldVersions
          :span="12"
          api-field-name="version"
          label="Version"
          :form="form"
          :external-errors="externalErrors"
          :disabled="loading"
          :rules="[$v.gsVersion()]"
        />
        <CrudDetailsField
          api-field-name="none"
          empty-label
          :span="12"
        >
          <el-row>
            <crud-field-checkbox
              :form="form"
              class="flex-none"
              slim
              :external-errors="externalErrors"
              :disabled="loading"
              api-field-name="dev"
            />
            <crud-field-checkbox
              :form="form"
              slim
              class="flex-none ml-2"
              :external-errors="externalErrors"
              :disabled="loading"
              label="Skip compression"
              api-field-name="skip_compression"
            />
          </el-row>
        </CrudDetailsField>
        <el-col :span="24">
          <el-upload
            v-model:file-list="form.files"
            class="gs-uploader-default"
            drag
            :accept="accept"
            :auto-upload="false"
            :on-change="onSelectFile"
            :disabled="loading"
          >
            <el-icon class="el-icon--upload">
              <UploadFilled />
            </el-icon>
            <div class="el-upload__text">
              Drop file here or <em>click to select</em>
            </div>
            <template #tip>
              <div
                class="el-upload__tip"
                :class="{
                  'text-rose-500': isSomeFail
                }"
              >
                file with a size less than {{ maxSize.human }}
              </div>
            </template>
          </el-upload>
        </el-col>
        <el-col :span="12">
          <el-button
            v-if="staticGroup"
            class="gs-loading mt-7 px-3"
            type="success"
            :loading="loading"
            :disabled="!form.files.length || isSomeFail"
            @click="submitStaticGroup"
          >
            upload file
          </el-button>
          <el-button
            v-else
            class="gs-loading mt-7 px-3"
            type="success"
            :loading="loading"
            :disabled="
              !form.files.length
                || isSomeFail
                || !form.target?.length
                || !!externalErrors?.version?.length
                || !!externalErrors?.importance?.length
            "
            @click="submitTarget"
          >
            upload file
          </el-button>
        </el-col>
        <el-col :span="12">
          <transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
          >
            <div
              v-if="loading"
              class="gs-uploader-progress transition ease-in"
            >
              <el-progress
                type="circle"
                :percentage="progress"
                :status="statusProgress"
                class="h-20 w-20"
              />
            </div>
          </transition>
        </el-col>
      </fields-col>
    </el-form>
  </el-drawer>
</template>

<style lang="postcss">
.gs-uploader-default {
  .el-upload-list {
    max-width: 70%;
  }

  .el-upload-list__item.is-fail {
    .el-upload-list__item-file-name,
    .el-icon.el-icon--document {
      color: #f43f5e !important;
    }
  }
}
.gs-uploader-progress {
  .el-progress-circle {
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
