<script>
import iso from 'iso-3166-1'
import { isNumber, mergeWith } from 'lodash'
import AmChartWorldMap from '!/components/shared/AmChartWorldMap.vue'

const before = {}
export default {
  name: 'UserStatics',
  components: { AmChartWorldMap },
  props: {
    cols: {
      type: Array,
      default: () => ['Country', 'ActiveUsersLast24', 'ActiveUsersLast1min', 'PayingUsers', 'AllUsersWithSeasonPass', 'PayingUsersLast24h', 'NewUsersLast24h', 'PaidLast24h']
    },
    sortCol: {
      type: String,
      default: 'ActiveUsersLast24'
    },
    envs: {
      type: Array,
      default: () => ['prod']
    }
  },
  data() {
    return {
      tab: 0,
      showMap: false,
      items: [],
      polling: null,
      defSort: { prop: this.sortCol, order: 'descending' },
      envsUrls: {},
      loading: {}
    }
  },
  computed: {
    mapData() {
      const data = {
        maxVal: 1
      }
      this.items.forEach((v) => {
        let val = 0
        if (this.tab === 1) {
          val = v.Data.Android.ActiveUsersLast24
        } else if (this.tab === 2) {
          val = v.Data.Ios.ActiveUsersLast24
        } else {
          val = v.Data.Android.ActiveUsersLast24 + v.Data.Ios.ActiveUsersLast24
        }
        if (val) {
          data[v.ISO] = val
          if (val > data.maxVal) {
            data.maxVal = val
          }
        }
      })
      return data
    },
    finalItems() {
      const data = []
      const dataSummary = {
        country: 'Total',
        class: 'font-black',
        AllUsersWithSeasonPass: 0,
        AllUsers: 0,
        ActiveUsersLast24: 0,
        ActiveUsersLast1min: 0,
        PayingUsersLast24h: 0,
        NewUsersLast24h: 0,
        PaidLast24h: 0,
        PayingUsers: 0,
        AllUsersNew: 0,
        ActiveUsersLast24New: 0,
        ActiveUsersLast1minNew: 0,
        PayingUsersLast24hNew: 0,
        NewUsersLast24hNew: 0,
        PaidLast24hNew: 0,
        PayingUsersNew: 0
      }
      this.items.forEach((v, k) => {
        const countryData = iso.whereAlpha2(v.ISO)
        let name = countryData === undefined ? v.ISO : countryData.country
        if (name === 'United Kingdom of Great Britain and Northern Ireland') {
          name = 'United Kingdom'
        }
        const row = {
          country: name,
          AllUsersNew: 0,
          ActiveUsersLast24New: 0,
          ActiveUsersLast1minNew: 0,
          PayingUsersLast24hNew: 0,
          NewUsersLast24hNew: 0,
          PaidLast24hNew: 0,
          PayingUsersNew: 0
        }
        if (this.tab === 1) {
          row.AllUsersWithSeasonPass = v.Data.Android.AllUsersWithSeasonPass
          dataSummary.AllUsersWithSeasonPass += v.Data.Android.AllUsersWithSeasonPass
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsersWithSeasonPass - before[v.ISO].Android.AllUsersWithSeasonPass
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.AllUsers = v.Data.Android.AllUsers
          dataSummary.AllUsers += v.Data.Android.AllUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsers - before[v.ISO].Android.AllUsers
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.ActiveUsersLast24 = v.Data.Android.ActiveUsersLast24
          dataSummary.ActiveUsersLast24 += v.Data.Android.ActiveUsersLast24
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast24 - before[v.ISO].Android.ActiveUsersLast24
            if (diff !== 0) {
              row.ActiveUsersLast24New = diff
              dataSummary.ActiveUsersLast24New += diff
            }
          }
          row.ActiveUsersLast1min = v.Data.Android.ActiveUsersLast1min
          dataSummary.ActiveUsersLast1min += v.Data.Android.ActiveUsersLast1min
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast1min - before[v.ISO].Android.ActiveUsersLast1min
            if (diff !== 0) {
              row.ActiveUsersLast1minNew = diff
              dataSummary.ActiveUsersLast1minNew += diff
            }
          }
          row.PayingUsers = v.Data.Android.PayingUsers
          dataSummary.PayingUsers += v.Data.Android.PayingUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsers - before[v.ISO].Android.PayingUsers
            if (diff !== 0) {
              row.PayingUsersNew = diff
              dataSummary.PayingUsersNew += diff
            }
          }
          row.PayingUsersLast24h = v.Data.Android.PayingUsersLast24h
          dataSummary.PayingUsersLast24h += v.Data.Android.PayingUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsersLast24h - before[v.ISO].Android.PayingUsersLast24h
            if (diff !== 0) {
              row.PayingUsersLast24hNew = diff
              dataSummary.PayingUsersLast24hNew += diff
            }
          }
          row.NewUsersLast24h = v.Data.Android.NewUsersLast24h
          dataSummary.NewUsersLast24h += v.Data.Android.NewUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.NewUsersLast24h - before[v.ISO].Android.NewUsersLast24h
            if (diff !== 0) {
              row.NewUsersLast24hNew = diff
              dataSummary.NewUsersLast24hNew += diff
            }
          }
          row.PaidLast24h = v.Data.Android.PaidLast24h
          dataSummary.PaidLast24h += v.Data.Android.PaidLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast24 - before[v.ISO].Android.ActiveUsersLast24
            if (diff !== 0) {
              row.ActiveUsersLast24New = diff
              dataSummary.PaidLast24hNew += diff
            }
          }
        } else if (this.tab === 2) {
          row.AllUsersWithSeasonPass = v.Data.Ios.AllUsersWithSeasonPass
          dataSummary.AllUsersWithSeasonPass += v.Data.Ios.AllUsersWithSeasonPass
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsersWithSeasonPass - before[v.ISO].Ios.AllUsersWithSeasonPass
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.AllUsers = v.Data.Ios.AllUsers
          dataSummary.AllUsers += v.Data.Ios.AllUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsers - before[v.ISO].Ios.AllUsers
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.ActiveUsersLast24 = v.Data.Ios.ActiveUsersLast24
          dataSummary.ActiveUsersLast24 += v.Data.Ios.ActiveUsersLast24
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast24 - before[v.ISO].Ios.ActiveUsersLast24
            if (diff !== 0) {
              row.ActiveUsersLast24New = diff
              dataSummary.ActiveUsersLast24New += diff
            }
          }
          row.ActiveUsersLast1min = v.Data.Ios.ActiveUsersLast1min
          dataSummary.ActiveUsersLast1min += v.Data.Ios.ActiveUsersLast1min
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast1min - before[v.ISO].Ios.ActiveUsersLast1min
            if (diff !== 0) {
              row.ActiveUsersLast1minNew = diff
              dataSummary.ActiveUsersLast1minNew += diff
            }
          }
          row.PayingUsers = v.Data.Ios.PayingUsers
          dataSummary.PayingUsers += v.Data.Ios.PayingUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsers - before[v.ISO].Ios.PayingUsers
            if (diff !== 0) {
              row.PayingUsersNew = diff
              dataSummary.PayingUsersNew += diff
            }
          }
          row.PayingUsersLast24h = v.Data.Ios.PayingUsersLast24h
          dataSummary.PayingUsersLast24h += v.Data.Ios.PayingUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsersLast24h - before[v.ISO].Ios.PayingUsersLast24h
            if (diff !== 0) {
              row.PayingUsersLast24hNew = diff
              dataSummary.PayingUsersLast24hNew += diff
            }
          }
          row.NewUsersLast24h = v.Data.Ios.NewUsersLast24h
          dataSummary.NewUsersLast24h += v.Data.Ios.NewUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.NewUsersLast24h - before[v.ISO].Ios.NewUsersLast24h
            if (diff !== 0) {
              row.NewUsersLast24hNew = diff
              dataSummary.NewUsersLast24hNew += diff
            }
          }
          row.PaidLast24h = v.Data.Ios.PaidLast24h
          dataSummary.PaidLast24h += v.Data.Ios.PaidLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.PaidLast24h - before[v.ISO].Ios.PaidLast24h
            if (diff !== 0) {
              row.PaidLast24hNew = diff
              dataSummary.PaidLast24hNew += diff
            }
          }
        } else if (this.tab >= 0) {
          row.AllUsersWithSeasonPass = v.Data.Android.AllUsersWithSeasonPass + v.Data.Ios.AllUsersWithSeasonPass
          dataSummary.AllUsersWithSeasonPass += row.AllUsersWithSeasonPass
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsersWithSeasonPass - (before[v.ISO].Android.AllUsersWithSeasonPass + before[v.ISO].Ios.AllUsersWithSeasonPass)
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.AllUsers = v.Data.Android.AllUsers + v.Data.Ios.AllUsers
          dataSummary.AllUsers += row.AllUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.AllUsers - (before[v.ISO].Android.AllUsers + before[v.ISO].Ios.AllUsers)
            if (diff !== 0) {
              row.AllUsersNew = diff
              dataSummary.AllUsersNew += diff
            }
          }

          row.ActiveUsersLast24 = v.Data.Android.ActiveUsersLast24 + v.Data.Ios.ActiveUsersLast24
          dataSummary.ActiveUsersLast24 += row.ActiveUsersLast24
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast24 - (before[v.ISO].Android.ActiveUsersLast24 + before[v.ISO].Ios.ActiveUsersLast24)
            if (diff !== 0) {
              row.ActiveUsersLast24New = diff
              dataSummary.ActiveUsersLast24New += diff
            }
          }

          row.ActiveUsersLast1min = v.Data.Android.ActiveUsersLast1min + v.Data.Ios.ActiveUsersLast1min
          dataSummary.ActiveUsersLast1min += row.ActiveUsersLast1min
          if (before[v.ISO] !== undefined) {
            const diff = row.ActiveUsersLast1min - (before[v.ISO].Android.ActiveUsersLast1min + before[v.ISO].Ios.ActiveUsersLast1min)
            if (diff !== 0) {
              row.ActiveUsersLast1minNew = diff
              dataSummary.ActiveUsersLast1minNew += diff
            }
          }
          row.PayingUsers = v.Data.Android.PayingUsers + v.Data.Ios.PayingUsers
          dataSummary.PayingUsers += row.PayingUsers
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsers - (before[v.ISO].Android.PayingUsers + before[v.ISO].Ios.PayingUsers)
            if (diff !== 0) {
              row.PayingUsersNew = diff
              dataSummary.PayingUsersNew += diff
            }
          }
          row.PayingUsersLast24h = v.Data.Android.PayingUsersLast24h + v.Data.Ios.PayingUsersLast24h
          dataSummary.PayingUsersLast24h += row.PayingUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.PayingUsersLast24h - (before[v.ISO].Android.PayingUsersLast24h + before[v.ISO].Ios.PayingUsersLast24h)
            if (diff !== 0) {
              row.PayingUsersLast24hNew = diff
              dataSummary.PayingUsersLast24hNew += diff
            }
          }
          row.NewUsersLast24h = v.Data.Android.NewUsersLast24h + v.Data.Ios.NewUsersLast24h
          dataSummary.NewUsersLast24h += row.NewUsersLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.NewUsersLast24h - (before[v.ISO].Android.NewUsersLast24h + before[v.ISO].Ios.NewUsersLast24h)
            if (diff !== 0) {
              row.NewUsersLast24hNew = diff
              dataSummary.NewUsersLast24hNew += diff
            }
          }
          row.PaidLast24h = v.Data.Android.PaidLast24h + v.Data.Ios.PaidLast24h
          dataSummary.PaidLast24h += row.PaidLast24h
          if (before[v.ISO] !== undefined) {
            const diff = row.PaidLast24h - (before[v.ISO].Android.PaidLast24h + before[v.ISO].Ios.PaidLast24h)
            if (diff !== 0) {
              row.PaidLast24hNew = diff
              dataSummary.PaidLast24hNew += diff
            }
          }
        }
        data[k] = row
      })
      data.push(dataSummary)
      return data.sort((a, b) => {
        if (this.defSort.order === 'descending') {
          return b?.[this.defSort.prop] - a?.[this.defSort.prop]
        } else {
          return a?.[this.defSort.prop] - b?.[this.defSort.prop]
        }
      })
    }
  },
  created() {
    const storedData = this.$store.getters['auth/userLocalSettings']?.dash || {}
    this.tab = storedData?.tab || 0
    this.showMap = storedData?.showMap || false
    let loadEnvs = false
    this.envs.forEach((env) => {
      if (this.$store.state.auth.envs?.[env]) {
        this.envsUrls[env] = this.$store.state.auth.envs[env]
      } else {
        loadEnvs = true
      }
    })
    if (loadEnvs) {
      this.$store.dispatch('auth/loadEnvs').then(() => {
        this.envs.forEach((env) => {
          if (this.$store.state.auth.envs?.[env]) {
            this.envsUrls[env] = this.$store.state.auth.envs[env]
          }
        })
        this.pollData()
      })
    } else {
      this.pollData()
    }
  },
  beforeUnmount() {
    this.$store.commit('auth/userLocalSettings', { ...this.$store.getters['auth/userLocalSettings'], dash: { tab: this.tab, showMap: this.showMap } })
    clearInterval(this.polling)
  },
  methods: {
    pollData() {
      this.loadData()
      this.polling = setInterval(() => {
        this.loadData(false)
      }, 15000)
    },
    loadData(showLoader = true) {
      if (showLoader) {
        this.loading.allData = true
      }
      this.items.forEach((v) => {
        before[v.ISO] = {
          Android: {
            AllUsersWithSeasonPass: Number.parseInt(v.Data.Android.AllUsersWithSeasonPass),
            AllUsers: Number.parseInt(v.Data.Android.AllUsers),
            ActiveUsersLast24: Number.parseInt(v.Data.Android.ActiveUsersLast24),
            ActiveUsersLast1min: Number.parseInt(v.Data.Android.ActiveUsersLast1min),
            PayingUsersLast24h: Number.parseInt(v.Data.Android.PayingUsersLast24h),
            NewUsersLast24h: Number.parseInt(v.Data.Android.NewUsersLast24h),
            PaidLast24h: Number.parseInt(v.Data.Android.PaidLast24h),
            PayingUsers: Number.parseInt(v.Data.Android.PayingUsers)
          },
          Ios: {
            AllUsersWithSeasonPass: Number.parseInt(v.Data.Ios.AllUsersWithSeasonPass),
            AllUsers: Number.parseInt(v.Data.Ios.AllUsers),
            ActiveUsersLast24: Number.parseInt(v.Data.Ios.ActiveUsersLast24),
            ActiveUsersLast1min: Number.parseInt(v.Data.Ios.ActiveUsersLast1min),
            PayingUsersLast24h: Number.parseInt(v.Data.Ios.PayingUsersLast24h),
            NewUsersLast24h: Number.parseInt(v.Data.Ios.NewUsersLast24h),
            PaidLast24h: Number.parseInt(v.Data.Ios.PaidLast24h),
            PayingUsers: Number.parseInt(v.Data.Ios.PayingUsers)
          }
        }
      })

      const requests = []
      Object.values(this.envsUrls).forEach((envUrl) => {
        requests.push(this.$axios.get(`${envUrl}/admin/api/mainpage/globalstats/`))
      })
      Promise.allSettled(requests).then((results) => {
        const data = {}
        results.forEach((result) => {
          if (result.status === 'rejected') {
            this.$message({
              message: result?.reason || 'connection failed',
              type: 'warning',
              offset: 40
            })
          } else {
            result.value.data.rows.forEach((el) => {
              data[el.ISO] = this.mergeWithSum(el, data?.[el.ISO] || {})
            })
          }
        })
        this.items = Object.values(data)
        if (showLoader) {
          this.loading.allData = false
        }
      })
    },
    changeTab(tab) {
      this.tab = -1
      setTimeout(() => {
        this.tab = tab
      }, 200)
    },
    onSortChange(ev) {
      if (ev.order === null) {
        this.defSort = { prop: this.sortCol, order: 'descending' }
      } else {
        this.defSort.prop = ev.prop
        this.defSort.order = ev.order
      }
    },
    mergeWithSum: (arr1, arr2) => {
      return mergeWith({}, arr1, arr2, (objValue, srcValue) => {
        if (isNumber(srcValue) && isNumber(objValue)) {
          return srcValue + objValue
        }
        return undefined
      })
    }
  }
}
</script>

<template>
  <div
    class="gs-related-py-lg gs-related-px-lg"
    style="min-width: 1000px"
  >
    <div>
      <el-button-group class="ml-4">
        <el-button
          :class="[tab === 0 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(0)"
        >
          <icon-ify
            icon="mdi:earth"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
        <el-button
          :class="[tab === 1 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(1)"
        >
          <icon-ify
            icon="material-symbols:android"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
        <el-button
          :class="[tab === 2 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(2)"
        >
          <icon-ify
            icon="mdi:apple"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
      </el-button-group>
      <el-button
        class="ml-8"
        :class="[showMap ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
        @click="showMap = !showMap"
      >
        <icon-ify
          icon="mdi:map-marker"
          class="gs-scaled-icon-md text-black"
        />
      </el-button>
    </div>
    <el-collapse-transition>
      <div
        v-if="showMap"
        v-loading="!!loading.allData"
        class="gs-related-py-lg gs-related-px-lg"
      >
        <AmChartWorldMap
          class="h-[600px] min-h-[80vh] w-full"
          :map-data="mapData"
        />
      </div>
    </el-collapse-transition>
    <div class="gs-related-py-lg gs-related-px-lg">
      <transition
        name="el-fade-in"
        mode="out-in"
      >
        <div v-if="tab >= 0">
          <el-table
            v-loading="!!loading.allData"
            :data="finalItems"
            class="gs-scaled gs-v-loading"
            :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
            style="min-width: 100%"
            :height="$windowHeight * 0.85"
            header-cell-class-name="border-b-4 border-neutral-50 px-0 gs-cell-padding-mini"
            :default-sort="defSort"
            size="small"
            @sort-change="onSortChange"
          >
            <template
              v-for="col in cols"
              :key="col"
            >
              <!-- Country -->
              <el-table-column
                v-if="col === 'Country'"
                prop="country"
                label="Country"
                class-name="whitespace-nowrap"
                :min-width="200"
                fixed
              >
                <template #default="{ row }">
                  <span
                    :class="row.class"
                    class="whitespace-nowrap"
                  >{{ row.country || 'Unknown' }}</span>
                </template>
              </el-table-column>
              <!-- ActiveUsersLast24 -->
              <el-table-column
                v-if="col === 'ActiveUsersLast24'"
                prop="ActiveUsersLast24"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Active Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.ActiveUsersLast24.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.ActiveUsersLast24New && row.ActiveUsersLast24"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.ActiveUsersLast24New > 0 ? 'success' : 'danger'"
                  >
                    {{ row.ActiveUsersLast24New }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- ActiveUsersLast1min -->
              <el-table-column
                v-if="col === 'ActiveUsersLast1min'"
                prop="ActiveUsersLast1min"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Active Users last 1min
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.ActiveUsersLast1min.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.ActiveUsersLast1minNew && row.ActiveUsersLast1min"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.ActiveUsersLast1minNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.ActiveUsersLast1minNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PayingUsers -->
              <el-table-column
                v-if="col === 'PayingUsers'"
                prop="PayingUsers"
                :min-width="40 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paying <br>Users
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PayingUsers.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PayingUsersNew && row.PayingUsers"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PayingUsersNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PayingUsersNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- AllUsersWithSeasonPass -->
              <el-table-column
                v-if="col === 'AllUsersWithSeasonPass'"
                prop="AllUsersWithSeasonPass"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Users With Pass
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.AllUsersWithSeasonPass.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.AllUsersNew && row.AllUsersWithSeasonPass"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.AllUsersNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.AllUsersNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- AllUsers -->
              <el-table-column
                v-if="col === 'AllUsers'"
                prop="AllUsers"
                :min-width="30 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    All <br>Users
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.AllUsers.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.AllUsersNew && row.AllUsers"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.AllUsersNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.AllUsersNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PayingUsersLast24h -->
              <el-table-column
                v-if="col === 'PayingUsersLast24h'"
                prop="PayingUsersLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paying Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PayingUsersLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PayingUsersLast24hNew && row.PayingUsersLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PayingUsersLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PayingUsersLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- NewUsersLast24h -->
              <el-table-column
                v-if="col === 'NewUsersLast24h'"
                prop="NewUsersLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    New Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.NewUsersLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.NewUsersLast24hNew && row.NewUsersLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.NewUsersLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.NewUsersLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PaidLast24h -->
              <el-table-column
                v-if="col === 'PaidLast24h'"
                prop="PaidLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paid last<br>24h($)
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PaidLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PaidLast24hNew && row.PaidLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PaidLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PaidLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
