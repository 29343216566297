<script>
import { inject /* , ref, computed */ } from 'vue'
import dayjs from 'dayjs'

export default {
  name: 'LogsDialog',
  setup() {
    const crudDetails = inject('crudDetails')

    const renderApiRows = ({ data }) => {
      data.logs = data.logs || []
      data?.logs.forEach((log) => {
        log.Date = dayjs(log.Date).format('YYYY-MM-DD HH:mm:ss')
        log.Type = 'UPDATE'
        if (log?.Before === null) {
          log.Type = 'INSERT'
        } else if (log?.Changes === null) {
          log.Type = 'DELETE'
        }
      })
      return (
        data?.logs
          .filter((log) => {
            if (log.Changes && Object.keys(log.Changes).length === 1 && log.Changes.Modified) {
              return false
            }
            return true
          })
          .sort((a, b) => dayjs(b.Date) - dayjs(a.Date)) || []
      )
    }

    return {
      crudDetails,
      renderApiRows
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="crudDetails.logsDialog"
    class="small"
    align-center
    :width="$windowWidth < 640 ? '95%' : '80%'"
  >
    <template #header>
      <div class="break-all">
        {{ `Logs ${crudDetails.metaData.subtitle}` }}
      </div>
    </template>
    <template #default>
      <div class="px-4">
        <filtering-table
          v-if="crudDetails.logsDialog"
          index-rows-column
          height="75vh"
          max-height="75vh"
          header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
          cell-class-name="gs-col-padding-mini"
          fit
          hide-refresh-btn
          api="entity/logs"
          :api-prefix="crudDetails.apiPrefix"
          :proxy="crudDetails.proxy"
          :api-params="{
            entity: crudDetails.crudEntity,
            id: crudDetails?.externalData?.item?.ID || crudDetails?.form?.ID || crudDetails.$route.params?.id,
            env: crudDetails.$route?.query?.env || crudDetails?.apiEnv
          }"
          :columns="{
            Date: { width: 200, sortable: true, filterField: true, type: 'string' },
            User: {
              width: 250,
              sortable: true,
              filterField: true,
              type: 'string',
              elAttr: { formatter: (log) => log?.Meta?.admin_user || '-' }
            },
            Type: { width: 100, sortable: true, filterField: true, type: 'string' },
            Changes: {
              sortable: true,
              filterField: true,
              type: 'string',
              elAttr: {
                formatter: (log) =>
                  Object.entries(log?.Changes || {})
                    .map(([key, val]) => `${key}: ${val}`)
                    .join(' '),
                minWidth: 600
              }
            }
          }"
          :render-api-rows="renderApiRows"
        >
          <template #cell_Changes="{ row }">
            <el-scrollbar max-height="100">
              <div class="my-1 flex flex-wrap gap-y-1.5">
                <template
                  v-for="(value, field) in row?.Changes || {}"
                  :key="field"
                >
                  <el-tag
                    v-if="field !== 'Modified'"
                    class="gs-font-scaled gs-height-related-sm mx-0.5 px-1 leading-4 transition"
                    type="info"
                    size="small"
                  >
                    <span class="font-related-sm">
                      <b>{{ field }}:</b>
                      {{ value }}
                    </span>
                  </el-tag>
                </template>
              </div>
            </el-scrollbar>
          </template>
        </filtering-table>
      </div>
    </template>
  </el-dialog>
</template>
