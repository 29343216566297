<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldsSwitcher',
  mixins: [BasicProps]
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    component="el-switch"
    inline-prompt
    active-text="Y"
    inactive-text="N"
    inactive-color="#d4d4d4"
    active-color="#13ce66"
    :class-component="[classComponent ? classComponent : 'ml-5']"
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
