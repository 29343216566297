const Settings = {
  usables: {
    battle_event: { color: '#ffebf9', label: 'battle event' },
    event: { color: '#ffe4b0', label: 'event' },
    event_materials: { color: '#f6f7be', label: 'event materials' },
    reset: { color: '#cbf9ce', label: 'event reset' },
    others_options: { color: undefined, label: 'no type' }
  },
  giftboxes: {
    battle_event: { color: '#ffebf9', label: 'battle event' },
    event: { color: '#ffe4b0', label: 'event' },
    event_keys: { color: '#f6f7be', label: 'event keys' },
    gifts: { color: '#cbf9ce', label: 'gifts' },
    materials: { color: '#cef9f2', label: 'materials' },
    tickets: { color: '#c9c2ff', label: 'tickets' },
    others_options: { color: undefined, label: 'no type' }
  }
}

function SetRowColorByTypeItem(settingsKey) {
  return ({ row }) => {
    if (Settings?.[settingsKey]?.[row?.ApType]?.color) {
      return {
        backgroundColor: Settings[settingsKey][row.ApType].color
      }
    }
    return undefined
  }
}

export { Settings, SetRowColorByTypeItem }
