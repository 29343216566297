<script>
import { autoFillGraphic } from '@/composition/autoFillGraphic'

export default {
  name: 'SkinDetailsPage',
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'skin'
        itemData.ApIsSearchable = true
        itemData.SortingOrder = 0
      }
      return itemData
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    enable-relations
    doc-key="items-skin"
  >
    <template #form="{ form }">
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          @change="autoFillGraphic($event, form)"
        />
        <!-- Graphic body -->
        <crud-field-selector-files
          api-field-name="GraphicBody"
          required
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
        <!-- Raw item graphic -->
        <crud-field-selector-files
          api-field-name="RawGraphic"
          label="Raw item graphic"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Sorting order -->
        <crud-field-number
          api-field-name="SortingOrder"
          :max="1000000"
        />
        <!-- Slot -->
        <crud-field-select
          api-field-name="Slot"
          options-enum="enums.WearableSlot"
        />
        <!-- Skin type -->
        <crud-field-select
          api-field-name="SkinType"
          options-enum="enums.SkinType"
        />
        <!-- Unlocked by -->
        <crud-field-selector-items api-field-name="UnlockedBy" />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          required
        />
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
