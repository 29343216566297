<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'QuestEventRankingRewardsSelector',
  components: {
    DynamicSelector,
    EventRankingDetailsPage: defineAsyncComponent(() => import('@/pages/quests/event-ranking-rewards/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Quest event ranking reward'"
    :columns-settings="{
      Labels: {},
      Name: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="quests/event-ranking-rewards"
    :edit-model-route-name="filterMode ? '' : 'event-ranking-rewards-details'"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <EventRankingDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
