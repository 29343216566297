<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectActiveBoostFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcOptions, buffAssetsOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcOptions,
      buffAssetsOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'activeboost',
      'target-team': 'enemies',
      'duration': 0,
      'buff-asset': 'no_icon',
      'value-min': 0,
      'value-max': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="ActiveBoost_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="ActiveBoost_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- duration -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.duration"
          label="Duration"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ActiveBoost_Duration"
          disable-doc-settings
        />
      </div>
      <!-- buff-asset -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.buff-asset"
          label="Buff asset"
          slim
          :clearable="false"
          :options="buffAssetsOptions"
          doc-field-name="ActiveBoost_Buff_asset"
          disable-doc-settings
        />
      </div>
      <!-- ch_balls -->
      <div class="w-64 p-3">
        <crud-field-slot
          api-field-name="effectRow.data.ch_balls"
          label="Balls"
          doc-field-name="ActiveBoost_Balls"
          disable-doc-settings
        >
          <template #default>
            <el-checkbox-group v-model="form.effectRow.data.ch_balls">
              <table class="w-full">
                <tr>
                  <td>
                    <el-checkbox :label="1">
                      1-ball
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="2">
                      2-ball
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="4">
                      4-ball
                    </el-checkbox>
                  </td>
                </tr>
              </table>
            </el-checkbox-group>
          </template>
        </crud-field-slot>
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <div class="col-w-3" />
      <!-- value-min -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.value-min"
          label="Value min"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ActiveBoost_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- value-max -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.value-max"
          label="Value max"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ActiveBoost_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-value"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="ActiveBoost_Increment_per_level"
          disable-doc-settings
        />
      </div>
      <!-- ch_colors -->
      <div class="w-64 p-3">
        <crud-field-slot
          api-field-name="effectRow.data.ch_colors"
          label="Colors"
          doc-field-name="ActiveBoost_Colors"
          disable-doc-settings
        >
          <template #default>
            <el-checkbox-group v-model="form.effectRow.data.ch_colors">
              <table class="w-full">
                <tr>
                  <td>
                    <el-checkbox :label="1">
                      blue
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="2">
                      white
                    </el-checkbox>
                  </td>
                  <td>
                    <el-checkbox :label="4">
                      red
                    </el-checkbox>
                  </td>
                </tr>
              </table>
            </el-checkbox-group>
          </template>
        </crud-field-slot>
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
