<script>
import { inject, ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'SyncErrorsDialog',
  props: {
    detailsPage: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const crudParent = props.detailsPage ? inject('crudDetails') : inject('crudTable')
    const loading = ref({})
    const treeData = ref([])
    const renderTreeData = (data) => {
      loading.value.treeData = true
      const grouped = {}
      data.forEach((row) => {
        if (!grouped[row.entity]) {
          grouped[row.entity] = {
            label: row.entity,
            children: {}
          }
        }
        const route = app.$utils.getRouteByEntity(row.entity, true)
        grouped[row.entity].children[row.id] = {
          row,
          label: row.name || `${row.id}`,
          entity: row.entity,
          route: route ? { name: route.name, params: { id: row.id } } : null
        }
      })

      treeData.value = Object.entries(grouped).map(([, entity]) => {
        entity.children = Object.entries(entity.children).map(([, row]) => {
          return row
        })

        return entity
      })
      loading.value.treeData = false
    }

    const cancelSync = () => {
      crudParent.syncErrorsDialog = false
      if (props.detailsPage) {
        crudParent.syncErrorsDetails = {
          sync_error: [],
          syncForced: false
        }
      } else {
        crudParent.fetchData()
        crudParent.syncErrorsDetails = {
          sync_error: [],
          action: '',
          items: []
        }
      }
    }

    const syncForcedCrudTable = (syncRefers) => {
      const requests = []
      crudParent.syncErrorsDetails.items.forEach((data) => {
        if (syncRefers) {
          data.force_references = true
          loading.value.syncForced = true
        } else {
          data.skip_references = true
          loading.value.syncForcedSkip = true
        }
        requests.push(app.$axios.post(`${crudParent.apiPrefix}merge/versioned/`, data))
      })
      Promise.allSettled(requests).then((result) => {
        if (result.status === 'rejected') {
          app.$utils.catchError(result?.reason || result)
        } else {
          crudParent.fetchData()
          app.$message({
            message: 'data synchronized',
            type: 'success',
            offset: 40
          })
        }
        loading.value.syncForced = false
        loading.value.syncForcedSkip = false
        if (syncRefers) {
          loading.value.syncForced = true
        } else {
          loading.value.syncForcedSkip = true
        }

        cancelSync()
      })
    }

    const syncForcedCrudDetails = (syncRefers) => {
      crudParent.syncErrorsDetails.syncForced = true
      crudParent.syncErrorsDetails.syncRefers = syncRefers
      if (syncRefers) {
        loading.value.syncForced = true
      } else {
        loading.value.syncForcedSkip = true
      }
      crudParent.crudSaveAction()
    }

    const syncForcedCaller = (syncRefers) => {
      if (props.detailsPage) {
        syncForcedCrudDetails(syncRefers)
      } else {
        syncForcedCrudTable(syncRefers)
      }
    }

    renderTreeData(crudParent.syncErrorsDetails.sync_error || [])
    return {
      crudParent,
      loading,
      treeData,
      syncForcedCaller,
      cancelSync
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="crudParent.syncErrorsDialog"
    class="small"
    :width="$windowWidth < 640 ? '95%' : '60%'"
  >
    <template #header>
      <div class="gs-font-scaled font-related-xxl flex items-center">
        <icon-ify
          icon="bx:error"
          class="mr-4 inline-block h-14 w-14 text-amber-600"
        />
        <div>
          <template v-if="crudParent?.multiEntity">
            For the following entities sync blocked because of relations with only dev found.
            <div class="font-related-xss italic text-gray-400">
              Entities without eny errors (per entity) have been synchronized.
            </div>
          </template>
          <template v-else>
            Sync blocked - relations with only dev found
          </template>
        </div>
      </div>
    </template>
    <template #default>
      <el-scrollbar
        class="gs-scaled mt-4 px-4"
        height="60vh"
      >
        <el-tree
          v-loading="loading.treeData"
          :data="treeData"
          :props="{ children: 'children', label: 'label' }"
          class="mb-8"
          style="min-height: 400px"
        >
          <template #default="{ data }">
            <template v-if="!!data.children">
              {{ data.label }}
            </template>
            <template v-else>
              <router-link
                v-if="data.route"
                :to="$utils.bindStaticParams(data.route)"
                target="_blank"
                class="gs-user-font text-blue-500 hover:text-blue-600"
              >
                {{ data.label }}
              </router-link>
              <template v-else>
                {{ data.label }}
              </template>
            </template>
          </template>
        </el-tree>
      </el-scrollbar>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="gs-height-related-xl gs-btn-outlined-neutral"
          size="small"
          @click="cancelSync"
        >Cancel sync</el-button>
        <el-button
          class="gs-height-related-xl gs-btn-primary"
          size="small"
          :loading="loading.syncForcedSkip"
          @click="syncForcedCaller(false)"
        >
          Continue and don't sync found references
        </el-button>
        <el-button
          class="gs-height-related-xl gs-btn-danger"
          size="small"
          :loading="loading.syncForced"
          @click="syncForcedCaller(true)"
        >
          Continue and sync found references
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
