<script>
export default {
  name: 'FieldsCol',
  props: {
    span: {
      type: Number,
      default: 24
    },
    xs: {
      type: [Number, Boolean],
      default: undefined
    },
    sm: {
      type: [Number, Boolean],
      default: undefined
    },
    offset: {
      type: Number,
      default: undefined
    },
    gutter: {
      type: Number,
      default: 40
    },
    justify: {
      type: String,
      default: undefined
    },
    colBreak: {
      type: Boolean,
      default: false
    },
    mobileBreak: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="contents">
    <el-col
      :span="span"
      :xs="xs === true ? 24 : xs"
      :sm="sm === true ? 24 : sm"
      :offset="offset"
      v-bind="$attrs"
    >
      <el-row
        :gutter="gutter"
        :justify="justify"
      >
        <slot />
      </el-row>
    </el-col>
    <el-col v-if="colBreak" />
    <el-col
      v-if="mobileBreak"
      class="sm:hidden"
    />
  </div>
</template>
