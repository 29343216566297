<script setup>
import { userRightRoles } from '!/composition/utilities'

const assessRight = userRightRoles.admin
</script>

<template>
  <crud-table
    :default-visible-columns="['Email']"
    api="settings/admin-users"
    api-item="settings/admin-user"
    :versioned="false"
    :access-right="assessRight"
  />
</template>
