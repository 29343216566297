import md5 from 'md5'
import { userRightRoles } from '!/composition/utilities'

const FetchData = {
  data() {
    return {
      rowsApi: [],
      colsApi: {},
      loadingCrud: undefined,
      isAccess: undefined,
      fetchDataResponse: undefined,
      IndexGroupedBy: {}
    }
  },
  computed: {
    tableLoader() {
      return this.loadingCrud || this.loading
    },
    crudHash() {
      return md5(this.apiPrefix + this.api + this.$route.name)
    },
    crudGroupSpanMethod() {
      if (this.groupBy) {
        return this.groupSpanMethod
      }
      return undefined
    }
  },
  methods: {
    crudUpdateQueryParams() {
      const paginationParams = this.getPaginationParams()
      const sortingParams = this.getSortingParams()
      this.updateCrudRoteQuery(paginationParams, sortingParams, this.filterParams.all, this.searchParams.all)
    },
    fetchData(onFetchCallBack = null) {
      this.isAccess = this.$utils.checkRights(this.accessRight || userRightRoles.editor)
      if (!this.isAccess) {
        return this.$notify({
          title: 'No permission',
          type: 'warning',
          customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
          message: 'access denied'
        })
      }

      if (this.api) {
        this.loadingCrud = true
        if (this.selectMode) {
          this.setSelectedRow(null)
        }
        const paginationParams = this.getPaginationParams()
        const sortingParams = this.getSortingParams()
        this.updateCrudRoteQuery(paginationParams, sortingParams, this.filterParams.all, this.searchParams.all)
        const params = {
          ...this.proxyRequestParams,
          ...(this.apiPagination ? paginationParams : {}),
          ...sortingParams,
          ...(this.apiFiltering ? this.filterParams.all : {}),
          ...this.searchParams.all,
          ...this.staticApiParams,
          columns: this.currentTableVisibleColumnsNames.join(',')
        }
        if (this.crudSelectIdsLength && this.crudSelectIdsMode) {
          params.selected_ids = Object.keys(this.crudSelectIds)
        }
        const proxy = { url: '' }
        if (this.proxyRequestParams?.uri) {
          proxy.url =
            (this.proxyRequestParams?.envsUrls
              ? this.proxyRequestParams.envsUrls[params.env]
              : this.$store.getters['auth/envs'][params.env]) + this.proxyRequestParams.uri
          delete params.uri
          delete params.envsUrls
          params.json = 1
        }
        this.$axios(proxy.url ? proxy.url : `${this.apiPrefix + this.api}/`, { params })
          .then(({ data }) => {
            const fetchData = this.renderLoadedData(data || {})
            if (this.$slots?.table) {
              this.total = fetchData?.total || 0
              this.fetchDataResponse = fetchData
              return false
            }
            this.colsApi = fetchData?.columns || {}
            const notExistColumns = []
            const crudCols = this.$utils.cloneDeep({ ID: 'uint', ...this.colsApi, ...this.columns })
            this.defaultVisibleColumns.forEach((colName) => {
              if (!crudCols[colName]) {
                notExistColumns.push(colName)
              }
            })
            if (notExistColumns.length) {
              this.$notify({
                title: 'Error',
                type: 'warning',
                customClass: 'child-inherit-colors bg-teal-50 text-red-500 z-[999999]',
                duration: 9000,
                dangerouslyUseHTMLString: true,
                message: `<div class="text-left">Default columns: <b>"${notExistColumns.join(
                  ','
                )}"</b> have not been found. Please report to developer.</div>`
              })
            }
            this.total = fetchData?.total || 0
            this.groupByFetchData(fetchData)
            if (this.versioned) {
              this.maxId = fetchData?.max_id || null
              const rendered = this.renderVersionedData(
                fetchData?.items || [],
                fetchData?.children || {},
                fetchData?.children_map || {}
              )
              this.children = rendered.children || {}
              this.childrenMap = rendered.childrenMap || {}
              this.rowsApi = rendered.items || []
            } else {
              this.rowsApi = fetchData?.items || []
            }
            if (this.selectMode) {
              this.setSelectedRow(this.findRowBySelectionId(this.currentSelected.ID))
            }
          })
          .catch(this.$utils.catchError)
          .then(() => {
            this.loadingCrud = false
            if (typeof onFetchCallBack === 'function') {
              onFetchCallBack()
            }
            this.calculateTopTableHeight()
          })
      } else {
        const paginationParams = this.getPaginationParams()
        const sortingParams = this.getSortingParams()
        this.updateCrudRoteQuery(paginationParams, sortingParams, this.filterParams.all, this.searchParams.all)
        this.$emit('fetchData', {
          params: { ...this.filterParams.all, ...this.searchParams.all },
          pagination: this.pagination,
          sorting: sortingParams
        })
      }
    },
    updateCrudStorage(key, val, save = true) {
      if (this.selectMode) {
        return false
      }
      this.$store.commit('main/updateCrudStorage', [this.crudHash, { [key]: val }])
      if (save) {
        this.$store.dispatch('main/saveCrudStorage', this.$store.getters['main/crudStorage'])
      }
    },
    getCrudStorage(key = null) {
      if (this.selectMode) {
        return null
      }
      return key
        ? this.$store.getters['main/crudStorage']?.[this.crudHash]?.[key]
        : this.$store.getters['main/crudStorage']?.[this.crudHash]
    },
    updateSessionCrudStorage(key, val) {
      if (this.selectMode) {
        return false
      }
      this.$store.commit('session/updateCrudStorage', [this.crudHash, { [key]: val }])
    },
    getSessionCrudStorage(key = null) {
      if (this.selectMode) {
        return null
      }
      return key
        ? this.$store.getters['session/crudStorage']?.[this.crudHash]?.[key]
        : this.$store.getters['session/crudStorage']?.[this.crudHash]
    },
    updateCrudRoteQuery(paginationParams, sortingParams, filterParams = undefined, searchParams = undefined) {
      if (this.selectMode) {
        return false
      }
      const queryParams = { ...(this.$route?.query || {}) }
      queryParams.c_pag = paginationParams
      queryParams.c_sor = sortingParams
      queryParams.c_fil = filterParams
      queryParams.c_sea = searchParams
      this.$router.push({
        query: queryParams
      })
    },
    groupSpanMethod({ row, columnIndex }) {
      if (row?._nested?.parent) {
        const offsetActionColumn =
          this.actionColSettings && this.$store.getters['auth/userLocalSettings']?.actionColum === 'left' ? 1 : 0
        const offsetMultiSelectColumn = this.crudSettings.crudSelectIdsMode ? 1 : 0
        if (columnIndex === 0 + offsetActionColumn) {
          return {
            rowspan: 1,
            colspan:
              this.tableVisibleColumns.length +
              1 +
              ((this.$slots?.expand_row || this.versioned) && !this.selectMode ? 2 : 0) +
              (this.actionColSettings ? 1 : 0) -
              offsetActionColumn +
              offsetMultiSelectColumn
          }
        } else if (columnIndex >= 1 + offsetActionColumn) {
          return { rowspan: 0, colspan: 0 }
        }
      }
    },
    toggleGroupedRows(groupIndex) {
      if (groupIndex !== undefined) {
        const groupedRows = this.$refs.crudTable.$el.querySelectorAll(`table tr.gs-nested-${groupIndex}`)
        if (groupedRows.length) {
          groupedRows.forEach((el) => {
            el.classList.toggle('gs-expanded')
          })
        }
      }
    },
    groupByFetchData(fetchData) {
      if (this.groupBy) {
        fetchData.items.sort((a, b) => {
          if (a[this.groupBy] < b[this.groupBy]) {
            return -1
          }
          if (a[this.groupBy] > b[this.groupBy]) {
            return 1
          }
          return 0
        })
        const groupedItems = []
        const groupsParent = {}
        fetchData.items.forEach((row) => {
          const group = row[this.groupBy]
          if (this.IndexGroupedBy[group] === undefined) {
            this.IndexGroupedBy.length = (this.IndexGroupedBy.length || 0) + 1
            this.IndexGroupedBy[group] = this.IndexGroupedBy.length
          }
          if (groupsParent[group] === undefined) {
            groupsParent[group] = { _nested: { groupedVal: group, counter: 0, parent: true, parentIndex: null }, Versioned: {} }
            groupedItems.push(groupsParent[group])
            groupsParent[group]._nested.parentIndex = groupedItems.length
          }
          ++groupsParent[group]._nested.counter
          row._nested = { groupedVal: group, parentIndex: groupsParent[group]._nested.parentIndex }
          groupedItems.push(row)
        })
        fetchData.items = groupedItems
      }
    }
  }
}

export { FetchData }
