<script>
import CrudFieldLangSelect from '!/components/forms/crud-fields/CrudFieldLangSelect.vue'
import EnvSelector from '!/components/proxy/EnvSelector.vue'
import { userRightRoles } from '!/composition/utilities'

export default {
  components: { EnvSelector, CrudFieldLangSelect },
  data() {
    return {
      userRightRoles,
      envApi: '',
      env: 'dev',
      ws: null,
      initRequestId: 0,
      loginInput: '',
      consoleInput: '',
      tokenInput: '',
      versionInput: '',
      platformInput: 'Ios',
      countryCodeInput: '',
      langInput: 'EN',
      jsonWidth: '*',
      loggedName: ''
    }
  },
  mounted() {
    // todo - block inputs
    if (!this.$utils.checkRights(userRightRoles.clientConsoleProd)) {
      this.$notify({
        title: 'No permission',
        type: 'warning',
        customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
        message: 'access denied'
      })
    }
    const token = localStorage.getItem('clientconsole-token')
    const name = localStorage.getItem('clientconsole-name')
    const server = localStorage.getItem('clientconsole-logged-on-server') // env
    if (typeof token != 'undefined' && typeof server != 'undefined') {
      this.loggedName = name
    }
  },
  created() {
    const token = localStorage.getItem('clientconsole-token')
    const name = localStorage.getItem('clientconsole-name')
    const lang = localStorage.getItem('clientconsole-language')
    const onServer = localStorage.getItem('clientconsole-logged-on-server') // env
    if (token) {
      this.tokenInput = token
    }
    if (name) {
      this.loginInput = name
    }
    if (lang) {
      this.langInput = lang.toUpperCase()
    }
    if (onServer) {
      this.env = onServer
    }

    const envApi = this.$store.getters['auth/envs']?.[this.env]
    if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
      this.envApi = envApi
    }
    this.initWebsocket()
  },
  methods: {
    changeEnv() {
      const envApi = this.$store.getters['auth/envs']?.[this.env]
      this.envApi = envApi
      localStorage.setItem('clientconsole-server', this.envApi)
      localStorage.setItem('clientconsole-logged-on-server', this.env)
      this.initWebsocket()
    },
    disconnect() {
      this.ws?.close?.()
    },
    reconnect() {
      this.disconnect()
      this.ws.openSocket()
      this.appendLog('<pre><b>[ WEBSOCKET CREATED ]</b></pre>')
    },
    sendCommand(obj) {
      // obj additionalData = {}
      if (!this.ws) {
        return false
      }

      obj.token = this.tokenInput
      obj.version = this.versionInput
      obj.platform = this.platformInput
      obj.country_code = this.countryCodeInput
      obj.lang = this.langInput.toLowerCase()
      obj.access_token = 'c58116569d9y52d71ub474x25058h83ste25dbxa'

      const jsonString = JSON.stringify(obj)
      this.appendLog(`<pre style='background:#42e41c36;'><b>SENT</b>      ${jsonString}</pre>`)
      this.ws.send(obj)
    },
    appendLog(msg) {
      const log = document.getElementById('log')
      const doScroll = log.scrollTop > log.scrollHeight - log.clientHeight - 1
      const item = document.createElement('span')
      item.innerHTML = msg
      log.appendChild(item)
      if (doScroll) {
        log.scrollTop = log.scrollHeight - log.clientHeight
      }
    },
    clearLog() {
      document.getElementById('log').innerHTML = ''
    },
    initWebsocket() {
      this.$axios.get('/admin/api/environments/').then((data) => {
        const envUrl = data.data.environments[this.env]
        if (!envUrl) {
          this.$message.error('Env url not defined!')
          return
        }

        this.ws = this.$socket(envUrl)
        this.ws.openSocket()

        this.appendLog('<pre><b>[ WEBSOCKET CREATED ]</b></pre>')

        this.ws.onOpen(() => {
          // this.initRequestId = this.sendTask('logged/player/init');
          this.initRequestId = 0
        })
        this.ws.onResponse((ev) => {
          if (ev) {
            if (typeof ev.data === 'undefined') {
              return
            }
            let txt = ev.data
            const arrJsons = txt.split('\0')

            for (let idx = 0; idx < arrJsons.length; idx++) {
              // jeżeli odpowiedź nie jest jsonem, to musi być danymi statycznymi, które mają nasz własny format
              // na początku będzie: ###STATICDATA###timestamp
              if ((arrJsons.length >= 2 && arrJsons[idx][0] === '#') || arrJsons[idx][1] === '#') {
                // nie wiem czemu, ale w [1] to jest, powinno być w [0]
                txt = arrJsons[idx]
              } else {
                let j
                try {
                  j = JSON.parse(arrJsons[idx])
                } catch (_e) {
                  j = JSON.parse('{}')
                }

                let w = this.jsonWidth
                if (w === '*') {
                  // adaptive format length
                  const sz = arrJsons[idx].length
                  if (sz < 200)
                    w = 60
                  else if (sz < 300)
                    w = 80
                  else if (sz < 500)
                    w = 160
                  else if (sz < 1000)
                    w = 200
                  else if (sz < 2000)
                    w = 400
                  else w = 600
                }
                if (w === '**') {
                  // adaptive format length
                  const sz = arrJsons[idx].length
                  if (sz < 200)
                    w = 60
                  else if (sz < 300)
                    w = 80
                  else if (sz < 500)
                    w = 250
                  else if (sz < 1000)
                    w = 400
                  else if (sz < 2000)
                    w = 700
                  else w = 1000
                }
                if (w === '***') {
                  // adaptive format length
                  const sz = arrJsons[idx].length
                  if (sz < 200)
                    w = 120
                  else if (sz < 300)
                    w = 180
                  else if (sz < 500)
                    w = 350
                  else if (sz < 1000)
                    w = 600
                  else if (sz < 2000)
                    w = 900
                  else w = 1500
                }

                if (!(w > 10 && w < 10000)) {
                  w = 160
                }

                txt = this.stringify(j, { maxLength: w, indent: 3 })
              }

              this.appendLog(`<pre><b>INCOMMING </b>${txt}</pre>`)

              if (typeof j.login != 'undefined' && typeof j.login.token != 'undefined') {
                this.tokenInput = j.login.token
                this.loginInput = j.player.name
                this.loggedName = j.player.name
                localStorage.setItem('clientconsole-token', j.login.token)
                localStorage.setItem('clientconsole-name', j.player.name)
                localStorage.setItem('clientconsole-language', this.langInput.toLowerCase())
                localStorage.setItem('clientconsole-server', this.envApi)
                localStorage.setItem('clientconsole-logged-on-server', this.env)
              }
            }
          }
        })
      })
    },
    stringify(passedObj, options) {
      const stringOrChar = /("(?:[^\\"]|\\.)*")|[:,]/g

      options = options || {}
      const indent = JSON.stringify([1], undefined, options.indent === undefined ? 2 : options.indent).slice(2, -3)
      const maxLength = indent === '' ? Infinity : options.maxLength === undefined ? 80 : options.maxLength
      let replacer = options.replacer

      return (function _stringify(obj, currentIndent, reserved) {
        // prettier-ignore
        let end, index, items, key, keyPart, keys, length, nextIndent, prettified, start, value

        if (typeof obj === 'object' && !Array.isArray(obj)) {
          obj = sortObject(obj)
        }

        if (obj && typeof obj.toJSON === 'function') {
          obj = obj.toJSON()
        }

        const string = JSON.stringify(obj, replacer)

        if (string === undefined) {
          return string
        }

        length = maxLength - currentIndent.length - reserved

        if (string.length <= length) {
          prettified = string.replace(stringOrChar, (match, stringLiteral) => {
            return stringLiteral || `${match} `
          })
          if (prettified.length <= length) {
            return prettified
          }
        }

        if (replacer != null) {
          obj = JSON.parse(string)
          replacer = undefined
        }

        if (typeof obj === 'object' && obj !== null) {
          nextIndent = currentIndent + indent
          items = []
          index = 0

          if (Array.isArray(obj)) {
            start = '['
            end = ']'
            length = obj.length
            for (; index < length; index++) {
              items.push(_stringify(obj[index], nextIndent, index === length - 1 ? 0 : 1) || 'null')
            }
          } else {
            start = '{'
            end = '}'
            keys = Object.keys(obj)
            length = keys.length
            for (; index < length; index++) {
              key = keys[index]
              keyPart = `${JSON.stringify(key)}: `
              value = _stringify(obj[key], nextIndent, keyPart.length + (index === length - 1 ? 0 : 1))
              if (value !== undefined) {
                items.push(keyPart + value)
              }
            }
          }

          if (items.length > 0) {
            return [start, indent + items.join(`,\n${nextIndent}`), end].join(`\n${currentIndent}`)
          }
        }

        return string
      })(passedObj, '', 0)
    }
  }
}

function sortObject(o) {
  const sorted = {}
  let key
  const a = []

  for (key in o) {
    if (Object.prototype.hasOwnProperty.call(o, key)) {
      a.push(key)
    }
  }

  a.sort()

  for (key = 0; key < a.length; key++) {
    sorted[a[key]] = o[a[key]]
  }
  return sorted
}
</script>

<template>
  <crud-table
    :top-actions="false"
    :versioned="false"
    hide-columns-selector
    hide-refresh-btn
    disable-pagination
    disable-multi-select-rows
  >
    <template #table>
      <div class="relative mb-16 px-4">
        <el-form
          :model="$data"
          label-position="top"
          style="min-width: 1000px"
        >
          <el-row>
            <fields-col
              id="top-bar"
              :xs="8"
              :sm="24"
              mobile-break
            >
              <crud-field-slot
                :sm="3"
                label="Env"
              >
                <EnvSelector
                  v-model:value-field="env"
                  :disabled="!envApi"
                  label=" "
                  @change="changeEnv"
                />
              </crud-field-slot>
              <CrudFieldLangSelect
                :sm="4"
                api-field-name="langInput"
                :form="$data"
                :external-errors="{}"
                :visible-options="['EN', 'FR', 'DE', 'IT', 'ES', 'PT', 'RU', 'ZH', 'CM', 'PL']"
                label="Lang"
                :disabled="!envApi"
              />
              <crud-field-text
                id="param-login"
                :sm="4"
                api-field-name="loginInput"
                name="param-login"
                label="Login"
                :form="$data"
                :external-errors="{}"
                required
              />
              <crud-field-slot
                :sm="4"
                empty-label
                class="text-right sm:text-left"
              >
                <el-button
                  type="primary"
                  size="small"
                  @click="sendCommand({ task: 'logged/player/console', param: `login ${loginInput}` })"
                >
                  Do login
                </el-button>
              </crud-field-slot>
              <crud-field-slot :sm="8">
                <div
                  v-if="loggedName"
                  id="logged-name"
                  class="text-right"
                >
                  You are logged to API as:
                  <b>{{ loggedName }}</b>
                  @{{ env }}
                </div>
              </crud-field-slot>
            </fields-col>
            <fields-col
              :xs="8"
              :sm="24"
              mobile-break
            >
              <crud-field-text
                id="param-console"
                :sm="4"
                api-field-name="consoleInput"
                name="param-console"
                label="Command"
                :form="$data"
                :external-errors="{}"
              />
              <crud-field-text
                id="param-token"
                :sm="4"
                api-field-name="tokenInput"
                name="param-token"
                label="Token"
                :form="$data"
                :external-errors="{}"
              />
              <crud-field-text
                id="param-globalcountrycode"
                :sm="4"
                api-field-name="countryCodeInput"
                name="param-globalcountrycode"
                label="Country"
                :form="$data"
                :external-errors="{}"
              />
              <crud-field-text
                id="param-globalversion"
                :sm="4"
                api-field-name="versionInput"
                name="param-globalversion"
                label="Version"
                :form="$data"
                :external-errors="{}"
              />
              <crud-field-text
                id="param-platform"
                :sm="4"
                api-field-name="platformInput"
                name="param-platform"
                label="Platform"
                :form="$data"
                :external-errors="{}"
              />
              <crud-field-text
                id="param-json-width"
                :sm="4"
                api-field-name="jsonWidth"
                name="param-json-width"
                label="JSON width"
                :form="$data"
                :external-errors="{}"
              />
            </fields-col>
            <fields-col
              :xs="8"
              :sm="24"
              mobile-break
              justify="end"
              class="pr-5 py-5"
            >
              <el-button
                type="primary"
                size="small"
                @click="sendCommand({ task: 'logged/player/console', param: consoleInput })"
              >
                Send
              </el-button>
              <el-button
                size="small"
                @click="reconnect()"
              >
                Reconnect
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="clearLog()"
              >
                Clear
              </el-button>
            </fields-col>
            <fields-col
              :xs="8"
              :sm="24"
              class="px-5"
            >
              <div
                id="log"
                class="main w-full"
              />
            </fields-col>
          </el-row>
        </el-form>
      </div>
    </template>
  </crud-table>
</template>

<style>
#top-bar {
  background: #e8e8e8;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 10px;
}
#logged-name {
  font-size: 12px;
}
.main {
  margin-top: 10px;
  font-size: 12px;
  padding: 0 10px;
  background: #fff;
  overflow: auto;
  white-space: pre-wrap;
  min-height: 500px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
</style>
