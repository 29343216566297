<script>
import { Bottom, Close, Delete, Select, Top, ZoomOut } from '@element-plus/icons-vue'

export default {
  name: 'CrudSelectorsColumns',
  components: { ZoomOut, Close, Top, Bottom },
  props: {},
  setup() {
    return {
      icons: {
        Select,
        Delete
      }
    }
  },
  data() {
    return {
      selectorRows: [],
      newPreset: '',
      presetsRows: {}
    }
  },
  computed: {
    crudTable() {
      return this.$parent
    },
    tableSelectorOptions() {
      const orderedList = []
      const checkedColumns = {}
      this.crudTable.selectorVisibleColumns.columns.forEach((colApiName, index) => {
        checkedColumns[colApiName] = true
        if (this.crudTable.tableColumns?.[colApiName]) {
          orderedList.push({
            label: this.crudTable.tableColumns?.[colApiName]?.elAttr?.label,
            value: this.crudTable.tableColumns?.[colApiName]?.elAttr?.prop,
            up: index > 0,
            down: index < this.crudTable.selectorVisibleColumns.columns.length - 1
          })
        }
      })
      this.crudTable.tableColumnsArr.forEach((colConfig) => {
        if (!checkedColumns[colConfig.elAttr.prop]) {
          orderedList.push({ label: colConfig.elAttr.label, value: colConfig.elAttr.prop })
        }
      })
      this.updateCheckedColumnsMap(checkedColumns)
      return orderedList
    },
    validNewPreset() {
      return this.newPreset.length > 3 && this.newPreset.length < 21
    },
    orderedPresetsRows() {
      return [
        { name: 'default' },
        ...Object.keys(this.presetsRows)
          .map((name) => {
            return { name }
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
      ]
    },
    isDefaultColumnsLayout() {
      let isDefault = this.crudTable.visibleColumns.length === this.crudTable.selectorVisibleColumns.columns.length
      if (isDefault) {
        this.crudTable.selectorVisibleColumns.columns.some((colName, index) => {
          if (this.crudTable.visibleColumns?.[index] !== colName) {
            isDefault = false
            return true
          }
          return false
        })
      }
      return isDefault
    }
  },
  watch: {
    tableSelectorOptions: {
      handler() {
        this.$utils.nextLoopEvent().then(() => {
          // required because tableSelectorOptions computed change onToggleColumn arrguments before call this method;
          this.selectorRows = [...this.tableSelectorOptions]
        })
      },
      deep: true,
      intermediate: true
    }
  },
  created() {
    this.presetsRows = { ...(this.crudTable.getCrudStorage('selectorColumnsPresets') || {}) }
  },
  methods: {
    handleCheckAllChange() {
      if (this.crudTable.selectorVisibleColumns.columns.length) {
        this.crudTable.selectorVisibleColumns.columns = []
        this.crudTable.selectorVisibleColumns.all = false
      } else {
        this.crudTable.selectorVisibleColumns.columns = this.tableSelectorOptions.map((option) => {
          return option.value
        })
        this.crudTable.selectorVisibleColumns.all = true
      }
      this.crudTable.fetchData()
    },
    revertToDefaultColumns() {
      this.crudTable.selectorVisibleColumns.columns = [...this.crudTable.visibleColumns]
      this.crudTable.fetchData()
    },
    moveColumn(index, up = true) {
      if (this.crudTable.loadingCrud && !this.crudTable.selectorVisibleColumns.columns?.[index]) {
        return false
      }
      this.crudTable.loadingCrud = true
      const list = [...this.crudTable.selectorVisibleColumns.columns]
      const newIndex = up ? index - 1 : index + 1
      const prevItem = list[newIndex]
      list[newIndex] = list[index]
      list[index] = prevItem
      this.crudTable.selectorVisibleColumns.columns = list
      this.crudTable.loadingCrud = false
    },
    onToggleColumn(checkboxVal, apiNameCol) {
      if (!checkboxVal && this.crudTable.crudFilters[this.crudTable.getFilterFieldName(apiNameCol)] !== undefined) {
        this.crudTable.crudFilters[this.crudTable.getFilterFieldName(apiNameCol)] = undefined
      }
      this.crudTable.fetchData()
    },
    updateCheckedColumnsMap(map) {
      this.crudTable.selectorVisibleColumns.checkedColumns = map
    },
    addNewPreset() {
      if (this.validNewPreset) {
        if (this.newPreset === 'default') {
          this.$notify({
            title: 'Not allowed',
            type: 'warning',
            position: 'bottom-right',
            customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
            message: 'the "default" preset cannot be modified'
          })
        } else {
          this.presetsRows[this.newPreset] = [...this.crudTable.selectorVisibleColumns.columns]
          const presets = this.crudTable.getCrudStorage('selectorColumnsPresets') || {}
          presets[this.newPreset] = [...this.crudTable.selectorVisibleColumns.columns]
          this.crudTable.updateCrudStorage('selectorColumnsPresets', presets)
          this.newPreset = ''
        }
      }
    },
    deletePreset(name) {
      if (name === 'default') {
        return false
      }
      const presets = this.crudTable.getCrudStorage('selectorColumnsPresets') || {}
      delete presets[name]
      this.crudTable.updateCrudStorage('selectorColumnsPresets', presets)
      delete this.presetsRows[name]
    },
    choosePreset(name) {
      if (name === 'default') {
        this.revertToDefaultColumns()
      } else {
        this.crudTable.selectorVisibleColumns.columns = [...this.presetsRows[name]]
        this.crudTable.fetchData()
      }

      this.$refs?.columnsSelector?.hide?.()
    },
    setAsInitiated() {
      this.crudTable.selectorVisibleColumns.initiated = true
    }
  }
}
</script>

<template>
  <el-popover
    ref="columnsSelector"
    :show-arrow="false"
    popper-class="p-0"
    trigger="click"
    :width="400 * crudTable.userScaledRatioWidth"
    placement="top"
    :offset="-25"
  >
    <!-- button of columns selector -->
    <template #reference>
      <el-button
        type=""
        class="ml-1 h-auto p-0"
        :class="{
          'gs-btn-text-neutral-light':
            !crudTable.selectorVisibleColumns.columns?.length && !crudTable.filterParams.inHiddenColumnsLength,
          'gs-btn-text-primary':
            crudTable.selectorVisibleColumns.columns?.length && !crudTable.filterParams.inHiddenColumnsLength,
          'gs-btn-text-danger': crudTable.filterParams.inHiddenColumnsLength
        }"
        @click="setAsInitiated"
      >
        <el-tooltip
          effect="light"
          placement="top"
          :show-after="600"
        >
          <template #content>
            Column management
            <div
              v-if="crudTable.filterParams.inHiddenColumnsLength"
              class="text-xs font-light italic text-rose-400"
            >
              (not visible filter)
            </div>
          </template>
          <div>
            <icon-ify
              icon="fluent:column-triple-edit-24-regular"
              class="gs-scaled-icon-xs"
            />
          </div>
        </el-tooltip>
      </el-button>
    </template>
    <!-- content menu of columns selector -->
    <div class="w-full">
      <div class="mb-1 flex items-center justify-start pl-2 pr-4 pt-2 text-xs relative">
        <!-- toggle all -->
        <el-checkbox
          v-model="crudTable.selectorVisibleColumns.all"
          :indeterminate="
            !!crudTable.selectorVisibleColumns.columns?.length
              && crudTable.selectorVisibleColumns.columns?.length < tableSelectorOptions?.length
          "
          @change="handleCheckAllChange"
        >
          <span
            class="text-neutral-400 hover:text-cyan-500"
            :class="{
              'text-xs': crudTable.userScaledRatio < 1.1,
              'text-sm': crudTable.userScaledRatio > 1
            }"
          >
            toggle all
          </span>
        </el-checkbox>
        <!--  set default -->
        <el-popover
          v-if="!crudTable.selectMode"
          popper-class="p-0"
          trigger="click"
          :width="400"
          :teleported="false"
          @hide="newPreset = ''"
        >
          <template #reference>
            <span>
              <el-tooltip
                effect="light"
                placement="top"
                :show-after="600"
                content="Columns presets"
              >
                <el-button
                  v-if="crudTable.visibleColumns?.length"
                  size="small"
                  class="gs-btn-outlined-warning-light ml-3 px-1"
                >
                  <div>
                    <icon-ify
                      icon="fluent:column-triple-edit-24-regular"
                      class="gs-scaled-icon-xs"
                    />
                  </div>
                </el-button>
              </el-tooltip>
            </span>
          </template>
          <div class="gs-font-scaled p-2">
            <div class="font-related-sm flex items-center justify-between px-2 py-2.5 text-neutral-500">
              <div>Columns presets</div>
              <div class="font-related-sm gs-expand-input-wrapper flex flex-1 items-center justify-end pl-4">
                <el-input
                  v-model="newPreset"
                  :size="crudTable.userScaledSize"
                  :minlength="4"
                  :maxlength="20"
                  show-word-limit
                  clearable
                  @keyup.enter="addNewPreset"
                />
                <el-button
                  :size="crudTable.userScaledSize"
                  :icon="icons.Select"
                  :disabled="!validNewPreset"
                  class="-translate-x-px rounded-l-none px-1.5"
                  :class="{
                    'gs-btn-outlined-primary ': validNewPreset,
                    'gs-btn-outlined-neutral': !validNewPreset
                  }"
                  @click="addNewPreset"
                />
              </div>
            </div>
            <el-divider class="my-1" />
            <el-table
              :data="orderedPresetsRows"
              :max-height="Math.min($windowHeight - 130, 250)"
              style="width: 100%"
              cell-class-name="py-1 border-0"
              :show-header="false"
            >
              <el-table-column>
                <template #default="{ row }">
                  <div
                    class="flex cursor-pointer items-center justify-between pb-0.5"
                    @click="choosePreset(row.name)"
                  >
                    <div class="gs-font-scaled">
                      {{ row.name }}
                    </div>
                    <div>
                      <el-popconfirm
                        v-if="row.name !== 'default'"
                        title="Are you sure ?"
                        :teleported="false"
                        placemant="top"
                        @cancel.stop
                        @confirm.stop="deletePreset(row.name)"
                      >
                        <template #reference>
                          <el-button
                            class="gs-btn-outlined-danger-light ml-0.5 px-0"
                            :icon="icons.Delete"
                            size="small"
                            @click.stop
                          />
                        </template>
                      </el-popconfirm>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-popover>
        <span v-else>
          <el-tooltip
            effect="light"
            placement="top"
            :show-after="600"
            content="Set default columns"
          >
            <el-button
              v-if="crudTable.visibleColumns?.length"
              size="small"
              class="gs-btn-outlined-warning-light ml-3 px-1"
              @click="choosePreset('default')"
            >
              <div>
                <icon-ify
                  icon="fluent:column-triple-edit-24-regular"
                  class="gs-scaled-icon-xs"
                />
              </div>
            </el-button>
          </el-tooltip>
        </span>
        <!-- close menu btn -->
        <el-button
          type=""
          class="gs-btn-text-neutral-light absolute right-4 bg-transparent p-0"
          @click="$refs?.columnsSelector?.hide?.()"
        >
          <el-icon class="gs-scaled-icon-xss">
            <Close />
          </el-icon>
        </el-button>
        <span
          v-if="!isDefaultColumnsLayout && !crudTable.selectMode"
          class="absolute -bottom-0.5 left-2 font-related-xss text-neutral-400"
        >
          current columns layout is customized (<span
            class="cursor-pointer hover:text-blue-400 active:text-blue-500"
            @click="choosePreset('default')"
          >revert</span>)
        </span>
      </div>
      <el-divider class="my-0.5" />

      <el-checkbox-group v-model="crudTable.selectorVisibleColumns.columns">
        <el-table
          :data="selectorRows"
          :max-height="Math.min($windowHeight - 100, 350)"
          style="width: 100%"
          class="gs-nowrap-table"
          cell-class-name="py-1 border-0"
          :show-header="false"
        >
          <!-- label (name of column) -->
          <el-table-column min-width="380">
            <template #default="{ row: item }">
              <el-checkbox
                :value="item.value"
                style="position: initial"
                class="h-auto w-full py-0.5 font-normal"
                @change="onToggleColumn($event, item.value)"
              >
                <!-- label (name column) -->
                <div style="width: 100%">
                  {{ item.label }}
                </div>
              </el-checkbox>
            </template>
          </el-table-column>
          <!-- fixed column with actions -->
          <el-table-column
            fixed="right"
            class-name="bg-sky-50 bg-opacity-75 backdrop-blur-sm border-l-2 border-sky-100"
            :width="50 * crudTable.userScaledRatio"
          >
            <template #default="{ row: item, $index: index }">
              <!-- arrows up/down -->
              <div
                v-if="item.up || item.down"
                class="flex items-center justify-start pr-2"
                @click.stop
              >
                <div>
                  <el-button
                    class="gs-btn-outlined-primary-light ml-0.5 px-0 text-sky-800"
                    :class="{ invisible: !item.up }"
                    size="small"
                    @click.stop="moveColumn(index)"
                  >
                    <el-icon class="gs-scaled-icon-xss">
                      <Top />
                    </el-icon>
                  </el-button>
                </div>
                <div>
                  <el-button
                    class="gs-btn-outlined-primary-light ml-1 px-0 text-sky-800"
                    :class="{ invisible: !item.down }"
                    size="small"
                    @click.stop="moveColumn(index, false)"
                  >
                    <el-icon class="gs-scaled-icon-xss">
                      <Bottom />
                    </el-icon>
                  </el-button>
                </div>
              </div>
              <!-- hidden filters notice -->
              <div
                v-if="crudTable.filterParams.inHiddenColumns?.[item.value]"
                class="flex items-center justify-center pr-2"
              >
                <el-icon
                  :size="15"
                  class="text-rose-400"
                >
                  <el-tooltip
                    content="not visible filter of column"
                    effect="light"
                    :show-after="600"
                  >
                    <template #content>
                      <span class="text-rose-400">not visible filter of column</span>
                    </template>
                    <ZoomOut />
                  </el-tooltip>
                </el-icon>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-checkbox-group>
    </div>
  </el-popover>
</template>
