<script>
export default {
  name: 'FieldsTable',
  inheritAttrs: false
}
</script>

<template>
  <el-scrollbar class="gs-fields-table w-full">
    <table v-bind="$attrs">
      <slot name="tHeader" />
      <slot name="tBody" />
    </table>
  </el-scrollbar>
</template>

<style lang="postcss">
.gs-fields-table {
  table-layout: fixed;
  font-size: 13px;
  th {
    padding: 8px 12px;
    color: #909399;
    font-weight: 700;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    border-bottom: 1px solid #ebeef5;
  }
  td {
    vertical-align: top;
    border-bottom: 1px solid #ebeef5;
    > div {
      padding: 8px 12px;
      color: #606266;
    }
  }
}
</style>
