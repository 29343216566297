<script>
import { inject } from 'vue'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'

export default {
  name: 'TopSearchCheckBoxes',
  components: { FieldsLabel },
  props: {
    hideArchived: {
      type: Boolean,
      default: false
    },
    hideNotSearchAble: {
      type: Boolean,
      default: false
    },
    hideAboveLegendary: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const searchFields = inject('searchFields')
    return {
      searchFields
    }
  },
  computed: {
    parent() {
      return this.$parent
    }
  }
}
</script>

<template>
  <FieldsLabel
    class="ml-1.5"
    label="TopSearchCheckBoxes"
    class-label="invisible"
    :full-width="false"
  >
    <div class="flex flex-row">
      <gs-checkbox
        v-if="!hideArchived"
        v-model="searchFields.with_archived"
        label="with archived"
        true-value="on"
      />
      <gs-checkbox
        v-if="!hideNotSearchAble"
        v-model="searchFields.with_not_searchable"
        label="with not searchable"
        true-value="on"
      />
      <gs-checkbox
        v-if="!hideAboveLegendary"
        v-model="searchFields.above_legendary"
        label="Above legendary"
        true-value="on"
      />
    </div>
  </FieldsLabel>
</template>

<style scoped></style>
