<script>
import { globalProperties } from '!/plugins/utilities'

// slots: icon, icon_collapsed, default
export default {
  name: 'PageMenuItem',
  props: {
    router: {
      // name of route or router object
      type: [String, Object],
      default: ''
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    iconRight: {
      type: [String, Boolean],
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    classIcon: {
      type: String,
      default: ''
    },
    draggable: {
      type: Boolean,
      default: false
    },
    forcedId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    itemRouter() {
      if (this.router === '') {
        return {}
      }
      const searchedRoute = typeof this.router === 'string' ? { name: this.router } : this.router
      if (this.forcedId) {
        searchedRoute.params = { id: this.forcedId }
      }
      const itemRouter = this.$utils.getFullRouteData(searchedRoute)
      if (itemRouter?.meta?.query) {
        itemRouter.query = { ...itemRouter.query, ...itemRouter.meta.query }
      }
      return itemRouter
    },
    visible() {
      const accessRole = this.itemRouter?.meta?.accessRole
      if (accessRole) {
        return globalProperties.$utils.checkRights(accessRole)
      }
      return true
    },
    isDetails() {
      return this.$route?.meta?.parentName && this.$route?.meta?.parentName === this.itemRouter.name
    }
  },
  created() {},
  methods: {
    onClickLink() {
      if (this.$windowWidth < 640) {
        this.$store.commit('main/togglePageMenu')
      }
    }
  }
}
</script>

<template>
  <el-menu-item
    v-if="visible"
    :route="itemRouter || {}"
    :index="itemRouter?.path || ''"
    class="pr-3"
    :draggable="draggable"
    :class="{ 'cursor-ns-resize': draggable, 'text-green-400': isDetails }"
  >
    <template v-if="$store.getters['main/pageMenuCollapse']">
      <template v-if="$slots?.icon_collapsed">
        <el-icon class="gs-scaled-icon-lg">
          <slot name="icon_collapsed" />
        </el-icon>
      </template>
      <template v-else>
        <el-icon
          v-if="icon"
          :class="[classIcon]"
          class="gs-scaled-icon-lg"
        >
          <icon-ify
            :icon="icon"
            class="h-full w-full"
          />
        </el-icon>
        <el-icon
          v-else-if="icon !== false"
          class="gs-menu-no-icon gs-scaled-icon-lg"
        />
      </template>
    </template>
    <template v-else>
      <template v-if="$slots?.icon">
        <el-icon class="gs-scaled-icon-lg">
          <slot name="icon" />
        </el-icon>
      </template>
      <template v-else>
        <el-icon
          v-if="icon"
          :class="[classIcon]"
          class="gs-scaled-icon-lg"
        >
          <icon-ify
            :icon="icon"
            class="h-full w-full"
          />
        </el-icon>
        <el-icon
          v-else-if="icon !== false"
          class="gs-scaled-icon-lg"
        />
      </template>
    </template>
    <template #title>
      <router-link
        :to="$utils.bindStaticParams(itemRouter || {})"
        class="gs-font-scaled flex h-full w-full grow items-center justify-between whitespace-normal leading-none"
        :class="{ 'cursor-ns-resize select-none': draggable }"
        @click.stop="onClickLink"
      >
        <div
          class="grow"
          style="max-width: 165px"
        >
          <slot name="default">
            <span class="font-related-lg">{{ title || itemRouter?.meta?.Title }}</span>
          </slot>
        </div>
        <div>
          <slot name="iconRight">
            <el-icon
              v-if="iconRight"
              :class="[classIcon]"
              class="gs-scaled-icon-lg"
            >
              <icon-ify
                :icon="iconRight"
                class="ml-1 h-full w-full"
              />
            </el-icon>
          </slot>
        </div>
      </router-link>
    </template>
  </el-menu-item>
</template>
