<script setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function renderInitItem(form) {
  if (!form?.ID) {
    form.SendAt = dayjs().utc().format('YYYY-MM-DDT00:00:00Z')
    form.IsProd = true
  }
  return form
}

function disableDate(date) {
  return date <= dayjs().add(-1, 'day')
}
</script>

<template>
  <crud-details-page
    api="tools/global-push-notification"
    disable-top-margin
    :versioned="false"
    :render-init-item="renderInitItem"
  >
    <template #form="{ form }">
      <crud-field-date-time
        :sm="8"
        api-field-name="SendAt"
        label="Send at (UTC)"
        :disabled-date="disableDate"
        required
      />
      <crud-field-textarea
        :sm="24"
        api-field-name="Message"
        required
      />
      <crud-field-switcher
        api-field-name="IsProd"
        doc-icon-class="-top-2 -right-2"
      />
      <template v-if="form.ID">
        <fields-col>
          <section-divider />
        </fields-col>
        <fields-col
          v-for="server in ['Prod']"
          :key="server"
          :sm="8"
        >
          <div class="m-2 w-full border p-2">
            <section-divider content-position="left">
              {{ server }}
            </section-divider>
            <el-descriptions
              :column="3"
              size="small"
              direction="vertical"
            >
              <el-descriptions-item label="Sent">
                <el-switch
                  size="small"
                  :model-value="form[`Sent${server}`] || false"
                  inline-prompt
                  active-text="Y"
                  inactive-text="N"
                  inactive-color="#d4d4d4"
                  active-color="#13ce66"
                />
              </el-descriptions-item>
              <el-descriptions-item label="Last id">
                {{ form[`LastId${server}`] || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="Sent counter">
                {{ form[`SentCounter${server}`] || 0 }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
