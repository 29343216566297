<script>
import CrudFieldSelectorItems from '@/components/forms/crud-fields/CrudFieldSelectorItems.vue'
import CrudFieldSelectorItemTagGroups from '@/components/forms/crud-fields/CrudFieldSelectorItemTagGroups.vue'

export default {
  components: { CrudFieldSelectorItems, CrudFieldSelectorItemTagGroups }
}
</script>

<template>
  <crud-details-page
    api="items/item-tag"
    disable-top-margin
    :versioned="false"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- GroupNo -->
        <CrudFieldSelectorItemTagGroups
          api-field-name="GroupNo"
          required
        />
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Item -->
        <CrudFieldSelectorItems
          api-field-name="Item"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
