import { convertArrayToCSV } from 'convert-array-to-csv'
import { get } from 'lodash'
import dayjs from 'dayjs'

const Versioned = {
  data() {
    return {
      maxId: null,
      children: {},
      childrenMap: {},
      dev2ProdSum: 0,
      toRemoveOnProdSum: 0,
      dev2ProdSync: {},
      syncErrorsDialog: false,
      syncErrorsDetails: {
        sync_error: [],
        action: '',
        items: []
      }
    }
  },
  computed: {
    dev2ProdSyncSelected() {
      return Object.values(this.dev2ProdSync).some(val => val)
    }
  },
  methods: {
    renderVersionedData(rows, children, childrenMap) {
      if (!this.versioned) {
        return rows
      }
      const items = []
      this.dev2ProdSum = 0
      this.toRemoveOnProdSum = 0
      const prevSync = {
        dev2ProdSync: { ...this.dev2ProdSync }
      }
      this.dev2ProdSync = {}
      rows.forEach((row) => {
        const rowChildrenMap = (this.multiEntity ? childrenMap?.[row?.Entity]?.[row?.ID] : childrenMap?.[row?.ID]) || []
        row.versions = {}
        row.children = [row]
        let childrenIsDev = row.Versioned.IsDev
        let childrenIsProd = row.Versioned.IsProd
        let dev2prod = (row.Versioned.IsDev && !row.Versioned.IsProd) || (!row.Versioned.IsDev && row.Versioned.IsProd) ? 1 : 0
        let ab = row.Versioned.ABTest ? 1 : 0
        if (rowChildrenMap && rowChildrenMap.length > 0) {
          rowChildrenMap.forEach((childID) => {
            const child = (this.multiEntity ? children?.[row?.Entity]?.[childID] : children[childID]) || {}
            child.RightSync = this.$store.getters['auth/userRights']?.editor
            child.RightAbTest = this.$store.getters['auth/userRights']?.editor
            row.children.push(child)
            childrenIsDev = child.Versioned.IsDev ? true : childrenIsDev
            childrenIsProd = child.Versioned.IsProd ? true : childrenIsProd
            dev2prod +=
              (child.Versioned.IsDev && !child.Versioned.IsProd) || (!child.Versioned.IsDev && child.Versioned.IsProd) ? 1 : 0
            ab += child.Versioned.ABTest ? 1 : 0
            if (child.Versioned.IsDev && !child.Versioned.IsProd) {
              this.dev2ProdSum += 1
              this.dev2ProdSync[this.$utils.uniqRowIndex(child.ID, child?.Entity, this.multiEntity)] = false
            }
          })
        } else {
          if (row.Versioned.IsDev && !row.Versioned.IsProd) {
            this.dev2ProdSum += 1
            this.dev2ProdSync[this.$utils.uniqRowIndex(row.ID, row?.Entity, this.multiEntity)] = false
          } else if (row.Versioned.IsProd && !row.Versioned.IsDev && !rowChildrenMap.length) {
            ++this.toRemoveOnProdSum
          }
        }
        row.versions = { dev2prod, ab }
        row.childrenEnvs = { childrenIsDev, childrenIsProd }
        row.RightSync = this.$store.getters['auth/userRights']?.editor
        row.RightAbTest = this.$store.getters['auth/userRights']?.editor
        if (
          (this.searchFieldsRefer?.dev || this.searchFieldsRefer?.dev2prod) &&
          !this.searchFieldsRefer?.prod &&
          (!row.Versioned.IsDev || (row.Versioned.IsDev && row.Versioned.IsProd)) &&
          row.children.length > 1
        ) {
          let devRow = row
          row.children.some((childRow) => {
            if (childRow.Versioned.IsDev && childRow.ID !== row.ID) {
              devRow = { ...row, ...childRow }
              return true
            }
            return false
          })
          items.push(devRow)
        } else {
          items.push(row)
        }
      })
      this.bindPrevSyncOnLoadData(prevSync)
      return { items, children, childrenMap }
    },
    copyMaxId() {
      this.$utils
        .copyToClipboard(this.maxId)
        .then(() => {
          this.$message({
            message: `Next id: ${this.maxId} copied to clipboard`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.$message({
            message: 'copy to clipboard failed',
            type: 'error',
            offset: 40
          })
        })
    },
    syncItems(action) {
      const items = []
      Object.entries({ ...this.dev2ProdSync }).forEach(([key, val]) => {
        if (val) {
          items.push(this.multiEntity ? key : Number.parseInt(key))
        }
      })
      this.$messageBox
        .confirm(`Do you really want to sync ${items.length} items?`, undefined, {
          confirmButtonText: 'Yes, sync',
          type: 'warning'
        })
        .then(() => {
          this.loadingActions[action] = true
          const requests = []
          const requestsData = []
          if (this.multiEntity) {
            const groupedItemsByEntity = {}
            items.forEach((item) => {
              const [id, entity] = item.split('__')
              if (!groupedItemsByEntity[entity]) {
                groupedItemsByEntity[entity] = []
              }
              groupedItemsByEntity[entity].push(Number.parseInt(id))
            })
            Object.entries(groupedItemsByEntity).forEach(([entity, items]) => {
              const data = {
                items,
                entity,
                action
              }
              requestsData.push(data)
              requests.push(this.$axios.post(`${this.apiPrefix}merge/versioned/`, data))
            })
          } else {
            const data = {
              items,
              entity: this.crudEntity,
              action
            }
            requestsData.push(data)
            requests.push(this.$axios.post(`${this.apiPrefix}merge/versioned/`, data))
          }
          Promise.allSettled(requests).then((results) => {
            let isError = false
            const syncEntities = []
            const syncErrorsSum = {
              sync_error: [],
              action,
              items: []
            }
            results.forEach((result, index) => {
              if (result.status === 'rejected') {
                const errorData = result?.reason?.response?.data
                isError = true
                if (errorData?.errors?.sync_error && errorData?.details?.sync_error?.length) {
                  syncErrorsSum.sync_error.push(...errorData.details.sync_error)
                  syncErrorsSum.items.push(requestsData[index])
                } else {
                  if (result?.reason !== false) {
                    this.$utils.catchError(result?.reason || result)
                  }
                }
              } else {
                syncEntities.push(requestsData[index]?.entity)
              }
            })
            if (isError) {
              this.syncErrorsDetails = {
                sync_error: syncErrorsSum.sync_error,
                action,
                items: syncErrorsSum.items
              }
              this.syncErrorsDialog = true
              if (syncEntities.length) {
                this.$message({
                  message: `Synchronized entities: ${syncEntities.join(', ')}.`,
                  type: 'success',
                  offset: 40,
                  duration: 6000
                })
              }
            } else {
              this.$message({
                message: 'data synchronized',
                type: 'success',
                offset: 40
              })
              this.syncErrorsDetails = {
                sync_error: [],
                action: '',
                items: []
              }
            }
            this.fetchData()
            this.loadingActions[action] = false
          })
        })
        .catch(() => {})
    },
    bindPrevSyncOnLoadData(prevSync) {
      if (this.dev2ProdSum) {
        Object.entries(prevSync.dev2ProdSync).forEach(([key, val]) => {
          if (val && this.dev2ProdSync[key] !== undefined) {
            this.dev2ProdSync[key] = true
          }
        })
      }
    },
    excelExportVersionedToFile(typeEnv) {
      if (!this.total || this.total > this.excelExportSettings.maxRows || !this.usedTopActions?.excelExport)
        return false
      this.loadingActions.excelExport = true
      let params = {}
      if (typeEnv === 'selected') {
        params = {
          columns: this.currentTableVisibleColumnsNames.join(','),
          ...this.staticApiParams,
          excel: 1,
          typeEnv,
          selected_ids: Object.keys(this.crudSelectIds)
        }
      } else {
        params = {
          ...this.getSortingParams(),
          ...this.filterParams.all,
          ...this.searchParams.all,
          columns: this.currentTableVisibleColumnsNames.join(','),
          ...this.staticApiParams,
          ...(this.crudSettings.crudSelectIdsMode && this.crudSelectIdsMode && this.crudSelectIdsLength
            ? { selected_ids: Object.keys(this.crudSelectIds) }
            : {}),
          excel: 1,
          typeEnv
        }
      }
      this.$axios
        .get(`${this.apiPrefix + this.api}/`, {
          params,
          responseType: 'blob'
        })
        .then((response) => {
          this.$utils.saveBlobToFile(response.data, `export_${this.crudEntity}_${dayjs().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`)
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loadingActions.excelExport = false
        })
    },
    excelExportVersioned(typeEnv) {
      if (!this.total || this.total > this.excelExportSettings.maxRows || !this.usedTopActions?.excelExport)
        return false
      this.loadingActions.excelExport = true
      const requests = []
      for (let offset = 0; offset * this.excelExportSettings.chunks < this.total; offset++) {
        if (typeEnv === 'selected') {
          requests.push(
            this.$axios.get(`${this.apiPrefix + this.api}/`, {
              params: {
                page: offset + 1,
                on_page: this.excelExportSettings.chunks,
                columns: this.currentTableVisibleColumnsNames.join(','),
                ...this.staticApiParams,
                selected_ids: Object.keys(this.crudSelectIds)
              }
            })
          )
        } else {
          requests.push(
            this.$axios.get(`${this.apiPrefix + this.api}/`, {
              params: {
                page: offset + 1,
                on_page: this.excelExportSettings.chunks,
                ...this.getSortingParams(),
                ...this.filterParams.all,
                ...this.searchParams.all,
                columns: this.currentTableVisibleColumnsNames.join(','),
                ...this.staticApiParams,
                ...(this.crudSettings.crudSelectIdsMode && this.crudSelectIdsMode && this.crudSelectIdsLength
                  ? { selected_ids: Object.keys(this.crudSelectIds) }
                  : {})
              }
            })
          )
        }
      }
      Promise.all(requests)
        .then((data) => {
          if (!data.length) {
            this.$message({
              message: 'no rows',
              type: 'warning',
              offset: 40
            })
          }
          const exportedRows = []
          data.forEach((resp) => {
            (resp?.data?.items || []).forEach((row) => {
              const rowWithChildren = [row]
              if (resp?.data?.children_map?.[row?.ID]) {
                resp.data.children_map[row?.ID].forEach((childId) => {
                  if (resp?.data?.children?.[childId]) {
                    rowWithChildren.push(resp.data.children[childId])
                  }
                })
              }
              rowWithChildren.forEach((child) => {
                if (typeEnv === 'dev' && !child?.Versioned?.IsDev) {
                  return
                }
                if (typeEnv === 'prod' && !child?.Versioned?.IsProd) {
                  return
                }
                const exportRow = [child?.[this.indexColumn]]
                this.tableVisibleColumns.forEach((col) => {
                  if (col.elAttr.prop === this.indexColumn) {
                    exportRow.push(child?.Versioned?.Root)
                    exportRow.push(child?.Versioned?.IsDev ? 1 : '')
                    exportRow.push(child?.Versioned?.IsProd ? 1 : '')
                    exportRow.push(child?.Versioned?.ABTest ? child?.Versioned?.ABTest?.ID : '')
                    exportRow.push(child?.Versioned?.ABTestGroup || '')
                    return false
                  }

                  let colValue = get(child, col.elAttr.prop)
                  if (Number(colValue) === Number.parseInt(colValue) || Math.abs(Number(colValue) % 1) > 0) {
                    colValue = colValue.toString().replace('.', ',')
                  }
                  if (col.typeValueField === 'clientstring' && colValue) {
                    const parts = colValue.AdminLabel.split(':')
                    colValue = `${parts[0]}|${parts.slice(1).join(':').trimStart()}`
                  }
                  if (colValue !== undefined) {
                    exportRow.push(colValue)
                  } else {
                    exportRow.push('')
                  }
                })
                exportRow.forEach((value, index) => {
                  if (value?.ID) {
                    exportRow[index] = value.ID
                  }
                })
                exportedRows.push(exportRow)
              })
            })
          })
          const exportedHeaders = [`${this.indexColumn}.${this.crudEntity}`]
          this.tableVisibleColumns.forEach((col) => {
            const parts = col.elAttr.prop.split('.')
            if (parts.length > 0 && parts[1] === 'AdminLabel') {
              exportedHeaders.push(`${col.elAttr.prop}[PREVIEW]`)
            } else if (col.elAttr.prop === this.indexColumn) {
              exportedHeaders.push(...['[V]Root', '[V]Dev', '[V]Prod', '[V]AB', '[V]AB group'])
            } else {
              exportedHeaders.push(col.elAttr.prop)
            }
          })
          this.$utils
            .copyToClipboard(
              convertArrayToCSV(exportedRows, {
                header: exportedHeaders,
                separator: '\t'
              })
            )
            .then(() => {
              this.$message({
                message: 'copied to clipboard',
                type: 'success',
                offset: 40
              })
              this.loadingActions.excelExport = false
            })
            .catch(() => {
              this.$message({
                message: 'copy to clipboard failed',
                type: 'error',
                offset: 40
              })
              this.loadingActions.excelExport = false
            })
        })
        .catch((error) => {
          this.loadingActions.excelExport = false
          this.$utils.catchError(error)
        })
    }
  }
}

export { Versioned }
