<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'EffectConditionShieldFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { conditionsOptions, booleanOptions } = options
    const { initDefaultValues } = methods
    return {
      conditionsOptions,
      booleanOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      effectType: 'conditionshield',
      state: 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Type"
          :options="conditionsOptions"
          :clearable="false"
          slim
          style="max-width: 250px"
          doc-field-name="ConditionShield_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- state -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.state`"
          label="Shield state"
          slim
          :clearable="false"
          :options="booleanOptions"
          doc-field-name="ConditionShield_state"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
