<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'

export default {
  name: 'ItemsSelector',
  components: {
    DynamicSelector,
    FieldsLabel,
    ItemsGiftboxDetails: defineAsyncComponent(() => import('@/pages/items/items/details/GiftBoxDetails.vue')),
    ItemsGuildbannerDetails: defineAsyncComponent(() => import('@/pages/items/items/details/GuildBannerDetails.vue')),
    ItemsOrbDetails: defineAsyncComponent(() => import('@/pages/items/items/details/OrbDetails.vue')),
    ItemsPartDetails: defineAsyncComponent(() => import('@/pages/items/items/details/PartDetails.vue')),
    ItemsSkinDetails: defineAsyncComponent(() => import('@/pages/items/items/details/SkinDetails.vue')),
    ItemsTalentDetails: defineAsyncComponent(() => import('@/pages/items/items/details/TalentDetails.vue')),
    ItemsUsableDetails: defineAsyncComponent(() => import('@/pages/items/items/details/UsableDetails.vue')),
    ItemsWearableDetails: defineAsyncComponent(() => import('@/pages/items/items/details/WearableDetails.vue')),
    ItemsTags: defineAsyncComponent(() => import('@/pages/items/item-tags/IndexPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    disableEditModel: {
      type: Boolean,
      default: false
    },
    disableRelations: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    renderEditModalRouteName(row) {
      if (row?.Class === undefined) {
        return undefined
      }
      return `items-${row.Class}-details`
    },
    renderSelectionRow(row) {
      if (row?.Name) {
        return row?.Name
      }
      return null
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Items'"
    :width="860"
    :columns-settings="{
      Name: {},
      Labels: {},
      Class: { width: 170 }
    }"
    api="items/items"
    show-img-preview-column
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    :edit-model-route-name="filterMode ? '' : renderEditModalRouteName"
    :disable-edit-model="disableEditModel"
    :relations-route-name="filterMode || disableRelations ? '' : 'item-tags'"
  >
    <template #top="{ filters, isFullInit }">
      <FieldsLabel
        v-if="isFullInit"
        class="ml-1.5"
        label="TopSearchCheckBoxes"
        class-label="invisible"
      >
        <gs-checkbox
          v-model="filters.with_not_searchable"
          label="with not searchable"
          true-value="on"
          @click="filters.with_not_searchable = filters.with_not_searchable ? undefined : 'on'"
        />
        <gs-checkbox
          v-model="filters.above_legendary"
          label="above legendary"
          true-value="on"
          @click="filters.above_legendary = filters.above_legendary ? undefined : 'on'"
        />
      </FieldsLabel>
    </template>
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector, isFullInit }">
      <component
        :is="entityRoute.name"
        v-if="isFullInit"
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
    <template #relationsDialogContent="{ enabled, route, closeDialog, selectedRelation, changeRelation }">
      <ItemsTags
        v-if="enabled"
        :default-visible-columns="['Name', 'Label']"
        :route="route"
        select-mode
        :selected-row="{ Name: selectedRelation }"
        selected-row-index="Name"
        :columns-settings="{ ID: false, Name: { width: 200 } }"
        :static-api-params="{ grouped: 'on' }"
        hide-columns-selector
        :show-img-preview-column="false"
        @cancel-selection="closeDialog()"
        @change-selection="changeRelation(renderSelectionRow($event))"
      >
        <template #topLeft="{ searchFilters }">
          <el-tag
            v-if="!!selectedRelation"
            class="gs-font-scaled gs-height-related-sm cursor-pointer leading-4"
            size="small"
            type="warning"
            closable
            @close="changeRelation(null, false)"
            @click="searchFilters.ID = selectedRelation?.ID"
          >
            <div class="flex items-center">
              <el-icon class="gs-scaled-icon-xs mr-2">
                <icon-ify
                  icon="lucide:mouse-pointer-click"
                  class="h-full w-full"
                />
              </el-icon>
              <span>Selected item tag: <b>{{ selectedRelation }}</b></span>
            </div>
          </el-tag>
        </template>
        <template #bottomBarLeft>
          <el-button
            class="gs-height-related gs-font-scaled gs-btn-outlined-primary-neutral"
            @click="closeDialog()"
          >
            close
          </el-button>
          <el-button
            v-if="!!selectedRelation"
            class="gs-height-related gs-font-scaled gs-btn-outlined-danger"
            @click="changeRelation(null)"
          >
            remove relation
          </el-button>
        </template>
      </ItemsTags>
    </template>
  </DynamicSelector>
</template>
