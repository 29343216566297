<template>
  <crud-details-page
    api="settings/perk-description"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- PerkName -->
        <crud-field-select
          api-field-name="PerkName"
          options-enum="enums.PerksList"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Description -->
        <crud-field-selector-client-strings
          api-field-name="Description"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
