<script>
import { inject } from 'vue'
import PreviewFileInput from '!/components/forms/components/PreviewFileInput.vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'CrudFieldFile',
  components: { PreviewFileInput },
  mixins: [BasicProps],
  props: {
    uploadTitle: {
      type: String,
      default: undefined
    },
    downloadFileName: {
      type: String,
      default: ''
    },
    api: {
      type: String,
      default: '/admin/api/binary/upload/simple/'
    },
    apiData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const initForm = props.form ? {} : inject('initForm', {})
    const fieldNamePath = props.apiFieldName.split('.')
    const handleChange = (val) => {
      if (initForm?.value?.diffs) {
        if ((app.$utils.getByPath(initForm.value.form, fieldNamePath) || null) !== (val || null)) {
          initForm.value.diffs[props.apiFieldName] = true
        } else {
          delete initForm.value.diffs[props.apiFieldName]
        }
      }
    }
    return {
      handleChange
    }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <PreviewFileInput
          v-model="fieldModel"
          :upload-title="uploadTitle"
          :disabled="disabled"
          :download-file-name="downloadFileName"
          :api="api"
          :api-data="apiData"
          @update:model-value="handleChange"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
