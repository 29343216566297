<script>
export default {
  name: 'PageMenuLabel',
  props: {
    hideCollapsed: {
      type: Boolean,
      default: false
    },
    classCollapsed: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <el-menu-item-group class="label">
    <template #title>
      <span v-bind="$attrs"><slot name="default" /></span>
    </template>
    <div
      v-if="$store.getters['main/pageMenuCollapse'] && !hideCollapsed"
      :class="classCollapsed"
    >
      <slot name="collapsed">
        <el-divider class="build-in my-0.5" />
      </slot>
    </div>
  </el-menu-item-group>
</template>
