<script setup>
import { useStore } from 'vuex'
import { userRightRoles } from '!/composition/utilities'

function formatNumber(number) {
  if (!number) {
    return 0
  } else if (number * 1 >= 1000000) {
    return '>1MM'
  }
  return number?.toLocaleString?.()
}

const languages = useStore().getters['auth/userLanguages']

const languagesKeys = languages.map((lang) => {
  return `Keys${lang}`
})

const columns = {
  ID: 'uint',
  Keys: 'text',
  KeysRemarks: 'text',
  KeysEN: 'text',
  ...Object.fromEntries(
    languagesKeys.map((langKey) => {
      return [langKey, 'text']
    })
  )
}

const columnsSettings = {
  CreatedAt: { cellEdit: false, elAttr: { 'class-name': 'whitespace-nowrap' } },
  CreatedBy: { cellEdit: false, elAttr: { 'class-name': 'whitespace-nowrap' } },
  Keys: { header: 'Keys', filterField: false, cellEdit: false },
  KeysRemarks: { header: 'Remarks', filterField: false, cellEdit: false },
  KeysEN: { header: 'EN', filterField: false, cellEdit: false, width: 80, elAttr: { 'class-name': 'gs-td-padding-mini' } },
  ...Object.fromEntries(
    languagesKeys.map((langKey) => {
      return [langKey, { header: langKey.slice(-2).toUpperCase(), filterField: false, cellEdit: false, width: 80, elAttr: { 'class-name': 'gs-td-padding-mini' } }]
    })
  )
}
</script>

<template>
  <crud-table
    :default-visible-columns="['CreatedAt', 'Keys', 'KeysRemarks', ...languagesKeys]"
    :top-actions="{}"
    api="settings/translation-packs"
    api-item="settings/translation-pack"
    :versioned="false"
    :columns="columns"
    :columns-settings="columnsSettings"
    :actions-on-list="{
      crudDelete: { right: userRightRoles.translator }
    }"
  >
    <template #cell_Keys="{ row }">
      <el-link
        type="primary"
        class="gs-user-font whitespace-nowrap"
        :href="`/translations/client-strings?c_fil%5BTranslation%5D=${row.ID}`"
        target="_blank"
      >
        {{ row?.Keys?.toLocaleString?.() }}
      </el-link>
    </template>
    <template #cell_KeysRemarks="{ row }">
      <el-tooltip
        v-if="row.KeysRemarks"
        content="keys with remarks"
        effect="light"
        placement="top"
        :show-after="600"
      >
        <el-tag
          type="danger"
          class="gs-user-font gs-height-related-xs whitespace-nowrap"
        >
          {{ row.KeysRemarks }}
        </el-tag>
      </el-tooltip>
    </template>
    <template #cell_KeysEN="{ row }">
      <el-tooltip
        v-if="row.KeysEN"
        content="number of missing translations"
        effect="light"
        placement="top"
        :show-after="600"
      >
        <el-tag
          type="danger"
          class="gs-user-font gs-height-related-xs whitespace-nowrap"
        >
          {{ formatNumber(row?.KeysEN) }}
        </el-tag>
      </el-tooltip>
    </template>
    <template
      v-for="lang in languages"
      :key="lang"
      #[`cell_Keys${lang}`]="{ row }"
    >
      <el-tooltip
        v-if="row[`Keys${lang}`]"
        content="number of missing translations"
        effect="light"
        placement="top"
        :show-after="600"
      >
        <el-tag
          type="danger"
          class="gs-user-font gs-height-related-xs whitespace-nowrap"
        >
          {{ formatNumber(row?.[`Keys${lang}`]) }}
        </el-tag>
      </el-tooltip>
    </template>
  </crud-table>
</template>
