import { useCssVar, useFavicon, useTitle } from '@vueuse/core'
import { computed, watch } from 'vue'
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { DefaultMeta } from '@/settings/defaultMeta'

function setPageMeta(route) {
  useTitle((DefaultMeta?.TitlePrefix || '') + (DefaultMeta?.Title || route?.meta?.Title || route?.name) + (DefaultMeta?.TitleSuffix || ''))
  if (DefaultMeta?.FaviconFile || route?.meta?.FaviconFile) {
    useFavicon(DefaultMeta?.FaviconFile || route?.meta?.FaviconFile)
  }
}

export function setMeta() {
  const route = useRoute()
  const store = useStore()
  setPageMeta(route)
  useHead({
    bodyAttrs: {
      'gs-size': computed(() => store.getters['auth/userScaledSize'])
    }
  })
  watch(route, (val) => {
    setPageMeta(val)
  })
  const userFontSize = useCssVar('--gs-size-font', document.documentElement)
  watch(
    () => store.getters['auth/userScaledFontSize'],
    (val) => {
      userFontSize.value = val
    },
    { immediate: true }
  )
}
