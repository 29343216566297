<script setup>
</script>

<template>
  <crud-table
    :default-visible-columns="['Key', 'Value', 'HeroesCsv']"
    api="settings/client-hero-flags"
    api-item="settings/client-flag"
  />
</template>
