<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_3.png'
import TipImg4 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_4.png'
import TipImg5 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_5.png'
import TipImg6 from '!/pages/tools/ap-tips/tips/tip_8/tip_8_6.png'

export default {
  data() {
    return {
      srcList: [TipImg1, TipImg2, TipImg3, TipImg4, TipImg5, TipImg6]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      <b>Multiple duplication</b>
      <br>
      <span># Enables:</span>
      <br>
      - multi duplication rows; <br>
      - filtering multi duplicated rows (note: duplicate rows require editing to set dev/prod env and possibly unique field
      values; after doing this, duplicate rows are no longer marked as duplicated); <br>
      - includes the "duplicate field" option in the settings of the entities selectors documentation (does not apply to files
      selectors); <br>
      <span># Limitations</span>
      <br>
      - only for versioned; <br>
      - only when duplicate enabled; <br>
      <br>
      click on the screenshots to find out more
    </p>
  </div>
</template>

<style scoped></style>
