import { globalProperties as app } from '!/plugins/utilities'

function autoFillGraphic(graphic, form, fieldsMap = { sd: 'IconSd', hd: 'IconHd', prev: 'Preview', raw: 'RawGraphic', body: 'GraphicBody' }, params = {}, aliases = {}) {
  if (!graphic) {
    return false
  }
  app.$axios
    .post('/admin/api/files/autofill/', {
      name: graphic?.Name || null,
      ...params
    })
    .then(({ data }) => {
      const updated = [];
      ['avatars', 'layers'].forEach((field) => {
        if (data.graphics?.[field]) {
          Object.entries(data.graphics[field]).forEach(([key, value]) => {
            data.graphics[`${field.slice(0, -1)}${key}`] = value
          })
          delete data.graphics[field]
        }
      })
      Object.entries(data.graphics).forEach(([key, value]) => {
        if (value && value?.ID && fieldsMap[key] && form[fieldsMap[key]]?.ID !== value?.ID) {
          updated.push(aliases?.[key] || fieldsMap[key])
          form[fieldsMap[key]] = value
        }
      })
      if (updated.length) {
        app.$notify({
          title: 'Autofill graphic',
          type: 'info',
          message: `Updated: ${updated.join(', ')}.`,
          position: 'bottom-right',
          customClass: 'bg-amber-50 text-amber-600 child-inherit-colors',
          offset: 30,
          duration: 9000,
          showClose: true
        })
      }
    })
    .catch(app.$utils.catchError)
}

export { autoFillGraphic }
