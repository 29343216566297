<script>
import { ref } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'CrudFieldCountriesSelect',
  mixins: [BasicProps],
  props: {
    /** options used by selectors (array of {value: '', label: '',}); override built-in options */
    countriesOptions: {
      type: Array,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    /** an array of option values that should be visible only */
    visibleOptions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const selectorOptions = ref([])
    if (props.countriesOptions) {
      selectorOptions.value.push(...props.countriesOptions)
    } else {
      Object.entries(app.$utils.countries).forEach(([code, country]) => {
        selectorOptions.value.push({
          value: code.toUpperCase(),
          label: `${country.name} ${country?.emoji || ''}`
        })
      })
      if (props.visibleOptions.length) {
        const visible = {}
        props.visibleOptions.forEach((visibleCode) => {
          visible[visibleCode] = true
        })
        selectorOptions.value = selectorOptions.value.filter(el => !!visible?.[el.value])
      }
    }
    return {
      selectorOptions
    }
  }
}
</script>

<template>
  <crud-details-field
    component="el-select"
    v-bind="$props"
    :options="selectorOptions"
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
