<script setup></script>

<template>
  <crud-details-page
    api="talent-loots/talent-loot"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <fields-col :sm="5">
          <!-- Label -->
          <crud-field-text
            api-field-name="Label"
            required
          />
          <!-- Title -->
          <crud-field-text
            api-field-name="Title"
            required
          />
        </fields-col>
        <fields-col :sm="5">
          <!-- SmallGraphicId -->
          <crud-field-selector-files api-field-name="SmallGraphicId" />
          <!-- Priority -->
          <crud-field-number api-field-name="Priority" />
        </fields-col>
        <fields-col :sm="4">
          <!-- DrawsMin -->
          <crud-field-number
            api-field-name="DrawsMin"
            required
          />
          <!-- IsLimited -->
          <crud-field-switcher api-field-name="IsLimited" />
        </fields-col>
        <fields-col :sm="4">
          <!-- DrawsMax -->
          <crud-field-number
            api-field-name="DrawsMax"
            required
          />
          <!-- IsEvent -->
          <crud-field-switcher api-field-name="IsEvent" />
        </fields-col>
        <fields-col :sm="6">
          <!-- PromoItem -->
          <crud-field-selector-items
            api-field-name="PromoItem"
            label="Promoted item"
          />
          <!-- MegaDrawMultiplier -->
          <crud-field-number
            api-field-name="MegaDrawMultiplier"
            required
          />
        </fields-col>
      </fields-col>
      <fields-col
        v-for="i in 6"
        :key="i"
      >
        <fields-col>
          <section-divider>Threshold #{{ i }}</section-divider>
        </fields-col>
        <fields-col>
          <!-- ThReqDraws -->
          <crud-field-number
            :sm="3"
            :api-field-name="`Th${i}ReqDraws`"
            label="Draws"
          />
          <!-- ThPrice -->
          <crud-field-number
            :sm="3"
            :api-field-name="`Th${i}Price`"
            label="Price"
          />
          <!-- ThNormalMin -->
          <crud-field-number
            :sm="3"
            :api-field-name="`Th${i}NormalMin`"
            label="Amount min"
          />
          <!-- ThNormalMax -->
          <crud-field-number
            :sm="3"
            :api-field-name="`Th${i}NormalMax`"
            label="Amount max"
          />
          <!-- ThPromoMin -->
          <crud-field-number
            :sm="4"
            :api-field-name="`Th${i}PromoMin`"
            label="Promo amount min"
          />
          <!-- ThPromoMax -->
          <crud-field-number
            :sm="4"
            :api-field-name="`Th${i}PromoMax`"
            label="Promo amount max"
          />
          <!-- ThPromoMegaChance -->
          <crud-field-number
            :sm="3"
            :api-field-name="`Th${i}PromoMegaChance`"
            label="Mega chance"
          />
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
