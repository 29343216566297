<script setup>
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- Name -->
                <crud-field-selector-client-strings
                  api-field-name="Name"
                  required
                />
                <!-- Kind -->
                <crud-field-select
                  api-field-name="Kind"
                  options-enum="enums.ChestKind"
                  required
                />
                <!-- Comment -->
                <crud-field-textarea api-field-name="Comment" />
                <!-- Priority -->
                <crud-field-number api-field-name="Priority" />
                <!-- ReqLevel -->
                <crud-field-number
                  api-field-name="ReqLevel"
                  label="Level min"
                />
                <!-- ReqLevelMax -->
                <crud-field-number
                  api-field-name="ReqLevelMax"
                  label="Level max"
                />
              </fields-col>
              <fields-col :sm="8">
                <!-- Title -->
                <crud-field-text api-field-name="Title" />
                <!-- PricePremium -->
                <crud-field-number api-field-name="PricePremium" />
                <!-- PriceGold -->
                <crud-field-number api-field-name="PriceGold" />
                <el-col
                  :span="24"
                  class="bg-amber-100"
                >
                  <el-row>
                    <crud-field-item-tags api-field-name="ItemTags" />
                  </el-row>
                  <el-row>
                    <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
                  </el-row>
                </el-col>
                <!-- IsEvent -->
                <crud-field-switcher api-field-name="IsEvent" />
                <!-- IsLimited -->
                <crud-field-switcher api-field-name="IsLimited" />
              </fields-col>
              <fields-col :sm="8">
                <!-- Graphic -->
                <crud-field-selector-files
                  api-field-name="Graphic"
                  required
                />
                <!-- AssetJumpingChest -->
                <crud-field-selector-files api-field-name="AssetJumpingChest" />
                <!-- AssetChestRoll -->
                <crud-field-selector-files api-field-name="AssetChestRoll" />
                <!-- AssetChestLegendaryDrawRoll -->
                <crud-field-selector-files api-field-name="AssetChestLegendaryDrawRoll" />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Basic loots"
            name="basic_loots"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- Loot -->
                <crud-field-selector-loots api-field-name="Loot" />
                <!-- FirstFreeLoot -->
                <crud-field-selector-loots api-field-name="FirstFreeLoot" />
                <!-- FirstPaidLoot -->
                <crud-field-selector-loots api-field-name="FirstPaidLoot" />
                <!-- FirstEventLootId -->
                <crud-field-selector-loots api-field-name="FirstEventLootId" />
                <!-- SureOpenX10Loot -->
                <crud-field-selector-loots api-field-name="SureOpenX10Loot" />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <el-tab-pane
            label="Additional loot"
            name="additional_loot"
          >
            <fields-col>
              <template
                v-for="i in 3"
                :key="i"
              >
                <!-- ExtraLootId -->
                <crud-field-selector-loots
                  :sm="8"
                  :api-field-name="`ExtraLoot${i}Id`"
                />
                <!-- ExtraLootChance -->
                <crud-field-number
                  :sm="4"
                  :api-field-name="`ExtraLootChance${i}`"
                  label="Chance"
                />
                <!-- ExtraItem -->
                <crud-field-selector-items
                  :sm="8"
                  :api-field-name="`ExtraItem${i}`"
                />
                <!-- ExtraItemChance -->
                <crud-field-number
                  :sm="4"
                  :api-field-name="`ExtraItemChance${i}`"
                  label="Chance"
                />
              </template>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
