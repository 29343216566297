<script setup lang="ts">
</script>

<template>
  <crud-table
    :default-visible-columns="['Name', 'Label', 'Food']"
    api="battle/pets"
    api-item="battle/pet"
  />
</template>
