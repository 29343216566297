<template>
  <div class="text-sm font-normal">
    <el-divider>File fields</el-divider>
    <p>Each file has four fields:</p>
    <div class="m-3">
      <b>ID</b> - unique number identifier that is used to assign file to other elements in admin panel. For example hypothetical file
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        sword
      </el-tag>
      with ID
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        1
      </el-tag>
      is assigned to element
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Reward
      </el-tag>
      on form
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Battle rewards
      </el-tag>
      in form field
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Reward file
      </el-tag>. Game API used to define
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Rewards
      </el-tag>
      sends only ID of the
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Reward file
      </el-tag>
      field, for example:
      <div class="m-3">
        [{ "ID": 100, "Name": "Magical wooden sword", "RewardFile": 1 }, ...]
      </div>
    </div>
    <div class="m-3">
      <b>Name</b> - each file
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        ID
      </el-tag>
      has unique name that is any text without spaces (for example
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        wooden_sword
      </el-tag>. Name is never sent in game API. Is used to update proper files using
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        files uploader
      </el-tag>, which is explained later. For example if you upload file
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        magical_helmet.png
      </el-tag>
      Admin Panel searches a File with name
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        magical_helmet
      </el-tag>
      and if found, then uploaded file is added to this file. If there is no file in Admin Panel with this name, then new File is created.
    </div>
    <div class="m-3">
      <b>Group</b> -each file can be assign to a one group. Group is a simple dropdown list. All elements are hardcoded in API code, so if you need to edit this list please ask backend developer to do
      it for you. Group is used to help Admin Panel users filter files. Simply choose group in
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Group filter
      </el-tag>
      in files table header to show files from chosen group. File group is never send in game API.
    </div>
    <div class="m-3">
      <b>Importance</b> - defines how file is downloaded by game applications. You can choose one of these options:
      <div class="m-3">
        <b>in_build</b> - file is included in application during build process. Increases app size in app store. You should use this option for files that are used when user install and open game for
        a first time.
      </div>
      <div class="m-3">
        <b>pre_load</b> - file is not included in build but will be downloaded on loading screen when user opens a game. User needs to wait till all files with
        <el-tag
          size="small"
          effect="plain"
          type="info"
        >
          pre_load
        </el-tag>
        option are downloaded (progress bar is visible) to start playing the game.
      </div>
      <div class="m-3">
        <b>post_load</b> - file is downloaded in background when needed. For example user opens a game page where one file is used but this file is defined as
        <el-tag
          size="small"
          effect="plain"
          type="info"
        >
          post_load
        </el-tag>. In this case users sees a loader on this element, file is loaded in background, and element with loader is replaced by downloaded file.
      </div>
    </div>
    <el-divider>File binary content</el-divider>
    Each File (that has ID) needs a binary data that needs to be downloaded by app, for example PNG file or unity3D file. You can upload binary files related to one File element. Each binary file has
    it's own options:
    <div class="m-3">
      <b>Target</b> - defines on which device this binary data should be used. You can choose one of these options:
      <div class="m-3">
        <b>Android</b> - all android devices.
      </div>
      <div class="m-3">
        <b>Android SD</b> - all android low memory devices.
      </div>
      <div class="m-3">
        <b>Ios</b> - all iOS devices.
      </div>
      <div class="m-3">
        <b>Ios SD</b> - all iOS low memory devices.
      </div>
      <div class="m-3">
        <b>Png</b> - png files. When you choose this target you can upload only PNG files. There are two scenarios when you should use this target. One is when game application requires PNG file. Then
        you should upload file ONLY to this target, and NEVER upload any files to other targets (Android, Ios...). Then this PNG file is send in game API. Second scenario is when you want to upload
        file preview image that is used in Admin Panel. For example you uploaded sword.unity3d file to Adnroid i Ios targets and sword.png file to Png target. In this case game API sends unity3d files
        in files list but without PNG file (which us used only in Admin Panel as preview).
      </div>
      Devices with low memory first try to download SD version, if not defined then default version is used. For example Android with low memory checks for
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Android SD
      </el-tag>
      target, if not defined then
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Android
      </el-tag>
      is used. Android device with high memory checks only for
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Android
      </el-tag>
      target. So you need to upload at least
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Android
      </el-tag>
      and
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Ios
      </el-tag>
      files. SD versions are optional, but still recommended.
    </div>
    <div class="m-3">
      <b>Version</b> - defines from which game version this binary file should be used. Empty versions means "all versions". For example you uploaded two files for target
      <el-tag
        size="small"
        effect="plain"
        type="info"
      >
        Android
      </el-tag>
      - sword.unity3d with no version (empty field) and another file sword2.unity3d for version 1.22.123.23. Game app with version 1.20.11.11 will use sword.unity3d file, but game app with version
      1.22.123.23 and above will use sword2.unity3d file.
    </div>
  </div>
</template>
