<template>
  <crud-details-page
    api="settings/quest-criteria-string"
  >
    <template #form>
      <fields-col>
        <!-- Criterion -->
        <crud-field-select
          :sm="7"
          api-field-name="Criterion"
          options-enum="enums.QuestCriterion"
        />
        <!-- CustomKey -->
        <crud-field-select
          :sm="7"
          api-field-name="CustomKey"
          options-enum="enums.QuestCriteriaKey"
        />
        <!-- Contents -->
        <crud-field-selector-client-strings
          :sm="7"
          api-field-name="Contents"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
