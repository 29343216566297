<script>
import CrudFieldSelectorFaqTabs from '@/components/forms/crud-fields/CrudFieldSelectorFaqTabs.vue'

export default {
  components: { CrudFieldSelectorFaqTabs }
}
</script>

<template>
  <crud-details-page
    api="faq/item"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- FaqTab -->
        <CrudFieldSelectorFaqTabs
          api-field-name="FaqTab"
          required
        />
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- IconGraphic -->
        <crud-field-selector-files api-field-name="IconGraphic" />
      </fields-col>
      <fields-col :sm="8">
        <!-- TargetPlace -->
        <crud-field-select
          api-field-name="TargetPlace"
          options-enum="enums.QuestPlace"
        />
        <!-- ReadQmark -->
        <crud-field-select
          api-field-name="ReadQmark"
          label="Target Question mark (Read)"
          options-enum="enums.QuestionMarkHelper"
        />
        <!-- Url -->
        <crud-field-text
          api-field-name="Url"
          label="Url (video link)"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- ReqLevel -->
        <crud-field-number
          api-field-name="ReqLevel"
          label="Required hero level"
        />
        <!-- Order -->
        <crud-field-number api-field-name="Order" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
