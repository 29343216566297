const columnsBuildInAttrByType = {
  text: { width: 250 },
  uint: { 'width': 70, 'class-name': 'gs-col-padding-mini' },
  int: { 'width': 60, 'class-name': 'gs-col-padding-mini' },
  float: { 'width': 100, 'class-name': 'gs-col-padding-mini' },
  bool: { 'width': 65, 'class-name': 'gs-col-padding-mini text-center' },
  datetime: { 'width': 135, 'class-name': 'gs-col-padding-mini' },
  date: { width: 115 },
  entity: { 'width': 250, 'class-name': 'gs-col-td-padding-mini gs-td-padding-mini' },
  clientstring: { 'width': 250, 'class-name': 'gs-col-td-padding-mini gs-td-padding-mini' },
  enum: { 'width': 200, 'class-name': 'gs-col-padding-mini' }
}

const Columns = {
  data() {
    return {
      selectorVisibleColumns: {
        columns: [],
        all: false,
        checkedColumns: {},
        initiated: false
      },
      crudSettings: {
        fixed: true,
        fit: true,
        visibleCellEdit: false,
        favoritePage: false,
        crudSelectIdsMode: false
      },
      areCellsToEdit: false,
      topTableHeightValue: 0
    }
  },
  computed: {
    crudCols() {
      return { ID: 'uint', ...this.colsApi, ...this.columns }
    },
    watchedColumnsLength() {
      return this.api ? Object.keys(this.colsApi).length : Object.keys(this.columns).length
    },
    tableColumns() {
      return Object.fromEntries(
        Object.entries(this.crudCols)
          .map(([colApiName, typeValueField]) => {
            const typeApiColumn = this.renderApiColumnType(typeValueField, colApiName)
            if (this.columnsSettings?.[colApiName] === false) {
              return [colApiName, false]
            }
            let buildInColSettings = {}
            if (colApiName === 'ID') {
              buildInColSettings = {
                filterField: true,
                elAttr: { 'width': 60, 'sortable': true, 'class-name': 'gs-col-padding-mini' }
              }
            }
            const settings = {
              ...this.defaultColumnsSettings,
              ...buildInColSettings,
              ...this.columnsSettings?.[colApiName],
              elAttr:
                {
                  ...(columnsBuildInAttrByType?.[typeApiColumn.type]?.merge || {}),
                  'min-width': 250,
                  ...{
                    label: this.columnsSettings?.[colApiName]?.header || this.$utils.convertApiNamesToHuman(colApiName),
                    prop: colApiName
                  },
                  ...this.defaultColumnsSettings?.elAttr,
                  ...buildInColSettings.elAttr,
                  ...this.columnsSettings?.[colApiName]?.elAttr
                } || {},
              typeValueField: typeApiColumn.type,
              typeApiColumn
            }

            if (settings.elAttr?.sortable !== undefined) {
              settings._sortable = settings.elAttr?.sortable !== false
              settings.elAttr.sortable = false
            } else {
              settings._sortable = typeApiColumn.sort
              settings.elAttr.sortable = false
            }

            if (settings?.filterField === undefined) {
              settings.filterField = true
              if (typeApiColumn.like) {
                settings.filterField = `${colApiName}_like`
              }
              if (typeApiColumn.filterSuffix) {
                settings.filterField = `${colApiName}_${typeApiColumn.filterSuffix}`
              }
            }

            settings._cellEdit = false
            if (this.enableCellEditDialogs && colApiName !== this.indexColumn && settings?.cellEdit !== false) {
              this.areCellsToEdit = true
              if (this.crudSettings.visibleCellEdit) {
                settings._cellEdit = true
                settings.elAttr['class-name'] = `${settings.elAttr['class-name'] ? ' ' : ''}cursor-pointer`
              }
            }
            if (settings.width !== undefined) {
              settings.elAttr.width = settings.width
            }
            if (settings.elAttr.width !== undefined) {
              settings.elAttr.width = settings.elAttr.width + this.$store.getters['auth/userScaledWidth'] * 2
              delete settings.elAttr['min-width']
            } else if (columnsBuildInAttrByType?.[settings?.typeApiColumn?.type]?.width) {
              if (settings.typeApiColumn.type === 'enum') {
                settings.elAttr.width =
                  (Math.max((this.$store.state.enums.cache?.[settings.typeApiColumn.enum]?.maxValueLength || 0) * 13, 50) ||
                    columnsBuildInAttrByType[settings.typeApiColumn.type].width) +
                    this.$store.getters['auth/userScaledWidth'] * 2
              } else {
                settings.elAttr.width =
                  columnsBuildInAttrByType[settings.typeApiColumn.type].width + this.$store.getters['auth/userScaledWidth'] * 2
              }
              delete settings.elAttr['min-width']
            }
            if (columnsBuildInAttrByType?.[settings?.typeApiColumn?.type]?.['class-name']) {
              settings.elAttr['class-name'] =
                `${settings.elAttr['class-name'] || ''} ${columnsBuildInAttrByType[settings.typeApiColumn.type]['class-name']}`
            }
            return [colApiName, settings]
          })
          .filter(([, colSettings]) => {
            return colSettings !== false
          })
      )
    },
    tableColumnsArr() {
      return Object.entries(this.tableColumns).map(([, colConfig]) => {
        return colConfig
      })
    },
    tableVisibleColumns() {
      if (this.isAccess) {
        const growColumn = { index: 0, width: 0 }
        const columns =
          (this.selectorVisibleColumns.columns.length
            ? this.selectorVisibleColumns.columns
              .map((colApiName) => {
                return this.tableColumns?.[colApiName] || false
              })
              .filter((colSettings) => {
                return colSettings !== false
              })
            : this.tableColumnsArr) || []
        columns.forEach((col, index) => {
          if (col.elAttr.width >= growColumn.width && col.typeApiColumn.colApiName !== 'ID') {
            growColumn.width = col.elAttr.width
            growColumn.index = index
          }
          if (col.typeValueField === 'entity') {
            col.typeApiColumn.disabledSelector = this.$utils.getRouteByEntity(col.typeApiColumn.entity)?.meta?.selector === false
            if (col.typeApiColumn.disabledSelector) {
              col._cellEdit = false
              col.filterField = false
              col.elAttr['class-name'] = col.elAttr['class-name'].replace('cursor-pointer', '')
            }
          }
        })
        if (columns[growColumn.index]?.elAttr?.width && growColumn.width) {
          columns[growColumn.index].elAttr['min-width'] = columns[growColumn.index].elAttr.width
          delete columns[growColumn.index].elAttr.width
        }
        return columns
      } else {
        return []
      }
    },
    currentTableVisibleColumnsNames() {
      let cols = this.getCrudStorage('selectorVisibleColumns')
      if (!cols?.length) {
        cols = this.visibleColumns
      }
      return cols
    },
    tableRows() {
      this.clearCells()
      let rows = this.rows ? [...this.rows] : [...this.rowsApi]
      if (!this.apiFiltering) {
        if (this.filterParams.allLength) {
          rows = rows.filter((row) => {
            return !Object.entries(this.filterParams.all).some(([nameField, filterValue]) => {
              const fieldVal = this.tableColumns?.[nameField]?.elAttr?.formatter
                ? this.tableColumns[nameField].elAttr.formatter(row)
                : row[nameField]
              const typeField = this.tableColumns?.[nameField]?.typeApiColumn?.type || 'any'
              if (['any', 'string'].includes(typeField)) {
                if (String(fieldVal).toLowerCase().includes(String(filterValue).toLowerCase())) {
                  return false
                }
              } else if (['number', 'int', 'uint', 'float'].includes(typeField)) {
                if (fieldVal * 1 === filterValue * 1) {
                  return false
                }
              } else if (fieldVal === filterValue) {
                return false
              }
              return true
            })
          })
        }
        this.updateTableRowsTotal(rows.length)
      }

      if (!this.apiSorting) {
        if (this.sorting.prop) {
          const sortTypeVal = this.sorting.order === 'anscending' ? 1 : -1
          const isNumber = ['int', 'uint', 'float'].includes(this.tableColumns?.[this.sorting.prop]?.typeValueField)
          rows = rows.sort((a, b) => {
            const aVal = this.tableColumns?.[this.sorting.prop]?.elAttr.formatter
              ? this.tableColumns?.[this.sorting.prop]?.elAttr.formatter(a)
              : a?.[this.sorting.prop]
            const bVal = this.tableColumns?.[this.sorting.prop]?.elAttr.formatter
              ? this.tableColumns?.[this.sorting.prop]?.elAttr.formatter(b)
              : b?.[this.sorting.prop]
            if (isNumber) {
              if (aVal < bVal)
                return -1 * sortTypeVal
              if (aVal > bVal)
                return 1 * sortTypeVal
            } else {
              if (String(aVal).toUpperCase().trim() < String(bVal).toUpperCase().trim())
                return -1 * sortTypeVal
              if (String(aVal).toUpperCase().trim() > String(bVal).toUpperCase().trim())
                return 1 * sortTypeVal
            }

            return 0
          })
        }
      }

      if (!this.apiPagination) {
        rows = rows.slice(
          this.pagination.currentPage * this.pagination.onPage - this.pagination.onPage,
          this.pagination.currentPage * this.pagination.onPage
        )
      }
      return rows
    },
    tableHeight() {
      if (this.selectMode) {
        return this.$windowHeight * 0.7
      }
      const topSlotHeight = this.usedTopSlots ? this.topSlotHeight : 0
      return this.crudSettings.fixed
        ? this.$windowHeight -
        95 * this.userScaledRatio -
        (topSlotHeight ? topSlotHeight * this.userScaledRatio : this.topTableHeight)
        : undefined
    },
    topTableHeight() {
      return this.topTableHeightValue
    },
    visibleColumns() {
      return this.defaultVisibleColumns.includes('ID') ? this.defaultVisibleColumns : ['ID', ...this.defaultVisibleColumns]
    },
    resizeData() {
      return `vw:${this.$windowWidth} vh:${this.$windowHeight} ufs:${this.userScaledFontSize}`
    }
  },
  methods: {
    updateTableRowsTotal(total) {
      this.total = total
    },
    forceRefreshPage(delay = 0) {
      this.$utils.nextLoopEvent(delay).then(() => {
        this.$router.go(0)
      })
    },
    calculateTopTableHeight() {
      this.topTableHeightValue = this.$refs?.topTableWrapper?.clientHeight || 0
      setTimeout(() => {
        if (this.topTableHeightValue !== (this.$refs?.topTableWrapper?.clientHeight || 0)) {
          this.calculateTopTableHeight()
        }
      }, 100)
    }
  },
  watch: {
    resizeData: {
      handler() {
        this.calculateTopTableHeight()
      }
    }
  }
}

const RenderApiColumnType = {
  methods: {
    renderApiColumnType(apiType, colApiName) {
      const settings = {
        type: apiType,
        sort: colApiName === 'ID',
        like: false,
        colApiName
      }
      apiType.split(':').forEach((val) => {
        if (val === 'sort') {
          settings.sort = true
        } else if (val === 'like') {
          settings.filterSuffix = 'like'
        } else if (val.slice(0, 13) === 'filtersuffix_') {
          settings.filterSuffix = val.slice(13)
        } else if (val.substring(0, 5) === 'enum[') {
          settings.type = 'enum'
          settings.enum = val.slice(5, -1)
        } else if (val.substring(0, 7) === 'entity[') {
          settings.type = 'entity'
          settings.entity = val.slice(7, -1)
          settings.disabledSelector = false
        } else if (val === 'clientstring') {
          settings.type = 'clientstring'
          settings.editCellType = 'entity'
          settings.entity = 'game.ClientStringEntity'
        } else {
          settings.type = val
        }
      })
      return settings
    }
  }
}

export { Columns, RenderApiColumnType }
