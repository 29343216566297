<script>
export default {
  name: 'FieldsTableTd',
  props: {
    width: {
      type: Number,
      default: undefined
    },
    minWidth: {
      type: Number,
      default: undefined
    },
    cellClass: {
      type: [String, Object, Array],
      default: undefined
    }
  },
  computed: {
    cellWidth() {
      return this.width ? { width: `${this.width}px` } : undefined
    },
    cellMinWidth() {
      return this.minWidth ? { minWidth: `${this.minWidth}px` } : undefined
    }
  }
}
</script>

<template>
  <td :style="[cellWidth]">
    <div
      class="field-table-cell"
      :class="cellClass"
      :style="[cellWidth, cellMinWidth]"
    >
      <slot name="default" />
    </div>
  </td>
</template>
