<script>
export default {
  data() {
    return {
      noOfferMessage: true,
      loading: {}
    }
  },
  methods: {
    toggleValidation(val) {
      if (val) {
        this.noOfferMessage = false
      } else {
        this.noOfferMessage = true
      }
    },
    send(form, refreshData) {
      const server = this.$store.getters['auth/envs']?.[form.Region]
      if (server && form.ID) {
        this.loading.send = true
        this.$axios
          .get(`${server}/jwt/sendpersonalmessage/${form.ID}/`)
          .then(() => {
            this.$message({
              message: 'Has been sent.',
              type: 'success',
              offset: 40
            })
            refreshData()
          })
          .catch(this.$utils.catchError)
          .then(() => {
            this.loading.send = false
          })
      }
    },
    onDuplicate(duplicateData) {
      duplicateData.Sent = null
      return duplicateData
    }
  }
}
</script>

<template>
  <crud-details-page
    api="tools/personal-message"
    disable-top-margin
    re-load-data-after-save
    :versioned="false"
    :render-duplicate-item="onDuplicate"
  >
    <template #form="{ form }">
      <fields-col
        :sm="8"
      >
        <!-- Sender -->
        <crud-field-text
          api-field-name="Sender"
          required
        />
        <!-- Title -->
        <crud-field-text
          api-field-name="Title"
          :required="noOfferMessage"
        />
        <!-- Offer -->
        <crud-field-selector-offers
          api-field-name="Offer"
          label="Offer (special offer compensation !!!Add 1 gold otherwise CLAIM button won't appear!!!)"
          @change="toggleValidation($event, form)"
        />
        <crud-field-slot
          v-if="form.Sent"
          label="Sent"
        >
          <span>{{ $utils.formatUTCDateTime(form.Sent) }}</span>
        </crud-field-slot>
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- PlayerIDs -->
        <crud-field-textarea
          api-field-name="PlayerIDs"
          label="Heroes CSV IDs"
          :textarea-rows="5"
          required
        />
        <!-- Region -->
        <crud-field-select
          api-field-name="Region"
          options-enum="enums.RegionCodes"
          label="Server"
          required
        />
        <!-- Contents -->
        <crud-field-textarea
          api-field-name="Contents"
          label="Message"
          :textarea-rows="10"
          :required="noOfferMessage"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Gold -->
        <crud-field-number api-field-name="Gold" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
        <!-- Items (1-5) -->
        <template
          v-for="i in 5"
          :key="i"
        >
          <crud-field-selector-items
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Amount${i}`"
            :label="`Amount ${i}`"
          />
        </template>
      </fields-col>
    </template>
    <template #actionsFixed="{ form, refreshData }">
      <div v-if="form.ID && form.Sent === null">
        <el-popconfirm
          title="Are you sure?"
          @confirm="send(form, refreshData)"
        >
          <template #reference>
            <el-button
              class="gs-loading gs-font-scaled gs-height-related-xl gs-btn-outlined-warning ml-2 px-3"
              :loading="loading.send"
              :disabled="!$store.getters['auth/envs']?.[form.Region]"
            >
              Send
            </el-button>
          </template>
        </el-popconfirm>
      </div>
    </template>
  </crud-details-page>
</template>
