<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { CircleCheck, Coin, CopyDocument, Delete, Edit, Memo, View } from '@element-plus/icons-vue'
import { userRightRoles } from '!/composition/utilities.js'
import { globalProperties as app } from '!/plugins/utilities'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  setup() {
    const route = useRoute()
    const id = (route?.params?.id || 0) * 1
    const rows = ref([])
    const loading = ref({ all: false, groups: false, entity: false })
    const fetchData = (type = 'all') => {
      if (loading.value.all || loading.value.groups || loading.value.entity || !id)
        return false
      loading.value[type] = true
      app.$axios
        .get('/admin/api/ab/usage/', { params: { id } })
        .then((response) => {
          rows.value = response?.data?.items || []
        })
        .catch(app.$utils.catchError)
        .then(() => {
          loading.value[type] = false
        })
    }
    return {
      id,
      loading,
      rows,
      fetchData,
      envSettings: CrudTagsSettings(['dev', 'prod']),
      icons: { Edit }
    }
  },
  computed: {
    Delete() {
      return Delete
    },
    Memo() {
      return Memo
    },
    View() {
      return View
    },
    Coin() {
      return Coin
    },
    CopyDocument() {
      return CopyDocument
    },
    CircleCheck() {
      return CircleCheck
    },
    userRightRoles() {
      return userRightRoles
    }
  }
}
</script>

<template>
  <crud-details-page
    api="settings/hipo"
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudDelete: false
    }"
    disable-doc
  >
    <template #form>
      <fields-col>
        <crud-field-text
          :sm="10"
          api-field-name="Title"
          readonly
          class-component="gs-readonly"
        />
        <crud-field-text
          :sm="10"
          api-field-name="Status"
          readonly
          class-component="gs-readonly"
        />
        <crud-field-textarea
          api-field-name="Description"
          readonly
          class-component="gs-readonly"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
