import { globalProperties as app } from '!/plugins/utilities'

function initTranslations() {
  const langs = [...app.$store.getters['auth/userLanguages'], ['en', 'gb'], ['zh', 'cn'], ['cm', 'cn'], ['ko', 'kr'], ['jp', 'ja']]
  langs.forEach((code) => {
    const codes = Array.isArray(code) ? code : [code.toLowerCase(), code]
    if (app.$utils?.languages?.[codes[0]] && app.$utils.countries?.[codes[1].toUpperCase()]) {
      app.$utils.languages[codes[0]].emoji = app.$utils.countries[codes[1].toUpperCase()].emoji
    }
  })
}

function initActions() {
  initTranslations()
}

export { initActions, initTranslations }
