<script>
import { inject, ref } from 'vue'

export default {
  name: 'ApiValidationWarningsDialog',
  props: {
    detailsPage: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const crudParent = props.detailsPage ? inject('crudDetails') : inject('crudTable')
    const loading = ref({})

    const cancel = () => {
      crudParent.apiValidationWarningsDialog = false
      if (props.detailsPage) {
        crudParent.apiValidationWarningsDetails = {
          warnings: [],
          skip: false
        }
      } else {
        if (crudParent.apiValidationWarningsDetails.close) {
          crudParent.apiValidationWarningsDetails.close.method(...crudParent.apiValidationWarningsDetails.close.params)
        }
        crudParent.apiValidationWarningsDetails = {
          warnings: [],
          skip: false
        }
      }
    }
    const save = () => {
      crudParent.apiValidationWarningsDetails.skip = true
      loading.value.continue = true
      if (props.detailsPage) {
        crudParent.crudSaveAction()
      } else if (crudParent.apiValidationWarningsDetails.save) {
        crudParent.apiValidationWarningsDetails.save.method(...crudParent.apiValidationWarningsDetails.save.params)
      }
    }
    return {
      crudParent,
      loading,
      cancel,
      save
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="crudParent.apiValidationWarningsDialog"
    class="small"
    :width="$windowWidth < 640 ? '95%' : '60%'"
  >
    <template #header>
      <div class="gs-font-scaled font-related-xxl flex items-center">
        <icon-ify
          icon="bx:error"
          class="mr-4 inline-block h-14 w-14 text-amber-600"
        />
        Api validation warnings
      </div>
    </template>
    <template #default>
      <el-scrollbar
        class="gs-scaled mt-4 px-4"
        height="50vh"
      >
        <ul class="ml-6 list-disc pl-6">
          <li
            v-for="(warning, index) in crudParent.apiValidationWarningsDetails.warnings"
            :key="index"
            class="py-2"
          >
            {{ warning }}
          </li>
        </ul>
      </el-scrollbar>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="gs-height-related-xl gs-btn-outlined-neutral"
          size="small"
          @click="cancel"
        >Cancel</el-button>
        <el-popconfirm
          title="Are you sure ?"
          @cancel.stop
          @confirm.stop="save"
        >
          <template #reference>
            <el-button
              class="gs-height-related-xl gs-btn-primary"
              size="small"
              :loading="loading.continue"
            >
              Continue anyway
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>
