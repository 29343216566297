<script>
import { autoFillGraphic } from '@/composition/autoFillGraphic'
import CrudFieldSelectorWearableSets from '@/components/forms/crud-fields/CrudFieldSelectorWearableSets.vue'
import CrudFieldSelectorBattlePassiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattlePassiveSkills.vue'

export default {
  name: 'WearableDetailsPage',
  components: { CrudFieldSelectorBattlePassiveSkills, CrudFieldSelectorWearableSets },
  methods: {
    initForm(itemData) {
      itemData.Class = 'wearable'
      if (itemData.Quality === 'legendary') {
        itemData.OverwriteSkinName = true
        itemData.OverwriteSkinWearableSlot = true
        itemData.OverwriteSkinGraphics = true
      } else {
        itemData.OverwriteSkinName = false
        itemData.OverwriteSkinWearableSlot = false
        itemData.OverwriteSkinGraphics = false
      }
      return itemData
    },
    skinOverwriteSettings(form) {
      const skinsSlots = ['head', 'chest', 'gloves', 'feet', 'main_hand', 'off_hand']
      if (skinsSlots.includes(form.Slot) && form.Quality === 'legendary') {
        form.OverwriteSkinName = true
        form.OverwriteSkinWearableSlot = true
        form.OverwriteSkinGraphics = true
      } else {
        form.OverwriteSkinName = false
        form.OverwriteSkinWearableSlot = false
        form.OverwriteSkinGraphics = false
      }
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    re-load-data-after-save
    enable-relations
    doc-key="items-wearable"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          @change="autoFillGraphic($event, form)"
        />
        <!-- Graphic body -->
        <crud-field-selector-files api-field-name="GraphicBody" />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- Icon Hd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
        <!-- Raw item graphic -->
        <crud-field-selector-files
          api-field-name="RawGraphic"
          label="Raw item graphic"
        />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="6">
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
        <!-- Slot -->
        <crud-field-select
          api-field-name="Slot"
          options-enum="enums.WearableSlot"
          @change="skinOverwriteSettings(form)"
        />
        <!-- WearableSet -->
        <CrudFieldSelectorWearableSets
          api-field-name="WearableSet"
          label="Wearable set"
        />
        <!-- Index points -->
        <crud-field-number
          :span="12"
          api-field-name="IndexPoints"
        />
        <!-- Show in index -->
        <crud-field-switcher
          :span="11"
          :offset="1"
          api-field-name="ShowInIndex"
        />
        <!-- PassiveSkill1 -->
        <CrudFieldSelectorBattlePassiveSkills
          api-field-name="PassiveSkill1"
          label="Passive skill 1"
          :disabled="form.Slot !== 'main_hand' && form.Slot !== 'off_hand'"
        />
        <!-- PassiveSkill2 -->
        <CrudFieldSelectorBattlePassiveSkills
          api-field-name="PassiveSkill2"
          label="Passive skill 2"
          :disabled="form.Slot !== 'main_hand' && form.Slot !== 'off_hand'"
        />
        <!-- PassiveSkill3 -->
        <CrudFieldSelectorBattlePassiveSkills
          api-field-name="PassiveSkill3"
          label="Passive skill 3"
          :disabled="form.Slot !== 'main_hand' && form.Slot !== 'off_hand'"
        />
        <!-- PassiveSkill4 -->
        <CrudFieldSelectorBattlePassiveSkills
          api-field-name="PassiveSkill4"
          label="Passive skill 4"
          :disabled="form.Slot !== 'main_hand' && form.Slot !== 'off_hand'"
        />
        <!-- AwakenItem -->
        <crud-field-selector-items
          api-field-name="AwakenItem"
          label="Awaken item [DON'T CHANGE, created automatically]"
        />
        <!-- ShardItem -->
        <crud-field-selector-items api-field-name="ShardItem" />
        <!-- ExtractCost -->
        <crud-field-number api-field-name="ExtractCost" />
      </fields-col>
      <fields-col :sm="12">
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          required
        />
        <fields-col :sm="18">
          <fields-col :sm="12">
            <!-- DamageBase -->
            <crud-field-number api-field-name="DamageBase" />
            <!-- DefenceBase -->
            <crud-field-number api-field-name="DefenceBase" />
            <!-- HpBase -->
            <crud-field-number api-field-name="HpBase" />
            <!-- MagicBase -->
            <crud-field-number api-field-name="MagicBase" />
            <!-- IsValuable -->
            <crud-field-switcher api-field-name="IsValuable" />
            <crud-field-switcher
              api-field-name="OverwriteSkinName"
              label="Overwrite skin name"
            />
            <crud-field-switcher
              api-field-name="OverwriteSkinWearableSlot"
              label="Overwrite skin wearable slot"
            />
            <crud-field-switcher
              api-field-name="OverwriteSkinGraphics"
              label="Overwrite skin graphics"
            />
            <!-- DisableCombinedPreview -->
            <crud-field-switcher
              api-field-name="DisableCombinedPreview"
              label="Disable bonus links preview"
            />
          </fields-col>
          <fields-col :sm="12">
            <!-- DamageIncrement -->
            <crud-field-number
              api-field-name="DamageIncrement"
              label="Increment"
            />
            <!-- DefenceIncrement -->
            <crud-field-number
              api-field-name="DefenceIncrement"
              label="Increment"
            />
            <!-- HpIncrement -->
            <crud-field-number
              api-field-name="HpIncrement"
              label="Increment"
            />
            <!-- HpIncrement -->
            <crud-field-number
              api-field-name="MagicIncrement"
              label="Increment"
            />
            <!-- Potential -->
            <crud-field-slot label="Potential">
              <span class="pl-2 text-slate-500">
                {{
                  (form.DamageIncrement || 0) * 1
                    + (form.DefenceIncrement || 0) * 1
                    + (form.HpIncrement || 0) * 1
                    + (form.MagicIncrement || 0) * 1 || '-'
                }}
              </span>
            </crud-field-slot>
          </fields-col>
        </fields-col>
      </fields-col>
      <fields-col>
        <section-divider class="mt-16">
          <span class="text-lg">Bonus links</span>
        </section-divider>
        <fields-table class="w-full">
          <template #tHeader>
            <tr class="text-xs">
              <th class="font-normal">
                LINK
              </th>
              <th class="text-center font-normal">
                PERCENT<br>BONUS
              </th>
              <th
                v-for="i in 3"
                :key="i"
                class="font-normal"
              >
                REQUIRED ITEM {{ i }}
              </th>
              <th />
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="i in 2"
              :key="i"
            >
              <!-- BonusLink{i}Effect -->
              <fields-table-td :width="110">
                <crud-field-select
                  :api-field-name="`BonusLink${i}Effect`"
                  :label="false"
                  options-enum="enums.BonusLinkKind"
                  slim
                />
              </fields-table-td>
              <!-- BonusLink{i}Percent -->
              <fields-table-td :width="80">
                <crud-field-number
                  :api-field-name="`BonusLink${i}Percent`"
                  :label="false"
                  slim
                />
              </fields-table-td>
              <!-- BonusLink{i}Item{c} -->
              <fields-table-td
                v-for="c in 3"
                :key="`${i}_${c}`"
                :width="300"
              >
                <crud-field-selector-items
                  :api-field-name="`BonusLink${i}Item${c}`"
                  :label="false"
                  slim
                />
              </fields-table-td>
              <td />
            </tr>
          </template>
        </fields-table>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
