<template>
  <crud-details-page
    api="battle/level-exp-cost"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Level -->
        <crud-field-number
          api-field-name="Level"
          required
        />
        <!-- Exp -->
        <crud-field-number
          api-field-name="Exp"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
