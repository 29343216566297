<script setup></script>

<template>
  <crud-details-page
    api="pvp/reward"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <crud-field-text
          :sm="8"
          api-field-name="Name"
          required
        />
        <crud-field-text
          :sm="8"
          api-field-name="Labels"
          required
        />
        <crud-field-select
          :sm="8"
          api-field-name="Type"
          options-enum="enums.PvpRankingRewardsType"
          required
        />
      </fields-col>
      <template
        v-for="i in 7"
        :key="i"
      >
        <fields-col>
          <section-divider>Reward #{{ i }}</section-divider>
        </fields-col>
        <fields-col :sm="6">
          <!-- PointsMin -->
          <crud-field-number
            :api-field-name="`Points${i}Min`"
            label="Points min"
          />
          <!-- PointsMax -->
          <crud-field-number
            :api-field-name="`Points${i}Max`"
            label="Points max"
          />
          <!-- Gold -->
          <crud-field-number
            :api-field-name="`Gold${i}`"
            label="Gold"
          />
          <!-- Premium -->
          <crud-field-number
            :api-field-name="`Premium${i}`"
            label="Premium"
          />
        </fields-col>
        <fields-col :sm="6">
          <!-- Name -->
          <crud-field-text
            :api-field-name="`Tier${i}Name`"
            label="Name"
          />
          <!-- TierAsset -->
          <crud-field-selector-files
            :api-field-name="`TierAsset${i}`"
            label="Tier asset"
          />
          <!-- ChestAsset -->
          <crud-field-selector-files
            :api-field-name="`ChestAsset${i}`"
            label="Chest asset"
          />
          <!-- InstantGold -->
          <crud-field-number
            :api-field-name="`InstantGold${i}`"
            label="InstantGold"
          />
        </fields-col>
        <fields-col :sm="12">
          <template
            v-for="j in 3"
            :key="j"
          >
            <!-- RewardItem -->
            <crud-field-selector-files
              :sm="14"
              :api-field-name="`Reward${i}Item${j}`"
              :label="`Item #${j}`"
            />
            <!-- RewardItemAmount -->
            <crud-field-number
              :sm="10"
              :api-field-name="`Reward${i}Item${j}Amount`"
              :label="`Item #${j} amount`"
            />
          </template>
          <!-- InstantRewardItem -->
          <crud-field-selector-files
            :sm="14"
            :api-field-name="`InstantReward${i}Item1`"
            label="Instant item"
          />
          <!-- InstantRewardItemAmount -->
          <crud-field-number
            :sm="10"
            :api-field-name="`InstantReward${i}Item1Amount`"
            label="Instant item amount"
          />
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
