<script setup>
import { ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { globalProperties as app } from '!/plugins/utilities'

const idGroup = ref(Number.parseInt(app.$route?.params?.id) || 0)
const loading = ref('')
const treeData = ref([])
const visibilityApplyList = ref(false)
const route = useRoute()
const router = useRouter()

function applyAll() {
  loading.value = 'applyAll'
  treeData.value = []
  app.$axios
    .get('/admin/api/item-tag-group/apply-all-list/', { params: { group: idGroup.value } })
    .then(({ data }) => {
      visibilityApplyList.value = true
      if (data.rows) {
        treeData.value = Object.entries(data.rows).map(([entity, items]) => {
          return {
            label: entity,
            children: items.map((item) => {
              const route = app.$utils.getRouteByEntity(entity, true)
              return {
                label: item?.AdminLabel,
                AdminIcon: item?.AdminIcon,
                route: route ? { name: route.name, params: { id: item.ID } } : null,
                children: (item.Children || []).map((child) => {
                  return {
                    label: child.field,
                    tag:
                      !!child?.before?.ID && !!child?.after?.ID && child.before.ID === child.after.ID
                        ? 'already applied'
                        : 'to apply',
                    before: child?.before,
                    after: child?.after
                  }
                })
              }
            })
          }
        })
      }
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value = ''
    })
}
function continueApplyAll() {
  loading.value = 'continueApplyAll'
  app.$axios
    .post('/admin/api/item-tag-group/apply-all/', { group: idGroup.value })
    .then(({ data }) => {
      visibilityApplyList.value = false
      app.$notify({
        title: 'Apply all',
        type: 'info',
        message: `changed: ${data?.changed}.`,
        position: 'top-right',
        customClass: 'bg-amber-50 text-amber-600 child-inherit-colors',
        offset: 30,
        duration: 9000,
        showClose: true
      })
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value = ''
    })
}

function duplicateTags() {
  ElMessageBox.prompt('Please input label', {
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    inputValidator: (value) => {
      return value.trim().length >= 2 || 'Minimum 2 chars'
    }
  }).then(({ value }) => {
    loading.value = 'duplicateTags'
    app.$axios
      .post('/admin/api/item-tag-group/duplicate/', { group: idGroup.value, label: value })
      .then(({ data }) => {
        if (data?.id) {
          visibilityApplyList.value = false
          router.replace({ name: route.name, params: { id: data.id } })
        }
        app.$notify({
          type: 'info',
          message: `duplicated`,
          position: 'top-right',
          customClass: 'bg-amber-50 text-amber-600 child-inherit-colors',
          offset: 30,
          duration: 9000,
          showClose: true
        })
      })
      .catch(app.$utils.catchError)
      .then(() => {
        loading.value = ''
      })
  })
}
</script>

<template>
  <!--  don't remove key !!!!! -->
  <crud-details-page
    :key="$route.path"
    api="items/item-tag-group"
    disable-top-margin
    :versioned="false"
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- End -->
        <crud-field-date
          api-field-name="End"
          required
        />
      </fields-col>
    </template>
    <template
      v-if="idGroup"
      #actionsFixeMiddle
    >
      <div class="flex justify-end">
        <el-button
          class="gs-font-scaled gs-height-related-xl gs-btn-outlined-warning mr-1 px-3"
          :loading="loading === 'applyAll'"
          @click="applyAll"
        >
          Apply all
        </el-button>
        <el-button
          class="gs-font-scaled gs-height-related-xl gs-btn-outlined-primary mr-10 px-3"
          :loading="loading === 'duplicateTags'"
          @click="duplicateTags"
        >
          Duplicate all tags
        </el-button>
      </div>
    </template>
    <template
      v-if="idGroup"
      #drawers
    >
      <el-dialog
        v-model="visibilityApplyList"
        class="small"
        :width="$windowWidth < 640 ? '95%' : '80%'"
      >
        <template #header>
          <div class="break-all text-gs-bg">
            {{ `Apply all list` }}
          </div>
          <el-divider class="mt-2 mb-0" />
        </template>
        <template #default>
          <el-scrollbar
            class="gs-scaled mt-4 px-4"
            height="60vh"
          >
            <el-tree
              v-loading="loading"
              :data="treeData"
              :props="{ children: 'children', label: 'label' }"
              :empty-text="loading ? 'loading ...' : 'no data'"
              class="mb-8"
              style="min-height: 400px"
            >
              <template #default="{ data }">
                <template v-if="!!data.children">
                  <router-link
                    v-if="data.route"
                    :to="$utils.bindStaticParams(data.route)"
                    target="_blank"
                    class="gs-user-font text-blue-500 hover:text-blue-600"
                  >
                    {{ data.label }}
                  </router-link>
                  <template v-else>
                    {{ data.label }}
                  </template>
                </template>
                <template v-else>
                  <el-tooltip
                    v-if="data.AdminIcon"
                    raw-content
                    :show-after="600"
                    effect="light"
                    placement="right-start"
                  >
                    <template #default>
                      <el-image
                        v-if="data.AdminIcon"
                        :src="data.AdminIcon"
                        fit="contain"
                        class="mr-2"
                        :style="{
                          width: `${
                            35 * $store.getters['auth/userScaledRatioWidth']
                            + (35
                              * $store.getters['auth/userScaledRatioWidth']
                              * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                            / 100
                          }px`,
                          height: `${
                            35 * $store.getters['auth/userScaledRatioWidth']
                            + (35
                              * $store.getters['auth/userScaledRatioWidth']
                              * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                            / 100
                          }px`
                        }"
                        lazy
                      />
                    </template>
                    <template #content>
                      <el-image
                        :src="data.AdminIcon"
                        fit="contain"
                        style="width: 200px; height: 200px"
                      />
                    </template>
                  </el-tooltip>
                  <router-link
                    v-if="data.route"
                    :to="$utils.bindStaticParams(data.route)"
                    target="_blank"
                    class="gs-user-font text-blue-500 hover:text-blue-600"
                  >
                    {{ data.label }}
                  </router-link>
                  <template v-else>
                    {{ data.label }}
                    <template v-if="data?.tag">
                      <el-tag
                        :type="data.tag === 'to apply' ? 'danger' : 'success'"
                        effect="dark"
                        class="ml-2"
                      >
                        {{ data.tag }}
                      </el-tag>
                      <div class="flex align-middle pl-4 pr-2 font-bold">
                        BEFORE:
                      </div>
                      <template v-if="data?.before">
                        <el-tooltip
                          v-if="data?.before?.AdminIcon"
                          raw-content
                          :show-after="600"
                          effect="light"
                          placement="right-start"
                        >
                          <template #default>
                            <el-image
                              v-if="data?.before?.AdminIcon"
                              :src="data.before.AdminIcon"
                              fit="contain"
                              class="mr-2"
                              :style="{
                                width: `${
                                  35 * $store.getters['auth/userScaledRatioWidth']
                                  + (35
                                    * $store.getters['auth/userScaledRatioWidth']
                                    * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                                  / 100
                                }px`,
                                height: `${
                                  35 * $store.getters['auth/userScaledRatioWidth']
                                  + (35
                                    * $store.getters['auth/userScaledRatioWidth']
                                    * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                                  / 100
                                }px`
                              }"
                              lazy
                            />
                          </template>
                          <template #content>
                            <el-image
                              :src="data.before.AdminIcon"
                              fit="contain"
                              style="width: 200px; height: 200px"
                            />
                          </template>
                        </el-tooltip>
                        <div class="italic">
                          {{ data?.before?.AdminLabel }}
                        </div>
                      </template>
                      <div
                        v-else
                        class="italic"
                      >
                        none
                      </div>
                      <div class="flex align-middle pl-4 font-bold pr-2">
                        AFTER:
                      </div>
                      <template v-if="data?.after">
                        <el-tooltip
                          v-if="data?.after?.AdminIcon"
                          raw-content
                          :show-after="600"
                          effect="light"
                          placement="right-start"
                        >
                          <template #default>
                            <el-image
                              v-if="data?.after?.AdminIcon"
                              :src="data.after.AdminIcon"
                              fit="contain"
                              class="mr-2"
                              :style="{
                                width: `${
                                  35 * $store.getters['auth/userScaledRatioWidth']
                                  + (35
                                    * $store.getters['auth/userScaledRatioWidth']
                                    * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                                  / 100
                                }px`,
                                height: `${
                                  35 * $store.getters['auth/userScaledRatioWidth']
                                  + (35
                                    * $store.getters['auth/userScaledRatioWidth']
                                    * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                                  / 100
                                }px`
                              }"
                              lazy
                            />
                          </template>
                          <template #content>
                            <el-image
                              :src="data.after.AdminIcon"
                              fit="contain"
                              style="width: 200px; height: 200px"
                            />
                          </template>
                        </el-tooltip>
                        <div class="italic">
                          {{ data?.after?.AdminLabel }}
                        </div>
                      </template>
                      <div
                        v-else
                        class="italic"
                      >
                        none
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </el-tree>
          </el-scrollbar>
        </template>
        <template #footer>
          <el-button
            class="gs-font-scaled gs-height-related-xl gs-btn-primary mr-5 px-3"
            :loading="loading === 'continueApplyAll'"
            @click="continueApplyAll"
          >
            Continue apply all
          </el-button>
        </template>
      </el-dialog>
    </template>
  </crud-details-page>
</template>
