import { globalProperties as app } from '!/plugins/utilities'

const loadingEnums = {}
function loadEnumsValues(name) {
  if (app.$store.getters['auth/token'] && !loadingEnums[name]) {
    app.$store.commit('enums/loading', [name, true])
    loadingEnums[name] = true
    app.$axios
      .get('/admin/api/enum/values/', { params: { name } })
      .then((response) => {
        let maxValueLength = 0
        const storedEnum = (response?.data?.list || []).map((item) => {
          if (item.length > maxValueLength) {
            maxValueLength = item.length
          }
          return { value: item, label: item }
        })
        app.$store.commit('enums/updateEnum', [name, { value: storedEnum, updated: Date.now(), loading: false, maxValueLength }])
      })
      .catch((error) => {
        app.$store.commit('enums/updateEnum', [name, { value: [], updated: Date.now(), loading: false, maxValueLength: 0 }])
        app.$utils.catchError(error)
      })
      .then(() => {
        loadingEnums[name] = false
        app.$store.commit('enums/loading', [name, false])
      })
  }
}

const hour = 3600000

export default () => {
  return {
    namespaced: true,
    state() {
      return {
        cache: {}
      }
    },
    getters: {
      // $store.getters['enums/getEnum'](enumName)
      getEnum: state => (enumName) => {
        if (!enumName) {
          return []
        }
        if (!state?.cache?.[enumName]?.loading && (!state?.cache?.[enumName] || Date.now() - state?.cache?.[enumName]?.updated > hour)) {
          setTimeout(() => {
            loadEnumsValues(enumName)
          }, 300)
        }
        return state?.cache?.[enumName]?.value || []
      }
    },
    mutations: {
      updateEnum(state, [enumName, value]) {
        // $store.commit('enums/updateEnum', [enumName, value])
        // value: {value: storedEnum, updated: Date.now(), loading: false}
        state.cache[enumName] = value
      },
      clearAll(state) {
        // $store.commit('enums/clearAll')
        state.cache = {}
      },
      loading(state, [enumName, loading]) {
        // $store.commit('enums/loading', [enumName, value])
        if (!state.cache?.[enumName]) {
          state.cache[enumName] = { value: [], updated: 0, loading: false }
        }
        state.cache[enumName].loading = loading
      }
    }
  }
}
