<script>
import CrudFieldSelectorBattleMobs from '@/components/forms/crud-fields/CrudFieldSelectorBattleMobs.vue'

export default {
  components: { CrudFieldSelectorBattleMobs }
}
</script>

<template>
  <crud-details-page
    api="tower/stage"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- StageNo -->
        <crud-field-number
          api-field-name="StageNo"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <template
          v-for="i in 3"
          :key="i"
        >
          <!-- Mob -->
          <CrudFieldSelectorBattleMobs
            :api-field-name="`Mob${i}`"
            :label="`Mob ${i}`"
          />
          <!-- Mob level -->
          <crud-field-number
            :api-field-name="`Mob${i}Level`"
            :label="`Mob ${i} level`"
          />
        </template>
      </fields-col>
      <fields-col :sm="8">
        <template
          v-for="i in 4"
          :key="i"
        >
          <!-- RewardItem -->
          <crud-field-selector-items
            :api-field-name="`RewardItem${i}`"
            :label="`Reward item ${i}`"
          />
          <!-- RewardItemAmount -->
          <crud-field-number
            :api-field-name="`RewardItem${i}Amount`"
            :label="`Reward item amount ${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
