<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import { userRightRoles } from '!/composition/utilities'

export default {
  setup() {
    return {
      accessRight: userRightRoles.playerViewer
    }
  },
  data() {
    return {
      userRightRoles,
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {},
      search: ref('')
    }
  },
  computed: {
    ...mapGetters({
      userScaledRatioWidth: 'auth/userScaledRatioWidth'
    })
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/jwt/gameplay/player/${this.$route.params?.id}/`
      this.$axios
        .get(envApi + this.endPoint)
        .then(({ data }) => {
          data.swapTokenPlayerId = ''
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${data?.item?.Name} (ID:${data?.item?.ID}) on ${data?.env}`
    },
    async savePlayer(formRefer) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to save player ?', undefined, {
          confirmButtonText: 'Yes, save player',
          type: 'warning'
        })
        .then(async () => {
          this.loading.savePlayer = true
          try {
            await formRefer.validateField('item.Name')
          } catch (_e) {
            this.$message({
              message: 'Not all fields are valid.',
              type: 'warning',
              offset: 40
            })
            this.loading.savePlayer = false
            return false
          }
          this.loading.savePlayer = true
          const params = {
            newPlayerName: this.externalData.item.Name
          }
          this.$axios
            .get(`${this.envApi}/jwt/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.savePlayer = false
            })
        })
        .catch(async () => {})
    },
    async swapTokens(formRefer) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to swap tokens ?', undefined, {
          confirmButtonText: 'Yes, swap tokens',
          type: 'warning'
        })
        .then(async () => {
          this.loading.swapTokens = true
          try {
            await formRefer.validateField('swapTokenPlayerId')
          } catch (_e) {
            this.$message({
              message: 'SwapTokenPlayerId is not valid.',
              type: 'warning',
              offset: 40
            })
            this.loading.swapTokens = false
            return false
          }

          let swapPlayerData = await this.$axios.get(
            `${this.envApi}/jwt/gameplay/player/${this.externalData.swapTokenPlayerId}/`
          )
          swapPlayerData = swapPlayerData?.data || {}
          const isPlayer = swapPlayerData?.item?.ID > 0
          let msg = 'No player with such ID!'
          if (isPlayer && swapPlayerData.item.ID !== this.externalData.item.ID) {
            msg =
              `Are you sure you want to swap ${
                this.externalData.item.Name
              } with this player?` +
              `\n\nPlayer ID: ${
                swapPlayerData.item.ID
              }\nPlayer Name: ${
                swapPlayerData.item.Name
              }\nPlayer Creation Date: ${
                swapPlayerData.item.CreatedAt
              }\nPlayer Last Active Date: ${
                swapPlayerData.item.LastActiveAt
              }\nPlayer Level: ${
                swapPlayerData.item.Level}`
          } else if (isPlayer && swapPlayerData.item.ID === this.externalData.item.ID) {
            msg = 'You cannot swap the player\'s token with itself!'
          }

          const confirm = await this.$messageBox
            .confirm(msg.split('\n').join('<br>'), 'Swapping tokens', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            })
            .catch(() => {
              this.loading.swapTokens = false
            })

          if (!confirm || !isPlayer || swapPlayerData?.item?.ID === this.externalData.item.ID) {
            this.loading.swapTokens = false
            return
          }
          const params = {
            tokenSwapPlayerID: this.externalData.swapTokenPlayerId
          }
          this.$axios
            .get(`${this.envApi}/jwt/gameplay/swaptokens/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify('Tokens swapped')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.swapTokens = false
            })
        })
    },
    async cancelAccountDelete() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to cancel account deletion?', undefined, {
          confirmButtonText: 'Yes, cancel',
          type: 'warning'
        })
        .then(async () => {
          this.$axios
            .get(`${this.envApi}/jwt/gameplay/cancelaccountdelete/${this.externalData.item.ID}/`, {})
            .then(() => {
              this.notify('Account deletion was cancelled')
              this.$refs.form.refreshData()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
        })
        .catch(async () => {})
    },
    notify(message = 'saved') {
      this.$message({
        message,
        type: 'success',
        offset: 40
      })
    }
  }
}
</script>

<template>
  <crud-details-page
    ref="form"
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    proxy
    disable-top-margin
    :external-data="externalData"
    :render-title="setTitle"
    :access-right="accessRight"
    @is-access="loadData"
  >
    <template #form="{ form, formRefer }">
      <fields-col
        class="gs-font-scaled"
      >
        <el-tabs
          class="w-full"
          model-value="maindata"
        >
          <el-tab-pane
            label="Main data"
            name="maindata"
            lazy
          >
            <table class="gs-basic-table w-full">
              <tr>
                <td>
                  <strong>ID</strong><br>
                  {{ form.item.ID }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Region</strong><br>
                  {{ form?.env }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Name</strong><br>
                  {{ form.item.Name.toUpperCase() }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Heropower</strong><br>
                  {{ form.item.Heropower }}
                </td>
              </tr>
              <tr v-if="form.item.Guild">
                <td>
                  <strong>Guild</strong><br>
                  {{ form.guild_name }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Platform</strong><br>
                  {{ form.item.Platform }}
                </td>
              </tr>
              <tr v-if="form.item.CountryCode">
                <td>
                  <strong>Country code</strong><br>
                  {{ form.item.CountryCode }}
                </td>
              </tr>
              <tr v-if="form.item.Lang">
                <td>
                  <strong>Language</strong><br>
                  {{ form.item.Lang }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Level</strong><br>
                  {{ form.item.Level }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>VIP</strong><br>
                  {{ form.vip }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Purchased premium</strong><br>
                  {{ form.item.PremiumPurchased }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Stats</strong><br>
                  HP: {{ form.item.HpTotal }}<br>
                  Damage: {{ form.item.DamageTotal }}<br>
                  Defence: {{ form.item.DefenceTotal }}<br>
                  Magic: {{ form.item.MagicTotal }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Created at</strong><br>
                  {{ $utils.formatUTCDateTime(form.item.CreatedAt) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Last active at</strong><br>
                  {{ $utils.formatUTCDateTime(form.item.LastActiveAt) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Paid total</strong><br>
                  {{ form.payments_sum.toFixed(2) }}$
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Transactions</strong><br>
                  {{ form.transactions }}
                </td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane
            label="Items"
            name="items"
            lazy
          >
            <el-col>
              <el-divider content-position="left">
                Main
              </el-divider>
            </el-col>
            <el-row>
              <div class="relative mr-2 inline-flex flex-col">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  :content="`Gold: ${form.item.Gold}`"
                >
                  <el-image
                    v-if="form.icons[1835]"
                    :src="form.icons[1835]"
                    style="width: 60px; height: 60px"
                  />
                </el-tooltip>
                <div class="badge top-left absolute">
                  {{ form.item.Gold }}
                </div>
              </div>
              <div class="relative mr-2 inline-flex flex-col">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  :content="`Gems: ${form.item.Premium}`"
                >
                  <el-image
                    v-if="form.icons[1837]"
                    :src="form.icons[1837]"
                    fit="contain"
                    style="width: 60px; height: 60px"
                  />
                </el-tooltip>
                <div class="badge top-left absolute">
                  {{ form.item.Premium }}
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Body
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(item, itemIndex) in form.body_items"
                :key="itemIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${item.Tooltip}`"
                >
                  <el-image
                    v-if="item.Graphic"
                    :src="item.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${item.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ item.Level }}
                </div>
                <div
                  v-if="item.AwakenBaseItem"
                  class="pt-1"
                >
                  <strong>{{ item.Tpl }},<br>({{ item.AwakenBaseItem }})</strong>
                </div>
                <div
                  v-if="!item.AwakenBaseItem"
                  class="pt-1"
                >
                  <strong>{{ item.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-row :gutter="2">
              <div
                v-for="(orb, orbIndex) in form.body_orbs"
                :key="orbIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${orb.Tooltip}`"
                >
                  <el-image
                    v-if="orb.Graphic"
                    :src="orb.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${orb.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ orb.Level }}
                </div>
                <div
                  v-if="orb.EnhanceLevel > 0"
                  class="badge top-right absolute"
                >
                  +{{ orb.EnhanceLevel }}
                </div>
                <div class="pt-1">
                  <strong>{{ orb.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                All Talents
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(t, tIndex) in form.talents"
                :key="tIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <div class="badge bottom-left absolute">
                  {{ t.TalentLevel }}
                </div>
                <div class="badge top-right absolute">
                  x{{ t.Count }}
                </div>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${t.Tooltip}`"
                >
                  <el-image
                    v-if="t.Graphic"
                    :src="t.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                  />
                </el-tooltip>
                <div class="pt-1">
                  <strong>{{ t.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Talents in Loadouts
              </el-divider>
            </el-col>
            <el-row
              v-for="(g, gIndex) in form.docked_talents"
              :key="gIndex"
              :gutter="2"
            >
              <el-col>
                <h2>Group {{ gIndex }}:</h2>
              </el-col>
              <div
                v-for="(t, tIndex) in g"
                :key="tIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${t.Tooltip}`"
                >
                  <el-image
                    v-if="t.Graphic"
                    :src="t.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ t.TalentLevel }}
                </div>
                <div class="badge top-right absolute">
                  x{{ t.Count }}
                </div>
                <div class="pt-1">
                  <strong>{{ t.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Collection 1
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(c1, c1Index) in form.body_collection1"
                :key="c1Index"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${c1.Tooltip}`"
                >
                  <el-image
                    v-if="c1.Graphic"
                    :src="c1.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${c1.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ c1.Level }}
                </div>
                <div class="pt-1">
                  <strong>{{ c1.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Collection 2
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(c2, c2Index) in form.body_collection2"
                :key="c2Index"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${c2.Tooltip}`"
                  :class="`${c2.Quality}`"
                >
                  <el-image
                    v-if="c2.Graphic"
                    :src="c2.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ c2.Level }}
                </div>
                <div class="pt-1">
                  <strong>{{ c2.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Other Gear
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(w, wIndex) in form.rest_wearables"
                :key="wIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${w.Tooltip}`"
                >
                  <el-image
                    v-if="w.Graphic"
                    :src="w.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${w.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ w.Level }}
                </div>
                <div class="badge bottom-right absolute">
                  {{ w.Count }}
                </div>
                <div
                  v-if="w.AwakenBaseItem"
                  class="pt-1"
                >
                  <strong>{{ w.Tpl }},<br>({{ w.AwakenBaseItem }})</strong>
                </div>
                <div
                  v-if="!w.AwakenBaseItem"
                  class="pt-1"
                >
                  <strong>{{ w.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Usables
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(usable, usableIndex) in form.usables"
                :key="usableIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${usable.Tooltip}`"
                >
                  <el-image
                    v-if="usable.Graphic"
                    :src="usable.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${usable.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  x{{ usable.Count }}
                </div>
                <div class="pt-1">
                  <strong>{{ usable.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Cosmetics
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(c, cIndex) in form.cosmetics"
                :key="cIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${c.Tooltip}`"
                >
                  <el-image
                    v-if="c.Graphic"
                    :src="c.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${c.Quality}`"
                  />
                </el-tooltip>
                <div class="pt-1">
                  <strong>{{ c.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Other Orbs
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(orb, orbIndex) in form.rest_orbs"
                :key="orbIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${orb.Tooltip}`"
                >
                  <el-image
                    v-if="orb.Graphic"
                    :src="orb.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${orb.Quality}`"
                  />
                </el-tooltip>
                <div class="badge bottom-left absolute">
                  {{ orb.Level }}
                </div>
                <div
                  v-if="orb.EnhanceLevel > 0"
                  class="badge top-right absolute"
                >
                  +{{ orb.EnhanceLevel }}
                </div>
                <div class="pt-1">
                  <strong>{{ orb.Tpl }}</strong>
                </div>
              </div>
            </el-row>
            <el-col>
              <el-divider content-position="left">
                Parts
              </el-divider>
            </el-col>
            <el-row :gutter="2">
              <div
                v-for="(p, pIndex) in form.parts"
                :key="pIndex"
                class="relative mr-2 inline-flex flex-col"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
                  raw-content
                  :content="`${p.Tooltip}`"
                >
                  <el-image
                    v-if="p.Graphic"
                    :src="p.Graphic"
                    fit="contain"
                    style="width: 60px; height: 60px"
                    :class="`${p.Quality}`"
                  />
                </el-tooltip>
                <div class="badge top-right absolute">
                  x{{ p.Count }}
                </div>
                <div class="pt-1">
                  <strong>{{ p.Tpl }}</strong>
                </div>
              </div>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="Payments"
            name="payments"
            lazy
          >
            <div v-if="!form.payments.length">
              No payment with OK status for this user
            </div>
            <filtering-table
              v-if="!!form.payments.length"
              :rows="form.payments"
              :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
              header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
              cell-class-name="gs-col-padding-mini"
              fit
              :columns="{
                date: { sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                TransactionId: {
                  header: 'Transaction ID',
                  sortable: true,
                  filterField: true,
                  type: 'string',
                  elAttr: { width: 150 }
                },
                status: { sortable: true, filterField: true, type: 'string', elAttr: { width: 60 } },
                OfferId: { header: 'Offer ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
                price: { sortable: true, filterField: true, type: 'float', elAttr: { width: 100 } },
                logs: { header: 'Message', sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 200 } }
              }"
              :page-sizes="[50, 100, 200]"
              hide-refresh-btn
            >
              <template #cell_price="{ row }">
                {{ Number(row.price).toLocaleString() }}
              </template>
              <template #cell_logs="{ row }">
                <el-scrollbar :max-height="200">
                  {{ row.logs }}
                </el-scrollbar>
              </template>
            </filtering-table>
          </el-tab-pane>
          <el-tab-pane
            label="Token Swap"
            name="token_swap"
            lazy
          >
            <el-card
              shadow="never"
              class="mt-10"
            >
              <fields-col>
                <crud-field-text
                  :sm="6"
                  api-field-name="swapTokenPlayerId"
                  :rules="[$v.integer()]"
                  required
                />
                <crud-field-slot :sm="13">
                  <div class="leading-tight text-red-600">
                    This option swaps the tokens of this player and the selected one.<br>
                    You should be careful with this option. It should be used only when necessary.<br>
                    It should be used only when the player asks for the change. <br>
                    The swap should be justified (e.g. by matching payment history).<br>
                    <strong>Please provide a numeric player ID!</strong>
                  </div>
                </crud-field-slot>
                <crud-field-slot
                  :sm="4"
                  empty-label
                  label="Swap Tokens"
                  :disabled="!envApi"
                >
                  <el-button
                    class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                    :loading="loading.swapTokens"
                    @click="swapTokens(formRefer)"
                  >
                    Swap tokens
                  </el-button>
                </crud-field-slot>
              </fields-col>
            </el-card>
          </el-tab-pane>
          <el-tab-pane
            v-if="form.item.IsToDel"
            label="Cancel account deletion"
            name="cancelaccountdelete"
            editable
          >
            <el-card
              shadow="never"
              class="mt-10"
            >
              <crud-field-slot
                :sm="4"
                :offset="1"
                empty-label
                label="Cancel account delete"
              >
                <el-button
                  class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                  @click="cancelAccountDelete(maindata)"
                >
                  Cancel account deletion
                </el-button>
              </crud-field-slot>
            </el-card>
          </el-tab-pane>
          <el-tab-pane
            label="Edit"
            name="edit"
            lazy
          >
            <el-card
              shadow="never"
              class="mt-6"
            >
              <fields-col>
                <crud-field-text
                  :sm="6"
                  api-field-name="item.Name"
                  label="Player name"
                  :rules="[$v.maxLength(255)]"
                  required
                />
                <crud-field-slot
                  :sm="4"
                  empty-label
                  label="Edit player"
                >
                  <el-button
                    class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                    :disabled="!envApi"
                    :loading="loading.savePlayer"
                    @click="savePlayer(formRefer)"
                  >
                    Save player
                  </el-button>
                </crud-field-slot>
              </fields-col>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>

<style>
.el-col {
  position: relative;
}
.badge {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-shadow:
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
  position: absolute;
  z-index: 300;
}
.top-left {
  top: 0px;
  left: 2px;
}
.top-right {
  top: 0px;
  right: 6px;
}
.bottom-left {
  top: 46px;
  left: 2px;
}
.bottom-right {
  top: 46px;
  right: 6px;
}
.common {
  background-color: #dfdfdd;
}
.uncommon {
  background-color: #a4e631;
}
.rare {
  background-color: #60e2e7;
}
.epic {
  background-color: #cd82dc;
}
.legendary {
  background-color: #fabb16;
}
.artifact1,
.artifact2,
.artifact3,
.artifact4,
.artifact5 {
  background-color: #de1a00;
}
.el-badge.gs .el-badge__content {
  z-index: 600;
}
.el-badge.gs.top-right .el-badge__content {
  right: 40%;
}
</style>
