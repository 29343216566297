<script>
import { inject } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'CrudFieldColor',
  mixins: [BasicProps],
  setup(props) {
    const initForm = props.form ? {} : inject('initForm', {})
    const fieldNamePath = props.apiFieldName.split('.')
    const handleChange = (val) => {
      if (initForm?.value?.diffs) {
        if ((app.$utils.getByPath(initForm.value.form, fieldNamePath) || null) !== (val || null)) {
          initForm.value.diffs[props.apiFieldName] = true
        } else {
          delete initForm.value.diffs[props.apiFieldName]
        }
      }
    }
    return {
      handleChange
    }
  },
  methods: {
    onSetFieldModel(val) {
      return val === null ? '' : val
    }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <div class="pl-5">
          <el-color-picker
            v-model="fieldModel"
            size="large"
            :disabled="disabled"
            @change="handleChange"
          />
          {{ fieldModel }}
        </div>
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
