<script setup></script>

<template>
  <crud-details-page
    api="trade-shops-components/trade-shops-component"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Labels -->
        <crud-field-text api-field-name="Labels" />
        <!-- Tab -->
        <crud-field-number api-field-name="Tab" />
      </fields-col>
      <fields-col :sm="6">
        <!-- ReqLevel -->
        <crud-field-number
          api-field-name="ReqLevel"
          label="Required level"
        />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="6">
        <!-- Currency -->
        <crud-field-selector-items
          api-field-name="Currency"
          label="Currency [overrides shop currency!]"
        />
        <!-- BasicCurrency -->
        <crud-field-select
          api-field-name="BasicCurrency"
          label="Basic currency [overrides shop currency!]"
          options-enum="enums.BarterShopComponentBasicCurrency"
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- IsDailyLimit -->
        <crud-field-switcher api-field-name="IsDailyLimit" />
        <!-- IsAutoClear -->
        <crud-field-switcher api-field-name="IsAutoClear" />
      </fields-col>
      <fields-col>
        <section-divider>Thresholds</section-divider>
      </fields-col>
      <template
        v-for="i in 5"
        :key="i"
      >
        <!-- ThresholdItem -->
        <crud-field-selector-items
          :sm="6"
          :api-field-name="`Threshold${i}Item`"
          :label="`Item ${i}`"
        />
        <!-- ThresholdAmount -->
        <crud-field-number
          :sm="6"
          :api-field-name="`Threshold${i}Amount`"
          :label="`Item ${i} amount`"
        />
        <!-- ThresholdPrice -->
        <crud-field-number
          :sm="6"
          :api-field-name="`Threshold${i}Price`"
          :label="`Item ${i} price`"
        />
        <!-- ThresholdLimit -->
        <crud-field-number
          :sm="6"
          :api-field-name="`Threshold${i}Limit`"
          :label="`Item ${i} limit`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
