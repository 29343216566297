<script>
import { inject } from 'vue'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'
import { filterCascader } from '!/composition/filterCascader'

export default {
  name: 'TopSelector',
  components: { FieldsLabel },
  setup() {
    const searchFields = inject('searchFields')
    const options = [
      {
        value: 'incomplete',
        label: 'missing platform'
      },
      {
        value: 'invalid_sd',
        label: 'invalid SD'
      },
      {
        value: 'dev_only',
        label: 'Dev version'
      }
    ]
    const { settings, model, toggle } = filterCascader(options, searchFields)

    return {
      options,
      settings,
      model,
      searchFields,
      toggle
    }
  }
}
</script>

<template>
  <FieldsLabel
    class="ml-1.5"
    label="Filters"
  >
    <el-cascader
      v-model="model"
      v-bind="settings"
      :size="$store.getters['auth/userScaledSize']"
      :options="options"
      popper-class="gs-font-scaled"
    >
      <template #default="{ node, data }">
        <span
          class="inline-block w-full hover:text-blue-400"
          @click="toggle(node)"
        >
          {{ data.label }}
        </span>
      </template>
    </el-cascader>
  </FieldsLabel>
</template>

<style scoped></style>
