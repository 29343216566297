<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'EffectConditionShieldFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { conditionsOptions } = options
    const { initDefaultValues } = methods
    return {
      conditionsOptions,
      intensityColorOptions: [
        { value: 0, label: 'any' },
        { value: 1, label: 'blue' },
        { value: 2, label: 'white' },
        { value: 3, label: 'red' }
      ],
      intensityValueOptions: [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' }
      ],
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'conditionintensity',
      'color-code': 0,
      'value-from': 0,
      'value-to': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Type"
          :options="conditionsOptions"
          :clearable="false"
          slim
          style="max-width: 250px"
          doc-field-name="ConditionIntensity_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- color-code -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.color-code`"
          label="Color code"
          slim
          :clearable="false"
          :options="intensityColorOptions"
          doc-field-name="ConditionIntensity_Color_code"
          disable-doc-settings
        />
      </div>
      <!-- value-from -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.value-from`"
          label="Value from"
          slim
          :clearable="false"
          :options="intensityValueOptions"
          doc-field-name="ConditionIntensity_Value_from"
          disable-doc-settings
        />
      </div>
      <!-- value-to -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.value-to`"
          label="Value to"
          slim
          :clearable="false"
          :options="intensityValueOptions"
          doc-field-name="ConditionIntensity_Value_to"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
