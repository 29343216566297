import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import enums from './enums'
import main from './main'
import session from './session'

const dataStateLocal = createPersistedState({
  paths: ['auth', 'main', 'enums']
})
const dataStateSession = createPersistedState({
  storage: window.sessionStorage,
  paths: ['session']
})

export default (app) => {
  const store = createStore({
    modules: {
      auth: auth(app),
      enums: enums(app),
      main: main(app),
      session: session(app)
    },
    plugins: [dataStateLocal, dataStateSession]
  })
  app.use(store)
}
