<script setup></script>

<template>
  <crud-details-page
    api="guild/settings"
    disable-top-margin
  >
    <template #form>
      <template
        v-for="i in 3"
        :key="i"
      >
        <fields-col>
          <section-divider>Treasure donate {{ i }}</section-divider>
        </fields-col>
        <fields-col :sm="8">
          <!-- TreasureDonateGoldCost -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}GoldCost`"
            label="Gold cost"
          />
          <!-- TreasureDonatePremiumCCost -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}PremiumCCost`"
            label="Premium cost"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- TreasureDonateRewardGuildexp -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}RewardGuildexp`"
            label="Reward guild exp"
          />
          <!-- TreasureDonateRewardGuildfame -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}RewardGuildfame`"
            label="Reward guild fame"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- TreasureDonateRewardGuildcurrency -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}RewardGuildcurrency`"
            label="Reward guild currency"
          />
          <!-- TreasureDonateReqVipStatus -->
          <crud-field-number
            :api-field-name="`TreasureDonate${i}ReqVipStatus`"
            label="Required VIP status"
          />
        </fields-col>
        <fields-col>
          <section-divider>Guild Academy {{ i }}</section-divider>
        </fields-col>
        <crud-field-number
          :sm="6"
          api-field-name="AcademySingleTributeRewardGuildcurrency"
          label="Guild currency reward for each tribute"
        />
        <crud-field-selector-files
          v-for="k in 6"
          :key="k"
          :sm="6"
          :api-field-name="`AcademyRequiredTributeItem${k}`"
          :label="`Tribute item ${k}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
