<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldSelectorFiles',
  mixins: [BasicProps],
  props: {
    initFilters: {
      type: Object,
      default: () => ({
        // 'colNameApi': initValue
      })
    },
    staticGroup: {
      type: String,
      default: undefined
    },
    uploadBtn: {
      type: Boolean,
      default: false
    },
    uploadTitle: {
      type: String,
      default: undefined
    }
  },
  emits: ['change']
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <files-selector
          v-model="fieldModel"
          v-bind="$props"
          model-is-entity
          :label="false"
          full-width
          @change="$emit('change', $event)"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
