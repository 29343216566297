<script>
import CrudFieldSelectorCraftTabs from '@/components/forms/crud-fields/CrudFieldSelectorCraftTabs.vue'
import CrudFieldSelectorMilestones from '@/components/forms/crud-fields/CrudFieldSelectorMilestones.vue'
import CrudFieldSelectorBossGroups from '@/components/forms/crud-fields/CrudFieldSelectorBossGroups.vue'
import CrudFieldSelectorBattleEventSlotMultiplies from '@/components/forms/crud-fields/CrudFieldSelectorBattleEventSlotMultiplies.vue'
import CrudFieldSelectorWearableSets from '@/components/forms/crud-fields/CrudFieldSelectorWearableSets.vue'
import CrudFieldSelectorCrafts from '@/components/forms/crud-fields/CrudFieldSelectorCrafts.vue'
import CrudFieldSelectorItemTagGroups from '@/components/forms/crud-fields/CrudFieldSelectorItemTagGroups.vue'

export default {
  components: {
    CrudFieldSelectorCrafts,
    CrudFieldSelectorWearableSets,
    CrudFieldSelectorBattleEventSlotMultiplies,
    CrudFieldSelectorBossGroups,
    CrudFieldSelectorMilestones,
    CrudFieldSelectorCraftTabs,
    CrudFieldSelectorItemTagGroups
  }
}
</script>

<template>
  <crud-details-page
    api="events/battle-event"
    disable-top-margin
    enable-relations
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Start -->
        <crud-field-date-time
          api-field-name="Start"
          required
        />
        <!-- End -->
        <crud-field-date-time
          api-field-name="End"
          required
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- TopbarAsset -->
        <crud-field-selector-files
          api-field-name="TopbarAsset"
          required
        />
        <!-- LiveOpsStatus -->
        <crud-field-select
          api-field-name="LiveOpsStatus"
          options-enum="enums.LiveOpsCalendar"
          required
        />
        <el-col
          :span="24"
          class="bg-amber-100"
        >
          <el-row>
            <crud-field-item-tags api-field-name="ItemTags" />
          </el-row>
          <el-row>
            <CrudFieldSelectorItemTagGroups api-field-name="ItemTagGroup" />
          </el-row>
        </el-col>
      </fields-col>
      <fields-col :sm="8">
        <!-- CraftTab -->
        <CrudFieldSelectorCraftTabs api-field-name="CraftTab" />
        <!-- GameplayStart -->
        <crud-field-date-time
          api-field-name="GameplayStart"
          required
        />
        <!-- GameplayEnd -->
        <crud-field-date-time
          api-field-name="GameplayEnd"
          required
        />
      </fields-col>
      <fields-col>
        <section-divider>milestones</section-divider>
      </fields-col>
      <CrudFieldSelectorMilestones
        :sm="8"
        api-field-name="Milestone"
      />
      <CrudFieldSelectorMilestones
        :sm="8"
        api-field-name="GamePassMilestone"
      />
      <CrudFieldSelectorMilestones
        :sm="8"
        api-field-name="GuildMilestone"
      />
      <fields-col>
        <section-divider>locations gameplay</section-divider>
      </fields-col>
      <crud-field-selector-files
        :sm="8"
        api-field-name="LocationsBackgrAsset"
        label="Background asset"
      />
      <crud-field-number
        :sm="8"
        api-field-name="LocationsTicketsOnStart"
        label="Location tickets at event start"
      />
      <fields-col>
        <section-divider>bosses gameplay</section-divider>
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- BossesTicketsOnStart -->
        <crud-field-number
          api-field-name="BossesTicketsOnStart"
          label="Boss tickets at event start"
        />
        <!-- GuildBossTicketsOnStart -->
        <crud-field-number
          api-field-name="GuildBossTicketsOnStart"
          label="Guild boss tickets at event start"
        />
        <!-- MultiBossTicketsOnStart -->
        <crud-field-number
          api-field-name="MultiBossTicketsOnStart"
          label="Multi boss tickets at event start"
        />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- BossesNormalFightCost -->
        <crud-field-number
          api-field-name="BossesNormalFightCost"
          label="Tickets normal fight cost"
        />
        <!-- BossesPowerFightCost -->
        <crud-field-number
          api-field-name="BossesPowerFightCost"
          label="Tickets power fight cost"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- BossesIsGuildEnabled -->
        <crud-field-switcher
          api-field-name="BossesIsGuildEnabled"
          label="Guild bosses enabled"
        />
        <!-- BossGroup -->
        <CrudFieldSelectorBossGroups
          api-field-name="BossGroup"
          label="Battle boss group"
        />
      </fields-col>
      <fields-col>
        <section-divider>Multi boss base hero stats (base heropower)</section-divider>
      </fields-col>
      <crud-field-number
        :sm="6"
        api-field-name="MultiBossHeroDamage"
        label="Base damage"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiBossHeroHp"
        label="HP damage"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiBossHeroDefence"
        label="Defence damage"
      />
      <crud-field-number
        :sm="6"
        api-field-name="MultiBossHeroMagic"
        label="Magic damage"
      />
      <fields-col>
        <section-divider>BATTLE EVENT BOSSES/CAMPAIGN TALENTS PRESETS</section-divider>
      </fields-col>
      <crud-field-selector-items
        :sm="8"
        api-field-name="ForcedBossMultiTalent1Tpl"
        label="Forced blue talent"
        :init-filters="{ Class: 'talent' }"
      />
      <crud-field-selector-items
        :sm="8"
        api-field-name="ForcedBossMultiTalent2Tpl"
        label="Forced white talent"
      />
      <crud-field-selector-items
        :sm="8"
        api-field-name="ForcedBossMultiTalent3Tpl"
        label="Forced red talent"
      />
      <fields-col>
        <section-divider>GAMEPLAY MULTIPLIERS CONFIGURATION</section-divider>
      </fields-col>
      <fields-col>
        <fields-col
          :sm="6"
        >
          <!-- MultiplySlots -->
          <CrudFieldSelectorBattleEventSlotMultiplies api-field-name="MultiplySlots" />
          <!-- MultiplySlotsTopbar -->
          <crud-field-selector-files
            api-field-name="MultiplySlotsTopbar"
            label="Slots multipliers panel topbar"
          />
          <!-- MultiplyBoostPer -->
          <crud-field-number
            api-field-name="MultiplyBoostPer"
            label="Boost/enhance %"
            :precision="2"
          />
        </fields-col>
        <fields-col
          :sm="6"
        >
          <!-- MultiplyEmblem -->
          <CrudFieldSelectorWearableSets
            api-field-name="MultiplyEmblem"
            label="Bonus emblem"
          />
          <!-- MultiplyEmblemRareMul -->
          <crud-field-number
            api-field-name="MultiplyEmblemRareMul"
            label="Bonus emblem rare multiplier"
          />
          <!-- MultiplyEmblemEpicMul -->
          <crud-field-number
            api-field-name="MultiplyEmblemRareMul"
            label="Bonus emblem epic multiplier"
          />
          <!-- MultiplyEmblemEpicMul -->
          <crud-field-number
            api-field-name="MultiplyEmblemEpicMul"
            label="Bonus emblem legendary multiplier"
          />
        </fields-col>
        <fields-col
          :sm="6"
        >
          <!-- MultiplyEmblemArtifactMul -->
          <crud-field-number
            v-for="i in 5"
            :key="i"
            :api-field-name="`MultiplyEmblemArtifact${i}Mul`"
            :label="`Bonus emblem artifact${i} multiplier`"
          />
        </fields-col>
        <fields-col
          :sm="6"
        >
          <!-- FeaturedBoostCoef -->
          <crud-field-number
            api-field-name="FeaturedBoostCoef"
            label="Featured boost coef"
            :precision="2"
          />
          <!-- LegendaryBoostCoef -->
          <crud-field-number
            api-field-name="LegendaryBoostCoef"
            label="Legendary boost coef"
            :precision="2"
          />
          <!-- FeaturedSpiritColor -->
          <crud-field-select
            api-field-name="FeaturedSpiritColor"
            label="Featured talent color"
            options-enum="enums.SpiritColor"
          />
        </fields-col>
        <section-divider>Bonus promoted</section-divider>
        <template
          v-for="i in 3"
          :key="i"
        >
          <!-- MultiplyPromotedArtifact1Mul -->
          <crud-field-number
            v-for="k in 5"
            :key="k"
            class="flex-none"
            :api-field-name="`MultiplyPromoted${i}Artifact${k}Mul`"
            :label="`Item ${i} artifact ${k} multiplier`"
            :col-break="k === 5"
          />
        </template>
      </fields-col>
      <fields-col>
        <section-divider>FEATURED ITEMS</section-divider>
      </fields-col>
      <fields-col>
        <fields-table>
          <template #tHeader>
            <tr>
              <th>Nr</th>
              <th>Item</th>
              <th>Boost coef</th>
              <th>Multiplier</th>
              <th>Description</th>
              <th>Place</th>
              <th>Event tab</th>
              <th>Challenge craft</th>
            </tr>
          </template>
          <template #tBody>
            <tr
              v-for="row in 15"
              :key="row"
            >
              <fields-table-td
                :width="55"
                class="text-center"
              >
                {{ row }}
              </fields-table-td>
              <fields-table-td :width="270">
                <crud-field-selector-items
                  :api-field-name="`MultiplyItem${row}`"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="100">
                <crud-field-number
                  :api-field-name="`MultiplyItem${row}BoostCoef`"
                  :precision="2"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="100">
                <crud-field-number
                  :api-field-name="`MultiplyItem${row}Mul`"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="180">
                <crud-field-text
                  :api-field-name="`MultiplyItem${row}Description`"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="150">
                <crud-field-select
                  :api-field-name="`MultiplyItem${row}Place`"
                  options-enum="enums.QuestPlace"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="100">
                <crud-field-number
                  :api-field-name="`MultiplyItem${row}EventTabNo`"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
              <fields-table-td :width="220">
                <CrudFieldSelectorCrafts
                  :api-field-name="`MultiplyItem${row}ChallengeCraft`"
                  slim
                  :label="false"
                  :min-height="false"
                />
              </fields-table-td>
            </tr>
          </template>
        </fields-table>
      </fields-col>
    </template>
  </crud-details-page>
</template>
