<script>
export default {
  name: 'PageManuSubmenu',
  props: {
    icon: {
      type: [String, Boolean],
      default: false
    },
    classIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      // should be unique for all manu
      type: String,
      default: ''
    }
  },
  computed: {
    index() {
      return this.id || Date.now() + Math.random().toString(36).substring(2, 7)
    },
    isActive() {
      return (this.$route?.meta?.IdParentSubmenu || []).includes(this.id)
    }
  },
  mounted() {}
}
</script>

<template>
  <el-sub-menu
    :index="`submenu-${index}`"
    class="text-gs-menu-txt"
    :class="{
      active: isActive
    }"
    popper-class="gs-menu-sub"
  >
    <template #title>
      <template v-if="$store.getters['main/pageMenuCollapse']">
        <template v-if="$slots?.icon_collapsed">
          <el-icon :class="classIcon">
            <slot name="icon_collapsed" />
          </el-icon>
        </template>
        <template v-else>
          <el-icon
            v-if="icon"
            :class="[classIcon]"
            class="gs-scaled-icon-lg"
          >
            <icon-ify
              :icon="icon"
              class="h-full w-full"
            />
          </el-icon>
          <el-icon
            v-else-if="icon !== false"
            class="gs-menu-no-icon gs-scaled-icon-lg"
            :class="classIcon"
          />
        </template>
      </template>
      <template v-else>
        <template v-if="$slots?.icon">
          <el-icon
            :class="[classIcon]"
            class="gs-scaled-icon-lg"
          >
            <slot name="icon" />
          </el-icon>
        </template>
        <template v-else>
          <el-icon
            v-if="icon"
            :class="[classIcon]"
            class="gs-scaled-icon-lg"
          >
            <icon-ify
              :icon="icon"
              class="h-full w-full"
            />
          </el-icon>
          <el-icon
            v-else-if="icon !== false"
            class="gs-scaled-icon-lg"
            :class="classIcon"
          />
        </template>
      </template>
      <span class="gs-font-scaled leading-none">
        <span class="font-related-lg">
          <slot name="title">
            <span :class="$attrs?.class">{{ title }}</span>
          </slot>
        </span>
      </span>
    </template>
    <el-menu-item-group class="no-title">
      <slot name="default" />
    </el-menu-item-group>
  </el-sub-menu>
</template>
