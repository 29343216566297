<script>
import { Search } from '@element-plus/icons-vue'

export default {
  name: 'ImportFromCsv',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    entity: {
      type: String,
      required: true
    },
    versioned: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'imported'],
  setup() {
    return {
      Search
    }
  },
  data() {
    return {
      textarea: '',
      loading: false,
      showPreview: false
    }
  },
  computed: {
    visibleModel: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    textareaToTable() {
      return this.showPreview ? this.$papa.parse(this.textarea)?.data || [] : ''
    }
  },
  methods: {
    uploadData() {
      this.loading = true
      this.$axios
        .post(this.versioned ? '/admin/api/entities/import/versioned/' : '/admin/api/entities/import/', {
          entity: this.entity,
          text: this.textarea,
          route: this.$route.name
        })
        .then(() => {
          this.textarea = ''
          this.$emit('imported')
          this.$message({
            message: 'imported',
            type: 'success',
            offset: 40
          })
          this.visibleModel = false
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading = false
        })
    },
    clear() {
      this.loading = false
      this.textarea = ''
      this.showPreview = false
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="visibleModel"
    :close-on-click-modal="false"
    :width="$windowWidth < 640 ? '95%' : '60%'"
    @close="clear"
  >
    <el-input
      v-model.trim="textarea"
      :autosize="{ minRows: 5, maxRows: 10 }"
      type="textarea"
    />

    <transition name="el-fade-in-linear">
      <div v-if="showPreview">
        <div class="font-related-sm pb-1 pt-4 text-neutral-400">
          Preview:
        </div>
        <el-scrollbar
          :height="400"
          class="gs-scaled bg-neutral-50"
        >
          <table class="whitespace-nowrap px-4 py-2">
            <tr
              v-for="(row, rowI) in textareaToTable"
              :key="rowI"
              :class="{ 'sticky top-0 z-10 bg-neutral-100': !rowI }"
            >
              <td
                v-for="(td, tdI) in row"
                :key="tdI"
                class="px-2 py-1"
              >
                <div class="whitespace-pre-line">
                  {{ td }}
                </div>
              </td>
            </tr>
          </table>
        </el-scrollbar>
      </div>
    </transition>

    <template #header>
      <span class="gs-font-scaled"><span class="font-related-xl">Paste data from google excel</span></span>
    </template>
    <template #footer>
      <div style="text-align: right; margin: 0">
        <el-tooltip
          content="Show preview"
          placement="top"
          effect="light"
          :show-after="400"
        >
          <el-button
            :size="$store.getters['auth/userScaledMediumSize']"
            class="gs-font-scaled"
            :icon="Search"
            circle
            @click="showPreview = !showPreview"
          />
        </el-tooltip>
        <el-button
          :size="$store.getters['auth/userScaledMediumSize']"
          class="gs-font-scaled"
          type=""
          @click="visibleModel = false"
        >
          cancel
        </el-button>
        <el-button
          :size="$store.getters['auth/userScaledMediumSize']"
          class="gs-loading gs-btn-primary gs-font-scaled"
          type="primary"
          :loading="loading"
          :disabled="!textarea?.length || textarea.split('\n').length < 2"
          @click="uploadData"
        >
          upload
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped></style>
