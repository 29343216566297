<script>
import { inject, ref } from 'vue'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'
import { initDateMask, initShortcutsDate } from '!/composition/dateTimePickerExtensions'

export default {
  name: 'CrudFieldDate',
  mixins: [BasicProps],
  props: {
    placeholder: {
      type: String,
      default: 'Select date'
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    valueFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const initForm = props.form ? {} : inject('initForm', {})
    const crudFormRefer = inject('crudFormRefer', {})
    const fieldNamePath = props.apiFieldName.split('.')
    const handleChange = (val) => {
      if (initForm?.value?.diffs) {
        if ((app.$utils.getByPath(initForm.value.form, fieldNamePath) || null) !== (val || null)) {
          initForm.value.diffs[props.apiFieldName] = true
        } else {
          delete initForm.value.diffs[props.apiFieldName]
        }
      }
    }

    const pickerDate = ref(null)
    const { initMaskDate } = initDateMask(pickerDate, crudFormRefer, props.apiFieldName)

    return {
      handleChange,
      initMaskDate
    }
  },
  data() {
    return {
      input: null
    }
  },
  computed: {
    scaleClass() {
      const classesMap = {
        small: undefined,
        default: 'scale-110',
        large: 'scale-125'
      }
      return classesMap?.[this.$store.getters['auth/userScaledSize']]
    }
  },
  created() {
    this.shortcuts = initShortcutsDate(this, 'fieldModel', this.format)
  },
  methods: {
    onSetFieldModel(val) {
      return val ? `${val}T00:00:00Z` : val
    }
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    type="date"
    component="el-date-picker"
  >
    <template #default>
      <slot>
        <!-- eslint-disable vue/no-unused-refs -->
        <el-date-picker
          ref="pickerDate"
          v-model="fieldModel"
          v-bind="$attrs"
          :disabled="disabled"
          class="gs-field"
          :class="[classComponent, { 'w-full': fullWidth }]"
          type="date"
          :placeholder="placeholder"
          :format="format"
          :value-format="valueFormat"
          :popper-class="scaleClass"
          :shortcuts="shortcuts"
          @focus.once="initMaskDate"
          @change.once="initMaskDate"
          @change="handleChange"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
