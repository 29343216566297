<script setup></script>

<template>
  <crud-details-page
    api="events/tier-ranking-reward"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.TierRankingType"
          required
        />
        <!-- Tier -->
        <crud-field-select
          api-field-name="Tier"
          options-enum="enums.TierPercentDistribution"
          required
        />
        <!-- Tier0PositionMin -->
        <crud-field-number api-field-name="Tier0PositionMin" />
        <!-- Tier0PositionMax -->
        <crud-field-number api-field-name="Tier0PositionMax" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Premium -->
        <crud-field-number api-field-name="Premium" />
      </fields-col>
      <fields-col
        :sm="12"
      >
        <template
          v-for="i in 3"
          :key="i"
        >
          <!-- Item -->
          <crud-field-selector-items
            :sm="12"
            :api-field-name="`Item${i}`"
          />
          <!-- Amount -->
          <crud-field-number
            :sm="12"
            :api-field-name="`Amount${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
