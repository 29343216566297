<script>
import { mapGetters } from 'vuex'
// el+ BUG
// added because of bug of el+ https://element-plus.run/#eyJBcHAudnVlIjoiPHRlbXBsYXRlPlxuICA8ZGl2PlxuICAgIDxlbC1jaGVja2JveCB2LW1vZGVsPVwic2VhcmNoRmllbGRzLmNoZWNrZWQxXCIgbGFiZWw9XCJPcHRpb24gMVwiIHNpemU9XCJsYXJnZVwiIC8+XG4gICAge3tzZWFyY2hGaWVsZHN9fVxuICA8L2Rpdj5cbiAgPGRpdiBAY2xpY2s9XCJzZWFyY2hGaWVsZHMgPSB7fVwiPlxuICAgIDxidXR0b24+XG4gICAgICBjbGljayB3aGVuIGNoZWNrZWQgdG8gcmVzZXRcbiAgICA8L2J1dHRvbj5cbiAgPC9kaXY+XG4gIDxpPnNldCBlbXB0eSBvYmplY3Qgbm90IHJlc2V0IGNoZWNrYm94IChsaWtlIGVnIGZvciBlbC1zd2l0aCk8L2k+XG48L3RlbXBsYXRlPlxuXG48c2NyaXB0ID5cbmV4cG9ydCBkZWZhdWx0IHtcbiAgZGF0YSgpIHtcbiAgICByZXR1cm4ge1xuICAgICAgc2VhcmNoRmllbGRzOiB7fSxcbiAgICB9XG4gIH1cbn1cbjwvc2NyaXB0PlxuIiwiaW1wb3J0X21hcC5qc29uIjoie1xuICBcImltcG9ydHNcIjoge31cbn0iLCJfbyI6e319
export default {
  name: 'GsCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: undefined,
      default: undefined
    },
    trueValue: {
      type: undefined,
      default: true
    },
    falseValue: {
      type: undefined,
      default: undefined
    },
    colorActive: {
      type: String,
      default: undefined
    },
    colorInactive: {
      type: String,
      default: undefined
    },
    bordered: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      model: false
    }
  },
  computed: {
    checkboxValue: {
      get() {
        return this.model
      },
      set() {
        this.model = !this.model
        this.$emit('update:modelValue', this.model ? this.trueValue : this.falseValue)
      }
    },
    checkBoxColor() {
      return this.checkboxValue ? this.colorActive : this.colorInactive
    },
    ...mapGetters({
      userScaledSize: 'auth/userScaledSize'
    })
  },
  watch: {
    modelValue: {
      handler(val) {
        if (val === 'true' && this.trueValue === true && !this.model) {
          this.model = true
        } else if ((val === this.trueValue) !== this.model) {
          this.model = val === this.trueValue
        }
      },
      immediate: true
    }
  }
}
</script>

<template>
  <label
    class="el-checkbox mr-1.5 cursor-pointer px-1.5 text-xs"
    :class="[{ 'is-checked': checkboxValue, 'is-bordered': bordered }, `el-checkbox--${userScaledSize}`]"
    :style="[{ 'border-color': checkBoxColor }]"
  >
    <span
      class="el-checkbox__input"
      :class="{ 'is-checked': checkboxValue }"
    >
      <input
        v-model="checkboxValue"
        type="checkbox"
        class="hidden"
      >
      <span
        class="el-checkbox__inner"
        :style="[{ 'background-color': checkboxValue ? checkBoxColor : undefined, 'border-color': checkBoxColor }]"
      />
    </span>
    <span
      class="el-checkbox__label pl-1.5 font-light"
      :style="[{ color: checkBoxColor }]"
    >
      {{ label }}
    </span>
  </label>
</template>

<style scoped></style>
