import { debounce } from 'lodash'

const conditionsOptions = {
  conditionshield: 'shield',
  conditionintensity: 'intensity'
}
const options = {
  effectOptions: [
    { value: 'hit', label: 'hit' },
    { value: 'heal', label: 'heal' },
    { value: 'attribute', label: 'attribute' },
    { value: 'resurrection', label: 'resurrection' },
    { value: 'stun', label: 'stun' },
    { value: 'ball', label: 'ball' },
    { value: 'activeboost', label: 'activeboost' },
    { value: 'chain', label: 'chain' },
    { value: 'remove_intensity', label: 'remove intensity' },
    { value: 'shield_revive', label: 'shield revive' },
    { value: 'shield_alter', label: 'shield alter' }
  ],
  conditionsOptions: [
    { value: 'conditionshield', label: conditionsOptions.conditionshield },
    { value: 'conditionintensity', label: conditionsOptions.conditionintensity }
  ],
  targetTeamOptions: [
    { value: 'self', label: 'self' },
    { value: 'enemy', label: 'enemy' },
    { value: 'friend', label: 'friend' }
  ],
  targetRangeOptions: [
    { value: 'single', label: 'single' },
    { value: 'all', label: 'all' },
    { value: 'random_one', label: 'random one' },
    { value: 'use_from_previous_effect', label: 'use from previous effect' }
  ],
  targetRowOptions: [
    { value: 'any', label: 'any' },
    { value: 'front', label: 'front' },
    { value: 'back', label: 'back' }
  ],
  srcOptions: [
    { value: 'attack', label: 'attack' },
    { value: 'magic', label: 'magic' }
  ],
  booleanOptions: [
    { value: 1, label: 'yes' },
    { value: 0, label: 'no' }
  ],
  buffAssetsOptions: [
    { value: 'no_icon', label: 'no icon' },
    { value: 'active_boost', label: 'active boost' },
    { value: 'active_boost_blue', label: 'active boost blue' },
    { value: 'active_boost_red', label: 'active boost red' },
    { value: 'active_boost_white', label: 'active boost white' },
    { value: 'bleeding', label: 'bleeding' },
    { value: 'burning', label: 'burning' },
    { value: 'crit_up', label: 'crit up' },
    { value: 'crit_down', label: 'crit down' },
    { value: 'dodge_up', label: 'dodge up' },
    { value: 'dodge_down', label: 'dodge down' },
    { value: 'freeze', label: 'freeze' },
    { value: 'heal', label: 'heal' },
    { value: 'magic_up', label: 'magic up' },
    { value: 'magic_down', label: 'magic down' },
    { value: 'magic_resistance', label: 'magic resistance' },
    { value: 'magic_resistance_down', label: 'magic resistance down' },
    { value: 'melee_up', label: 'melee up' },
    { value: 'melee_down', label: 'melee down' },
    { value: 'melee_resistance', label: 'melee resistance' },
    { value: 'melee_resistance_down', label: 'melee resistance down' },
    { value: 'poison', label: 'poison' },
    { value: 'prevent_stun', label: 'prevent stun' },
    { value: 'shield_up', label: 'shield up' },
    { value: 'shield_down', label: 'shield down' },
    { value: 'stun', label: 'stun' }
  ],
  colorOptions: [
    { value: 'white', label: 'white' },
    { value: 'red', label: 'red' },
    { value: 'blue', label: 'blue' },
    { value: 'random', label: 'random' }
  ]
}

const methods = {
  initDefaultValues(values = {}) {
    const path = `effectRows.${this.rowIndex}.data`
    this.$utils.setByPath(this.form, path, { ...values, ...this.$utils.getByPath(this.form, path, {}) })
  },
  isConditionType(type) {
    return ['conditionshield', 'conditionintensity'].includes(type)
  },
  getAssetsRows(effectsRows) {
    const rows = []
    effectsRows.some((effectRow, index) => {
      if (!effectRow.isConditionType) {
        rows.push({ ...effectRow, effectIndex: index })
      }
      return rows.length >= 9
    })
    return rows
  }
}

const stringMap = {
  hit: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'times', // Times
    'src', // Value source
    'is-percent', // Use % of value  yes: 1, no: 0
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value', // Increment per level
    'skip-defence-min', // Skip def. min %
    'skip-defence-max', // Skip def. max %
    'dlvl-skip-defence', // Increment per level
    'source-change', // Source Change %
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'add-defence-value', // Defence hit %
    'add-defence-dlvl-value' // Increment per level
  ],
  heal: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'times', // Times
    'src', // Value source
    'is-percent', // Use % of value  yes: 1, no: 0
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value' // Increment per level
  ],
  attribute: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'kind', // Kind,
    'is-percent', // it is not visible
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value', // Increment per level
    'duration', // Duration
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'buff-asset' // Buff asset
  ],
  resurrection: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'is-percent', // Use % of HP,
    'hp-min', // HP value min
    'hp-max', // HP value max
    'dlvl-hp', // Increment per level
    'chance', // Chance %
    'dlvl-chance' // Chance inc per lvl
  ],
  stun: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'value', // Stun turns
    'chance', // Chance %
    'dlvl-chance', // Chance inc per lvl
    'buff-asset', // Buff asset
    'prevent-value' // Prevent turns
  ],
  ball: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'effect', // Kind
    'color', // Color
    'min', // Ball No. min
    'max', // Ball No. max
    'chance', // Chance %
    'dlvl-chance' // Chance increment per level
  ],
  activeboost: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'buff-asset', // Buff asset
    'ch_colors', // Colors
    'ch_balls', // Balls
    'value-min', // Value min
    'value-max', // Value max
    'dlvl-value', // Increment per level
    'duration' // Duration
  ],
  chain: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'chance', // Chance %,
    'dlvl-chance', // Chance increment per level
    'color', // Triggered color
    'balls' // Triggered balls
  ],
  remove_intensity: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'chance', // Chance %,
    'dlvl-chance' // Chance increment per level
  ],
  shield_revive: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'chance', // Chance %,
    'dlvl-chance', // Chance inc per level
    'value', // Shield value after revive %
    'dlvl-value' // Value inc per level
  ],
  shield_alter: [
    'target-team', // Target team
    'target-range', // Target range
    'target-row', // target-row Target row
    'times', // Times
    'min-value', // Value min
    'max_value', // Value max
    'dlvl-value' // Increment per level
  ],
  // condition:          ['state', 'color-code', 'value-from', 'value-to'],
  conditionshield: [
    'state' // Shield state
  ],
  conditionintensity: [
    'color-code', // Color code
    'value-from', // Value from
    'value-to' // Value to
  ]
}
const buildEffectString = debounce((effectsRows, assignCallBack) => {
  const newEffectStringData = []
  effectsRows.forEach((row) => {
    const newRowStringData = []
    if (stringMap?.[row.type]) {
      stringMap?.[row.type].forEach((field) => {
        let val
        switch (field) {
          case 'dlvl-value':
          case 'dlvl-skip-defence':
          case 'dlvl-chance':
          case 'add-defence-dlvl-value':
          case 'dlvl-hp':
            val = String(row?.data?.[field])
            if (!val.includes('.')) {
              val += '.0'
            }
            break
          case 'ch_balls':
          case 'ch_colors':
            val = 0
            if (row?.data?.[field]?.length) {
              row.data[field].forEach((sumVale) => {
                val += sumVale
              })
            }

            break
          default:
            val = row?.data?.[field]
        }

        // console.log(field, row?.data?.[field], row?.data?.[field] === undefined ? '???!!!!!!!!' : '???')
        newRowStringData.push(val)
      })
    }
    if (methods.isConditionType(row.type)) {
      newEffectStringData.push(`condition=${conditionsOptions[row.type]},${newRowStringData.join(',')}`)
    } else {
      newEffectStringData.push(`${row.type}=${newRowStringData.join(',')}`)
    }
  })
  assignCallBack(newEffectStringData.join(';'))
}, 500)

function renderEffectString(effectString, assignCallBack) {
  const effectsRowsData = effectString.split(';')
  effectsRowsData.forEach((row, index) => {
    effectsRowsData[index] = row.split(',')
    if (effectsRowsData[index]?.[0]) {
      effectsRowsData[index][0] = effectsRowsData[index][0].split('=')
    }
    const formRow = {
      type: effectsRowsData[index][0][0],
      initData: {
        effectType: effectsRowsData[index][0][0]
      },
      rowIndex: index,
      stringData: effectsRowsData[index]
    }
    effectsRowsData[index][0] = effectsRowsData[index][0][1]
    const isConditionRow = formRow.type === 'condition'
    effectsRowsData[index].forEach((val, index) => {
      const isNumber = /^-?\d*(?:[.,]\d*)?$/.test(val)
      const renderedVal = isNumber ? val * 1 : val
      if (isConditionRow) {
        if (index === 0) {
          formRow.type = `condition${val}`
          formRow.initData.effectType = formRow.type
        } else {
          formRow.initData[stringMap[formRow.type][index - 1]] = renderedVal
        }
      } else {
        formRow.initData[stringMap[formRow.type][index]] = renderedVal
        if (['ch_balls', 'ch_colors'].includes(stringMap[formRow.type][index])) {
          const val = []
          const checkValues = [1, 2, 4]
          checkValues.forEach((checkVal) => {
            if (checkVal & renderedVal) {
              val.push(checkVal)
            }
          })
          formRow.initData[stringMap[formRow.type][index]] = val
        }
      }
    })
    effectsRowsData[index] = formRow
  })
  assignCallBack(effectsRowsData)
}

const props = {
  props: {
    rowIndex: {
      type: Number,
      default: 0
    },
    form: {
      type: Object,
      default: undefined
    },
    externalErrors: {
      type: Object,
      default: undefined
    }
  }
}

export { options, methods, props, stringMap, buildEffectString, renderEffectString }
