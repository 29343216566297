<script>
import CrudFieldSelectorBarberShops from '@/components/forms/crud-fields/CrudFieldSelectorBarberShops.vue'
import { autoFillGraphic } from '@/composition/autoFillGraphic'

export default {
  name: 'UsableDetailsPage',
  components: { CrudFieldSelectorBarberShops },
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.Class = 'usable'
        itemData.ApIsSearchable = true
        itemData.PriceGold = 0
        itemData.PricePremium = 0
        itemData.SortingOrder = 0
        itemData.ReqLevel = 0
        itemData.AmountRequiredToUse = 1
        itemData.Target = 'none'
      }
      return itemData
    },
    autoFillGraphic
  }
}
</script>

<template>
  <crud-details-page
    :back-routes="['items-all']"
    api="items/item"
    :render-init-item="initForm"
    enable-relations
    doc-key="items-usable"
  >
    <template #form="{ form }">
      <fields-col
        :sm="6"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Quality -->
        <crud-field-select
          api-field-name="Quality"
          options-enum="enums.ItemQuality"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files
          api-field-name="Graphic"
          required
          @change="autoFillGraphic($event, form, { sd: 'IconSd', hd: 'IconHd', prev: 'Preview' })"
        />
        <!-- Icon SD -->
        <crud-field-selector-files
          api-field-name="IconSd"
          label="Icon SD"
          required
        />
        <!-- IconHd -->
        <crud-field-selector-files
          api-field-name="IconHd"
          label="Icon HD"
          required
        />
        <!-- Preview -->
        <crud-field-selector-files
          api-field-name="Preview"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Description -->
        <crud-field-selector-client-strings api-field-name="Description" />
        <!-- Price gold -->
        <crud-field-number api-field-name="PriceGold" />
        <!-- Price premium -->
        <crud-field-number api-field-name="PricePremium" />
        <!-- Target -->
        <crud-field-select
          api-field-name="Target"
          options-enum="enums.UsableTarget"
        />
        <!-- CraftTab -->
        <crud-field-selector-items
          api-field-name="CraftTab"
          label="Challenge craft tab"
          :disabled="form.Target !== 'city_craft'"
        />
        <!-- Sorting order -->
        <crud-field-number
          api-field-name="SortingOrder"
          :max="1000000"
        />
        <!-- IsResetAtBattleEventStart -->
        <crud-field-switcher api-field-name="IsResetAtBattleEventStart" />
      </fields-col>
      <fields-col
        :sm="12"
      >
        <!-- Labels -->
        <crud-field-textarea
          api-field-name="Labels"
          :textarea-rows="1"
          required
        />
        <fields-col
          :sm="12"
        >
          <!-- Amount required to use -->
          <crud-field-number api-field-name="AmountRequiredToUse" />
          <!-- Effect -->
          <crud-field-select
            api-field-name="Effect"
            options-enum="enums.UsableEffect"
          />
          <!-- Effect param -->
          <crud-field-number
            label="Effect param"
            api-field-name="EffectParam1"
          />
          <!-- Barber -->
          <CrudFieldSelectorBarberShops api-field-name="Barber" />
          <el-col
            :span="24"
            class="bg-amber-100"
          >
            <el-row>
              <crud-field-item-tags api-field-name="ItemTags" />
            </el-row>
            <el-row>
              <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
            </el-row>
          </el-col>
        </fields-col>
        <fields-col :sm="12">
          <!-- Is hidden -->
          <crud-field-switcher api-field-name="IsHidden" />
          <!-- Do not send to inventory -->
          <crud-field-switcher api-field-name="DontSendToInventory" />
          <!-- Admin type -->
          <crud-field-select
            api-field-name="ApType"
            label="Admin type"
            options-enum="enums.AdminPanelTypeItems"
          />
          <!-- Required level (to show on items list) -->
          <crud-field-number
            label="Required level (to show on items list)"
            api-field-name="ReqLevel"
          />
        </fields-col>
      </fields-col>
      <fields-col
        :sm="24"
        justify="end"
      >
        <!-- Admin panel searchable -->
        <crud-field-switcher
          api-field-name="ApIsSearchable"
          label="Admin panel searchable"
          col-class="mt-10"
          class="flex-none"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
