<template>
  <crud-table
    :columns-settings="{
      Exp: { header: 'Experience' }
    }"
    :default-visible-columns="['Level', 'Exp']"
    api="battle/level-exp-costs"
    api-item="battle/level-exp-costs"
  />
</template>
