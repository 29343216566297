import axios from 'axios'
import { saveLoginRedirect } from '!/composition/utilities'
import { LayoutSettings } from '@/settings/defaultMeta.js'

let loginRequiredLast = false

export default {
  install: (Vue) => {
    const globalProperties = Vue.config.globalProperties
    let apiBaseUrl = import.meta.env.VITE_API_URL
    if (LayoutSettings?.gameSelectorSettings?.length) {
      const gameSetting = globalProperties.$store.getters['main/gameApiSettings']
      if (gameSetting?.env_variable) {
        if (!import.meta.env[gameSetting.env_variable]) {
          globalProperties.$notify({
            title: 'Config error',
            type: 'warning',
            customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
            message: `Not defined env variable (${gameSetting.env_variable}). Used default api.`,
            duration: 0
          })
        }
        apiBaseUrl = import.meta.env[gameSetting.env_variable] || apiBaseUrl
      }
    }
    const instance = axios.create({
      baseURL: apiBaseUrl
    })
    instance.interceptors.request.use((config) => {
      config.headers.Authorization = Vue.config.globalProperties.$store.getters['auth/token']
      return config
    })

    instance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data...
        return response
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error?.response?.status === 401) {
          let moreInfo = ''
          if (LayoutSettings?.gameSelectorSettings?.length) {
            moreInfo = ` (${globalProperties.$store.getters['main/gameApiSettings']?.name || error?.config?.baseURL})`
            globalProperties.$store.commit('main/setGameApiSettings', {})
          }
          if (!loginRequiredLast) {
            loginRequiredLast = true
            globalProperties.$notify({
              title: error?.response?.data?.error || 'Error',
              type: 'warning',
              customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
              message: error?.response?.data?.reason + moreInfo || 'unauthorized',
              duration: 9000
            })
            setTimeout(() => {
              loginRequiredLast = false
            }, 2000)
          }
          let prevRedirectRoute
          try {
            prevRedirectRoute = JSON.parse(localStorage.getItem('login-redirect-prev'))
          } catch (_e) {
            prevRedirectRoute = null
          }
          if (prevRedirectRoute?.name && globalProperties.$route.name === prevRedirectRoute?.name) {
            localStorage.removeItem('login-redirect')
            localStorage.removeItem('login-redirect-prev')
          } else {
            saveLoginRedirect(globalProperties.$route)
            saveLoginRedirect(globalProperties.$route, true)
          }
          globalProperties.$store.dispatch('auth/logout')
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(false)
        } else if (error?.response?.status > 400) {
          globalProperties.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
            message:
              `${error?.response?.data?.error ? `${error.response.data.error} ${error.response.data?.reason}` : error}` ||
              'Oops something went wrong',
            duration: 9000
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(false)
        } else {
          return Promise.reject(error)
        }
      }
    )

    Vue.config.globalProperties.$axios = instance
  }
}
