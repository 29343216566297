import { LoginPageSettings } from '@/settings/defaultMeta.js'

export default (app) => {
  return {
    namespaced: true,
    state() {
      return {
        token: '',
        userData: {},
        userRights: {},
        userLocalSettings: {},
        languages: [],
        envs: {}
      }
    },
    mutations: {
      setToken(state, token) {
        // $store.commit('auth/setToken')
        state.token = token || ''
      },
      setUserData(state, data) {
        // $store.commit('auth/setUserData')
        state.userData = data || {}
      },
      setUserRights(state, data) {
        // $store.commit('auth/setUserData')
        const roles = data || []
        state.userRights =
          Object.fromEntries(
            roles.map((role) => {
              return [role, true]
            })
          ) || {}
      },
      userLocalSettings(state, data) {
        // $store.commit('auth/userLocalSettings')
        state.userLocalSettings = data || {}
      },
      setLanguages(state, data) {
        // $store.commit('auth/setLanguages')
        state.languages = data || []
      },
      setEnvs(state, data) {
        // $store.commit('auth/setEnvs')
        state.envs = data || {}
      }
    },
    getters: {
      token: (state) => {
        // $store.getters['auth/token'];
        return state.token
      },
      userData: (state) => {
        // $store.getters['auth/userData'];
        return state.userData
      },
      userRights: (state) => {
        // $store.getters['auth/userRights'];
        return state.userRights
      },
      userLanguages: (state) => {
        // $store.getters['auth/userLanguages'];
        return state.languages
      },
      userLocalSettings: (state) => {
        // $store.getters['auth/userLocalSettings'];
        return state.userLocalSettings
      },
      userScaledFontSize: (state) => {
        return `${state.userLocalSettings?.fontSize || 12}px`
      },
      userScaledRatio: (state) => {
        const key = Math.ceil((20 - (state.userLocalSettings?.fontSize || 12)) / 3)
        const ratio = [1.2, 1.1, 1]
        return ratio[Math.max(0, key - 1)]
      },
      userScaledRatioWidth: (state) => {
        return (state.userLocalSettings?.fontSize || 12) / 12
      },
      userScaledWidth: (state) => {
        return ((state.userLocalSettings?.fontSize || 12) - 12) * 2
      },
      userScaledClass: (state) => {
        const key = Math.ceil((20 - (state.userLocalSettings?.fontSize || 12)) / 2)
        const classes = ['xl', 'lg', 'md', 'sm']
        return `gs-scaled-${classes[Math.max(0, key - 1)]}`
      },
      userScaledSize: (state) => {
        const key = Math.ceil((20 - (state.userLocalSettings?.fontSize || 12)) / 3)
        const classes = ['large', 'default', 'small']
        return classes[Math.max(0, key - 1)]
      },
      userScaledMediumSize: (state) => {
        const key = Math.ceil((20 - (state.userLocalSettings?.fontSize || 12)) / 4)
        const classes = ['default', 'small']
        return classes[Math.max(0, key - 1)]
      },
      userScaledLargeSize: (state) => {
        const key = Math.ceil((20 - (state.userLocalSettings?.fontSize || 12)) / 4)
        const classes = ['large', 'default']
        return classes[Math.max(0, key - 1)]
      },
      envs: (state) => {
        // $store.getters['auth/envs'];
        return state.envs
      }
    },
    actions: {
      // actions in Vuex are asynchronous
      async login({ commit, dispatch }, event) {
        // $store.dispatch('auth/login');
        commit('setToken', event.token)
        await dispatch('loadUser')
        if (LoginPageSettings.loadEnvs !== false) {
          await dispatch('loadEnvs')
        }
      },
      async reLoadUser({ dispatch }, forcedApi) {
        // $store.dispatch('auth/reLoadUser');
        app.config.globalProperties.$store.commit('enums/clearAll')
        await dispatch('loadUser', forcedApi)
        if (LoginPageSettings.loadEnvs !== false) {
          await dispatch('loadEnvs', forcedApi)
        }
      },
      logout({ commit }) {
        // $store.dispatch('auth/logout');
        app.config.globalProperties.$router.push({ name: 'login' })
        commit('setToken', '')
        commit('setUserData', {})
        commit('setUserRights', [])
        commit('setLanguages', [])
        app.config.globalProperties.$store.commit('enums/clearAll')
      },
      async loadUser({ commit, dispatch }, forcedApi = '') {
        // $store.dispatch('auth/loadUser');
        await app.config.globalProperties.$axios
          .get(`${forcedApi}/admin/api/logged/user/`)
          .then(({ data }) => {
            app.config.globalProperties.$store.dispatch('main/initCrudStorage', data?.settings)
            commit('setUserData', data?.user)
            commit('setUserRights', [...(data?.roles || []), 'user'])
            const languages = data?.languages || []
            commit(
              'setLanguages',
              languages.map((lang) => {
                const lower = lang.toLowerCase()
                return lower[0].toUpperCase() + lower.slice(1)
              })
            )
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err)
            dispatch('logout')
          })
      },
      async loadEnvs({ commit }, forcedApi = '') {
        await app.config.globalProperties.$axios
          .get(`${forcedApi}/admin/api/environments/`)
          .then(({ data }) => {
            commit('setEnvs', data?.environments)
          })
          .catch(app.config.globalProperties.$utils.catchError)
      }
    }
  }
}
