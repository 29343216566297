const FilteringData = {
  data() {
    return {
      crudFilters: {},
      crudSearchFields: {},
      crudSelectIds: {},
      crudSelectIdsMode: false
    }
  },
  computed: {
    filterParams() {
      const params = {
        all: {},
        allLength: 0,
        map: {},
        inHiddenColumnsLength: 0,
        inHiddenColumns: {}
      }
      if (this.watchedColumnsLength || !this.apiFiltering) {
        const checkedColumnsLength = Object.keys(this.selectorVisibleColumns.checkedColumns).length
        this.tableColumnsArr.forEach((colSettings) => {
          if (!colSettings?.filterField) {
            return false
          }
          const name = this.getFilterFieldName(colSettings?.elAttr?.prop)
          params.map[name] = colSettings?.elAttr?.prop
          if (this.isNotEmptySearchValue(colSettings?.typeValueField, this.crudFilters?.[name])) {
            params.all[name] = this.crudFilters?.[this.getFilterFieldName(colSettings?.elAttr?.prop)]
            ++params.allLength
            if (
              checkedColumnsLength &&
              colSettings?.elAttr?.prop &&
              this.selectorVisibleColumns.checkedColumns[colSettings.elAttr.prop] === undefined
            ) {
              ++params.inHiddenColumnsLength
              params.inHiddenColumns[colSettings.elAttr.prop] = true
            }
          }
        })
      } else {
        params.all = { ...this.crudFilters }
      }
      return params
    },
    searchParams() {
      const params = {
        all: {},
        allLength: 0
      }
      Object.entries(this.searchFieldsRefer).forEach(([fieldName, fieldValue]) => {
        if (this.isNotEmptySearchValue('any', fieldValue)) {
          params.all[fieldName] = fieldValue
          ++params.allLength
        }
      })
      return params
    },
    searchFieldsModel: {
      get() {
        return this.searchFieldsRefer
      },
      set(val) {
        if (this.searchFields) {
          if (!Object.keys(val).length) {
            this.$emit('clearSearchData', (searchFields, skipFields) => {
              Object.keys(searchFields).forEach((key) => {
                if (!skipFields.includes(key)) {
                  searchFields[key] = undefined
                }
              })
            })
          }
        } else {
          this.crudSearchFields = val
        }
      }
    },
    searchFieldsRefer() {
      return this.searchFields || this.crudSearchFields
    },
    showClearBtn() {
      return !!(this.filterParams.allLength || this.searchParams.allLength || this.sorting.prop) && !this.hideClearBtn
    },
    crudSelectIdsLength() {
      return Object.keys(this.crudSelectIds).length
    }
  },
  methods: {
    getFilterFieldName(colApiName) {
      return this.tableColumns?.[colApiName]?.filterField === true
        ? colApiName
        : this.tableColumns?.[colApiName]?.filterField || colApiName
    },
    isNotEmptySearchValue(typeField, value) {
      switch (typeField) {
        case 'int':
        case 'uint':
          return !!value || value === 0
        case 'bool':
          return value || value === false
        default:
          return !!value || value === false || value === 0
      }
    },
    clearFilters() {
      this.crudFilters = {}
      this.searchFieldsModel = {}
      this.sorting = {
        order: '',
        prop: ''
      }
      this.$emit('resetAll')
    },
    getSortingParams() {
      return {
        sort: this.sorting.prop ? this.sorting.prop : undefined,
        sort_desc: this.sorting.prop ? this.sorting.order === 'descending' : undefined
      }
    },
    selectIdFromRow(row) {
      if (this.crudSelectIds?.[row.ID]) {
        delete this.crudSelectIds[row.ID]
      } else {
        this.crudSelectIds[row.ID] = true
      }
    },
    checkAllIdFromCurrentPage() {
      this.tableRows.forEach((row) => {
        this.crudSelectIds[row.ID] = true
      })
    },
    uncheckAllIdFromCurrentPage() {
      this.tableRows.forEach((row) => {
        delete this.crudSelectIds[row.ID]
      })
    },
    pasteIdsFromClipboard() {
      window.navigator.clipboard.readText().then((clipText) => {
        if (!clipText.length) {
          this.$message({
            message: 'clipboard is empty',
            type: 'warning',
            offset: 100,
            duration: 10000,
            showClose: true
          })
          return false
        }
        const pasteData = this.$papa.parse(clipText)?.data || []
        const notValid = pasteData.some((row, index) => {
          if (!row.length || row.length > 1 || !/^[1-9]\d*$/.test(row[0])) {
            return true
          }
          pasteData[index] = row[0] * 1
          return false
        })
        if (notValid) {
          this.$message({
            message: 'Not valid data (single excel column; only numbers; not empty cells)',
            type: 'error',
            offset: 100,
            duration: 10000,
            showClose: true
          })
        } else {
          pasteData.forEach((id) => {
            this.crudSelectIds[id] = true
          })
        }
      })
    },
    toggleOnlySelectedIds(show = true) {
      this.crudSelectIdsMode = show
      this.clearFilters()
    },
    uncheckAllSelectIds() {
      this.crudSelectIdsMode = false
      this.crudSelectIds = {}
      this.fetchData()
    }
  },
  watch: {
    crudSelectIdsLength: {
      handler() {
        if (!this.crudSelectIdsLength && this.crudSelectIdsMode) {
          this.crudSelectIdsMode = false
          this.fetchData()
        }
        localStorage.setItem('crudSelectIds', JSON.stringify({ crudSelectIds: this.crudSelectIds, routeName: this.$route.name }))
      }
    }
  }
}

export { FilteringData }
