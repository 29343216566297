import { pick } from 'lodash'

const tagsDefaultSettings = {
  'dev2prod': {
    typeBtn: 'warning',
    styleClasses: 'bg-amber-500',
    textColor: 'text-amber-500',
    bgHover: 'hover:bg-amber-500/25',
    syncIcon: true,
    outlinedClasses: 'gs-checkbox-outlined-warning border-amber-500'
  },
  'dev': {
    typeBtn: 'danger',
    styleClasses: 'bg-rose-500',
    textColor: 'text-rose-500',
    bgHover: 'hover:bg-rose-500/25',
    outlinedClasses: 'gs-checkbox-outlined-danger border-rose-500'
  },
  'prod': {
    typeBtn: 'success',
    styleClasses: 'bg-emerald-500',
    textColor: 'text-emerald-500',
    bgHover: 'hover:bg-emerald-500/25',
    outlinedClasses: 'gs-checkbox-outlined-success border-emerald-500'
  },
  'all': {
    styleClasses: 'bg-cyan-400'
  },
  'versioned': {
    styleClasses: 'bg-lime-600',
    showVersionsQuantity: true
  },
  'ab': {
    styleClasses: 'bg-sky-600',
    showVersionsQuantity: true
  },
  '2removeOnProd': {
    outlinedClasses: 'gs-checkbox-outlined-warning border-amber-500'
  }
}

export function CrudTagsSettings(...args) {
  return arguments.length ? pick(tagsDefaultSettings, ...args) : tagsDefaultSettings
}
