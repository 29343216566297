<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_3.png'
import TipImg4 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_4.png'
import TipImg5 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_5.png'
import TipImg6 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_6.png'
import TipImg7 from '!/pages/tools/ap-tips/tips/tip_6/tip_6_7.png'

export default {
  data() {
    return {
      srcList: [
        TipImg1,
        TipImg2,
        TipImg3,
        TipImg4,
        TipImg5,
        TipImg6,
        TipImg7
      ]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      <span># Form field documentation and settings can be added on forms. This documentation is common (visible) for each instance of the form (for different ID's).</span>
      <br>
      <span># Available settings:</span>
      <br> - marking entities fields as duplicate;
      <br> - expected min/max value for numbers fields;
      <br> <br>
      click on the screenshots to find out more
    </p>
  </div>
</template>

<style scoped></style>
