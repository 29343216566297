<script>
import { ref } from 'vue'
import { filesize } from 'filesize'
import { Close } from '@element-plus/icons-vue'
import DocumentationComponent from './DocumentationComponent.vue'
import FilesUploaderDrawer from '!/components/drawers/FilesUploaderDrawer.vue'
import TopFilter from '!/components/pages/files/TopFilter.vue'

export default {
  components: { FilesUploaderDrawer, DocumentationComponent, TopFilter, Close },
  setup() {
    const imageColFormatter = (row, column, cellValue) => {
      return cellValue?.length ? '&#9989;' : ''
    }
    return {
      filesize,
      columnsSettings: {
        Name: { cellEdit: true },
        Downloads: { cellEdit: false, filterField: false, width: 90 },
        Group: { cellEdit: true, width: 130 },
        Importance: { cellEdit: true, width: 100 },
        HashAndroid: {
          header: 'Android',
          htmlValue: true,
          elAttr: {
            width: 110,
            formatter: imageColFormatter
          }
        },
        SizeAndroid: {
          header: 'Android MB',
          elAttr: {
            width: 100
          }
        },
        SizeAndroidSd: {
          header: 'AndroidSD MB',
          elAttr: {
            width: 110
          }
        },
        SizeIos: {
          header: 'iOS MB',
          elAttr: {
            width: 100
          }
        },
        SizeIosSd: {
          header: 'iOSSD MB',
          elAttr: {
            width: 100
          }
        },
        SizePng: {
          header: 'PNG MB',
          elAttr: {
            width: 100
          }
        },
        SizeSoundbank: {
          header: 'Sound MB',
          elAttr: {
            width: 100
          }
        },
        HashAndroidSd: {
          header: 'Android SD',
          htmlValue: true,
          elAttr: {
            width: 110,
            formatter: imageColFormatter
          }
        },
        HashIos: {
          header: 'Ios',
          htmlValue: true,
          elAttr: {
            width: 80,
            formatter: imageColFormatter
          }
        },
        HashIosSd: {
          header: 'Ios SD',
          htmlValue: true,
          elAttr: {
            width: 110,
            formatter: imageColFormatter
          }
        },
        HashPng: {
          header: 'Png',
          htmlValue: true,
          elAttr: {
            width: 80,
            formatter: imageColFormatter
          }
        },
        HashSoundBank: {
          header: 'SoundBank',
          htmlValue: true,
          elAttr: {
            width: 80,
            formatter: imageColFormatter
          }
        }
      },
      drawer: ref(false)
    }
  },
  data() {
    const searchParams = {}
    if (this.$route.query.first !== undefined) {
      searchParams.first = this.$route.query.first
    }
    if (this.$route.query.other !== undefined) {
      searchParams.other = this.$route.query.other
    }
    return {
      drawerDocumentation: false,
      searchParams,
      total: {
        in_build_ios: 0,
        in_build_android: 0,
        in_build_soundbank: 0,
        pre_load_ios_hd: 0,
        pre_load_ios_sd: 0,
        pre_load_android_hd: 0,
        pre_load_android_sd: 0,
        pre_load_android_soundbank: 0,
        pre_load_soundbank: 0,
        post_load_ios_hd: 0,
        post_load_ios_sd: 0,
        post_load_android_hd: 0,
        post_load_android_sd: 0,
        post_load_soundbank: 0
      }
    }
  },
  mounted() {
    this.$axios.get('/admin/api/files/usage/').then((data) => {
      this.total = { ...this.total, ...(data?.data ?? {}) }
    })
  },
  methods: {
    fileSizeFinal(key, row) {
      for (const version of row.versions) {
        if (version[key]) {
          return filesize(version[key], { round: 2 })
        }
      }
      return ''
    }
  }
}
</script>

<template>
  <crud-table
    :columns-settings="columnsSettings"
    :default-columns-settings="{
      cellEdit: false
    }"
    :default-visible-columns="[
      'Name',
      'Downloads',
      'Group',
      'Importance',
      'SizeAndroid',
      'SizeAndroidSd',
      'SizeIos',
      'SizeIosSd',
      'SizePng'
    ]"
    show-img-preview-column
    api="binary/files"
    api-item="binary/file"
    :versioned="false"
    :static-api-params="searchParams"
    :columns="{
      Downloads: 'string'
    }"
  >
    <template #cell_Downloads="{ row }">
      <el-tooltip
        v-if="row.downloads.first"
        content="downloads in first minutes today and yesterday"
        effect="light"
      >
        <span class="text-lg font-bold text-orange-700">{{ row.downloads.first }}</span>
      </el-tooltip>
      <span
        v-if="row.downloads.first && row.downloads.other"
        class="text-lg"
      >/</span>
      <el-tooltip
        v-if="row.downloads.other"
        content="downloads during gameplay after some time today and yesterday"
        effect="light"
      >
        <span class="text-lg font-bold text-lime-700">{{ row.downloads.other }}</span>
      </el-tooltip>
    </template>
    <template #cell_SizeAndroid="{ row }">
      {{ fileSizeFinal('SizeAndroid', row) }}
    </template>
    <template #cell_SizeAndroidSd="{ row }">
      {{ fileSizeFinal('SizeAndroidSd', row) }}
    </template>
    <template #cell_SizeIos="{ row }">
      {{ fileSizeFinal('SizeIos', row) }}
    </template>
    <template #cell_SizeIosSd="{ row }">
      {{ fileSizeFinal('SizeIosSd', row) }}
    </template>
    <template #cell_SizePng="{ row }">
      {{ fileSizeFinal('SizePng', row) }}
    </template>
    <template #cell_SizeSoundbank="{ row }">
      {{ fileSizeFinal('SizeSoundBank', row) }}
    </template>
    <template #topTable>
      <el-scrollbar>
        <el-row class="min-w-[1000px]">
          <el-col :span="24">
            <el-descriptions
              :column="10"
              size="small"
              border
              direction="vertical"
            >
              <el-descriptions-item label="In build iOS">
                {{
                  filesize(total.in_build_ios ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="In build Android">
                {{
                  filesize(total.in_build_android ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="In build Soundbank">
                {{
                  filesize(total.in_build_soundbank ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Pre iOS HD">
                {{
                  filesize(total.pre_load_ios_hd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Pre iOS SD">
                {{
                  filesize(total.pre_load_ios_sd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Pre Android HD">
                {{
                  filesize(total.pre_load_android_hd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Pre Android SD">
                {{
                  filesize(total.pre_load_android_sd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Pre Soundbank">
                {{
                  filesize(total.pre_load_soundbank ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Post iOS HD">
                {{
                  filesize(total.post_load_ios_hd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Post iOS SD">
                {{
                  filesize(total.post_load_ios_sd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Post Android HD">
                {{
                  filesize(total.post_load_android_hd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Post Android SD">
                {{
                  filesize(total.post_load_android_sd ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
              <el-descriptions-item label="Post Soundbank">
                {{
                  filesize(total.post_load_soundbank ?? 0, { round: 1 })
                }}
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :span="12">
            <el-descriptions
              :column="5"
              size="small"
              class="pt-2"
              border
              direction="vertical"
            >
              <el-descriptions-item
                v-for="key in ['0', '50', '100', '500', '1000']"
                :key="key"
                :label="`${key}+`"
              >
                <template v-if="total.downloadsFirst?.[key]">
                  <el-tooltip
                    :content="`downloads in first minutes of the game today and yesterday (above ${key})`"
                    effect="light"
                  >
                    <el-link
                      type="primary"
                      :href="`/files/?first=${key}`"
                      target="_blank"
                    >
                      {{ total.downloadsFirst[key] }}
                    </el-link>
                  </el-tooltip>
                </template>
                <template v-else>
&nbsp;
                </template>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :span="12">
            <el-descriptions
              :column="5"
              size="small"
              class="pl-2 pt-2"
              border
              direction="vertical"
            >
              <el-descriptions-item
                v-for="key in ['0', '50', '100', '500', '1000']"
                :key="key"
                :label="`${key}+`"
              >
                <template v-if="total.downloadsOther?.[key]">
                  <el-tooltip
                    v-if="total.downloadsOther?.[key]"
                    :content="`downloads after some time today and yesterday (above ${key})`"
                    effect="light"
                  >
                    <el-link
                      type="primary"
                      :href="`/files/?other=${key}`"
                      target="_blank"
                    >
                      {{ total.downloadsOther[key] }}
                    </el-link>
                  </el-tooltip>
                </template>
                <template v-else>
&nbsp;
                </template>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
        </el-row>
      </el-scrollbar>
    </template>
    <template #topRight>
      <el-tooltip
        effect="light"
        placement="top"
        content="files documentation"
        :show-after="600"
      >
        <el-button
          size="small"
          class="gs-btn-outlined-primary-light relative mx-1 px-1"
          @click="drawerDocumentation = true"
        >
          <icon-ify
            icon="bxs:help-circle"
            class="h-6 w-6"
          />
        </el-button>
      </el-tooltip>
      <el-tooltip
        v-if="$store.getters['auth/userRights']?.editor"
        effect="light"
        placement="top"
        content="upload new"
        :show-after="600"
      >
        <el-button
          size="small"
          class="gs-btn-outlined-primary-light relative mx-1 px-1"
          @click="drawer = true"
        >
          <icon-ify
            icon="fa-solid:cloud-upload-alt"
            class="h-6 w-6"
          />
        </el-button>
      </el-tooltip>
    </template>
    <template #drawers="{ refreshData }">
      <FilesUploaderDrawer
        v-model:show="drawer"
        title="Upload new files"
        multi
        :limit="100"
        @refresh="refreshData()"
      />
    </template>
    <template #topLeft>
      <TopFilter />
    </template>
  </crud-table>
  <el-drawer
    v-model="drawerDocumentation"
    :with-header="false"
    :size="$windowWidth < 640 ? '95%' : '60%'"
  >
    <el-button
      class="gs-btn-text-neutral-light absolute right-4 top-0 bg-transparent p-0"
      @click="drawerDocumentation = false"
    >
      <el-icon class="gs-scaled-icon-xss">
        <Close />
      </el-icon>
    </el-button>
    <DocumentationComponent />
  </el-drawer>
</template>
