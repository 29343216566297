<script>
import CrudFieldSelectorBattlePassiveSkills from '@/components/forms/crud-fields/CrudFieldSelectorBattlePassiveSkills.vue'

export default {
  components: { CrudFieldSelectorBattlePassiveSkills }
}
</script>

<template>
  <crud-details-page
    api="events/slot-multiply"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <template
        v-for="i in 4"
        :key="i"
      >
        <fields-col>
          <section-divider>Row {{ i }}</section-divider>
        </fields-col>
        <fields-col>
          <fields-table>
            <template #tHeader>
              <tr>
                <th>Used</th>
                <th>Quality</th>
                <th>Wearable slot</th>
                <th>Orb attr</th>
                <th>Emblem slot</th>
                <th>Only gear</th>
                <th>Only orb</th>
              </tr>
            </template>
            <template #tBody>
              <tr
                v-for="row in 4"
                :key="row"
              >
                <fields-table-td :width="100">
                  <crud-field-checkbox
                    :api-field-name="`Slot${i}${row}IsUsed`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="120">
                  <crud-field-select
                    :api-field-name="`Slot${i}${row}Quality`"
                    options-enum="enums.ItemQuality"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="120">
                  <crud-field-select
                    :api-field-name="`Slot${i}${row}WearableSlot`"
                    options-enum="enums.WearableSlot"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="120">
                  <crud-field-select
                    :api-field-name="`Slot${i}${row}OrbAttr`"
                    options-enum="enums.BaseAttributeOrb"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="350">
                  <crud-field-selector-wearable-sets
                    :api-field-name="`Slot${i}${row}Emblem`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="120">
                  <crud-field-checkbox
                    :api-field-name="`Slot${i}${row}OnlyGear`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="100">
                  <crud-field-checkbox
                    :api-field-name="`Slot${i}${row}OnlyOrb`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
              </tr>
            </template>
          </fields-table>
        </fields-col>
        <fields-col>
          <section-divider class="mx-20">
            <span class="text-xs">Bonus multipliers</span>
          </section-divider>
        </fields-col>
        <fields-col>
          <fields-table>
            <template #tHeader>
              <tr>
                <th
                  v-for="k in 4"
                  :key="k"
                >
                  Slot
                </th>
                <th>Passive skill</th>
                <th>Event skill</th>
              </tr>
            </template>
            <template #tBody>
              <tr
                v-for="row in 6"
                :key="row"
              >
                <fields-table-td
                  v-for="k in 4"
                  :key="k"
                  :width="220"
                >
                  <crud-field-select
                    :api-field-name="`BonusSlot${i}${k}no${row}`"
                    options-enum="enums.MultiplierBonusSlot"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="350">
                  <CrudFieldSelectorBattlePassiveSkills
                    :api-field-name="`BonusSlot${i}No${row}Pskl`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
                <fields-table-td :width="200">
                  <crud-field-checkbox
                    :api-field-name="`IsEventSkill${i}No${row}`"
                    :label="false"
                    slim
                  />
                </fields-table-td>
              </tr>
            </template>
          </fields-table>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
