<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldSelectorItems',
  mixins: [BasicProps],
  props: {
    initFilters: {
      type: Object,
      default: () => ({
        // 'colNameApi': initValue
      })
    },
    disableEditModel: {
      type: Boolean,
      default: false
    },
    disableRelations: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change']
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <items-selector
          v-model="fieldModel"
          v-bind="$props"
          :disable-edit-model="disableEditModel"
          model-is-entity
          :label="false"
          full-width
          :debug="$attrs?.debug"
          @change="$emit('change', $event)"
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend">
        <div v-if="fieldModel?.Class === 'part'">
          <el-tooltip
            content="part item"
            :show-after="400"
            placement="bottom"
            effect="light"
          >
            <el-icon class="gs-scaled-icon-lg mr-1 text-yellow-500">
              <icon-ify
                icon="mdi:puzzle"
                class="h-full w-full"
              />
            </el-icon>
          </el-tooltip>
        </div>
        <div v-else-if="fieldModel?.Class === 'skin'">
          <el-tooltip
            content="skin item"
            :show-after="400"
            placement="bottom"
            effect="light"
          >
            <el-icon class="gs-scaled-icon-lg mr-1 text-fuchsia-400">
              <icon-ify
                icon="ant-design:skin-filled"
                class="h-full w-full"
              />
            </el-icon>
          </el-tooltip>
        </div>
      </slot>
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
