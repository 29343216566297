<template>
  <crud-details-page
    api="city/shop-set"
    disable-top-margin
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- MinUserLevel -->
        <crud-field-number
          api-field-name="MinUserLevel"
          required
        />
        <!-- MaxUserLevel -->
        <crud-field-number
          api-field-name="MaxUserLevel"
          required
        />
        <!-- Comment -->
        <crud-field-text api-field-name="Comment" />
      </fields-col>
      <fields-col
        :sm="8"
      >
        <!-- Loots -->
        <crud-field-selector-loots
          v-for="i in 8"
          :key="i"
          :api-field-name="`Loot${i}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
