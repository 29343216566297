<script>
import { methods, options, props } from './compositions/EffectConfig'

export default {
  name: 'PassiveSkillsEffectShieldAlterFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetTeamOptions, booleanOptions, srcOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetTeamOptions,
      booleanOptions,
      srcOptions,
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'shield_alter',
      'target-team': 'enemies',
      'times': 1,
      'min-value': 0,
      'max_value': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          api-field-name="effectRow.data.effectType"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="ShieldAlter_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          api-field-name="effectRow.data.target-team"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="ShieldAlter_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- times -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.times"
          label="Times"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="ShieldAlter_Times"
          disable-doc-settings
        />
      </div>
      <!-- min-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.min-value"
          label="Value min"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="ShieldAlter_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- max_value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.max_value"
          label="Value max"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="ShieldAlter_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          api-field-name="effectRow.data.dlvl-value"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="ShieldAlter_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
