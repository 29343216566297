<script>
import CrudFieldCheckbox from '!/components/forms/crud-fields/CrudFieldCheckbox.vue'
import CrudFieldSelectorNpcs from '@/components/forms/crud-fields/CrudFieldSelectorBossRewards.vue'
import CrudFieldSelectorDialogs from '@/components/forms/crud-fields/CrudFieldSelectorDialogs.vue'
import CrudFieldSelectorQuestEventRankingRewards from '@/components/forms/crud-fields/CrudFieldSelectorQuestEventRankingRewards.vue'
import CrudFieldSelectorQuests from '@/components/forms/crud-fields/CrudFieldSelectorQuests.vue'
import CrudFieldSelectorMilestones from '@/components/forms/crud-fields/CrudFieldSelectorMilestones.vue'
import CrudFieldSelectorOffers from '@/components/forms/crud-fields/CrudFieldSelectorOffers.vue'

export default {
  components: {
    CrudFieldCheckbox,
    CrudFieldSelectorOffers,
    CrudFieldSelectorMilestones,
    CrudFieldSelectorQuests,
    CrudFieldSelectorQuestEventRankingRewards,
    CrudFieldSelectorDialogs,
    CrudFieldSelectorNpcs
  },
  methods: {
    validate(formRefer) {
      formRefer?.clearValidate?.()
      formRefer?.validate?.()
    }
  }
}
</script>

<template>
  <crud-details-page
    api="quests/group"
    disable-top-margin
    enable-relations
  >
    <template #form="{ form, formRefer }">
      <fields-col>
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="6">
                <!-- Name -->
                <crud-field-selector-client-strings api-field-name="Name" />
                <!-- NameExtra -->
                <crud-field-text api-field-name="NameExtra" />
                <!-- Label -->
                <crud-field-text api-field-name="Label" />
                <!-- Notes -->
                <crud-field-textarea
                  api-field-name="Notes"
                  :textarea-rows="4.5"
                />
              </fields-col>
              <fields-col :sm="6">
                <!-- Tag -->
                <crud-field-text api-field-name="Tag" />
                <!-- SortOrder -->
                <crud-field-number api-field-name="SortOrder" />
                <!-- IsGuildEvent -->
                <crud-field-switcher
                  api-field-name="IsGuildEvent"
                  label="GUILD QUEST EVENT"
                />
                <!-- ForcePrio -->
                <crud-field-switcher api-field-name="ForcePrio" />
                <!-- IsNotForReturningPlayers -->
                <crud-field-switcher api-field-name="IsNotForReturningPlayers" />
              </fields-col>
              <fields-col :sm="6">
                <!-- Type -->
                <crud-field-select
                  api-field-name="Type"
                  options-enum="enums.QuestGroupType"
                  required
                  @change="validate(formRefer)"
                />
                <!-- ItemToDisplay -->
                <crud-field-selector-items api-field-name="ItemToDisplay" />
                <!-- Description -->
                <crud-field-selector-client-strings
                  api-field-name="Description"
                  required
                />
                <!-- ChainNo -->
                <crud-field-number api-field-name="ChainNo" />
              </fields-col>
              <fields-col :sm="6">
                <!-- Npc -->
                <CrudFieldSelectorNpcs api-field-name="Npc" />
                <!-- NpcAssetGraphic -->
                <crud-field-selector-files api-field-name="NpcAssetGraphic" />
                <!-- BackgroundAssetGraphic -->
                <crud-field-selector-files api-field-name="BackgroundAssetGraphic" />
                <!-- Dialog -->
                <CrudFieldSelectorDialogs api-field-name="Dialog" />
                <!-- Icon -->
                <crud-field-selector-files api-field-name="Icon" />
              </fields-col>
              <fields-col>
                <section-divider>Time settings</section-divider>
              </fields-col>
              <fields-col :sm="8">
                <!-- QeStart -->
                <crud-field-date-time
                  api-field-name="QeStart"
                  label="Start"
                  :required="['event', 'season'].includes(form.Type)"
                />
                <!-- QeEnd -->
                <crud-field-date-time
                  api-field-name="QeEnd"
                  label="End"
                  :required="['event', 'season'].includes(form.Type)"
                />
              </fields-col>
              <!-- QeFinishedDisplayHours -->
              <crud-field-number
                :sm="8"
                api-field-name="QeFinishedDisplayHours"
                label="QE finished display (hours)"
              />
              <fields-col :sm="8">
                <crud-field-selector-items
                  api-field-name="ItemToReset1"
                  label="Item to reset 1"
                />
                <crud-field-selector-items
                  api-field-name="ItemToReset2"
                  label="Item to reset 2"
                />
                <crud-field-selector-items
                  api-field-name="ItemToReset3"
                  label="Item to reset 3"
                />
              </fields-col>
              <fields-col>
                <section-divider>Requirements</section-divider>
              </fields-col>
              <fields-col :sm="24">
                <crud-field-number
                  :sm="6"
                  api-field-name="ReqLevel"
                  label="Required level"
                />
                <crud-field-number
                  :sm="6"
                  api-field-name="ReqLevelMax"
                  label="Required level max"
                />
                <crud-field-selector-quest-groups
                  :sm="6"
                  api-field-name="ReqQuestGroup"
                  label="Required quest group"
                />
                <crud-field-selector-battle-locations
                  :sm="6"
                  api-field-name="ReqLocation"
                  label="Required location"
                />
                <crud-field-select
                  :sm="6"
                  api-field-name="ReqDifficulty"
                  label="Required difficulty"
                  options-enum="enums.BattleDifficulty"
                />
                <crud-field-date-time
                  :sm="6"
                  api-field-name="ReqHeroCreatedAfter"
                  label="Hero created after"
                />
                <crud-field-date-time
                  :sm="6"
                  api-field-name="ReqHeroCreatedBefore"
                  label="Hero created before"
                />
              </fields-col>
              <fields-col>
                <section-divider>Championships / QuestEvents / QE</section-divider>
              </fields-col>
              <fields-col>
                <!-- QeForcedDuration -->
                <crud-field-number
                  :sm="6"
                  api-field-name="QeForcedDuration"
                  label="Duration [h]"
                />
                <!-- QeReqScore -->
                <crud-field-number
                  :sm="6"
                  api-field-name="QeReqScore"
                  label="Required score to join"
                />
                <!-- QeRankingRewards -->
                <CrudFieldSelectorQuestEventRankingRewards
                  :sm="6"
                  api-field-name="QeRankingRewards"
                  label="Ranking rewards reference"
                />
                <!-- RankingLeagueProdAmount -->
                <crud-field-number
                  :sm="6"
                  api-field-name="RankingLeagueProdAmount"
                  label="League amount [PROD]"
                />
                <!-- ChampionshipType -->
                <crud-field-select
                  :sm="6"
                  api-field-name="ChampionshipType"
                  options-enum="enums.ChampionshipType"
                />
                <!-- IsShowQe -->
                <crud-field-switcher
                  :sm="6"
                  api-field-name="IsShowQe"
                />
              </fields-col>
              <fields-col>
                <section-divider>Guild Ranking Rewards</section-divider>
              </fields-col>
              <fields-col>
                <!-- IsGroupRanking -->
                <crud-field-switcher
                  :sm="6"
                  api-field-name="IsGroupRanking"
                />
                <!-- GuildRewardMinScore -->
                <crud-field-number
                  :sm="6"
                  api-field-name="GuildRewardMinScore"
                  label="Reward receiver min score"
                />
                <!-- QeGuildRankingRewards -->
                <CrudFieldSelectorQuestEventRankingRewards
                  v-for="i in 3"
                  :key="i"
                  :sm="6"
                  :api-field-name="`QeGuildRanking${i}Rewards`"
                  :label="`Guild Ranking League ${i} rewards reference`"
                  :required="form.IsGuildEvent"
                />
                <template v-if="form.IsGroupRanking">
                  <!-- QeGuildRankingRewards -->
                  <CrudFieldSelectorQuestEventRankingRewards
                    v-for="i in 3"
                    :key="i"
                    :sm="6"
                    :api-field-name="`QeGuildRanking${i + 3}Rewards`"
                    :label="`Guild Ranking League ${i + 3} rewards reference`"
                    required
                  />
                </template>
              </fields-col>
              <fields-col>
                <section-divider>Milestones</section-divider>
              </fields-col>
              <fields-col>
                <!-- Milestone1 -->
                <CrudFieldSelectorMilestones
                  :sm="8"
                  api-field-name="Milestone1"
                  label="Milestone"
                />
                <CrudFieldSelectorMilestones
                  :sm="8"
                  api-field-name="Milestone2"
                  label="Premium milestone"
                />
              </fields-col>
              <fields-col>
                <section-divider>Extra Milestones</section-divider>
              </fields-col>
              <fields-col>
                <crud-field-number
                  :sm="6"
                  api-field-name="ExtraMilestoneStartNo"
                  label="Milestone Start No"
                />
                <crud-field-number
                  :sm="6"
                  api-field-name="ExtraMilestonePrice"
                  label="Milestone Price"
                />
                <crud-field-number
                  :sm="6"
                  api-field-name="ExtraMilestonePriceIncrement"
                  label="Milestone Price Increment"
                />
                <crud-field-number
                  :sm="6"
                  api-field-name="ExtraMilestoneDailyLimit"
                  label="Milestone Daily Limit"
                />
              </fields-col>
              <fields-col>
                <section-divider>Quest Tabs</section-divider>
              </fields-col>
              <fields-col>
                <el-tabs class="w-full">
                  <el-tab-pane
                    v-for="i in 4"
                    :key="i"
                    :label="`Tab ${i}`"
                  >
                    <fields-col>
                      <crud-field-text
                        :sm="4"
                        :api-field-name="`Tab${i}Name`"
                        label="Tab name"
                      />
                      <crud-field-date-time
                        :sm="4"
                        :api-field-name="`Tab${i}Start`"
                        label="Tab start"
                      />
                      <crud-field-date-time
                        :sm="4"
                        :api-field-name="`Tab${i}End`"
                        label="Tab end"
                      />
                      <crud-field-number
                        :sm="4"
                        :api-field-name="`Tab${i}ReqScore`"
                        label="Tab req score"
                      />
                      <crud-field-selector-items
                        :sm="4"
                        :api-field-name="`Tab${i}ReqItem`"
                        label="Tab req item"
                      />
                      <crud-field-slot :sm="4">
                        <CrudFieldCheckbox
                          :api-field-name="`Tab${i}DailyReset`"
                          slim
                        />
                        <CrudFieldCheckbox
                          :api-field-name="`Tab${i}WeeklyReset`"
                          slim
                        />
                      </crud-field-slot>
                      <template
                        v-for="k in 7"
                        :key="k"
                      >
                        <CrudFieldSelectorQuests
                          :sm="9"
                          :api-field-name="`Quest${k + (i - 1) * 7}.Quest`"
                          :label="`Quest ${k}`"
                        />
                        <crud-field-number
                          :sm="5"
                          :api-field-name="`Quest${k + (i - 1) * 7}.Limit`"
                          :label="`Limit ${k}`"
                        />
                        <crud-field-number
                          :sm="5"
                          :api-field-name="`Quest${k + (i - 1) * 7}.GuildLimit`"
                          :label="`Guild limit ${k}`"
                        />
                        <crud-field-number
                          :sm="5"
                          :api-field-name="`Quest${k + (i - 1) * 7}.RewardPoints`"
                          :label="`Reward points / per limit ${k}`"
                        />
                      </template>
                    </fields-col>
                  </el-tab-pane>
                </el-tabs>
              </fields-col>
            </fields-col>
            <el-col
              :sm="12"
              class="bg-amber-100"
            >
              <el-row>
                <crud-field-item-tags api-field-name="ItemTags" />
              </el-row>
              <el-row>
                <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
              </el-row>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Offers"
            name="offers"
          >
            <fields-col>
              <template
                v-for="i in 5"
                :key="i"
              >
                <CrudFieldSelectorOffers
                  :sm="8"
                  :api-field-name="`Product${i}`"
                  :label="`Offer ${i}`"
                />
                <crud-field-switcher
                  :sm="16"
                  :api-field-name="`Product${i}24hMore`"
                  label="24h more"
                />
              </template>
            </fields-col>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
