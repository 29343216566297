<script>
export default {
  components: {},
  data() {
    return {
      stats: null,
      globalStats: {
        users: 0,
        clicks: 0,
        move: 0,
        seconds: 0,
        imports: 0,
        importedRows: 0
      },
      statsByDate: [],
      statsByUser: [],
      statsByForm: [],
      statsUsers: [],
      loading: true
    }
  },
  mounted() {
    this.$axios.get('/admin/api/usage/get/').then((data) => {
      this.stats = data.data.data
      let group = { date: 0, clicks: 0, move: 0, seconds: 0, users: 0, imports: 0, importedRows: 0, usersGroup: {} }
      const userGroup = {}
      const statsByUser = {}
      const statsByForm = {}
      const statsUsers = {}
      for (const val of this.stats) {
        if (!userGroup[val.user]) {
          this.globalStats.users++
          userGroup[val.user] = true
        }
        this.globalStats.clicks += val.clicks
        this.globalStats.move += val.move
        this.globalStats.seconds += val.seconds
        this.globalStats.imports += val.imports
        this.globalStats.importedRows += val.importedRows

        if (group.date === 0 || group.date === val.date) {
          group.date = val.date
          group.clicks += val.clicks
          group.move += val.move
          group.seconds += val.seconds
          group.imports += val.imports
          group.importedRows += val.importedRows
          if (!group.usersGroup[val.user]) {
            group.users++
            group.usersGroup[val.user] = true
          }
        } else {
          this.statsByDate.push(group)
          group = { date: 0, clicks: 0, move: 0, seconds: 0, users: 0, imports: 0, importedRows: 0, usersGroup: {} }
        }
        if (!statsByUser[val.user]) {
          statsByUser[val.user] = {
            user: val.user,
            clicks: 0,
            move: 0,
            seconds: 0,
            forms: 0,
            imports: 0,
            importedRows: 0,
            formsMapping: {}
          }
        }
        statsByUser[val.user].clicks += val.clicks
        statsByUser[val.user].move += val.move
        statsByUser[val.user].seconds += val.seconds
        statsByUser[val.user].imports += val.imports
        statsByUser[val.user].importedRows += val.importedRows
        if (!statsByUser[val.user].formsMapping[val.route]) {
          statsByUser[val.user].forms++
          statsByUser[val.user].formsMapping[val.route] = true
        }
        if (val.route) {
          if (!statsByForm[val.route]) {
            statsByForm[val.route] = {
              form: val.route,
              clicks: 0,
              move: 0,
              seconds: 0,
              users: 0,
              imports: 0,
              importedRows: 0,
              usersMapping: {}
            }
          }
          statsByForm[val.route].clicks += val.clicks
          statsByForm[val.route].move += val.move
          statsByForm[val.route].seconds += val.seconds
          statsByForm[val.route].imports += val.imports
          statsByForm[val.route].importedRows += val.importedRows

          if (!statsByForm[val.route].usersMapping[val.user]) {
            statsByForm[val.route].users++
            statsByForm[val.route].usersMapping[val.user] = true
          }
          if (!statsUsers[val.user]) {
            statsUsers[val.user] = { user: val.user, rows: {} }
          }
          if (!statsUsers[val.user].rows[val.route]) {
            statsUsers[val.user].rows[val.route] = {
              form: val.route,
              clicks: 0,
              move: 0,
              seconds: 0,
              imports: 0,
              importedRows: 0
            }
          }
          statsUsers[val.user].rows[val.route].clicks += val.clicks
          statsUsers[val.user].rows[val.route].move += val.move
          statsUsers[val.user].rows[val.route].seconds += val.seconds
          statsUsers[val.user].rows[val.route].imports += val.imports
          statsUsers[val.user].rows[val.route].importedRows += val.importedRows
          statsUsers[val.user].seconds += val.seconds
        }
      }
      if (group.date !== 0) {
        this.statsByDate.push(group)
      }
      for (const i in statsByUser) {
        this.statsByUser.push(statsByUser[i])
      }
      this.statsByUser.sort((a, b) => {
        return a.seconds > b.seconds ? -1 : 1
      })
      for (const i in statsByForm) {
        this.statsByForm.push(statsByForm[i])
      }
      this.statsByForm.sort((a, b) => {
        return a.seconds > b.seconds ? -1 : 1
      })
      for (const i in statsUsers) {
        const row = statsUsers[i]
        const data = { user: row.user, seconds: row.seconds, rows: [] }
        for (const j in row.rows) {
          data.rows.push(row.rows[j])
        }
        data.rows.sort((a, b) => {
          return a.seconds > b.seconds ? -1 : 1
        })
        this.statsUsers.push(data)
      }
      this.statsUsers.sort((a, b) => {
        return a.seconds > b.seconds ? -1 : 1
      })
      this.loading = false
    })
  }
}
</script>

<template>
  <crud-table
    :top-actions="false"
    :versioned="false"
    hide-columns-selector
    hide-refresh-btn
    disable-pagination
    disable-multi-select-rows
    :loading="loading"
  >
    <template #table>
      <div v-if="!loading">
        <el-scrollbar>
          <div class="sm:min-w-[1200px]">
            <el-row
              :gutter="16"
              class="sm:m-5"
            >
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="globalStats.users || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Users last 7 days
                      </div>
                    </template>
                  </el-statistic>
                </div>
              </el-col>
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="Math.round(globalStats.seconds / 3600) || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Time spent last 7 days
                      </div>
                    </template>
                    <template #suffix>
                      {{ Math.round(globalStats.seconds / 3600) > 1 ? 'hours' : 'hour' }}
                    </template>
                  </el-statistic>
                </div>
              </el-col>
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="globalStats.clicks || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Mouse clicks last 7 days
                      </div>
                    </template>
                  </el-statistic>
                </div>
              </el-col>
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="(globalStats.move / 10000).toFixed(0) * 1 || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Mouse moves last 7 days
                      </div>
                    </template>
                    <template #suffix>
                      m
                    </template>
                  </el-statistic>
                </div>
              </el-col>
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="globalStats.imports || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Imports last 7 days
                      </div>
                    </template>
                  </el-statistic>
                </div>
              </el-col>
              <el-col
                :xs="12"
                :sm="4"
              >
                <div class="statistic-card p-4">
                  <el-statistic :value="globalStats.importedRows || 0">
                    <template #title>
                      <div style="display: inline-flex; align-items: center">
                        Imported rows last 7 days
                      </div>
                    </template>
                  </el-statistic>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
        <div class="relative flex justify-center">
          <icon-ify
            icon="mdi:gesture-swipe"
            class="gs-scaled-icon-xs text-neutral-400 sm:hidden"
          />
        </div>
        <el-row>
          <el-col :span="24">
            <el-collapse
              accordion
              class="m-2 sm:m-10 gs-inverted"
            >
              <el-collapse-item name="by-date">
                <template #title>
                  By day
                </template>
                <filtering-table
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  :rows="statsByDate"
                  :columns="{
                    date: {
                      type: 'string',
                      elAttr: { minWidth: 180, formatter: (row) => new Date(row.date * 1000).toISOString().slice(0, 10) },
                      sortable: true,
                      filterField: true
                    },
                    users: { type: 'string', elAttr: { minWidth: 100 }, sortable: true, filterField: true },
                    seconds: {
                      type: 'string',
                      elAttr: {
                        minWidth: 150,
                        formatter: (row) => {
                          return (row.seconds / 3600).toFixed(1) + (Math.round(row.seconds / 3600) > 1 ? ' hours' : ' hour');
                        }
                      },
                      sortable: true,
                      filterField: true
                    },
                    clicks: { type: 'uint', elAttr: { minWidth: 150 }, sortable: true, filterField: true },
                    move: {
                      type: 'string',
                      elAttr: { minWidth: 150, formatter: (row) => `${(row.move / 10000).toFixed(0)} m` },
                      sortable: true,
                      filterField: true
                    },
                    imports: { type: 'uint', elAttr: { minWidth: 150 }, sortable: true, filterField: true },
                    importedRows: { type: 'uint', elAttr: { minWidth: 150 }, sortable: true, filterField: true }
                  }"
                  hide-refresh-btn
                  :max-height="`${Math.min(600, $windowHeight * 0.7)}px`"
                />
              </el-collapse-item>

              <el-collapse-item name="by-user">
                <template #title>
                  By user
                </template>
                <filtering-table
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  :rows="statsByUser"
                  :columns="{
                    user: { type: 'string', elAttr: { minWidth: 300 }, sortable: true, filterField: true },
                    seconds: {
                      type: 'string',
                      elAttr: {
                        minWidth: 150,
                        formatter: (row) => {
                          return (row.seconds / 3600).toFixed(1) + (Math.round(row.seconds / 3600) > 1 ? ' hours' : ' hour');
                        }
                      },
                      sortable: true,
                      filterField: true
                    },
                    forms: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true },
                    clicks: {
                      type: 'uint',
                      header: 'Mouse clicks',
                      elAttr: { minWidth: 105 },
                      sortable: true,
                      filterField: true
                    },
                    move: {
                      type: 'string',
                      header: 'Mouse moves',
                      elAttr: { minWidth: 150, formatter: (row) => `${(row.move / 10000).toFixed(0)} m` },
                      sortable: true,
                      filterField: true
                    },
                    imports: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true },
                    importedRows: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true }
                  }"
                  hide-refresh-btn
                  :max-height="`${Math.min(600, $windowHeight * 0.7)}px`"
                />
              </el-collapse-item>

              <el-collapse-item name="by-route">
                <template #title>
                  By form
                </template>
                <filtering-table
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  :rows="statsByForm"
                  :columns="{
                    form: { type: 'string', elAttr: { minWidth: 300 }, sortable: true, filterField: true },
                    seconds: {
                      type: 'string',
                      header: 'Time spent',
                      elAttr: {
                        minWidth: 150,
                        formatter: (row) => {
                          return (row.seconds / 60).toFixed(0) + (Math.round(row.seconds / 60) > 1 ? ' minutes' : ' minute');
                        }
                      },
                      sortable: true,
                      filterField: true
                    },
                    users: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true },
                    clicks: {
                      type: 'uint',
                      header: 'Mouse clicks',
                      elAttr: { minWidth: 105 },
                      sortable: true,
                      filterField: true
                    },
                    move: {
                      type: 'string',
                      header: 'Mouse moves',
                      elAttr: { minWidth: 150, formatter: (row) => `${(row.move / 10000).toFixed(0)} m` },
                      sortable: true,
                      filterField: true
                    },
                    imports: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true },
                    importedRows: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true }
                  }"
                  hide-refresh-btn
                  :max-height="`${Math.min(600, $windowHeight * 0.7)}px`"
                />
              </el-collapse-item>

              <el-collapse-item
                v-if="statsUsers.length"
                name="users"
              >
                <template #title>
                  Users statistics
                </template>
                <el-collapse
                  accordion
                  class="m-2 sm:m-10"
                >
                  <el-collapse-item
                    v-for="user in statsUsers"
                    :key="user.user"
                    :name="user.user"
                  >
                    <template #title>
                      {{ user.user }}
                    </template>
                    <filtering-table
                      header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                      cell-class-name="gs-col-padding-mini"
                      :rows="user.rows"
                      :columns="{
                        form: { type: 'string', elAttr: { minWidth: 300 }, sortable: true, filterField: true },
                        seconds: {
                          type: 'string',
                          header: 'Time spent',
                          elAttr: {
                            minWidth: 150,
                            formatter: (row) => {
                              return (row.seconds / 60).toFixed(0) + (Math.round(row.seconds / 60) > 1 ? ' minutes' : ' minute');
                            }
                          },
                          sortable: true,
                          filterField: true
                        },
                        clicks: {
                          type: 'uint',
                          header: 'Mouse clicks',
                          elAttr: { minWidth: 150 },
                          sortable: true,
                          filterField: true
                        },
                        move: {
                          type: 'string',
                          header: 'Mouse moves',
                          elAttr: { minWidth: 150, formatter: (row) => `${(row.move / 10000).toFixed(0)} m` },
                          sortable: true,
                          filterField: true
                        },
                        imports: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true },
                        importedRows: { type: 'uint', elAttr: { minWidth: 105 }, sortable: true, filterField: true }
                      }"
                      hide-refresh-btn
                      :max-height="`${Math.min(600, $windowHeight * 0.6)}px`"
                    />
                  </el-collapse-item>
                </el-collapse>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
      </div>
    </template>
  </crud-table>
</template>

<style scoped>
.statistic-card {
  height: 100%;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}
</style>
