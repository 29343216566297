import { globalProperties as app } from '!/plugins/utilities'

export default () => {
  return {
    namespaced: true,
    state() {
      return {
        pageMenuVisible: true,
        pageMenuCollapse: false,
        pageLoaderLinear: false,
        pageHeaderSubtitle: '',
        pageHeaderImgUrl: '',
        pageHeaderHideImg: false,
        crudStorage: {},
        gameApiSettings: {}
      }
    },
    mutations: {
      togglePageMenu(state) {
        // $store.commit('main/togglePageMenu')
        state.pageMenuVisible = !state.pageMenuVisible
      },
      togglePageManuCollapse(state) {
        // $store.commit('main/togglePageManuCollapse')
        state.pageMenuCollapse = !state.pageMenuCollapse
      },
      togglePageLoaderLinear(state) {
        // $store.commit('main/togglePageLoaderLinear')
        state.pageLoaderLinear = !state.pageLoaderLinear
      },
      pageHeaderSubtitle(state, subTitle) {
        // $store.commit('main/pageHeaderSubtitle', subTitle)
        state.pageHeaderSubtitle = subTitle
      },
      pageHeaderImgUrl(state, url) {
        // $store.commit('main/pageHeaderImgUrl', url)
        state.pageHeaderImgUrl = url
      },
      pageHeaderHideImg(state, hide) {
        // $store.commit('main/pageHeaderHideImg', hide)
        state.pageHeaderHideImg = !!hide
      },
      setCrudStorage(state, [crudHash, value]) {
        // $store.commit('main/setCrudStorage', [crudHash, value])
        if (!state.crudStorage[crudHash]) {
          state.crudStorage[crudHash] = {}
        }
        state.crudStorage[crudHash] = { ...value }
      },
      updateCrudStorage(state, [crudHash, value]) {
        // $store.commit('main/updateCrudStorage', [crudHash, value])
        if (!state.crudStorage[crudHash]) {
          state.crudStorage[crudHash] = {}
        }
        state.crudStorage[crudHash] = { ...state.crudStorage[crudHash], ...value }
      },
      removeCrudStorage(state, crudHash) {
        // $store.commit('main/removeCrudStorage', crudHash)
        delete state.crudStorage[crudHash]
      },
      setGameApiSettings(state, settings) {
        // $store.commit('main/setGameApiSettings', settings)
        state.gameApiSettings = settings
      }
    },
    getters: {
      pageMenu: (state) => {
        // $store.getters['main/pageMenu'];
        return state.pageMenuVisible
      },
      pageMenuCollapse: (state) => {
        // $store.getters['main/pageMenuCollapse'];
        return state.pageMenuCollapse
      },
      pageMenuWidth: (state) => {
        // $store.getters['main/pageMenuWidth'];
        return state.pageMenuVisible ? `${state.pageMenuCollapse ? 64 + app.$store.getters['auth/userScaledWidth'] : 240 + app.$store.getters['auth/userScaledWidth'] * 3}px` : 0
      },
      pageHeaderSubtitle: (state) => {
        // $store.getters['main/pageHeaderSubtitle'];
        return state.pageHeaderSubtitle
      },
      pageHeaderImgUrl: (state) => {
        // $store.getters['main/pageHeaderImgUrl'];
        return state.pageHeaderImgUrl
      },
      pageHeaderHideImg: (state) => {
        // $store.getters['main/pageHeaderHideImg'];
        return state.pageHeaderHideImg
      },
      crudStorage: (state) => {
        // $store.getters['main/crudStorage'];
        return state.crudStorage
      },
      gameApiSettings: (state) => {
        // $store.getters['main/gameApiSettings'];
        return state.gameApiSettings
      }
    },
    actions: {
      initCrudStorage({ state }, crudStorageData) {
        if (crudStorageData) {
          state.crudStorage = { ...state.crudStorage, ...crudStorageData }
        }
      },
      saveCrudStorage(_, crudStorageData) {
        app.$axios
          .post('/admin/api/logged/settings/ ', {
            data: { ...crudStorageData }
          })
          .catch(app.$utils.catchError)
      }
    }
  }
}
