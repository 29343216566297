<script>
import { userRightRoles } from '!/composition/utilities'

export default {
  setup() {
    return {
      assessRight: userRightRoles.admin
    }
  },
  computed: {
    contentsFields() {
      return [
        'ContentsFr',
        'ContentsDe',
        'ContentsIt',
        'ContentsEs',
        'ContentsPt',
        'ContentsRu',
        'ContentsPl',
        'ContentsZh',
        'ContentsCm'
      ]
    }
  },
  methods: {
    initForm(itemData) {
      if (!itemData?.ID) {
        itemData.NeedTranslation = true
      }
      return itemData
    },
    onDuplicateItem(itemData) {
      itemData.StringId = ''
      return itemData
    }
  }
}
</script>

<template>
  <crud-details-page
    api="settings/admin-user"
    :render-init-item="initForm"
    :render-duplicate-item="onDuplicateItem"
    :versioned="false"
    :access-right="assessRight"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Email -->
        <crud-field-text
          api-field-name="Email"
          required
        />
        <!-- Admin -->
        <crud-field-switcher
          api-field-name="Admin"
          label="Admin (can add/edit/delete admin users)"
        />
        <!-- Editor -->
        <crud-field-switcher
          api-field-name="Editor"
          label="Editor (can edit data)"
        />
        <!-- Deployer -->
        <crud-field-switcher
          api-field-name="Deployer"
          label="Deployer (can sync data to production and clear cache)"
        />
        <!-- Support -->
        <crud-field-switcher
          api-field-name="Support"
          label="Support (can access support sections)"
        />
        <!-- Translator -->
        <crud-field-switcher
          api-field-name="Translator"
          label="Translator (can translate client strings)"
        />
        <!-- RightPlayerViewer -->
        <crud-field-switcher
          api-field-name="RightPlayerViewer"
          label="Player viewer"
        />
        <!-- RightPostTesterProd -->
        <crud-field-switcher
          api-field-name="RightPostTesterProd"
          label="Tester prod"
        />
        <!-- RightClientConsoleProd -->
        <crud-field-switcher
          api-field-name="RightClientConsoleProd"
          label="Client console prod"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
