<script>
import { inject, ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'UsageDialog',
  setup() {
    const crudDetails = inject('crudDetails')
    const loading = ref(false)
    const treeData = ref([])
    const renderTreeData = (data) => {
      const grouped = {}
      let apiFieldName = ''
      data.forEach((row) => {
        const isRoot = row.entity.slice(2, 5) !== '---'

        if (isRoot) {
          if (!grouped[row.entity]) {
            grouped[row.entity] = {
              label: row.entity,
              children: {}
            }
          }
          if (!grouped[row.entity][row.name]) {
            grouped[row.entity].children[row.name] = {
              label: `${row.name} (${row.id})`,
              children: []
            }
            apiFieldName = row.name
          }
        } else {
          row.entity = row.entity.slice(5)
          const route = app.$utils.getRouteByEntity(row.entity, true)
          grouped[row.entity].children[apiFieldName].children.push({
            row,
            label: `${row.name || `#${row.id}`}`,
            route: route ? { name: route.name, params: { id: row.id } } : null
          })
        }
      })

      treeData.value = Object.entries(grouped).map(([, entity]) => {
        entity.children = Object.entries(entity.children).map(([, apiField]) => {
          return apiField
        })

        return entity
      })
    }

    loading.value = true
    app.$axios
      .get('/admin/api/entity/usage/', {
        params: {
          entity: crudDetails.crudEntity,
          id: crudDetails.form.ID
        }
      })
      .then((response) => {
        renderTreeData(response.data.usage)
      })
      .catch(app.$utils.catchError)
      .then(() => {
        loading.value = false
      })
    return {
      crudDetails,
      loading,
      treeData
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="crudDetails.usageDialog"
    class="small"
    :width="$windowWidth < 640 ? '95%' : '80%'"
  >
    <template #header>
      <div class="break-all">
        {{ `Usage of ${crudDetails.metaData.subtitle}` }}
      </div>
    </template>
    <template #default>
      <el-scrollbar
        class="gs-scaled mt-4 px-4"
        height="60vh"
      >
        <el-tree
          v-loading="loading"
          :data="treeData"
          :props="{ children: 'children', label: 'label' }"
          :empty-text="loading ? 'loading usage...' : 'no usage'"
          class="mb-8"
          style="min-height: 400px"
        >
          <template #default="{ data }">
            <template v-if="!!data.children">
              {{ data.label }}
            </template>
            <template v-else>
              <router-link
                v-if="data.route"
                :to="$utils.bindStaticParams(data.route)"
                target="_blank"
                class="gs-user-font text-blue-500 hover:text-blue-600"
              >
                {{ data.label }}
              </router-link>
              <template v-else>
                {{ data.label }}
              </template>
            </template>
          </template>
        </el-tree>
      </el-scrollbar>
    </template>
  </el-dialog>
</template>
