<template>
  <crud-details-page
    api="journeys/journey"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- LevelMin -->
        <crud-field-number
          api-field-name="LevelMin"
          required
        />
        <!-- LevelMax -->
        <crud-field-number
          api-field-name="LevelMax"
          required
        />
        <!-- Time -->
        <crud-field-number
          api-field-name="Time"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- RewardGold -->
        <crud-field-number api-field-name="RewardGold" />
        <!-- RewardItemExp -->
        <crud-field-number api-field-name="RewardItemExp" />
        <!-- RewardItemJourneyCurrency -->
        <crud-field-number api-field-name="RewardItemJourneyCurrency" />
      </fields-col>
      <fields-col :sm="8">
        <!-- RewardItem -->
        <crud-field-selector-items api-field-name="RewardItem" />
        <!-- RewardItemAmount -->
        <crud-field-number api-field-name="RewardItemAmount" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
