<script>
import { inject } from 'vue'
import { ElMessageBox } from 'element-plus'
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'CrudFieldText',
  mixins: [BasicProps],
  props: {
    /**
     * if > 0 prop component is set as el-input with attr type="textarea"
     */
    textareaRows: {
      type: Number,
      default: 5
    }
  },
  setup(props) {
    const initForm = props.form ? {} : inject('initForm', {})
    const crudDetails = inject('crudDetails', {})
    const fieldNamePath = props.apiFieldName.split('.')

    const checkTags = () => {
      const itemTagsContent = app.$utils.getByPath(crudDetails.form, fieldNamePath) || ''
      let missing = ''
      let mapRows = null
      itemTagsContent.split('\n').forEach((itemTag) => {
        const decodedItemTag = itemTag.split(':')
        if (decodedItemTag.length === 2) {
          const fieldPath = decodedItemTag[0].split('.')
          if (fieldPath[0] === 'rows') {
            if (mapRows === null) {
              mapRows = {};
              (crudDetails?.form?.rows || []).forEach((row) => {
                mapRows[row.ID] = row
              })
            }
            if (
              !mapRows[fieldPath[1]] ||
              app.$utils.getByPath(mapRows[fieldPath[1]], fieldPath.slice(2, fieldPath.length)) === undefined
            ) {
              missing += `${itemTag}\n`
            }
          } else {
            if (app.$utils.getByPath(crudDetails.form, fieldPath) === undefined) {
              missing += `${itemTag}\n`
            }
          }
        }
      })
      if (missing.length) {
        missing = `Missing fields \n\n${missing}`
      } else {
        missing = 'all fields exist'
      }
      ElMessageBox.alert(`<pre>${missing}</pre>`, 'Checking itemTags fields', {
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,
        customClass: 'w-max max-w-[95vw]'
      })
    }

    return {
      initForm,
      fieldNamePath,
      checkTags
    }
  },
  created() {
    this.$watch('fieldModel', (val) => {
      if (this.initForm?.diffs) {
        if ((app.$utils.getByPath(this.initForm.form, this.fieldNamePath) || null) !== (val || null)) {
          this.initForm.diffs[this.apiFieldName] = true
        } else {
          delete this.initForm.diffs[this.apiFieldName]
        }
      }
    })
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    :readonly="false"
    class-component="gs-readonly"
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label="{ label }">
      <div @click="checkTags">
        {{ label }}
        <icon-ify
          icon="line-md:check-list-3"
          class="gs-scaled-icon-xs inline-block cursor-pointer"
        />
      </div>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
