function saveLoginRedirect(routeObj, prev = false) {
  if (!['login', '404'].includes(routeObj.name)) {
    localStorage.setItem(
      `login-redirect${prev ? '-prev' : ''}`,
      JSON.stringify({
        name: routeObj.name,
        params: routeObj.params,
        query: routeObj.query
      })
    )
  }
}

const userRightRoles = {
  forbidden: 'forbidden',
  admin: 'admin',
  editor: 'editor',
  deployer: 'deployer',
  user: 'user',
  support: 'support',
  timeshift: 'timeshift',
  translator: 'translator',
  playerViewer: 'playerViewer',
  clientConsoleProd: 'clientConsoleProd'
}

export { saveLoginRedirect, userRightRoles }
