const Pagination = {
  data() {
    return {
      pagination: {
        currentPage: 1,
        onPage: 50
      },
      total: 0
    }
  },
  computed: {
    crudTotal() {
      return (this.api ? this.total : this.rowsTotal) || 0
    }
  },
  methods: {
    getPaginationParams() {
      return {
        page: this.pagination.currentPage > 1 ? this.pagination.currentPage : undefined,
        on_page: this.pagination.onPage === this.rowsOnPageList?.[0] ? undefined : this.pagination.onPage
      }
    }
  }
}

export { Pagination }
