<template>
  <crud-details-page
    api="settings/feature-unlock"
    disable-top-margin
  >
    <template #form>
      <fields-col
        v-for="code in [
          'Journey',
          'QuestsDailytasks',
          'CityGearshop',
          'CityBarrelshop',
          'CityStampshop',
          'CityTradeshop',
          'CityBank',
          'CityTavern',
          'CityBarbershop',
          'ForgeMerge',
          'ForgeSmelt',
          'ForgeExtract',
          'ForgeDivide',
          'ForgeReforge',
          'ItemBoost',
          'Orbs',
          'Collection1',
          'Collection2',
          'Arena',
          'SecretCode',
          'BattleHard',
          'Guild',
          'BossChallenge',
          'QuestsChampionships',
          'Shrine',
          'BattleEvent',
          'Awaken',
          'Tower',
          'Skins',
          'GrandEvent',
          'SeasonPass',
          'Pets'
        ]"
        :key="code"
        :sm="8"
      >
        <crud-field-number
          :api-field-name="code"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
