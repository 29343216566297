<template>
  <crud-details-page
    api="settings/message-string"
  >
    <template #form>
      <fields-col>
        <!-- MessageTemplate -->
        <crud-field-select
          :sm="6"
          api-field-name="MessageTemplate"
          options-enum="enums.MessageTemplate"
          required
        />
        <!-- Sender -->
        <crud-field-selector-client-strings
          :sm="6"
          api-field-name="Sender"
        />
        <!-- Title -->
        <crud-field-selector-client-strings
          :sm="6"
          api-field-name="Title"
        />
        <!--  Contents -->
        <crud-field-selector-client-strings
          :sm="6"
          api-field-name="Contents"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
