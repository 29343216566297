<script>
import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'

export default {
  name: 'GsTable',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    expectedRowHeight: {
      type: Number,
      default: 40
    },
    additionalVisibleRows: {
      type: Number,
      default: 5
    },
    headerClass: {
      type: String,
      default: 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800 p-2 border-white'
    },
    headerAlignClass: {
      type: String,
      default: 'text-left'
    },
    cellClass: {
      type: String,
      default: 'p-2 border-slate-400'
    },
    cellAlignClass: {
      type: String,
      default: 'justify-left text-left items-center'
    },
    defaultColWidth: {
      type: Number,
      default: 100
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    emptyString: {
      type: String,
      default: 'no data'
    },
    loadingString: {
      type: String,
      default: 'loading...'
    },
    rowClassRender: {
      type: Function,
      /**
       *
       * @args rowData, rowIndex
       * @return class name
       */
      default: undefined
    },
    forceAll: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const resizeEl = ref(null)
    const heightResizeEl = ref(0)
    useResizeObserver(resizeEl, (entries) => {
      const entry = entries[0]
      const { height } = entry.contentRect
      heightResizeEl.value = height
    })
    return {
      resizeEl,
      heightResizeEl
    }
  },
  data() {
    return {
      lastVisibleRowIndex: 0,
      onPageVisibleRows: 10,
      totalTableWidth: 0,
      rowsTable: [],
      rendering: false,
      scrollWatcher: undefined,
      columnsDef: []
    }
  },
  computed: {
    totalRows() {
      return this.rows.length
    },
    propsRows() {
      return [...this.rows]
    }
  },
  watch: {
    columns: {
      handler() {
        this.buildColumnsDef()
      },
      immediate: true

    }
  },
  created() {
    if (!this.forceAll) {
      this.scrollWatcher = this.onScroll
    }
  },
  mounted() {
    if (!this.forceAll) {
      this.lastVisibleRowIndex =
        Math.ceil(this.heightResizeEl / this.expectedRowHeight) + this.onPageVisibleRows + this.additionalVisibleRows
      this.$watch('heightResizeEl', () => {
        this.onPageVisibleRows = Math.max(Math.ceil(this.heightResizeEl / this.expectedRowHeight), this.onPageVisibleRows)
        this.lastVisibleRowIndex = Math.max(this.onPageVisibleRows + this.additionalVisibleRows, this.lastVisibleRowIndex)
      })
    }
    this.$watch('propsRows', () => {
      this.setRows()
    })
    this.setRows()
  },
  methods: {
    onScroll(ev) {
      if (this.totalRows >= this.lastVisibleRowIndex) {
        this.lastVisibleRowIndex = Math.max(
          this.lastVisibleRowIndex,
          Math.ceil(ev?.scrollTop / this.expectedRowHeight) + this.onPageVisibleRows + this.additionalVisibleRows
        )
      } else {
        this.scrollWatcher = undefined
      }
    },
    setRows() {
      this.rendering = true
      this.$utils.nextLoopEvent(100).then(() => {
        this.rowsTable = this.rows.map((row, rIndex) => {
          row._rowClass = undefined
          if (this.rowClassRender) {
            row._rowClass = this.rowClassRender(row, rIndex)
          }
          if (row._colspan) {
            const colspan = {}
            let colspanSum = 0
            this.columns.forEach((col, cIndex) => {
              colspan[col.prop] = Math.max(row?._colspan?.[col.prop] || 1, 1)
              if (colspanSum && cIndex < colspanSum) {
                colspan[col.prop] = 0
              }
              colspanSum += colspan[col.prop]
            })
            row._colspan = colspan
          }
          return row
        })
        this.$utils.nextLoopEvent().then(() => {
          this.rendering = false
        })
      })
    },
    buildColumnsDef() {
      const maxWidthIndex = { val: 0, index: null }
      const cols = this.columns.map((col, cIndex) => {
        const colSettings = { ...col }
        colSettings.width = (colSettings.width || this.defaultColWidth) * this.$store.getters['auth/userScaledRatioWidth']
        if (colSettings.width > maxWidthIndex.val) {
          maxWidthIndex.val = colSettings.width
          maxWidthIndex.index = cIndex
        }
        this.totalTableWidth += col.width
        colSettings.width = `${colSettings.width}px`
        if (!colSettings?.title?.length && colSettings?.prop?.length) {
          colSettings.title = this.$utils.convertApiNamesToHuman(colSettings.prop)
        }
        return colSettings
      })
      if (maxWidthIndex.index !== null) {
        cols[maxWidthIndex.index].minWidth = true
      }
      this.columnsDef = cols
    }
  }
}
</script>

<template>
  <el-scrollbar
    ref="resizeEl"
    max-height="80vh"
    :class="{ 'w-full': fullWidth }"
    @scroll="scrollWatcher"
  >
    <table
      class="gs-table gs-font-scaled bg-white text-left text-neutral-600"
      :class="{ 'gs-bordered': bordered, 'w-full': fullWidth }"
      :style="{ minWidth: `${totalTableWidth - 2}px` }"
    >
      <colgroup>
        <col
          v-for="(col, cIndex) in columnsDef"
          :key="cIndex"
          :style="{ [col.minWidth === true ? 'minWidth' : 'width']: col?.width }"
          :name="`${col.prop}`"
        >
      </colgroup>
      <thead>
        <slot
          name="topHead"
          :header-class="headerClass"
        />
        <tr>
          <th
            v-for="(col, cIndex) in columnsDef"
            :key="cIndex"
            :class="[col?.headerClass || headerClass, col?.headerAlignClass || headerAlignClass]"
          >
            <template v-if="$slots[`header_${col.prop}`]">
              <slot
                :name="`header_${col.prop}`"
                :column="col"
              >
                {{ col.title }}
              </slot>
            </template>
            <template v-else>
              {{ col.title }}
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rIndex) in rowsTable"
          :key="rIndex"
          :class="[row._rowClass]"
        >
          <template v-if="!scrollWatcher || (scrollWatcher && rIndex <= lastVisibleRowIndex)">
            <template
              v-for="(col, cIndex) in columnsDef"
              :key="cIndex"
            >
              <td
                v-if="!row?._colspan || row._colspan[col.prop]"
                :colspan="!row?._colspan || row._colspan[col.prop] < 2 ? undefined : row._colspan[col.prop]"
              >
                <div
                  class="cell"
                  :class="[row.cellClass || col?.cellClass || cellClass, col?.cellAlignClass || cellAlignClass]"
                >
                  <template v-if="$slots[`cell_${col.prop}`]">
                    <slot
                      :name="`cell_${col.prop}`"
                      :column="col"
                      :row="row"
                      :cell="row?.[col.prop]"
                      :index="rIndex"
                    >
                      {{ row?.[col.prop] }}
                    </slot>
                  </template>
                  <template v-else>
                    {{ row?.[col.prop] }}
                  </template>
                </div>
              </td>
            </template>
          </template>
          <td
            v-else
            class="loading bg-neutral-50 p-2 text-gray-500"
            :colspan="columnsDef.length"
          >
            <div class="cell items-center">
              loading ...
            </div>
          </td>
        </tr>
        <tr v-if="!rowsTable.length || (rendering && loadingString.length)">
          <td
            class="loading p-2 text-gray-500"
            :colspan="columnsDef.length"
          >
            <div class="cell items-center justify-center text-center">
              {{ rendering ? loadingString : emptyString }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </el-scrollbar>
</template>

<style lang="postcss">
.gs-table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  td {
    border-bottom-width: 1px;
  }
  &.gs-bordered {
    td {
      border-left-width: 1px;
      &:last-child {
        border-right-width: 1px;
      }
    }
    th {
      border-right-width: 1px;
    }
  }
  tr {
    background: inherit;
  }
  th {
    background: inherit;
  }
  .cell {
    min-height: 40px;
    display: flex;
  }
  thead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
}
</style>
