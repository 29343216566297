<script>
import TypeLegend from '@/pages/items/items/sub/TypeLegend.vue'
import TopSearchCheckBoxes from '@/pages/items/items/sub/TopSearchCheckBoxes.vue'
import { SetRowColorByTypeItem } from '@/pages/items/items/compositions/TypeLegendSettings'

export default {
  components: { TypeLegend, TopSearchCheckBoxes },
  setup() {
    return {
      renderRowColor: SetRowColorByTypeItem
    }
  }
}
</script>

<template>
  <crud-table
    :default-visible-columns="['Name', 'Labels', 'Graphic']"
    api="items/items"
    api-item="items/item"
    show-img-preview-column
    :add-new-settings="{
      routeName: 'items-giftbox-details'
    }"
    :columns-settings="{ Class: false }"
    :static-api-params="{ Class: 'giftbox' }"
    :table-props="{
      'row-style': renderRowColor('giftboxes')
    }"
  >
    <template #topLeft>
      <TopSearchCheckBoxes />
    </template>
    <template #bottomBarLeft>
      <TypeLegend settings-key="giftboxes" />
    </template>
  </crud-table>
</template>
