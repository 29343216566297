<script>
import { inject, ref } from 'vue'
import { Close } from '@element-plus/icons-vue'

export default {
  name: 'CrudDetailsDocumentationDrawer',
  components: { Close },
  props: {
    typeField: {
      type: String,
      default: undefined
    }
  },
  setup() {
    const crudDetails = inject('crudDetails')
    const loading = ref({})
    const activeTab = ref('description')
    const activeTabForm = ref('documentation')
    const editDoc = ref(false)
    return { crudDetails, loading, activeTab, editDoc, activeTabForm }
  },
  computed: {
    editMode() {
      return this.crudDetails.docMode || this.editDoc
    }
  },
  methods: {
    update() {
      this.$refs?.editForm
        ?.validate?.()
        .then(() => {
          this.loading.update = true
          this.$axios
            .get(`${this.crudDetails.apiPrefix}documentation/save/`, {
              params: {
                form_id: this.crudDetails.crudDocKey,
                location: this.crudDetails.currentDocData.docField,
                text: JSON.stringify(this.crudDetails.currentDocData.data)
              }
            })
            .then(() => {
              this.crudDetails.loadDocumentation()
              this.$message({
                message: 'documentation saved',
                type: 'success',
                offset: 40
              })
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.loading.update = false
            })
          if (
            this.crudDetails?.id &&
            !this.crudDetails.currentDocData.fieldsDocs &&
            (this.crudDetails.currentDocData?.instanceNotes || '') !== (this.crudDetails.instanceNotes || '')
          ) {
            this.loading.updateInstanceNotes = true
            this.$axios
              .get(`${this.crudDetails.apiPrefix}documentation/save/`, {
                params: {
                  form_id: `${this.crudDetails.crudDocKey}_notes_${this.crudDetails.id}`,
                  location: 'instanceNotes',
                  text: this.crudDetails.currentDocData.instanceNotes
                }
              })
              .then(() => {
                this.crudDetails.loadInstanceNotes()
              })
              .catch(this.$utils.catchError)
              .then(() => {
                this.loading.updateInstanceNotes = false
              })
          }
        })
        .catch((err) => {
          this.crudDetails.notifyNotAllValid(err)
        })
    },
    onClose() {
      this.crudDetails.currentDocData = {}
      this.editDoc = false
      this.activeTab = 'description'
      this.activeTabForm = 'documentation'
    },
    onOpen() {
      if (!this.crudDetails.currentDocData?.fieldsDocs) {
        if (
          !this.crudDetails.currentDocData?.data?.description?.length &&
          this.crudDetails?.id &&
          this.crudDetails.currentDocData?.instanceNotes?.length
        ) {
          this.activeTabForm = 'notes'
        }
      }
    }
  }
}
</script>

<template>
  <el-drawer
    v-model="crudDetails.drawerDocumentation"
    destroy-on-close
    :with-header="false"
    :size="$windowWidth < 640 ? '95%' : '60%'"
    @closed="onClose"
    @open="onOpen"
  >
    <el-button
      class="gs-btn-text-neutral-light absolute right-4 top-0 bg-transparent p-0"
      @click="crudDetails.drawerDocumentation = false"
    >
      <el-icon class="gs-scaled-icon-xss">
        <Close />
      </el-icon>
    </el-button>
    <template v-if="crudDetails.crudDocKey">
      <el-form
        ref="editForm"
        label-position="top"
        :model="crudDetails.currentDocData.data"
      >
        <el-divider
          v-if="crudDetails.currentDocData?.title?.length"
          class="mb-2 mt-4"
          content-position="left"
        >
          <span class="gs-font-scaled inline-block max-w-md overflow-hidden text-ellipsis whitespace-nowrap">{{
            crudDetails.currentDocData.title
          }}</span>
        </el-divider>
        <div
          v-if="crudDetails.currentDocData?.fieldsDocs"
          :style="{ height: editMode ? '93%' : '95%' }"
          class="gs-font-scaled"
        >
          <el-tabs
            v-model="activeTab"
            class="h-full"
          >
            <el-tab-pane
              label="Description"
              name="description"
              class="h-full"
            >
              <el-scrollbar
                :wrap-class="editMode ? undefined : 'border-b border-silver-50 pb-2'"
                :max-height="$windowHeight - 160"
              >
                <div
                  v-if="!editMode"
                  class="gs-font-scaled whitespace-pre"
                  :style="{ minHeight: `${$windowHeight - 180}px` }"
                >
                  {{ crudDetails.currentDocData?.data?.description }}
                </div>
                <el-input
                  v-else
                  v-model="crudDetails.currentDocData.data.description"
                  type="textarea"
                  :input-style="`height: ${$windowHeight - 180}px`"
                />
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane
              v-if="
                ['number', 'dynamic_selector'].includes(crudDetails.currentDocData?.settingsField?.type)
                  && !crudDetails.currentDocData.disableDocSettings
                  && !(
                    crudDetails.currentDocData?.settingsField?.type === 'dynamic_selector'
                    && !crudDetails?.actionsList?.crudDuplicate
                  )
              "
              label="Settings"
              name="settings"
              lazy
            >
              <el-scrollbar
                wrap-class="border-b border-silver-50 pb-2"
                :height="$windowHeight - 160"
              >
                <div class="gs-font-scaled">
                  <crud-field-switcher
                    v-if="crudDetails.currentDocData?.settingsField?.type === 'dynamic_selector'"
                    api-field-name="duplicate"
                    :form="crudDetails.currentDocData.data"
                    :external-errors="{}"
                    :label="false"
                    :disabled="!editMode"
                    disable-doc
                  >
                    <template #fieldPrepend>
                      Duplicate field
                    </template>
                  </crud-field-switcher>
                  <el-row
                    v-if="crudDetails.currentDocData?.settingsField?.type === 'number'"
                    class="relative mt-4 border px-2 pt-3"
                  >
                    <div class="absolute -top-2.5 left-4 bg-white px-3 text-neutral-500">
                      User validation:
                    </div>
                    <crud-field-number
                      :span="8"
                      api-field-name="MinValueForNumber"
                      :form="crudDetails.currentDocData.data"
                      :external-errors="{}"
                      label="Min value"
                      :precision="10"
                      :min="false"
                      :disabled="!editMode"
                      nullable
                      disable-doc
                    />
                    <crud-field-number
                      :span="8"
                      :offset="2"
                      api-field-name="MaxValueForNumber"
                      :precision="10"
                      :min="false"
                      :form="crudDetails.currentDocData.data"
                      :external-errors="{}"
                      label="Max value"
                      :disabled="!editMode"
                      nullable
                      disable-doc
                    />
                    <crud-field-switcher
                      :span="5"
                      :offset="1"
                      api-field-name="Skip0ForNumber"
                      :form="crudDetails.currentDocData.data"
                      :external-errors="{}"
                      label="Skip &quot;0&quot; value"
                      :disabled="!editMode"
                      disable-doc
                    />
                  </el-row>
                </div>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          v-else
          :style="{ height: editMode ? '93%' : '95%' }"
        >
          <el-tabs
            v-model="activeTabForm"
            class="h-full"
          >
            <el-tab-pane
              label="Form documentation"
              name="documentation"
              class="h-full"
            >
              <el-scrollbar
                :wrap-class="editMode ? undefined : 'border-b border-silver-50 pb-2'"
                :max-height="$windowHeight - 160"
              >
                <div
                  v-if="!editMode"
                  class="gs-font-scaled whitespace-pre"
                  :style="{ minHeight: `${$windowHeight - 180}px` }"
                >
                  {{ crudDetails.currentDocData?.data?.description }}
                </div>
                <el-input
                  v-else
                  v-model="crudDetails.currentDocData.data.description"
                  type="textarea"
                  :input-style="`height: ${$windowHeight - 180}px`"
                />
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane
              v-if="crudDetails?.id"
              label="Instance notes"
              name="notes"
              class="h-full"
            >
              <el-scrollbar
                :wrap-class="editMode ? undefined : 'border-b border-silver-50 pb-2'"
                :max-height="$windowHeight - 160"
              >
                <div
                  v-if="!editMode"
                  class="gs-font-scaled whitespace-pre"
                  :style="{ minHeight: `${$windowHeight - 180}px` }"
                >
                  {{ crudDetails.currentDocData?.instanceNotes }}
                </div>
                <el-input
                  v-else
                  v-model="crudDetails.currentDocData.instanceNotes"
                  type="textarea"
                  :input-style="`height: ${$windowHeight - 180}px`"
                />
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="absolute bottom-4 left-5 right-10 flex justify-end pt-2">
          <template v-if="editMode">
            <el-button
              class="gs-loading gs-btn-outlined-neutral-neutral gs-font-scaled gs-height-related-xl px-3"
              @click="crudDetails.drawerDocumentation = false"
            >
              cancel
            </el-button>
            <el-button
              class="gs-loading gs-btn-primary gs-font-scaled gs-height-related-xl px-3"
              :loading="loading.update || loading.updateInstanceNotes"
              @click="update"
            >
              save
            </el-button>
          </template>
          <el-button
            v-else
            class="gs-loading gs-btn-outlined-primary-neutral gs-font-scaled gs-height-related-xl px-3"
            @click="editDoc = true"
          >
            edit
          </el-button>
        </div>
      </el-form>
    </template>
    <div
      v-else
      class="gs-font-scaled pt-10 font-bold text-red-400"
    >
      "Documentation key" is unknown. Please report the problem to the developer.
    </div>
  </el-drawer>
</template>

<style scoped></style>
