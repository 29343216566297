<script>
import { onBeforeUnmount, ref } from 'vue'
import { WarnTriangleFilled } from '@element-plus/icons-vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'VersionControl',
  components: { WarnTriangleFilled },
  setup() {
    const isNewVersion = ref(false)

    if (!app.$utils.isDevMode()) {
      const checkVersion = (init = false) => {
        const deployVersion = localStorage.getItem('deploy-version')
        fetch('/build.txt')
          .then((response) => {
            if (response.ok) {
              return response.text()
            }
          })
          .then((data) => {
            isNewVersion.value = false
            if (data) {
              if (init) {
                localStorage.setItem('deploy-version', data)
              } else if (deployVersion) {
                isNewVersion.value = String(data) !== String(deployVersion)
              }
            }
          })
      }
      checkVersion(true)
      const intervalRefer = setInterval(checkVersion, 15000)

      onBeforeUnmount(() => {
        clearInterval(intervalRefer)
      })
    }

    return {
      isNewVersion
    }
  }
}
</script>

<template>
  <el-popover
    trigger="hover"
    placement="bottom-start"
    :show-after="400"
    :width="300"
    :show-arrow="false"
    popper-class="bg-orange-100"
  >
    <template #reference>
      <div
        class="gs-version-icon gs-font-scaled fixed right-16 inline-block"
        style="z-index: 10000"
      >
        <el-icon
          v-show="isNewVersion"
          class="font-related-x3em cursor-help text-red-600"
        >
          <WarnTriangleFilled class="animate-bounce" />
        </el-icon>
      </div>
    </template>
    <template #default>
      <div class="gs-font-scaled">
        <div class="font-bold">
          A new version of the panel is available
        </div>
        <el-divider class="mt-1 mb-2" />
        <div class="font-related-xs break-normal text-left font-semibold leading-snug tracking-wider text-gray-500">
          It is recommended to immediately save the data and refresh the panel.
        </div>
        <div class="mt-1.5 text-right">
          <el-button
            class="gs-height-related-lg gs-btn-outlined-warning bg-transparent"
            @click="$router.go(0)"
          >
            refresh panel
          </el-button>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<style scoped>
body {
  .gs-version-icon {
    top: 1px;
  }
  &[gs-size='small'] {
    .gs-version-icon {
      top: 5px;
    }
  }
}
</style>
