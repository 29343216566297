<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_3/tip_3_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_3/tip_3_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_3/tip_3_3.png'

export default {
  data() {
    return {
      srcList: [TipImg1, TipImg2, TipImg3]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      Pages listing entities can be added to the menu in the "<b>Favorite</b>" section (visible only if at least one such page exists).<br>
      To add/remove a page from "Favorite" click on the "table settings" icon (three vertical dots) in the bottom right corner of the page and use <b>"Favorite page" switcher</b>.<br>
      The "Favorite" menu items are draggable, meaning you can change their order.<br>
    </p>
  </div>
</template>

<style scoped></style>
