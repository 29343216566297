<script>
import { computed } from 'vue'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'EnvTags',
  props: {
    environments: {
      type: Object,
      default: () => ({
        dev: true,
        prod: true
      })
    }
  },
  setup(props) {
    return {
      tagsSettings: CrudTagsSettings(...Object.keys(props.environments), 'all'),
      visibleTags: computed(() => {
        const all = props.environments.dev && props.environments.prod
        return all
          ? { all: true }
          : Object.fromEntries(
            Object.entries(props.environments).filter(([, isVisible]) => {
              return isVisible
            })
          )
      })
    }
  }
}
</script>

<template>
  <div class="my-1 flex flex-wrap gap-y-1.5">
    <el-tag
      v-for="(_, tagName, index) in visibleTags"
      :key="index"
      class="gs-font-scaled gs-height-related-sm mx-0.5 border-transparent px-1 transition hover:scale-110"
      :class="[tagsSettings[tagName].styleClasses]"
      size="small"
      effect="dark"
    >
      <span>{{ tagName }}</span>
    </el-tag>
  </div>
</template>
