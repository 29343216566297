<script>
import { Edit } from '@element-plus/icons-vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  setup() {
    const renderLoadedData = (response) => {
      response.items = []
      response.children = {}
      response.children_map = {}
      Object.entries(response.rows).forEach(([entity, rowEntity]) => {
        const route = app.$utils.getRouteByEntity(entity, true)
        Object.entries(rowEntity).forEach(([idRoot, rowVersion]) => {
          rowVersion.forEach((row) => {
            row.Entity = entity
            row._route = route?.name ? { name: route.name, params: { id: row.ID }, query: { back: 'global-sync' } } : null
            if (row.ID === idRoot * 1) {
              response.items.push(row)
            } else {
              if (!response.children[entity]) {
                response.children[entity] = {}
                response.children_map[entity] = {}
              }
              if (!response.children_map[entity][idRoot]) {
                response.children_map[entity][idRoot] = []
              }
              response.children[entity][row.ID] = row
              response.children_map[entity][idRoot].push(row.ID)
            }
          })
        })
      })
      return response
    }

    return {
      renderLoadedData,
      icons: {
        Edit
      }
    }
  }
}
</script>

<template>
  <crud-table
    api="sync/global"
    hide-versioned-top-filters
    disable-pagination
    :top-actions="false"
    :columns="{
      AdminLabel: 'string'
    }"
    :columns-settings="{
      AdminLabel: { header: 'Name', width: 500 },
      ID: { filterField: false, elAttr: { sortable: false }, width: 125 }
    }"
    :default-columns-settings="{
      cellEdit: false,
      filterField: false
    }"
    :action-col-settings="{
      label: 'Actions',
      elAttr: {
        'width': 60,
        'class-name': 'text-center'
      }
    }"
    :render-loaded-data="renderLoadedData"
    multi-entity
    group-by="Entity"
    disable-multi-select-rows
  >
    <template #topLeft="{ searchFields }">
      <div class="ml-4">
        <crud-type-fields
          v-model:value-field="searchFields.global_search"
          label="Global search"
          search-icon
        />
      </div>
    </template>
    <template #cell_action="{ row }">
      <router-link
        v-if="row._route"
        tabindex="-1"
        :to="$utils.bindStaticParams(row._route)"
      >
        <el-tooltip
          effect="light"
          placement="top"
          :show-after="600"
          content="Edit"
        >
          <el-button
            class="gs-height-related-sm font-related-xl px-1"
            tabindex="-1"
            :icon="icons.Edit"
          />
        </el-tooltip>
      </router-link>
      <span v-else>&nbsp;</span>
    </template>
  </crud-table>
</template>
