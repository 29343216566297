<script>
import draggable from 'vuedraggable'
import { ref } from 'vue'
import CrudFieldSelectorTradeShopComponents from '@/components/forms/crud-fields/CrudFieldSelectorTradeShopComponents.vue'

export default {
  components: { CrudFieldSelectorTradeShopComponents, Draggable: draggable },
  setup() {
    const itemsOrder = ref([])

    const initItem = (form) => {
      itemsOrder.value = Array.from({ length: 50 }).fill(null).map((_, index) => ({ index, item: index + 1 }))
      return form
    }

    const saveItem = (form) => {
      const orderedData = {}
      itemsOrder.value.forEach((orderData, order) => {
        orderedData[`Item${order + 1}`] = form[`Item${orderData.item}`]
      })
      return { ...form, ...orderedData }
    }

    const onChangeOrder = (markAsChanged) => {
      markAsChanged(
        'orderedData',
        itemsOrder.value.some((element, index) => {
          return element.index !== index
        })
      )
    }

    return {
      itemsOrder,
      initItem,
      saveItem,
      onChangeOrder
    }
  }
}
</script>

<template>
  <crud-details-page
    api="trade-shops/trade-shop"
    disable-top-margin
    :render-init-item="initItem"
    :render-saved-item="saveItem"
    enable-relations
  >
    <template #form="{ markAsChanged }">
      <fields-col
        :sm="6"
      >
        <!-- Labels -->
        <crud-field-text
          api-field-name="Labels"
          required
        />
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Kind -->
        <crud-field-select
          api-field-name="Kind"
          options-enum="enums.TradeShopKind"
          required
        />
        <!-- ReqLevel -->
        <crud-field-number
          api-field-name="ReqLevel"
          label="Required level"
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Start -->
        <crud-field-date-time
          api-field-name="Start"
          required
        />
        <!-- End -->
        <crud-field-date-time
          api-field-name="End"
          required
        />
        <!-- Priority -->
        <crud-field-number api-field-name="Priority" />
        <!-- ShowEndTimer -->
        <crud-field-switcher api-field-name="ShowEndTimer" />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- Topbar -->
        <crud-field-selector-files
          api-field-name="Topbar"
          label="Topbar graphic"
        />
        <!-- Icon -->
        <crud-field-selector-files
          api-field-name="Icon"
          label="Icon graphic"
        />
        <!-- ItemCurrency -->
        <crud-field-selector-items api-field-name="ItemCurrency" />
        <!-- QmarkPlace -->
        <crud-field-select
          api-field-name="QmarkPlace"
          label="Question mark place"
          options-enum="enums.QuestionMarkPlace"
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <!-- TabName -->
        <crud-field-text
          v-for="i in 4"
          :key="i"
          :api-field-name="`TabName${i}`"
          :label="`Tab ${i} name`"
        />
      </fields-col>
      <Draggable
        v-model="itemsOrder"
        item-key="index"
        ghost-class="gs-ghost"
        class="flex w-full flex-wrap"
        handle=".handle"
        @change="onChangeOrder(markAsChanged)"
      >
        <template #item="{ element, index }">
          <el-col
            class="relative"
            :sm="6"
          >
            <CrudFieldSelectorTradeShopComponents
              :api-field-name="`Item${element.item}`"
              :label="`Item ${index + 1}`"
              class="px-0"
            />
            <div class="absolute -left-1 top-0 bottom-0 flex items-center">
              <icon-ify
                icon="clarity:drag-handle-line"
                class="gs-scaled-icon-xl handle cursor-move"
              />
            </div>
          </el-col>
        </template>
      </Draggable>
      <el-col
        :sm="12"
        class="bg-amber-100"
      >
        <el-row>
          <crud-field-item-tags api-field-name="ItemTags" />
        </el-row>
        <el-row>
          <crud-field-selector-item-tag-groups api-field-name="ItemTagGroup" />
        </el-row>
      </el-col>
    </template>
  </crud-details-page>
</template>
