<script>
import TipImg1 from '!/pages/tools/ap-tips/tips/tip_1/tip_1_1.png'
import TipImg2 from '!/pages/tools/ap-tips/tips/tip_1/tip_1_2.png'
import TipImg3 from '!/pages/tools/ap-tips/tips/tip_1/tip_1_3.png'

export default {
  data() {
    return {
      srcList: [
        TipImg1,
        TipImg2,
        TipImg3
      ]
    }
  }
}
</script>

<template>
  <div class="p-4">
    <p class="overflow-auto">
      <el-image
        style="width: 100px; height: 100px"
        :src="srcList[0]"
        :preview-src-list="srcList"
        fit="cover"
        preview-teleported
        class="float-left mr-2"
        hide-on-click-modal
      />
      <b>Mask datetime/date</b> - visible after focus empty input (or after keypress "Enter" in empty field); makes typing easier a date from the numeric keypad without the need to use a date picker <br>
      <b>Keypress "Enter"</b> - when the field is filled and focused it is possible to move between date elements using the "Enter" button<br>
      <b>Keypress "SHIFT"</b> - select content of input<br>
    </p>
  </div>
</template>

<style scoped></style>
