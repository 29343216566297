import { inject } from 'vue'

const BasicProps = {
  props: {
    /**
     * props used by el-col component (like span, offset)
     * https://element-plus.org/en-US/component/layout.html
     */
    span: {
      type: Number,
      default: undefined
    },
    xs: {
      type: [Number, Boolean],
      default: undefined
    },
    sm: {
      type: [Number, Boolean],
      default: undefined
    },
    offset: {
      type: Number,
      default: undefined
    },
    push: {
      type: Number,
      default: undefined
    },
    pull: {
      type: Number,
      default: undefined
    },
    minHeight: {
      type: [Number, Boolean],
      default: 48
    },
    colClass: {
      type: [String, Object],
      default: undefined
    },
    /** Config props */
    label: {
      type: [String, Boolean],
      default: undefined
    },
    /** hides the text label (but not container) */
    emptyLabel: {
      type: Boolean,
      default: false
    },
    /**
     *  it is also possible to give a path to subfields by prefix with parents fields separated dot char
     *  eg rows.0.amount
     */
    apiFieldName: {
      type: String,
      default: '',
      required: true
    },
    /**
     *   'form' is passed by props of crud-details-page form slot
     *  (for components used in default slot use v-model="form.[apiFieldName]")
     */
    form: {
      type: Object,
      default: undefined
    },
    /**
     *   'externalErrors' is passed by props of crud-details-page form slot
     */
    externalErrors: {
      type: Object,
      default: undefined
    },
    /**
     *  https://github.com/yiminghe/async-validator
     *
     *        examples:
     *           { required: true, message: 'required', trigger: 'change' },
     *           { type: 'email', message: 'not valid email', trigger: 'change' },
     *           { validator: (rule, value) => value !== 'wrong_value' }
     */
    rules: {
      type: Array,
      default: () => []
    },
    /**
     * abbreviation for using the rules prop with the required validator
     */
    required: {
      type: Boolean,
      default: false
    },
    /** !Not defined props ($attr) are used as component field props */
    classComponent: {
      type: [String, Object],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /** removes the bottom margin */
    slim: {
      type: Boolean,
      default: false
    },
    /** when used as filter field */
    filterMode: {
      type: Boolean,
      default: false
    },
    /** grow field to container */
    growField: {
      type: Boolean,
      default: true
    },
    disableDoc: {
      type: Boolean,
      default: false
    },
    docIconClass: {
      type: String,
      default: undefined
    },
    disableDocSettings: {
      type: Boolean,
      default: false
    },
    docFieldName: {
      type: String,
      default: undefined
    },
    colBreak: {
      type: Boolean,
      default: false
    },
    mobileBreak: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      crudForm: undefined
    }
  },
  created() {
    if (this.form === undefined) {
      const injectForm = inject('crudForm')
      this.crudForm = injectForm.value
      this.$watch(
        () => injectForm.value,
        () => {
          this.crudForm = injectForm.value
        }
      )
    }
  },
  computed: {
    fieldNamePath() {
      return this.apiFieldName.split('.')
    },
    fieldModel: {
      get() {
        return this.$utils.getByPath(this.$data?.crudForm || this.$props.form, this.fieldNamePath)
      },
      set(val) {
        this.$utils.setByPath(this.$data?.crudForm || this.$props.form, this.fieldNamePath, this.onSetFieldModel(val))
      }
    }
  },
  methods: {
    onSetFieldModel(val) {
      return val
    }
  }
}
const AddNewByGlobalSearchProps = {
  props: {
    addNewByGlobalSearch: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: undefined
    },
    id: {
      type: [Number, String],
      default: undefined
    }
  }
}
export { BasicProps, AddNewByGlobalSearchProps }
