const SelectMode = {
  data() {
    return {
      selectModeEvents: {},
      currentSelected: { index: -1, ID: undefined }
    }
  },
  methods: {
    findRowBySelectionId(id) {
      let rowIndex = null
      if (id !== undefined) {
        this.tableRows.some((row, index) => {
          if (id === row?.[this.selectedRowIndex]) {
            rowIndex = index
            return true
          }
          return false
        })
      }
      return rowIndex
    },
    setSelectedRow(rowIndex) {
      this.$refs?.crudTable?.setCurrentRow?.(this.tableRows?.[rowIndex])
    }
  },
  created() {
    if (this.selectMode) {
      this.currentSelected.ID = this.selectedRow?.[this.selectedRowIndex]
      this.selectModeEvents['row-click'] = (currentRow) => {
        this.currentSelected.index = this.tableRows.indexOf(currentRow)
        if (this.currentSelected.index > -1) {
          this.currentSelected.ID = currentRow?.[this.selectedRowIndex]
          this.$emit('change-selection', this.$utils.cloneDeep(currentRow))
        }
      }

      this.$watch(
        'selectedRow',
        () => {
          if (this.currentSelected.ID !== this.selectedRow?.[this.selectedRowIndex]) {
            this.currentSelected.ID = this.selectedRow?.[this.selectedRowIndex]
            this.currentSelected.index = this.findRowBySelectionId(this.selectedRow?.[this.selectedRowIndex])
            this.setSelectedRow(this.currentSelected.index)
          }
        },
        { deep: true }
      )
    }
  }
}

export { SelectMode }
