<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldNumber',
  mixins: [BasicProps],
  props: {
    min: {
      type: [Number, Boolean],
      default: 0
    },
    max: {
      type: Number,
      default: undefined
    },
    step: {
      type: Number,
      default: undefined
    },
    stepStrictly: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 0
    },
    clearable: {
      type: Boolean,
      default: true
    },
    nullable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <crud-details-field
    v-bind="$props"
    number
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label="{ label }">
      <slot name="label">
        {{ label }}
        <template v-if="nullable">
          <sup><icon-ify
            :icon="required ? 'ph:number-circle-zero-fill' : 'jam:rubber'"
            class="gs-scaled-icon-xss inline-block scale-90 text-neutral-400"
          /></sup>
        </template>
      </slot>
    </template>
  </crud-details-field>
</template>

<style scoped></style>
