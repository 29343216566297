import VueMdijs from 'vue-mdijs'

// import { mdiFencing } from '@mdi/js';
//
// VueMdijs.add({ mdiFencing });

export default {
  install: (Vue) => {
    Vue.use(VueMdijs)
  }
}
