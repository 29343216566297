<template>
  <crud-table
    :default-visible-columns="['LevelMin', 'LevelMax', 'Time', 'RewardGold', 'RewardItemExp', 'RewardItemJourneyCurrency', 'RewardItem', 'RewardItemAmount']"
    :columns-settings="{
      LevelMin: { header: 'Min Lvl' },
      LevelMax: { header: 'Max Lvl' },
      RewardGold: { header: 'Gold reward' },
      RewardItemExp: { header: 'Item exp reward' },
      RewardItemJourneyCurrency: { header: 'Item journey currency reward' },
      RewardItemJourneyCurrency: { header: 'Item journey currency reward' },
      RewardItemAmount: { header: 'Amount item reward' }
    }"
    api="journeys/journeys"
    api-item="journeys/journey"
  />
</template>
