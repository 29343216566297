<script>
import { inject, ref } from 'vue'
import { Close } from '@element-plus/icons-vue'

export default {
  name: 'CrudDetailsMoreActions',
  components: { Close },
  setup() {
    const crudDetails = inject('crudDetails')
    const visiblePopoverMoreActions = ref(false)

    return {
      crudDetails,
      visiblePopoverMoreActions
    }
  }
}
</script>

<template>
  <el-popover
    v-model:visible="visiblePopoverMoreActions"
    :show-arrow="false"
    popper-class="p-0"
    trigger="click"
    :width="210 * $store.getters['auth/userScaledRatioWidth']"
    placement="bottom"
    :offset="-25"
  >
    <!-- button of crud settings -->
    <template #reference>
      <el-button
        class="gs-loading gs-font-scaled gs-height-related-xl px-0 ml-2"
      >
        <div>
          <icon-ify
            icon="heroicons-outline:dots-vertical"
            class="gs-scaled-icon-xs"
          />
        </div>
      </el-button>
    </template>
    <!-- content menu of crud settings -->
    <el-scrollbar
      class="pt-2"
      :max-height="Math.min($windowHeight - 100, 350)"
    >
      <div class="flex items-center justify-start pl-2 pr-4 text-xs">
        <!-- close menu btn -->
        <el-button
          type=""
          class="gs-btn-text-neutral-light absolute top-0 right-4 bg-transparent p-0"
          @click="visiblePopoverMoreActions = false"
        >
          <el-icon class="gs-scaled-icon-xss">
            <Close />
          </el-icon>
        </el-button>
      </div>
      <!-- list settings -->
      <div class="gs-font-scaled mt-4 mb-3">
        <div class="relative pl-2 pr-3">
          <el-switch
            v-model="crudDetails.docMode"
            :size="$store.getters['auth/userScaledMediumSize']"
            style="--el-switch-on-color: #13ce66"
            inline-prompt
            active-text="Y"
            inactive-text="N"
          />
          <span
            class="gs-font-scaled cursor-pointer pl-1.5"
            :class="{ 'text-green-600': crudDetails.docMode }"
            @click="crudDetails.docMode = !crudDetails.docMode"
          >
            documentation mode
          </span>
        </div>
      </div>
    </el-scrollbar>
  </el-popover>
</template>

<style scoped></style>
