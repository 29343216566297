<script>
import { defineAsyncComponent, ref } from 'vue'
import { UploadFilled } from '@element-plus/icons-vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'FilesSelector',
  components: {
    DynamicSelector,
    FilesDetailsPage: defineAsyncComponent(() => import('!/pages/files/DetailsPage.vue')),
    FilesUploaderDrawer: defineAsyncComponent(() => import('!/components/drawers/FilesUploaderDrawer.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    staticGroup: {
      type: String,
      default: undefined
    },
    uploadBtn: {
      type: Boolean,
      default: false
    },
    uploadTitle: {
      type: String,
      default: 'Upload new file'
    }
  },
  setup() {
    const uploadDrawer = ref(false)
    return {
      uploadDrawer,
      icons: {
        UploadFilled
      }
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Files'"
    :columns-settings="{
      Name: {},
      Group: { width: 170 }
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="binary/files"
    show-img-preview-column
    :edit-model-route-name="filterMode ? '' : 'files-details'"
    selector-type="dynamic_selector_files"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <FilesDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
    <template #additionalActions>
      <el-button
        v-if="uploadBtn"
        class="gs-btn-outlined-info-light gs-height-related-lg font-related-lg mr-1"
        :icon="icons.UploadFilled"
        @click="uploadDrawer = true"
      >
        upload new
      </el-button>
    </template>
    <template #drawers>
      <FilesUploaderDrawer
        v-if="uploadDrawer"
        v-model:show="uploadDrawer"
        :static-group="staticGroup"
        :title="uploadTitle"
      />
    </template>
  </DynamicSelector>
</template>
