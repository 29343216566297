<script>
import CrudFieldSelectorOffers from '@/components/forms/crud-fields/CrudFieldSelectorOffers.vue'

export default {
  components: { CrudFieldSelectorOffers },
  setup() {
    return {
      tabsPrefix: [
        ['B', 'Best'],
        ['N', 'Normal'],
        ['W', 'Weak']
      ],
      tabsDays: [2, 5, 10, 25, 50, 100]
    }
  }
}
</script>

<template>
  <crud-details-page
    api="payments/special-offer"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="24">
        <el-tabs
          class="w-full"
          model-value="main"
        >
          <el-tab-pane
            label="Main data"
            name="main"
          >
            <fields-col>
              <fields-col :sm="8">
                <!-- Label -->
                <crud-field-text
                  api-field-name="Label"
                  required
                />
                <!-- IsUsingDynamicGems -->
                <crud-field-switcher
                  api-field-name="IsUsingDynamicGems"
                  label="Is using dynamic gems (value caluclated by days of game)"
                />
              </fields-col>
            </fields-col>
          </el-tab-pane>
          <template
            v-for="prefix in tabsPrefix"
            :key="prefix"
          >
            <el-tab-pane
              v-for="days in tabsDays"
              :key="days"
              :label="prefix[0] + days"
              lazy
            >
              <fields-col :sm="16">
                <template
                  v-for="i in 12"
                  :key="i"
                >
                  <CrudFieldSelectorOffers
                    :sm="12"
                    :api-field-name="prefix[1] + days + i"
                    :label="`Product ${i}`"
                  />
                  <crud-field-number
                    :sm="12"
                    :api-field-name="`${prefix[1] + days + i}Weight`"
                    :label="`Weight ${i}`"
                  />
                </template>
              </fields-col>
            </el-tab-pane>
          </template>
        </el-tabs>
      </fields-col>
    </template>
  </crud-details-page>
</template>
