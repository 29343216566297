<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import CrudFieldSelectorTranslationPacks from '!/components/forms/crud-fields/CrudFieldSelectorTranslationPacks.vue'
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'
import { globalProperties as app } from '!/plugins/utilities'
import { userRightRoles } from '!/composition/utilities'

export default {
  components: { CrudFieldSelectorTranslationPacks, CrudFieldFile },
  setup() {
    const isDevEnv = import.meta.env.DEV
    const isEditMode = ref(false)
    const isTranslator = app.$utils.checkRights(userRightRoles.translator)
    const isDisabled = !isTranslator
    const store = useStore()
    const contentsFields = store.getters['auth/userLanguages'].map((lang) => {
      return `Contents${lang}`
    })
    const compareLangsOptions = [
      { value: 'Contents', label: 'En - English' },
      ...store.getters['auth/userLanguages'].map((lang) => {
        return { value: `Contents${lang}`, label: `${lang} - ${app.$utils.languageByCode(lang)?.name}` }
      })
    ]
    const editForm = ref({})
    const isCopyTranslations = ref((localStorage.getItem('copy-translations') || '').length > 0)
    const additionalFields = ref({})
    const dialogCompareVisible = ref(false)
    return {
      isDevEnv,
      isTranslator,
      contentsFields,
      compareLangsOptions,
      isCopyTranslations,
      initForm: (itemData) => {
        editForm.value = itemData
        isEditMode.value = !!itemData?.ID
        return itemData
      },
      actionsSettings: {
        crudSave: { right: userRightRoles.editor },
        crudDuplicate: { right: userRightRoles.translator },
        crudNewVersion: { right: userRightRoles.translator },
        crudUsage: { right: userRightRoles.translator },
        crudDelete: { right: userRightRoles.translator }
      },
      onSaveItem: (savedItem) => {
        if (savedItem.ID && !isTranslator) {
          return {
            ID: savedItem.ID,
            Remarks: savedItem.Remarks
          }
        }
        return savedItem
      },
      isDisabled,
      isDisabledOnlyEditMode: computed(() => {
        return isEditMode.value ? isDisabled : false
      }),
      copyTranslations: () => {
        const copy = {}
        contentsFields.forEach((fieldName) => {
          copy[fieldName] = editForm.value?.[fieldName] || ''
        })
        localStorage.setItem('copy-translations', JSON.stringify(copy))
        isCopyTranslations.value = true
      },
      pasteTranslation: () => {
        const paste = JSON.parse(localStorage.getItem('copy-translations'))
        if (paste) {
          contentsFields.forEach((fieldName) => {
            editForm.value[fieldName] = paste?.[fieldName] || ''
          })
        }
        isCopyTranslations.value = false
      },
      additionalFields,
      compareSelectedLangs() {
        dialogCompareVisible.value = true
      },
      dialogCompareVisible
    }
  }
}
</script>

<template>
  <crud-details-page
    api="settings/client-string"
    :render-init-item="initForm"
    :render-saved-item="onSaveItem"
    disable-top-margin
    :actions="actionsSettings"
    :versioned="false"
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- StringId -->
        <crud-field-text
          api-field-name="StringId"
          label="Key"
          required
          :disabled="isDisabledOnlyEditMode"
        />
        <!-- Contents -->
        <crud-field-textarea
          :textarea-rows="$windowWidth > 640 ? 1 : 8"
          api-field-name="Contents"
          label="Translation EN"
          required
          :disabled="isDisabledOnlyEditMode"
        >
          <template #label>
            <span class="pr-2 text-base">
              {{ $utils.languageByCode('EN')?.emoji }}
            </span>
            Translation en - {{ $utils.languageByCode('EN')?.name }}
          </template>
        </crud-field-textarea>
        <!-- ContentsTranslated -->
        <crud-field-switcher
          :sm="8"
          api-field-name="ContentsTranslated"
          label="verified en"
          :disabled="isDisabled"
        />
        <!-- Translation -->
        <CrudFieldSelectorTranslationPacks
          :sm="16"
          api-field-name="Translation"
          label="Translation pack"
          :disabled="isDisabled"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Contents -->
        <crud-field-textarea
          :textarea-rows="5"
          api-field-name="Comment"
          :disabled="isDisabledOnlyEditMode"
        />
        <!-- Screenshot -->
        <CrudFieldFile
          api-field-name="Screenshot"
          upload-title="Upload screenshot"
          :disabled="isDisabledOnlyEditMode"
          :download-file-name="`screenshot_client_strings_id_${form.ID}`"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Remarks -->
        <crud-field-textarea
          :textarea-rows="5"
          api-field-name="Remarks"
        />
        <!-- AutoTranslate -->
        <crud-field-switcher
          api-field-name="AutoTranslate"
          label="Auto translate"
          :disabled="isDisabledOnlyEditMode"
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-select
          v-if="isTranslator"
          api-field-name="comparelangs"
          label="Compare langs"
          :form="additionalFields"
          :collapse-tags="false"
          :options="compareLangsOptions"
          multiple
          :multiple-limit="2"
        >
          <template #fieldAppend>
            <el-button
              class="gs-font-scaled gs-height-related-xl gs-btn-outlined-primary-neutral ml-2"
              :disabled="additionalFields?.comparelangs?.length !== 2"
              @click="compareSelectedLangs"
            >
              compare
            </el-button>
          </template>
        </crud-field-select>
      </fields-col>
      <fields-col class="pb-10" />
      <fields-col>
        <crud-field-textarea
          v-for="field in contentsFields"
          :key="field"
          :sm="8"
          :textarea-rows="$windowWidth > 640 ? 1 : 8"
          :api-field-name="field"
          :disabled="isDisabled"
        >
          <template #label>
            <span class="pr-2 text-base">{{ $utils.languageByCode(field.slice(-2))?.emoji }}</span>
            {{ $utils.convertApiNamesToHuman(field) }} - {{ $utils.languageByCode(field.slice(-2))?.name }}
          </template>
        </crud-field-textarea>
      </fields-col>
      <fields-col v-if="isTranslator">
        <crud-field-slot>
          <el-button
            class="gs-font-scaled gs-height-related-xl gs-btn-outlined-primary-neutral"
            @click="copyTranslations"
          >
            copy translations
          </el-button>
          <el-button
            class="gs-font-scaled gs-height-related-xl gs-btn-outlined-success-neutral"
            :disabled="!isCopyTranslations"
            @click="pasteTranslation"
          >
            paste translations
          </el-button>
        </crud-field-slot>
      </fields-col>
      <el-dialog
        v-if="isTranslator"
        v-model="dialogCompareVisible"
        title="Compare langs"
        fullscreen
      >
        <fields-col>
          <crud-field-textarea
            v-for="field in additionalFields.comparelangs"
            :key="field"
            :sm="12"
            :textarea-rows="0"
            :api-field-name="field"
            :disabled="isDisabled"
            input-style="height: 85vh;"
          >
            <template #label>
              <template v-if="field !== 'Contents'">
                <span class="pr-2 text-base">{{ $utils.languageByCode(field.slice(-2))?.emoji }}</span>
                {{ $utils.convertApiNamesToHuman(field) }} - {{ $utils.languageByCode(field.slice(-2))?.name }}
              </template>
              <template v-else>
                <span class="pr-2 text-base">
                  {{ $utils.languageByCode('EN')?.emoji }}
                </span>
                Contents en - {{ $utils.languageByCode('EN')?.name }}
              </template>
            </template>
          </crud-field-textarea>
        </fields-col>
        <div class="flex justify-end pr-4 pt-4">
          <el-button
            class="gs-font-scaled gs-height-related-xl gs-btn-primary"
            @click="dialogCompareVisible = false"
          >
            close
          </el-button>
        </div>
      </el-dialog>
    </template>
  </crud-details-page>
</template>
