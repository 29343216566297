<template>
  <crud-details-page
    api="settings/string-template"
  >
    <template #form>
      <fields-col>
        <!-- Kind -->
        <crud-field-select
          :sm="8"
          api-field-name="Kind"
          options-enum="enums.StringTemplateKind"
          required
        />
        <!-- Labels -->
        <crud-field-text
          :sm="8"
          api-field-name="Labels"
        />
        <!-- Contents -->
        <crud-field-selector-client-strings
          :sm="8"
          api-field-name="Contents"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
