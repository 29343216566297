<script setup>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

const dialogDebugDump = ref(false)
const loadingDebugDump = ref(false)
const debugDumpData = ref({
  response: null
})

function debugDump() {
  loadingDebugDump.value = true

  app.$axios
    .get('/jwt/creator/debugdump/')
    .then(({ data }) => {
      debugDumpData.value.response = data || 'no data'
      debugDumpData.value.response = debugDumpData.value.response.replace('<pre>', '').replace('</pre>', '')
      dialogDebugDump.value = true
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingDebugDump.value = false
    })
}

function clearDebugDumpData() {
  debugDumpData.value.response = null
}
</script>

<template>
  <crud-table
    :default-visible-columns="['ID', 'Label', 'Type', 'TargetName', 'TargetId']"
    api="creator/creators"
    api-item="creator/creator"
    :versioned="false"
  >
    <template #topRight>
      <el-tooltip
        effect="light"
        placement="top"
        content="debug dump"
        :show-after="600"
      >
        <el-button
          :size="$store.getters['auth/userScaledMediumSize']"
          class="gs-loading gs-btn-outlined-primary-light relative mx-1 px-1"
          :loading="loadingDebugDump"
          @click="debugDump"
        >
          <icon-ify
            icon="carbon:debug"
            class="gs-scaled-icon-lg"
          />
        </el-button>
      </el-tooltip>
    </template>
    <template #drawers>
      <el-dialog
        v-model="dialogDebugDump"
        width="90%"
        title="Debug dump"
        @close="clearDebugDumpData"
      >
        <div class="my-2 py-10 border-t-2 border-t-neutral-300">
          <el-scrollbar max-height="65vh">
            <pre>{{ debugDumpData.response }}</pre>
          </el-scrollbar>
        </div>
      </el-dialog>
    </template>
  </crud-table>
</template>
