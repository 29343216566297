<script>
import CrudFieldSelectorDialogs from '@/components/forms/crud-fields/CrudFieldSelectorDialogs.vue'

export default {
  components: { CrudFieldSelectorDialogs }
}
</script>

<template>
  <crud-details-page
    api="battle/location"
    disable-top-margin
  >
    <template #form>
      <fields-col
        :sm="8"
      >
        <!-- Name -->
        <crud-field-selector-client-strings
          api-field-name="Name"
          required
        />
        <!-- Order -->
        <crud-field-number
          api-field-name="Order"
          required
        />
        <!-- Code -->
        <crud-field-select
          api-field-name="Code"
          options-enum="enums.LocationCode"
          required
        />
        <!-- Graphic -->
        <crud-field-selector-files api-field-name="Graphic" />
        <!-- IdleGraphic -->
        <crud-field-selector-files api-field-name="IdleGraphic" />
        <!-- CompleteDialog -->
        <CrudFieldSelectorDialogs api-field-name="CompleteDialog" />
      </fields-col>
      <fields-col
        v-for="type in ['Normal', 'Hard']"
        :key="type"
        :sm="8"
      >
        <template
          v-for="i in 3"
          :key="i"
        >
          <!-- RewardRequiredStars -->
          <crud-field-number
            :api-field-name="`Reward${type}${i}RequiredStars`"
            :label="`Required stars ${type} ${i}`"
          />
          <!-- RewardPremium -->
          <crud-field-number
            :api-field-name="`Reward${type}${i}Premium`"
            :label="`Premium ${type} ${i}`"
          />
          <!-- RewardItem -->
          <crud-field-selector-items
            :api-field-name="`Reward${type}${i}Item`"
            :label="`Item ${type} ${i}`"
          />
          <!-- RewardItemAmount -->
          <crud-field-number
            :api-field-name="`Reward${type}${i}ItemAmount`"
            :label="`Item amount ${type} ${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
