<script>
import { methods, options, props } from '@/components/pages/battle/active-skills/compositions/EffectConfig'

export default {
  name: 'ActiveSkillsEffectAttributeFormFields',
  mixins: [props],
  emits: ['change-effect'],
  setup() {
    const { effectOptions, targetRangeOptions, targetRowOptions, targetTeamOptions, buffAssetsOptions } = options
    const { initDefaultValues } = methods
    return {
      effectOptions,
      targetRowOptions,
      targetTeamOptions,
      targetRangeOptions,
      buffAssetsOptions,
      kindOptions: [
        { value: 'attack_pct', label: 'attack pct' },
        { value: 'shield_pct', label: 'shield pct' },
        { value: 'shield_value', label: 'shield value' },
        { value: 'magic_pct', label: 'magic pct' },
        { value: 'hp_pct', label: 'hp pct' },
        { value: 'hp_value', label: 'hp value' },
        { value: 'hp_by_atk_pct', label: 'hp by atk pct' },
        { value: 'hp_by_mag_pct', label: 'hp by mag pct' },
        { value: 'hp_by_attacker_atk_pct', label: 'hp by attacker atk pct' },
        { value: 'crit_pct', label: 'crit pct' },
        { value: 'dodge_pct', label: 'dodge pct' },
        { value: 'melee_resistance_pct', label: 'melee resistance pct' },
        { value: 'magic_resistance_pct', label: 'magic resistance pct' },
        { value: 'prevent_stun', label: 'prevent stun' },
        { value: 'permanent_hp_max_pct', label: 'permanent hp max pct' },
        { value: 'permanent_shield_max_pct', label: 'permanent shield max pct' }
      ],
      initDefaultValues
    }
  },
  created() {
    this.initDefaultValues({
      'effectType': 'attribute',
      'duration': 0,
      'target-team': 'enemy',
      'target-range': 'single',
      'target-row': 'any',
      'is-percent': 1,
      'kind': 'attack_pct',
      'buff-asset': 'no_icon',
      'chance': 100,
      'dlvl-chance': 0,
      'min-value': 0,
      'max_value': 0,
      'dlvl-value': 0
    })
  }
}
</script>

<template>
  <el-scrollbar class="w-full">
    <div class="wrapper-fields flex w-full">
      <!-- effectType -->
      <div class="col-w-3">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.effectType`"
          label="Effect"
          :options="effectOptions"
          :clearable="false"
          slim
          class="gs-effect-field"
          style="max-width: 250px"
          doc-field-name="Attribute_Effect"
          disable-doc-settings
          @change="$emit('change-effect', $event)"
        />
      </div>
      <!-- duration -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.duration`"
          label="Duration"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Attribute_Duration"
          disable-doc-settings
        />
      </div>
      <!-- kind -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.kind`"
          label="Kind"
          slim
          :clearable="false"
          :options="kindOptions"
          doc-field-name="Attribute_Kind"
          disable-doc-settings
        />
      </div>
      <!-- buff-asset -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.buff-asset`"
          label="Buff asset"
          slim
          :clearable="false"
          :options="buffAssetsOptions"
          doc-field-name="Attribute_Buff_asset"
          disable-doc-settings
        />
      </div>
    </div>
    <div class="wrapper-fields flex w-full">
      <!-- target-team -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-team`"
          label="Target team"
          slim
          :clearable="false"
          :options="targetTeamOptions"
          doc-field-name="Attribute_Target_team"
          disable-doc-settings
        />
      </div>
      <!-- target-range -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-range`"
          label="Target range"
          slim
          :clearable="false"
          :options="targetRangeOptions"
          doc-field-name="Attribute_Target_range"
          disable-doc-settings
        />
      </div>
      <!-- target-row -->
      <div class="col-w-1">
        <crud-field-select
          :api-field-name="`effectRows.${rowIndex}.data.target-row`"
          label="Target row"
          slim
          :clearable="false"
          :options="targetRowOptions"
          doc-field-name="Attribute_Target_row"
          disable-doc-settings
        />
      </div>
      <!-- chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.chance`"
          label="Chance %"
          :precision="2"
          :min="0"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Attribute_Chance%"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-chance -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-chance`"
          label="Chance inc per lvl"
          :precision="2"
          :min="0"
          slim
          :clearable="false"
          doc-field-name="Attribute_Chance_inc_per_lvl"
          disable-doc-settings
        />
      </div>
      <!-- min-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.min-value`"
          label="Value min"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="Attribute_Value_min"
          disable-doc-settings
        />
      </div>
      <!-- max_value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.max_value`"
          label="Value max"
          :min="false"
          slim
          :clearable="false"
          doc-field-name="Attribute_Value_max"
          disable-doc-settings
        />
      </div>
      <!-- dlvl-value -->
      <div class="col-w-1">
        <crud-field-number
          :api-field-name="`effectRows.${rowIndex}.data.dlvl-value`"
          label="Increment per level"
          :precision="2"
          :min="false"
          :max="100"
          slim
          :clearable="false"
          doc-field-name="Attribute_Increment_per_level"
          disable-doc-settings
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
